import React, { useContext, useEffect, useState } from 'react';
import useFetch from 'hooks/useFetch/useFetch';
import { UrlKeyProvider } from 'context/returns/UrlKeyContext';
import { OverviewSteps } from 'components/Returns/Overview/OverviewSteps/OverviewSteps';
import { ReviewContextProvider } from 'context/returns/ReviewContext';
import { OverviewContextProvider } from 'context/returns/OverviewContext';
import { Error } from 'components/Shared/Error/Error';
import { MethodsContextProvider } from 'context/returns/MethodsContext';
import { Loading } from 'components/Shared/Loading/Loading';
import {
  OverviewApiResponseErrorType,
  OverviewApiResponseSuccessType,
  OverviewFormStepsEnum
} from 'types/return/overview/overview-api-response-type';
import { Location, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ReturnsLayout } from 'layouts/Returns/ReturnsLayout/ReturnsLayout';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { ProductsSelectionContextProvider } from 'context/returns/ProductsSelectionContext';
import { Environment, resolveEnvironment } from 'utils/common/environment-resolver/environment-resolver';
import { OrderItemsContextProvider } from 'context/returns/OrderItemsContext';

export const Overview: React.FC = () => {
  const [urlSearchParams] = useSearchParams();
  const location: Location = useLocation();
  const navigate = useNavigate();

  const [orderNumber, setOrderNumber] = useState<string | null>(urlSearchParams.get('inputOrderId'));
  const [shippingPostalCode, setShippingPostalCode] = useState<string | null>(urlSearchParams.get('shippingPostalCode'));
  const [urlKey] = useState<string | null>(urlSearchParams.get('key'));
  // @ts-ignore
  const [isFromSearchOrder] = useState<boolean>(location.state && location.state.fromSearchOrder);

  const { vanityName } = useContext(ClientConfigContext);
  const OVERVIEW_API = `/api/return/overview/${vanityName}?inputOrderId=${orderNumber}${
    shippingPostalCode ? `&shippingPostalCode=${shippingPostalCode}` : ''
  }${urlKey ? `&key=${urlKey}` : ''}`;

  const [overviewConfig, , overviewError] = useFetch<OverviewApiResponseSuccessType, OverviewApiResponseErrorType>(OVERVIEW_API);

  useEffect(() => {
    setShippingPostalCode(urlSearchParams.get('shippingPostalCode'));
    setOrderNumber(urlSearchParams.get('inputOrderId'));
    const environment: Environment = resolveEnvironment();
    if (!orderNumber && !shippingPostalCode && !urlKey) return navigate(environment === Environment.LOCAL ? `/search/${vanityName}` : `/search`);
    if (!orderNumber && shippingPostalCode && !urlKey) return navigate(environment === Environment.LOCAL ? `/search/${vanityName}` : `/search`);
    if (!orderNumber && shippingPostalCode && urlKey) return navigate(environment === Environment.LOCAL ? `/search/${vanityName}` : `/search`);
    if (!orderNumber && !shippingPostalCode && urlKey) return navigate(environment === Environment.LOCAL ? `/search/${vanityName}` : `/search`);
    if (overviewError && 'errorTitle' in overviewError && overviewError.errorTitle === 'RETURN.overview.page.error.notFound')
      return navigate(environment === Environment.LOCAL ? `/search/${vanityName}` : `/search`);
    if (overviewError && 'errorTitle' in overviewError && overviewError.errorTitle === 'RETURN.overview.page.error.shippingPostalCodeNotFound')
      return navigate(environment === Environment.LOCAL ? `/search/${vanityName}` : `/search`);
    if (overviewError && 'errorTitle' in overviewError && overviewError.errorTitle === 'RETURN.overview.page.error.dashesWithinOrderNumberNotAllowed')
      return navigate(environment === Environment.LOCAL ? `/search/${vanityName}` : `/search`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, urlSearchParams, vanityName, overviewError]);

  /*useEffect(() => {
    Object.keys(selectedProducts).forEach((product) => {
      if (selectedProducts[product]?.option === 'exchange') {
        setcustomeTitle("Exchange for order #");
      }else if (selectedProducts[product]?.option === 'return') {
        setcustomeTitle("Return for order #");
      }
    });
  })*/
  if (overviewError) return <Error />;
  if (!overviewConfig) return <Loading />;

  return (
    <OverviewContextProvider
      initialIsFromSearchOrder={isFromSearchOrder}
      initialStep={overviewConfig.orderItems.length > 0 ? OverviewFormStepsEnum.One : OverviewFormStepsEnum.Two}>
      <OrderItemsContextProvider initialOrderItems={overviewConfig.orderItems}>
        <ProductsSelectionContextProvider>
          <ReviewContextProvider
            initialClientReturnType={overviewConfig.returnsModule.clientReturnType}
            initialShipFromAddress={overviewConfig.shipTo}
            dispositionSortCode={overviewConfig.returnsModule.dispositionSortCode}
            returnsCustomField={overviewConfig.returnsModule.returnsCustomField}
            isShippingPostalCodeExists={!!shippingPostalCode}>
            <UrlKeyProvider urlKeyProp={overviewConfig.urlKey}>
              <MethodsContextProvider>
                <ReturnsLayout
                  title={overviewConfig.returnsModule.orderNumberTitle}
                  orderNumber={orderNumber}
                  policyParagraph={overviewConfig.returnsModule.returnsPolicyView.policyParagraph}
                  policyParagraphLink={overviewConfig.returnsModule.returnsPolicyView.policyParagraphLink}>
                  <OverviewSteps
                    overviewConfig={overviewConfig}
                    orderNumber={orderNumber ? orderNumber : ''}
                    shippingPostalCode={shippingPostalCode}
                    address={overviewConfig.shipTo}
                  />
                </ReturnsLayout>
              </MethodsContextProvider>
            </UrlKeyProvider>
          </ReviewContextProvider>
        </ProductsSelectionContextProvider>
      </OrderItemsContextProvider>
    </OverviewContextProvider>
  );
};
