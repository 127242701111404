import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { resolveTheme } from 'utils/common/theme-resolver/theme-resolver';
import { getDesignTokens } from 'styles/theme';
import { loadFonts2 } from 'utils/common/google-fonts/google-fonts';
import { GlobalStyles } from 'components/Shared/GlobalStyles/GlobalStyles';
import Header from 'components/Shared/Header/Header';
import { Footer } from 'components/Shared/Footer/Footer';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet';
import { getFavIcons } from 'utils/common/favicons/favicons';
import { ClientConfigurationErrorType, ClientConfigurationType } from 'types/shared/config/client-configuration-type';
import { AppErrorLoader } from 'app/AppErrorLoader/AppErrorLoader';
import useFetch from 'hooks/useFetch/useFetch';
import { resolveVanityName2 } from 'utils/app/resolve-vanity-name/resolve-vanity-name';
import { resolvePageType } from 'utils/shared/resolve-page-type/resolve-page-type';
import { initGoogleTagManager } from 'utils/common/google-tag-manager/google-tag-manager';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { ClientConfigContextProvider } from 'context/shared/ClientConfigContext';
import { isPreviewBasedOnRoute } from 'utils/app/is-preview-based-on-route/is-preview-based-on-route';
import { shouldDisplayFooter } from 'utils/app/should-display-footer/should-display-footer';
import { PageTypeEnum } from 'types/app/page-type-enum';
import { shouldDisplayTopNav } from 'utils/app/should-display-top-nav/should-display-top-nav';
import { getApiHostURL } from 'utils/common/get-api-host-url/get-api-host-url';
import { ApiHostTypeEnum } from 'types/shared/config/api-host-type-enum';

type AppParams = {
  vanityName: string | undefined;
  designId: string | undefined;
  trackingNumber: string | undefined;
};

export const App: React.FC = () => {
  const { vanityName, designId, trackingNumber } = useParams<AppParams>();
  const ldClient = useLDClient();
  const location = useLocation();
  const [isPreviewBasedOnRouteUrl, setIsPreviewBasedOnRouteUrl] = useState<boolean>(false);
  const [launchDarklyIdentifyLoading, setLaunchDarklyIdentifyLoading] = useState<boolean>(true);
  const [launchDarklyIdentifyError, setLaunchDarklyIdentifyError] = useState<string | null>(null);
  const [resolvedVanityName] = useState<string>(resolveVanityName2(vanityName, location.pathname));
  const [resolvedPageType, setResolvedPageType] = useState<PageTypeEnum>(
    resolvePageType(location.pathname, resolvedVanityName, trackingNumber, designId)
  );
  const [areFontsLoading, setAreFontsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsPreviewBasedOnRouteUrl(isPreviewBasedOnRoute(location.pathname));
  }, [location]);

  // useEffect(() => {
  //   setResolvedVanityName(resolveVanityName(vanityName, isPreviewBasedOnRouteUrl, resolvedPageType));
  // }, [isPreviewBasedOnRouteUrl, vanityName, resolvedPageType]);

  useEffect(() => {
    setResolvedPageType(resolvePageType(location.pathname, resolvedVanityName, trackingNumber, designId));
  }, [location, resolvedVanityName, trackingNumber, designId]);

  // Note: for localhost each route should contain :vanityName to Be resolved and download the proper client-configuration
  // For Demo or Production it is not necessary as we can get vanityName by using window.location.hostname and use vanityName from domain
  // Exception is for preview there we'll also use vanityName from params not from domain even if domain exists.
  const CLIENT_CONFIGURATION_API = `${getApiHostURL(
    ApiHostTypeEnum.CLIENT_CONFIGURATION_API
  )}/api/configuration/published-configurations/${resolvedVanityName}`;
  // const CLIENT_CONFIGURATION_API_NEW = `${getApiHostURL(ApiHostTypeEnum.CLIENT_CONFIGURATION_API)}/api/configurations?vanityName=${resolvedVanityName}&configType=Publish&type=published-configuration&option=shortened`;
  const CLIENT_CONFIGURATION_PREVIEW_API = `${getApiHostURL(
    ApiHostTypeEnum.CLIENT_CONFIGURATION_API
  )}/api/configuration/client-configurations/preview/${resolvedVanityName}/${designId}`;
  // const CLIENT_CONFIGURATION_PREVIEW_API_NEW = `${getApiHostURL(ApiHostTypeEnum.CLIENT_CONFIGURATION_API)}/api/designs/${designId}/configurations?type=preview-configuration`;
  const [clientConfig, , clientConfigError] = useFetch<ClientConfigurationType, ClientConfigurationErrorType>(
    designId ? CLIENT_CONFIGURATION_PREVIEW_API : CLIENT_CONFIGURATION_API
  );

  useEffect(() => {
    loadFonts2(null, () => setAreFontsLoading(false), ['Playfair+Display:400,700', 'Roboto:400,700']);

    if (clientConfig) {
      initGoogleTagManager(clientConfig.tagManagerModule.tagManagerId, clientConfig.tagManagerModule.tagManagerEnvironment);
      loadFonts2(clientConfig.styleModule, () => setAreFontsLoading(false), ['Playfair+Display:400,700', 'Roboto:400,700']);
    }
  }, [clientConfig]);

  useEffect(() => {
    if (ldClient && clientConfig && clientConfig.brandId) {
      (async () => {
        try {
          setLaunchDarklyIdentifyLoading(true);
          const response: LDFlagSet = await ldClient.identify({ key: clientConfig.brandId });
          if (response.message) return setLaunchDarklyIdentifyError(response.message);
          setLaunchDarklyIdentifyError(null);
        } catch (e) {
          setLaunchDarklyIdentifyError(null);
        } finally {
          setLaunchDarklyIdentifyLoading(false);
        }
      })();
    }
  }, [clientConfig, ldClient]);

  const theme = useMemo(() => {
    if (clientConfig) return resolveTheme(clientConfig.styleModule, clientConfig.template, resolvedPageType);
    return resolveTheme(undefined, undefined, resolvedPageType);
  }, [clientConfig, resolvedPageType]);

  const mui_theme = useMemo(() => {
    if (clientConfig && clientConfig.styleModule)
      return createTheme(
        getDesignTokens(
          clientConfig.styleModule.bodyFontFamily,
          clientConfig.styleModule.secondaryColor,
          clientConfig.styleModule.secondaryBackgroundDark,
          clientConfig.styleModule.secondaryColor,
          clientConfig.styleModule.secondaryBackgroundDark ? '#fff' : '#000',
          '#000',
          clientConfig.styleModule.buttonStyle
        )
      );
    return createTheme(getDesignTokens('Roboto', '#000', false, '#000', '#fff', '#000', 'rounded'));
  }, [clientConfig]);

  if (clientConfigError) return <AppErrorLoader error={clientConfigError} isPreviewBasedOnRouteUrl={isPreviewBasedOnRouteUrl} />;
  if (launchDarklyIdentifyError)
    return (
      <AppErrorLoader
        isPreviewBasedOnRouteUrl={isPreviewBasedOnRouteUrl}
        error={{ error: { error: '', message: launchDarklyIdentifyError, path: '', status: 404 } }}
      />
    );
  if (!clientConfig || !ldClient || launchDarklyIdentifyLoading || areFontsLoading) return null;

  return (
    <MUIThemeProvider theme={mui_theme}>
      <ThemeProvider theme={{ ...theme, customStyles: { styleModule: clientConfig.styleModule } }}>
        <ClientConfigContextProvider
          initialBrandName={clientConfig.brandName}
          initialClientReturnType={clientConfig.returnsModule.clientReturnType}
          initialDesignId={clientConfig.designId ? clientConfig.designId : null}
          initialIsPreview={clientConfig.isPreview}
          initialMerchantId={clientConfig.merchantId}
          initialTemplate={clientConfig.template}
          initialBrandId={clientConfig.brandId}
          initialVanityName={clientConfig.vanityName}
          initialStyleModule={clientConfig.styleModule}
          initialClientConfig={clientConfig}>
          <GlobalStyles isPreviewBasedOnRouteUrl={isPreviewBasedOnRouteUrl} clientConfig={clientConfig} theme={theme} />
          <Helmet>{getFavIcons(clientConfig.headerModule.faviconsModule)}</Helmet>
          <Header headerModule={clientConfig.headerModule} topNavExists={shouldDisplayTopNav(resolvedPageType)} />
          <Outlet />
          {shouldDisplayFooter(resolvedPageType) && (
            <Footer
              footerModule={clientConfig.footerModule}
              smsEnabled={clientConfig.notificationsModule ? clientConfig.notificationsModule.smsEnabled : false}
            />
          )}
        </ClientConfigContextProvider>
      </ThemeProvider>
    </MUIThemeProvider>
  );
};
