import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const FeedbackContainer = styled.div`
  width: 100%;
  max-width: 640px;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      @media ${theme.mediumUp} {
        display: flex;
        justify-content: center;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      max-width: none;
      padding: ${theme.primaryBackgroundDark ? '20px 0' : '20px'};
      border: ${theme.primaryBackgroundDark ? 'none' : '1px solid #e1e1e1'};
      @media ${theme.xlargeUp} {
        padding: 20px;
        display: block;
      }
    `}
`;

export const FeedbackTextWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      @media ${theme.mediumUp} {
        width: 50%;
        margin-bottom: 0;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      margin-bottom: 40px;
    `}
`;

export const FeedbackTitle = styled.h4<{ textAlignLeft: boolean | null }>`
  transition: all 0.3s;
  font-family: ${getCustomStyle('styleModule.headerFontFamily')};

  @media ${({ theme }) => theme.mediumUp} {
    text-align: ${({ textAlignLeft }) => (textAlignLeft ? 'left' : 'center')};
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      text-align: center;
      margin: 0 0 10px 0;
      font-weight: 700;
      font-size: 20px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      margin: 0 0 15px 0;
      font-size: 25px;
      font-weight: 500;
      color: ${theme.primaryBackgroundDark ? 'white' : 'black'};

      @media ${theme.xlargeUp} {
        margin: 0 0 20px 0;
      }
    `}
`;

export const FeedbackQuestionContainer = styled.div`
  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      padding: 0 20px;
      @media ${theme.mediumUp} {
        padding: 0;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      padding: 0;
    `}
`;

export const FeedbackQuestion = styled.p`
  line-height: 1.3;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 400;
  margin: 0;

  @media ${({ theme }) => theme.mediumUp} {
    text-align: left;
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      text-align: center;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      color: ${theme.primaryBackgroundDark ? 'white' : 'black'};
    `}
`;

export const FeedbackMessage = styled.p`
  line-height: 1.3;
  box-sizing: border-box;
  transition: opacity 1s;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin: 0;
  
  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      color: ${theme.primaryBackgroundDark ? 'white' : 'black'};
    `}

  @media ${({ theme }) => theme.mediumUp} {
    text-align: center;
  }
`;

export const FeedbackErrorMessage = styled.p`
  line-height: 1.3;
  box-sizing: border-box;
  transition: opacity 1s;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin: 0 0 5px 0;
  color: #dd1010;

  @media ${({ theme }) => theme.mediumUp} {
    text-align: left;
  }
`;

export const FeedbackRatingWrapper = styled.div`
  width: 100%;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      display: flex;
      justify-content: center;
      @media ${theme.mediumUp} {
        width: 50%;
        justify-content: flex-end;
        align-items: center;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      @media ${theme.xlargeUp} {
        display: block;
      }
    `}
`;

export const FeedbackStarsContainer = styled.ul`
  text-decoration: none;
  display: flex;
  transition: all 2s;

  &:active {
    transition: all 2s;
    transform: scale(1.5);
  }
`;

export const FeedbackStar = styled.li<{ isActive: boolean }>`
  margin: 0;
  padding: 0 10px 0 0;
  font-size: 26px;
  transition: all 0.2s;
  background: none;
  cursor: pointer;
  outline: 0;

  &:last-child {
    padding: 0;
  }

  @font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/icomoon.ttf?m5zd6s') format('truetype'), url('/assets/fonts/icomoon.woff?m5zd6s') format('woff'),
      url('/assets/fonts/icomoon.svg?m5zd6s#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      @media ${theme.xlargeUp} {
        font-size: 30px;
      }
    `}

  &::before {
    /* stylelint-disable */
    font-family: 'icomoon';
    /* stylelint-enable */
    transition: all 0.25s;
    content: '\\e90a';
    font-weight: 100;
    background-color: transparent;
    color: ${getCustomStyle('styleModule.secondaryColor')};

    ${({ isActive }) =>
      isActive &&
      css`
        content: '\\e909' !important;
      `}
  }

  &:hover {
    transform: scale(1.3);
  }
`;

export interface OwnProps {
  isActive?: boolean;
}
