import { OrderItemType } from 'types/return/overview/overview-api-response-type';
import { NonReturnableReasonCode } from 'types/return/overview/products-selection/non-returnabled-code-enum';

export const isMilitaryAddress = (shippingAddress) => {
  if (
    shippingAddress?.mailingAddress?.stateOrProvince === 'AA' ||
    shippingAddress?.mailingAddress?.stateOrProvince === 'AE' ||
    shippingAddress?.mailingAddress?.stateOrProvince === 'AP'
  ) {
    return true;
  } else {
    return false;
  }
};
export const HazmatDataExist = (items: OrderItemType[]): boolean => {
  //items = (items as OrderItemType[]).map((item: OrderItemType) => ({ ...item, hazmat: null, hazmatType: null }));
  const isHazmatAvailable = items.filter((orderItem: OrderItemType) => orderItem?.hazmat === true);
  return isHazmatAvailable.length > 0 ? true : false;
};
export const itemsContainsMilitaryAddressAndHazmat = (items: OrderItemType[], shippingAddress): boolean => {
  if (isMilitaryAddress(shippingAddress) && HazmatDataExist(items) === true) {
    const returnable = items.filter(
      (orderItem: OrderItemType) =>
        orderItem.returnable === true && (!orderItem.nonReturnableReasonCode || orderItem.nonReturnableReasonCode == undefined)
    );
    const hazmat = items.filter(
      (orderItem: OrderItemType) =>
        orderItem.returnable === true &&
        (!orderItem.nonReturnableReasonCode || orderItem.nonReturnableReasonCode == undefined) &&
        orderItem?.hazmat === true
    );
    if (returnable.length > 0 && returnable.length == hazmat.length) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};

export const filterItemsForReturn = (items: OrderItemType[], isReturnable: boolean, shippingAddress?): OrderItemType[] => {
  //shippingAddress.mailingAddress.stateOrProvince = 'AE';
  let militaryAddress = false;
  if (isMilitaryAddress(shippingAddress)) {
    militaryAddress = true;
  }
  if (militaryAddress && HazmatDataExist(items) === true) {
    const allitemsAreRestricted = itemsContainsMilitaryAddressAndHazmat(items, shippingAddress);

    items = items.filter((orderItem: OrderItemType) =>
      isReturnable
        ? orderItem.returnable && orderItem?.hazmat === false
        : !orderItem.returnable || (orderItem.returnable && orderItem?.hazmat === true)
    );
    items.map((orderItem) => {
      if (orderItem?.hazmat === true && orderItem.returnable === true) {
        if (!allitemsAreRestricted) {
          orderItem.nonReturnableReasonCode = NonReturnableReasonCode.RESTRICTED_ADDRESS;
        } else {
          orderItem.nonReturnableReasonCode = NonReturnableReasonCode.RESTRICTED_ADDRESS_NO_REASON;
        }
        orderItem.returnable = false;
      }
    });
    return items;
  } else {
    return items.filter((orderItem: OrderItemType) => (isReturnable ? orderItem.returnable : !orderItem.returnable));
  }
};

export const itemsContainsReturnPeriodExpired = (items: OrderItemType[]): boolean => {
  return items.some(
    (orderItem: OrderItemType) => !orderItem.returnable && orderItem.nonReturnableReasonCode === NonReturnableReasonCode.RETURN_PERIOD_EXPIRES
  );
};
