export class SchedulePickupPayload {
  additionalInstruction: string | null;
  estimatedPackageWeight: number;
  locationPackage: string;
  phoneNumber: string;
  pickupAddress: PickupAddressPayload;
  weightUnit: string = 'LB';

  constructor(
    additionalInstruction: string | null,
    estimatedPackageWeight: number,
    locationPackage: string,
    phoneNumber: string,
    pickupAddress: PickupAddressPayload
  ) {
    this.additionalInstruction = additionalInstruction;
    this.estimatedPackageWeight = estimatedPackageWeight;
    this.locationPackage = locationPackage;
    this.phoneNumber = phoneNumber;
    this.pickupAddress = pickupAddress;
  }
}

export interface PickupAddressPayload {
  address1: string;
  address2: string | null;
  city: string;
  postalCode: string;
  stateOrProvince: string;
}
