import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import React, { useContext } from 'react';
import { LogoAlignedType } from 'types/shared/config/headerModule/header-module-type';
import { LogoWrapper, LogoLabel, LogoLink, LogoPicture } from './Logo.styled';

export const Logo: React.FC<OwnProps> = (props) => {
  const { logoAsText, logo, logoText, logoUrl, logoAligned, isNavExists } = props;
  const { brandName } = useContext(ClientConfigContext);

  return (
    <LogoWrapper data-test-id="header-logo">
      <LogoLabel data-test-id="logo-label">
        {logoAsText && logoText ? (
          <>
            {logoUrl ? (
              <LogoLink href={logoUrl} data-test-id="logo-url">
                <span data-test-id="logo-text" data-gtm-id="logo-text">
                  {logoText}
                </span>
              </LogoLink>
            ) : (
              <span data-test-id="logo-text" data-gtm-id="logo-text">
                {logoText}
              </span>
            )}
          </>
        ) : (
          <>
            {logoUrl ? (
              <LogoLink href={logoUrl} data-test-id="logo-url">
                <LogoPicture logoAligned={logoAligned} isNavExists={isNavExists} data-test-id="logo-picture">
                  <img src={`${logo}?w=300`} alt={`${brandName} logo`} title={logoText} data-test-id="logo-image" data-gtm-id="logo-image" />
                </LogoPicture>
              </LogoLink>
            ) : (
              <LogoPicture logoAligned={logoAligned} isNavExists={isNavExists} data-test-id="logo-picture">
                <img src={`${logo}?w=300`} alt={`${brandName} logo`} title={logoText} data-test-id="logo-image" data-gtm-id="logo-image" />
              </LogoPicture>
            )}
          </>
        )}
      </LogoLabel>
    </LogoWrapper>
  );
};

export interface OwnProps {
  logoAsText: boolean;
  logo?: string;
  logoText?: string;
  logoUrl: string | null;
  logoAligned: LogoAlignedType;
  isNavExists: boolean;
}
