import React, { useEffect } from 'react';
import { Container, UnpackIconContainer, Info } from './DropOffLocationEmptyState.styled';
import { useIntl } from 'react-intl';

export const DropOffLocationEmptyState: React.FC<OwnProps> = (props) => {
  const { isUnpackedBoxChecked } = props;
  const intl = useIntl();

  const handleChangeScroll = () => {
    const element = document.getElementById('dropoffwrapperid');
    if (element) element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
  };

  return (
    <>
      <Container>
        <UnpackIconContainer>
          <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
            <circle cx="32.5" cy="32.5156" r="32" fill="#D8DAE4" />
            <image x="14.5" y="14" width="36" height="36" href="/assets/img/search.svg" />
          </svg>
        </UnpackIconContainer>
        <Info>
          <h3>{intl.formatMessage({ id: 'RETURN.returnInfo.dropOffLocationsEmptyState.noLocFound' })}</h3>

          {!isUnpackedBoxChecked && (
            <p>
              {intl.formatMessage({ id: 'RETURN.returnInfo.dropOffLocationsEmptyState.noLoc10Mile' })} <br />
              <a onClick={handleChangeScroll} style={{ textDecoration: 'underline' }}>
                Change the address
              </a>
              {intl.formatMessage({ id: 'RETURN.returnInfo.dropOffLocationsEmptyState.addressChange' })}
            </p>
          )}

          {isUnpackedBoxChecked === true && (
            <p>
              {intl.formatMessage({ id: 'RETURN.returnInfo.dropOffLocationsEmptyState.noLocForUnpacked' })} <br />
              <a onClick={handleChangeScroll} style={{ textDecoration: 'underline' }}>
                Change the address
              </a>
              {intl.formatMessage({ id: 'RETURN.returnInfo.dropOffLocationsEmptyState.addressFilterChange' })}
            </p>
          )}
        </Info>
      </Container>
    </>
  );
};

export interface OwnProps {
  isUnpackedBoxChecked: boolean;
}
