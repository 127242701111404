import React from 'react';
import { PolicyLayout } from 'layouts/ConsumerConnectPolicy/PolicyLayout/PolicyLayout';
import { ConsumerConnectSMSTermsContainer, PolicyParagraph, PolicyTitle, TextWrapper } from './ConsumerConnectSMSTerms.styled';
import { useIntl } from 'react-intl';

export const ConsumerConnectSmsTerms = () => {
  const intl = useIntl();

  return (
    <PolicyLayout
      pageTitle={intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.page_title' })}
      title={intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.title' })}>
      <ConsumerConnectSMSTermsContainer>
        <TextWrapper>
          <PolicyTitle>{intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.acceptance_by_you.title' })}</PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.acceptance_by_you.paragraph1' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.acceptance_by_you.paragraph2' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>{intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.message_and_data_rates_may_apply.title' })}</PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.message_and_data_rates_may_apply.paragraph1' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>{intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.how_to_opt_out.title' })}</PolicyTitle>
          <PolicyParagraph>
            {intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.how_to_opt_out.paragraph1' }, { label: <b>STOP</b> })}
          </PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>{intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.requesting_help_or_more_information.title' })}</PolicyTitle>
          <PolicyParagraph>
            {intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.requesting_help_or_more_information.paragraph1' }, { label: <b>HELP</b> })}
          </PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>{intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.notification_messages_may_not_be_available.title' })}</PolicyTitle>
          <PolicyParagraph>
            {intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.notification_messages_may_not_be_available.paragraph1' })}
          </PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>{intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.changes_to_the_program_these_terms.title' })}</PolicyTitle>
          <PolicyParagraph>
            {intl.formatMessage({ id: 'POLICY.SMS_NOTIFICATION_TERMS.changes_to_the_program_these_terms.paragraph1' })}
          </PolicyParagraph>
        </TextWrapper>
      </ConsumerConnectSMSTermsContainer>
    </PolicyLayout>
  );
};
