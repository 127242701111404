import React, { useContext, useEffect, useState } from 'react';
import useFetch from 'hooks/useFetch/useFetch';
import { scrollToTop } from 'utils/returns/overview/common/scroll-to-top/scroll-to-top';
import { Error } from 'components/Shared/Error/Error';
import PageTitle from 'components/Shared/PageTitle/PageTitle';
import { Label } from 'components/Returns/ReturnInfo/Label/Label';
import { QRCode } from 'components/Returns/ReturnInfo/QRCode/QRCode';
import { ReturnInfoApiResponseErrorType, ReturnInfoApiResponseSuccessType } from 'types/return/return-info/return-info-api-response-type';
import { useSearchParams } from 'react-router-dom';
import { ReturnsLayout } from 'layouts/Returns/ReturnsLayout/ReturnsLayout';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { useIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LabelDetails } from 'components/Returns/Label/LabelDetails/LabelDetails';
import { QRCodeDetails } from 'components/Returns/QRCode/QRCodeDetails/QRCodeDetails';
import { ReturnInfoLayout } from 'layouts/Returns/ReturnInfoLayout/ReturnInfoLayout';
import { MethodEnum } from 'types/return/overview/methods/method-types';
import { ReturnInfoLoading } from './ReturnInfoLoading';
import { setReturnInfoApi } from 'utils/returns/returnInfo/common/set-return-info-api/set-return-info-api';
import { useLocation } from 'react-router-dom';
import { Location } from 'react-router-dom';

export const ReturnInfo: React.FC = () => {
  const [urlSearchParams] = useSearchParams();
  const intl = useIntl();
  const { returnsUiChangesForUspsLocationsCoco754 } = useFlags();

  const [orderNumber] = useState<string | null>(urlSearchParams.get('inputOrderId'));
  const [shippingPostalCode] = useState<string | null>(urlSearchParams.get('shippingPostalCode'));
  const [urlKey] = useState<string | null>(urlSearchParams.get('key'));
  const [labelId] = useState<string | null>(urlSearchParams.get('labelId'));
  const location: Location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const carrierName: any = location.state;

  const [pdfError, setPdfError] = useState<boolean>(false);

  const { brandName, vanityName } = useContext(ClientConfigContext);

  const RETURN_INFO_API = setReturnInfoApi(vanityName, orderNumber, shippingPostalCode, urlKey, labelId);

  const [returnInfoConfig, , error] = useFetch<ReturnInfoApiResponseSuccessType, ReturnInfoApiResponseErrorType>(RETURN_INFO_API);

  useEffect(() => {
    scrollToTop('auto');
  }, [carrierName]);

  if (error || !orderNumber || !labelId) return <Error />;
  if (!returnInfoConfig) return <ReturnInfoLoading />;

  if (returnsUiChangesForUspsLocationsCoco754)
    return (
      <ReturnsLayout
        title={returnInfoConfig.returnsModule.orderNumberTitle}
        orderNumber={orderNumber}
        policyParagraph={returnInfoConfig.returnsModule.returnsPolicyView.policyParagraph}
        policyParagraphLink={returnInfoConfig.returnsModule.returnsPolicyView.policyParagraphLink}>
        <PageTitle
          title={intl.formatMessage({
            id:
              returnInfoConfig.pdfContentLink || returnInfoConfig.imgLabelLink
                ? 'RETURN.returnInfo.label.page.title'
                : 'RETURN.returnInfo.qrCode.page.title'
          })}
          brandName={brandName}
        />
        <ReturnInfoLayout
          returnZipCode={returnInfoConfig.returnZipCode}
          methodType={returnInfoConfig.pdfContentLink || returnInfoConfig.imgLabelLink ? MethodEnum.Label : MethodEnum.QRCode}
          trackingPackageUrl={returnInfoConfig.trackingUrl}
          qrCodeId={returnInfoConfig.qrCodeId}
          carrierName={carrierName ? carrierName[1] : ''}>
          {(returnInfoConfig.pdfContentLink || returnInfoConfig.imgLabelLink) && (
            <LabelDetails
              details={returnInfoConfig}
              urlKey={urlKey}
              labelId={labelId}
              inputOrderId={orderNumber}
              shippingPostalCode={shippingPostalCode}
              carrierName={carrierName ? carrierName[1] : ''}
            />
          )}
          {returnInfoConfig.qrCode && (
            <QRCodeDetails qrCode={returnInfoConfig.qrCode} urlKey={urlKey} tagManagerDetails={returnInfoConfig.tagManagerModule} />
          )}
        </ReturnInfoLayout>
      </ReturnsLayout>
    );

  return (
    <ReturnsLayout
      title={returnInfoConfig.returnsModule.orderNumberTitle}
      orderNumber={orderNumber}
      policyParagraph={returnInfoConfig.returnsModule.returnsPolicyView.policyParagraph}
      policyParagraphLink={returnInfoConfig.returnsModule.returnsPolicyView.policyParagraphLink}>
      <PageTitle
        title={intl.formatMessage({
          id:
            returnInfoConfig.pdfContentLink || returnInfoConfig.imgLabelLink
              ? 'RETURN.returnInfo.label.page.title'
              : 'RETURN.returnInfo.qrCode.page.title'
        })}
        brandName={brandName}
      />
      {returnInfoConfig.pdfContentLink || returnInfoConfig.imgLabelLink ? (
        <Label
          pdfError={pdfError}
          setPdfError={setPdfError}
          labelId={labelId}
          inputOrderId={orderNumber}
          shippingPostalCode={shippingPostalCode}
          urlKey={urlKey}
          carrierName={carrierName ? carrierName[1] : ''}
          returnInfoConfig={returnInfoConfig}
        />
      ) : (
        <QRCode urlKey={urlKey} returnInfoConfig={returnInfoConfig} />
      )}
    </ReturnsLayout>
  );
};
