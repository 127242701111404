import React, { PropsWithChildren } from 'react';
import useFetch from 'hooks/useFetch/useFetch';
import { PickupConfigResponseSuccessType, PickupConfigResponseErrorType } from 'types/return/return-info/pickup-config-response-type';
import { setPickupLabelApi } from 'utils/returns/returnInfo/label/set-pickup-label-api/set-pickup-label-api';
import { LabelPickupSchedulerContent } from 'components/Returns/ReturnInfo/Label/LabelInfo/LabelPickupSchedulerContent/LabelPickupSchedulerContent';
import { PickupContextProvider } from 'context/returns/PickupContext';
import { Loading } from 'components/Shared/Loading/Loading';

export const LabelPickupScheduler: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { labelId, inputOrderId } = props;

  const PICKUP_LABEL_API = setPickupLabelApi(labelId);

  const [pickupConfig, , pickupError] = useFetch<PickupConfigResponseSuccessType, PickupConfigResponseErrorType>(PICKUP_LABEL_API);

  if (pickupError) return null;
  if (!pickupConfig) return <Loading height="72px" />;

  return (
    <PickupContextProvider
      initialPickupAddress={pickupConfig.pickupAddress}
      initialIsPickupExists={pickupConfig.isPickupExist}
      initialPhoneNumber={pickupConfig.phoneNumber}
      initialConfirmedPickupDate={pickupConfig.pickupDate}
      initialPickupCancelLink={pickupConfig.pickupCancelLink}
      initialConfirmationNumber={pickupConfig.confirmationNumber}>
      <LabelPickupSchedulerContent labelId={labelId} inputOrderId={inputOrderId} />
    </PickupContextProvider>
  );
};

export interface OwnProps {
  labelId: string;
  inputOrderId: string;
}
