import styled from '@emotion/styled';
export const PickupAddressSummaryContainer = styled.div`
  margin: 0 0 30px 0;
`;
export const PickupAddressSummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 2px 0;
`;
export const PickupAddressSummaryTitle = styled.h3`
  font-size: 14px;
  margin: 0;
  font-weight: 300;
  color: #4e4e4e;
`;
export const PickupAddressSummaryElement = styled.p`
  margin: 0 0 3px 0;
  font-size: 14px;
  color: #4e4e4e;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
