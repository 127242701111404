import { DropOffLocations } from 'components/Returns/Common/DropOffLocations/DropOffLocations';
import React from 'react';
import { useIntl } from 'react-intl';
import { MethodEnum } from 'types/return/overview/methods/method-types';
import {
  ReturnInfoLayoutContentBox,
  ReturnInfoLayoutDetailsWrapper,
  ReturnInfoLayoutInstructionWrapper,
  ReturnInfoLayoutSubheader,
  ReturnInfoLayoutWrapper
} from './ReturnInfoLayout.styled';
import { ReturnInfoPackageTracking } from './ReturnInfoPackageTracking/ReturnInfoPackageTracking';
import { ReturnsInstructions } from './ReturnsInstructions/ReturnsInstructions';
import { CarriersEnum } from 'types/return/overview/methods/method-types';

export const ReturnInfoLayout: React.FC<OwnProps> = (props) => {
  const { children, returnZipCode, methodType, trackingPackageUrl, qrCodeId, carrierName } = props;
  const intl = useIntl();
  return (
    <ReturnInfoLayoutWrapper data-test-id="return-info-layout-wrapper">
      <ReturnInfoLayoutSubheader>
        {intl.formatMessage({
          id: `RETURN.returnInfo.common.subheader.type.${methodType === MethodEnum.Label ? 'label' : 'qrCode'}`
        })}
      </ReturnInfoLayoutSubheader>
      <ReturnInfoLayoutContentBox>
        <ReturnInfoLayoutDetailsWrapper data-test-id="return-info-layout-details-wrapper">{children}</ReturnInfoLayoutDetailsWrapper>
        <ReturnInfoLayoutInstructionWrapper>
          <ReturnsInstructions methodType={methodType} carrierName={carrierName} />
        </ReturnInfoLayoutInstructionWrapper>
      </ReturnInfoLayoutContentBox>
      {carrierName?.toLowerCase() === CarriersEnum.USPS && <DropOffLocations returnZipCode={returnZipCode} />}
      <ReturnInfoPackageTracking methodType={methodType} trackingPackageUrl={trackingPackageUrl} qrCodeId={qrCodeId} />
    </ReturnInfoLayoutWrapper>
  );
};

export interface OwnProps {
  children: React.ReactNode;
  returnZipCode: string;
  methodType: MethodEnum;
  trackingPackageUrl: string;
  qrCodeId: string | null;
  carrierName?: string | undefined;
}
