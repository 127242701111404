import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const LabelInfoContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;

  @media ${({ theme }) => theme.mediumUp} {
    margin-bottom: 0;
    width: 51%;
  }

  @media ${({ theme }) => theme.largeUp} {
    width: 46%;
  }

  @media ${({ theme }) => theme.xlargeUp} {
    width: 39%;
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    width: 36%;
  }
`;

export const LabelInfoWrapper = styled.div`
  margin: 0 0 20px 0;
`;

export const LabelInfoTitle = styled.h4`
  margin: 0 0 15px 0;
  font-weight: normal;
  font-size: 18px;
  color: ${({ theme }) => theme.bodyFontColor};
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;
export const LabelInfoSubtitle = styled.p`
  color: ${({ theme }) => theme.bodyFontColor};
  font-size: 14px;
  margin: 0 0 50px 0;
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
`;
