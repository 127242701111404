import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

const TitleText = styled.h2`
  font-size: 18px;
  margin: 0 0 40px;
  padding-bottom: 8px;
  font-weight: normal;
  border-bottom: 1px solid ${({ theme }) => theme.returnsBorderColor};
  color: ${({ theme }) => theme.bodyFontColor};
  font-family: ${({ theme }) => getCustomStyle('styleModule.headerFontFamily', theme.primaryFontFamily)};
`;

export { TitleText };
