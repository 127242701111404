import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const ProductDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin: 50px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const ItemImageBox = styled.div`
  width: 140px;
  min-width: 140px;
`;

export const ContentBox = styled.div<{ displayImage: boolean }>`
  width: 100%;
  margin: ${({ displayImage }) => (displayImage ? '0 0 0 20px' : '0')};
`;

export const ContentText = styled.p<{ isBold?: boolean; margin?: string; isCapitalize?: boolean }>`
  font-size: 14px;
  margin: ${({ margin }) => (margin ? margin : 0)};
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  text-transform: ${({ isCapitalize }) => (isCapitalize ? 'capitalize' : 'none')};
`;

export const ContentAttributeName = styled.span`
  text-transform: none;
  font-weight: 300;
`;

export const ContentAttributeValue = styled.span`
  text-transform: none;
  font-weight: 700;
`;

export const ContentQuantityValue = styled.span`
  text-transform: none;
  font-weight: 700;
`;
