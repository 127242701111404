import { SelectedProducts } from 'context/returns/ProductsSelectionContext';
import { ExchangeProductType, SelectedProduct } from 'models/returns/selected-product/SelectedProduct';
import { isItemChecked } from 'utils/returns/overview/common/is-item-checked/is-item-checked';
import { isItemOptionSelected } from 'utils/returns/overview/common/is-item-option-selected/is-item-option-selected';

export const calculateSelectedProducts = (selectedProducts: SelectedProducts, option: 'exchange' | 'return'): number => {
  let amountOfProducts: number = 0;

  Object.values(selectedProducts).forEach((selectedProduct: SelectedProduct) => {
    if (isItemChecked(selectedProduct) && isItemOptionSelected(selectedProduct) && option === 'return' && selectedProduct.option === option)
      amountOfProducts += selectedProduct.quantity;
    if (isItemChecked(selectedProduct) && isItemOptionSelected(selectedProduct) && option === 'exchange' && selectedProduct.option === option) {
      Object.values(selectedProduct.exchangeProducts).forEach((exchangeProduct: ExchangeProductType) => {
        amountOfProducts += exchangeProduct.quantity;
      });
    }
  });

  return amountOfProducts;
};
