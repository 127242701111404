import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { rgba } from 'polished';

interface CustomLoadingButtonProps {
  width?: string;
  maxHeight?: string;
  marginDefault?: string;
  widthMd?: string;
  marginMd?: string;
  widthXl?: string;
  marginXl?: string;
  widthXXL?: string;
  marginXXL?: string;
  textAlignCenter?: boolean;
  fontSize?: string;
  padding?: string;
  isUppercase?: boolean;
  borderRadius?: string;
  transitionDuration?: string;
}

export const CustomLoadingButton = styled(LoadingButton, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== 'width' &&
    prop !== 'maxHeight' &&
    prop !== 'marginDefault' &&
    prop !== 'widthMd' &&
    prop !== 'marginMd' &&
    prop !== 'widthXl' &&
    prop !== 'marginXl' &&
    prop !== 'widthXXL' &&
    prop !== 'marginXXL' &&
    prop !== 'textAlignCenter' &&
    prop !== 'fontSize' &&
    prop !== 'padding' &&
    prop !== 'isUppercase' &&
    prop !== 'borderRadius' &&
    prop !== 'transitionDuration'
})<CustomLoadingButtonProps>(
  ({
    theme,
    width,
    maxHeight,
    marginDefault,
    widthMd,
    marginMd,
    widthXl,
    marginXl,
    widthXXL,
    marginXXL,
    isUppercase,
    loading,
    variant,
    fontSize,
    size,
    borderRadius,
    transitionDuration
  }) => ({
    width: width ? width : 'auto',
    maxHeight: maxHeight ? maxHeight : 'auto',

    margin: marginDefault ? marginDefault : '0',
    textTransform: isUppercase ? 'uppercase' : 'capitalize',
    transition: `all ${transitionDuration ? transitionDuration : '0.3s'} ease-in-out`,
    fontSize: fontSize ? fontSize : size === 'small' ? '13px' : size === 'large' ? '16px' : '14px',
    fontWeight: '400 !important',
    boxShadow: 'none',
    opacity: loading ? '0.6' : '1',
    backgroundColor: variant === 'text' || variant === 'outlined' ? 'transparent !important' : `${theme.palette.secondary.main} !important`,
    color:
      variant === 'text' || variant === 'outlined'
        ? theme.palette.secondary.main
        : theme.palette.button.secondaryBackgroundDark
        ? theme.palette.common.white
        : '#0000008A',

    '&:hover': {
      boxShadow: 'none',
      border: variant === 'outlined' ? `1px solid ${theme.palette.secondary.light} !important` : 'none',
      backgroundColor: variant === 'outlined' ? rgba(30, 136, 229, 0.08) : `${theme.palette.secondary.light} !important`,
      transition: 'all 0.3s ease-in-out',
      color:
        variant === 'text' || variant === 'outlined'
          ? theme.palette.secondary.main
          : theme.palette.button.secondaryBackgroundDark
          ? theme.palette.common.white
          : theme.palette.common.white
    },

    border: variant === 'outlined' ? `1px solid ${theme.palette.secondary.main} !important` : 'none',

    '& > div': {
      span: {
        color:
          variant === 'text' || variant === 'outlined'
            ? theme.palette.secondary.main
            : theme.palette.button.secondaryBackgroundDark
            ? theme.palette.common.white
            : theme.palette.common.black
      }
    },

    borderRadius: borderRadius
      ? borderRadius
      : theme.palette.button.buttonStyle === 'Rounded'
      ? '5px'
      : theme.palette.button.buttonStyle === 'Pill'
      ? '40px'
      : '0',

    [theme.breakpoints.up('medium')]: {
      width: widthMd && widthMd,
      margin: marginMd && marginMd
    },

    [theme.breakpoints.up('xlarge')]: {
      width: widthXl && widthXl,
      margin: marginXl && marginXl
    },

    [theme.breakpoints.up('xxlarge')]: {
      width: widthXXL && widthXXL,
      margin: marginXXL && marginXXL
    },

    '&.Mui-disabled': {
      opacity: 0.8,
      color:
        variant === 'text' || variant === 'outlined'
          ? theme.palette.secondary.main
          : theme.palette.button.secondaryBackgroundDark
          ? theme.palette.common.white
          : '#0000008A'
    },

    '&.Mui-disabled.MuiLoadingButton-loading': {
      color: 'transparent'
    }
  })
);
