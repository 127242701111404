import { FormHelperTextProps } from '@mui/material';
import { ReviewFormGroup } from 'components/Returns/Overview/Review/ReviewForm/ReviewForm.styled';
import { CustomCheckbox } from 'components/Shared/styled-components/CustomCheckbox/CustomCheckbox.styled';
import React, { ChangeEvent, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';
import { buildCheckOrderExistsApi } from 'utils/returns/search-order/build-check-order-exists-api/build-check-order-exists-api';
import { buildGetOrderAuthKeyAPI } from 'utils/customer-service/build-get-order-auth-key-api/build-get-order-auth-key-api';
import {
  Container,
  Title,
  AuthenticatedReturnsFormCustomFormControlLabel,
  AuthenticatedReturnsFormCustomLoadingButton
} from './AuthenticatedReturnsForm.styled';
import { CustomOrderNumberField } from 'types/shared/config/returnsModule/returns-module-type';
import { useIntl } from 'react-intl';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { CustomTextField } from 'components/Shared/styled-components/CustomTextField/CustomTextField.styled';
import { isFirstLetterVowel } from 'utils/shared/articles/articles';
import { isOrderNumberValid } from 'utils/shared/is-order-number-valid/is-order-number-valid';
import { isZipCodeValid } from 'utils/shared/zip-code-regex/zip-code-regex';
import { enhanceZipCodeValue } from 'utils/shared/enhance-zip-code-value/enhance-zip-code-value';
import { Alert } from 'components/Shared/Alert/Alert';
import { CheckOrderExistsApiResponseError, CheckOrderExistsApiResponseSuccess } from 'types/return/search-order/check-order-exists-api-response-type';
import { GetOrderAuthKeyApiResponseSuccess, GetOrderAuthKeyApiResponseError } from 'types/customer-service/get-order-auth-key-api-response-type';
import { initGoogleTagManagerDataLayer } from 'utils/common/google-tag-manager/google-tag-manager';
import { buildAuthReturnsRedirectUrl } from 'utils/customer-service/build-auth-returns-redirect-url/build-auth-returns-redirect-url';
import { getCookie } from 'utils/common/utils/utils';

export const AuthenticatedReturnsForm: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { isIntegrated, customOrderNumberField, checkIsExpired, setIsUserAuth } = props;

  const [orderNumber, setOrderNumber] = useState<string>('');
  const [orderNumberError, setOrderNumberError] = useState<string | null>(null);
  const [zipCode, setZipCode] = useState<string>('');
  const [zipCodeError, setZipCodeError] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<null | string>('');
  const [displayZipCodeTextField, setDisplayZipCodeTextField] = useState<boolean>(isIntegrated);
  const intl = useIntl();
  const { vanityName, brandId, template, merchantId } = useContext(ClientConfigContext);

  const getOrderAuthKey = async (vanityName: string, brandId: string, orderNumber: string): Promise<void> => {
    const GET_ORDER_AUTH_KEY_API: string = buildGetOrderAuthKeyAPI(vanityName, brandId, orderNumber);

    const headers = {
      'X-BRAND-ID': brandId,
      'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty',
      'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
    };

    const init: RequestInit = {
      method: 'GET',
      headers
    };

    try {
      const response = await fetch(GET_ORDER_AUTH_KEY_API, init);
      const data: GetOrderAuthKeyApiResponseSuccess | GetOrderAuthKeyApiResponseError = await response.json();

      // @ts-ignore
      if (data.errorTitle) {
        // @ts-ignore
        return setFormError(intl.formatMessage({ id: data.errorTitle }));
      }
      // @ts-ignore
      window.open(buildAuthReturnsRedirectUrl(vanityName, orderNumber, !!zipCode ? zipCode : null, data.urlKey), 'blank');
    } catch (e) {
      setFormError(intl.formatMessage({ id: 'RETURN.getAuthKey.error.serverError' }));
    } finally {
      setIsLoading(false);
    }
  };

  const checkOrderExists = async (isIntegrated: boolean, vanityName: string, brandId: string, orderNumber: string, zipCode: string | null) => {
    const CHECK_ORDER_EXISTS_API: string = buildCheckOrderExistsApi(isIntegrated, vanityName, brandId, orderNumber, zipCode);

    const headers = {
      'X-BRAND-ID': brandId,
      'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty',
      'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
    };

    const init: RequestInit = {
      method: 'GET',
      headers
    };

    try {
      setIsLoading(true);
      setFormError(null);

      const response = await fetch(CHECK_ORDER_EXISTS_API, init);
      const data: CheckOrderExistsApiResponseSuccess | CheckOrderExistsApiResponseError = await response.json();

      // @ts-ignore
      if (data.errorTitle) {
        setIsLoading(false);

        // @ts-ignore
        return setFormError(intl.formatMessage({ id: data.errorTitle }));
      }
      await getOrderAuthKey(vanityName, brandId, orderNumber);
    } catch (e) {
      setFormError(intl.formatMessage({ id: 'RETURN.searchOrder.form.error.serverError' }));
      setIsLoading(false);
    }
  };

  const handleToggleRMAReturn = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { checked } = target;

    setDisplayZipCodeTextField(checked);
    setZipCode('');
    setFormError(null);
    setZipCodeError(null);
  };

  const handleChangeOrderNumber = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;

    setOrderNumber(value);

    if (value.length === 0)
      return setOrderNumberError(
        intl.formatMessage(
          { id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.orderNumber.textfield.error.empty' },
          { article: isFirstLetterVowel(customOrderNumberField.labelValue), fieldValue: customOrderNumberField.labelValue.toLowerCase() }
        )
      );
    if (!isOrderNumberValid(value, customOrderNumberField.allowDashes))
      return setOrderNumberError(
        intl.formatMessage(
          { id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.orderNumber.textfield.error.regex' },
          { article: isFirstLetterVowel(customOrderNumberField.labelValue), fieldValue: customOrderNumberField.labelValue.toLowerCase() }
        )
      );
    if (value.length < 3)
      return setOrderNumberError(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.orderNumber.textfield.error.tooShort' })
      );
    if (value.length > customOrderNumberField.maxLengthValidation)
      return setOrderNumberError(
        intl.formatMessage(
          { id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.orderNumber.textfield.error.tooLong' },
          { maxLength: customOrderNumberField.maxLengthValidation }
        )
      );
    setOrderNumberError(null);
  };

  const handleChangeZipCode = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;

    setZipCode(enhanceZipCodeValue(value));

    if (enhanceZipCodeValue(value).length === 0)
      return setZipCodeError(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.zipCode.textfield.error.empty' })
      );
    if (!isZipCodeValid(enhanceZipCodeValue(value)))
      return setZipCodeError(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.zipCode.textfield.error.regex' })
      );
    setZipCodeError(null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const localStorageKey: string = 'expiresAt';
    const expiresObject: string | null = localStorage.getItem(localStorageKey);
    if (!!expiresObject) {
      const expiredAtValue: number = JSON.parse(expiresObject).expiresAt;
      if (checkIsExpired(expiredAtValue)) {
        initGoogleTagManagerDataLayer(
          null,
          null,
          merchantId,
          vanityName,
          null,
          'Customer Service',
          'trackPageview',
          'Non-integrated',
          template,
          false,
          null,
          brandId
        );
        return setIsUserAuth(false);
      }
    }
    const errorsArray: string[] = [];

    if (orderNumber.length === 0) {
      errorsArray.push(
        intl.formatMessage(
          { id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.orderNumber.textfield.error.empty' },
          { article: isFirstLetterVowel(customOrderNumberField.labelValue), fieldValue: customOrderNumberField.labelValue.toLowerCase() }
        )
      );
      setOrderNumberError(
        intl.formatMessage(
          { id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.orderNumber.textfield.error.empty' },
          { article: isFirstLetterVowel(customOrderNumberField.labelValue), fieldValue: customOrderNumberField.labelValue.toLowerCase() }
        )
      );
    }

    if (zipCode.length === 0 && displayZipCodeTextField) {
      errorsArray.push(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.zipCode.textfield.error.empty' })
      );
      setZipCodeError(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.zipCode.textfield.error.empty' })
      );
    }

    if (errorsArray.length > 0 || !!orderNumberError || !!zipCodeError) return;

    await checkOrderExists(displayZipCodeTextField, vanityName, brandId, orderNumber, displayZipCodeTextField ? zipCode : null);
  };

  return (
    <Container
      noValidate
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(event)}
      data-test-id="authenticated-returns-form"
      aria-label="authenticated-returns-form"
      displayZipCodeTextField={displayZipCodeTextField}>
      <ReviewFormGroup justifyContent="space-between" margin="0 0 20px 0">
        <Title data-test-id="authenticated-returns-form__title">
          {intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.title' })}
        </Title>
        {isIntegrated && (
          <AuthenticatedReturnsFormCustomFormControlLabel
            control={
              <CustomCheckbox
                data-test-id="authenticated-returns-form__rma-return-checkbox"
                disabled={isLoading}
                inputProps={{ 'data-gtm-id': 'authenticated-returns-return-method-checkbox' } as React.InputHTMLAttributes<HTMLInputElement>}
                size="small"
                color="secondary"
                checked={displayZipCodeTextField}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleToggleRMAReturn(event)}
              />
            }
            label={intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.rmaReturn.checkbox.label' })}
          />
        )}
      </ReviewFormGroup>
      <ReviewFormGroup justifyContent="space-between" alignItems="flex-start">
        <CustomTextField
          disabled={isLoading}
          placeholder={intl.formatMessage(
            { id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.orderNumber.textfield.placeholder' },
            { fieldValue: customOrderNumberField.labelValue }
          )}
          name="orderNumber"
          data-test-id="authenticated-returns-form__order-number-text-field"
          width="150px"
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeOrderNumber(event)}
          value={orderNumber}
          helperText={!!orderNumberError ? orderNumberError : ''}
          error={!!orderNumberError}
          inputProps={{ 'data-test-id': 'authenticated-returns-form__order-number-text-field-input' }}
          FormHelperTextProps={
            {
              'data-test-id': 'authenticated-returns-form__order-number-text-field-error',
              sx: { fontSize: '12px', lineHeight: '1.4' }
            } as FormHelperTextProps
          }
        />
        {displayZipCodeTextField && (
          <CustomTextField
            disabled={isLoading}
            value={zipCode}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeZipCode(event)}
            helperText={!!zipCodeError ? zipCodeError : ''}
            data-test-id="authenticated-returns-form__zip-code-text-field"
            error={!!zipCodeError}
            marginXXL="0 0 0 20px"
            marginDefault="0 0 0 15px"
            width="150px"
            placeholder={intl.formatMessage({
              id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.zipCode.textfield.placeholder'
            })}
            name="zipCode"
            inputProps={{ 'data-test-id': 'authenticated-returns-form__zip-code-text-field-input' }}
            FormHelperTextProps={
              {
                'data-test-id': 'authenticated-returns-form__zip-code-text-field-error',
                sx: { fontSize: '12px', lineHeight: '1.4' }
              } as FormHelperTextProps
            }
          />
        )}
        <AuthenticatedReturnsFormCustomLoadingButton
          disabled={isLoading}
          loading={isLoading}
          type="submit"
          data-test-id="authenticated-returns-form__submit-button"
          data-gtm-id="authenticated-returns-button"
          width="150px"
          maxHeight="100%"
          marginDefault="0 0 0 15px"
          marginXXL="0 0 0 20px">
          {displayZipCodeTextField
            ? intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.integrated.submitButton' })
            : intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsForm.nonIntegrated.submitButton' })}
        </AuthenticatedReturnsFormCustomLoadingButton>
      </ReviewFormGroup>
      {!!formError && <Alert marginSm=" 20px 0 0 0" iconSize="22px" message={formError} />}
    </Container>
  );
};

export interface OwnProps {
  isIntegrated: boolean;
  checkIsExpired: (param: number) => boolean;
  setIsUserAuth: Dispatch<SetStateAction<boolean>>;
  customOrderNumberField: CustomOrderNumberField;
}
