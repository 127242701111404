export const isZipCodeFieldRequired = (
  zipCodeValue: string,
  stateValue: string,
  cityValue: string,
  isRequiredValidationEnabled: boolean
): string | boolean =>
  !isRequiredValidationEnabled ||
  !!zipCodeValue ||
  (!!stateValue && !!cityValue) ||
  'RETURN.returnInfo.common.dropOffLocations.inputs.zipCode.validation.empty';
