import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { ReviewContainer } from './Review.styled';
import { ReviewForm } from './ReviewForm/ReviewForm';
import PageTitle from 'components/Shared/PageTitle/PageTitle';
import { scrollToTop } from 'utils/returns/overview/common/scroll-to-top/scroll-to-top';
import { OverviewApiResponseSuccessType } from 'types/return/overview/overview-api-response-type';
import { initGoogleTagManagerDataLayer } from '../../../../utils/common/google-tag-manager/google-tag-manager';
import { ClientConfigContext } from '../../../../context/shared/ClientConfigContext';
import { useIntl } from 'react-intl';
import { ProductsSelectionContext } from 'context/returns/ProductsSelectionContext';

export const Review: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { overviewConfig, orderNumber, shippingPostalCode } = props;

  const intl = useIntl();

  const { merchantId, vanityName, template, brandName, brandId } = useContext(ClientConfigContext);
  const { selectedProducts } = useContext(ProductsSelectionContext);
  const returntitle = intl.formatMessage({ id: 'RETURN.overview.review.page.returnTitle' });
  const exchangetitle = intl.formatMessage({ id: 'RETURN.overview.review.page.exchangeTitle' });
  const inialtitle = intl.formatMessage({ id: 'RETURN.overview.review.page.initialTitle' });

  useEffect(() => {
    scrollToTop('auto');
  }, []);

  useEffect(() => {
    function modifyText(new_text) {
      const t2 = document.getElementById('titlereturn');
      if (t2) {
        t2.textContent = new_text;
      }
    }

    const element1 = document.querySelector<HTMLElement>('.reviewcontainer')!;

    if (element1) {
      Object.keys(selectedProducts).forEach((product) => {
        if (Object.keys(selectedProducts).length === 1) {
          if (selectedProducts[product]?.option?.includes('return')) {
            modifyText(returntitle);
          } else if (selectedProducts[product]?.option?.includes('exchange')) {
            modifyText(exchangetitle);
          }
        } else if (Object.keys(selectedProducts).length > 1) {
          const populationArr = Object.values(selectedProducts);
          const options = populationArr.map((item) => item.option);
          const unique = options.map((item) => item).filter((value, index, self) => self.indexOf(value) === index);
          if (unique.length === 1) {
            if (unique.includes('return')) {
              modifyText(returntitle);
            } else if (unique.includes('exchange')) {
              modifyText(exchangetitle);
            }
          } else if (unique.length > 1) {
            modifyText(inialtitle);
          }
        }
      });
    }
  });

  useEffect(() => {
    if (overviewConfig) {
      return initGoogleTagManagerDataLayer(
        overviewConfig.tagManagerModule.deliveryType,
        overviewConfig.tagManagerModule.trackingStatus,
        merchantId,
        vanityName,
        null,
        'Return Overview',
        'returnPageview',
        overviewConfig.returnsModule.clientReturnType === 'integrated' ? 'RMA' : 'Non-integrated',
        template,
        !!overviewConfig.urlKey,
        null,
        brandId
      );
    }
  }, [overviewConfig, merchantId, vanityName, template, brandId]);

  return (
    <ReviewContainer data-test-id="review-step-container" className="reviewcontainer">
      <PageTitle title={intl.formatMessage({ id: 'RETURN.overview.review.page.title' })} brandName={brandName} />
      <ReviewForm
        notificationsModule={overviewConfig.notificationsModule}
        returnsModule={overviewConfig.returnsModule}
        clientReturnType={overviewConfig.returnsModule.clientReturnType}
        orderNumber={orderNumber}
        shippingPostalCode={shippingPostalCode}
      />
    </ReviewContainer>
  );
};

export interface OwnProps {
  overviewConfig: OverviewApiResponseSuccessType;
  orderNumber: string;
  shippingPostalCode: string | null;
}
