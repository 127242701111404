import { PBIcomoonIconsEnum } from 'constants/PBIconsEnums';

export enum SocialLinkNameEnum {
  Instagram = 'instagram',
  Facebook = 'facebook',
  Pinterest = 'pinterest',
  Tumblr = 'tumblr',
  Twitter = 'twitter',
  Youtube = 'youtube'
}

export const socialLinkNameToIconNameMapper = (
  socialLinkName: string
):
  | PBIcomoonIconsEnum.Instagram
  | PBIcomoonIconsEnum.Facebook
  | PBIcomoonIconsEnum.Pinterest
  | PBIcomoonIconsEnum.Tumblr
  | PBIcomoonIconsEnum.Twitter
  | PBIcomoonIconsEnum.Youtube
  | PBIcomoonIconsEnum.X => {
  switch (socialLinkName) {
    case SocialLinkNameEnum.Instagram:
      return PBIcomoonIconsEnum.Instagram;
    case SocialLinkNameEnum.Facebook:
      return PBIcomoonIconsEnum.Facebook;
    case SocialLinkNameEnum.Pinterest:
      return PBIcomoonIconsEnum.Pinterest;
    case SocialLinkNameEnum.Tumblr:
      return PBIcomoonIconsEnum.Tumblr;
    case SocialLinkNameEnum.Twitter:
      return PBIcomoonIconsEnum.Twitter;
    case SocialLinkNameEnum.Youtube:
      return PBIcomoonIconsEnum.Youtube;
    default:
      return PBIcomoonIconsEnum.X;
  }
};
