import React, { PropsWithChildren } from 'react';
import { AppErrorContainer, ErrorBox, ErrorBoxMessage, ErrorMessage, ErrorTip } from './AppError.styled';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { getFavIcons } from 'utils/common/favicons/favicons';
import { FaviconsModule } from 'types/tracking-types';
import { resolveAppErrorPageTitle } from 'utils/app/app-error/resolve-app-error-page-title/resolve-app-error-page-title';
import { resolveAppErrorMessage } from 'utils/app/app-error/resolve-app-error-message/resolve-app-error-message';
import { resolveAppErrorTip } from 'utils/app/app-error/resolve-app-error-tip/resolve-app-error-tip';
import PageTitle from 'components/Shared/PageTitle/PageTitle';

export const AppError: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { status } = props;
  const intl = useIntl();

  return (
    <AppErrorContainer data-test-id="app-error-container">
      <PageTitle title={intl.formatMessage({ id: resolveAppErrorPageTitle(status) })} brandName={null} />
      <Helmet>{getFavIcons({ useDefault: true } as FaviconsModule)}</Helmet>
      <ErrorBox>
        <ErrorBoxMessage>
          <ErrorMessage data-test-id="error-message">{intl.formatMessage({ id: resolveAppErrorMessage(status) })}</ErrorMessage>
          <ErrorTip data-test-id="error-tip-message">{intl.formatMessage({ id: resolveAppErrorTip(status) })}</ErrorTip>
        </ErrorBoxMessage>
      </ErrorBox>
    </AppErrorContainer>
  );
};

export interface OwnProps {
  status: number | 400 | 404 | 500 | 503;
}
