import styled from '@emotion/styled';

export const PostOfficeListItemContainer = styled.li`
  font-size: 14px;
  margin-bottom: 15px;
`;

export const PostOfficeItemTitle = styled.h5`
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 5px 0;
`;

export const HoursWrapper = styled.div`
  display: block;

  @media ${({ theme }) => theme.mediumUp} {
    display: flex;
  }
`;

export const HoursList = styled.ul``;

export const HoursListTitle = styled.p`
  margin: 0 0 5px 0;
  font-size: 14px;

  @media ${({ theme }) => theme.mediumUp} {
    margin: 0 10px 0 0;
  }
`;
