import React from 'react';
import { ExportButton } from './ExportCSVButton.styled';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBOutlineIconsEnum } from 'constants/PBIconsEnums';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { prepareDataToCSVExport } from 'utils/customer-service/prepare-data-to-csv-export/prepare-data-to-csv-export';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

export const ExportCSVButton = (props: OwnProps) => {
  const { parcels } = props;
  const filename = dayjs().format('YYYY-MM-DDTHH:mm:ss') + '.csv';

  return (
    <CSVLink filename={filename} data={prepareDataToCSVExport(parcels)} separator=";" data-test-id="cs-export-csv-link" data-gtm-id="export-csv">
      <ExportButton>
        <PBIcon type={PBIconTypeEnum.Outline} name={PBOutlineIconsEnum.Export} aria-label="cs-export-csv-icon" />
      </ExportButton>
    </CSVLink>
  );
};

export interface OwnProps {
  parcels: Parcel[];
}
