import React, { PropsWithChildren } from 'react';
import { BannerContainer, BannerIconContainer, BannerList, BannerListItem, BannerTextContainer, BannerTitle, BannerWrapper } from '../Banner.styled';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import useFetch from 'hooks/useFetch/useFetch';
import { WeatherAlert } from 'types/shared/weather-alert-types';
import { DefaultErrorResponse } from 'hooks/useFetch/useFetch';
import { PBOutlineIconsEnum } from 'constants/PBIconsEnums';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';

export const WeatherBanner: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { postalCode } = props;
  const WEATHER_API = `/weather/api/v1/data/${postalCode ? postalCode : undefined}`;
  const [weatherAlertData, , error] = useFetch<WeatherAlert, DefaultErrorResponse>(WEATHER_API);

  if (error) return null;
  if ((weatherAlertData && !weatherAlertData.title && weatherAlertData.alerts.length === 0) || !weatherAlertData) return null;
  return (
    <BannerContainer data-test-id="banner-container">
      <BannerWrapper data-test-id="banner-wrapper">
        <BannerIconContainer data-test-id="banner-icon-container">
          <PBIcon type={PBIconTypeEnum.Outline} name={PBOutlineIconsEnum.WarningTriangle} />
        </BannerIconContainer>
        <BannerTextContainer data-test-id="banner-text-container">
          {weatherAlertData.title && <BannerTitle data-test-id="banner-title">{weatherAlertData.title}</BannerTitle>}
          {weatherAlertData.alerts && weatherAlertData.alerts.length !== 0 && (
            <BannerList data-test-id="banner-list">
              {weatherAlertData.alerts.map((textObject, index) => (
                <BannerListItem key={index}>{textObject.message}</BannerListItem>
              ))}
            </BannerList>
          )}
        </BannerTextContainer>
      </BannerWrapper>
    </BannerContainer>
  );
};

export interface OwnProps {
  postalCode: string;
}
