import styled from '@emotion/styled';

export const SchedulePickupStepsContainer = styled.div`
  max-width: 530px;
  width: 100%;
  max-height: 99vh;
  background: #ffffff;
  border: 1px solid #d8dae4;
  box-shadow: 1px 0 8px rgba(113, 113, 113, 0.1);
  border-radius: 6px;
  overflow-y: auto;
`;

export const SchedulePickupStepsWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 55px 25px;
  height: auto;
  transition: height 0.5s ease-out;
  @media ${({ theme }) => theme.mediumUp} {
    padding: 40px 45px;
  }
`;

export const CloseModalButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  i {
    font-weight: 300;
    font-size: 20px;
  }

  @media ${({ theme }) => theme.mediumUp} {
    display: none;
  }
`;

export const SchedulePickupTitle = styled.h3`
  font-size: 24px;
  font-weight: 400;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #9b9b9b;
  color: #4e4e4e;
  margin: 0 0 30px;
`;
