import { SearchBy } from 'types/customer-service/search-by-type';
import queryString from 'query-string';
import { getApiHostURL } from 'utils/common/get-api-host-url/get-api-host-url';
import { ApiHostTypeEnum } from 'types/shared/config/api-host-type-enum';

export const buildFindParcelsApi = (searchBy: SearchBy) => {
  const FIND_PARCELS_API: string = `${getApiHostURL(ApiHostTypeEnum.SEARCH_SERVICE_API)}/api/search/find-parcels`;
  return queryString.stringifyUrl({
    url: FIND_PARCELS_API,
    query: {
      searchBy: searchBy
    }
  });
};
