import React, { useState, useMemo, ReactNode, Dispatch, SetStateAction } from 'react';

export const PtsSwitchContext = React.createContext<PtsSwitchContextType>({
  consumerViewOnly: false,
  setConsumerViewOnly: () => {}
});

export const PtsSwitchProvider: React.FC<PtsSwitchProviderType> = (props) => {
  const { children } = props;

  const [consumerViewOnly, setConsumerViewOnly] = useState<boolean>(false);

  const value = useMemo<PtsSwitchContextType>(
    () => ({
      consumerViewOnly,
      setConsumerViewOnly
    }),
    [consumerViewOnly]
  );

  return <PtsSwitchContext.Provider value={value}>{children}</PtsSwitchContext.Provider>;
};

export interface PtsSwitchProviderType {
  children: ReactNode;
}

export interface PtsSwitchContextType {
  consumerViewOnly: boolean;
  setConsumerViewOnly: Dispatch<SetStateAction<boolean>>;
}
