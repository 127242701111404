import styled from '@emotion/styled';

export const ScheduledPickupConfirmationContainer = styled.div``;

export const ScheduledPickupConfirmationTitle = styled.h2<{ areConfirmedAdditionalInstructionsExist: boolean }>`
  font-size: 14px;
  color: #4e4e4e;
  font-weight: ${({ areConfirmedAdditionalInstructionsExist }) => (areConfirmedAdditionalInstructionsExist ? 600 : 300)};
  margin: 0 0 30px 0;
`;

export const ConfirmedPickupDetailsBox = styled.div`
  display: flex;
  margin: 0 0 30px 0;
`;

export const ConfirmedDate = styled.p`
  font-weight: 400;
  font-size: 36px;
  color: #000000;
  margin: 0;
  width: 50%;

  @media ${({ theme }) => theme.mediumUp} {
    width: 35%;
  }
`;

export const ConfirmedLocationDetailsBox = styled.div`
  width: 50%;

  @media ${({ theme }) => theme.mediumUp} {
    width: 65%;
  }
`;

export const ConfirmedLocationElement = styled.p`
  color: #4e4e4e;
  font-size: 14px;
  font-weight: 400;
  margin: 0;

  &:nth-of-type(3) {
    margin: 0 0 20px 0;
  }

  &:last-child {
    margin: 0;
  }
`;

export const ScheduledPickupConfirmationDetails = styled.p`
  color: #4e4e4e;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 40px 0;
`;

export const ButtonsContainer = styled.div``;
