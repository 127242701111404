import styled from '@emotion/styled';

export const QRCodeContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-self: stretch;
  justify-content: center;

  @media ${({ theme }) => theme.largeUp} {
    width: 40%;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const QRCodeContentWrapper = styled.div`
  position: relative;
  width: 280px;
  height: 280px;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

export const QRCodeImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  max-width: 350px;
  max-height: 350px;
`;
