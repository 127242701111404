import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Collapse from '@mui/material/Collapse';
import moment from 'moment';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { TrackingContainer, TrackingEventsButton, TrackingEventsButtonWrapper, TrackingEventsList } from './TrackingEvents.styled';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { TrackingEventsListItem } from './TrackingEventsListItem/TrackingEventsListItem';
import { TrackingButton } from '../Common/Buttons/TrackingButtons.styled';
import { TemplateEnum } from 'types/shared/template-type';
import { TrackingEventsModuleType } from 'types/tracking/trackingConfig/trackingEventsModule/tracking-events-module-type';
import { ClientConfigContext } from '../../../context/shared/ClientConfigContext';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PBMiniIconsEnum } from 'constants/PBIconsEnums';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';

export const TrackingEvents: React.FC<OwnProps> = (props) => {
  const { trackingEventsModule } = props;
  const { trackingEvents } = trackingEventsModule;

  const [showMore, setShowMore] = useState(false);
  const [seeButton, setSeeButton] = useState(false);
  const { template } = useContext(ClientConfigContext);
  const theme = useTheme();
  const xlargeUp = useMediaQuery(theme.breakpoints.up('xlarge'));
  const intl = useIntl();
  const COLLAPSE_TIMEOUT: number = 300; // miliseconds
  let timeoutId: NodeJS.Timeout;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackingEventsFirst3Items = trackingEvents.slice(0, 3);
  const trackingEventsNextItems = trackingEvents.slice(3);
  const handleShowMore = (showMoreValue) => {
    setShowMore(!showMoreValue);
    timeoutId = setTimeout(() => {
      setSeeButton(!showMoreValue);
    }, COLLAPSE_TIMEOUT);
  };

  return (
    <SectionLayout
      data-test-id="tracking-events-container"
      marginDefault="0 0 40px 0"
      paddingXl={template === TemplateEnum.SPARTAN ? '0' : ''}
      paddingXXl={template === TemplateEnum.SPARTAN ? '0' : ''}
      backgroundAsColor={false}>
      <TrackingContainer data-test-id="tracking-events-container">
        {trackingEvents && trackingEvents.length !== 0 && (
          <TrackingEventsList data-test-id="tracking-events-list">
            {trackingEventsFirst3Items.map((trackingEvent, index) => (
              <TrackingEventsListItem
                key={index}
                date={trackingEvent.date}
                hour={moment(trackingEvent.hour, 'hh:mm A').format('h:mm A')}
                description={trackingEvent.description}
                location={trackingEvent.location}
                trackingEventsLength={trackingEvents.length}
              />
            ))}
            {trackingEvents.length > 3 && (
              <Collapse in={showMore} unmountOnExit timeout={COLLAPSE_TIMEOUT} data-test-id="tracking-events__collapse-list-wrapper">
                {trackingEventsNextItems.map((trackingEvent, index) => (
                  <TrackingEventsListItem
                    key={index}
                    date={trackingEvent.date}
                    hour={moment(trackingEvent.hour, 'hh:mm A').format('h:mm A')}
                    description={trackingEvent.description}
                    location={trackingEvent.location}
                    trackingEventsLength={trackingEvents.length}
                  />
                ))}
              </Collapse>
            )}

            {trackingEvents.length > 3 && (
              <TrackingEventsButtonWrapper data-test-id="tracking-events-button-wrapper">
                {template === TemplateEnum.VIVID && (
                  <TrackingEventsButton
                    data-gtm-id={seeButton ? 'tracking-show-less' : 'tracking-show-all'}
                    data-test-id="tracking-events-button"
                    onClick={() => handleShowMore(showMore)}>
                    {intl.formatMessage({ id: seeButton ? 'TRACKING.trackingEvents.header.seeLess' : 'TRACKING.trackingEvents.header.seeAll' })}
                  </TrackingEventsButton>
                )}
                {!(template === TemplateEnum.VIVID) && !xlargeUp && (
                  <TrackingButton
                    width="600px"
                    onClick={() => handleShowMore(showMore)}
                    data-test-id="tracking-button"
                    paddingDefault="12px 0"
                    fontSize="14px"
                    fontWeight="700"
                    data-gtm-id={seeButton ? 'tracking-show-less' : 'tracking-show-all'}>
                    {seeButton
                      ? intl.formatMessage({ id: 'TRACKING.trackingEvents.hide.label' })
                      : intl.formatMessage({ id: 'TRACKING.trackingEvents.show.label' })}
                  </TrackingButton>
                )}

                {!(template === TemplateEnum.VIVID) && xlargeUp && (
                  <TrackingEventsButton
                    data-gtm-id={seeButton ? 'tracking-show-less' : 'tracking-show-all'}
                    data-test-id="tracking-events-button"
                    onClick={() => handleShowMore(showMore)}>
                    {intl.formatMessage({ id: seeButton ? 'TRACKING.trackingEvents.hide.label' : 'TRACKING.trackingEvents.show.label' })}
                    <PBIcon type={PBIconTypeEnum.Mini} name={seeButton ? PBMiniIconsEnum.MinimalUp : PBMiniIconsEnum.MinimalRight} />
                  </TrackingEventsButton>
                )}
              </TrackingEventsButtonWrapper>
            )}
          </TrackingEventsList>
        )}
      </TrackingContainer>
    </SectionLayout>
  );
};

export interface OwnProps {
  trackingEventsModule: TrackingEventsModuleType;
}
