import React from 'react';
import { PolicyHeaderContainer, PolicyHeaderWrapper, LogoContainer, LogoImage } from './PolicyHeader.styled';

export const PolicyHeader = () => {
  return (
    <PolicyHeaderContainer>
      <PolicyHeaderWrapper>
        <LogoContainer>
          <LogoImage src="/assets/img/pb/pb-logo.svg" />
        </LogoContainer>
      </PolicyHeaderWrapper>
    </PolicyHeaderContainer>
  );
};
