import React, { PropsWithChildren } from 'react';
import {
  NoLabelImageContainer,
  NoLabelImageParagraph,
  NoLabelImageSubtitle,
  NoLabelImageTitle,
  LabelEmailInputWrapper,
  NoLabelImageWrapper
} from './NoLabelImage.styled';
import { LabelEmailInput } from '../LabelEmailInput/LabelEmailInput';
import { useIntl } from 'react-intl';

export const NoLabelImage: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { sendEmailUrl } = props;

  const intl = useIntl();

  return (
    <NoLabelImageContainer data-test-id="no-label-image-container">
      <NoLabelImageWrapper>
        <NoLabelImageTitle data-test-id="no-label-image-title">
          {intl.formatMessage({ id: 'RETURN.returnInfo.label.noLabelImage.title' })}
        </NoLabelImageTitle>
        <NoLabelImageSubtitle data-test-id="no-label-image-subtitle">
          {intl.formatMessage({ id: 'RETURN.returnInfo.label.noLabelImage.subtitle' })}
        </NoLabelImageSubtitle>
        <LabelEmailInputWrapper>
          <LabelEmailInput sendEmailUrl={sendEmailUrl} />
        </LabelEmailInputWrapper>
        <NoLabelImageParagraph data-test-id="no-label-image-paragraph-1">
          {intl.formatMessage({ id: 'RETURN.returnInfo.label.noLabelImage.paragraph1' })}
        </NoLabelImageParagraph>
        <NoLabelImageParagraph data-test-id="no-label-image-paragraph-2">
          {intl.formatMessage({ id: 'RETURN.returnInfo.label.noLabelImage.paragraph2' })}
        </NoLabelImageParagraph>
      </NoLabelImageWrapper>
    </NoLabelImageContainer>
  );
};

export interface OwnProps {
  sendEmailUrl: string;
}
