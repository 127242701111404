import { ConvertedAttribute } from 'types/return/overview/products-selection/products-selection-form/converted-attribute-type';
import { Attribute } from 'types/return/overview/products-selection/products-selection-form/return-product-api-response';

export const transformAttributesOptionsToStringArray = (attributes: Attribute[], sortAttributeOptions: boolean): ConvertedAttribute[] => {
  const transformedAndSortedArray: ConvertedAttribute[] = attributes.map((element: Attribute) => {
    const sortedAttributeOptions: string[] = element.attributeOptions
      .sort((a, b) => a.attributeValueOrder - b.attributeValueOrder)
      .map((element) => element.attributeValue);

    return {
      attributeName: element.attributeName,
      attributeOptions: sortedAttributeOptions
    };
  });

  const transformedArray: ConvertedAttribute[] = attributes.map((element: Attribute) => {
    const sortedAttributeOptions: string[] = element.attributeOptions.map((element) => element.attributeValue);

    return {
      attributeName: element.attributeName,
      attributeOptions: sortedAttributeOptions
    };
  });

  if (sortAttributeOptions) return transformedAndSortedArray;
  return transformedArray;
};
