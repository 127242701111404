import styled from '@emotion/styled';

export const PolicyHeaderContainer = styled.header`
  padding: 15px 0;
  background: linear-gradient(90deg, #3e53a4 0%, #cf0989 100%);
`;

export const PolicyHeaderWrapper = styled.div`
  width: 100%;
  padding: 0 20px;

  @media ${({ theme }) => theme.mediumUp} {
    padding: 0 30px;
  }

  @media ${({ theme }) => theme.largeUp} {
    padding: 0 50px;
  }

  @media ${({ theme }) => theme.xlargeUp} {
    padding: 0 100px;
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    padding: 0 120px;
  }
`;

export const LogoContainer = styled.div`
  width: 229px;
`;
export const LogoImage = styled.img`
  width: 100%;
`;
