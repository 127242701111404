import { PackageLocationType } from '../types/return/return-info/Label/package-location-type';

export const packageLocations: PackageLocationType[] = [
  {
    name: 'In/At Mailbox',
    value: 'In/At Mailbox',
    id: '1X'
  },
  {
    name: 'Front Door',
    value: 'Front Door',
    id: '2X'
  },
  {
    name: 'Back Door',
    value: 'Back Door',
    id: '3X'
  },
  {
    name: 'Side Door',
    value: 'Side Door',
    id: '4X'
  },
  {
    name: 'Knock on Door/Ring Bell',
    value: 'Knock on Door/Ring Bell',
    id: '5X'
  },
  {
    name: 'Mail Room',
    value: 'Mail Room',
    id: '6X'
  },
  {
    name: 'Office',
    value: 'Office',
    id: '7X'
  },
  {
    name: 'Reception',
    value: 'Reception',
    id: '8X'
  },
  {
    name: 'Other (additional instructions required)',
    value: 'Other',
    id: '9X'
  }
];
