import React, { useState } from 'react';
import { Image } from 'components/Shared/ImageWithFallback/ImageWithFallback.styled';

export const ImageWithFallback: React.FC<OwnProps> = (props) => {
  const { src, fallbackSrc, alt, height, minHeight, isBorder } = props;
  const [error, setError] = useState<boolean>(false);

  const handleError = () => {
    setError(true);
  };

  return (
    <Image
      isBorder={isBorder}
      src={!error && src ? src : fallbackSrc}
      alt={alt}
      onError={handleError}
      data-test-id="image-with-fallback"
      height={height}
      minHeight={minHeight}
    />
  );
};

export interface OwnProps {
  src: string;
  fallbackSrc: string;
  alt: string;
  height?: string;
  minHeight?: string;
  isBorder?: boolean;
}
