import dayjs from 'dayjs';
import { ParcelStatusEnum } from 'types/customer-service/find-parcels-api-response-type';
import { ParcelsViewEnum } from 'types/customer-service/parcels-view-enum';
import { whetherDisplayEDDTime } from 'utils/customer-service/whether-display-edd-time/whether-display-edd-time';

export const transformEddTimeToDisplayForm = (
  estimatedDeliveryDate: string | null,
  parcelDeliveredAtDate: string | null,
  parcelStatus: ParcelStatusEnum,
  parcelsView: ParcelsViewEnum
): string | null => {
  switch (parcelStatus) {
    case ParcelStatusEnum.PREPARING:
    case ParcelStatusEnum.IN_TRANSIT:
    case ParcelStatusEnum.OUT_FOR_DELIVERY:
    case ParcelStatusEnum.RETURN_STARTED:
    case ParcelStatusEnum.RETURN_IN_TRANSIT:
    case ParcelStatusEnum.RETURN_OUT_FOR_DELIVERY:
    case ParcelStatusEnum.FALLBACK:
      if (!estimatedDeliveryDate) return null;
      if (parcelsView === ParcelsViewEnum.Tile && whetherDisplayEDDTime(estimatedDeliveryDate)) return dayjs(estimatedDeliveryDate).format(`LT`);
      if (parcelsView === ParcelsViewEnum.Table && whetherDisplayEDDTime(estimatedDeliveryDate)) return dayjs(estimatedDeliveryDate).format(`LT`);
      return null;
    case ParcelStatusEnum.DELIVERED:
    case ParcelStatusEnum.RETURN_RECEIVED:
      if (!parcelDeliveredAtDate) return null;
      if (parcelsView === ParcelsViewEnum.Tile && whetherDisplayEDDTime(parcelDeliveredAtDate)) return dayjs(parcelDeliveredAtDate).format(`LT`);
      if (parcelsView === ParcelsViewEnum.Table && whetherDisplayEDDTime(parcelDeliveredAtDate)) return dayjs(parcelDeliveredAtDate).format(`LT`);
      return null;
    case ParcelStatusEnum.DELIVERY_EXCEPTION:
    case ParcelStatusEnum.RETURN_DELIVERY_EXCEPTION:
    case ParcelStatusEnum.UNKNOWN:
    case ParcelStatusEnum.RETURN_UNKNOWN:
      if (!estimatedDeliveryDate) return null;
      if (whetherDisplayEDDTime(estimatedDeliveryDate)) return null;
      return null;
    case ParcelStatusEnum.NOT_FOUND:
    case ParcelStatusEnum.TIMEOUT:
      return null;
  }
};
