import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import React from 'react';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { SearchBy } from 'types/customer-service/search-by-type';
import { OrderNumberParcelsList } from './OrderNumberParcelsList/OrderNumberParcelsList';
import { TrackingNumberParcelsList } from './TrackingNumberParcelsList/TrackingNumberParcelsList';

export const ParcelsList: React.FC<OwnProps> = (props) => {
  const { parcels, searchBy } = props;

  return (
    <SectionLayout
      backgroundAsColor={false}
      marginDefault="50px 0 0"
      paddingDefault="0 15px"
      paddingMd="0 30px"
      paddingL="0 30px"
      paddingXl="0 95px"
      paddingXXl="0 115px">
      {searchBy === SearchBy.TRACKING_NUMBER && <TrackingNumberParcelsList parcels={parcels} />}
      {searchBy === SearchBy.ORDER_NUMBER && <OrderNumberParcelsList parcels={parcels} />}
    </SectionLayout>
  );
};

export interface OwnProps {
  parcels: Parcel[];
  searchBy: SearchBy;
}
