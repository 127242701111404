import { theme as themeDefault } from './theme.default';
import { theme as themeSpartan } from './theme.spartan';
import { theme as themeVivid } from './theme.vivid';
import { theme as themeLinear } from './theme.linear';

import { setLightness } from 'polished';
import times from 'lodash/times';
import { AllowedColors, CustomTheme, ThemeProps } from 'types/modules';

export const breakpoints = [576, 768, 992, 1200];
const screen = 'only screen';

const smallRange = [0, breakpoints[0] - 1];
const mediumRange = [breakpoints[0], breakpoints[1] - 1];
const largeRange = [breakpoints[1], breakpoints[2] - 1];
const xlargeRange = [breakpoints[2], breakpoints[3] - 1];
const xxlargeRange = [breakpoints[3], 99999999];

const upperBound = (range: number[]) => {
  if (range.length < 2) {
    return 999999999999;
  }
  return range[1];
};

const lowerBound = (range: number[]) => {
  if (range.length <= 0) {
    return 0;
  }
  return range[0];
};

export const createTheme = (defaultTheme: CustomTheme, customTheme?: Partial<CustomTheme>): ThemeProps => {
  const theme = { ...defaultTheme, ...customTheme };

  const { gray, primaryColor, secondaryColor, tertiaryColor } = theme;

  const generateColorVariation = (keyName: string, color: string) => {
    let colors: { [x: string]: string } = {
      [keyName]: color
    };

    times(10, (i) => {
      if (i === 0) {
        i += 0.5;
      }

      const newColor = { [`${keyName}${i * 100}`]: setLightness(i / 10, color) };

      colors = {
        ...colors,
        ...newColor
      };
    });

    return colors;
  };

  const resolvePalette = (): Partial<AllowedColors> => {
    return {
      ...generateColorVariation('gray', gray),
      ...generateColorVariation('primaryColor', primaryColor),
      ...generateColorVariation('secondaryColor', secondaryColor),
      ...generateColorVariation('tertiaryColor', tertiaryColor)
    };
  };

  return {
    //Breakpoints
    smallDown: `${screen} and (max-width:${upperBound(smallRange)}px)`,
    mediumDown: `${screen} and (max-width:${upperBound(mediumRange)}px)`,
    largeDown: `${screen} and (max-width:${upperBound(largeRange)}px)`,
    xlargeDown: `${screen} and (max-width:${upperBound(xlargeRange)}px)`,
    xxlargeDown: `${screen} and (max-width:${upperBound(xxlargeRange)}px)`,

    mediumUp: `${screen} and (min-width:${lowerBound(mediumRange)}px)`,
    largeUp: `${screen} and (min-width:${lowerBound(largeRange)}px)`,
    xlargeUp: `${screen} and (min-width:${lowerBound(xlargeRange)}px)`,
    xxlargeUp: `${screen} and (min-width:${lowerBound(xxlargeRange)}px)`,

    mediumMq: `${screen} and (min-width:${lowerBound(mediumRange)}px) and (max-width: ${upperBound(mediumRange)}px)`,
    largeMq: `${screen} and (min-width:${lowerBound(largeRange)}px) and (max-width: ${upperBound(largeRange)}px)`,
    xLargeMq: `${screen} and (min-width:${lowerBound(xlargeRange)}px) and (max-width: ${upperBound(xlargeRange)}px)`,
    xxLargeMq: `${screen} and (min-width:${lowerBound(xxlargeRange)}px) and (max-width: ${upperBound(xxlargeRange)}px)`,

    //Specific browsers media queries
    ieOnly: `all and (-ms-high-contrast: none), (-ms-high-contrast: active)`,

    ...theme,

    //Palette
    ...resolvePalette()
  };
};

export const themes = {
  default: themeDefault,
  spartan: themeSpartan,
  vivid: themeVivid,
  linear: themeLinear
};

export const theme = themeDefault;
