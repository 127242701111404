import React, { createRef, useContext, useState, useMemo, useEffect } from 'react';
import { SingleTableRow, IconBox, EstimatedDeliveryTime } from './SingleParcelRow.styled';
import { TrackingNumberCellLink } from './TrackingNumberCellLink/TrackingNumberCellLink';
import { useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { ParcelsViewEnum } from 'types/customer-service/parcels-view-enum';
import { transformEddDateToDisplayForm } from 'utils/customer-service/transform-edd-date-to-display-form/transform-edd-date-to-display-form';
import { transformEddTimeToDisplayForm } from 'utils/customer-service/transform-edd-time-to-display-form/transform-edd-time-to-display-form';
import { useIntl } from 'react-intl';
import { transformStatusToDisplayForm } from 'utils/customer-service/transform-status-to-display-form/transform-status-to-display-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { setTrackingNumberLink } from 'utils/customer-service/set-tracking-number-link/set-tracking-number-link';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBOutlineIconsEnum } from 'constants/PBIconsEnums';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { CollapseTrackingEventsRow } from './CollapseTrackingEventsRow/CollapseTrackingEventsRow';
import { shouldDisplayTrackingEvents } from 'utils/customer-service/should-display-tracking-events/should-display-tracking-events';
import { PtsSwitchContext } from 'context/customer-service/PtsSwitchContext';
import { shouldHiddenTrackingNumberLink } from 'utils/customer-service/should-hidden-tracking-number-link/should-hidden-tracking-number-link';
import { AuthUserContext } from 'context/customer-service/AuthUserContext';
import { shouldDisplayReferenceNumberColumn } from 'utils/customer-service/should-display-column/should-display-reference-number-column/should-display-reference-number-column';
import { EllipseColumnText } from './EllipseColumnText/EllipseColumnText';
import { CustomTableCell } from 'components/Shared/styled-components/CustomTableCell/CustomTableCell.styled';
import { resolveEDDTimePreposition } from 'utils/customer-service/transform-edd-time-to-display-form/resolve-edd-time-preposition/resolve-edd-time-preposition';

export const SingleParcelRow = (props: OwnProps) => {
  const {
    parcel: { parcelDetails, trackingEvents }
  } = props;

  const { isUserAuth } = useContext(AuthUserContext);
  const { vanityName } = useContext(ClientConfigContext);
  const { consumerViewOnly } = useContext(PtsSwitchContext);

  const { hideTrackingLinkOnCsPageCbconnect2554 } = useFlags();
  const theme = useTheme();
  const xLargeUp = useMediaQuery(theme.breakpoints.up('xlarge'));
  const intl = useIntl();

  const [isTrackingEventsVisible, setIsTrackingEventsVisible] = useState<boolean>(false);
  const trackingNumberLinkRef = createRef<HTMLAnchorElement>();
  const hasTrackingEvents = useMemo(() => shouldDisplayTrackingEvents(trackingEvents, consumerViewOnly, isUserAuth), [
    trackingEvents,
    consumerViewOnly,
    isUserAuth
  ]);

  useEffect(() => {
    if (!hasTrackingEvents) {
      return setIsTrackingEventsVisible(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerViewOnly]);

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    if ((trackingNumberLinkRef.current && trackingNumberLinkRef.current.contains(event.target as Node)) || !hasTrackingEvents) return;
    setIsTrackingEventsVisible(!isTrackingEventsVisible);
  };

  return (
    <>
      <SingleTableRow
        data-test-id="single-table-row"
        onClick={(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => handleRowClick(event)}
        shouldHavePointer={hasTrackingEvents}
        data-gtm-id={hasTrackingEvents ? (isTrackingEventsVisible ? 'table-row-accordion-close' : 'table-row-accordion-open') : null}
        aria-label="cs-single-parcel-table-row">
        {/* ship date */}
        <CustomTableCell
          widthDefault="25%"
          widthXL="20%"
          paddingDefault="14px 5px 10px"
          fontSizeDefault="12px"
          fontSizeXL="14px"
          aria-label="ship-date-cell">
          {hasTrackingEvents && (
            <IconBox>
              <PBIcon
                type={PBIconTypeEnum.Outline}
                name={isTrackingEventsVisible ? PBOutlineIconsEnum.MinimalDown : PBOutlineIconsEnum.MinimalRight}
                aria-label="collapse-tracking-events-arrow"
              />
            </IconBox>
          )}
          {parcelDetails.deliveryDetails ? dayjs(parcelDetails.deliveryDetails.shipDate).format('l') : '-'}
        </CustomTableCell>

        {/* tracking number */}
        <TrackingNumberCellLink
          ref={trackingNumberLinkRef}
          text={parcelDetails.trackingNumber}
          href={
            shouldHiddenTrackingNumberLink(hideTrackingLinkOnCsPageCbconnect2554, parcelDetails.parcelStatus)
              ? null
              : setTrackingNumberLink(parcelDetails.trackingNumber, vanityName)
          }
        />

        {/* reference number */}
        {shouldDisplayReferenceNumberColumn(xLargeUp) && (
          <EllipseColumnText
            isUppercase={false}
            text={parcelDetails.referenceNumber ? parcelDetails.referenceNumber : '-'}
            numberOfSuffixChar={4}
            ariaLabel="reference-number-cell"
          />
        )}

        {/* current status */}
        <CustomTableCell
          widthDefault="25%"
          widthXL="20%"
          paddingDefault="14px 5px 10px"
          fontSizeDefault="12px"
          fontSizeXL="14px"
          shouldOneLineDisplay
          aria-label="current-status-cell">
          {transformStatusToDisplayForm(intl.formatMessage({ id: parcelDetails.primaryTextKey }), parcelDetails.parcelStatus)}
        </CustomTableCell>

        {/* estimated delivery */}
        <CustomTableCell
          widthDefault="25%"
          widthXL="20%"
          paddingDefault="14px 5px 10px"
          fontSizeDefault="12px"
          fontSizeXL="14px"
          aria-label="estimated-delivery-cell">
          {/* date */}
          {transformEddDateToDisplayForm(
            parcelDetails.parcelStatus,
            parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.estimatedDeliveryDate : null,
            parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.parcelDeliveredAtDate : null,
            parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.parcelDeliveryAddress : null,
            ParcelsViewEnum.Table
          )}

          {/*  time */}
          {transformEddTimeToDisplayForm(
            parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.estimatedDeliveryDate : null,
            parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.parcelDeliveredAtDate : null,
            parcelDetails.parcelStatus,
            ParcelsViewEnum.Table
          ) && (
            <EstimatedDeliveryTime>
              {intl.formatMessage({ id: resolveEDDTimePreposition(parcelDetails.parcelStatus, ParcelsViewEnum.Table) })}
              {transformEddTimeToDisplayForm(
                parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.estimatedDeliveryDate : null,
                parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.parcelDeliveredAtDate : null,
                parcelDetails.parcelStatus,
                ParcelsViewEnum.Table
              )}
            </EstimatedDeliveryTime>
          )}
        </CustomTableCell>
      </SingleTableRow>
      <CollapseTrackingEventsRow
        isCollapse={isTrackingEventsVisible}
        trackingEvents={trackingEvents}
        referenceNumber={parcelDetails.referenceNumber}
      />
    </>
  );
};

export interface OwnProps {
  parcel: Parcel;
}
