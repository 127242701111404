import css from '@emotion/css';
import styled from '@emotion/styled';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const NotificationsContainer = styled.div`
  width: 100%;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      max-width: 740px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      padding: ${theme.primaryBackgroundDark ? '20px 0' : '20px'};
      border: ${theme.primaryBackgroundDark ? 'none' : '1px solid #e1e1e1'};
      max-width: 860px;
      @media ${theme.xlargeUp} {
        padding: 20px;
      }
    `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      @media ${theme.largeUp} {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    `}
`;

export const TextWrapper = styled.div`
  margin: 0 0 20px 0;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      @media ${theme.largeUp} {
        margin: 0;
      }
    `}
`;

export const NotificationsTitle = styled.h4<{ isDark: boolean }>`
  font-size: 25px;
  font-weight: 600;
  font-family: ${({ theme }) =>
    getCustomStyle('styleModule.headerFontFamily') ? getCustomStyle('styleModule.headerFontFamily') : theme.primaryFontFamily};

  ${({ theme, isDark }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      text-align: center;
      margin: 0 0 10px 0;
      color: ${isDark ? theme.darkBackgroundColor : theme.white};

      @media ${theme.largeUp} {
        text-align: left;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      font-weight: 400;
      margin: 0 0 20px 0;
      color: ${theme.primaryBackgroundDark ? theme.white : 'black'};
    `}
`;

export const NotificationsSubtitle = styled.p<{ isDark: boolean }>`
  font-size: 15px;
  margin: 0;
  font-family: ${({ theme }) =>
    getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : theme.secondaryFontFamily};
  ${({ theme, isDark }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      text-align: center;
      color: ${isDark ? theme.darkBackgroundColor : theme.white};

      @media ${theme.largeUp} {
        text-align: left;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      font-weight: 200;
      margin: 0 0 30px 0;
      color: ${getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? '#FFF' : '#000'};
    `}
`;

export const NotificationsSelectContainer = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      justify-content: center;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      justify-content: flex-start;
    `}
`;

export const IconButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 53px;
  height: 53px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 1;

  &:nth-of-type(2) {
    margin-left: 15px;
  }

  background-color: ${({ theme }) =>
    getCustomStyleProperty(theme, 'styleModule.secondaryColor') ? getCustomStyle('styleModule.secondaryColor') : theme.secondaryColor};

  i {
    transition: all 0.3s ease-in-out;
    font-size: 30px;
    color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? theme.white : theme.buttonColorDark)};
  }

  &:hover {
    transition: all 0.3s ease-in-out;
    opacity: 1;
    i {
      transition: all 0.3s ease-in-out;
      color: ${({ theme }) => theme.white};
    }
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      border-radius: 15px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
      &:hover {
        i {
          color: #e3e3e3;
        }
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      border-radius: 100%;
      &:hover {
        i {
          color: #e3e3e3;
        }
      }
    `}
`;

export const SmsButton = styled.button``;

export const Icon = styled.img``;

export const FormsContainer = styled.div`
  margin: 15px 0 0 0;
`;
