import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const SelectedProductReviewItemContainer = styled.li<{ first: boolean }>`
  margin: ${({ first }) => (first ? 0 : '50px 0 0 0')};
  width: 100%;
  padding: 28px 30px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
`;

export const ItemBox = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.largeUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ItemTitle = styled.p`
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 0 10px 0;
  color: ${({ theme }) => theme.bodyFontColor};
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;

  @media ${({ theme }) => theme.largeUp} {
    width: calc(50% - 35px);
  }
`;

export const ReturnBox = styled.div<{ anyExchangeProductExists: boolean }>`
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    width: ${({ anyExchangeProductExists }) => (anyExchangeProductExists ? 'calc(50% - 35px)' : '100%')};
  }
`;

export const ExchangeBox = styled.ul`
  list-style: none;
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    width: calc(50% - 35px);
  }
`;

export const ArrowBox = styled.div<{ isTreatAsSpacer: boolean }>`
  visibility: ${({ isTreatAsSpacer }) => (isTreatAsSpacer ? 'hidden' : 'visible')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bbb;
  margin: 30px 0;

  @media ${({ theme }) => theme.largeUp} {
    justify-content: center;
    width: 70px;
    min-width: 70px;
    margin: 0;
    height: ${({ isTreatAsSpacer }) => (isTreatAsSpacer ? 'auto' : '140px')};
    transform: rotate(-90deg);
  }
`;

export const ItemTitleBox = styled.div`
  width: 100%;
  display: flex;
`;
