import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import useFetch, { DefaultErrorResponse } from 'hooks/useFetch/useFetch';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import {
  FeedbackContainer,
  FeedbackErrorMessage,
  FeedbackMessage,
  FeedbackQuestion,
  FeedbackQuestionContainer,
  FeedbackRatingWrapper,
  FeedbackStar,
  FeedbackStarsContainer,
  FeedbackTextWrapper,
  FeedbackTitle
} from './Feedback.styled';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { Loading } from 'components/Shared/Loading/Loading';
import { useIntl } from 'react-intl';
import { TemplateEnum } from 'types/shared/template-type';
import { FeedbackAnswerType } from 'types/tracking/common/feedback/feedback-answer-type';
import { FeedbackQuestionType } from 'types/tracking/common/feedback/feedback-question-type';
import { ClientConfigContext } from '../../../context/shared/ClientConfigContext';
import { buildFeedbackAnswersApi } from 'utils/tracking/feedback/build-feedback-answers-api/build-feedback-answers-api';
import { buildFeedbackQuestionsApi } from 'utils/tracking/feedback/build-feedback-questions-api/build-feedback-questions-api';

export const Feedback: React.FC<OwnProps> = (props) => {
  const { title, trackingNumber, isPreview, isReturn, bgColor, setShowFeedback } = props;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [rating, setRating] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [question, setQuestion] = useState<FeedbackQuestionType | null>(null);

  const { brandId, template } = useContext(ClientConfigContext);
  const intl = useIntl();

  //API
  const FEEDBACK_QUESTION_URL = buildFeedbackQuestionsApi(isPreview, brandId, trackingNumber, isReturn);
  const FEEDBACK_ANSWER_URL = buildFeedbackAnswersApi(brandId, trackingNumber);

  const [questions, , questionError] = useFetch<FeedbackQuestionType[] | null, DefaultErrorResponse>(FEEDBACK_QUESTION_URL);

  useEffect(() => {
    if (questions && questions.length === 0) return setShowFeedback(false);
    if (questions && questions[currentQuestionIndex]) return setQuestion(questions[currentQuestionIndex]);
    return setQuestion(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex, questions]);

  useEffect(() => {
    if (questionError) return setShowFeedback(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionError]);

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer[0] = {
        ...window.dataLayer[0],
        feedbackQuestions: question?.question
      };
    }
  }, [question, currentQuestionIndex]);

  const starsLevels: number[] = [1, 2, 3, 4, 5];

  const handleSentFeedback = async (questionId: string, rating: number) => {
    const answerObject: FeedbackAnswerType = {
      questionId: questionId,
      rating: rating
    };

    const params = enhanceFetchParams({
      headers: enhanceHeadersParams(brandId),
      method: 'POST',
      body: JSON.stringify(answerObject)
    });

    try {
      const response = await fetch(FEEDBACK_ANSWER_URL, params);
      const data = await response.json();
      if (data.error) return setError(true);
      setError(false);
      setCurrentQuestionIndex((prevState) => prevState + 1);
    } catch (err) {
      setError(true);
    }
  };

  if (questionError) return null;
  if (questions && questions.length === 0) return null;

  if (!questions)
    return (
      <SectionLayout
        marginDefault="0 0 50px 0"
        bgColor={template === TemplateEnum.SPARTAN ? bgColor : ''}
        marginXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
        marginXXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
        paddingXl={template === TemplateEnum.SPARTAN ? '0' : ''}
        paddingXXl={template === TemplateEnum.SPARTAN ? '0' : ''}
        backgroundAsColor={template === TemplateEnum.SPARTAN}>
        <FeedbackContainer>
          <Loading height="150px" data-test-id="feedback-container" />
        </FeedbackContainer>
      </SectionLayout>
    );

  return (
    <SectionLayout
      marginDefault="0 0 50px 0"
      bgColor={template === TemplateEnum.SPARTAN ? bgColor : ''}
      marginXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
      marginXXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
      paddingXl={template === TemplateEnum.SPARTAN ? '0' : ''}
      paddingXXl={template === TemplateEnum.SPARTAN ? '0' : ''}
      backgroundAsColor={template === TemplateEnum.SPARTAN}>
      <FeedbackContainer data-test-id="feedback-container">
        <FeedbackTextWrapper>
          <FeedbackTitle data-test-id="feedback-title" textAlignLeft={!!question}>
            {question ? title : intl.formatMessage({ id: 'TRACKING.feedback.thanksTitleMessage' })}
          </FeedbackTitle>
          <FeedbackQuestionContainer>
            {error && (
              <FeedbackErrorMessage data-test-id="feedback-error-message">
                {intl.formatMessage({ id: 'TRACKING.feedback.errorAnswerMessage' })}
              </FeedbackErrorMessage>
            )}
            {question ? (
              <FeedbackQuestion data-test-id="feedback-question">{question.question}</FeedbackQuestion>
            ) : (
              <FeedbackMessage data-test-id="feedback-message">{intl.formatMessage({ id: 'TRACKING.feedback.thanksMessage' })}</FeedbackMessage>
            )}
          </FeedbackQuestionContainer>
        </FeedbackTextWrapper>
        {question && (
          <FeedbackRatingWrapper data-test-id="feedback-rating-wrapper">
            <FeedbackStarsContainer data-test-id="feedback-stars-container" onMouseLeave={() => setRating(0)}>
              {starsLevels.map((starLevel: number) => (
                <FeedbackStar
                  isActive={starLevel <= rating}
                  data-test-id="feedback-star"
                  data-gtm-id="feedback-star"
                  onMouseEnter={() => setRating(starLevel)}
                  onClick={() => handleSentFeedback(question.id, starLevel)}
                  key={starLevel}
                  aria-label={starLevel.toString()}
                />
              ))}
            </FeedbackStarsContainer>
          </FeedbackRatingWrapper>
        )}
      </FeedbackContainer>
    </SectionLayout>
  );
};

export interface OwnProps {
  title: string;
  trackingNumber: string;
  isPreview: boolean;
  isReturn: boolean;
  bgColor?: string;
  setShowFeedback: Dispatch<SetStateAction<boolean>>;
}
