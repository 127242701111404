import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';
import { PageTypeEnum } from 'types/app/page-type-enum';

const StyledBurger = styled.button<{ active: boolean }>`
  cursor: pointer;
  background: transparent;
  border: 0;
  position: absolute;
  padding: 0;
  height: 31px;
  width: 31px;
  left: 20px;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  transition: all 1s ease 0s;

  @media ${({ theme }) => theme.mediumUp} {
    left: 30px;
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.CustomerServicePage &&
    css`
      left: 15px;

      @media ${theme.mediumUp} {
        left: 30px;
      }
    `}

  &:hover,
  &:focus {
    border: 0;
    outline: 0;
  }

  > span {
    width: 31px;
    height: 3px;
    background: ${({ theme }) => theme.darkBackgroundColor};
    display: block;
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease 0s;

    &:after,
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 31px;
      height: 3px;
      background: ${({ theme }) => theme.darkBackgroundColor};
      transform: rotate(0deg);
      transition: all 0.3s ease 0s;
    }

    &:before {
      top: -10px;
    }

    &:after {
      bottom: -10px;
    }
  }

  ${({ active }) =>
    active &&
    css`
      > span {
        background: transparent;

        &:after {
          transform: rotate(45deg);
          top: 50%;
        }

        &:before {
          transform: rotate(-45deg);
          top: 50%;
        }
      }
    `}

  ${({ theme, active }) =>
    getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') &&
    css`
      > span {
        background: ${theme.lightBackgroundColor};

        &:after,
        &:before {
          background: ${theme.lightBackgroundColor};
        }
      }

      ${active &&
      css`
        > span {
          background: transparent;
        }
      `}
    `}
`;

export { StyledBurger };
