import { useIntl } from 'react-intl';
import React, { PropsWithChildren } from 'react';
import { ReturnPolicyContainer, ReturnPolicyDescription, ReturnPolicyLink, ReturnPolicyTitle } from './ReturnPolicy.styled';
import { PolicyParagraphLinkType } from 'types/return/shared/return-returns-policy-view-type';

export const ReturnPolicy: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { policyParagraph, policyParagraphLink } = props;

  const intl = useIntl();

  return (
    <ReturnPolicyContainer data-test-id="return-policy-container">
      <ReturnPolicyTitle>{intl.formatMessage({ id: 'RETURN.common.returnPolicy.title' })}</ReturnPolicyTitle>
      <ReturnPolicyDescription data-test-id="return-policy-description">
        {policyParagraph.text}
        {policyParagraphLink.enabled && !!policyParagraphLink.url && (
          <ReturnPolicyLink
            href={policyParagraphLink.url}
            target="_blank"
            rel="noopener noreferrer"
            data-test-id="returns-policy-link"
            data-gtm-id="returns-policy-link">
            {intl.formatMessage({ id: 'RETURN.common.returnPolicy.link' })}
          </ReturnPolicyLink>
        )}
      </ReturnPolicyDescription>
    </ReturnPolicyContainer>
  );
};

export interface OwnProps {
  policyParagraph: {
    text: string;
    enabled: boolean;
  };
  policyParagraphLink: PolicyParagraphLinkType;
}
