import queryString from 'query-string';
import { DropOffLocationsSearchParams } from 'types/return/return-info/Common/drop-off-locations-response';

export const buildDropOffLocationsURL = (searchParams: DropOffLocationsSearchParams): string => {
  const { zipCode, street, city, state } = searchParams;

  return queryString.stringifyUrl(
    {
      url: '/api/return/dropOffLocations/v2',
      query: {
        zipCode,
        street,
        city,
        state
      }
    },
    { skipNull: true, skipEmptyString: true, sort: false }
  );
};

export const buildUserLocationURL = (searchParams: DropOffLocationsSearchParams): string => {
  const { zipCode, street, city, state } = searchParams;

  return queryString.stringifyUrl(
    {
      url: '/api/return/dropOffLocations/userLocation',
      query: {
        zipCode,
        street,
        city,
        state
      }
    },
    { skipNull: true, skipEmptyString: true, sort: false }
  );
};
