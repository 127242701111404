import { css, SerializedStyles } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import { PageTypeEnum } from 'types/app/page-type-enum';
import { MainTheme } from 'types/modules';
import { getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';

export const baseStyles = (theme: MainTheme): SerializedStyles => {
  const normalize = emotionNormalize;

  const settingsForCCPolicyDocs = `
    html {
      scroll-behavior: smooth;
    }

    @font-face {
      font-family: 'Precision Sans W';
      font-style: normal;
      font-weight: 300;
      src: url('/assets/fonts/PrecisionSans_W_Lt.ttf') format('truetype'), 
      url('/assets/fonts/PrecisionSans_W_Lt.woff') format('woff'), 
      url('/assets/fonts/PrecisionSans_W_Lt.svg') format('svg');
    }

    @font-face {
      font-family: 'Precision Sans W';
      font-style: italic;
      font-weight: 300;
      src: url('/assets/fonts/PrecisionSans_W_LtIt.ttf') format('truetype'), 
      url('/assets/fonts/PrecisionSans_W_LtIt.woff') format('woff'), 
      url('/assets/fonts/PrecisionSans_W_LtIt.svg') format('svg');
    }

    @font-face {
      font-family: 'Precision Sans W';
      font-style: normal;
      font-weight: 400;
      src: url('/assets/fonts/PrecisionSans_W_Rg.ttf') format('truetype'), 
      url('/assets/fonts/PrecisionSans_W_Rg.woff') format('woff'), 
      url('/assets/fonts/PrecisionSans_W_Rg.svg') format('svg');
    }

    @font-face {
      font-family: 'Precision Sans W';
      font-style: italic;
      font-weight: 400;
      src: url('/assets/fonts/PrecisionSans_W_RgIt.ttf') format('truetype'), 
      url('/assets/fonts/PrecisionSans_W_RgIt.woff') format('woff'), 
      url('/assets/fonts/PrecisionSans_W_RgIt.svg') format('svg');
    }

    @font-face {
      font-family: 'Precision Sans W';
      font-style: normal;
      font-weight: 500;
      src: url('/assets/fonts/PrecisionSans_W_Md.ttf') format('truetype'), 
      url('/assets/fonts/PrecisionSans_W_Md.woff') format('woff'), 
      url('/assets/fonts/PrecisionSans_W_Md.svg') format('svg');
    }

        @font-face {
      font-family: 'Precision Sans W';
      font-style: italic;
      font-weight: 500;
      src: url('/assets/fonts/PrecisionSans_W_MdIt.ttf') format('truetype'), 
      url('/assets/fonts/PrecisionSans_W_MdIt.woff') format('woff'), 
      url('/assets/fonts/PrecisionSans_W_MdIt.svg') format('svg');
    }

    @font-face {
      font-family: 'Precision Sans W';
      font-style: normal;
      font-weight: 700;
      src: url('/assets/fonts/PrecisionSans_W_Bd.ttf') format('truetype'), 
      url('/assets/fonts/PrecisionSans_W_Bd.woff') format('woff'), 
      url('/assets/fonts/PrecisionSans_W_Bd.svg') format('svg');
    }

    @font-face {
      font-family: 'Precision Sans W';
      font-style: italic;
      font-weight: 700;
      src: url('/assets/fonts/PrecisionSans_W_BdIt.ttf') format('truetype'), 
      url('/assets/fonts/PrecisionSans_W_BdIt.woff') format('woff'), 
      url('/assets/fonts/PrecisionSans_W_BdIt.svg') format('svg');
    }
  `;

  const base = `
    * {
      box-sizing: border-box;
      
      &:before,
      &:after {
        box-sizing: border-box;
      }
      
      html {
        zoom: 1;
      }
    }

    html,
    body {
      width: 100%;
      margin: 0;
      padding: 0;

      @media ${theme.ieOnly} {
       display: flex; 
      }
    }
    
    body {
      background-color: ${theme.bodyBackgroundColor};
      color: ${theme.bodyFontColor};
      font-family: ${getCustomStyleProperty(theme, 'styleModule.bodyFontFamily') ?? theme.bodyFontFamily};
      font-weight: normal;
      font-style: normal;
      line-height: normal;
      font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
 
    #root {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 100vh;
    }
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    p {
      font-size: 12px;
      font-family: ${theme.bodyFontFamily};
    }

    a {
      cursor: pointer;
      font-size: 12px;
      color: ${theme.anchorColor};
      text-decoration: none;
      transition: color .3s ease 0s;

      &:hover {
        color: ${theme.anchorColorFocus};
      }
    }
  `;

  return css`
    ${normalize}
    ${base}
    ${theme.pageType === PageTypeEnum.ConsumerConnectPolicy ? settingsForCCPolicyDocs : ''}
  `;
};

export default baseStyles;
