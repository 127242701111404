import React, { useContext, useMemo } from 'react';
import { CustomTableRow, L2Description, MobileCellText } from './SingleEventRow.styled';
import { CustomTableCell } from 'components/Shared/styled-components/CustomTableCell/CustomTableCell.styled';
import { TrackingEvent } from 'types/customer-service/find-parcels-api-response-type';
import { setProcessingLocationValue } from 'utils/customer-service/set-processing-location-value/set-processing-location-value';
import { setActivityValue } from 'utils/customer-service/set-activity-value/set-activity-value';
import { AuthUserContext } from 'context/customer-service/AuthUserContext';
import { PtsSwitchContext } from 'context/customer-service/PtsSwitchContext';
import { shouldDisplayL2DescriptionValue } from 'utils/customer-service/should-display-l2-description-value/should-display-l2-description-value';
import { useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';

export const SingleEventRow = (props: OwnProps) => {
  const { trackingEvent } = props;

  const { isUserAuth } = useContext(AuthUserContext);
  const { consumerViewOnly } = useContext(PtsSwitchContext);

  const theme = useTheme();
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));

  const existsOnlyL2Description = useMemo(() => !trackingEvent.activity.l1Description && !!trackingEvent.activity.l2Description, [
    trackingEvent.activity.l1Description,
    trackingEvent.activity.l2Description
  ]);

  if (isUserAuth && existsOnlyL2Description && consumerViewOnly) return null;

  if (largeUp)
    return (
      <CustomTableRow shouldHaveGreyBackground={isUserAuth && existsOnlyL2Description && !consumerViewOnly}>
        <CustomTableCell
          aria-label="tracking-event-date-cell"
          widthDefault="15%"
          fontSizeDefault="12px"
          fontSizeXL="14px"
          paddingDefault="6px 10px"
          fontWeight="bold"
          isBorderBottomActive>
          {dayjs(trackingEvent.date).format('ll')}
        </CustomTableCell>
        <CustomTableCell
          aria-label="tracking-event-time-cell"
          widthDefault="15%"
          fontSizeDefault="12px"
          fontSizeXL="14px"
          paddingDefault="6px 10px"
          isBorderBottomActive>
          {dayjs(trackingEvent.date).format(`LT`)}
        </CustomTableCell>
        <CustomTableCell
          aria-label="tracking-event-activity-cell"
          widthDefault="45%"
          fontSizeDefault="12px"
          fontSizeXL="14px"
          paddingDefault="6px 10px"
          fontWeight="bold"
          isBorderBottomActive>
          {/* always we should display description or l1description depends on auth status */}
          {setActivityValue(trackingEvent.activity.description, trackingEvent.activity.l1Description, isUserAuth)
            ? setActivityValue(trackingEvent.activity.description, trackingEvent.activity.l1Description, isUserAuth)
            : null}
          {/* displaying l2description depends on user status and pts switcher value */}
          {shouldDisplayL2DescriptionValue(trackingEvent.activity.l2Description, isUserAuth, consumerViewOnly) && (
            <L2Description>{trackingEvent.activity.l2Description}</L2Description>
          )}
        </CustomTableCell>
        <CustomTableCell
          aria-label="tracking-event-processing-location-cell"
          widthDefault="25%"
          paddingDefault="6px 10px"
          fontSizeDefault="12px"
          fontSizeXL="14px"
          toUppercase
          align="right"
          isBorderBottomActive>
          {setProcessingLocationValue(trackingEvent.processingLocation)}
        </CustomTableCell>
      </CustomTableRow>
    );

  return (
    <CustomTableRow shouldHaveGreyBackground={isUserAuth && existsOnlyL2Description && !consumerViewOnly}>
      <CustomTableCell
        aria-label="tracking-event-date-and-time-cell"
        widthDefault="40%"
        fontSizeDefault="12px"
        paddingDefault="6px 10px"
        isBorderBottomActive>
        <MobileCellText isBolded>{dayjs(trackingEvent.date).format('ll')}</MobileCellText>
        <MobileCellText isBolded={false}>{dayjs(trackingEvent.date).format(`LT`)}</MobileCellText>
      </CustomTableCell>
      <CustomTableCell
        aria-label="tracking-event-activity-and-processing-location-cell"
        widthDefault="60%"
        fontSizeDefault="12px"
        paddingDefault="6px 10px"
        align="right"
        isBorderBottomActive>
        <MobileCellText isBolded>
          {setActivityValue(trackingEvent.activity.description, trackingEvent.activity.l1Description, isUserAuth)
            ? setActivityValue(trackingEvent.activity.description, trackingEvent.activity.l1Description, isUserAuth)
            : null}
          {/* displaying l2description depends on user status and pts switcher value */}
          {shouldDisplayL2DescriptionValue(trackingEvent.activity.l2Description, isUserAuth, consumerViewOnly) && (
            <L2Description>{trackingEvent.activity.l2Description}</L2Description>
          )}
        </MobileCellText>
        <MobileCellText isBolded={false}>
          {/* always we should display description or l1description depends on auth status */}
          {setProcessingLocationValue(trackingEvent.processingLocation)}
        </MobileCellText>
      </CustomTableCell>
    </CustomTableRow>
  );
};

export interface OwnProps {
  trackingEvent: TrackingEvent;
}
