import React from 'react';
import { useIntl } from 'react-intl';
import { Container, Title, Text, Link } from './AuthenticatedReturnsExpired.styled';
export const AuthenticatedReturnsExpired: React.FC = () => {
  const intl = useIntl();

  return (
    <Container data-test-id="authenticated-returns-expired">
      <Title data-test-id="authenticated-returns-expired__title">
        {intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsExpired.title' })}
      </Title>
      <Text data-test-id="authenticated-returns-expired__paragraph">
        {intl.formatMessage(
          { id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsExpired.paragraph' },
          {
            link: (
              <Link
                target="_blank"
                data-gtm-id="expired-message-link"
                href="https://client.pitneybowes.com/home"
                data-test-id="authenticated-returns-expired__link"
                key={1}>
                {intl.formatMessage({ id: 'CS.parcels.searchParcels.authenticatedReturns.authenticatedReturnsExpired.link' })}
              </Link>
            ),
            br: <br />
          }
        )}
      </Text>
    </Container>
  );
};
