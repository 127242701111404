import React, { PropsWithChildren, ReactNode } from 'react';
import { ReturnsLayoutContainer, ReturnsLayoutWrapper } from './ReturnsLayout.styled';
import { ReturnsTitle } from './ReturnsTitle/ReturnsTitle';
import { ReturnPolicy } from './ReturnPolicy/ReturnPolicy';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { ReturnsHeading } from './ReturnsHeading/ReturnsHeading';
import { PolicyParagraphLinkType, PolicyParagraphType } from 'types/return/shared/return-returns-policy-view-type';

export const ReturnsLayout: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { children, title, orderNumber, policyParagraph, policyParagraphLink, searchOrderTitle, searchOrderSubtitle } = props;
  return (
    <ReturnsLayoutContainer>
      <SectionLayout backgroundAsColor={true} bgColor="#FFF">
        <ReturnsLayoutWrapper>
          {title && <ReturnsTitle title={title} orderNumber={orderNumber ? orderNumber : ''} />}
          {searchOrderTitle && searchOrderSubtitle && <ReturnsHeading title={searchOrderTitle} subtitle={searchOrderSubtitle} />}
          {children}
          {policyParagraph.enabled && !!policyParagraph.text && (
            <ReturnPolicy
              policyParagraph={{ text: policyParagraph.text, enabled: policyParagraph.enabled }}
              policyParagraphLink={policyParagraphLink}
            />
          )}
        </ReturnsLayoutWrapper>
      </SectionLayout>
    </ReturnsLayoutContainer>
  );
};

export interface OwnProps {
  children: ReactNode;
  searchOrderTitle?: string;
  searchOrderSubtitle?: string;
  title?: string;
  orderNumber: string | null;
  policyParagraph: PolicyParagraphType;
  policyParagraphLink: PolicyParagraphLinkType;
}
