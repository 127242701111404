import { SelectedProducts } from 'context/returns/ProductsSelectionContext';
import { SelectedProduct } from 'models/returns/selected-product/SelectedProduct';
import { TransformedExchangeItem } from 'models/returns/selected-product/SelectedProduct';
import { transformExchangesItems } from '../transform-exchanges-items/transform-exchanges-items';
import { transformRootItems } from '../transform-root-items/transform-root-items';

export const imageFilterIfAtLeastOneExchangeProductHasImage = (selectedProducts: SelectedProducts): boolean => {
  let isAtLeastOneExchangeProductHasImage: boolean = false;
  transformRootItems(selectedProducts).map((selectedProduct: SelectedProduct) => {
    const isAtLeastOneExchangeProductHasImageAndSelectedProductOptionIsExchange = transformExchangesItems(
      selectedProduct.exchangeProducts,
      selectedProduct.exchangeOptionsAttributes
    ).some((exchangeItem: TransformedExchangeItem) => !!exchangeItem.exchangeProductImageUrl && selectedProduct.option === 'exchange');
    if (isAtLeastOneExchangeProductHasImageAndSelectedProductOptionIsExchange) isAtLeastOneExchangeProductHasImage = true;
  });

  return isAtLeastOneExchangeProductHasImage;
};
