import { ConvertedAttribute } from 'types/return/overview/products-selection/products-selection-form/converted-attribute-type';

export const resolveSelectEnabled = (isDataLoading: boolean, attributes: ConvertedAttribute[], rootProductAttribute: string): boolean => {
  if (isDataLoading) return false;
  if (attributes.length > 0) {
    const attributeObject = attributes.find((element: ConvertedAttribute) => element.attributeName === rootProductAttribute);
    if (attributeObject) return attributeObject.attributeOptions.length > 0;
  }
  return true;
};
