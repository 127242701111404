import React, { useContext } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { States } from 'types/tracking-types';
import { useIntl } from 'react-intl';
import {
  DeliveryDate,
  EDDTime,
  LeadContainer,
  GiftContainer,
  GiftIcon,
  GiftbannerHeader,
  GiftbannerHeaderdiv,
  GiftbannerText,
  LeadContentWrapper,
  PrimaryText,
  PrimaryTextContainer,
  StartedLink,
  StartedLinkdiv,
  StartedIcon,
  SecondaryText
} from './Lead.styled';
import { buildDeliveredDate, setEDDText } from 'utils/common/tracking.utils/tracking.utils';
import { SectionLayout, GiftTrackerBanner } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
//import { makeStyles } from '@material-ui/core/styles';
import { TemplateEnum } from 'types/shared/template-type';
import { HeaderModuleType } from 'types/shared/config/headerModule/header-module-type';
import { TrackingEventsModuleType } from 'types/tracking/trackingConfig/trackingEventsModule/tracking-events-module-type';
import {
  ClientConfigContext,
  ClientConfigContextProvider,
  ClientConfigContextType,
  defaultClientConfigContext
} from 'context/shared/ClientConfigContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TrackingConfigurationType } from 'types/tracking/trackingConfig/tracking-configuration-type';
import { getGiftAppURL } from 'utils/common/get-api-host-url/get-api-host-url';

smoothscroll.polyfill();

export const Lead: React.FC<OwnProps> = (props) => {
  const { giftTrackerExapps8413 } = useFlags();
  const { primaryTextKey, headerModule, trackingEventsModule, trackingPackageStatus, trackingData, brandName } = props;
  const { heroImage, backgroundColor, showBackgroundAsColor } = headerModule;

  const { template } = useContext(ClientConfigContext);
  const intl = useIntl();

  const isTrackingEventsDataExist = trackingEventsModule && trackingEventsModule.trackingEvents && trackingEventsModule.trackingEvents.length > 0;
  const deliveryDate = isTrackingEventsDataExist ? trackingEventsModule.trackingEvents[0].shortDate : '-';
  const deliveryDay = isTrackingEventsDataExist ? trackingEventsModule.trackingEvents[0].dayOfWeek : '-';

  const primaryText = primaryTextKey ? intl.formatMessage({ id: primaryTextKey }) : '';
  const secondaryText = trackingEventsModule.secondaryTextKey
    ? intl.formatMessage({
        id: !trackingEventsModule.eddText ? `${trackingEventsModule.secondaryTextKey}.noEDD` : `${trackingEventsModule.secondaryTextKey}`
      })
    : '';

  const removeNoEdd = (text) => {
    if (text.endsWith('.CUSTOM.noEDD')) {
      return text.slice(0, -13);
    }
    if (text.endsWith('.CUSTOM')) {
      return text.slice(0, -7);
    }
    return text;
  };

  const cleanedText = removeNoEdd(secondaryText);

  const date = trackingEventsModule.packageDelivered
    ? buildDeliveredDate(deliveryDate, trackingEventsModule.location, trackingPackageStatus, deliveryDay)
    : setEDDText(trackingPackageStatus, trackingEventsModule.eddText ? trackingEventsModule.eddText : null);

  return (
    <div>
      {giftTrackerExapps8413 && trackingPackageStatus === 'InTransit' && (
        <GiftTrackerBanner href={`${getGiftAppURL()}/${brandName}/${trackingData.trackingNumber}`} target="_blank" rel="noreferrer">
          <GiftContainer>
            <GiftIcon>
              <img data-test-id="gift-icon" src="/assets/img/icon_gift_pack.svg" alt="gift icon" className="css-f01b74-Icon2 elvg2vj10" />
            </GiftIcon>
            <GiftbannerHeaderdiv>
              <GiftbannerHeader>Is this a gift ? Try the new Gift Tracker.</GiftbannerHeader>
              <GiftbannerText>Get them more excited as they track your gift, with custom digital message.</GiftbannerText>
            </GiftbannerHeaderdiv>
            <StartedLinkdiv>
              <StartedLink id="getstarted_button">Get Started</StartedLink>
              <StartedIcon>
                <img data-test-id="getstarted-icon" src="/assets/img/minimal-right.svg" alt="gift icon" className="css-f01b74-Icon2 elvg2vj10" />
              </StartedIcon>
            </StartedLinkdiv>
          </GiftContainer>
        </GiftTrackerBanner>
      )}

      <SectionLayout
        paddingXl={template === TemplateEnum.SPARTAN ? '0' : ''}
        paddingXXl={template === TemplateEnum.SPARTAN ? '0' : ''}
        backgroundAsColor={showBackgroundAsColor}
        bgColor={template === TemplateEnum.VIVID ? backgroundColor : 'transparent'}
        backgroundImage={template === TemplateEnum.VIVID ? heroImage : ''}>
        <LeadContainer data-test-id="lead-container" template={template}>
          <PrimaryTextContainer template={template} data-test-id="primary-text-container">
            <PrimaryText template={template} data-test-id="lead-primary-text">
              {primaryText}
            </PrimaryText>
          </PrimaryTextContainer>
          <LeadContentWrapper template={template} data-test-id="lead-content-wrapper">
            <SecondaryText data-test-id="lead-secondary-text">{cleanedText}</SecondaryText>
            {date && (
              <DeliveryDate data-test-id="lead-delivery-date">
                {date}
                {trackingEventsModule.eddTime && (
                  <EDDTime>{intl.formatMessage({ id: 'TRACKING.lead.eddTime' }, { time: trackingEventsModule.eddTime })}</EDDTime>
                )}
              </DeliveryDate>
            )}
          </LeadContentWrapper>
        </LeadContainer>
      </SectionLayout>
    </div>
  );
};

export interface OwnProps {
  headerModule: HeaderModuleType;
  trackingEventsModule: TrackingEventsModuleType;
  primaryTextKey: string;
  trackingPackageStatus: States | string;
  trackingData: TrackingConfigurationType;
  brandName: string;
}
