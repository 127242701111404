import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LanguageContextProvider } from 'context/shared/LanguageContext';
import { App } from 'app/App';
import { SearchOrder } from 'pages/Returns/SearchOrder/SearchOrder';
import { Overview } from 'pages/Returns/Overview/Overview';
import { ReturnInfo } from 'pages/Returns/ReturnInfo/ReturnInfo';
import { Tracking } from 'pages/Tracking/Tracking';
import { Unsubscribe } from 'pages/Unsubscribe/Unsubscribe';
import { NotFound } from 'pages/NotFound/NotFound';
import { CancelPickup } from 'pages/CancelPickup/CancelPickup';
import { Environment, resolveEnvironment } from 'utils/common/environment-resolver/environment-resolver';
import { ConsumerConnectSmsTerms } from 'pages/ConsumerConnectSMSTerms/ConsumerConnectSMSTerms';
import { ConsumerConnectPrivacyStatement } from 'pages/ConsumerConnectPrivacyStatement/ConsumerConnectPrivacyStatement';
import { ConsumerConnectTermsOfUse } from 'pages/ConsumerConnectTermsOfUse/ConsumerConnectTermsOfUse';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { resolveClientId } from 'utils/common/launch-darkly/launch-darkly-client-id/launch-darkly-client-id';
import { launchDarklyResolveUserDefaultKey } from 'utils/common/launch-darkly/launch-darkly-resolve-user-default-key/launch-darkly-resolve-user-default-key';
import { CustomerService } from 'pages/CustomerService/CustomerService';

import { GiftApp } from 'GiftTrackerApp/GiftApp';
import Occasion from 'GiftTrackerApp/occaison';
import Media from 'GiftTrackerApp/Media';
import Photo from 'GiftTrackerApp/Photo';
import Video from 'GiftTrackerApp/Video';
import Share from 'GiftTrackerApp/Share';
import RecipientComponent from 'GiftTrackerApp/reciver';
import { CookiePolicy } from 'pages/CookiesPolicy/CookiesPolicy';

(async () => {
  const LDProvider: ({ children }: { children: React.ReactNode }) => JSX.Element = await asyncWithLDProvider({
    clientSideID: resolveClientId(),
    context: {
      key: launchDarklyResolveUserDefaultKey()
    },
    options: { streaming: true, diagnosticOptOut: true, fetchGoals: false, sendEvents: false }
  });
  const environment: Environment = resolveEnvironment();

  render(
    <LDProvider>
      <LanguageContextProvider>
        <BrowserRouter>
          {environment === Environment.LOCAL ? (
            <Routes>
              <Route element={<GiftApp />}>
                <Route path="/send/:vanityName/:brandName/:trackingNumber" element={<Occasion />} />
                <Route path="/send/:vanityName/media" element={<Media />} />
                <Route path="/send/:vanityName/photo" element={<Photo />} />
                <Route path="/send/:vanityName/video" element={<Video />} />
                <Route path="/send/:vanityName/share" element={<Share />} />
                <Route path="/:vanityName/r/:giftId" element={<RecipientComponent />} />
              </Route>
              <Route element={<App />}>
                <Route path="/search/:vanityName" element={<SearchOrder />} />
                <Route path="/return/overview/:vanityName" element={<Overview />} />
                <Route path="/return/getLabel/:vanityName" element={<ReturnInfo />} />
                <Route path="/tracking/:vanityName/:trackingNumber" element={<Tracking />} />
                <Route path="/track/:vanityName" element={<CustomerService />} />
                <Route path="/unsubscribe/:vanityName/:trackingNumber" element={<Unsubscribe />} />
                <Route path="/return/pickup/cancel/:vanityName/labels/:labelId/key/:key" element={<CancelPickup />} />
                <Route path="/preview/:vanityName/:designId" element={<CustomerService />} />
                <Route path="/preview/published/tracking/:vanityName/:trackingNumber" element={<Tracking />} />
                <Route path="/preview/draft/tracking/:vanityName/:designId/:trackingNumber" element={<Tracking />} />
              </Route>
              <Route path="/docs/consumer-connect-sms-terms" element={<ConsumerConnectSmsTerms />} />
              <Route path="/docs/consumer-connect-tou" element={<ConsumerConnectTermsOfUse />} />
              <Route path="/docs/privacy-statement" element={<ConsumerConnectPrivacyStatement />} />
              <Route path="/docs/cookies-policy" element={<CookiePolicy />} />
              <Route path="/docs/*" element={<Navigate to="/track/willow" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          ) : (
            <Routes>
              <Route element={<GiftApp />}>
                <Route path="/send/:brandName/:trackingNumber" element={<Occasion />} />
                <Route path="/send/:vanityName/:brandName/:trackingNumber" element={<Occasion />} />
                <Route path="/send/:vanityName/media" element={<Media />} />
                <Route path="/send/:vanityName/photo" element={<Photo />} />
                <Route path="/send/:vanityName/video" element={<Video />} />
                <Route path="/send/:vanityName/share" element={<Share />} />
                <Route path="/:vanityName/r/:giftId" element={<RecipientComponent />} />
              </Route>

              <Route element={<App />}>
                <Route path="/search" element={<SearchOrder />} />
                <Route path="/search/:vanityName" element={<SearchOrder />} />
                <Route path="/return/overview/:vanityName" element={<Overview />} />
                <Route path="/return/getLabel/:vanityName" element={<ReturnInfo />} />
                <Route path="/track/:trackingNumber" element={<Tracking />} />
                <Route path="/tracking/:trackingNumber" element={<Tracking />} />
                <Route path="/tracking/:vanityName/:trackingNumber" element={<Tracking />} />
                <Route path="/track" element={<CustomerService />} />
                <Route path="/unsubscribe/:vanityName/:trackingNumber" element={<Unsubscribe />} />
                <Route path="/return/pickup/cancel/:vanityName/labels/:labelId/key/:key" element={<CancelPickup />} />
                <Route path="/preview/:vanityName/:designId" element={<CustomerService />} />
                <Route path="/preview/published/tracking/:vanityName/:trackingNumber" element={<Tracking />} />
                <Route path="/preview/draft/tracking/:vanityName/:designId/:trackingNumber" element={<Tracking />} />
              </Route>
              <Route path="/docs/consumer-connect-sms-terms" element={<ConsumerConnectSmsTerms />} />
              <Route path="/docs/consumer-connect-tou" element={<ConsumerConnectTermsOfUse />} />
              <Route path="/docs/privacy-statement" element={<ConsumerConnectPrivacyStatement />} />
              <Route path="/docs/cookies-policy" element={<CookiePolicy />} />
              <Route path="/docs/*" element={<Navigate to="/track" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
        </BrowserRouter>
      </LanguageContextProvider>
    </LDProvider>,
    document.getElementById('root')
  );
})();

// <Route path="/preview/:vanityName/:designId" element={<CustomerService />} />   --> publisher: after make change --> click preview button --> choose customer service overview
// <Route path="/preview/published/tracking/:vanityName/:trackingNumber" element={<Tracking />} />   --> publisher live design overview ( smartphone view)
// <Route path="/preview/draft/tracking/:vanityName/:designId/:trackingNumber" element={<Tracking />} />   --> publisher: after make change --> click preview button --> choose tracking overview | AND | publisher: after make change --> click save button --> click publish button --> tracking overview
