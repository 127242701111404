import styled from '@emotion/styled';

export const ErrorContainer = styled.div`
  background: url('/assets/img/cs-background.png') repeat;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorContentWrapper = styled.section``;

export const ErrorTitle = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.bodyFontFamily};
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: 1.23;
  margin: 0 0 20px;
`;

export const ErrorSubtitle = styled.p`
  color: ${({ theme }) => theme.white};
  text-align: center;
  font-size: 13px;
  margin: 0;
`;

export const ErrorImage = styled.img``;
