import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const PackageTrackingWrapper = styled.div`
  margin: 25px 0 0;
  @media ${({ theme }) => theme.largeUp} {
    margin: 60px 0 0;
  }
`;

export const PackageTrackingReminder = styled.div`
  display: flex;
  align-items: center;
`;

export const PackageTrackingReminderIconContainer = styled.div`
  width: 29px;
  min-width: 25px;
  margin-right: 10px;
`;

export const PackageTrackingReminderIcon = styled.img`
  width: 100%;
`;

export const PackageTrackingReminderText = styled.div`
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
`;

export const PackageTrackingAdditionalParagraph = styled.p`
  font-size: 14px;
  margin: 20px 0 0;
`;

export const PackageTrackingAdditionalParagraphLink = styled.a`
  font-size: inherit;
`;
