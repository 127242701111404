import { useContext, useEffect, useState } from 'react';
import { enhanceHeadersParams } from 'utils/common/utils/utils';
import { ClientConfigContext } from '../../context/shared/ClientConfigContext';

export interface ErrorResponse {
  errorCode: 400 | 404 | 500 | 503;
  message: string | null | undefined;
}

export interface DefaultErrorResponse {
  error: {
    error: string;
    message: string;
    path: string;
    status: number;
  };
}

function useFetch<RESPONSE_TYPE, ERROR_RESPONSE_TYPE>(
  url: string | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any = null
): [RESPONSE_TYPE | null, boolean, ERROR_RESPONSE_TYPE | DefaultErrorResponse | null] {
  const [data, setData] = useState<RESPONSE_TYPE | null>(null);
  const [error, setError] = useState<ERROR_RESPONSE_TYPE | DefaultErrorResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const { brandId } = useContext(ClientConfigContext);

  const enhancedHeaders = params
    ? enhanceHeadersParams(brandId, params)
    : enhanceHeadersParams(brandId, { headers: [['Accept', 'application/json']] });
  const enhancedParams = Object.assign({}, { ...params, ...{ headers: enhancedHeaders } });

  async function fetchUrl(requestUrl: string) {
    try {
      const response: Response = await fetch(requestUrl, enhancedParams);

      if (!response.ok) {
        const errorMessage = await response.json();
        setError(errorMessage);
        return;
      }

      const json: RESPONSE_TYPE = await response.json();

      setData(json);
    } catch (error) {
      setError({
        error: {
          error: 'Internal Error',
          message: 'Internal Error',
          path: '',
          status: 500
        }
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (url) {
      fetchUrl(url);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
  return [data, loading, error];
}

export default useFetch;
