import { ProcessingLocation } from 'types/customer-service/find-parcels-api-response-type';

export const setProcessingLocationValue = (processingLocation: ProcessingLocation): string => {
  const { city, state } = processingLocation;

  if (!(city || state)) return '-';
  if (city && !state) return city;
  if (!city && state) return state;

  return `${city} ${state}`;
};
