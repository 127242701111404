import { ConvertedAttribute } from 'types/return/overview/products-selection/products-selection-form/converted-attribute-type';

export const resolveAttributeOptions = (attributes: ConvertedAttribute[], rootProductAttribute: string): string[] => {
  if (attributes.length === 0) return [];
  const attributeObject: ConvertedAttribute | undefined = attributes.find(
    (element: ConvertedAttribute) => element.attributeName === rootProductAttribute
  );
  if (attributeObject) return attributeObject.attributeOptions;
  return [];
};
