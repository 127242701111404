import React, { ChangeEvent, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from 'react';
import { AttributesListItemContainer, ProductTextModifierCapitalize } from './AttributesListItem.styled';
import { CustomLabel, SelectPlaceholder } from 'components/Returns/Common/CustomSelectElements/CustomSelectElements.styled';
import { isIE } from 'react-device-detect';
import { FormHelperText, MenuItem, Skeleton } from '@mui/material';
import { CustomFormControl } from 'components/Shared/styled-components/CustomFormControl/CustomFormControl.styled';
import { SelectedProducts, SelectedProductsErrors, ProductsSelectionContext } from 'context/returns/ProductsSelectionContext';
import { CustomSelect } from 'components/Shared/styled-components/CustomSelect/CustomSelect.styled';
import { AttributesSelectedOption } from 'types/return/overview/products-selection/products-selection-form/attributes-selected-option';
import { resolveSelectEnabled } from 'utils/returns/overview/products-selection/resolve-select-enabled/resolve-select-enabled';
import { resolveAttributeOptions } from 'utils/returns/overview/products-selection/resolve-attribute-options/resolve-attribute-options';
import { useIntl } from 'react-intl';

export const AttributesListItem: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { rootProductAttribute, setAttributesSelectedOptions, rootProductId, exchangeOptionIndex, isDataLoading } = props;

  const { setSelectedProducts, selectedProducts, setSelectedProductsErrors, selectedProductsErrors } = useContext(ProductsSelectionContext);

  const [attributeOptions, setAttributeOptions] = useState<string[]>(
    resolveAttributeOptions(
      selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex] &&
        selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].attributes
        ? selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].attributes
        : [],
      rootProductAttribute
    )
  );
  const [isSelectEnabled, setIsSelectEnabled] = useState<boolean>(
    resolveSelectEnabled(
      isDataLoading,
      selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex] &&
        selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].attributes
        ? selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].attributes
        : [],
      rootProductAttribute
    )
  );

  const intl = useIntl();

  useEffect(() => {
    if (
      selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex] &&
      selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].attributes
    )
      setIsSelectEnabled(
        resolveSelectEnabled(isDataLoading, selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].attributes, rootProductAttribute)
      );
    if (
      selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex] &&
      selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].attributes
    )
      setAttributeOptions(
        resolveAttributeOptions(selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].attributes, rootProductAttribute)
      );
  }, [isDataLoading, rootProductAttribute, selectedProducts, rootProductId, exchangeOptionIndex]);

  useEffect(() => {
    if (
      attributeOptions.length > 0 &&
      selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex] &&
      selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex][rootProductAttribute] &&
      selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex][rootProductAttribute] !== '' &&
      !attributeOptions.includes(selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex][rootProductAttribute])
    ) {
      setSelectedProducts((prevState: SelectedProducts) => {
        return {
          ...prevState,
          [rootProductId]: {
            ...prevState[rootProductId],
            exchangeOptionsAttributes: {
              ...prevState[rootProductId].exchangeOptionsAttributes,
              [exchangeOptionIndex]: {
                ...prevState[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex],
                [rootProductAttribute]: ''
              }
            }
          }
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeOptions, rootProductAttribute, exchangeOptionIndex, rootProductId]);

  const handleClickStopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleChangeAttributeSelectedOption = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { target } = event;
    const { value } = target;
    setAttributesSelectedOptions((prevState: AttributesSelectedOption[]) => [
      { attributeName: rootProductAttribute, selectedOption: !!value ? value : null },
      ...prevState
    ]);
    setSelectedProducts((prevState: SelectedProducts) => {
      return {
        ...prevState,
        [rootProductId]: {
          ...prevState[rootProductId],
          exchangeOptionsAttributes: {
            ...prevState[rootProductId].exchangeOptionsAttributes,
            [exchangeOptionIndex]: {
              ...prevState[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex],
              [rootProductAttribute]: value
            }
          }
        }
      };
    });

    if (value.length === 0)
      return setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
        return {
          ...prevState,
          [rootProductId]: {
            ...prevState[rootProductId],
            exchangeOptionsAttributesErrors: {
              ...prevState[rootProductId].exchangeOptionsAttributesErrors,
              [exchangeOptionIndex]: {
                ...prevState[rootProductId].exchangeOptionsAttributesErrors[exchangeOptionIndex],
                [rootProductAttribute]: intl.formatMessage(
                  {
                    id:
                      'RETURN.overview.productsSelection.productsSelectionForm.product.exchangeOptionsList.exchangeOptionsListItem.attributesList.attributesListItem.attribute.select.error.empty'
                  },
                  { attribute: rootProductAttribute }
                )
              }
            }
          }
        };
      });
    return setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
      return {
        ...prevState,
        [rootProductId]: {
          ...prevState[rootProductId],
          exchangeOptionsAttributesErrors: {
            ...prevState[rootProductId].exchangeOptionsAttributesErrors,
            [exchangeOptionIndex]: {
              ...prevState[rootProductId].exchangeOptionsAttributesErrors[exchangeOptionIndex],
              [rootProductAttribute]: null
            }
          }
        }
      };
    });
  };

  if (
    !selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex] ||
    !selectedProductsErrors[rootProductId].exchangeOptionsAttributesErrors[exchangeOptionIndex]
  )
    return null;
  return (
    <AttributesListItemContainer data-test-id="attribute">
      <CustomFormControl
        data-test-id={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-form-control`}
        variant="outlined"
        width="100%"
        size="small">
        {isIE ? (
          <CustomLabel
            data-test-id={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-label`}
            htmlFor={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-select`}>
            {intl.formatMessage(
              {
                id:
                  'RETURN.overview.productsSelection.productsSelectionForm.product.exchangeOptionsList.exchangeOptionsListItem.attributesList.attributesListItem.attribute.select.label'
              },
              { attribute: <ProductTextModifierCapitalize>{rootProductAttribute}</ProductTextModifierCapitalize> }
            )}
          </CustomLabel>
        ) : (
          <CustomLabel
            data-test-id={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-label`}
            id={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-select`}>
            {intl.formatMessage(
              {
                id:
                  'RETURN.overview.productsSelection.productsSelectionForm.product.exchangeOptionsList.exchangeOptionsListItem.attributesList.attributesListItem.attribute.select.label'
              },
              { attribute: <ProductTextModifierCapitalize>{rootProductAttribute}</ProductTextModifierCapitalize> }
            )}
          </CustomLabel>
        )}
        {isIE ? (
          <CustomSelect
            disabled={!isSelectEnabled || isDataLoading}
            id={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-select`}
            aria-describedby={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-select-error-text`}
            native
            data-error-id={!!selectedProductsErrors[rootProductId].exchangeOptionsAttributesErrors[exchangeOptionIndex][rootProductAttribute]}
            error={!!selectedProductsErrors[rootProductId].exchangeOptionsAttributesErrors[exchangeOptionIndex][rootProductAttribute]}
            value={selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex][rootProductAttribute]}
            // @ts-ignore
            onClick={(event: ChangeEvent<HTMLSelectElement>) => handleClickStopPropagation(event)}
            // @ts-ignore
            onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeAttributeSelectedOption(event)}
            required
            displayEmpty
            inputProps={{
              'data-test-id': `attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-select`
            }}>
            {isDataLoading ? (
              <option value="">Loading...</option>
            ) : (
              <option value="">
                {intl.formatMessage({
                  id:
                    'RETURN.overview.productsSelection.productsSelectionForm.product.exchangeOptionsList.exchangeOptionsListItem.attributesList.attributesListItem.attribute.select.placeholder'
                })}
              </option>
            )}
            {attributeOptions.map((element: string) => (
              <option key={element} value={element}>
                {element}
              </option>
            ))}
          </CustomSelect>
        ) : (
          <CustomSelect
            disabled={!isSelectEnabled || isDataLoading}
            data-error-id={!!selectedProductsErrors[rootProductId].exchangeOptionsAttributesErrors[exchangeOptionIndex][rootProductAttribute]}
            error={!!selectedProductsErrors[rootProductId].exchangeOptionsAttributesErrors[exchangeOptionIndex][rootProductAttribute]}
            value={selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex][rootProductAttribute]}
            // @ts-ignore
            onClick={(event: ChangeEvent<HTMLSelectElement>) => handleClickStopPropagation(event)}
            // @ts-ignore
            onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeAttributeSelectedOption(event)}
            required
            labelId={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-select`}
            aria-describedby={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-select-error-text`}
            displayEmpty
            inputProps={{
              'data-test-id': `attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-select`
            }}>
            {isDataLoading ? (
              <MenuItem value="">
                <Skeleton />
              </MenuItem>
            ) : (
              <MenuItem value="">
                <SelectPlaceholder>
                  {intl.formatMessage({
                    id:
                      'RETURN.overview.productsSelection.productsSelectionForm.product.exchangeOptionsList.exchangeOptionsListItem.attributesList.attributesListItem.attribute.select.placeholder'
                  })}
                </SelectPlaceholder>
              </MenuItem>
            )}
            {attributeOptions.map((element: string) => (
              <MenuItem key={element} value={element}>
                {element}
              </MenuItem>
            ))}
          </CustomSelect>
        )}
        {isSelectEnabled && !!selectedProductsErrors[rootProductId].exchangeOptionsAttributesErrors[exchangeOptionIndex][rootProductAttribute] && (
          <FormHelperText
            disabled={!isSelectEnabled || isDataLoading}
            id={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-select-error-text`}
            data-test-id={`attribute-${rootProductId}-${exchangeOptionIndex}__${rootProductAttribute}-select-error-text`}>
            <ProductTextModifierCapitalize>
              {selectedProductsErrors[rootProductId].exchangeOptionsAttributesErrors[exchangeOptionIndex][rootProductAttribute]}
            </ProductTextModifierCapitalize>
          </FormHelperText>
        )}
      </CustomFormControl>
    </AttributesListItemContainer>
  );
};

export interface OwnProps {
  rootProductAttribute: string;
  isDataLoading: boolean;
  setAttributesSelectedOptions: React.Dispatch<SetStateAction<AttributesSelectedOption[]>>;
  rootProductId: string;
  exchangeOptionIndex: number;
}
