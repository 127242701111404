import React, { PropsWithChildren } from 'react';
import { HoursListItemContainer } from './HoursListItem.styled';

export const HoursListItem: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { days, hours } = props;

  return <HoursListItemContainer data-test-id="post-office-hours-list-item">{`${days} ${hours}`}</HoursListItemContainer>;
};

export interface OwnProps {
  days: string;
  hours: string;
}
