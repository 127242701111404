import styled from '@emotion/styled';
import { getCustomStyle } from '../../../../../../../../utils/common/custom-styles/custom-styles';

export const ExchangeOptionContainer = styled.li`
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
export const ExchangeOptionWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ExchangeOptionImageContainer = styled.div`
  width: 140px;
  margin-right: 18px;
`;

export const ExchangeOptionDetails = styled.div`
  flex: 2;
`;

export const ExchangeOptionDetailsWrapper = styled.div`
  @media ${({ theme }) => theme.largeUp} {
    margin: 0 0 20px 0;
  }
`;

export const ExchangeOptionName = styled.p`
  margin: 0;
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;

export const ExchangeOptionSku = styled.p`
  margin: 0 0 20px 0;
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;

export const ExchangeOptionTextModifier = styled.span`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;
