import { styled } from '@mui/material';
import { Button } from '@mui/material';
import { rgba } from 'polished';

interface CustomButtonProps {
  width?: string;
  maxHeight?: string;
  minWidth?: string;
  marginDefault?: string;
  widthMd?: string;
  marginMd?: string;
  widthXl?: string;
  marginXl?: string;
  textAlignCenter?: boolean;
  fontSize?: string;
  padding?: string;
  isUppercase?: boolean;
  borderRadius?: string;
  transitionDuration?: string;
}
export const CustomButton = styled(Button, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== 'width' &&
    prop !== 'maxHeight' &&
    prop !== 'minWidth' &&
    prop !== 'marginDefault' &&
    prop !== 'widthMd' &&
    prop !== 'marginMd' &&
    prop !== 'widthXl' &&
    prop !== 'marginXl' &&
    prop !== 'textAlignCenter' &&
    prop !== 'fontSize' &&
    prop !== 'padding' &&
    prop !== 'isUppercase' &&
    prop !== 'borderRadius' &&
    prop !== 'transitionDuration'
})<CustomButtonProps>(
  ({
    theme,
    width,
    maxHeight,
    minWidth,
    marginDefault,
    widthMd,
    marginMd,
    widthXl,
    marginXl,
    isUppercase,
    variant,
    size,
    borderRadius,
    transitionDuration
  }) => ({
    width: width ? width : 'auto',
    maxHeight: maxHeight ? maxHeight : 'auto',
    minWidth: minWidth ? minWidth : 'auto',

    margin: marginDefault ? marginDefault : '0',
    textTransform: isUppercase ? 'uppercase' : 'capitalize',
    transition: `background ${transitionDuration ? transitionDuration : '0.3s'} ease-in-out`,
    fontSize: size === 'small' ? '13px' : '16px',
    fontWeight: '400 !important',
    boxShadow: 'none',
    textDecoration: 'none',
    backgroundColor: variant === 'text' || variant === 'outlined' ? 'transparent !important' : `${theme.palette.secondary.main} !important`,
    color:
      variant === 'text' || variant === 'outlined'
        ? theme.palette.secondary.main
        : theme.palette.button.secondaryBackgroundDark
        ? theme.palette.common.white
        : '#0000008A',

    '&:hover': {
      boxShadow: 'none',
      textDecoration: 'none',
      border: variant === 'outlined' ? `1px solid ${theme.palette.secondary.light} !important` : 'none',
      backgroundColor:
        variant === 'outlined' ? rgba(30, 136, 229, 0.08) : variant === 'text' ? 'transparent' : `${theme.palette.secondary.light} !important`,
      transition: 'background 0.3s ease-in-out',
      color:
        variant === 'text' || variant === 'outlined'
          ? theme.palette.secondary.main
          : theme.palette.button.secondaryBackgroundDark
          ? theme.palette.common.white
          : theme.palette.common.white
    },

    border: variant === 'outlined' ? `1px solid ${theme.palette.secondary.main} !important` : 'none',

    borderRadius: borderRadius
      ? borderRadius
      : theme.palette.button.buttonStyle === 'Rounded'
      ? '5px'
      : theme.palette.button.buttonStyle === 'Pill'
      ? '40px'
      : '0',

    [theme.breakpoints.up('medium')]: {
      width: widthMd && widthMd,
      margin: marginMd && marginMd
    },

    [theme.breakpoints.up('xlarge')]: {
      width: widthXl && widthXl,
      margin: marginXl && marginXl
    },

    '&.Mui-disabled': {
      opacity: 0.8,
      color:
        variant === 'text' || variant === 'outlined'
          ? theme.palette.secondary.main
          : theme.palette.button.secondaryBackgroundDark
          ? theme.palette.common.white
          : '#0000008A'
    }
  })
);
