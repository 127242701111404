import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Media() {
  const [backgroundClass, setBackgroundClass] = useState('r-background-birthday');
  const item = localStorage.getItem('occasion');

  useEffect(() => {
    // set the background as per the occasion
    if (item) setBackgroundClass(`r-background-${item.toLowerCase()}`);
  }, [item]);

  const navigate = useNavigate();
  const onPhotoSubmit = (e) => {
    e.preventDefault();
    navigate('/send/gift/photo');
  };

  const onVideoSubmit = (e) => {
    e.preventDefault();
    navigate('/send/gift/video');
  };

  const brand = localStorage.getItem('brandName');
  const trackingnumber = localStorage.getItem('tracking');

  const handleback = () => {
    navigate('/send/gift/' + brand + '/' + trackingnumber);
  };
  return (
    <div>
      <h1 className="media_selection_title">
        <span className="mobile-lefticon" onClick={handleback}>
          <img src="/assets/img/left-icon.png" />
        </span>
        What kind of message would you like to send ?
      </h1>
      <div className={`media_selection_background r-background ${backgroundClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="video-container" onClick={onVideoSubmit}>
                <div className="icon-div">
                  <img src="/assets/img/icon_recorder.svg" />
                </div>
                <div className="title-div">
                  <p>Video</p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="photo-container" onClick={onPhotoSubmit}>
                <div className="icon-div">
                  <img src="/assets/img/icon_camera.svg" />
                </div>
                <div className="title-div">
                  <p>Photo with text</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
