import React, { PropsWithChildren } from 'react';
import { BannersContainer, BannersContent } from './BannersLayout.styled';

export const BannersLayout: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { children, marginDefault } = props;
  return (
    <BannersContainer marginDefault={marginDefault}>
      <BannersContent>{children}</BannersContent>
    </BannersContainer>
  );
};

interface OwnProps {
  marginDefault?: string;
  children: React.ReactNode;
}
