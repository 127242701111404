import React from 'react';
import { PolicyLayout } from 'layouts/ConsumerConnectPolicy/PolicyLayout/PolicyLayout';
import {
  PrivacyStatementContainer,
  PrivacyStatementParagraph,
  PrivacyStatementTableOfContents,
  PrivacyStatementTableOfContentsItem,
  PrivacyStatementTableOfContentsLink,
  PrivacyStatementChapters,
  PrivacyStatementChapter,
  PrivacyStatementChapterTitle,
  PrivacyStatementList,
  PrivacyStatementListItem,
  AccordionBox,
  CustomAccordion,
  CustomAccordionSummary,
  CustomAccordionDetails,
  AccordionTitle,
  JapanPolicyMarkImgBox,
  JapanPolicyMarkImg,
  EmailLink,
  NonLinkedUrl
} from './ConsumerConnectPrivacyStatement.styled';
import { useIntl } from 'react-intl';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { BackToTopButton } from 'components/Shared/BackToTopButton/BackToTopButton';
import { PBOutlineIconsEnum } from 'constants/PBIconsEnums';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';

interface TableOfContentsInterface {
  id: string;
  title: string;
}

export const ConsumerConnectPrivacyStatement = () => {
  const intl = useIntl();

  const [isAccordionExpanded, setIsAccordionExpanded] = React.useState<string | false>(false);

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setIsAccordionExpanded(isExpanded ? panel : false);
  };

  const TABLE_OF_CONTENTS: TableOfContentsInterface[] = [
    {
      id: 'personal',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.1'
    },
    {
      id: 'other',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.2'
    },
    {
      id: 'retaining',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.3'
    },
    {
      id: 'sharing',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.4'
    },
    {
      id: 'protecting',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.5'
    },
    {
      id: 'cross',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.6'
    },
    {
      id: 'visiting',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.7'
    },
    {
      id: 'shield',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.8'
    },
    {
      id: 'specific',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.9'
    },
    {
      id: 'product',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.10'
    },
    {
      id: 'choices',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.11'
    },
    {
      id: 'rights',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.12'
    },
    {
      id: 'changes',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.13'
    },
    {
      id: 'contact',
      title: 'POLICY.PRIVACY_STATEMENT.tableOfContent.item.14'
    }
  ];

  return (
    <PolicyLayout
      pageTitle={intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.pageTitle' })}
      title={intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.title' })}
      subtitle={intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.subtitle' })}>
      <PrivacyStatementContainer>
        <PrivacyStatementParagraph margin="0 0 10px">
          {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.overview.part1' })}
        </PrivacyStatementParagraph>
        <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.overview.part2' })}</PrivacyStatementParagraph>
        <PrivacyStatementParagraph isBolded>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.tableOfContent.title' })}</PrivacyStatementParagraph>
        <PrivacyStatementTableOfContents>
          {TABLE_OF_CONTENTS.map((item: TableOfContentsInterface) => (
            <PrivacyStatementTableOfContentsItem key={item.id}>
              <PrivacyStatementTableOfContentsLink href={`#${item.id}`}>{intl.formatMessage({ id: item.title })}</PrivacyStatementTableOfContentsLink>
            </PrivacyStatementTableOfContentsItem>
          ))}
        </PrivacyStatementTableOfContents>
        <PrivacyStatementChapters>
          {/* Collecting Personal Information from Site Users */}
          <PrivacyStatementChapter id="personal">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter1.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter1.content1' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter1.content2' })}</PrivacyStatementParagraph>
            <PrivacyStatementList listStyle="disc">
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter1.content2.item1' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter1.content2.item2' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter1.content2.item3' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter1.content2.item4' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter1.content2.item5' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter1.content2.item6' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
            </PrivacyStatementList>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter1.content3' })}</PrivacyStatementParagraph>
          </PrivacyStatementChapter>

          {/* Collecting Other Information */}
          <PrivacyStatementChapter id="other">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.content1' })}</PrivacyStatementParagraph>
            <AccordionBox>
              <CustomAccordion expanded={isAccordionExpanded === 'other1'} onChange={handleAccordionChange('other1')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="other-content-1"
                  id="other-header-1">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item1.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item1.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item1.content2' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item1.content3' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 10px 40px" isBolded>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item1.content4' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 10px 80px" isBolded>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item1.content5' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 10px 80px">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item1.content6' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 0 80px">
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item1.content7' },
                      {
                        url: <NonLinkedUrl>https://www.leadfeeder.com/privacy</NonLinkedUrl>
                      }
                    )}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'other2'} onChange={handleAccordionChange('other2')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="other-content-2"
                  id="other-header-2">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item2.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item2.content1' })}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'other3'} onChange={handleAccordionChange('other3')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="other-content-3"
                  id="other-header-3">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item3.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item3.content1' },
                      {
                        url: <NonLinkedUrl>https://cross-device-privacy.adobe.com</NonLinkedUrl>
                      }
                    )}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'other4'} onChange={handleAccordionChange('other4')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="other-content-4"
                  id="other-header-4">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item4.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item4.content1' })}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'other5'} onChange={handleAccordionChange('other5')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="other-content-5"
                  id="other-header-5">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item5.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item5.content1' })}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'other6'} onChange={handleAccordionChange('other6')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="other-content-6"
                  id="other-header-6">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item6.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter2.accordion.item6.content1' })}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
            </AccordionBox>
          </PrivacyStatementChapter>

          {/* Using and Retaining Personal Information */}
          <PrivacyStatementChapter id="retaining">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter3.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter3.content1' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter3.content2' })}</PrivacyStatementParagraph>
          </PrivacyStatementChapter>

          {/* Sharing Personal Information */}
          <PrivacyStatementChapter id="sharing">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content1' })}</PrivacyStatementParagraph>
            <PrivacyStatementList listStyle="numeric">
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content1.list.item1' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content1.list.item2' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content1.list.item3' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
            </PrivacyStatementList>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content2' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content3' })}</PrivacyStatementParagraph>
            <PrivacyStatementList listStyle="numeric">
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content3.list.item1' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content3.list.item2' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content3.list.item3' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
            </PrivacyStatementList>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content4' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter4.content5' })}</PrivacyStatementParagraph>
          </PrivacyStatementChapter>

          {/* Protecting Personal Information */}
          <PrivacyStatementChapter id="protecting">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter5.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter5.content1' })}</PrivacyStatementParagraph>
          </PrivacyStatementChapter>

          {/* Cross Border Data Transfer */}
          <PrivacyStatementChapter id="cross">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter6.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter6.content1' })}</PrivacyStatementParagraph>
            <PrivacyStatementList listStyle="disc">
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter6.content1.list.item1' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter6.content1.list.item2' })}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
            </PrivacyStatementList>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter6.content2' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter6.content3' })}</PrivacyStatementParagraph>
          </PrivacyStatementChapter>

          {/* Visiting Nonaffiliated Pitney Bowes Websites */}
          <PrivacyStatementChapter id="visiting">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter7.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter7.content1' })}</PrivacyStatementParagraph>
          </PrivacyStatementChapter>

          {/* EU-U.S. and Swiss-U.S. Privacy Shield Compliance */}
          <PrivacyStatementChapter id="shield">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter8.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter8.content1' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph>
              {intl.formatMessage(
                { id: 'POLICY.PRIVACY_STATEMENT.chapter8.content2' },
                {
                  url: <NonLinkedUrl>https://www.privacyshield.gov/</NonLinkedUrl>
                }
              )}
            </PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter8.content3' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter8.content4' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter8.content5' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph>
              {intl.formatMessage(
                { id: 'POLICY.PRIVACY_STATEMENT.chapter8.content6' },
                {
                  url: <NonLinkedUrl>https://www.jamsadr.com/eu-us-privacy-shield.</NonLinkedUrl>
                }
              )}
            </PrivacyStatementParagraph>
            <PrivacyStatementParagraph>
              {intl.formatMessage(
                { id: 'POLICY.PRIVACY_STATEMENT.chapter8.content7' },
                {
                  url: <NonLinkedUrl>https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint</NonLinkedUrl>
                }
              )}
            </PrivacyStatementParagraph>
          </PrivacyStatementChapter>

          {/* Country-Specific Requirements */}
          <PrivacyStatementChapter id="specific">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.content1' })}</PrivacyStatementParagraph>
            <AccordionBox>
              <CustomAccordion expanded={isAccordionExpanded === 'specific1'} onChange={handleAccordionChange('specific1')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="specific-content-1"
                  id="specific-header-1">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item1.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item1.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item1.content2' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item1.content3' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item1.content4' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item1.content5' },
                      {
                        url: <NonLinkedUrl>https://www.facebook.com/legal/terms/page_controller_addendum.</NonLinkedUrl>
                      }
                    )}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item1.content6' })}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'specific2'} onChange={handleAccordionChange('specific2')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="specific-content-2"
                  id="specific-header-2">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item2.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item2.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item2.content2' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item2.content3' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item2.content4' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item2.content5' },
                      { url: <NonLinkedUrl>https://www.facebook.com/legal/terms/page_controller_addendum</NonLinkedUrl> }
                    )}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item2.content6' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 0">
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item2.content7' },
                      {
                        url: <NonLinkedUrl>https://www.facebook.com/privacy/explanation</NonLinkedUrl>
                      }
                    )}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'specific3'} onChange={handleAccordionChange('specific3')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="specific-content-3"
                  id="specific-header-3">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item3.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item3.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item3.content2' })}
                  </PrivacyStatementParagraph>
                  <JapanPolicyMarkImgBox>
                    <JapanPolicyMarkImg src="/assets/img/docs/japan-privacy-mark.png" />
                  </JapanPolicyMarkImgBox>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'specific4'} onChange={handleAccordionChange('specific4')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="specific-content-4"
                  id="specific-header-4">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item4.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item4.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item4.content2' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item4.content3' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item4.content4' })}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'specific5'} onChange={handleAccordionChange('specific5')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="specific-content-5"
                  id="specific-header-5">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.content2' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.content3' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.content4' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementList listStyle="numeric">
                    <PrivacyStatementListItem>
                      <PrivacyStatementParagraph>
                        {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.content4.list.item1' })}
                      </PrivacyStatementParagraph>
                    </PrivacyStatementListItem>
                    <PrivacyStatementListItem>
                      <PrivacyStatementParagraph>
                        {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.content4.list.item2' })}
                      </PrivacyStatementParagraph>
                    </PrivacyStatementListItem>
                    <PrivacyStatementListItem>
                      <PrivacyStatementParagraph>
                        {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.content4.list.item3' })}
                      </PrivacyStatementParagraph>
                    </PrivacyStatementListItem>
                  </PrivacyStatementList>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.content5' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.content6' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter9.accordion.item5.content7' })}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
            </AccordionBox>
          </PrivacyStatementChapter>

          {/* Product-Specific Statements */}
          <PrivacyStatementChapter id="product">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter10.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter10.content1' })}</PrivacyStatementParagraph>
            <PrivacyStatementList listStyle="disc">
              <PrivacyStatementListItem>
                <PrivacyStatementParagraph>
                  {intl.formatMessage(
                    { id: 'POLICY.PRIVACY_STATEMENT.chapter10.content1.list.item1' },
                    {
                      url: <NonLinkedUrl>https://www.pitneybowes.com/us/legal/personal-shipping.html</NonLinkedUrl>
                    }
                  )}
                </PrivacyStatementParagraph>
              </PrivacyStatementListItem>
            </PrivacyStatementList>
          </PrivacyStatementChapter>

          {/* Changes to this Privacy Statement */}
          <PrivacyStatementChapter id="choices">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter11.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter11.content1' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph margin="0 0 10px">
              {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter11.content2' })}
            </PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter11.content3' })}</PrivacyStatementParagraph>
          </PrivacyStatementChapter>

          {/* Changes to this Privacy Statement */}
          <PrivacyStatementChapter id="rights">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter12.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>
              {intl.formatMessage(
                { id: 'POLICY.PRIVACY_STATEMENT.chapter12.content1' },
                {
                  url: (
                    <NonLinkedUrl>
                      https://privacyportal-cdn.onetrust.com/dsarwebform/08eb8ffe-b5ab-4462-bb85-f09b3f935399/f6fd82a6-6ec4-4749-b131-246123e1b025.html
                    </NonLinkedUrl>
                  )
                }
              )}
            </PrivacyStatementParagraph>
          </PrivacyStatementChapter>

          {/* Changes to this Privacy Statement */}
          <PrivacyStatementChapter id="changes">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter13.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter13.content1' })}</PrivacyStatementParagraph>
          </PrivacyStatementChapter>

          {/* Contact Us */}
          <PrivacyStatementChapter id="contact">
            <PrivacyStatementChapterTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.title' })}</PrivacyStatementChapterTitle>
            <PrivacyStatementParagraph>
              {intl.formatMessage(
                { id: 'POLICY.PRIVACY_STATEMENT.chapter14.content1' },
                {
                  url: (
                    <NonLinkedUrl>
                      https://privacyportal.onetrust.com/ui/#/preferences/multipage/login/ab2351c1-5023-4d07-89ab-249014bde70d
                    </NonLinkedUrl>
                  )
                }
              )}
            </PrivacyStatementParagraph>
            <PrivacyStatementParagraph>
              {intl.formatMessage(
                { id: 'POLICY.PRIVACY_STATEMENT.chapter14.content2' },
                {
                  url: (
                    <NonLinkedUrl>
                      http://support.pitneybowes.com/SearchArticles/VFP05_KnowledgeWithSidebarHowTo?id=kA180000000Cs4SCAS&popup=false&lang=en_US
                    </NonLinkedUrl>
                  )
                }
              )}
            </PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.content3' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph margin="0">
              {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.content4' })}
            </PrivacyStatementParagraph>
            <PrivacyStatementParagraph margin="0">
              {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.content5' })}
            </PrivacyStatementParagraph>
            <PrivacyStatementParagraph margin="0">
              {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.content6' })}
            </PrivacyStatementParagraph>
            <PrivacyStatementParagraph margin="0">
              {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.content7' })}
            </PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.content8' })}</PrivacyStatementParagraph>
            <PrivacyStatementParagraph>
              {intl.formatMessage(
                { id: 'POLICY.PRIVACY_STATEMENT.chapter14.content9' },
                { email: <EmailLink href="mailto:privacyoffice@pb.com">privacyoffice@pb.com</EmailLink> }
              )}
            </PrivacyStatementParagraph>
            <PrivacyStatementParagraph>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.content10' })}</PrivacyStatementParagraph>
            <AccordionBox>
              <CustomAccordion expanded={isAccordionExpanded === 'contact1'} onChange={handleAccordionChange('contact1')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="contact-content-1"
                  id="contact-header-1">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.content2' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.content3' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.content4' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.content5' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.content6' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.content7' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.content8' },
                      { email: <EmailLink href="mailto:rivacymatters.au@pb.com">rivacymatters.au@pb.com</EmailLink> }
                    )}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.content9' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 0">
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item1.content10' },
                      { email: <EmailLink href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</EmailLink> }
                    )}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'contact2'} onChange={handleAccordionChange('contact2')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="contact-content-2"
                  id="contact-header-2">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content2' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content3' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content4' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content5' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content6' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content7' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content8' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content9' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content10' },
                      { email: <EmailLink href="mailto:marcel.cordeiro@pb.com">marcel.cordeiro@pb.com</EmailLink> }
                    )}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content11' },
                      { email: <EmailLink href="mailto:privacyoffice@pb.com">privacyoffice@pb.com</EmailLink> }
                    )}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item2.content12' })}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'contact3'} onChange={handleAccordionChange('contact3')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="contact-content-3"
                  id="contact-header-3">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item3.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item3.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item3.content2' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item3.content3' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item3.content4' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item3.content5' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item3.content6' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item3.content7' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 0">
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item3.content8' },
                      { email: <EmailLink href="mailto:datenschutzbeauftragter@pb.com">datenschutzbeauftragter@pb.com</EmailLink> }
                    )}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'contact4'} onChange={handleAccordionChange('contact4')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="contact-content-4"
                  id="contact-header-4">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.content2' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.content3' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.content4' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.content5' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.content6' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.content7' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.content8' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.content9' },
                      { email: <EmailLink href="mailto:ruchi.bhalla@pb.com">ruchi.bhalla@pb.com</EmailLink> }
                    )}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item4.content10' },
                      { email: <EmailLink href="mailto:privacyoffice@pb.com">privacyoffice@pb.com</EmailLink> }
                    )}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion expanded={isAccordionExpanded === 'contact5'} onChange={handleAccordionChange('contact5')}>
                <CustomAccordionSummary
                  expandIcon={<PBIcon name={PBOutlineIconsEnum.MinimalDown} type={PBIconTypeEnum.Outline} />}
                  aria-controls="contact-content-5"
                  id="contact-header-5">
                  <AccordionTitle>{intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item5.title' })}</AccordionTitle>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  <PrivacyStatementParagraph margin="0 0 10px 0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item5.content1' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item5.content2' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item5.content3' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item5.content4' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item5.content5' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="0">
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item5.content6' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item5.content7' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph>
                    {intl.formatMessage({ id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item5.content8' })}
                  </PrivacyStatementParagraph>
                  <PrivacyStatementParagraph margin="10px 0 0">
                    {intl.formatMessage(
                      { id: 'POLICY.PRIVACY_STATEMENT.chapter14.accordion.item5.content9' },
                      { email: <EmailLink href="mailto:privacyoffice@pb.com">privacyoffice@pb.com</EmailLink> }
                    )}
                  </PrivacyStatementParagraph>
                </CustomAccordionDetails>
              </CustomAccordion>
            </AccordionBox>
          </PrivacyStatementChapter>

          {/* END */}
        </PrivacyStatementChapters>
        <BackToTopButton fontFamily={`'Precision Sans W', sans-serif`} fontColor={`#3E53A4`} visibleAbove={400} />
      </PrivacyStatementContainer>
    </PolicyLayout>
  );
};
