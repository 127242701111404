export interface ExchangeOptionAttributesFetchDataError {
  error: string | null;
}
export type ExchangeOptionsAttributesFetchDataError = Record<number, ExchangeOptionAttributesFetchDataError>;

export type KeyValuePairAttributesErrors = Record<string, string | null>;
export type ExchangeOptionsAttributesErrors = Record<number, KeyValuePairAttributesErrors>;

export class SelectedProductErrors {
  reasonError: string | null;
  selectedQuantityError: string | null;
  reasonCommentError: string | null;
  exchangeOptionsAttributesFetchDataError: ExchangeOptionsAttributesFetchDataError;
  exchangeOptionsAttributesErrors: ExchangeOptionsAttributesErrors;

  constructor(exchangeOptionsAttributesErrors: ExchangeOptionsAttributesErrors) {
    this.reasonError = null;
    this.selectedQuantityError = null;
    this.reasonCommentError = null;
    this.exchangeOptionsAttributesFetchDataError = {};
    this.exchangeOptionsAttributesErrors = exchangeOptionsAttributesErrors;
  }
}
