import React, { useEffect, useState } from 'react';
import { Container } from './OrderNumberParcelsList.styled';
import { SortedParcels } from './SortedParcels/SortedParcels';
import { useIntl } from 'react-intl';
import { Parcel, ParcelTypeEnum } from 'types/customer-service/find-parcels-api-response-type';
import { sortParcelsByType } from 'utils/customer-service/sort-parcels-by-type/sort-parcels-by-type';
import { PBMiniIconsEnum } from 'constants/PBIconsEnums';

export const OrderNumberParcelsList: React.FC<OwnProps> = (props) => {
  const { parcels } = props;

  const intl = useIntl();

  const [deliveryParcels, setDeliveryParcels] = useState<Parcel[]>(sortParcelsByType(parcels).deliveryParcels);
  const [returnParcels, setReturnParcels] = useState<Parcel[]>(sortParcelsByType(parcels).returnParcels);
  const [unknownParcels, setUnknownParcels] = useState<Parcel[]>(sortParcelsByType(parcels).unknownParcels);

  // TODO: set delivery etc immediatelly and in useEffect in case it will be changed
  useEffect(() => {
    const sortedParcels = sortParcelsByType(parcels);
    const { deliveryParcels, returnParcels, unknownParcels } = sortedParcels;

    setDeliveryParcels(deliveryParcels);
    setReturnParcels(returnParcels);
    setUnknownParcels(unknownParcels);
  }, [parcels]);

  return (
    <Container data-test-id="order-number-parcels-list">
      {deliveryParcels.length > 0 && (
        <SortedParcels
          sortedParcels={deliveryParcels}
          iconName={PBMiniIconsEnum.DeliveryFast}
          parcelsType={ParcelTypeEnum.DELIVERY}
          subheading={intl.formatMessage({ id: 'CS.parcels.parcelsList.orderNumber.delivery.subheading' })}
        />
      )}
      {returnParcels.length > 0 && (
        <SortedParcels
          sortedParcels={returnParcels}
          iconName={PBMiniIconsEnum.Undo}
          parcelsType={ParcelTypeEnum.RETURN}
          subheading={intl.formatMessage({ id: 'CS.parcels.parcelsList.orderNumber.return.subheading' })}
        />
      )}
      {unknownParcels.length > 0 && (
        <SortedParcels
          sortedParcels={unknownParcels}
          iconName={PBMiniIconsEnum.AlertCircle}
          parcelsType={ParcelTypeEnum.UNKNOWN}
          subheading={intl.formatMessage({ id: 'CS.parcels.parcelsList.orderNumber.unknown.subheading' })}
        />
      )}
    </Container>
  );
};

export interface OwnProps {
  parcels: Parcel[];
}
