import styled from '@emotion/styled';
import { TableRow } from '@mui/material';
import { styled as muiStyled } from '@mui/system';

export const SingleTableRow = muiStyled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'shouldHavePointer'
})<SingleTableRowProps>(({ shouldHavePointer }) => ({
  cursor: shouldHavePointer ? 'pointer' : 'default'
}));

export const IconBox = styled.span`
  display: inline-block;
  width: 20px;
`;

export const EstimatedDeliveryTime = styled.span`
  font-size: 12px;
`;

interface SingleTableRowProps {
  shouldHavePointer: boolean;
}
