import React, { useState, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { LanguageValueType } from 'types/customer-service/languages-types';
import en from 'locale/en.json';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import { languageValueToDayJsLocaleFileMapper } from 'constants/language-code-value-to-day-js-locale-file-mapper';
import { importDayJsTranslation } from 'utils/common/import-day-js-translation/import-day-js-translation';

dayjs.extend(localeData);
dayjs.extend(localizedFormat);

export const LanguageContext = React.createContext<LanguageContextType>({
  locale: 'en',
  handleLocale: () => {}
});

export const LanguageContextProvider: React.FC = (props) => {
  const { children } = props;

  const [locale, setLocale] = useState<LanguageValueType>('en');

  const handleLocale = async (newLocale: LanguageValueType) => {
    const dayJsLanguageCode = languageValueToDayJsLocaleFileMapper[newLocale];
    await importDayJsTranslation(languageValueToDayJsLocaleFileMapper[newLocale]);
    dayjs.locale(dayJsLanguageCode);
    setLocale(newLocale);
  };

  const value = useMemo<LanguageContextType>(
    () => ({
      locale,
      handleLocale
    }),
    [locale]
  );

  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider locale={locale} messages={{ ...en, ...require(`locale/${locale}.json`) }}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

interface LanguageContextType {
  locale: LanguageValueType;
  handleLocale: (newLocale: LanguageValueType) => void;
}
