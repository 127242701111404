import { ColumnTypeEnum } from 'types/customer-service/column-type-enum';

export const resolveParcelColumnName = (columnType: ColumnTypeEnum): string => {
  switch (columnType) {
    case ColumnTypeEnum.ShipDate:
      return 'CS.parcels.searchByTrackingNumber.view.table.head.shipDate';
    case ColumnTypeEnum.TrackingNumber:
      return 'CS.parcels.searchByTrackingNumber.view.table.head.trackingNumber';
    case ColumnTypeEnum.ReferenceNumber:
      return 'CS.parcels.searchByTrackingNumber.view.table.head.referenceNumber';
    case ColumnTypeEnum.CurrentStatus:
      return 'CS.parcels.searchByTrackingNumber.view.table.head.currentStatus';
    case ColumnTypeEnum.EstimatedDelivery:
      return 'CS.parcels.searchByTrackingNumber.view.table.head.estimatedDelivery';
  }
};
