import React, { useContext, useEffect, useState } from 'react';
import { getCancelPickupUrl } from 'utils/cancel-pickup/get-cancel-pickup-url/get-cancel-pickup-url';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import { Alert } from 'components/Shared/Alert/Alert';
import { PickupStatus } from 'components/CancelPickup/PickupStatus/PickupStatus';
import { shouldDisplayPickupAlert } from 'utils/cancel-pickup/should-display-pickup-alert/should-display-pickup-alert';
import { Loading } from 'components/Shared/Loading/Loading';
import { useParams } from 'react-router-dom';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';

type CancelPickupParams = {
  labelId: string | undefined;
  key: string | undefined;
};

export const CancelPickupSchedule: React.FC = () => {
  const { key, labelId } = useParams<CancelPickupParams>();

  const { brandId } = useContext(ClientConfigContext);

  const [pickupMessage, setPickupMessage] = useState<string | null>(null);

  const CANCEL_PICKUP_API = getCancelPickupUrl(key, labelId);

  useEffect(() => {
    const fetchHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    };

    const params = enhanceFetchParams({ method: 'DELETE', headers: enhanceHeadersParams(brandId, fetchHeaders) });

    (async () => {
      try {
        const pickupCancelFetch = await fetch(CANCEL_PICKUP_API, params);
        const response = await pickupCancelFetch.json();

        if (response.errorTitle) return setPickupMessage(response.errorTitle);

        return setPickupMessage(response.successMessage);
      } catch (e) {
        return setPickupMessage('Server error');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, labelId, brandId]);

  if (!pickupMessage) return <Loading height="50px" />;

  // SEKELETON STRUCTURE FOR THE FUTURE SOLUTIONS
  // if (!pickupMessage)
  //   return (
  //     <SkeletonBox>
  //       <Skeleton variant="circular" width={40} height={40} animation="wave" />
  //       <Skeleton variant="text" width="100%" height={20} sx={{ marginLeft: '15px' }} animation="wave" />
  //     </SkeletonBox>
  //   );
  if (shouldDisplayPickupAlert(pickupMessage)) return <Alert message={pickupMessage} marginSm="0" gtmId="cancel-pickup-failure-message" />;
  return <PickupStatus pickupMessage={pickupMessage} />;
};
