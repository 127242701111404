import styled from '@emotion/styled';

export const InstructionsListItemContainer = styled.li`
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const InstructionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InstructionStepCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  height: 25px;
  background-color: #c0c0c0;
  color: white;
  border-radius: 50%;
  margin-right: 20px;
`;

export const InstructionTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

export const PostOfficeList = styled.ul`
  list-style: lower-alpha;
  padding-left: 65px;
  margin-top: 15px;

  @media ${({ theme }) => theme.mediumUp} {
    padding-left: 80px;
  }
`;

export const PostOfficeListCustomItem = styled.li`
  font-size: 14px;
`;

export const PostOfficeListCustomItemLink = styled.a`
  font-size: 14px;
`;
