import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px;
`;

export const Content = styled.ul``;

export const TileWrapper = styled.li`
  width: 100%;
  margin: 0 0 30px 0;

  &:last-of-type {
    margin: 0;
  }
`;
