import React, { useContext, useState } from 'react';
import { SearchParcels } from './SearchParcels/SearchParcels';
import { ParcelsList } from './ParcelsList/ParcelsList';
import { Container } from './Parcels.styled';
import { SearchBy } from 'types/customer-service/search-by-type';
import {
  FindParcelsApiResponseError,
  FindParcelsApiResponseSuccess,
  FindParcelsPayloadBody,
  Parcel
} from 'types/customer-service/find-parcels-api-response-type';
import { buildFindParcelsApi } from 'utils/customer-service/build-find-parcels-api/build-find-parcels-api';
import { Alert } from 'components/Shared/Alert/Alert';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { useIntl } from 'react-intl';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import {
  calculateEDDDisplayStatuses,
  EDDDisplayStatuses
} from 'utils/customer-service/calculate-edd-display-statuses/calculate-edd-display-statuses';
import { EDDDisplayCSPageType, EDDDisplayMapperEnum, setEDDDisplayValue } from 'utils/customer-service/set-edd-display-value/set-edd-display-value';
import { sortParcelsByErrorStatus } from 'utils/customer-service/sort-parcels-by-error-status/sort-parcels-by-error-status';
import {
  resolveSearchParcelsStatus,
  SearchParcelsStatusEnum
} from 'utils/customer-service/resolve-search-parcels-status/resolve-search-parcels-status';
import { ParcelsListSkeleton } from './ParcelsListSkeleton/ParcelsListSkeleton';
import { getCookie } from 'utils/common/utils/utils';

export const Parcels: React.FC = () => {
  const [searchBy, setSearchBy] = useState<SearchBy.TRACKING_NUMBER | SearchBy.ORDER_NUMBER>(SearchBy.TRACKING_NUMBER);
  const [parcels, setParcels] = useState<Parcel[]>([]);
  const [parcelsErrorCode, setParcelsErrorCode] = useState<string | null>(null);
  const [amountOfErrorParcels, setAmountOfErrorParcels] = useState<number | null>(null);
  const [parcelsErrors, setParcelsErrors] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clientConfig } = useContext(ClientConfigContext);
  const [shouldDisplayParcelsList, setShouldDisplayParcelsList] = useState<boolean>(false);
  const [displayTryAgainButton, setDisplayTryAgainButton] = useState<boolean>(false);
  const { brandId } = useContext(ClientConfigContext);

  const intl = useIntl();

  const fetchParcels = async (searchBy: SearchBy, trackingNumbersOrOrderNumber: FindParcelsPayloadBody): Promise<void> => {
    const FIND_PARCELS_API: string = buildFindParcelsApi(searchBy);
    try {
      setIsLoading(true);
      setParcelsErrorCode(null);
      setAmountOfErrorParcels(null);
      setParcelsErrors(null);
      setParcels([]);
      setDisplayTryAgainButton(false);
      setShouldDisplayParcelsList(false);
      const payload: FindParcelsPayloadBody = trackingNumbersOrOrderNumber;

      const body: BodyInit = JSON.stringify(payload);

      const headers: HeadersInit = {
        'X-BRAND-ID': brandId,
        'Content-Type': 'application/json',
        'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty',
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
      };

      const init: RequestInit = {
        body,
        headers,
        method: 'POST'
      };
      const response = await fetch(FIND_PARCELS_API, init);
      const data: FindParcelsApiResponseError | FindParcelsApiResponseSuccess = await response.json();

      // @ts-ignore
      if (!!data.errorTitle) {
        window.dataLayer.push({
          eddDisplay: EDDDisplayMapperEnum.SERVER_ERROR
        });
        window.dataLayer.push({
          event: 'submitSearchParcelsForm',
          trackingOrOrderNumbersCount: searchBy === SearchBy.TRACKING_NUMBER ? trackingNumbersOrOrderNumber.length : 1,
          parcelsSearchBy: searchBy,
          searchParcelsStatus: SearchParcelsStatusEnum.ERROR
        });
        // @ts-ignore
        return setParcelsErrorCode(data.errorTitle);
      }

      // @ts-ignore
      const statusOfEDDDisplay: EDDDisplayStatuses = calculateEDDDisplayStatuses(data);
      const EDDDisplayValue: EDDDisplayCSPageType = setEDDDisplayValue(statusOfEDDDisplay);

      window.dataLayer.push({
        eddDisplay: EDDDisplayValue
      });

      window.dataLayer.push({
        event: 'submitSearchParcelsForm',
        trackingOrOrderNumbersCount: searchBy === SearchBy.TRACKING_NUMBER ? trackingNumbersOrOrderNumber.length : 1,
        parcelsSearchBy: searchBy,
        searchParcelsStatus: resolveSearchParcelsStatus(data as Parcel[]) // HERE we can add also mix status when some parcels are success and some not
      });

      setParcels(data as Parcel[]);
      const errorsParcels = sortParcelsByErrorStatus(data as Parcel[]);
      const { timeoutParcels, notFoundParcels } = errorsParcels;

      if (timeoutParcels.length + notFoundParcels.length !== (data as Parcel[]).length) setShouldDisplayParcelsList(true);

      if (timeoutParcels.length > 0) {
        setDisplayTryAgainButton(true);
        if (searchBy === SearchBy.TRACKING_NUMBER) {
          setParcelsErrorCode('CS.parcels.searchByTrackingNumber.error.timeout');
          setAmountOfErrorParcels(timeoutParcels.length);
          return setParcelsErrors(timeoutParcels.map((parcel: Parcel) => parcel.parcelDetails.trackingNumber));
        }
        if (searchBy === SearchBy.ORDER_NUMBER) {
          setParcelsErrorCode('CS.parcels.searchByOrderNumber.error.timeout');
          setAmountOfErrorParcels(timeoutParcels.length);
          return setParcelsErrors(timeoutParcels.map((parcel: Parcel) => parcel.parcelDetails.trackingNumber));
        }
      }

      if (notFoundParcels.length > 0) {
        setDisplayTryAgainButton(false);
        if (searchBy === SearchBy.TRACKING_NUMBER) {
          setParcelsErrorCode('CS.parcels.searchByTrackingNumber.error.notFound');
          setAmountOfErrorParcels(notFoundParcels.length);
          return setParcelsErrors(notFoundParcels.map((parcel: Parcel) => parcel.parcelDetails.trackingNumber));
        }
        if (searchBy === SearchBy.ORDER_NUMBER) {
          setParcelsErrorCode('CS.parcels.searchByOrderNumber.error.notFound');
          setAmountOfErrorParcels(notFoundParcels.length);
          return setParcelsErrors(notFoundParcels.map((parcel: Parcel) => parcel.parcelDetails.trackingNumber));
        }
      }
    } catch (e) {
      window.dataLayer.push({
        eddDisplay: EDDDisplayMapperEnum.SERVER_ERROR
      });
      window.dataLayer.push({
        event: 'submitSearchParcelsForm',
        trackingOrOrderNumbersCount: searchBy === SearchBy.TRACKING_NUMBER ? trackingNumbersOrOrderNumber.length : 1,
        parcelsSearchBy: searchBy,
        searchParcelsStatus: SearchParcelsStatusEnum.ERROR
      });

      setParcelsErrorCode('CS.parcels.alert.error.serverError.status.500');
    } finally {
      setIsLoading(false);
    }
  };

  const clearParcelsResults = () => {
    setParcelsErrorCode(null);
    setParcelsErrors(null);
    setParcels([]);
  };

  return (
    <Container>
      <SearchParcels
        isIntegrated={clientConfig.returnsModule.clientReturnType === 'integrated'}
        clearParcelsResults={clearParcelsResults}
        fetchParcels={fetchParcels}
        searchByOrderNumberEnabled={clientConfig.searchByOrderNumberEnabled}
        customOrderNumberField={clientConfig.returnsModule.customOrderNumberField}
        searchBy={searchBy}
        setSearchBy={setSearchBy}
        isLoading={isLoading}
        areReturnsEnabled={clientConfig.returnsModule.enabled}
      />
      {parcelsErrorCode && (
        <SectionLayout
          backgroundAsColor={false}
          paddingDefault="30px 15px 0 15px"
          paddingMd="30px 30px 0 30px"
          paddingL="30px 30px 0 30px"
          paddingXl="50px 95px 0 95px"
          paddingXXl="50px 115px 0 115px">
          <Alert
            marginSm="0"
            message={intl.formatMessage({ id: parcelsErrorCode }, { amountOfErrorParcels: amountOfErrorParcels })}
            error={parcelsErrors ? parcelsErrors : undefined}
            btnMessage={displayTryAgainButton ? intl.formatMessage({ id: 'CS.parcels.alert.buttonMessage' }) : undefined}
            buttonGTMId="customer-service-try-again-button"
            // @ts-ignore
            btnMethod={() =>
              fetchParcels(
                SearchBy.TRACKING_NUMBER,
                parcels.map((element: Parcel) => element.parcelDetails.trackingNumber)
              )
            }
          />
        </SectionLayout>
      )}
      {isLoading ? (
        <ParcelsListSkeleton searchBy={searchBy} />
      ) : shouldDisplayParcelsList && parcels.length > 0 ? (
        <ParcelsList parcels={parcels} searchBy={searchBy} />
      ) : null}
    </Container>
  );
};
