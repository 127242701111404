import { Parcel, ParcelStatusEnum } from 'types/customer-service/find-parcels-api-response-type';

export enum SearchParcelsStatusEnum {
  ERROR = 'error',
  MIXED = 'mixed',
  SUCCESS = 'success'
}

export const resolveSearchParcelsStatus = (parcels: Parcel[]): SearchParcelsStatusEnum => {
  const errorParcels: Parcel[] = [];
  const successParcels: Parcel[] = [];

  parcels.forEach((element: Parcel) => {
    if (element.parcelDetails.parcelStatus === ParcelStatusEnum.NOT_FOUND || element.parcelDetails.parcelStatus === ParcelStatusEnum.TIMEOUT)
      return errorParcels.push(element);
    return successParcels.push(element);
  });

  if (errorParcels.length > 0 && successParcels.length > 0) return SearchParcelsStatusEnum.MIXED;
  if (errorParcels.length > 0) return SearchParcelsStatusEnum.ERROR;
  return SearchParcelsStatusEnum.SUCCESS;
};
