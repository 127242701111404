import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const FooterLayout = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 15px;

  background-color: white;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media ${({ theme }) => theme.largeUp} {
    padding: 20px 100px;
  }


  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.CustomerServicePage &&
    css`
      background-color: white;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;

      padding: 50px 30px;

      @media ${theme.largeUp} {
        padding: 20px 30px;
      }

      @media ${theme.xlargeUp} {
        padding: 20px 95px;
      }

      @media ${theme.xxlargeUp} {
        padding: 20px 115px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      background-color: ${theme.gray900};
      box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;

      @media ${theme.largeUp} {
        padding: 20px 50px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      background-color: ${theme.gray900};
      box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;

      @media ${theme.largeUp} {
        padding: 20px 100px;
      }

      @media ${theme.xxlargeUp} {
        padding: 20px 120px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.ReturnsPage &&
    css`
      background-color: ${theme.gray900};
      box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;

      @media ${theme.largeUp} {
        padding: 20px 50px;
      }

      @media ${theme.xlargeUp} {
        padding: 20px 100px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.UnsubscribePage &&
    css`
      background-color: ${theme.gray900};
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;

      padding: 50px 30px;

      @media ${theme.largeUp} {
        padding: 20px 30px;
      }

      @media ${theme.xlargeUp} {
        padding: 20px 95px;
      }

      @media ${theme.xxlargeUp} {
        padding: 20px 115px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.NotFound &&
    css`
      background-color: ${theme.gray900};
      box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;

      @media ${theme.largeUp} {
        padding: 20px 100px;
      }
    `}
`;

export const FooterContainer = styled.div<{ shouldCenterL: boolean }>`
  width: 100%;
  max-width: 1340px;

  @media ${({ theme }) => theme.largeUp} {
    display: flex;
    justify-content: ${({ shouldCenterL }) => (shouldCenterL ? 'center' : 'space-between')};
    align-items: center;
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.CustomerServicePage &&
    css`
      max-width: 1660px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      max-width: 800px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      max-width: 1660px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.ReturnsPage &&
    css`
      max-width: 840px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.UnsubscribePage &&
    css`
      max-width: 1660px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.NotFound &&
    css`
      max-width: 1660px;
    `}
`;
// contact wrapper
export const ContactWrapper = styled.div`
  margin-bottom: 20px;
  @media ${({ theme }) => theme.largeUp} {
    margin-bottom: 0;
  }
`;

export const EmptyContactWrapper = styled.div<{ shouldHideItem: boolean }>`
  width: 220px;
  height: 1px;
  display: ${({ shouldHideItem }) => (shouldHideItem ? 'none' : 'block')};
`;

export const ContactTitle = styled.h5`
  margin: 0 0 5px 0;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
  font-family: ${getCustomStyle('styleModule.headerFontFamily')};
  text-align: center;

  @media ${({ theme }) => theme.largeUp} {
    text-align: left;
  }
`;

export const ContactSubtitle = styled.p`
  font-weight: 100;
  font-size: 12px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1.31;
  text-align: center;
  color: ${({ theme }) => theme.gray500};
  font-family: ${getCustomStyle('styleModule.bodyFontFamily')};

  @media ${({ theme }) => theme.largeUp} {
    text-align: left;
  }
`;

export const ContactLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.largeUp} {
    justify-content: flex-start;
  }
`;

export const ContactLinksBreak = styled.span`
  display: block;
  color: ${({ theme }) => theme.black};
  margin: 0 5px;
  font-size: 12px;
`;

export const ContactLink = styled.a`
  display: flex;
  align-items: center;
  color: ${getCustomStyle('styleModule.secondaryColor')};
  font-family: ${getCustomStyle('styleModule.subheaderFontFamily')};
`;

export const ContactParagraph = styled.p`
  display: flex;
  margin: 0;
  align-items: center;
  color: ${getCustomStyle('styleModule.secondaryColor')};
  font-family: ${getCustomStyle('styleModule.subheaderFontFamily')};
`;

//social media wrapper
export const SocialMediaIconsList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media ${({ theme }) => theme.largeUp} {
    margin-bottom: 0;
  }
`;

export const SocialMediaIconItem = styled.li`
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const SocialMediaIconLink = styled.a`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-decoration: none;
  background-color: ${({ theme }) => getCustomStyle('styleModule.secondaryColor', theme.secondaryColor800)};

  i {
    color: ${({ theme }) => (!getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? theme.darkBackgroundColor : theme.white)};
    font-size: 24px;
  }
`;

//terms wrapper

export const TermsWrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;

export const TermsList = styled.ul`
  display: flex;
  margin-bottom: 10px;
`;

export const TermsListItem = styled.li`
  margin: 0 15px 0 0;

  &:last-child {
    margin: 0;
  }
`;

export const TermsListLink = styled.a`
  font-weight: 100;
  font-size: 12px;
  display: block;
  padding: 0;
  line-height: 1.31;
  text-align: center;
  color: ${({ theme }) => theme.gray500};
  font-family: ${getCustomStyle('styleModule.bodyFontFamily')};
`;

export const PoweredByLinkContainer = styled.a<{ shouldCenterL: boolean }>`
  display: block;
  width: 173px;

  @media ${({ theme }) => theme.largeUp} {
    margin-left: auto;
    margin-right: ${({ shouldCenterL }) => (shouldCenterL ? 'auto' : '0')};
  }
`;

export const PoweredByContainer = styled.div<{ shouldCenterL: boolean }>`
  width: 173px;

  @media ${({ theme }) => theme.largeUp} {
    margin-left: auto;
    margin-right: ${({ shouldCenterL }) => (shouldCenterL ? 'auto' : '0')};
  }
`;

export const PoweredByImg = styled.img`
  width: 100%;
`;
