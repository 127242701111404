import React, { PropsWithChildren, useContext } from 'react';
import {
  CloseModalButtonContainer,
  SchedulePickupStepsContainer,
  SchedulePickupStepsWrapper,
  SchedulePickupTitle
} from './SchedulePickupSteps.styled';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import IconButton from '@mui/material/IconButton';
import { USPSForm } from 'components/Returns/Label/USPSForm/USPSForm';
import { ScheduledPickupConfirmation } from 'components/Returns/Label/ScheduledPickupConfirmation/ScheduledPickupConfirmation';
import { PickupContext } from 'context/returns/PickupContext';
import { useIntl } from 'react-intl';
import { isDemoLink } from 'utils/returns/returnInfo/label/is-demo-link/is-demo-link';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBMiniIconsEnum } from 'constants/PBIconsEnums';

export const SchedulePickupSteps: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { handleModalClose, labelId, inputOrderId } = props;

  const intl = useIntl();
  const { step } = useContext(PickupContext);

  return (
    <SchedulePickupStepsContainer data-test-id="schedule-pickup-steps-container">
      <SchedulePickupStepsWrapper>
        <CloseModalButtonContainer data-test-id="close-modal-button">
          <IconButton
            data-test-id="usps-pickup-close-modal-button"
            data-gtm-id={!isDemoLink(inputOrderId) ? 'usps-pickup-close-modal-button' : undefined}
            aria-label="close"
            onClick={handleModalClose}>
            <PBIcon type={PBIconTypeEnum.Mini} name={PBMiniIconsEnum.Close} />
          </IconButton>
        </CloseModalButtonContainer>
        <SchedulePickupTitle data-test-id="schedule-pickup-title">
          {step === 0
            ? intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.title.step0' })
            : intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.title.step1' })}
        </SchedulePickupTitle>
        {step === 0 && <USPSForm handleModalClose={handleModalClose} labelId={labelId} inputOrderId={inputOrderId} />}
        {step === 1 && <ScheduledPickupConfirmation handleModalClose={handleModalClose} />}
      </SchedulePickupStepsWrapper>
    </SchedulePickupStepsContainer>
  );
};

export interface OwnProps {
  handleModalClose: () => void;
  labelId: string;
  inputOrderId: string;
}
