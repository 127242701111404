import styled from '@emotion/styled';
import { TableRow } from '@mui/material';
import { styled as muiStyled } from '@mui/system';

export const CustomTableRow = muiStyled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'shouldHaveGreyBackground'
})<CustomTableRowProps>(({ shouldHaveGreyBackground }) => ({
  backgroundColor: shouldHaveGreyBackground ? '#f3f3f3' : '#ffffff',

  '&:last-of-type td': {
    borderBottom: 0
  }
}));

export const L2Description = styled.span`
  display: block;
  font-weight: normal;
`;

export const MobileCellText = styled.p<{ isBolded: boolean }>`
  font-weight: ${({ isBolded }) => (isBolded ? 'bold' : 'normal')};
  margin: 0;
  font-size: inherit;
`;

interface CustomTableRowProps {
  shouldHaveGreyBackground: boolean;
}
