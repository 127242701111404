import { EDDDisplayStatuses } from 'utils/customer-service/calculate-edd-display-statuses/calculate-edd-display-statuses';

export type EDDDisplayCSPageType = 'None' | 'Date' | 'DateTime' | 'Mixed' | 'Mixed with None';

export enum EDDDisplayMapperEnum {
  nonEDD = 'None',
  EDD = 'Date',
  EDDWithTime = 'DateTime',
  SERVER_ERROR = 'Error'
}

export const setEDDDisplayValue = (statuses: EDDDisplayStatuses): EDDDisplayCSPageType => {
  const foundStatuses: string[] = [];

  Object.keys(statuses).forEach((status) => {
    if (statuses[status] > 0) foundStatuses.push(status);
  });

  if (foundStatuses.length === 0) return 'None';
  if (foundStatuses.length === 1) return EDDDisplayMapperEnum[foundStatuses[0]];
  if (foundStatuses.some((status: string) => status === 'nonEDD')) return 'Mixed with None';
  return 'Mixed';
};
