import { NonReturnableReasonCode } from 'types/return/overview/products-selection/non-returnabled-code-enum';
import { isUserAuthenticated } from 'utils/shared/is-user-authenticated/is-user-authenticated';

export const isNotReturnableProductAvailableToReturnOrExchange = (
  returnable: boolean,
  nonReturnableReasonCode: NonReturnableReasonCode,
  urlKey: string
): boolean => {
  return (
    !returnable &&
    isUserAuthenticated(urlKey) &&
    (nonReturnableReasonCode === NonReturnableReasonCode.RETURN_PERIOD_EXPIRES || nonReturnableReasonCode === NonReturnableReasonCode.NON_RETURNABLE)
  );
};
