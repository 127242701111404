export const resolveSelectedProductOption = (
  areExchangesEnabled: boolean,
  returnable: boolean,
  exchangeable: boolean,
  isAuthenticated: boolean,
  rootProductAttributesValuesLength: number
): 'return' | null => {
  if (areExchangesEnabled && exchangeable) return null;
  if (areExchangesEnabled && !exchangeable && !returnable && isAuthenticated && rootProductAttributesValuesLength > 0) return null;
  if (areExchangesEnabled && !exchangeable && returnable && isAuthenticated && rootProductAttributesValuesLength > 0) return null;
  return 'return';
};
