import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const ReturnIconContainer = styled.li`
  display: flex;
  align-items: center;
  max-width: 290px;
  min-width: 212px;

  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.largeUp} {
    margin-bottom: 0;
    min-width: auto;
    max-width: 220px;
  }
`;

export const ReturnIconImageWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 40px;
  min-width: 40px;
  margin-right: 25px;
`;

export const ReturnPBIconWrapper = styled.div`
  width: 40px;
  min-width: 40px;
  margin-right: 25px;

  i {
    font-size: 36px;
  }
`;

export const ReturnIconImage = styled.img`
  width: 100%;
`;

export const ReturnIconTextWrapper = styled.div``;

export const ReturnIconTitle = styled.h2`
  color: #2e2e2e;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  font-size: 20px;
  margin: 0 0 3px 0;

  @media ${({ theme }) => theme.largeUp} {
    font-size: 18px;
  }
`;

export const ReturnIconDescription = styled.p`
  font-size: 17px;
  color: #2e2e2e;
  margin: 0;
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};

  @media ${({ theme }) => theme.largeUp} {
    font-size: 15px;
  }
`;
