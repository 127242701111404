import { resolveVanityNameAPIHost } from 'utils/common/resolve-vanity-api-host/resolve-vanity-name-api-host';

export const buildCheckOrderExistsApi = (
  isIntegrated: boolean,
  vanityName: string,
  brandId: string,
  orderId: string,
  zipCode: string | null
): string => {
  const CHECK_ORDER_EXISTS_INTEGRATED_API: string = `${resolveVanityNameAPIHost(
    vanityName
  )}/api/brands/${brandId}/orders/${orderId}/zipCode/${zipCode}/is-exist`;
  const CHECK_ORDER_EXISTS_NON_INTEGRATED_API: string = `${resolveVanityNameAPIHost(vanityName)}/api/brands/${brandId}/orders/${orderId}/is-exist`;

  return isIntegrated ? CHECK_ORDER_EXISTS_INTEGRATED_API : CHECK_ORDER_EXISTS_NON_INTEGRATED_API;
};
