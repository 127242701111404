import { DeliveryType } from 'types/delivery-type';
import { FetchParamsType } from 'types/return/shared/fetch-params-type';

export function getCookie(cname: string): string {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // eslint-disable-next-line
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    // eslint-disable-next-line
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const enhanceFetchParams = (params: FetchParamsType): Record<string, unknown> => {
  const paramsHeaders = params && params.headers;
  const enhanceHeaders = {
    ...paramsHeaders,
    ...{ 'X-XSRF-TOKEN': getCookie('XSRF-TOKEN') }
  };

  return Object.assign({}, { ...params, ...{ headers: enhanceHeaders } });
};

export const enhanceHeadersParams = (brandId: string, params: FetchParamsType | null = null): Record<string, unknown> => {
  const paramsHeaders = (params && params.headers) || [];
  if (!brandId)
    return {
      ...paramsHeaders,
      ...{ Accept: 'application/json' },
      ...{ 'Content-Type': 'application/json' },
      ...{ 'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty' }
    };
  return {
    ...paramsHeaders,
    ...{ Accept: 'application/json' },
    ...{ 'Content-Type': 'application/json' },
    ...{ 'X-BRAND-ID': brandId },
    ...{ 'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty' }
  };
};

export const enhanceHeadersParams1 = (params: FetchParamsType | null = null): Record<string, unknown> => {
  const paramsHeaders = (params && params.headers) || [];

  return {
    ...paramsHeaders,
    ...{ Accept: 'application/json' },
    //...{ "Content-Type": "multipart/form-data" },
    ...{ 'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty' }
  };
};

export const calculateDeliveryType = (isReturn: boolean): DeliveryType => {
  return isReturn ? DeliveryType.Return : DeliveryType.Forward;
};
