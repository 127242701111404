import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  margin: 0 0 0 30px;
`;

export const Switcher = styled.button<{ active: boolean }>`
  display: block;
  width: 47px;
  height: 35px;
  background-color: ${({ active }) => (active ? '#4a4a4a' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#4a4a4a')};
  outline: none;
  border: 1px solid #4a4a4a;
  transition: 0.3s background, color;

  &:hover:not([disabled]) {
    cursor: pointer;
    transition: 0.3s background;
    background: #d9dced;
  }

  &:nth-of-type(1) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0;
  }

  &:nth-of-type(2) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 0;
  }
`;
