import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';

export const ExploreTheBrandContainer = styled.div`
  width: 100%;
  max-width: 820px;
`;

export const ExploreTheBrandWrapper = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    display: flex;
    justify-content: space-between;
  }
`;

export const FirstItemContainer = styled.div`
  width: 100%;
  box-shadow: 2px 2px 2px ${({ theme }) => theme.gray700};
  position: relative;
  height: 400px;
  margin-bottom: 20px;
  cursor: pointer;

  @media ${({ theme }) => theme.largeUp} {
    width: calc(50% - 10px);
    margin-bottom: 0;
  }
`;

export const SecondItemContainer = styled.div`
  width: 100%;
  height: 400px;

  @media ${({ theme }) => theme.largeUp} {
    width: calc(50% - 10px);
  }
`;

export const ItemImage = styled.div<{ backgroundImage: string }>`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url("${backgroundImage}");
  `}

  @media ${({ theme }) => theme.largeUp} {
    background-position: center center;
    background-size: cover;
  }
`;

export const ItemContentBox = styled.div`
  padding: 15px 12px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.white};

  @media ${({ theme }) => theme.largeUp} {
    padding: 20px 17px;
  }
`;

export const ItemTitle = styled.h4`
  font-size: 20px;
  letter-spacing: -0.3px;
  padding: 0;
  margin: 0 0 20px 0;
  text-align: center;
`;

export const ItemButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 195px;

  @media ${({ theme }) => theme.largeUp} {
    margin-bottom: 15px;
    height: 192.5px;
  }
`;

export const ItemSmallImage = styled.div<{ backgroundImage: string }>`
  width: calc(50% - 5px);

  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  cursor: pointer;
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url("${backgroundImage}");
  `}

  @media ${({ theme }) => theme.largeUp} {
    background-position: center center;
    background-size: contain;
    width: calc(50% - 7.5px);
  }
`;

export const ItemMediumImage = styled.div<{ backgroundImage: string }>`
  width: 100%;
  height: 195px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  cursor: pointer;

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url("${backgroundImage}");
  `}

  @media ${({ theme }) => theme.largeUp} {
    background-position: center center;
    background-size: cover;
    height: 192.5px;
  }
`;

export const ItemQuoteContainer = styled.div`
  width: 100%;
  height: 195px;
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${getCustomStyle('styleModule.secondaryColor')};
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    font-size: 60px;
    line-height: 60px;
    color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? theme.white : theme.darkBackgroundColor)};
    font-family: ${({ theme }) => theme.secondaryFontFamily};
  }

  &:before {
    content: '“';
    top: 15px;
    left: 15px;
    height: 25px;
  }

  &:after {
    content: '”';
    height: 25px;
    bottom: 15px;
    right: 15px;
  }

  @media ${({ theme }) => theme.largeUp} {
    height: 192.5px;
  }
`;

export const ItemQuote = styled.q`
  font-size: 20px;
  text-align: center;
  quotes: none;
  font-weight: normal;
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? theme.white : theme.darkBackgroundColor)};
  font-family: ${({ theme }) => theme.bodyFontFamily};
`;

export const ItemQuoteAuthor = styled.p`
  padding: 0;
  margin: 0;
  font-size: 18px;
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? theme.white : theme.darkBackgroundColor)};
  font-family: ${({ theme }) => theme.bodyFontFamily};
`;
