import styled from '@emotion/styled';

export const ReturnsTitleContainer = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  margin: 0 0 50px 0;
`;

export const ReturnsTitleText = styled.span``;

export const ReturnsTitleOrderNumber = styled.span`
  font-weight: 700;
`;
