import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { ProductsSelectionContainer } from './ProductsSelection.styled';
import { useIntl } from 'react-intl';
import { Alert, alertType } from 'components/Shared/Alert/Alert';
import {
  filterItemsForReturn,
  itemsContainsReturnPeriodExpired,
  isMilitaryAddress,
  HazmatDataExist
} from 'utils/returns/overview/products-selection/filter-order-items/filter-order-items';
import { UrlKeyContext } from 'context/returns/UrlKeyContext';
import { filterItemsImages } from 'utils/returns/overview/products-selection/filter-items-images/filter-items-images';
import PageTitle from 'components/Shared/PageTitle/PageTitle';
import { isUserAuthenticated } from 'utils/shared/is-user-authenticated/is-user-authenticated';
import { AlertErrorTitle, OverviewContext } from 'context/returns/OverviewContext';
import { OrderItemType, OverviewApiResponseSuccessType, ShipToType } from 'types/return/overview/overview-api-response-type';
import { initGoogleTagManagerDataLayer } from 'utils/common/google-tag-manager/google-tag-manager';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { ProductsSelectionForm } from './ProductsSelectionForm/ProductsSelectionForm';
import { Loading } from 'components/Shared/Loading/Loading';
import { NonReturnableReasonCode } from 'types/return/overview/products-selection/non-returnabled-code-enum';

export const ProductsSelection: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { overviewConfig, orderNumber, shippingPostalCode, shippingAddress } = props;

  const { returnsModule, orderItems } = overviewConfig;
  const intl = useIntl();
  const { brandName, merchantId, vanityName, template, clientReturnType, brandId } = useContext(ClientConfigContext);
  const { urlKey } = useContext(UrlKeyContext);
  const { formError, formErrorsList } = useContext(OverviewContext);

  const [returnNotAllowedForAddress, setReturnNotAllowedForAddress] = useState<boolean>(false);
  const [eligibleOrderItems, setEligibleOrderItems] = useState<OrderItemType[]>(filterItemsForReturn(orderItems, true, shippingAddress));
  const [nonEligibleOrderItems, setNonEligibleOrderItems] = useState<OrderItemType[]>(filterItemsForReturn(orderItems, false, shippingAddress));
  const [returnPeriodExpired, setReturnPeriodExpired] = useState<boolean>(false);
  const [shouldDisplayImageEligible, setShouldDisplayImageEligible] = useState<boolean>(false);
  const [shouldDisplayImageNonEligible, setShouldDisplayImageNonEligible] = useState<boolean>(false);
  useEffect(() => {
    const eligibleItems = filterItemsForReturn(orderItems, true, shippingAddress);
    const nonEligibleItems = filterItemsForReturn(orderItems, false, shippingAddress);
    const periodExpiredItems = itemsContainsReturnPeriodExpired(orderItems) && eligibleItems.length === 0;
    const filterEligibleItems = filterItemsImages(eligibleItems);
    const filterNonEligibleItems = filterItemsImages(nonEligibleItems);
    setReturnNotAllowedForAddress(isMilitaryAddress(shippingAddress));
    setEligibleOrderItems(eligibleItems);
    setReturnPeriodExpired(periodExpiredItems);
    setNonEligibleOrderItems(nonEligibleItems);
    setShouldDisplayImageEligible(filterEligibleItems);
    setShouldDisplayImageNonEligible(filterNonEligibleItems);
  }, [orderItems, shippingAddress]);
  useEffect(() => {
    if (overviewConfig) {
      return initGoogleTagManagerDataLayer(
        overviewConfig.tagManagerModule.deliveryType,
        overviewConfig.tagManagerModule.trackingStatus,
        merchantId,
        vanityName,
        null,
        'Return Item Selection',
        'returnPageview',
        overviewConfig.returnsModule.clientReturnType === 'integrated' ? 'RMA' : 'Non-integrated',
        template,
        !!overviewConfig.urlKey,
        null,
        brandId
      );
    }
  }, [overviewConfig, merchantId, vanityName, clientReturnType, template, brandId]);

  if (!shippingPostalCode) return <Loading />;
  return (
    <ProductsSelectionContainer data-test-id="products-selection-step" id="productselection">
      <PageTitle title={intl.formatMessage({ id: 'RETURN.overview.productsSelection.page.title' })} brandName={brandName} />
      {formError && formError === AlertErrorTitle.PRODUCTS_SELECTION_VALIDATION && (
        <Alert
          message={intl.formatMessage({ id: formError })}
          error={formErrorsList}
          gtmId="label-generation-error"
          testId="products-selection-step__validation-alert-error"
        />
      )}
      {!isUserAuthenticated(urlKey) && returnPeriodExpired && (
        <Alert
          policyUrl={!!returnsModule.returnsPolicyView.policyParagraphLink.url ? returnsModule.returnsPolicyView.policyParagraphLink.url : ''}
          type={alertType.INFO}
          gtmId="return-window-expired"
          theme="notice"
          message={intl.formatMessage({
            id: 'RETURN.overview.productsSelection.returnPeriodExpired.text'
          })}
          testId="products-selection-step__period-expired-alert-error"
        />
      )}
      {!isUserAuthenticated(urlKey) &&
        returnNotAllowedForAddress &&
        HazmatDataExist(orderItems) &&
        orderItems.filter((item) => {
          item.nonReturnableReasonCode === NonReturnableReasonCode.RESTRICTED_ADDRESS_NO_REASON;
        }) &&
        eligibleOrderItems.length === 0 && (
          <Alert
            type={alertType.INFO}
            gtmId="return-window-expired"
            theme="notice"
            message={intl.formatMessage({
              id: 'RETURN.overview.productsSelection.militaryAddressandHazmat.text'
            })}
            testId="products-selection-step__hazmat_address-alert-error"
          />
        )}
      <ProductsSelectionForm
        urlKey={urlKey}
        orderNumber={orderNumber}
        shippingPostalCode={shippingPostalCode}
        returnsModule={returnsModule}
        eligibleOrderItems={eligibleOrderItems}
        shouldDisplayImageEligible={shouldDisplayImageEligible}
        nonEligibleOrderItems={nonEligibleOrderItems}
        shouldDisplayImageNonEligible={shouldDisplayImageNonEligible}
      />
    </ProductsSelectionContainer>
  );
};

export interface OwnProps {
  overviewConfig: OverviewApiResponseSuccessType;
  orderNumber: string;
  shippingPostalCode: string | null;
  shippingAddress: ShipToType | null;
}
