import React from 'react';
import { PolicyLayout } from 'layouts/ConsumerConnectPolicy/PolicyLayout/PolicyLayout';
import { ConsumerConnectTermsOfUseContainer, PolicyList, PolicyListItem, PolicyParagraph, PolicyTitle, TextWrapper } from './CookiesPolicy.styled';
import { useIntl } from 'react-intl';

export const CookiePolicy = () => {
  const intl = useIntl();

  return (
    <PolicyLayout pageTitle={''} title={''} subtitle={''}>
      <ConsumerConnectTermsOfUseContainer>
        <TextWrapper>
          <PolicyTitle>Cookie Policy</PolicyTitle>
          <PolicyParagraph></PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyParagraph>
            <div id="ot-sdk-cookie-policy"></div>
          </PolicyParagraph>
          <PolicyParagraph>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings btn btn-secondary" type="button">
              Cookie Settings
            </button>
          </PolicyParagraph>
        </TextWrapper>
      </ConsumerConnectTermsOfUseContainer>
    </PolicyLayout>
  );
};
