export const resolveAppErrorTip = (status: number | 400 | 404 | 500 | 503): string => {
  switch (status) {
    case 400:
      return 'APP.appErrorLoader.appError.error.400.tip';
    case 404:
      return 'APP.appErrorLoader.appError.error.404.tip';
    case 503:
      return 'APP.appErrorLoader.appError.error.503.tip';
    default:
      return 'APP.appErrorLoader.appError.error.500.tip';
  }
};
