import React, { ChangeEvent, PropsWithChildren, useContext, useState } from 'react';
import { ButtonWrapper, Container, FormContainer, LabelEmailCustomTextField, LabelEmailInputContainer } from './LabelEmailInput.styled';
import { useIntl } from 'react-intl';
import { Alert, alertType } from 'components/Shared/Alert/Alert';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import { isEmailValid } from 'utils/shared/email-regex/email-regex';
import { CustomLoadingButton } from 'components/Shared/styled-components/CustomLoadingButton/CustomLoadingButton.styled';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { launchDarklyResolveDynamicText } from 'utils/common/launch-darkly/launch-darkly-resolve-dynamic-text/launch-darkly-resolve-dynamic-text';
import { FormHelperTextProps } from '@mui/material';

enum EmailStatus {
  Success,
  Failure,
  NotSent
}

export const LabelEmailInput: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { sendEmailUrl } = props;

  const { brandId } = useContext(ClientConfigContext);

  const intl = useIntl();
  const { makeEmailCopyOfLabelButtonTextDynamicCoco529 } = useFlags();
  const [loading, setLoading] = useState<boolean>(false);
  const [hideEmailInput, setHideEmailInput] = useState<boolean>(false);
  const [emailFormActive, setEmailFormActive] = useState<boolean>(false);
  const [status, setStatus] = useState<EmailStatus>(EmailStatus.NotSent);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const handleSendEmailAgain = () => {
    setStatus(EmailStatus.NotSent);
    setHideEmailInput(false);
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setEmail(value);

    if (value.length === 0) return setEmailError(intl.formatMessage({ id: 'RETURN.returnInfo.label.labelEmailInput.form.email.input.error.empty' }));
    if (!isEmailValid(value))
      return setEmailError(intl.formatMessage({ id: 'RETURN.returnInfo.label.labelEmailInput.form.email.input.error.regex' }));
    setEmailError('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (email.length === 0) return setEmailError(intl.formatMessage({ id: 'RETURN.returnInfo.label.labelEmailInput.form.email.input.error.empty' }));
    if (!!emailError) return;

    const fetchHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    };

    const params = enhanceFetchParams({
      method: 'POST',
      headers: enhanceHeadersParams(brandId, fetchHeaders),
      body: email
    });

    try {
      setLoading(true);
      const response = await fetch(sendEmailUrl, params);

      const data = await response.json();

      // data.error
      if (data.Error) return setStatus(EmailStatus.Failure);

      setStatus(EmailStatus.Success);
      setEmail('');
    } catch {
      setStatus(EmailStatus.Failure);
    } finally {
      setLoading(false);
      setHideEmailInput(true);
    }
  };

  return (
    <LabelEmailInputContainer data-test-id="label-email-input-container">
      {!hideEmailInput && (
        <Container>
          <ButtonWrapper isActive={emailFormActive}>
            <CustomButton
              size="medium"
              onClick={() => setEmailFormActive(true)}
              isUppercase
              width="100%"
              data-gtm-id="email-copy-of-label"
              data-test-id="email-copy-of-label"
              variant="contained">
              {launchDarklyResolveDynamicText(
                makeEmailCopyOfLabelButtonTextDynamicCoco529,
                intl.formatMessage({ id: 'RETURN.returnInfo.label.labelEmailInput.button.openForm' })
              )}
            </CustomButton>
          </ButtonWrapper>
          <FormContainer noValidate onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
            <LabelEmailCustomTextField
              data-test-id="label-email-text-field"
              size="small"
              id="outlined-helperText"
              placeholder={intl.formatMessage({ id: 'RETURN.returnInfo.label.labelEmailInput.form.email.input.placeholder' })}
              type="email"
              value={email}
              error={!!emailError}
              data-error-id={!!emailError}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeEmail(event)}
              helperText={!!emailError ? emailError : ''}
              variant="outlined"
              inputProps={{ 'data-test-id': 'label-email-input' }}
              FormHelperTextProps={{ 'data-test-id': 'label-email-input-helper-text' } as FormHelperTextProps}
            />

            <CustomLoadingButton
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              size="medium"
              disabled={loading}
              isUppercase
              data-gtm-id="email-copy-of-label-send"
              data-test-id="email-copy-of-label-send-button"
              type="submit"
              loading={loading}
              variant="contained">
              {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelEmailInput.form.button.submit' })}
            </CustomLoadingButton>
          </FormContainer>
        </Container>
      )}
      {status === EmailStatus.Success && (
        <Alert
          marginSm="0 0 10px"
          message={intl.formatMessage({ id: 'RETURN.returnInfo.label.labelEmailInput.form.alert.success' })}
          type={alertType.SUCCESS}
          iconSize="22px"
          btnMethod={handleSendEmailAgain}
          btnMessage="Email label again."
        />
      )}
      {status === EmailStatus.Failure && (
        <Alert
          marginSm="0 0 10px"
          message={intl.formatMessage({ id: 'RETURN.returnInfo.label.labelEmailInput.form.alert.error' })}
          type={alertType.ERROR}
          iconSize="22px"
          gtmId="notification-email-failed-to-send"
        />
      )}
    </LabelEmailInputContainer>
  );
};

export interface OwnProps {
  sendEmailUrl: string;
}
