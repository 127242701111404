import React, { PropsWithChildren, SetStateAction } from 'react';
import { AttributesListContainer } from './AttributesList.styled';
import { AttributesListItem } from 'components/Returns/Overview/ProductsSelection/ProductsSelectionForm/Product/ExchangeOptionsList/ExchangeOption/AttributesList/AttributesListItem/AttributesListItem';
import { AttributesSelectedOption } from 'types/return/overview/products-selection/products-selection-form/attributes-selected-option';

export const AttributesList: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { rootProductAttributesValues, setAttributesSelectedOptions, rootProductId, exchangeOptionIndex, isDataLoading } = props;

  return (
    <AttributesListContainer data-test-id="attributes-list">
      {rootProductAttributesValues.map((element: string, index: number) => (
        <AttributesListItem
          isDataLoading={isDataLoading}
          rootProductId={rootProductId}
          rootProductAttribute={element}
          key={index}
          exchangeOptionIndex={exchangeOptionIndex}
          setAttributesSelectedOptions={setAttributesSelectedOptions}
        />
      ))}
    </AttributesListContainer>
  );
};

export interface OwnProps {
  rootProductAttributesValues: string[];
  isDataLoading: boolean;
  setAttributesSelectedOptions: React.Dispatch<SetStateAction<AttributesSelectedOption[]>>;
  rootProductId: string;
  exchangeOptionIndex: number;
}
