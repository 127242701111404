import React, { useContext } from 'react';
import { Container } from './CustomerService.styled';
import { BannersLayout } from 'layouts/Shared/BannersLayout/BannersLayout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { WordPressBanners } from 'components/Shared/Banner/WordPressBanners/WordPressBanners';
import { useIntl } from 'react-intl';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import PageTitle from 'components/Shared/PageTitle/PageTitle';
//import { addPBAndAdobeExternalScripts } from 'utils/customer-service/add-pb-and-adobe-external-scripts/add-pb-and-adobe-external-scripts';
import { LanguageSwitcher } from 'components/CustomerService/LanguageSwitcher/LanguageSwitcher';
import { Parcels } from 'components/CustomerService/Parcels/Parcels';
import { PtsSwitchProvider } from 'context/customer-service/PtsSwitchContext';
import { AuthUserProvider } from 'context/customer-service/AuthUserContext';

export const CustomerService = () => {
  const { brandName } = useContext(ClientConfigContext);

  const { boxDocsBanners } = useFlags();
  const intl = useIntl();

  return (
    <AuthUserProvider>
      <PtsSwitchProvider>
        <PageTitle title={intl.formatMessage({ id: 'CS.searchResults.page.title' })} brandName={brandName} />
        {/* {addPBAndAdobeExternalScripts()} */}
        <Container data-test-id="customer-service">
          <BannersLayout>{boxDocsBanners && <WordPressBanners />}</BannersLayout>
          <Parcels />
          <LanguageSwitcher />
        </Container>
      </PtsSwitchProvider>
    </AuthUserProvider>
  );
};
