export enum PBIcomoonIconsEnum {
  ChevronRight = '\\e409',
  Pencil = '\\e90c',
  Plus = '\\e90b',
  ArrowInit = '\\e901',
  Envelope = '\\e902',
  X = '\\e903',
  CheckCircle = '\\e904',
  TextSms = '\\e905',
  Cancel = '\\e906',
  Messenger = '\\e907',
  Arrow = '\\e908',
  StarFill = '\\e909',
  StarStroke = '\\e90a',
  ArrowDown = '\\ea3e',
  Facebook = '\\e900',
  Facebook2 = '\\ea91',
  Instagram = '\\ea92',
  Twitter = '\\ea96',
  Youtube = '\\ea9d',
  Tumblr = '\\eab9',
  Pinterest = '\\e90d'
}

export enum PBOutlineIconsEnum {
  AccountSwitcher = '\\e92d',
  Add = '\\eac5',
  AddCircle = '\\eac6',
  AddQueryBuilder = '\\eac7',
  AddressBook = '\\eac8',
  AlertCircle = '\\eac9',
  Android = '\\eaca',
  Apple = '\\eacb',
  Apps = '\\eacc',
  Archive = '\\e915',
  ArchiveDrawer = '\\e917',
  Attachment = '\\eace',
  Bank = '\\ead0',
  Basket = '\\e926',
  Bell = '\\e902',
  Belp = '\\e91c',
  Betweeness = '\\ead1',
  Bluetooth = '\\ead2',
  Box = '\\ead3',
  Bulb = '\\eb22',
  Calculator = '\\ead4',
  Calendar = '\\ead5',
  Camera = '\\ead6',
  CameraOff = '\\ead7',
  Cart = '\\ead8',
  Catalog = '\\ead9',
  CatalogAddTo = '\\eada',
  CatalogNew = '\\eadb',
  ChartBars = '\\e916',
  ChartBarsAxis = '\\e917',
  Chat = '\\eadc',
  ChatConversation = '\\eadd',
  Check = '\\e90b',
  CheckCircle = '\\eadf',
  CheckSquare = '\\e92a',
  CircleDown = '\\e907',
  CircleLeft = '\\e909',
  CircleRight = '\\e90a',
  CircleUp = '\\e908',
  Clipboard = '\\eade',
  Close = '\\eae0',
  Closeness = '\\eae1',
  CloudDownload = '\\eae2',
  CloudUpLoad = '\\eae3',
  Coins = '\\e91e',
  CollapseAll = '\\e924',
  Connection = '\\eae4',
  ConnectionDatabase = '\\eae5',
  ConnectionFilesystem = '\\eae6',
  ConnectionNew = '\\eae7',
  CreditCard = '\\eae8',
  CurrencyDollar = '\\eae9',
  CurrencyEuro = '\\eaea',
  CurrencyPound = '\\eaeb',
  CurrencyYen = '\\eaec',
  CursorArrowLeft = '\\e90d',
  Dashboard = '\\eaee',
  Database = '\\eaef',
  Degree = '\\eaf0',
  Delete = '\\e910',
  DeleteCircle = '\\eaf1',
  DeleteKey = '\\e91b',
  DeleteSquare = '\\e928',
  DeliveryFast = '\\eaf2',
  Direction = '\\e91f',
  Download = '\\eaf4',
  Drop = '\\e900',
  Duplicate = '\\eaf5',
  Entity = '\\eaf7',
  ExpandAll = '\\e925',
  Export = '\\eaf8',
  FastForward = '\\eaf9',
  File = '\\eafb',
  FileContent = '\\eafc',
  Filter = '\\eafd',
  Flag = '\\eafe',
  Flash = '\\eaff',
  Folder = '\\eb01',
  FolderAddTo = '\\eb02',
  FolderNew = '\\eb03',
  FolderOpen = '\\eb04',
  FolderSquare = '\\eb00',
  FullScreen = '\\eb06',
  Glasses = '\\e923',
  Globe = '\\eb07',
  Globe2 = '\\e921',
  GrammarCheck = '\\e918',
  Headset = '\\e927',
  Heart = '\\eb08',
  HelpCircle = '\\eb09',
  HigherValue = '\\eb0a',
  History = '\\eb0b',
  Home = '\\eb0c',
  HubvisCanvas = '\\eb0d',
  HubvisCircular = '\\eb0e',
  HubvisCluster = '\\eb0f',
  HubvisExpandArrow = '\\eb10',
  HubvisGraph = '\\eb11',
  HubvisHierarchy = '\\eb12',
  HubvisLoadPred = '\\eb13',
  HubvisLoadSucc = '\\eb14',
  HubvisMap = '\\eb15',
  HubvisModel = '\\eb16',
  HubvisOrganic = '\\eb17',
  HubvisOrthogonal = '\\eb18',
  HubvisQuerySelection = '\\eb19',
  HubvisRadial = '\\eb1a',
  HubvisRemoveFilter = '\\eb1b',
  HubvisSelectEntires = '\\eb1e',
  HubvisSelectPred = '\\eb1c',
  HubvisSelectSucc = '\\eb1d',
  HubvisTable = '\\eb1f',
  HubvisTree = '\\eb20',
  HubvisUnload = '\\eb21',
  Image = '\\eb4a',
  Import = '\\eb23',
  Influence = '\\eb24',
  InfoCircle = '\\eb25',
  Key = '\\eb26',
  Label = '\\eb27',
  LabelAuto = '\\eb28',
  LabelLayer = '\\eb29',
  LabelSelect = '\\eb2a',
  Layer = '\\eb2b',
  LayerGroup = '\\eb2c',
  LayerNew = '\\eb2d',
  Layers = '\\eb2e',
  Like = '\\eb30',
  Line = '\\eb2f',
  Link = '\\eb31',
  LinkBroken = '\\eb32',
  ListBullet = '\\eb33',
  ListNumbers = '\\eb34',
  LoaderCircle = '\\e901',
  Location = '\\eb35',
  LocationPin = '\\eb36',
  Lock = '\\eb37',
  LowerValue = '\\eb38',
  Mail = '\\eb39',
  MapFolded = '\\eb3a',
  MapMarker = '\\eb3d',
  MapWorld = '\\eb3b',
  MapWorldNew = '\\eb3c',
  MediaPlayer = '\\eb3e',
  Megaphone = '\\e919',
  Menu = '\\eb3f',
  Metadata = '\\eb40',
  MetadataTemplate = '\\eb41',
  Microphone = '\\eb42',
  MinimalDown = '\\e905',
  MinimalLeft = '\\eacf',
  MinimalRight = '\\eb47',
  MinimalUp = '\\e906',
  MobileButton = '\\e911',
  Money = '\\eb43',
  MoneyCoins = '\\eb44',
  Monitor = '\\eb45',
  Newsletter = '\\e920',
  Next = '\\eb05',
  Notification = '\\e919',
  Pause = '\\eb48',
  Palette = '\\e922',
  Pencil = '\\eaf6',
  Phone = '\\eb49',
  Play = '\\eb4b',
  Plug = '\\eb4c',
  Point = '\\eb4d',
  Polygon = '\\eb4e',
  Preferences = '\\e91a',
  Previous = '\\eb4f',
  Printer = '\\eb50',
  PropertyGradient = '\\eb51',
  QueryBuilder = '\\eb52',
  Redo = '\\eb53',
  ReduceScreen = '\\eb54',
  Refresh = '\\eb55',
  Refresh2 = '\\e91d',

  RemoveSquare = '\\e99',
  Rewind = '\\eb56',
  Save = '\\eb57',
  Scale = '\\eb58',
  Scissors = '\\eaed',
  ScreenFull = '\\e904',
  ScreenReduce1 = '\\e903',
  Search = '\\eb59',
  Send = '\\eb5a',
  Settings = '\\eb5b',
  Share = '\\eb5c',
  SignIn = '\\eb5d',
  SignOut = '\\eb5e',
  Sitemap = '\\eb5f',
  SmallTriangleDown = '\\eaf3',
  SmallTriangleUp = '\\eacd',
  Sort = '\\eb60',
  SoundDown = '\\eb61',
  SoundOff = '\\eb62',
  SoundOn = '\\eb63',
  SoundUp = '\\eb64',
  Star = '\\eafa',
  Stop = '\\eb65',
  Table = '\\eb66',
  TableNew = '\\eb67',
  TabletButton = '\\e913',
  TabletSimple = '\\e912',
  Tack = '\\eb68',
  TailDown = '\\e90f',
  TailUp = '\\e90e',
  TextCode = '\\e90c',
  Tile = '\\eb69',
  TileNew = '\\eb6a',
  Todo = '\\e914',
  Trash = '\\eb6b',
  TreeView = '\\eb6c',
  Undo = '\\eb6d',
  Unlock = '\\eb6e',
  Upload = '\\eb6f',
  User = '\\eb70',
  UserCircle = '\\e92e',
  Users = '\\eb46',
  Video = '\\eb71',
  WarningTriangle = '\\eb72',
  Wifi = '\\eb73',
  ZoomIn = '\\eb74',
  ZoomOut = '\\eb75'
}

export enum PBMiniIconsEnum {
  AccountSwitcher = '\\e94e',
  Add = '\\ea03',
  AddBold = '\\e92d',
  AddCircle = '\\ea04',
  AddSmall = '\\e92a',
  AddQueryBuilder = '\\ea05',
  AddressBook = '\\ea06',
  AlertCircle = '\\ea07',
  AlertCircleOpen = '\\ea08',
  Android = '\\ea09',
  Apple = '\\ea0a',
  Apps = '\\ea0b',
  ArchiveCheck = '\\e91d',
  ArrowBoldDown = '\\e92f',
  ArrowBoldLeft = '\\e931',
  ArrowBoldUp = '\\e930',
  ArrowBoldRight = '\\e932',
  ArrowDoubleLeft = '\\e933',
  ArrowDoubleRight = '\\e934',
  Attachment = '\\ea0d',
  Ban = '\\e91c',
  Bank = '\\ea0f',
  Basket = '\\e946',
  Bell = '\\e902',
  Betweeness = '\\ea10',
  Bluetooth = '\\ea11',
  Box = '\\ea12',
  Bulb = '\\ea67',
  Bullet = '\\e941',
  Calculator = '\\ea13',
  Calendar = '\\ea14',
  Camera = '\\ea15',
  CameraOff = '\\ea16',
  Cart = '\\ea17',
  ChartBars = '\\e93d',
  ChartBarsAxis = '\\e928',
  Catalog = '\\ea18',
  CatalogAddTo = '\\ea19',
  CatalogNew = '\\ea1a',
  Chat = '\\ea1b',
  ChatConversation = '\\ea1c',
  Check = '\\e918',
  CheckBold = '\\e935',
  CheckCircle = '\\ea1d',
  CheckCircleOpen = '\\ea1e',
  CheckSquare = '\\e949',
  CircleDown = '\\e914',
  CircleLeft = '\\e916',
  CircleUp = '\\e915',
  CircleRight = '\\e917',
  Clipboard = '\\ea1f',
  Close = '\\ea20',
  Closeness = '\\ea21',
  CloudDownload = '\\ea22',
  CloudUpload = '\\ea23',
  CollapseAll = '\\e944',
  Connection = '\\ea24',
  ConnectionDatabase = '\\ea25',
  ConnectionFilesystem = '\\ea26',
  ConnectionNew = '\\ea27',
  CreditCard = '\\ea28',
  CurrencyDollar = '\\ea29',
  CurrencyPound = '\\ea2a',
  CurrencyEuro = '\\ea2b',
  CurrencyYen = '\\ea2c',
  CursorArrowLeft = '\\e929',
  Dashboard = '\\ea2e',
  Database = '\\ea2f',
  Degree = '\\ea30',
  Delete = '\\e90d',
  DeleteBold = '\\e92e',
  DeleteCircle = '\\ea31',
  DeleteCircleOpen = '\\e913',
  DeleteSmall = '\\e92b',
  DeleteSquare = '\\e94a',
  DeliveryFast = '\\ea32',
  DistributeHorizontal = '\\e94f',
  DistributeVertical = '\\e950',
  Download = '\\ea34',
  Duplicate = '\\ea36',
  Drop = '\\e905',
  Entity = '\\ea38',
  ExpandAll = '\\e945',
  Export = '\\ea39',
  FastForward = '\\ea3b',
  File = '\\ea3d',
  FileContent = '\\ea3e',
  Filter = '\\ea3f',
  Flag = '\\ea40',
  Flash = '\\ea41',
  Folder = '\\ea42',
  FolderAdd = '\\e906',
  FolderRemove = '\\e907',
  FolderOpen = '\\ea46',
  FolderOutline = '\\ea43',
  FolderAddTo = '\\ea44',
  FolderNew = '\\ea45',
  FullScreen = '\\ea48',
  Glasses = '\\e943',
  Globe = '\\ea4a',
  Globe2 = '\\e942',
  Grid4 = '\\e908',
  Headset = '\\e948',
  Heart = '\\ea4c',
  HelpCircle = '\\ea4d',
  HelpCircleOpen = '\\ea4e',
  HigherValue = '\\ea4f',
  History = '\\ea50',
  Home = '\\ea51',
  HubvisCanvas = '\\ea52',
  HubvisCircular = '\\ea53',
  HubvisCluster = '\\ea54',
  HubvisExpandArrow = '\\ea55',
  HubvisGraph = '\\ea56',
  HubvisHierarchy = '\\ea57',
  HubvisLoadPred = '\\ea58',
  HubvisLoadSucc = '\\ea59',
  HubvisMap = '\\ea5a',
  HubvisModel = '\\ea5b',
  HubvisOrganic = '\\ea5c',
  HubvisOrthogonal = '\\ea5d',
  HubvisQuerySelection = '\\ea5e',
  HubvisRadial = '\\ea5f',
  HubvisRemoveFilter = '\\ea60',
  HubvisSelectEntires = '\\ea61',
  HubvisSelectPred = '\\ea62',
  HubvisSelectSucc = '\\ea63',
  HubvisTable = '\\ea64',
  HubvisTree = '\\ea65',
  HubvisUnload = '\\ea66',
  Image = '\\ea92',
  Import = '\\ea68',
  Influence = '\\ea69',
  InfoCircle = '\\ea6a',
  InfoCircleOpen = '\\ea6b',
  Key = '\\ea6d',
  Label = '\\ea6e',
  LabelAuto = '\\ea6f',
  LabelLayer = '\\ea70',
  LabelSelect = '\\ea71',
  Layer = '\\ea72',
  LayerGroup = '\\ea73',
  LayerNew = '\\ea74',
  Layers = '\\ea75',
  Line = '\\ea76',
  Like = '\\ea77',
  Link = '\\ea78',
  LinkBroken = '\\ea79',
  List = '\\e909',
  ListBullet = '\\ea7b',
  ListNumbers = '\\ea7c',
  LoaderCircle = '\\e904',
  Location = '\\ea7d',
  LocationPin = '\\ea7e',
  Lock = '\\ea7f',
  LogoDropbox = '\\ea35',
  LogoFacebook = '\\ea3a',
  LogoGithub = '\\ea49',
  LogoGoogle = '\\ea4b',
  LogoInstagram = '\\ea6c',
  LogoLinkedin = '\\ea7a',
  LogoTrello = '\\eab7',
  LogoPaypal = '\\ea90',
  LogoPinterest = '\\ea93',
  LogoSkype = '\\eaa9',
  LogoTwitter = '\\eab8',
  LogoWordpress = '\\eac1',
  LogoYoutube = '\\eac2',
  LowerValue = '\\ea80',
  Mail = '\\ea81',
  MailOutline = '\\e93f',
  Magnet = '\\e91b',
  MapFolded = '\\ea82',
  MapWorld = '\\ea83',
  MapWorldNew = '\\ea84',
  MapMarker = '\\ea85',
  MediaPlayer = '\\ea86',
  Menu = '\\ea87',
  MenuDots = '\\e936',
  Metadata = '\\ea88',
  MetadataTemplate = '\\ea89',
  Microphone = '\\ea8a',
  MinimalDown = '\\e90e',
  MinimalLeft = '\\ea0e',
  MinimalUp = '\\e90f',
  MinimalRight = '\\ea8e',
  MobileButton = '\\e939',
  Money = '\\ea8b',
  Monitor = '\\ea8c',
  Next = '\\ea47',
  Palette = '\\e937',
  Pause = '\\ea8f',
  Pencil = '\\ea37',
  Phone = '\\ea91',
  PinAdd = '\\e90a',
  PinDelete = '\\e912',
  Play = '\\ea94',
  Plug = '\\ea95',
  Point = '\\ea96',
  Polygon = '\\ea97',
  Preferences = '\\e93e',
  Previous = '\\ea98',
  Printer = '\\ea99',
  PropertyGradient = '\\ea9a',
  Redo = '\\ea9c',
  ReduceScreen = '\\ea9d',
  Refresh = '\\ea9e',
  Refresh2 = '\\e947',
  RemoveSmall = '\\e92c',
  RemoveSquare = '\\e94b',
  Report = '\\e919',
  Rewind = '\\ea9f',
  Save = '\\eaa0',
  Scale = '\\eaa1',
  Scissors = '\\ea2d',
  ScreenFull = '\\e90b',
  ScreenReduce = '\\e90c',
  Search = '\\eaa3',
  Send = '\\eaa2',
  Settings = '\\eaa4',
  Share = '\\eaa5',
  ShoppingTag = '\\e938',
  SignIn = '\\eaa6',
  SignOut = '\\eaa7',
  Sitemap = '\\eaa8',
  Slider = '\\e91a',
  SmallTriangleDown = '\\ea33',
  SmallTriangleLeft = '\\e910',
  SmallTriangleUp = '\\ea0c',
  SmallTriangleRight = '\\e911',
  Sort = '\\eaaa',
  SoundDown = '\\eaab',
  SoundOff = '\\eaac',
  SoundOn = '\\eaad',
  SoundUp = '\\eaae',
  Star = '\\ea3c',
  Stop = '\\eaaf',
  Table = '\\eab0',
  TableNew = '\\eab1',
  TabletButton = '\\e93b',
  TabletSimple = '\\e93a',
  Tack = '\\eab2',
  Tile = '\\eab3',
  TileNew = '\\eab4',
  TailDown = '\\e901',
  TailUp = '\\e900',
  Telephone = '\\e940',
  TextUnderline = '\\e93c',
  TextAlignCenter = '\\e91f',
  TextAlignJustify = '\\e920',
  TextAlignLeft = '\\e921',
  TextAlignRight = '\\e922',
  TextMarginLeft = '\\e923',
  TextMarginRight = '\\e924',
  TextBold = '\\e925',
  TextItalic = '\\e926',
  TextCode = '\\e927',
  ToggleOverview = '\\e91e',
  Trash = '\\eab5',
  TreeView = '\\eab6',
  Undo = '\\eab9',
  Unlock = '\\eaba',
  Upload = '\\eabb',
  User = '\\eabc',
  UserCircle = '\\e903',
  Users = '\\ea8d',
  WarningTriangle = '\\eabe',
  WarningTriangleOpen = '\\eabf',
  Wifi = '\\eac0',
  QueryBuilder = '\\ea9b',
  Video = '\\eabd',
  ZoomIn = '\\eac3',
  ZoomOut = '\\eac4'
}
