import React, { Dispatch, SetStateAction } from 'react';
import { IconButton, NotificationsSelectContainer } from '../Notifications.styled';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBIcomoonIconsEnum } from 'constants/PBIconsEnums';

export const NotificationsSelect: React.FC<OwnProps> = (props) => {
  const {
    shouldDisplayEmailForm,
    shouldDisplaySmsForm,
    setShouldDisplayEmailForm,
    setShouldDisplaySmsForm,
    shouldDisplayEmailButton,
    shouldDisplaySmsButton
  } = props;

  const handleToggleEmailForm = (): void => {
    setShouldDisplayEmailForm((prevState: boolean) => !prevState);
  };
  const handleToggleSmsForm = (): void => {
    setShouldDisplaySmsForm((prevState: boolean) => !prevState);
  };

  return (
    <NotificationsSelectContainer data-test-id="notifications-select-container">
      {shouldDisplayEmailButton && (
        <IconButton
          data-test-id="email-select-button"
          data-gtm-id={shouldDisplayEmailForm ? 'email-button-close' : 'email-button-open'}
          onClick={handleToggleEmailForm}
          aria-label="email-toggle-button">
          <PBIcon type={PBIconTypeEnum.Icomoon} name={PBIcomoonIconsEnum.Envelope} />
        </IconButton>
      )}

      {shouldDisplaySmsButton && (
        <IconButton
          data-test-id="sms-select-button"
          data-gtm-id={shouldDisplaySmsForm ? 'sms-button-close' : 'sms-button-open'}
          onClick={handleToggleSmsForm}
          aria-label="sms-toggle-button">
          <PBIcon type={PBIconTypeEnum.Icomoon} name={PBIcomoonIconsEnum.TextSms} />
        </IconButton>
      )}
    </NotificationsSelectContainer>
  );
};

export interface OwnProps {
  shouldDisplayEmailForm: boolean;
  shouldDisplaySmsForm: boolean;
  setShouldDisplayEmailForm: Dispatch<SetStateAction<boolean>>;
  setShouldDisplaySmsForm: Dispatch<SetStateAction<boolean>>;
  shouldDisplayEmailButton: boolean;
  shouldDisplaySmsButton: boolean;
}
