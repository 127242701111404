import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import { css } from '@emotion/react';
import { FormControlLabel, Select } from '@mui/material';

export const ReviewFormContainer = styled.form`
  width: 100%;
`;

export const ReviewFormFieldset = styled.fieldset<{ maxWidth?: string; margin?: string; lastOfTypeMarginBottom?: string; paddingL?: string }>`
  padding: 0;
  margin: ${({ margin }) => (margin ? margin : '0')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
  border: 0;
  width: 100%;
  display: block;

  @media ${({ theme }) => theme.largeUp} {
    padding: ${({ paddingL }) => (paddingL ? paddingL : '0')};
  }

  &:last-of-type {
    margin-bottom: ${({ lastOfTypeMarginBottom }) => (lastOfTypeMarginBottom ? lastOfTypeMarginBottom : '60px')};
  }
`;

export const ReviewFormLegendWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
`;

export const ReviewFormLegend = styled.legend<{ margin?: string }>`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  padding: 0;
  margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const ReviewFormLabel = styled.label<{ margin?: string }>`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  padding: 0;
  margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const ReviewFormEmailLabel = styled.label<{ margin?: string }>`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  padding: 0;
  margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const ReviewFormLegendDescription = styled.p<{ margin?: string }>`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  padding: 0;
  margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const ReviewFormGroup = styled.div<{ alignItems?: string; margin?: string; display?: string; displayMd?: string; justifyContent?: string }>`
  display: ${({ display }) => (display ? display : 'flex')};

  @media ${({ theme }) => theme.mediumUp} {
    ${({ displayMd }) =>
      displayMd &&
      css`
        display: ${displayMd};
      `}
  }

  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'space-between')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const ShipFromAddressTextFieldsWrapper = styled.div`
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media ${({ theme }) => theme.mediumUp} {
    justify-content: flex-end;
    flex-direction: row;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 30px;

  @media ${({ theme }) => theme.mediumUp} {
    width: auto;
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  margin-bottom: 12px;

  .MuiFormControlLabel-label {
    margin-left: 12px;
    color: ${({ theme }) => theme.bodyFontColor};
    font-family: ${({ theme }) => theme.bodyFontFamily};
  }

  @media ${({ theme }) => theme.mediumUp} {
    margin-bottom: 0;
    /* stylelint-disable */
    min-height: 40px;
    /* stylelint-enable */
  }
`;

export const ReviewSelect = styled(Select)`
  &[aria-invalid='true'] {
    color: #bbbbbb;
  }
  .MuiInputBase-input:invalid {
    color: #bbbbbb;
  }

  .MuiInputBase-input:invalid option {
    color: black;
  }
`;
