import styled from '@emotion/styled';

export const Container = styled.div``;

export const UnpackIconContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const Info = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #2e2e2e;
  width: 100%;
  text-align: center;
`;

export const UnpackIcon = styled.img`
  width: 64px;
  height: 64px;
`;
