import queryString from 'query-string';

export const buildGetTrackingReturnsApi = (vanityName: string, trackingNumber: string, urlKey: string | null, designId: string | null): string => {
  return queryString.stringifyUrl(
    {
      url: `/api/vanityNames/${vanityName}/trackingNumbers/${trackingNumber}/tracking/returns`,
      query: {
        key: urlKey,
        designId
      }
    },
    { skipNull: true, skipEmptyString: true, sort: false }
  );
};
