import styled from '@emotion/styled';

export const AppErrorContainer = styled.main`
  background: url('/assets/img/cs-background.png') repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const ErrorBox = styled.section`
  padding: 20px;
`;

export const ErrorBoxMessage = styled.div`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.bodyFontFamily};
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: 1.23;
  padding: 0 10px;
  text-align: center;
`;

export const ErrorMessage = styled.span``;

export const ErrorTip = styled.p`
  font-size: 13px;
`;
