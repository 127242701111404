export enum MethodEnum {
  Label = 'label',
  QRCode = 'qrcode'
}

export enum ShippingTypeEnum {
  Standard = 'standard',
  Expedited = 'expedited',
  UPS = 'ups'
}

export enum CarriersEnum {
  USPS = 'usps',
  UPS = 'ups'
}
