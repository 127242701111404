import styled from '@emotion/styled';

export const ExportButton = styled.button`
  display: flex;
  justify-content: center;
  width: 32px;
  height: 25px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s background;

  &:hover {
    background-color: #d9dced;
    transition: 0.3s background;
  }
`;
