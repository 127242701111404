import React, { PropsWithChildren } from 'react';
import {
  ReturnIconContainer,
  ReturnIconDescription,
  ReturnIconImage,
  ReturnIconImageWrapper,
  ReturnIconTextWrapper,
  ReturnIconTitle,
  ReturnPBIconWrapper
} from './ReturnIcon.styled';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { PBMiniIconsEnum } from 'constants/PBIconsEnums';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';

export const ReturnIcon: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { title, description, url, alt } = props;

  return (
    <ReturnIconContainer>
      {url ? (
        <ReturnIconImageWrapper>
          <ReturnIconImage src={url} alt={alt} />
        </ReturnIconImageWrapper>
      ) : (
        <ReturnPBIconWrapper>
          <PBIcon name={PBMiniIconsEnum.ListBullet} type={PBIconTypeEnum.Mini} data-test-id="returns-search-order-return-icon-pbicon" />
        </ReturnPBIconWrapper>
      )}
      <ReturnIconTextWrapper>
        <ReturnIconTitle data-test-id="returns-search-order-return-icon-title">{title}</ReturnIconTitle>
        <ReturnIconDescription data-test-id="returns-search-order-return-icon-description">{description}</ReturnIconDescription>
      </ReturnIconTextWrapper>
    </ReturnIconContainer>
  );
};

export interface OwnProps {
  title: string;
  description: string;
  url: string;
  alt: string;
}
