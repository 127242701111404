import React from 'react';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { SearchBy } from 'types/customer-service/search-by-type';
import { TilesList } from './TilesList/TilesList';
import { Container } from './TileView.styled';

export const TileView: React.FC<OwnProps> = (props) => {
  const { parcels, searchBy } = props;

  return (
    <Container data-test-id="tile-view">
      <TilesList parcels={parcels} searchBy={searchBy} />
    </Container>
  );
};

export interface OwnProps {
  parcels: Parcel[];
  searchBy: SearchBy;
}
