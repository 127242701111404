import { ParcelStatusEnum, ParcelDeliveryAddress } from 'types/customer-service/find-parcels-api-response-type';
import { ParcelsViewEnum } from 'types/customer-service/parcels-view-enum';
import dayjs from 'dayjs';

export const transformEddDateToDisplayForm = (
  status: ParcelStatusEnum,
  estimatedDeliveryDate: string | null,
  parcelDeliveredAtDate: string | null,
  parcelDeliveryAddress: ParcelDeliveryAddress | null,
  view: ParcelsViewEnum
): string => {
  const todayDate = new Date();

  switch (status) {
    case ParcelStatusEnum.OUT_FOR_DELIVERY:
      if (estimatedDeliveryDate && view === ParcelsViewEnum.Tile) return dayjs(estimatedDeliveryDate).format('dddd, MMMM D');
      if (!estimatedDeliveryDate && view === ParcelsViewEnum.Tile) return dayjs(todayDate).format('dddd, MMMM D');
      if (estimatedDeliveryDate && view === ParcelsViewEnum.Table) return dayjs(estimatedDeliveryDate).format('l');
      return dayjs(todayDate).format('l');
    case ParcelStatusEnum.DELIVERED:
      if (view === ParcelsViewEnum.Tile && !!parcelDeliveredAtDate && !!parcelDeliveryAddress)
        return `${dayjs(parcelDeliveredAtDate).format('MMMM DD')} to ${parcelDeliveryAddress.city ? parcelDeliveryAddress.city : ''}${
          parcelDeliveryAddress.city && parcelDeliveryAddress.state ? ', ' : ''
        }${!!parcelDeliveryAddress.state ? parcelDeliveryAddress.state : ''}`;
      if (view === ParcelsViewEnum.Tile && !!parcelDeliveredAtDate) return `${dayjs(parcelDeliveredAtDate).format('MMMM DD')} `;
      if (view === ParcelsViewEnum.Table && !!parcelDeliveredAtDate) return dayjs(parcelDeliveredAtDate).format('l');
      return '-';
    case ParcelStatusEnum.RETURN_RECEIVED:
      if (view === ParcelsViewEnum.Tile && !!parcelDeliveredAtDate) return dayjs(parcelDeliveredAtDate).format('dddd, MMMM D');
      if (view === ParcelsViewEnum.Table && !!parcelDeliveredAtDate) return dayjs(parcelDeliveredAtDate).format('l');
      return '-';
    case ParcelStatusEnum.PREPARING:
    case ParcelStatusEnum.IN_TRANSIT:
    case ParcelStatusEnum.RETURN_STARTED:
    case ParcelStatusEnum.RETURN_IN_TRANSIT:
    case ParcelStatusEnum.FALLBACK:
    case ParcelStatusEnum.RETURN_OUT_FOR_DELIVERY:
      if (estimatedDeliveryDate && view === ParcelsViewEnum.Tile) return dayjs(estimatedDeliveryDate).format('dddd, MMMM D');
      if (!estimatedDeliveryDate && view === ParcelsViewEnum.Tile) return '';
      if (estimatedDeliveryDate && view === ParcelsViewEnum.Table) return dayjs(estimatedDeliveryDate).format('l');
      return '-';
    case ParcelStatusEnum.DELIVERY_EXCEPTION:
    case ParcelStatusEnum.RETURN_DELIVERY_EXCEPTION:
    case ParcelStatusEnum.UNKNOWN:
    case ParcelStatusEnum.RETURN_UNKNOWN:
      if (estimatedDeliveryDate && view === ParcelsViewEnum.Tile) return '';
      if (!estimatedDeliveryDate && view === ParcelsViewEnum.Tile) return '';
      if (estimatedDeliveryDate && view === ParcelsViewEnum.Table) return '-';
      return '-';
    default:
      if (estimatedDeliveryDate && view === ParcelsViewEnum.Tile) return '';
      if (!estimatedDeliveryDate && view === ParcelsViewEnum.Tile) return '';
      if (estimatedDeliveryDate && view === ParcelsViewEnum.Table) return '-';
      return '-';
  }
};
