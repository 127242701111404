import styled from '@emotion/styled';
import { getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';

export const CircularProgress = styled.div<CircularProps>`
  display: inline-block;
  width: ${({ bigger }) => (bigger ? '60px' : '22px')};
  height: ${({ bigger }) => (bigger ? '60px' : '22px')};

  &::after {
    content: ' ';
    display: block;
    width: ${({ bigger }) => (bigger ? '50px' : '18px')};
    height: ${({ bigger }) => (bigger ? '50px' : '18px')};
    margin: ${({ bigger }) => (bigger ? '5px' : '2px')};
    border-radius: 50%;
    border: 1px solid
      ${({ theme, dark }) => (dark ? '#4a4a4a' : getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? '#fff' : '#4a4a4a')};
    border-bottom: solid 1px transparent;
    animation: loading-animation 1.2s linear infinite;
  }
  @keyframes loading-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export interface CircularProps {
  bigger?: boolean;
  dark?: boolean;
}
