import { TransformedExchangeItem } from 'models/returns/selected-product/SelectedProduct';
import { transformItemsAttributes } from 'utils/returns/overview/review/transform-items-attributes/transform-items-attributes';
import { checkIfExchangeProductExists } from 'utils/returns/overview/review/check-if-exchange-item-exists/check-if-exchange-item-exists';
import { ExchangeOptionsAttributes, ExchangeProducts } from 'models/returns/selected-product/SelectedProduct';

export const transformExchangesItems = (items: ExchangeProducts, exchangeOptionsAttributes: ExchangeOptionsAttributes): TransformedExchangeItem[] => {
  const temp: TransformedExchangeItem[] = [];

  Object.keys(items).forEach((key: string) => {
    const { name, sku, quantity, exchangeProductImageUrl } = items[key];
    const tempAttributes = transformItemsAttributes(exchangeOptionsAttributes[key]);

    const existingProductIndex: number = checkIfExchangeProductExists(temp, sku);

    if (existingProductIndex !== -1) {
      return (temp[existingProductIndex] = {
        ...temp[existingProductIndex],
        quantity: temp[existingProductIndex].quantity + items[key].quantity
      });
    }

    const tempObject: TransformedExchangeItem = {
      id: key,
      name: name,
      sku: sku,
      quantity: quantity,
      exchangeProductImageUrl: exchangeProductImageUrl,
      attributes: tempAttributes
    };
    return temp.push(tempObject);
  });

  return temp;
};
