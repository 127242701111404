import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const ReturnInfoLayoutWrapper = styled.div``;

export const ReturnInfoLayoutSubheader = styled.h2`
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  color: ${({ theme }) => theme.bodyFontColor};
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};

  @media ${({ theme }) => theme.largeUp} {
    padding: 0 0 15px 0;
    border-bottom: 1px solid #9b9b9b;
  }
`;

export const ReturnInfoLayoutContentBox = styled.div`
  margin: 20px 0 0;

  @media ${({ theme }) => theme.largeUp} {
    display: flex;
    margin: 60px 0 0;
  }
`;

export const ReturnInfoLayoutDetailsWrapper = styled.div`
  @media ${({ theme }) => theme.largeUp} {
    width: 50%;
    display: flex;
    justify-content: center;
  }
`;

export const ReturnInfoLayoutInstructionWrapper = styled.div`
  margin: 40px 0 0;

  @media ${({ theme }) => theme.largeUp} {
    width: 50%;
    margin: 0;
    display: flex;
    align-items: center;
  }

  @media ${({ theme }) => theme.xlargeUp} {
    padding: 0 0 0 20px;
  }
`;
