import React from 'react';
import { TableBody } from '@mui/material';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { SingleParcelRow } from './SingleParcelRow/SingleParcelRow';

export const BodyParcelTable: React.FC<OwnProps> = (props) => {
  const { parcels } = props;

  return (
    <TableBody data-test-id="table-body">
      {parcels.map((parcel: Parcel) => (
        <SingleParcelRow key={parcel.id} parcel={parcel} />
      ))}
    </TableBody>
  );
};

export interface OwnProps {
  parcels: Parcel[];
}
