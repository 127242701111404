export type FindParcelsPayloadBody = string[] | string;

export type FindParcelsApiResponseSuccess = Parcel[];

export interface FindParcelsApiResponseError {
  errorTitle: string;
  errors: string[];
}

export interface Parcel {
  id: string;
  parcelDetails: ParcelDetails;
  trackingEvents: TrackingEvent[];
}

export interface ParcelDetails {
  carrier: string | null;
  trackingNumber: string;
  referenceNumber: string | null;
  parcelType: ParcelTypeEnum;
  parcelStatus: ParcelStatusEnum;
  primaryTextKey: string;
  secondaryTextKey: string;
  deliveryDetails: DeliveryDetails | null;
}

export interface DeliveryDetails {
  shipDate: string;
  estimatedDeliveryDate: string | null;
  parcelDeliveredAtDate: string | null;
  parcelDeliveryAddress: ParcelDeliveryAddress | null;
}

export interface ParcelDeliveryAddress {
  city: string | null;
  state: string | null;
}

export enum ParcelStatusEnum {
  PREPARING = 'Preparing',
  IN_TRANSIT = 'InTransit',
  OUT_FOR_DELIVERY = 'OutForDelivery',
  DELIVERED = 'Delivered',
  DELIVERY_EXCEPTION = 'DeliveryException',
  UNKNOWN = 'Unknown',
  RETURN_STARTED = 'ReturnStarted',
  RETURN_IN_TRANSIT = 'ReturnInTransit',
  RETURN_OUT_FOR_DELIVERY = 'ReturnOutForDelivery',
  RETURN_RECEIVED = 'ReturnReceived',
  RETURN_DELIVERY_EXCEPTION = 'ReturnDeliveryException',
  RETURN_UNKNOWN = 'ReturnUnknown',
  NOT_FOUND = 'NotFound',
  TIMEOUT = 'Timeout',
  FALLBACK = 'Fallback'
}

/*
  Delivery - means customer get his parcel first time
  Return - means customer returns his parcel
*/

export enum ParcelTypeEnum {
  DELIVERY = 'DELIVERY',
  RETURN = 'RETURN',
  UNKNOWN = 'UNKNOWN'
}

export interface TrackingEvent {
  eventId: string;
  date: string;
  activity: TrackingEventActivity;
  processingLocation: ProcessingLocation;
}

export interface ProcessingLocation {
  city: string | null;
  state: string | null;
}

export interface TrackingEventActivity {
  description: string | null;
  l1Description: string | null;
  l2Description: string | null;
}
