import styled from '@emotion/styled';

export const QRCodeDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: stretch;
  justify-content: center;

  @media ${({ theme }) => theme.largeUp} {
    width: 80%;
    justify-content: center;
    align-items: flex-start;
  }
`;
