import styled from '@emotion/styled';

export const NotFoundContainer = styled.main`
  background: url('/assets/img/cs-background.png') repeat;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  min-height: auto;
`;

export const NotFoundWrapper = styled.section``;

export const NotFoundImage = styled.img`
  display: block;
  filter: invert(100%);
  height: 140px;
  margin: auto;

  @media ${(props) => props.theme.largeUp} {
    height: 160px;
  }
`;

export const NotFoundTitle = styled.h1`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.bodyFontFamily};
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: 1.23;
  padding: 0 10px;
  text-align: center;
`;
export const NotFoundTip = styled.p`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.bodyFontFamily};
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: 1.23;
  padding: 0 10px;
  text-align: center;
`;
