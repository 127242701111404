import React, { ChangeEvent, Dispatch, FormEvent, PropsWithChildren, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import {
  CustomReactTooltip,
  SearchOrderFormContainer,
  SearchOrderFormLabel,
  SearchOrderFormLabelText,
  ToolTipIconWrapper
} from './SearchOrderForm.styled';
import { ReviewFormFieldset } from 'components/Returns/Overview/Review/ReviewForm/ReviewForm.styled';
import { CustomTextField } from 'components/Shared/styled-components/CustomTextField/CustomTextField.styled';
import { enhanceZipCodeValue } from 'utils/shared/enhance-zip-code-value/enhance-zip-code-value';
import { isZipCodeValid } from 'utils/shared/zip-code-regex/zip-code-regex';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import { useIntl } from 'react-intl';
import { scrollToTop } from 'utils/returns/overview/common/scroll-to-top/scroll-to-top';
import { scrollToError } from 'utils/returns/overview/common/scroll-to-error/scroll-to-error';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { isFirstLetterVowel } from 'utils/shared/articles/articles';
import { SearchOrderReturnsModuleType } from 'types/return/search-order/returns-module/search-order-returns-module-type';
import { ReturnClientReturnType } from 'types/return/shared/client-return-type';
import { Alert } from 'components/Shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { CustomLoadingButton } from 'components/Shared/styled-components/CustomLoadingButton/CustomLoadingButton.styled';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { FormHelperTextProps } from '@mui/material';
import { buildCheckOrderExistsApi } from 'utils/returns/search-order/build-check-order-exists-api/build-check-order-exists-api';
import { isOrderNumberValid } from 'utils/shared/is-order-number-valid/is-order-number-valid';
import { PBMiniIconsEnum } from 'constants/PBIconsEnums';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';

export const SearchOrderForm: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { setFormError, clientReturnType, returnsModule, formError } = props;
  const { brandId, vanityName } = useContext(ClientConfigContext);
  const intl = useIntl();

  const [orderNumber, setOrderNumber] = useState<string>('');
  const [orderNumberError, setOrderNumberError] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [zipCodeError, setZipCodeError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  // fix: can't perform a react state update on an unmounted component
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleChangeOrderNumber = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setOrderNumber(value);

    if (value.length === 0)
      return setOrderNumberError(
        intl.formatMessage(
          { id: 'RETURN.searchOrder.form.orderNumber.input.error.empty' },
          {
            article: isFirstLetterVowel(returnsModule.customOrderNumberField.labelValue),
            label: returnsModule.customOrderNumberField.labelValue.toLowerCase()
          }
        )
      );
    if (!isOrderNumberValid(value, returnsModule.customOrderNumberField.allowDashes))
      return setOrderNumberError(
        intl.formatMessage(
          { id: 'RETURN.searchOrder.form.orderNumber.input.error.regex' },
          {
            article: isFirstLetterVowel(returnsModule.customOrderNumberField.labelValue),
            label: returnsModule.customOrderNumberField.labelValue.toLowerCase()
          }
        )
      );
    if (value.length < 3) return setOrderNumberError(intl.formatMessage({ id: 'RETURN.searchOrder.form.orderNumber.input.error.tooShort' }));
    if (value.length > returnsModule.customOrderNumberField.maxLengthValidation)
      return setOrderNumberError(
        intl.formatMessage(
          { id: 'RETURN.searchOrder.form.orderNumber.input.error.tooLong' },
          { maxCharacters: returnsModule.customOrderNumberField.maxLengthValidation }
        )
      );
    return setOrderNumberError('');
  };

  const handleChangeZipCode = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setZipCode(enhanceZipCodeValue(value));

    if (enhanceZipCodeValue(value).length === 0)
      return setZipCodeError(intl.formatMessage({ id: 'RETURN.searchOrder.form.zipCode.input.error.empty' }));
    if (!isZipCodeValid(enhanceZipCodeValue(value)))
      return setZipCodeError(intl.formatMessage({ id: 'RETURN.searchOrder.form.zipCode.input.error.regex' }));
    setZipCodeError('');
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setFormError('');
    const errorsArray: string[] = [];

    if (orderNumber.length === 0) {
      errorsArray.push(
        intl.formatMessage(
          { id: 'RETURN.searchOrder.form.orderNumber.input.error.empty' },
          {
            article: isFirstLetterVowel(returnsModule.customOrderNumberField.labelValue),
            label: returnsModule.customOrderNumberField.labelValue.toLowerCase()
          }
        )
      );
      setOrderNumberError(
        intl.formatMessage(
          { id: 'RETURN.searchOrder.form.orderNumber.input.error.empty' },
          {
            article: isFirstLetterVowel(returnsModule.customOrderNumberField.labelValue),
            label: returnsModule.customOrderNumberField.labelValue.toLowerCase()
          }
        )
      );
    }

    if (clientReturnType === 'integrated' && zipCode.length === 0) {
      errorsArray.push(intl.formatMessage({ id: 'RETURN.searchOrder.form.zipCode.input.error.empty' }));
      setZipCodeError(intl.formatMessage({ id: 'RETURN.searchOrder.form.zipCode.input.error.empty' }));
    }

    if (errorsArray.length > 0 || !!orderNumberError || !!zipCodeError) {
      window.dataLayer.push({ event: clientReturnType === 'integrated' ? 'findOrderButtonFailure' : 'startReturnButtonFailure' });
      return scrollToError();
    }

    const fetchHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    };

    const params = enhanceFetchParams({
      method: 'GET',
      headers: enhanceHeadersParams(brandId, fetchHeaders)
    });

    try {
      const SEARCH_ORDER_FIND_RETURN_API = buildCheckOrderExistsApi(
        clientReturnType === 'integrated',
        vanityName,
        brandId,
        orderNumber,
        !!zipCode ? zipCode : null
      );
      setLoading(true);
      const response = await fetch(SEARCH_ORDER_FIND_RETURN_API, params);
      const data = await response.json();

      if (data.errorTitle) {
        window.dataLayer.push({
          event: clientReturnType === 'integrated' ? 'findOrderButtonFailure' : 'continueOrderButtonFailure'
        });
        setFormError(data.errorTitle);
        return scrollToError();
      }

      setFormError('');
      navigate(
        `/return/overview/${vanityName}?inputOrderId=${orderNumber}${clientReturnType === 'integrated' ? `&shippingPostalCode=${zipCode}` : ''}`,
        { state: { fromSearchOrder: true } }
      );

      window.dataLayer.push({
        event: clientReturnType === 'integrated' ? 'findOrderButtonSuccess' : 'continueOrderButtonSuccess'
      });
      return scrollToTop('auto');
    } catch (e) {
      setFormError('RETURN.searchOrder.form.error.serverError');
      window.dataLayer.push({
        event: clientReturnType === 'integrated' ? 'findOrderButtonFailure' : 'continueOrderButtonFailure'
      });
      scrollToError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <SearchOrderFormContainer
      data-test-id="search-order-form-container"
      noValidate
      onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event)}>
      <ReviewFormFieldset
        data-test-id="search-order-form-order-number-fieldset"
        maxWidth="290px"
        margin="0 0 30px 0"
        data-error-id={!!orderNumberError}>
        <SearchOrderFormLabel htmlFor="order-number-input" margin="0 0 15px 0">
          {returnsModule.customOrderNumberField.labelValue}
        </SearchOrderFormLabel>
        <CustomTextField
          fullWidth
          size="medium"
          id="order-number-input"
          variant="outlined"
          required
          inputProps={{ 'data-test-id': 'order-number-text-field' }}
          value={orderNumber}
          error={!!orderNumberError}
          helperText={!!orderNumberError ? orderNumberError : ''}
          FormHelperTextProps={{ 'data-test-id': 'order-number-text-field-error' } as FormHelperTextProps}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeOrderNumber(event)}
          textAlignCenter
          fontSize="26px"
          padding="12px 14px"
        />
      </ReviewFormFieldset>

      {clientReturnType === 'integrated' && (
        <ReviewFormFieldset data-test-id="search-order-form-zip-code-fieldset" maxWidth="290px" margin="0 0 60px 0" data-error-id={!!zipCodeError}>
          <CustomReactTooltip effect="solid" place="right" type="light" border={true} borderColor="#a6a6a6" multiline={true} />
          <SearchOrderFormLabel htmlFor="zip-code-input" margin="0 0 15px 0">
            <SearchOrderFormLabelText margin="0 7px 0 0">
              {intl.formatMessage({ id: 'RETURN.searchOrder.form.zipCode.label' })}
            </SearchOrderFormLabelText>
            <ToolTipIconWrapper>
              <PBIcon
                type={PBIconTypeEnum.Mini}
                name={PBMiniIconsEnum.HelpCircleOpen}
                data-tip={intl.formatMessage({ id: 'RETURN.searchOrder.form.zipCode.tooltip' })}
              />
            </ToolTipIconWrapper>
          </SearchOrderFormLabel>

          <CustomTextField
            fullWidth
            size="medium"
            id="zip-code-input"
            variant="outlined"
            required
            inputProps={{ 'data-test-id': 'zip-code-text-field' }}
            value={zipCode}
            error={!!zipCodeError}
            helperText={!!zipCodeError ? zipCodeError : ''}
            FormHelperTextProps={{ 'data-test-id': 'zip-code-text-field-error' } as FormHelperTextProps}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeZipCode(event)}
            textAlignCenter
            fontSize="26px"
            padding="12px 14px"
          />
        </ReviewFormFieldset>
      )}

      {!!formError && (
        <Alert maxWidth="700px" marginSm="-30px auto 50px auto" message={intl.formatMessage({ id: formError })} gtmId="order-not-found" />
      )}

      <CustomLoadingButton
        size="large"
        width="100%"
        style={{ maxWidth: '290px' }}
        fontSize="18px"
        disabled={loading}
        isUppercase
        data-gtm-id={clientReturnType === 'integrated' ? 'find-order-button' : 'continue-return-button'}
        data-test-id="search-order-form-submit-button"
        type="submit"
        loading={loading}
        variant="contained">
        {intl.formatMessage({
          id:
            clientReturnType === 'integrated'
              ? 'RETURN.searchOrder.form.integrated.button.submit'
              : 'RETURN.searchOrder.form.nonIntegrated.button.submit'
        })}
      </CustomLoadingButton>
    </SearchOrderFormContainer>
  );
};

export interface OwnProps {
  formError: string;
  setFormError: Dispatch<SetStateAction<string>>;
  clientReturnType: ReturnClientReturnType;
  returnsModule: SearchOrderReturnsModuleType;
}
