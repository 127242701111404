import { MethodEnum, CarriersEnum } from 'types/return/overview/methods/method-types';

export interface InstructionType {
  id: number;
  text: string;
  number: number;
}

export const setReturnsInstruction = (methodType: MethodEnum, carrier?: string | null): InstructionType[] => {
  const labelInstruction: InstructionType[] = [
    {
      id: 1,
      text: 'RETURN.returnInfo.common.instruction.label.1',
      number: 1
    },
    {
      id: 2,
      text: 'RETURN.returnInfo.common.instruction.label.2',
      number: 2
    },
    {
      id: 3,
      text: 'RETURN.returnInfo.common.instruction.label.3',
      number: 3
    }
  ];

  const qrCodeInstruction: InstructionType[] = [
    {
      id: 1,
      text: 'RETURN.returnInfo.common.instruction.qrCode.1',
      number: 1
    },
    {
      id: 2,
      text: 'RETURN.returnInfo.common.instruction.qrCode.2',
      number: 2
    },
    {
      id: 3,
      text: 'RETURN.returnInfo.common.instruction.qrCode.3',
      number: 3
    }
  ];
  const upsLabelInstruction: InstructionType[] = [
    {
      id: 1,
      text: 'RETURN.returnInfo.common.instruction.label.1',
      number: 1
    },
    {
      id: 2,
      text: 'RETURN.returnInfo.common.instruction.label.2',
      number: 2
    },
    {
      id: 3,
      text: 'RETURN.returnInfo.common.instruction.label.ups.3',
      number: 3
    }
  ];
  if (methodType === MethodEnum.Label && carrier?.toLowerCase() === CarriersEnum.UPS) {
    return upsLabelInstruction;
  } else if (methodType === MethodEnum.Label) return labelInstruction;
  else return qrCodeInstruction;
};
