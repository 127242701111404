import { AttributesSelectedOption } from 'types/return/overview/products-selection/products-selection-form/attributes-selected-option';

export const createProductApiUrl = (
  orderNumber: string,
  rootProductSKU: string,
  rootProductAttributesValues: string[],
  attributesSelectedOptions: AttributesSelectedOption[]
): string => {
  const prefix: string = `/api/return/products?orderNumber=${orderNumber}&rootProductSKU=${rootProductSKU}`;

  let postfix: string = '';
  rootProductAttributesValues.forEach((element: string) => {
    const filteredElement = attributesSelectedOptions.find((element2: AttributesSelectedOption) => element2.attributeName === element);
    if (filteredElement && !!filteredElement.selectedOption) postfix += `&${element}=${filteredElement.selectedOption}`;
  });

  return `${prefix}${postfix}`;
};
