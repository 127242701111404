import React, { ChangeEvent, PropsWithChildren, useContext, useState } from 'react';
import {
  ProductContainer,
  ProductInput,
  ProductLabel,
  ProductOptions,
  ProductOptionsButtonsWrapper,
  ProductOptionsDescription,
  RootProduct,
  ProductArrowContainer,
  ProductDetails,
  ProductDetailsWrapper,
  ProductImageContainer,
  ProductLink,
  ProductName,
  ProductQuantity,
  ProductSku,
  ProductStatusInfo,
  ProductHazmatInfo,
  ProductStatusWrapper,
  ProductTextModifier,
  ProductTextModifierCapitalize,
  RootProductAttributesList,
  RootProductAttributesListItem,
  CustomFormGroup
} from './Product.styled';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { ImageWithFallback } from 'components/Shared/ImageWithFallback/ImageWithFallback';
import { CustomLabel, SelectPlaceholder } from 'components/Returns/Common/CustomSelectElements/CustomSelectElements.styled';
import { isIE } from 'react-device-detect';
import { FormHelperText, MenuItem, useMediaQuery } from '@mui/material';
import { CustomFormControl } from 'components/Shared/styled-components/CustomFormControl/CustomFormControl.styled';
import { useIntl } from 'react-intl';
import { ReviewFormFieldset } from 'components/Returns/Overview/Review/ReviewForm/ReviewForm.styled';
import { OrderItemType } from 'types/return/overview/overview-api-response-type';
import { ReturnsReasonViewType } from 'types/return/shared/returns-reason-view-type';
import { CustomTextField } from 'components/Shared/styled-components/CustomTextField/CustomTextField.styled';
import { isInputValid } from 'utils/shared/basic-input-regex/basic-input-regex';
import { ExchangeOptionsList } from 'components/Returns/Overview/ProductsSelection/ProductsSelectionForm/Product/ExchangeOptionsList/ExchangeOptionsList';
import { SelectedProducts, SelectedProductsErrors, ProductsSelectionContext } from 'context/returns/ProductsSelectionContext';
import { RootProductAttributeView } from 'models/returns/selected-product/SelectedProduct';
import {
  ExchangeOptionsAttributes,
  ExchangeProducts,
  ExchangeProductType,
  SelectedProduct,
  KeyValuePairAttributes
} from 'models/returns/selected-product/SelectedProduct';
import {
  ExchangeOptionAttributesFetchDataError,
  ExchangeOptionsAttributesErrors,
  ExchangeOptionsAttributesFetchDataError,
  SelectedProductErrors,
  KeyValuePairAttributesErrors
} from 'models/returns/selected-product-errors/SelectedProductErrors';
import { useTheme } from '@mui/material/styles';
import { CustomSelect } from 'components/Shared/styled-components/CustomSelect/CustomSelect.styled';
import { NonReturnableReasonCode } from 'types/return/overview/products-selection/non-returnabled-code-enum';
import { MethodEnum } from 'types/return/overview/methods/method-types';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { isUserAuthenticated } from 'utils/shared/is-user-authenticated/is-user-authenticated';
import { fillInArrayWithQuantities } from 'utils/returns/overview/products-selection/fill-in-array-with-quantities/fill-in-array-with-quantities';
import { isNotReturnableProductAvailableToReturnOrExchange } from 'utils/returns/overview/products-selection/is-not-returnable-product-available-to-return-or-exchange/is-not-returnable-product-available-to-return-or-exchange';
import { resolveProductOptionDescription } from 'utils/returns/overview/products-selection/resolve-product-option-description/resolve-product-option-description';
import { resolveProductStatusInfo } from 'utils/returns/overview/products-selection/resolve-product-status-info/resolve-product-status-info';
import { createProductReturnLabelLink } from 'utils/returns/overview/products-selection/create-product-return-label-link/create-product-return-label-link';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isProductStatusAdditionalInfoExist } from 'utils/returns/overview/products-selection/is-product-status-additional-info-exist/is-product-status-additional-info-exist';
import { resolveSelectedProductOption } from 'utils/returns/overview/products-selection/resolve-selected-product-option/resolve-selected-product-option';
import { FormHelperTextProps } from '@mui/material';
import { transformRootProductAttributesToStringArrayValues } from 'utils/returns/overview/products-selection/transform-root-product-attributes-to-string-array-values/transform-root-product-attributes-to-string-array-values';
import { transformRootProductAttributesToRootProductAttributesViews } from 'utils/returns/overview/products-selection/transform-root-product-attributes-to-root-product-attributes-views/transform-root-product-attributes-to-root-product-attributes-views';
import { whetherOrderItemHasQuantityEqualToOne } from 'utils/returns/overview/products-selection/whether-order-item-has-quantity-equal-to-one/whether-order-item-has-quantity-equal-to-one';
import { convertRootProductAttributesToKeyValuePairAttributes } from 'utils/returns/overview/products-selection/convert-root-product-attributes-values-to-key-value-pair-attributes/convert-root-product-attributes-values-to-key-value-pair-attributes';
import { convertRootProductAttributesToKeyValuePairAttributesErrors } from 'utils/returns/overview/products-selection/convert-root-product-attributes-values-to-key-value-pair-attributes-errors/convert-root-product-attributes-values-to-key-value-pair-attributes-errors';
import { isItemChecked } from 'utils/returns/overview/common/is-item-checked/is-item-checked';
import { isItemReturnQuantityValid } from 'utils/returns/overview/products-selection/is-item-return-quantity-valid/is-item-return-quantity-valid';
import { isItemOptionSelected } from 'utils/returns/overview/common/is-item-option-selected/is-item-option-selected';
import { LargeArrow } from './LargeArrow/LargeArrow';
import { SmallArrow } from './SmallArrow/SmallArrow';
import { CarriersEnum } from 'types/return/overview/methods/method-types';
export const Product: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const intl = useIntl();
  const { multiCarrierUps } = useFlags();
  const {
    product,
    reasonsEnabled,
    reasonRequired,
    returnsReasonViews,
    orderNumber,
    shippingPostalCode,
    urlKey,
    reasonsWithRequiredComment,
    areExchangesEnabled,
    shouldDisplayRootProductImage
  } = props;
  const {
    id,
    sku,
    imageUrl,
    name,
    quantity,
    orderItemId,
    rootProductAttributes,
    returnable,
    exchangeable,
    returnMessage,
    hazmat,
    hazmatType
  } = product;
  const theme = useTheme();
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));

  const { addAttributeOrderingToExchangesCoco782 } = useFlags();

  const { selectedProducts, setSelectedProducts, selectedProductsErrors, setSelectedProductsErrors } = useContext(ProductsSelectionContext);

  const { vanityName } = useContext(ClientConfigContext);

  const [rootProductAttributesValues] = useState<string[]>(
    transformRootProductAttributesToStringArrayValues(rootProductAttributes, addAttributeOrderingToExchangesCoco782)
  );

  const [rootProductAttributesViews] = useState<RootProductAttributeView[]>(
    transformRootProductAttributesToRootProductAttributesViews(rootProductAttributes, addAttributeOrderingToExchangesCoco782)
  );

  const [quantities] = useState<number[]>(fillInArrayWithQuantities(quantity));
  const [isReasonCommentRequired, setIsReasonCommentRequired] = useState<boolean>(
    reasonsWithRequiredComment.includes(selectedProducts[id] ? selectedProducts[id].reason : '')
  );

  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleClickStopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleClickExchangeContainer = () => {
    /*
    check if we can go ahead with rest of the validation for
    item which is not returnable but user is authenticated therefore
    there is a chance that it will override not returnable logic when certain conditions are met
     */
    if (isClicked) {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }

    if (
      product?.hazmatTypeSame === false ||
      (product.nonReturnableReasonCode && !isNotReturnableProductAvailableToReturnOrExchange(returnable, product.nonReturnableReasonCode, urlKey))
    )
      return;
    if (selectedProducts && selectedProducts[id]) {
      setSelectedProducts((prevState: SelectedProducts) => {
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            isChecked: isClicked
          }
        };
      });
    }

    if (!selectedProducts || (selectedProducts && !selectedProducts[id])) {
      const keyValuePairAttributes: KeyValuePairAttributes = convertRootProductAttributesToKeyValuePairAttributes(rootProductAttributesValues);

      const keyValuePairAttributesErrors: KeyValuePairAttributesErrors = convertRootProductAttributesToKeyValuePairAttributesErrors(
        rootProductAttributesValues
      );

      setSelectedProducts((prevState: SelectedProducts) => {
        return {
          ...prevState,
          [id]: new SelectedProduct(
            resolveSelectedProductOption(
              areExchangesEnabled,
              returnable,
              exchangeable,
              isUserAuthenticated(urlKey),
              rootProductAttributesValues.length
            ),
            imageUrl ? imageUrl : null,
            name,
            rootProductAttributesViews,
            sku,
            id,
            orderItemId,
            whetherOrderItemHasQuantityEqualToOne(product) ? 1 : 0,
            '',
            '',
            whetherOrderItemHasQuantityEqualToOne(product) ? { [0]: keyValuePairAttributes } : {},
            whetherOrderItemHasQuantityEqualToOne(product)
              ? { [0]: { sku: sku, quantity: 1, exchangeProductImageUrl: imageUrl ? imageUrl : null, name: name, attributes: [] } }
              : {},
            hazmat === true ? true : false,
            hazmatType ? hazmatType : '',
            product?.hazmatTypeSame === true ? true : false,
            multiCarrierUps && product.carrier?.toLowerCase() === CarriersEnum.UPS
              ? CarriersEnum.UPS
              : multiCarrierUps && product.carrier?.toLowerCase() != CarriersEnum.UPS
              ? CarriersEnum.USPS
              : undefined
          )
        };
      });
      setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
        return {
          ...prevState,
          [id]: new SelectedProductErrors(quantity <= 1 ? { [0]: keyValuePairAttributesErrors } : {})
        };
      });
    }
  };

  const handleClickReturnButton = (event) => {
    event.stopPropagation();
    setSelectedProducts((prevState: SelectedProducts) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          option: 'return'
        }
      };
    });
  };

  const handleClickExchangeButton = (event) => {
    event.stopPropagation();

    setSelectedProducts((prevState: SelectedProducts) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          option: 'exchange'
        }
      };
    });
  };

  const handleClickReturnInsteadButton = (event) => {
    event.stopPropagation();

    setSelectedProducts((prevState: SelectedProducts) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          option: 'return'
        }
      };
    });
  };

  const handleClickExchangeInsteadButton = (event) => {
    event.stopPropagation();

    setSelectedProducts((prevState: SelectedProducts) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          option: 'exchange'
        }
      };
    });
  };

  const handleClickCancelButton = (event) => {
    event.stopPropagation();

    setSelectedProducts((prevState: SelectedProducts) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          isChecked: false
        }
      };
    });
  };

  const handleChangeQuantity = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { target } = event;
    const { value } = target;

    const selectedQuantitiesArray: number[] = fillInArrayWithQuantities(+value);

    // attributes object

    const keyValuePairAttributes: KeyValuePairAttributes = convertRootProductAttributesToKeyValuePairAttributes(rootProductAttributesValues);
    const keyValuePairAttributesArray: KeyValuePairAttributes[] = selectedQuantitiesArray.map(() => keyValuePairAttributes);
    const keyValuePairAttributesObject: ExchangeOptionsAttributes = { ...keyValuePairAttributesArray };

    // exchange products object
    const exchangeProductsArray: ExchangeProductType[] = selectedQuantitiesArray.map(() => {
      return { sku: sku, quantity: 1, exchangeProductImageUrl: imageUrl ? imageUrl : null, name: name, attributes: [] };
    });

    const exchangeProductsObject: ExchangeProducts = { ...exchangeProductsArray };

    // attributes errors object
    const keyValuePairAttributesErrors: KeyValuePairAttributesErrors = convertRootProductAttributesToKeyValuePairAttributesErrors(
      rootProductAttributesValues
    );
    const keyValuePairAttributesErrorsArray: KeyValuePairAttributesErrors[] = selectedQuantitiesArray.map(() => keyValuePairAttributesErrors);
    const keyValuePairAttributesErrorsObject: ExchangeOptionsAttributesErrors = { ...keyValuePairAttributesErrorsArray };

    // exchange option attributes fetch data errors object
    const exchangeOptionsFetchDataErrorsArray: ExchangeOptionAttributesFetchDataError[] = selectedQuantitiesArray.map(() => ({ error: null }));
    const exchangeOptionsFetchDataErrorsObject: ExchangeOptionsAttributesFetchDataError = { ...exchangeOptionsFetchDataErrorsArray };

    setSelectedProducts((prevState: SelectedProducts) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          exchangeOptionsAttributes: keyValuePairAttributesObject,
          exchangeProducts: exchangeProductsObject,
          quantity: +value
        }
      };
    });

    setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          exchangeOptionsAttributesFetchDataError: exchangeOptionsFetchDataErrorsObject,
          exchangeOptionsAttributesErrors: keyValuePairAttributesErrorsObject
        }
      };
    });

    if (value.length === 0)
      return setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            selectedQuantityError: intl.formatMessage({
              id: 'RETURN.overview.productsSelection.productsSelectionForm.product.quantity.select.error.empty'
            })
          }
        };
      });
    return setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          selectedQuantityError: null
        }
      };
    });
  };

  const handleChangeReason = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { target } = event;
    const { value } = target;

    setSelectedProducts((prevState: SelectedProducts) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          reason: value
        }
      };
    });

    if (reasonsWithRequiredComment.includes(value)) {
      setIsReasonCommentRequired(true);
    } else {
      setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            reasonCommentError: null
          }
        };
      });
      setIsReasonCommentRequired(false);
    }

    if (reasonRequired && value.length === 0)
      return setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            reasonError: intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reason.select.error.empty' })
          }
        };
      });
    return setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          reasonError: null
        }
      };
    });
  };

  const handleChangeReasonComment = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;

    setSelectedProducts((prevState: SelectedProducts) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          comment: value
        }
      };
    });

    if (!isInputValid(value))
      return setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            reasonCommentError: intl.formatMessage({
              id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reasonComment.input.error.regex'
            })
          }
        };
      });
    if (isReasonCommentRequired && value.length === 0)
      return setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            reasonCommentError: intl.formatMessage({
              id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reasonComment.input.error.empty'
            })
          }
        };
      });
    return setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          reasonCommentError: null
        }
      };
    });
  };

  return (
    <ProductContainer data-test-id={`product-${id}`}>
      <ProductInput />
      <ProductLabel
        data-test-id={`product-${id}__label`}
        isClickable={
          (returnable && product.hazmatTypeSame != false) ||
          (!!product.nonReturnableReasonCode &&
            isNotReturnableProductAvailableToReturnOrExchange(returnable, product.nonReturnableReasonCode, urlKey))
        }
        isChecked={selectedProducts[id] && isItemChecked(selectedProducts[id])}
        onClick={() => handleClickExchangeContainer()}>
        <RootProduct data-test-id="root-product">
          {shouldDisplayRootProductImage && (
            <ProductImageContainer>
              <ImageWithFallback
                isBorder
                src={imageUrl ? imageUrl : ''}
                fallbackSrc="/assets/img/image-placeholder-framed.svg"
                alt={name}
                height="140px"
              />
            </ProductImageContainer>
          )}
          <ProductDetails justifyContentSpaceBetween={!!product.nonReturnableReasonCode}>
            <ProductDetailsWrapper marginDefault="0 0 15px 0">
              <ProductName>
                <ProductTextModifier>{name}</ProductTextModifier>
              </ProductName>
              <ProductSku>{sku}</ProductSku>
            </ProductDetailsWrapper>
            <ProductDetailsWrapper>
              {rootProductAttributesViews.length > 0 && (
                <RootProductAttributesList data-test-id="root-product-attributes-list">
                  {rootProductAttributesViews.map((element: RootProductAttributeView, index: number) => (
                    <RootProductAttributesListItem data-test-id="root-product-attributes-list__item" key={index}>
                      <ProductTextModifierCapitalize data-test-id="item__name">{element.attributeName}</ProductTextModifierCapitalize>{' '}
                      <ProductTextModifier data-test-id="item__value">{element.attributeValue}</ProductTextModifier>
                    </RootProductAttributesListItem>
                  ))}
                </RootProductAttributesList>
              )}

              <ProductQuantity data-test-id="root-product__quantity">
                {intl.formatMessage(
                  { id: 'RETURN.overview.productsSelection.productsSelectionForm.product.rootProductDetails.quantity' },
                  { value: <ProductTextModifier>{quantity}</ProductTextModifier> }
                )}
              </ProductQuantity>
            </ProductDetailsWrapper>
            <ProductHazmatInfo data-test-id="root-product__hazmat-info" isBold={product?.hazmatTypeSame ? product.hazmatTypeSame : false}>
              {product?.hazmatTypeSame === false &&
                intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.rootProductDetails.hazmat' })}
            </ProductHazmatInfo>
            {product.nonReturnableReasonCode && (
              <ProductStatusWrapper data-test-id="root-product__status-wrapper">
                <ProductStatusInfo
                  data-test-id="root-product__status-info"
                  isBold={product.nonReturnableReasonCode === NonReturnableReasonCode.ITEM_ALREADY_RETURNED}>
                  {isProductStatusAdditionalInfoExist(product.nonReturnableReasonCode, returnMessage)
                    ? returnMessage
                    : intl.formatMessage({ id: resolveProductStatusInfo(product.nonReturnableReasonCode, areExchangesEnabled) })}
                </ProductStatusInfo>
                {product.returnLabelId && product.method && (
                  <ProductLink
                    data-test-id="root-product__link"
                    rel="noopener"
                    target="_blank"
                    // @ts-ignore
                    onClick={(event: MouseEvent<HTMLAnchorElement>) => handleClickStopPropagation(event)}
                    href={createProductReturnLabelLink(vanityName, orderNumber, shippingPostalCode, urlKey, product.returnLabelId)}>
                    {intl.formatMessage({
                      id:
                        product.method === MethodEnum.Label
                          ? 'RETURN.overview.productsSelection.productsSelectionForm.product.rootProductDetails.productLabelLink'
                          : 'RETURN.overview.productsSelection.productsSelectionForm.product.rootProductDetails.productQRCodeLink'
                    })}
                  </ProductLink>
                )}
              </ProductStatusWrapper>
            )}
          </ProductDetails>
          {largeUp &&
            selectedProducts[id] &&
            isItemChecked(selectedProducts[id]) &&
            selectedProducts[id].option === 'exchange' &&
            isItemReturnQuantityValid(selectedProducts[id]) && (
              <ProductArrowContainer>
                {reasonsEnabled && selectedProducts[id].reason.length > 0 ? <LargeArrow /> : <SmallArrow />}
              </ProductArrowContainer>
            )}
        </RootProduct>

        {selectedProducts[id] && isItemChecked(selectedProducts[id]) && (
          <ProductOptions nonEligibleProductIsReturnable={!!product.nonReturnableReasonCode}>
            <ProductOptionsDescription
              data-test-id="product-options__description"
              isReturn={selectedProducts[id].option === 'return'}
              isExchange={selectedProducts[id].option === 'exchange'}>
              {intl.formatMessage({ id: resolveProductOptionDescription(selectedProducts[id].option) })}
            </ProductOptionsDescription>
            <ProductOptionsButtonsWrapper>
              {!selectedProducts[id] ||
                (selectedProducts[id] && !isItemOptionSelected(selectedProducts[id]) && (
                  <CustomButton variant="text" data-test-id="product__return-button" isUppercase onClick={(event) => handleClickReturnButton(event)}>
                    {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.options.button.return' })}
                  </CustomButton>
                ))}
              {((areExchangesEnabled && exchangeable && selectedProducts[id] && !isItemOptionSelected(selectedProducts[id])) ||
                (areExchangesEnabled &&
                  !exchangeable &&
                  selectedProducts[id] &&
                  !isItemOptionSelected(selectedProducts[id]) &&
                  isUserAuthenticated(urlKey) &&
                  rootProductAttributesValues.length > 0)) && (
                <CustomButton
                  marginDefault="0 0 0 20px"
                  data-test-id="product__exchange-button"
                  isUppercase
                  variant="text"
                  onClick={(event) => handleClickExchangeButton(event)}>
                  {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.options.button.exchange' })}
                </CustomButton>
              )}
              {selectedProducts[id] && selectedProducts[id].option === 'exchange' && (
                <CustomButton
                  marginDefault="0 20px 0 0"
                  data-gtm-id="return-select-exchanges-return-instead-button"
                  data-test-id="product__return-instead-button"
                  isUppercase
                  variant="text"
                  onClick={(event) => handleClickReturnInsteadButton(event)}>
                  {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.options.button.returnInstead' })}
                </CustomButton>
              )}
              {((areExchangesEnabled && exchangeable && selectedProducts[id] && selectedProducts[id].option === 'return') ||
                (areExchangesEnabled &&
                  !exchangeable &&
                  selectedProducts[id] &&
                  selectedProducts[id].option === 'return' &&
                  isUserAuthenticated(urlKey) &&
                  rootProductAttributesValues.length > 0)) && (
                <CustomButton
                  marginDefault="0 20px 0 0"
                  data-gtm-id="return-select-exchanges-exchange-button"
                  data-test-id="product__exchange-instead-button"
                  isUppercase
                  variant="text"
                  onClick={(event) => handleClickExchangeInsteadButton(event)}>
                  {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.options.button.exchangeInstead' })}
                </CustomButton>
              )}
              {selectedProducts[id] && (selectedProducts[id].option === 'exchange' || selectedProducts[id].option === 'return') && (
                <CustomButton
                  variant="text"
                  isUppercase
                  data-gtm-id="return-select-exchanges-cancel-button"
                  data-test-id="product__cancel-button"
                  onClick={(event) => handleClickCancelButton(event)}>
                  {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.options.button.cancel' })}
                </CustomButton>
              )}
            </ProductOptionsButtonsWrapper>
          </ProductOptions>
        )}

        {selectedProducts[id] &&
          isItemChecked(selectedProducts[id]) &&
          (selectedProducts[id].option === 'exchange' || selectedProducts[id].option === 'return') && (
            <ReviewFormFieldset
              data-test-id={`product-${id}__fieldset`}
              maxWidth="650px"
              paddingL="0 0 0 158px"
              margin="10px 0 0 0"
              lastOfTypeMarginBottom="0">
              <CustomFormGroup>
                <CustomFormControl
                  variant="outlined"
                  width="100%"
                  widthL="30%"
                  data-test-id={`product-${id}__select-quantity-form-control`}
                  size="small">
                  {isIE ? (
                    <CustomLabel data-test-id={`product-${id}__quantity-label`} htmlFor={`product-${id}__quantity-select`}>
                      {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.quantity.label' })}
                    </CustomLabel>
                  ) : (
                    <CustomLabel data-test-id={`product-${id}__quantity-label`} id={`product-${id}__quantity-select`}>
                      {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.quantity.label' })}
                    </CustomLabel>
                  )}
                  {isIE ? (
                    <CustomSelect
                      id={`product-${id}__quantity-select`}
                      name="quantity"
                      aria-describedby={`product-${id}__quantity-select-error-text`}
                      native
                      data-error-id={selectedProductsErrors[id] && !!selectedProductsErrors[id].selectedQuantityError}
                      error={selectedProductsErrors[id] && !!selectedProductsErrors[id].selectedQuantityError}
                      // @ts-ignore
                      value={selectedProducts[id].quantity === 0 ? '' : selectedProducts[id].quantity}
                      onClick={(event) => handleClickStopPropagation(event)}
                      // @ts-ignore
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeQuantity(event)}
                      required
                      displayEmpty
                      inputProps={{ 'data-test-id': `product-${id}__quantity-select` }}>
                      <option value="">
                        {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.quantity.select.placeholder' })}
                      </option>
                      {quantities.map((element: number) => (
                        <option key={element} value={element}>
                          {element}
                        </option>
                      ))}
                    </CustomSelect>
                  ) : (
                    <CustomSelect
                      labelId={`product-${id}__quantity-select`}
                      id={`product-${id}__quantity-select`}
                      name="quantity"
                      aria-describedby={`product-${id}__quantity-select-error-text`}
                      data-error-id={selectedProductsErrors[id] && !!selectedProductsErrors[id].selectedQuantityError}
                      error={selectedProductsErrors[id] && !!selectedProductsErrors[id].selectedQuantityError}
                      value={selectedProducts[id].quantity === 0 ? '' : selectedProducts[id].quantity}
                      onClick={(event) => handleClickStopPropagation(event)}
                      // @ts-ignore
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeQuantity(event)}
                      required
                      disabled={quantities.length === 1}
                      displayEmpty
                      inputProps={{ 'data-test-id': `product-${id}__quantity-select` }}>
                      <MenuItem value="">
                        <SelectPlaceholder>
                          {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.quantity.select.placeholder' })}
                        </SelectPlaceholder>
                      </MenuItem>
                      {quantities.map((element: number) => (
                        <MenuItem key={element} value={element}>
                          {element}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                  {selectedProductsErrors[id] && !!selectedProductsErrors[id].selectedQuantityError && (
                    <FormHelperText id={`product-${id}__quantity-select-error-text`} data-test-id={`product-${id}__quantity-select-error-text`}>
                      {selectedProductsErrors[id].selectedQuantityError}
                    </FormHelperText>
                  )}
                </CustomFormControl>

                {reasonsEnabled && (
                  <CustomFormControl
                    variant="outlined"
                    width="100%"
                    widthL="65%"
                    marginDefault="15px 0 0 0"
                    marginL="0"
                    size="small"
                    data-test-id={`product-${id}__reason-form-control`}>
                    {isIE ? (
                      <CustomLabel data-test-id={`product-${id}__reason-label`} htmlFor={`product-${id}__reason-select`}>
                        {intl.formatMessage(
                          { id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reason.label' },
                          { asterisk: reasonRequired ? ' *' : '' }
                        )}
                      </CustomLabel>
                    ) : (
                      <CustomLabel data-test-id={`product-${id}__reason-label`} id={`product-${id}__reason-select`}>
                        {intl.formatMessage(
                          { id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reason.label' },
                          { asterisk: reasonRequired ? ' *' : '' }
                        )}
                      </CustomLabel>
                    )}
                    {isIE ? (
                      <CustomSelect
                        id={`product-${id}__reason-select`}
                        name="reason"
                        native
                        data-error-id={selectedProductsErrors[id] && !!selectedProductsErrors[id].reasonError}
                        error={selectedProductsErrors[id] && !!selectedProductsErrors[id].reasonError}
                        value={selectedProducts[id].reason}
                        // @ts-ignore
                        onClick={(event: ChangeEvent<HTMLSelectElement>) => handleClickStopPropagation(event)}
                        // @ts-ignore
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeReason(event)}
                        required
                        displayEmpty
                        aria-describedby={`product-${id}__reason-select-error-text`}
                        inputProps={{ 'data-test-id': `product-${id}__reason-select` }}>
                        <option value="">
                          {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reason.select.placeholder' })}
                        </option>
                        {returnsReasonViews.map((element: ReturnsReasonViewType) => (
                          <option key={element.reasonText} value={element.reasonText}>
                            {element.reasonText}
                          </option>
                        ))}
                      </CustomSelect>
                    ) : (
                      <CustomSelect
                        labelId={`product-${id}__reason-select`}
                        name="reason"
                        aria-describedby={`product-${id}__reason-select-error-text`}
                        id={`product-${id}__reason-select`}
                        data-error-id={selectedProductsErrors[id] && !!selectedProductsErrors[id].reasonError}
                        error={selectedProductsErrors[id] && !!selectedProductsErrors[id].reasonError}
                        value={selectedProducts[id].reason}
                        // @ts-ignore
                        onClick={(event: ChangeEvent<HTMLSelectElement>) => handleClickStopPropagation(event)}
                        // @ts-ignore
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeReason(event)}
                        required
                        displayEmpty
                        inputProps={{ 'data-test-id': `product-${id}__reason-select` }}>
                        <MenuItem value="">
                          <SelectPlaceholder>
                            {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reason.select.placeholder' })}
                          </SelectPlaceholder>
                        </MenuItem>
                        {returnsReasonViews.map((element: ReturnsReasonViewType) => (
                          <MenuItem key={element.reasonText} value={element.reasonText}>
                            {element.reasonText}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    )}
                    {selectedProductsErrors[id] && !!selectedProductsErrors[id].reasonError && (
                      <FormHelperText id={`product-${id}__reason-select-error-text`} data-test-id={`product-${id}__reason-select-error-text`}>
                        {selectedProductsErrors[id].reasonError}
                      </FormHelperText>
                    )}
                  </CustomFormControl>
                )}
              </CustomFormGroup>

              {reasonsEnabled && selectedProducts[id].reason.length > 0 && (
                <CustomFormControl data-test-id={`product-${id}__reason-comment-form-control`} marginDefault="15px 0 0 0">
                  <CustomLabel htmlFor={`product-${id}__reason-comment`}>
                    {intl.formatMessage(
                      { id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reasonComment.label' },
                      { asterisk: isReasonCommentRequired ? ' *' : '' }
                    )}
                  </CustomLabel>
                  <CustomTextField
                    data-test-id={`product-${id}__reason-comment-text-field`}
                    id={`product-${id}__reason-comment`}
                    name="reason-comment"
                    fullWidth
                    size="small"
                    required
                    data-error-id={!!selectedProductsErrors && !!selectedProductsErrors[id].reasonCommentError}
                    error={!!selectedProductsErrors && !!selectedProductsErrors[id].reasonCommentError}
                    // @ts-ignore
                    onClick={(event: ChangeEvent<HTMLInputElement>) => handleClickStopPropagation(event)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeReasonComment(event)}
                    value={selectedProducts[id].comment}
                    helperText={
                      selectedProductsErrors[id] && !!selectedProductsErrors[id].reasonCommentError
                        ? selectedProductsErrors[id].reasonCommentError
                        : ''
                    }
                    variant="outlined"
                    FormHelperTextProps={{ 'data-test-id': `product-${id}__reason-comment-error-text` } as FormHelperTextProps}
                    inputProps={{ 'data-test-id': `product-${id}__reason-comment-input` }}
                  />
                </CustomFormControl>
              )}
            </ReviewFormFieldset>
          )}
        {areExchangesEnabled &&
          selectedProducts[id] &&
          isItemChecked(selectedProducts[id]) &&
          selectedProducts[id].option === 'exchange' &&
          isItemReturnQuantityValid(selectedProducts[id]) && (
            <ExchangeOptionsList
              orderNumber={orderNumber}
              rootProductSKU={sku}
              rootProductName={name}
              rootProductImageUrl={imageUrl}
              selectedQuantity={selectedProducts[id].quantity}
              rootProductId={id}
              rootProductAttributesValues={rootProductAttributesValues}
            />
          )}
      </ProductLabel>
    </ProductContainer>
  );
};

export interface OwnProps {
  shouldDisplayRootProductImage: boolean;
  product: OrderItemType;
  reasonsEnabled: boolean;
  reasonRequired: boolean;
  returnsReasonViews: ReturnsReasonViewType[];
  orderNumber: string;
  shippingPostalCode: string;
  urlKey: string;
  reasonsWithRequiredComment: string[];
  areExchangesEnabled: boolean;
}
