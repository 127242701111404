import styled from '@emotion/styled';
import { CustomSelect } from 'components/Shared/styled-components/CustomSelect/CustomSelect.styled';
import { CustomLoadingButton } from 'components/Shared/styled-components/CustomLoadingButton/CustomLoadingButton.styled';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { FormHelperText } from '@mui/material';

export const Form = styled.form`
  width: 100%;

  @media ${({ theme }) => theme.xlargeUp} {
    width: 50%;
  }
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: 400;
  margin: 0 0 25px 0;
  color: #4a4a4a;
`;

export const SearchParcelsFormCustomSelect = styled(CustomSelect)`
  background: transparent;
  border: none;
  outline: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.Mui-focused {
    background-color: rgba(0, 0, 0, 0.07);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &.Mui-disabled {
    background-color: transparent;
  }

  & > fieldset {
    border: none;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 5px 0 0 0;
  justify-content: center;
`;

export const Wrapper = styled.div`
  margin: 0 0 10px 0;
  width: 100%;
`;

export const SearchParcelsFormCustomLoadingButton = styled(CustomLoadingButton)`
  min-width: 56px;
  min-height: 56px;
  border-radius: 0 4px 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchParcelsFormCustomButton = styled(CustomButton)<{ visibility: string }>`
  visibility: ${({ visibility }) => visibility};
  border-radius: 4px;
  transition: 'all 2s ease-in-out' !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    transition: 'all 2s ease-in-out' !important;
    border-radius: 4px;
  }
`;

export const SearchParcelsCustomFormHelperText = styled(FormHelperText)`
  font-size: 14px;
  margin: 5px 0 0 0;
`;
