import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const ProductContainer = styled.li`
  margin-bottom: 24px;
  height: auto;

  &:last-of-type {
    margin: 0;
  }
`;

export const ProductInput = styled.input`
  appearance: none;
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
  background: transparent;
  border: none;
  display: block;
`;

export const ProductLabel = styled.div<{ isChecked: boolean; isClickable: boolean }>`
  width: 100%;
  padding: 18px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
      &:hover,
      &:focus {
        border: 1px solid #717171;
      }
    `}
  ${({ isChecked, theme }) =>
    isChecked &&
    css`
      cursor: default;
      background-color: #f8f8f8;
      padding: 17px;
      border: 2px solid #717171;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);

      &:hover,
      &:focus {
        border: solid 2px #717171;
      }

      @media ${theme.largeUp} {
        padding: 27px 29px;
      }
    `} @media ${({ theme }) => theme.largeUp} {
    padding: 28px 30px;
  }
`;

export const RootProduct = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const ProductArrowContainer = styled.div`
  width: 140px;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  padding-left: 40px;
  top: 112%;
`;

export const ProductArrowImage = styled.img`
  margin-left: 40px;
`;

export const ProductImageContainer = styled.div`
  width: 140px;
  margin-right: 18px;
`;

export const ProductDetails = styled.div<{ justifyContentSpaceBetween: boolean }>`
  flex: 2;
  display: flex;
  flex-direction: column;
  ${({ justifyContentSpaceBetween }) =>
    justifyContentSpaceBetween &&
    css`
      justify-content: space-between;
    `}
`;

export const ProductDetailsWrapper = styled.div<{ marginDefault?: string }>`
  margin: ${({ marginDefault }) => (marginDefault ? marginDefault : '0')};
`;

export const ProductName = styled.p`
  margin: 0;
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;

export const ProductSku = styled.p`
  margin: 0;
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;

export const RootProductAttributesList = styled.ul`
  margin: 0 0 1px 0;
`;

export const RootProductAttributesListItem = styled.li`
  margin: 0 0 1px 0;
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};

  &:last-of-type {
    margin: 0;
  }
`;

export const ProductQuantity = styled.p`
  margin: 0;
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;

export const ProductStatusWrapper = styled.div`
  margin: 20px 0 0 0;
`;

export const ProductStatusInfo = styled.p<{ isBold: boolean }>`
  margin: 0;
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
`;

export const ProductHazmatInfo = styled.p<{ isBold: boolean }>`
  margin: 0;
  font-size: 14px;
  padding-right: 170px;
  margin-top: 30px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
`;

export const ProductLink = styled.a`
  margin: 0;
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;

export const ProductTextModifier = styled.span`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;

export const ProductTextModifierCapitalize = styled.span`
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;

export const ProductOptions = styled.div<{ nonEligibleProductIsReturnable: boolean }>`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.largeUp} {
    margin-top: ${({ nonEligibleProductIsReturnable }) => (nonEligibleProductIsReturnable ? '10px' : 0)};
    padding-left: 158px;
  }
`;

export const ProductOptionsDescription = styled.p<{ isExchange: boolean; isReturn: boolean }>`
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};

  @media ${({ theme }) => theme.largeUp} {
    text-align: ${({ isReturn, isExchange }) => (isExchange || isReturn ? 'left' : 'right')};
  }
`;

export const ProductOptionsButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustomFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.largeUp} {
    flex-direction: row;
  }
`;
