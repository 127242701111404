import { BASE_URL } from 'constants/config';
import { isPreviewBasedOnRoute } from 'utils/app/is-preview-based-on-route/is-preview-based-on-route';

export const getApiUrl = (trackingNumber: string | undefined, previewType?: string, designId?: string): string => {
  if (previewType && designId) {
    return `${BASE_URL}/track/preview/${previewType}/package-details/${designId}/${trackingNumber}`;
  }

  if (previewType) {
    return `${BASE_URL}/track/preview/${previewType}/package-details/${trackingNumber}`;
  }

  return `${BASE_URL}/track/package-details/${trackingNumber}${window.location.search}`;
};

export const setTrackingDataUrl = (
  trackingNumber: string | undefined,
  key: string | null,
  isPreview: boolean,
  designId: string | null,
  pathname: string
): string => {
  if (isPreviewBasedOnRoute(pathname) || (isPreview && designId)) return `/api/track/preview/draft/package-details/published/${trackingNumber}`;
  return `/api/track/package-details/${trackingNumber}${key ? `?key=${key}` : ``}`;
};
