import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';
import { Link } from 'react-router-dom';

const Navigation = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;

const NavLinkItem = styled.li`
  display: inline-block;
  margin: 0 5% 0 2.5%;

  @media ${({ theme }) => theme.mediumDown} {
    width: 100%;
  }
`;

const NavLink = styled.a`
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: none;
  text-decoration: none;
  text-align: center;
  opacity: 0.85;

  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? theme.white : theme.black)};

  &:hover,
  &:focus {
    color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? theme.white : theme.black)};
    opacity: 1;
    text-decoration: none;
  }
`;

export const NavLinkTo = styled(Link)`
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: none;
  text-decoration: none;
  text-align: center;
  opacity: 0.85;

  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? theme.white : theme.black)};

  &:hover,
  &:focus {
    color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? theme.white : theme.black)};
    opacity: 1;
    text-decoration: none;
  }
`;

const DesktopNav = styled.nav<{ logoCenter: boolean }>`
  width: 100%;

  ${NavLink} {
    white-space: pre;
  }

  ${Navigation} {
    @media ${({ theme }) => theme.largeUp} {
      justify-content: ${({ logoCenter }) => (logoCenter ? 'center' : 'flex-start')};
    }

    ${({ logoCenter }) =>
      logoCenter &&
      css`
        padding: 10px 0;
      `}
  }
`;

const MobileNav = styled.nav`
  position: absolute;
  margin: 0;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  z-index: 1;

  > div {
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.25);
  }

  * {
    user-select: none;
  }

  ${Navigation} {
    flex-flow: column nowrap;
    background: ${({ theme }) => getCustomStyle('styleModule.primaryColor', theme.black)};
  }

  ${NavLinkItem} {
    border-bottom: 1px solid
      rgba(${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? `255, 255, 255, .2` : `0, 0, 0, .05`)});

    &:last-of-type {
      border-bottom: 0;
    }
  }

  ${NavLink} {
    padding: 12px 0;
    display: block;
    font-size: 16px;
    text-transform: none;
    text-decoration: none;
    opacity: 1;
  }
`;

export { Navigation, NavLinkItem, DesktopNav, NavLink, MobileNav };
