export enum Environment {
  LOCAL,
  AWS_DEV,
  AWS_PPD,
  AWS_PRD
}

export function resolveEnvironment(): Environment {
  const appLocation: string = window.location.href;
  if (appLocation.includes('shipment-prd.co') || appLocation.includes('shipment.co')) return Environment.AWS_PRD;

  if (appLocation.includes('shipment-demo.co')) return Environment.AWS_PPD;

  if (appLocation.includes('shipment-dev.co')) return Environment.AWS_DEV;

  return Environment.LOCAL;
}
