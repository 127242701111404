import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  ContentWrapper,
  FormsContainer,
  NotificationsContainer,
  NotificationsSubtitle,
  NotificationsTitle,
  TextWrapper
} from './Notifications.styled';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { EmailForm } from './EmailForm/EmailForm';
import { SmsForm } from './SmsForm/SmsForm';
import useFetch, { DefaultErrorResponse } from 'hooks/useFetch/useFetch';
import { DeliveryType } from 'types/delivery-type';
import { Loading } from 'components/Shared/Loading/Loading';
import {
  Email,
  NotificationNumber,
  NotificationsEmailGetResponse,
  NotificationsSmsGetResponse
} from 'types/tracking/common/notifications/notifications-type';
import { useIntl } from 'react-intl';
import { NotificationsSelect } from './NotificationsSelect/NotificationsSelect';
import { TemplateEnum } from 'types/shared/template-type';
import { NotificationsModuleType } from 'types/shared/config/notificationsModule/notifications-module-type';
import { ClientConfigContext } from '../../../context/shared/ClientConfigContext';

export const Notifications: React.FC<OwnProps> = (props) => {
  const { notificationsModule, bgColor, trackingNumber, vanityName, urlKey, deliveryType, setShowNotifications } = props;
  const {
    emailEnabled,
    smsEnabled,
    backgroundImage,
    notificationBackgroundDark,
    showBackgroundAsColor,
    backgroundColor,
    headerText,
    subheadText
  } = notificationsModule;
  const { template } = useContext(ClientConfigContext);

  //api
  const EMAIL_URL = `/notification/email/${trackingNumber}/${vanityName}?key=${urlKey ? urlKey : undefined}`;
  const SMS_URL = `/notification/sms/${trackingNumber}/${vanityName}?key=${urlKey ? urlKey : undefined}`;

  const [title, setTitle] = useState<string>(headerText);
  const [subtitle, setSubtitle] = useState<string>(subheadText);
  const [shouldDisplayEmailForm, setShouldDisplayEmailForm] = useState<boolean>(false);
  const [shouldDisplaySmsForm, setShouldDisplaySmsForm] = useState<boolean>(false);
  const [numbers, setNumbers] = useState<NotificationNumber[]>([]);
  const [emails, setEmails] = useState<Email[]>([]);
  const [email, , emailError] = useFetch<NotificationsEmailGetResponse, DefaultErrorResponse>(EMAIL_URL);
  const [sms, , smsError] = useFetch<NotificationsSmsGetResponse, DefaultErrorResponse>(SMS_URL);

  useEffect(() => {
    if (sms && sms.numbers) setNumbers([...sms.numbers]);
    if (email && email.emails) setEmails([...email.emails]);
  }, [sms, email]);

  useEffect(() => {
    if (emailError && smsError) return setShowNotifications(false);
  }, [emailError, setShowNotifications, smsError]);

  const intl = useIntl();

  useEffect(() => {
    if (email && email.emails && email.emails.length > 0) return setShouldDisplayEmailForm(true);
  }, [email]);

  useEffect(() => {
    if (sms && sms.numbers && sms.numbers.length > 0) return setShouldDisplaySmsForm(true);
  }, [sms]);

  useEffect(() => {
    if (!shouldDisplayEmailForm && !shouldDisplaySmsForm) {
      setTitle(headerText);
      setSubtitle(subheadText);
    }

    if (shouldDisplayEmailForm && !shouldDisplaySmsForm) {
      setTitle(intl.formatMessage({ id: 'TRACKING.notifications.title.emailOnly' }));
      setSubtitle(intl.formatMessage({ id: 'TRACKING.notifications.subtitle.emailOnly' }));
    }

    if (!shouldDisplayEmailForm && shouldDisplaySmsForm) {
      setTitle(intl.formatMessage({ id: 'TRACKING.notifications.title.smsOnly' }));
      setSubtitle(intl.formatMessage({ id: 'TRACKING.notifications.subtitle.smsOnly' }));
    }

    if (shouldDisplayEmailForm && shouldDisplaySmsForm) {
      setTitle(intl.formatMessage({ id: 'TRACKING.notifications.title.emailAndSms' }));
      setSubtitle(intl.formatMessage({ id: 'TRACKING.notifications.subtitle.emailAndSms' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplayEmailForm, shouldDisplaySmsForm]);

  if ((emailError && smsError) || (!emailEnabled && !smsEnabled)) return null;
  if ((!email && !emailError) || (!sms && !smsError))
    return (
      <SectionLayout
        paddingDefault={template === TemplateEnum.VIVID ? '20px 30px' : ''}
        paddingMd={template === TemplateEnum.VIVID ? '20px 30px' : ''}
        paddingL={template === TemplateEnum.VIVID ? '20px 50px' : ''}
        paddingXl={template === TemplateEnum.SPARTAN ? '0' : '30px 50px'}
        paddingXXl={template === TemplateEnum.SPARTAN ? '0' : '30px 100px'}
        marginDefault={template === TemplateEnum.VIVID ? '60px 0' : '0 0 2px 0'}
        marginXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
        marginXXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
        bgColor={template === TemplateEnum.VIVID ? backgroundColor : bgColor}
        backgroundAsColor={true}
        backgroundImage={''}>
        <NotificationsContainer>
          <Loading height="150px" data-test-id="notifications-spinner-container" />
        </NotificationsContainer>
      </SectionLayout>
    );

  return (
    <SectionLayout
      data-test-id="notifications-section"
      paddingDefault={template === TemplateEnum.VIVID ? '20px 30px' : ''}
      paddingMd={template === TemplateEnum.VIVID ? '20px 30px' : ''}
      paddingL={template === TemplateEnum.VIVID ? '20px 50px' : ''}
      paddingXl={template === TemplateEnum.SPARTAN ? '0' : '30px 50px'}
      paddingXXl={template === TemplateEnum.SPARTAN ? '0' : '30px 100px'}
      marginDefault={template === TemplateEnum.VIVID ? '60px 0' : '0 0 2px 0'}
      marginXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
      marginXXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
      bgColor={template === TemplateEnum.VIVID ? backgroundColor : bgColor}
      backgroundAsColor={template === TemplateEnum.VIVID ? showBackgroundAsColor : true}
      backgroundImage={backgroundImage ? backgroundImage : ''}>
      <NotificationsContainer data-test-id="notifications-container">
        <ContentWrapper>
          <TextWrapper>
            <NotificationsTitle data-test-id="notifications-title" isDark={!notificationBackgroundDark}>
              {title}
            </NotificationsTitle>
            <NotificationsSubtitle data-test-id="notifications-subtitle" isDark={!notificationBackgroundDark}>
              {subtitle}
            </NotificationsSubtitle>
          </TextWrapper>
          <NotificationsSelect
            setShouldDisplayEmailForm={setShouldDisplayEmailForm}
            setShouldDisplaySmsForm={setShouldDisplaySmsForm}
            shouldDisplayEmailForm={shouldDisplayEmailForm}
            shouldDisplaySmsForm={shouldDisplaySmsForm}
            shouldDisplayEmailButton={!!email && emailEnabled}
            shouldDisplaySmsButton={!!sms && smsEnabled}
          />
        </ContentWrapper>

        {((shouldDisplayEmailForm && email && emailEnabled) || (shouldDisplaySmsForm && sms && smsEnabled)) && (
          <FormsContainer data-test-id="notifications-forms-container">
            {shouldDisplayEmailForm && email && emailEnabled && (
              <EmailForm
                emails={emails}
                setEmails={setEmails}
                setShouldDisplayEmailForm={setShouldDisplayEmailForm}
                trackingNumber={trackingNumber}
                urlKey={urlKey}
                deliveryType={deliveryType}
                isDark={!notificationBackgroundDark}
              />
            )}
            {shouldDisplaySmsForm && sms && smsEnabled && (
              <SmsForm
                numbers={numbers}
                setNumbers={setNumbers}
                setShouldDisplaySmsForm={setShouldDisplaySmsForm}
                trackingNumber={trackingNumber}
                urlKey={urlKey}
                deliveryType={deliveryType}
                isDark={!notificationBackgroundDark}
              />
            )}
          </FormsContainer>
        )}
      </NotificationsContainer>
    </SectionLayout>
  );
};

export interface OwnProps {
  notificationsModule: NotificationsModuleType;
  bgColor?: string;
  urlKey: string | null;
  trackingNumber: string;
  vanityName: string;
  setShowNotifications: Dispatch<SetStateAction<boolean>>;
  deliveryType: DeliveryType.Return | DeliveryType.Forward;
}
