import { Button, Skeleton } from '@mui/material';
import { Alert } from 'components/Shared/Alert/Alert';
import React, { Dispatch, SetStateAction, useEffect, useState, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { DropOffLocationType } from 'types/return/return-info/Common/drop-off-location-type';
import { OriginAddress } from 'types/return/return-info/Common/origin-address-type';
import { resolveSearchLocationInfo } from 'utils/returns/returnInfo/common/resolve-search-location-info/resolve-search-location-info';
import { DropOffLocationItem } from './DropOffLocationItem/DropOffLocationItem';
import { DropOffLocationItemSkeleton } from './DropOffLocationItem/DropOffLocationItemSkeleton';
import {
  Container,
  Title,
  List,
  ContainerWrapper,
  DropOffLabel,
  DropOffContainer,
  DropOffContentBox,
  DropOffWrapper,
  DropOffMapWrapper,
  UnpackedContentBox,
  UnpackedBoxWrapper,
  UnpackedBoxLabel,
  UnpackedBoxText
} from './DropOffLocationsList.styled';
import { UnpackIcon, UnpackIconContainer } from './DropOffLocationItem/DropOffLocationItem.styled';
import { DropOffLocationEmptyState } from './DropOffLocationItem/DropOffLocationEmptyState';
import { GoogleMaps } from './GoogleMap/GoogleMap';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const DropOffLocationsList: React.FC<OwnProps> = (props) => {
  const { errorTitle, errors, isLoading, items, originAddress, isFormVisible, setIsFormVisible, userLatitudePosition, userLongitudePosition } = props;
  const { street: originStreet, city: originCity, state: originState, zipCode: originZipCode } = originAddress;
  const [dropOffLocations, setDropOffLocations] = useState<DropOffLocationType[]>(items);
  // const [userLatitudePosition, setUserLatitudePosition] = useState<number | null>(null);
  // const [userLongitudePosition, setUserLongitudePosition] = useState<number | null>(null);
  const [selectedDropOffLocation, setSelectedDropOffLocation] = useState<number | null>(null);
  const [isUnpackedBoxChecked, setUnpackedBoxChecked] = useState<boolean>(false);

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black
      }
    })
  );

  // useEffect(() => {
  //   const successCallback = (position) => {
  //     window.dataLayer.push({
  //       isUserLocationEnabled: 'Location Enabled'
  //     });
  //     setUserLatitudePosition(position.coords.lat);
  //     setUserLongitudePosition(position.coords.long);
  //   };

  //   const errorCallback = () => {
  //     window.dataLayer.push({
  //       isUserLocationEnabled: 'Location Disabled'
  //     });
  //   };

  //   navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  //   function queryAndTrackPermission(permissionName) {
  //     navigator.permissions.query({ name: permissionName }).then((permissionStatus) => {
  //       permissionStatus.onchange = () => {
  //         if (permissionStatus.state === 'granted') navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  //         if (permissionStatus.state === 'denied') navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  //       };
  //     });
  //   }
  //   queryAndTrackPermission('geolocation');
  // }, []);

  const intl = useIntl();

  let timeoutId;

  const handleClickOpenForm = () => {
    setIsFormVisible(true);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    setDropOffLocations(items);
  }, [items]);

  useEffect(() => {
    const firstNearestLocation = dropOffLocations[0];
    if (dropOffLocations.length > 0) {
      setSelectedDropOffLocation(firstNearestLocation.id);
    } else {
      setSelectedDropOffLocation(null);
    }
  }, [dropOffLocations]);

  const handleClickMethod = (value) => {
    setSelectedDropOffLocation(value.id);
  };

  const handleUnpackBoxCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { checked } = target;
    setUnpackedBoxChecked(checked);
    if (!isUnpackedBoxChecked) {
      let filteredLocations: DropOffLocationType[] = [];
      setDropOffLocations(filteredLocations);
      filteredLocations = items.filter((location) => {
        return location.packageLess;
      });
      setDropOffLocations(filteredLocations);
    } else {
      setDropOffLocations(items);
    }
  };

  return (
    <Container data-test-id="drop-off-locations">
      <ContainerWrapper>
        <Title id="drop-off-locations-heading" data-test-id="drop-off-locations__heading">
          {intl.formatMessage(
            { id: 'RETURN.returnInfo.common.dropOffLocationsList.title' },
            {
              info:
                (!!originCity && !!originState) || !!originZipCode ? (
                  resolveSearchLocationInfo(originStreet, originCity, originState, originZipCode)
                ) : (
                  <Skeleton
                    variant="text"
                    width={80}
                    sx={{ fontSize: '14px', margin: '0 0 0 5px' }}
                    data-test-id="drop-off-locations__heading-skeleton"
                  />
                )
            }
          )}
        </Title>
        {!isFormVisible && (
          <Button
            data-gtm-id="drop-off-locations-change-button"
            onClick={handleClickOpenForm}
            variant="text"
            size="small"
            sx={{ margin: '1px 0 0 10px', fontSize: '14px', color: '#0072B8', textTransform: 'none' }}>
            {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocationsList.button.openForm' })}
          </Button>
        )}
      </ContainerWrapper>
      {isLoading && (
        <List aria-labelledby="drop-off-locations-heading" data-test-id="drop-off-locations-skeletons-list">
          <DropOffLocationItemSkeleton />
          <DropOffLocationItemSkeleton />
          <DropOffLocationItemSkeleton />
        </List>
      )}

      {!isLoading && !errorTitle && errors.length === 0 && (
        <div>
          {items.length > 0 && (
            <UnpackedContentBox>
              <UnpackedBoxWrapper>
                <UnpackedBoxText>
                  <input value="test" type="checkbox" onChange={handleUnpackBoxCheck} checked={isUnpackedBoxChecked} />
                  <UnpackedBoxLabel>{intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocationsList.UnpackedBox' })}</UnpackedBoxLabel>
                </UnpackedBoxText>
                <UnpackIconContainer>
                  <BootstrapTooltip
                    title={intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocationItem.unpackedIconInfo' })}
                    placement="right">
                    <UnpackIcon src="/assets/img/Icon_Box_Block.svg" alt="reminder finger" />
                  </BootstrapTooltip>
                </UnpackIconContainer>
              </UnpackedBoxWrapper>
            </UnpackedContentBox>
          )}
          <DropOffContentBox>
            <DropOffWrapper className="dropOffLocation">
              {dropOffLocations.length > 0 && (
                <List aria-labelledby="drop-off-locations-heading" data-test-id="drop-off-locations-list">
                  {dropOffLocations.map((element: DropOffLocationType) => (
                    <DropOffContainer key={element.id} className="column">
                      <DropOffLabel
                        htmlFor={`drop-off-radio-input-${element.id}`}
                        data-test-id="drop-off-label"
                        checked={selectedDropOffLocation === element.id}
                        key={element.id}
                        onClick={() => handleClickMethod(element)}>
                        <DropOffLocationItem
                          item={element}
                          key={element.id}
                          userLatitudePosition={userLatitudePosition}
                          userLongitudePosition={userLongitudePosition}
                          originStreet={originStreet}
                          originCity={originCity}
                          originState={originState}
                          originZipCode={originZipCode}
                          selectedDropOffLocation={selectedDropOffLocation}
                        />
                      </DropOffLabel>
                    </DropOffContainer>
                  ))}
                </List>
              )}
              {dropOffLocations.length === 0 && <DropOffLocationEmptyState isUnpackedBoxChecked={isUnpackedBoxChecked}></DropOffLocationEmptyState>}
            </DropOffWrapper>
            <DropOffMapWrapper className="googleMap">
              <GoogleMaps
                dropOffLocations={dropOffLocations}
                userLatitudePosition={userLatitudePosition}
                userLongitudePosition={userLongitudePosition}
                originStreet={originStreet}
                originCity={originCity}
                originState={originState}
                originZipCode={originZipCode}
                selectedDropOffLocation={selectedDropOffLocation}
                setSelectedDropOffLocation={setSelectedDropOffLocation}></GoogleMaps>
            </DropOffMapWrapper>
          </DropOffContentBox>
        </div>
      )}

      {!isLoading && !!errorTitle && <Alert message={intl.formatMessage({ id: errorTitle })} error={errors} />}
    </Container>
  );
};

export interface OwnProps {
  originAddress: OriginAddress;
  isLoading: boolean;
  items: DropOffLocationType[];
  errorTitle: string | null;
  errors: string[];
  isFormVisible: boolean;
  setIsFormVisible: Dispatch<SetStateAction<boolean>>;
  userLatitudePosition: number;
  userLongitudePosition: number;
}
