import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const HeaderLayout = styled.header`
  position: relative;
`;

export const HeaderContainer = styled.div<{ isLogoCenter: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.primaryColor};
  z-index: 1000;

  ${({ theme, isLogoCenter }) =>
    theme.template === 'spartan' &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    !isLogoCenter &&
    css`
      justify-content: flex-start;
    `}

  ${({ theme, isLogoCenter }) =>
    theme.pageType === PageTypeEnum.CustomerServicePage &&
    !isLogoCenter &&
    css`
      justify-content: flex-start;
    `}

  ${({ theme, isLogoCenter }) =>
    theme.pageType === PageTypeEnum.UnsubscribePage &&
    !isLogoCenter &&
    css`
      justify-content: flex-start;
    `}

  ${({ theme, isLogoCenter }) =>
    theme.pageType === PageTypeEnum.ReturnsPage &&
    !isLogoCenter &&
    css`
      justify-content: center;
      padding: 0 15px;

      @media ${theme.largeUp} {
        padding: 0 50px;
      }

      @media ${theme.xlargeUp} {
        padding: 0 100px;
      }
    `}

       ${({ theme, isLogoCenter }) =>
         theme.pageType === PageTypeEnum.CancelPickupPage &&
         !isLogoCenter &&
         css`
           justify-content: flex-start;
         `}  


  ${({ theme }) =>
    !theme.primaryBackgroundDark &&
    css`
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    `}
`;

export const HeaderContent = styled.div<{ height: string; laptopView: boolean; isLogoCenter: boolean; isNavExists: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ laptopView }) => (laptopView ? 'space-between' : 'center')};
  width: 100%;
  max-width: 840px;
  height: ${({ height }) => height};
  padding: 0 20px;
  overflow: hidden;
  
  @media ${({ theme }) => theme.mediumUp} {
    padding: 0 30px;
  }
  ${({ theme, isLogoCenter }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      max-width: 840px;
      padding: 0 20px;

      @media ${theme.mediumUp} {
        padding: 0 30px;
      }

      @media ${theme.largeUp} {
        padding: ${isLogoCenter ? '0 50px' : '0 0 0 50px'};
      }

      @media ${theme.xxlargeUp} {
        padding: ${isLogoCenter ? '0 120px' : '0 0 0 120px'};
      }
    `}
  ${({ theme, isLogoCenter }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.CustomerServicePage &&
    css`
      padding: 0 30px;

      @media ${theme.xlargeUp} {
        padding: ${isLogoCenter ? '0 95px' : '0 0 0 95px'};
      }
      @media ${theme.xxlargeUp} {
        padding: ${isLogoCenter ? '0 115px' : '0 0 0 115px'};
      }
    `}

  ${({ theme, isLogoCenter }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.UnsubscribePage &&
    css`
      padding: 0 30px;

      @media ${theme.xlargeUp} {
        padding: ${isLogoCenter ? '0 95px' : '0 0 0 95px'};
      }
      @media ${theme.xxlargeUp} {
        padding: ${isLogoCenter ? '0 115px' : '0 0 0 115px'};
      }
    `}
  
  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.ReturnsPage &&
    css`
      max-width: 840px;
      padding: 0;

      @media ${theme.mediumUp} {
        padding: 0;
      }
    `}

    ${({ theme, isLogoCenter }) =>
      theme.pageType &&
      theme.pageType === PageTypeEnum.CancelPickupPage &&
      css`
        padding: 0 30px;

        @media ${theme.mediumUp} {
          padding: ${isLogoCenter ? '0 50px' : '0 0 0 50px'};
        }

        @media ${theme.xlargeUp} {
          padding: ${isLogoCenter ? '0 95px' : '0 0 0 95px'};
        }
        @media ${theme.xxlargeUp} {
          padding: ${isLogoCenter ? '0 115px' : '0 0 0 115px'};
        }
      `}

  ${({ theme, isLogoCenter, height }) =>
    isLogoCenter &&
    css`
      @media ${theme.mediumUp} {
        flex-direction: column;
        height: ${height};
        padding: 0;
      }
      @media ${theme.largeUp} {
        justify-content: center;
      }
    `}
`;

export const LogoContainer = styled.div<{ isLogoCenter: boolean }>`
  padding: 10px 0;

  @media ${({ theme }) => theme.largeUp} {
    padding: 10px 25px 10px 0;
  }

  ${({ isLogoCenter, theme }) =>
    isLogoCenter &&
    css`
      padding: 10px 0;

      @media ${theme.mediumUp} {
        padding: 10px 0;
      }
    `}
`;

export const HeaderBumpContent = styled.section<{ marginTop: string }>`
  margin: ${({ marginTop }) => `${marginTop} auto 0`};
`;
