import styled from '@emotion/styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { css } from '@emotion/react';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const FeaturedProductsContainer = styled.div`
  width: 100%;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      max-width: 800px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      max-width: 840px;
    `}
`;

export const FeaturedProductsSlider = styled(Slider)`
  .slick-arrow:before {
    content: '';
    background-image: url('../../../assets/img/icon-arrow-grey.svg');
    width: 15px;
    height: 20px;
    display: block;
    background-repeat: no-repeat;
  }

  .slick-next:before {
    transform: rotate(-180deg);
    margin-left: 10px;
  }
`;
