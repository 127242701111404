import { resolveEnvironment, Environment } from 'utils/common/environment-resolver/environment-resolver';
import { CLIENT_CONFIGURATION_API_HOSTS } from 'constants/client-configuration-api-hosts';
import { SEARCH_SERVICE_API_HOSTS } from 'constants/search-service-api-hosts';
import { GIFT_APP_URLS } from 'constants/gift-app-hosts';
import { ApiHostTypeEnum } from 'types/shared/config/api-host-type-enum';
import { HostType } from 'types/shared/config/host-type';

export const getApiHostURL = (type: ApiHostTypeEnum): string => {
  const domain = window.location.href;
  const HOSTS: HostType = type === ApiHostTypeEnum.CLIENT_CONFIGURATION_API ? CLIENT_CONFIGURATION_API_HOSTS : SEARCH_SERVICE_API_HOSTS;

  if (domain.includes('shipment.co')) return HOSTS['PROD'];

  const env: Environment = resolveEnvironment();

  return HOSTS[env];
};

export const getGiftAppURL = (): string => {
  const domain = window.location.href;

  if (domain.includes('shipment.co')) return GIFT_APP_URLS['PROD'];

  const env: Environment = resolveEnvironment();

  return GIFT_APP_URLS[env];
};
