import React from 'react';
import { Collapse, TableRow, TableCell } from '@mui/material';
import { TrackingEvent } from 'types/customer-service/find-parcels-api-response-type';
import { TrackingEventsTable } from 'components/CustomerService/Parcels/ParcelsList/TrackingEventsTable/TrackingEventsTable';

export const CollapseTrackingEventsRow = (props: OwnProps) => {
  const { isCollapse, trackingEvents, referenceNumber } = props;

  return (
    <TableRow>
      <TableCell colSpan={6} sx={{ padding: 0 }}>
        <Collapse in={isCollapse} timeout="auto" unmountOnExit>
          <TrackingEventsTable trackingEvents={trackingEvents} referenceNumber={referenceNumber} />
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export interface OwnProps {
  isCollapse: boolean;
  trackingEvents: TrackingEvent[];
  referenceNumber: string | null;
}
