import React, { ChangeEvent } from 'react';
import { CheckboxContainer, StyledCheckbox } from './Checkbox.styled';
import { isIE } from 'react-device-detect';

export const Checkbox: React.FC<OwnProps> = (props) => {
  const { checked, onChange, color, dataTestId, gtmTagId, marginDefault, marginMd, marginL, marginXl, marginXXl } = props;

  return (
    <CheckboxContainer marginDefault={marginDefault} marginMd={marginMd} marginL={marginL} marginXl={marginXl} marginXXl={marginXXl}>
      {isIE ? (
        <input type="checkbox" checked={checked} onChange={onChange} data-test-id={dataTestId} data-gtm-id={gtmTagId} />
      ) : (
        <StyledCheckbox type="checkbox" checked={checked} onChange={onChange} color={color} data-test-id={dataTestId} data-gtm-id={gtmTagId} />
      )}
    </CheckboxContainer>
  );
};

export interface OwnProps {
  checked: boolean;
  onChange: (arg: ChangeEvent<HTMLInputElement>) => void;
  color?: string;
  dataTestId?: string;
  gtmTagId?: string;
  marginDefault?: string;
  marginMd?: string;
  marginL?: string;
  marginXl?: string;
  marginXXl?: string;
}
