import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { SelectedProduct, TransformedExchangeItem } from 'models/returns/selected-product/SelectedProduct';
import React, { PropsWithChildren } from 'react';
import { transformExchangesItems } from 'utils/returns/overview/review/transform-exchanges-items/transform-exchanges-items';
import { ProductDetails } from './ProductDetails/ProductDetails';
import { ArrowBox, ExchangeBox, ItemBox, ItemTitle, ReturnBox, SelectedProductReviewItemContainer } from './SelectedProductReviewItem.styled';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBOutlineIconsEnum } from 'constants/PBIconsEnums';

export const SelectedProductReviewItem: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { selectedProduct, first, anyExchangeProductExists, isAtLeastOneProductAtAllHasImage } = props;

  const intl = useIntl();
  const theme = useTheme();
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));

  return (
    <SelectedProductReviewItemContainer key={selectedProduct.id} first={first} data-test-id="selected-product-review-item">
      <ItemBox>
        <ReturnBox data-test-id="return-box" anyExchangeProductExists={anyExchangeProductExists}>
          {!largeUp && (
            <ItemTitle data-test-id="return-box__title">
              {intl.formatMessage({ id: 'RETURN.overview.review.selectedProductsReviewList.return.title' })}
            </ItemTitle>
          )}
          <ProductDetails
            as="div"
            displayImage={isAtLeastOneProductAtAllHasImage}
            id={selectedProduct.id}
            name={selectedProduct.rootProductName}
            sku={selectedProduct.sku}
            imageUrl={selectedProduct.rootProductImageUrl}
            attributes={selectedProduct.rootProductAttributes}
            quantity={selectedProduct.quantity}
            reason={selectedProduct.reason}
            comment={selectedProduct.comment}
          />
        </ReturnBox>
        {selectedProduct.option === 'exchange' && (
          <ArrowBox isTreatAsSpacer={false} data-test-id="arrow-box">
            <PBIcon name={PBOutlineIconsEnum.TailDown} type={PBIconTypeEnum.Outline} size="lg" data-test-id="arrow-icon" />
          </ArrowBox>
        )}
        {selectedProduct.option === 'exchange' && selectedProduct.exchangeProducts && (
          <ExchangeBox data-test-id="exchange-box">
            {!largeUp && (
              <ItemTitle data-test-id="exchange-box__title">
                {intl.formatMessage({ id: 'RETURN.overview.review.selectedProductsReviewList.exchange.title' })}
              </ItemTitle>
            )}
            {transformExchangesItems(selectedProduct.exchangeProducts, selectedProduct.exchangeOptionsAttributes).map(
              (exchangeProduct: TransformedExchangeItem) => (
                <ProductDetails
                  displayImage={isAtLeastOneProductAtAllHasImage}
                  as="li"
                  key={exchangeProduct.id}
                  id={exchangeProduct.id}
                  name={exchangeProduct.name}
                  sku={exchangeProduct.sku}
                  imageUrl={exchangeProduct.exchangeProductImageUrl}
                  attributes={exchangeProduct.attributes}
                  quantity={exchangeProduct.quantity}
                />
              )
            )}
          </ExchangeBox>
        )}
      </ItemBox>
    </SelectedProductReviewItemContainer>
  );
};

export interface OwnProps {
  anyExchangeProductExists: boolean;
  isAtLeastOneProductAtAllHasImage: boolean;
  first: boolean;
  selectedProduct: SelectedProduct;
}
