import React, { useContext, useState } from 'react';
import useFetch, { DefaultErrorResponse } from 'hooks/useFetch/useFetch';
import { OrderItem } from 'components/Shared/OrderItem/OrderItem';
import { useIntl } from 'react-intl';
import { OrderDetailsButton, OrderDetailsButtonWrapper, OrderDetailsContainer, OrderDetailsList, OrderDetailsTitle } from './OrderDetails.styled';
import { Animate } from 'react-show';
import { TemplateEnum } from 'types/shared/template-type';
import { OrderDetailsModuleType } from 'types/shared/config/orderDetailsModule/order-details-module-type';
import { OrderDetailsType } from 'types/tracking/common/order-details/order-details-type';
import { ShippingInfoModuleType } from 'types/tracking/trackingConfig/shippingInfoModule/shipping-info-module-type';
import { SectionDescription } from 'components/Tracking/Common/SectionDescription/SectionDescription';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { Loading } from 'components/Shared/Loading/Loading';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';

export const OrderDetails: React.FC<OwnProps> = (props) => {
  const { vanityName, urlKey, shippingInfoModule, isPreview, orderDetailsModule } = props;
  const ORDER_ITEM_URL = `${isPreview ? '/preview' : ''}/order/${vanityName}/${shippingInfoModule.trackingNumber}?key=${urlKey ? urlKey : undefined}`;

  const [showMore, setShowMore] = useState(false);
  const [seeButton, setSeeButton] = useState(false);

  const intl = useIntl();
  const { template } = useContext(ClientConfigContext);

  const [orderItems, , error] = useFetch<OrderDetailsType[], DefaultErrorResponse>(ORDER_ITEM_URL);

  const orderItemsFirstThree = (orderItems || []).slice(0, 3);
  const orderItemsNext = (orderItems || []).slice(3);

  const handleShowMore = (showMoreValue) => {
    setShowMore(!showMoreValue);

    window.dataLayer.push({
      event: 'trackEvent',
      eventCategory: window.dataLayerVariables.eventCategory,
      eventAction: 'Your Orders',
      eventLabel: 'See More',
      merchantId: window.dataLayerVariables.merchantId,
      merchantName: window.dataLayerVariables.merchantName
    });

    setTimeout(() => {
      setSeeButton(!showMoreValue);
    }, 300);
  };

  if (error) return null;

  if (orderItems && orderItems.length === 0) return null;

  if (!orderItems)
    return (
      <SectionLayout
        backgroundAsColor={false}
        justifyContent={template === TemplateEnum.SPARTAN ? 'flex-start' : 'center'}
        marginDefault={template === TemplateEnum.VIVID ? '0 0 80px 0' : '0 0 80px 0'}
        paddingXl={template === TemplateEnum.SPARTAN ? '0' : ''}
        paddingXXl={template === TemplateEnum.SPARTAN ? '0' : ''}>
        <OrderDetailsContainer>
          <Loading height={template === TemplateEnum.SPARTAN ? '400px' : '200px'} />
        </OrderDetailsContainer>
      </SectionLayout>
    );

  return (
    <SectionLayout
      backgroundAsColor={false}
      justifyContent={template === TemplateEnum.SPARTAN ? 'flex-start' : 'center'}
      marginDefault={template === TemplateEnum.VIVID ? '0 0 80px 0' : '0 0 80px 0'}
      paddingXl={template === TemplateEnum.SPARTAN ? '0' : ''}
      paddingXXl={template === TemplateEnum.SPARTAN ? '0' : ''}>
      <OrderDetailsContainer data-test-id="order-details-container">
        {template === TemplateEnum.VIVID && (
          <SectionDescription
            title={intl.formatMessage({
              id: orderDetailsModule.packageLevelDisplay ? 'TRACKING.orderDetails.packageLevel.title' : 'TRACKING.orderDetails.title'
            })}
          />
        )}
        {template === TemplateEnum.SPARTAN && (
          <OrderDetailsTitle data-test-id="order-details-title">
            {intl.formatMessage({ id: 'TRACKING.spartan.orderDetails.title' })}
          </OrderDetailsTitle>
        )}
        <OrderDetailsList data-test-id="order-details-list">
          {template === TemplateEnum.VIVID &&
            orderItemsFirstThree.map((orderItem, index) => <OrderItem item={orderItem} key={`${orderItem.orderItemId}-${index}`} />)}
          {template === TemplateEnum.VIVID && orderItems.length > 3 && (
            <Animate
              show={showMore}
              duration={300}
              style={{ height: 'auto', overflow: 'hidden' }}
              start={{ height: 0 }}
              transitionOnMount
              stayMounted
              data-test-id="vivid-tracking-events-expanded">
              {orderItemsNext.map((orderItem, index) => (
                <OrderItem item={orderItem} key={`${orderItem.orderItemId}-${index}`} />
              ))}
            </Animate>
          )}

          {template === TemplateEnum.SPARTAN &&
            orderItems.map((orderItem, index) => <OrderItem item={orderItem} key={`${orderItem.orderItemId}-${index}`} />)}

          {template === TemplateEnum.VIVID && orderItems.length > 3 && (
            <OrderDetailsButtonWrapper>
              <OrderDetailsButton data-test-id="order-details-button" onClick={() => handleShowMore(showMore)}>
                {intl.formatMessage({ id: seeButton ? 'TRACKING.trackingEvents.header.seeLess' : 'TRACKING.trackingEvents.header.seeAll' })}
              </OrderDetailsButton>
            </OrderDetailsButtonWrapper>
          )}
        </OrderDetailsList>
      </OrderDetailsContainer>
    </SectionLayout>
  );
};

export interface OwnProps {
  vanityName: string;
  urlKey: string | null;
  shippingInfoModule: ShippingInfoModuleType;
  orderDetailsModule: OrderDetailsModuleType;
  isPreview: boolean;
}
