import React, { SetStateAction } from 'react';
import { CustomTableSortLabel } from './SingleParcelColumnHead.styled';
import { useIntl } from 'react-intl';
import { ColumnTypeEnum } from 'types/customer-service/column-type-enum';
import { resolveParcelColumnName } from 'utils/customer-service/resolve-parcel-column-name/resolve-parcel-column-name';
import { SortByParcelType } from 'types/customer-service/sort-by-parcel-type';
import { SortDirection } from '@mui/material';
import { CustomTableCell } from 'components/Shared/styled-components/CustomTableCell/CustomTableCell.styled';

export const SingleParcelColumnHead = (props: OwnProps) => {
  const { columnType, sortBy, setSortBy, sortType, setSortType } = props;

  const intl = useIntl();

  const handleTableSort = (columnType: ColumnTypeEnum): void => {
    const isAsc = sortBy === columnType && sortType === 'asc';
    setSortType(isAsc ? 'desc' : 'asc');
    setSortBy(columnType);
    window.dataLayer.push({
      tableViewSortBy: intl.formatMessage({ id: resolveParcelColumnName(columnType) }),
      event: 'customerServiceSortTable'
    });
  };

  return (
    <CustomTableCell
      fontSizeDefault="11px"
      fontSizeXL="12px"
      paddingDefault="10px 5px 10px"
      widthDefault="25%"
      widthXL="20%"
      align="left"
      shouldOneLineDisplay
      sortDirection={sortBy === columnType ? sortType : undefined}>
      <CustomTableSortLabel
        data-test-id="table-sort-label"
        active={sortBy === columnType}
        direction={sortBy === columnType ? (sortType as 'asc' | 'desc') : 'asc'}
        onClick={() => handleTableSort(columnType)}>
        {intl.formatMessage({ id: resolveParcelColumnName(columnType) })}
      </CustomTableSortLabel>
    </CustomTableCell>
  );
};

export interface OwnProps {
  columnType: ColumnTypeEnum;
  sortBy: SortByParcelType;
  setSortBy: React.Dispatch<SetStateAction<SortByParcelType>>;
  sortType: SortDirection;
  setSortType: React.Dispatch<SetStateAction<SortDirection>>;
}
