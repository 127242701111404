import React from 'react';
import { StyledBurger } from './Burger.styled';

export const Burger: React.FC<OwnProps> = (props) => {
  const { active, onClick } = props;

  return (
    <StyledBurger active={active} onClick={onClick} data-test-id="burger-button" aria-expanded={active}>
      <span />
    </StyledBurger>
  );
};

export interface OwnProps {
  onClick: () => void;
  active: boolean;
}

export default Burger;
