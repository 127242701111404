import { CustomFormControl } from 'components/Shared/styled-components/CustomFormControl/CustomFormControl.styled';
import React, { SetStateAction, useState } from 'react';
import { CustomLabel, SelectPlaceholder } from 'components/Returns/Common/CustomSelectElements/CustomSelectElements.styled';
import {
  DropOffLocationsInputsContainer,
  InputsSeparator,
  DropOffLocationsFormHeading,
  DropOffLocationsFormWrapper
} from './DropOffLocationsForm.styled';
import { useIntl } from 'react-intl';
import { CustomTextField } from 'components/Shared/styled-components/CustomTextField/CustomTextField.styled';
import { Select, MenuItem, FormHelperText } from '@mui/material';
import { states } from 'constants/states';
import { isIE } from 'react-device-detect';
import { useFormContext, Controller, SubmitHandler } from 'react-hook-form';
import { isAddressValid } from 'utils/shared/address-regex/address-regex';
import { isCityValid } from 'utils/shared/city-regex/city-regex';
import { isZipCodeValid } from 'utils/shared/zip-code-regex/zip-code-regex';
import { isStateFieldRequired } from 'utils/returns/return-info/drop-off-locations/is-state-field-required/is-state-field-required';
import { isCityFieldRequired } from 'utils/returns/return-info/drop-off-locations/is-city-field-required/is-city-field-required';
import { isZipCodeFieldRequired } from 'utils/returns/return-info/drop-off-locations/is-zip-code-field-required/is-zip-code-field-required';
import { fieldRegexValidation } from 'utils/returns/return-info/drop-off-locations/field-regex-validation/field-regex-validation';
import { CustomLoadingButton } from 'components/Shared/styled-components/CustomLoadingButton/CustomLoadingButton.styled';
import { DropOffLocationsSearchParams } from 'types/return/return-info/Common/drop-off-locations-response';
import { DropOffLocationsFormValues } from 'types/return/return-info/Common/drop-off-locations-form-values';
import { enhanceZipCodeValue } from 'utils/shared/enhance-zip-code-value/enhance-zip-code-value';

export const DropOffLocationsForm: React.FC<OwnProps> = (props) => {
  const { isDropOffLocationsLoading, setSearchParams } = props;

  const intl = useIntl();
  const {
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { isSubmitted }
  } = useFormContext<DropOffLocationsFormValues>();

  const [isRequiredValidationEnabled, setIsRequiredValidationEnabled] = useState<boolean>(false);

  const onSubmit: SubmitHandler<DropOffLocationsFormValues> = async (data) => {
    const { zipCode, street, city, state } = data;

    setSearchParams({
      zipCode,
      street,
      city,
      state
    });
    setIsRequiredValidationEnabled(false);
  };

  return (
    <DropOffLocationsFormWrapper
      onSubmit={(e) => {
        window.dataLayer.push({
          event: 'drop-off-locations-submit-form'
        });
        setIsRequiredValidationEnabled(true);
        handleSubmit(onSubmit)(e);
      }}
      noValidate>
      <DropOffLocationsFormHeading>
        {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocations.heading.formVisible' })}
      </DropOffLocationsFormHeading>
      <DropOffLocationsInputsContainer>
        <Controller
          name="street"
          control={control}
          defaultValue=""
          rules={{
            validate: {
              streetRegex: (value) =>
                fieldRegexValidation(value, isAddressValid, 'RETURN.returnInfo.common.dropOffLocations.inputs.street.validation.regex')
            }
          }}
          render={({ field: { name, onBlur, onChange, value, ref }, fieldState: { error } }) => (
            <CustomFormControl marginDefault="0" marginXl="0 15px 0 0" width="100%" widthXl="35%">
              <CustomLabel htmlFor="drop-off-locations-street">
                {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocations.inputs.street.label' })}
              </CustomLabel>
              <CustomTextField
                id="drop-off-locations-street"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                error={!!error}
                helperText={!!error ? intl.formatMessage({ id: error.message }) : null}
                variant="outlined"
                size="small"
                // @ts-ignore
                FormHelperTextProps={{ 'data-test-id': 'drop-off-location-street-input-error' }}
              />
            </CustomFormControl>
          )}
        />
        <Controller
          name="city"
          control={control}
          defaultValue=""
          rules={{
            validate: {
              cityRequired: (value) => isCityFieldRequired(value, watch('zipCode'), isRequiredValidationEnabled),
              cityRegex: (value) => fieldRegexValidation(value, isCityValid, 'RETURN.returnInfo.common.dropOffLocations.inputs.city.validation.regex')
            }
          }}
          render={({ field: { name, onBlur, onChange, value, ref }, fieldState: { error } }) => (
            <CustomFormControl marginDefault="20px 0 0 0" marginXl="0 15px 0 0" width="100%" widthL="48%" widthXl="20%">
              <CustomLabel htmlFor="drop-off-locations-city">
                {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocations.inputs.city.label' })}
              </CustomLabel>
              <CustomTextField
                id="drop-off-locations-city"
                name={name}
                value={value}
                onChange={(event) => {
                  onChange(event);
                  if (isSubmitted) trigger(['state', 'zipCode']);
                }}
                onBlur={onBlur}
                ref={ref}
                error={!!error}
                helperText={!!error ? intl.formatMessage({ id: error.message }) : null}
                variant="outlined"
                size="small"
                // @ts-ignore
                FormHelperTextProps={{ 'data-test-id': 'drop-off-location-city-input-error' }}
              />
            </CustomFormControl>
          )}
        />
        <Controller
          name="state"
          control={control}
          defaultValue=""
          rules={{
            validate: {
              stateRequired: (value) => isStateFieldRequired(value, watch('zipCode'), isRequiredValidationEnabled)
            }
          }}
          render={({ field: { name, onBlur, onChange, value, ref }, fieldState: { error } }) => (
            <CustomFormControl
              size="small"
              marginDefault="20px 0 0 0"
              marginXl="0 15px 0 0"
              width="100%"
              widthL="48%"
              widthXl="20%"
              data-test-id="drop-off-location-state-form-control">
              {isIE ? (
                <CustomLabel htmlFor="drop-off-locations-state">
                  {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocations.inputs.state.label' })}
                </CustomLabel>
              ) : (
                <CustomLabel id="drop-off-locations-state">
                  {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocations.inputs.state.label' })}
                </CustomLabel>
              )}
              {isIE ? (
                <Select id="drop-off-locations-state" name={name} value={value} onChange={onChange} onBlur={onBlur} native displayEmpty>
                  <option value="">{intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocations.inputs.state.placeholder' })}</option>
                  {states.map((state) => (
                    <option key={state.name} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </Select>
              ) : (
                <Select
                  labelId="drop-off-locations-state"
                  name={name}
                  value={value}
                  onChange={(event) => {
                    onChange(event);
                    if (isSubmitted) trigger(['city', 'zipCode']);
                  }}
                  onBlur={onBlur}
                  ref={ref}
                  error={!!error}
                  displayEmpty>
                  <MenuItem value="">
                    <SelectPlaceholder>
                      {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocations.inputs.state.placeholder' })}
                    </SelectPlaceholder>
                  </MenuItem>
                  {states.map((state) => (
                    <MenuItem key={state.name} value={state.abbreviation}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {!!error && (
                <FormHelperText data-test-id="drop-off-location-state-input-error">{intl.formatMessage({ id: error.message })}</FormHelperText>
              )}
            </CustomFormControl>
          )}
        />

        <InputsSeparator>or</InputsSeparator>
        <Controller
          name="zipCode"
          control={control}
          defaultValue=""
          rules={{
            validate: {
              zipCodeRequired: (value) => isZipCodeFieldRequired(value, watch('state'), watch('city'), isRequiredValidationEnabled),
              zipCodeRegex: (value) =>
                fieldRegexValidation(value, isZipCodeValid, 'RETURN.returnInfo.common.dropOffLocations.inputs.zipCode.validation.regex')
            }
          }}
          render={({ field: { name, onBlur, onChange, value, ref }, fieldState: { error } }) => (
            <CustomFormControl marginDefault="0" marginXl="0 0 0 15px" width="100%" widthXl="20%">
              <CustomLabel htmlFor="drop-off-locations-zip-code">
                {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocations.inputs.zipCode.label' })}
              </CustomLabel>
              <CustomTextField
                id="drop-off-locations-zip-code"
                name={name}
                value={value}
                onChange={(event) => {
                  event.target.value = enhanceZipCodeValue(event.target.value);
                  onChange(event);
                  if (isSubmitted) trigger(['state', 'city']);
                }}
                onBlur={onBlur}
                ref={ref}
                error={!!error}
                helperText={!!error ? intl.formatMessage({ id: error.message }) : null}
                variant="outlined"
                size="small"
                // @ts-ignore
                FormHelperTextProps={{ 'data-test-id': 'drop-off-location-zip-code-input-error' }}
              />
            </CustomFormControl>
          )}
        />
        <CustomLoadingButton
          type="submit"
          loading={isDropOffLocationsLoading}
          maxHeight="40px"
          marginDefault="20px 0 0 0"
          marginXl="21px 0 0 15px"
          width="100%"
          widthXl="auto"
          borderRadius="5px"
          transitionDuration="0s"
          data-gtm-id="drop-off-locations-find-button">
          {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocations.submitButton' })}
        </CustomLoadingButton>
      </DropOffLocationsInputsContainer>
    </DropOffLocationsFormWrapper>
  );
};

export interface OwnProps {
  isDropOffLocationsLoading: boolean;
  setSearchParams: React.Dispatch<SetStateAction<DropOffLocationsSearchParams>>;
}
