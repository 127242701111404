import { SelectedProducts } from 'context/returns/ProductsSelectionContext';
import { SelectedProduct } from 'models/returns/selected-product/SelectedProduct';
import { isItemChecked } from 'utils/returns/overview/common/is-item-checked/is-item-checked';
import { isItemOptionSelected } from 'utils/returns/overview/common/is-item-option-selected/is-item-option-selected';

export const calculateUiSelectionItems = (selectedProducts: SelectedProducts): number => {
  let amountOfSelectionItems: number = 0;
  Object.values(selectedProducts).forEach((selectedProduct: SelectedProduct) => {
    if (isItemChecked(selectedProduct) && isItemOptionSelected(selectedProduct)) amountOfSelectionItems++;
  });
  return amountOfSelectionItems;
};
