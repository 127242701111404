import queryString from 'query-string';

export const setSendEmailWithLabelUrl = (
  vanityName: string,
  labelId: string,
  inputOrderId: string,
  shippingPostalCode: string | null,
  urlKey: string | null
): string =>
  queryString.stringifyUrl(
    {
      url: `/return/${vanityName}/${labelId}/email`,
      query: {
        inputOrderId,
        shippingPostalCode,
        key: urlKey
      }
    },
    { skipNull: true, skipEmptyString: true, sort: false }
  );
