import React, { useEffect, useState } from 'react';
import { HeaderContainer, HeaderContent, HeaderLayout, LogoContainer, HeaderBumpContent } from './Header.styled';
import { Burger } from './Burger/Burger';
import { Navigations } from './Navigations/Navigations';
import { Logo } from './Logo/Logo';
import { useMedia } from 'use-media';
import { HeaderModuleType } from 'types/shared/config/headerModule/header-module-type';

const Header: React.FC<OwnProps> = (props) => {
  const {
    headerModule: { showLogoAsText, logoUrl, logoAligned, logo, logoText, navigations },
    topNavExists
  } = props;
  const laptopWidth = 768;
  const desktop = useMedia({ minWidth: `${laptopWidth}px` });
  const [height, setHeight] = useState<number>(66);
  const [mobileNavActive, setMobileNavActive] = useState<boolean>(false);

  useEffect(() => {
    if (desktop && logoAligned === 'center') {
      if (navigations.length === 0) return setHeight(66);
      return setHeight(100);
    }
    return setHeight(66);
  }, [desktop, logoAligned, navigations.length]);

  if (!topNavExists)
    return (
      <HeaderLayout data-test-id="header">
        <HeaderContainer data-test-id="header-container" isLogoCenter={logoAligned === 'center'}>
          <HeaderContent data-test-id="header-content" height="66px" laptopView={desktop} isLogoCenter={logoAligned === 'center'} isNavExists={false}>
            <LogoContainer isLogoCenter={logoAligned === 'center'} data-logo-align={logoAligned || 'left'}>
              <Logo
                logoAligned={logoAligned || 'left'}
                logoAsText={showLogoAsText}
                logo={logo}
                logoUrl={logoUrl}
                logoText={logoText}
                isNavExists={navigations.length > 0}
              />
            </LogoContainer>
          </HeaderContent>
        </HeaderContainer>
        <HeaderBumpContent data-test-id="header-bump-content" marginTop={`${height}px`} />
      </HeaderLayout>
    );

  return (
    <HeaderLayout data-test-id="header">
      <HeaderContainer data-test-id="header-container" isLogoCenter={logoAligned === 'center'}>
        <HeaderContent
          data-test-id="header-content"
          height={`${height}px`}
          laptopView={desktop}
          isLogoCenter={logoAligned === 'center'}
          isNavExists={navigations.length > 0}>
          {!desktop && navigations.length > 0 && <Burger active={mobileNavActive} onClick={() => setMobileNavActive(!mobileNavActive)} />}
          <LogoContainer isLogoCenter={logoAligned === 'center'} data-logo-align={logoAligned || 'left'}>
            <Logo
              logoAligned={logoAligned || 'left'}
              logoAsText={showLogoAsText}
              logo={logo}
              logoUrl={logoUrl}
              logoText={logoText}
              isNavExists={navigations.length > 0}
            />
          </LogoContainer>
          {navigations.length > 0 && (
            <Navigations activeMobileNav={mobileNavActive} laptopView={desktop} navigations={navigations} logoAligned={logoAligned} />
          )}
        </HeaderContent>
      </HeaderContainer>
      <HeaderBumpContent data-test-id="header-bump-content" marginTop={`${height}px`} />
    </HeaderLayout>
  );
};

export interface OwnProps {
  headerModule: HeaderModuleType;
  topNavExists: boolean;
}

export default Header;
