import { useMediaQuery, useTheme } from '@mui/material';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { buildVerifyJWTApi } from 'utils/customer-service/build-verify-jwt-api/build-verify-jwt-api';
import { Container } from './AuthenticatedReturns.styled';
import { AuthenticatedReturnsForm } from './AuthenticatedReturnsForm/AuthenticatedReturnsForm';
import { useLocation, useSearchParams } from 'react-router-dom';
import { VerifyTokenErrorResponse, VerifyTokenPayloadBody, VerifyTokenSuccessResponse } from 'types/customer-service/verify-token-api-response-type';
import { initGoogleTagManagerDataLayer } from 'utils/common/google-tag-manager/google-tag-manager';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { AuthenticatedReturnsExpired } from './AuthenticatedReturnsExpired/AuthenticatedReturnsExpired';
import { CustomOrderNumberField } from 'types/shared/config/returnsModule/returns-module-type';
import { AuthenticatedReturnsFormSkeletons } from './AuthenticatedReturnsFormSkeletons/AuthenticatedReturnsFormSkeletons';
import { AuthUserContext } from 'context/customer-service/AuthUserContext';

export const AuthenticatedReturns: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { searchByOrderNumberEnabled, customOrderNumberField, areReturnsEnabled, isIntegrated } = props;
  const VERIFY_JWT_API: string = buildVerifyJWTApi();
  const location = useLocation();
  const JWT: string | null = !!location.hash ? location.hash.replace('#', '') : null;
  const ONE_HOUR_IN_MILISECONDS: number = 3600000;
  const [isUserAuth, setIsUserAuth] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { vanityName, template, brandId, merchantId } = useContext(ClientConfigContext);
  const { setIsUserAuth: setIsUserAuthContext } = useContext(AuthUserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const authParam: string | null = searchParams.get('a');
  const numberParam: string | null = searchParams.get('number');
  const theme = useTheme();
  const xLargeUp = useMediaQuery(theme.breakpoints.up('xlarge'));

  const isTokenValid = async (jwt: string): Promise<boolean> => {
    const payload: VerifyTokenPayloadBody = {
      token: jwt
    };

    const headers = {
      'X-BRAND-ID': brandId,
      'content-type': 'application/json',
      Accept: 'application/json',
      'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty'
    };

    const init: RequestInit = {
      method: 'POST',
      // @ts-ignore
      headers,
      body: JSON.stringify(payload)
    };

    try {
      setIsLoading(true);
      const response = await fetch(VERIFY_JWT_API, init);
      const data: VerifyTokenSuccessResponse | VerifyTokenErrorResponse = await response.json();

      // @ts-ignore
      if (data.errorTitle) return false;
      return true;
    } catch {
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const checkIsExpired = (expiresAtTimeInMiliseconds: number) => {
    return Date.now() > expiresAtTimeInMiliseconds;
  };

  useEffect(() => {
    window.dataLayer.push({ event: 'runConfirmItScript' });
    // SOLUTION 2
    const localStorageKey: string = 'expiresAt';
    if (JWT) {
      (async () => {
        const isTokenVerified: boolean = await isTokenValid(JWT);

        if (isTokenVerified) {
          if (!!localStorage.getItem(localStorageKey)) localStorage.removeItem(localStorageKey);
          localStorage.setItem(localStorageKey, JSON.stringify({ expiresAt: Date.now() + ONE_HOUR_IN_MILISECONDS }));

          if (!!numberParam) {
            setSearchParams({ a: '1', number: numberParam });
          } else {
            setSearchParams({ a: '1' });
          }
          setIsUserAuthContext(true);
          setIsUserAuth(true);

          initGoogleTagManagerDataLayer(
            null,
            null,
            merchantId,
            vanityName,
            null,
            'Customer Service',
            'trackPageview',
            'Non-integrated',
            template,
            true,
            null,
            brandId
          );
          return setIsLoading(false);
        }
        if (!!localStorage.getItem(localStorageKey)) localStorage.removeItem(localStorageKey);
        if (!!numberParam) {
          setSearchParams({ a: '1', number: numberParam });
        } else {
          setSearchParams({ a: '1' });
        }
        setIsUserAuthContext(false);
        setIsUserAuth(false);
        initGoogleTagManagerDataLayer(
          null,
          null,
          merchantId,
          vanityName,
          null,
          'Customer Service',
          'trackPageview',
          'Non-integrated',
          template,
          false,
          null,
          brandId
        );
        return setIsLoading(false);
      })();
    } else {
      const expiresObject: string | null = localStorage.getItem(localStorageKey);
      if (!!expiresObject) {
        const expiredAtValue: number = JSON.parse(expiresObject).expiresAt;

        if (!checkIsExpired(expiredAtValue)) {
          if (!!numberParam) {
            setSearchParams({ a: '1', number: numberParam });
          } else {
            setSearchParams({ a: '1' });
          }
          setIsUserAuthContext(true);
          setIsUserAuth(true);
          initGoogleTagManagerDataLayer(
            null,
            null,
            merchantId,
            vanityName,
            null,
            'Customer Service',
            'trackPageview',
            'Non-integrated',
            template,
            true,
            null,
            brandId
          );
          return setIsLoading(false);
        } else {
          if (!!numberParam) {
            setSearchParams({ a: '1', number: numberParam });
          } else {
            setSearchParams({ a: '1' });
          }
          setIsUserAuthContext(false);
          setIsUserAuth(false);
          initGoogleTagManagerDataLayer(
            null,
            null,
            merchantId,
            vanityName,
            null,
            'Customer Service',
            'trackPageview',
            'Non-integrated',
            template,
            false,
            null,
            brandId
          );
          return setIsLoading(false);
        }
      } else {
        setIsUserAuthContext(false);
        setIsUserAuth(false);
        initGoogleTagManagerDataLayer(
          null,
          null,
          merchantId,
          vanityName,
          null,
          'Customer Service',
          'trackPageview',
          'Non-integrated',
          template,
          false,
          null,
          brandId
        );
        return setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !xLargeUp ||
    !customOrderNumberField ||
    !areReturnsEnabled ||
    isIntegrated === null ||
    (!isLoading && !isUserAuth && !localStorage.getItem('expiresAt') && !authParam && !JWT)
  )
    return null;
  return (
    <Container searchByOrderNumberEnabled={searchByOrderNumberEnabled} data-test-id="authenticated-returns">
      {isLoading ? (
        <AuthenticatedReturnsFormSkeletons />
      ) : isUserAuth ? (
        <AuthenticatedReturnsForm
          isIntegrated={isIntegrated}
          checkIsExpired={checkIsExpired}
          setIsUserAuth={setIsUserAuth}
          customOrderNumberField={customOrderNumberField}
        />
      ) : (
        <AuthenticatedReturnsExpired />
      )}
    </Container>
  );
};

export interface OwnProps {
  searchByOrderNumberEnabled: boolean;
  customOrderNumberField: CustomOrderNumberField | null;
  areReturnsEnabled: boolean;
  isIntegrated: boolean | null;
}
