import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Container, PickUpBox, PickUpInformation, PickUpTitle, CancelPickupLink } from './LabelPickupSchedulerContent.styled';
import { useIntl } from 'react-intl';
import { PickupContext, USPSFormAlertErrorTitle } from 'context/returns/PickupContext';
import { setPickupValidateApi } from 'utils/returns/returnInfo/label/set-pickup-validate-api/set-pickup-validate-api';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import { Dialog, useMediaQuery } from '@mui/material';
import { SchedulePickupSteps } from '../SchedulePickupSteps/SchedulePickupSteps';
import { Loading } from 'components/Shared/Loading/Loading';
import { CustomLoadingButton } from 'components/Shared/styled-components/CustomLoadingButton/CustomLoadingButton.styled';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { isDemoLink } from 'utils/returns/returnInfo/label/is-demo-link/is-demo-link';
import { useTheme } from '@mui/material/styles';

export const LabelPickupSchedulerContent: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { labelId, inputOrderId } = props;
  const { brandId } = useContext(ClientConfigContext);
  const {
    confirmationNumber,
    addressOne,
    addressTwo,
    city,
    selectedState,
    zipCode,
    setSchedulePickupFormError,
    setCheckAvailabilityFormError,
    setShowPickupFromAddressForm,
    setEstimatedWeight,
    setEstimatedWeightError,
    setPackageLocation,
    setPackageLocationError,
    setAdditionalInstructions,
    setAdditionalInstructionsError,
    isPickupExist,
    confirmedPickupDate,
    setEstimatedPickupDate,
    setIsPickupExist,
    setValuesFromInitialPickupFromAddressForForm,
    setValueFromInitialPhoneNumber,
    pickupCancelLink
  } = useContext(PickupContext);

  const PICKUP_VALIDATE_API = setPickupValidateApi(inputOrderId, {
    address1: addressOne,
    address2: addressTwo,
    city: city,
    postalCode: zipCode,
    stateOrProvince: selectedState
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [animationOn, setAnimationOn] = useState<boolean>(true);

  const intl = useIntl();
  const theme = useTheme();
  const mediumUp = useMediaQuery(theme.breakpoints.up('medium'));
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));

  useEffect(() => {
    let timeout;
    if (isPickupExist && confirmedPickupDate) {
      timeout = setTimeout(() => {
        setAnimationOn(false);
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isPickupExist, confirmedPickupDate]);

  const handleModalClose = () => {
    setShowPickupFromAddressForm(false);
    setEstimatedPickupDate(null);

    setCheckAvailabilityFormError(null);
    setValuesFromInitialPickupFromAddressForForm();

    setEstimatedWeight('');
    setEstimatedWeightError(null);
    setPackageLocation('');
    setPackageLocationError(null);
    setAdditionalInstructions('');
    setAdditionalInstructionsError(null);

    setSchedulePickupFormError(null);

    setIsModalOpen(false);

    setValueFromInitialPhoneNumber();

    if (confirmedPickupDate) {
      setIsPickupExist(true);
    }
  };

  const handlePickupValidate = async () => {
    const fetchHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    };

    const params = enhanceFetchParams({
      method: 'GET',
      headers: enhanceHeadersParams(brandId, fetchHeaders)
    });

    try {
      setIsLoading(true);
      const response = await fetch(PICKUP_VALIDATE_API, params);
      const data = await response.json();

      if (data.errorTitle) {
        setCheckAvailabilityFormError(data.errorTitle);
        setShowPickupFromAddressForm(true);
        return setIsModalOpen(true);
      }

      setEstimatedPickupDate(data.estimatePickupDate);
      setShowPickupFromAddressForm(false);
      return setIsModalOpen(true);
    } catch (e) {
      setCheckAvailabilityFormError(intl.formatMessage({ id: 'RETURN.returnInfo.labelInfo.pickUp.address.serverError' }));
      setCheckAvailabilityFormError(USPSFormAlertErrorTitle.CHECK_AVAILABILITY);
      setShowPickupFromAddressForm(true);
      return setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isPickupExist && confirmedPickupDate && pickupCancelLink && confirmationNumber)
    return (
      <Container data-test-id="confirmed-pickup-date-container">
        {animationOn ? (
          <Loading height="72px" />
        ) : (
          <PickUpInformation data-test-id="pickup-information">
            {intl.formatMessage(
              { id: 'RETURN.returnInfo.label.labelInfo.pickUp.alreadyCreated' },
              {
                date: <b>{confirmedPickupDate}</b>,
                confirmationNumber: <b>{confirmationNumber}</b>,
                pickupCancelLink: (
                  <CancelPickupLink
                    href={pickupCancelLink}
                    target="_blank"
                    rel="noreferrer"
                    data-test-id="pickup-information-cancel-pickup-link"
                    data-gtm-id="pickup-information-cancel-pickup-link">
                    cancel pickup
                  </CancelPickupLink>
                )
              }
            )}
          </PickUpInformation>
        )}
      </Container>
    );

  return (
    <PickUpBox data-test-id="return-return-info-pickup-box">
      <PickUpTitle data-test-id="return-return-info-pickup-title">
        {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.paragraph' })}
      </PickUpTitle>
      <CustomLoadingButton
        data-test-id="return-return-info-pickup-button"
        data-gtm-id={!isDemoLink(inputOrderId) ? 'schedule-usps-pickup' : undefined}
        isUppercase
        onClick={handlePickupValidate}
        width="100%"
        size={largeUp ? 'large' : mediumUp ? 'medium' : 'large'}
        type="submit"
        loading={isLoading}
        variant="contained">
        {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.button' })}
      </CustomLoadingButton>
      <Dialog open={isModalOpen} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <SchedulePickupSteps handleModalClose={handleModalClose} labelId={labelId} inputOrderId={inputOrderId} />
      </Dialog>
    </PickUpBox>
  );
};

export interface OwnProps {
  labelId: string;
  inputOrderId: string;
}
