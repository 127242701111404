import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const PromotionalContainer = styled.div<{ singlePromotional?: boolean }>`
  width: 100%;
  max-width: 600px;
  cursor: ${({ singlePromotional }) => (singlePromotional ? 'pointer' : 'auto')};

  @media ${({ theme }) => theme.largeUp} {
    display: flex;
  }

  ${({ theme, singlePromotional }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      border: ${theme.primaryBackgroundDark ? 'none' : '1px solid #e1e1e1'};
      @media ${theme.largeUp} {
        border: ${!theme.primaryBackgroundDark && singlePromotional ? '1px solid #e1e1e1' : 'none'};
      }
      max-width: 640px;
    `}
`;

export const PromotionalItem = styled.div<{
  backgroundColor?: string;
  backgroundImage?: string;
  widthL?: string;
  singlePromotional?: boolean;
}>`
  padding: 20px;
  background: ${getCustomStyle('styleModule.primaryColor', '#ec9b23')};
  width: 100%;

  ${({ theme, singlePromotional }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      @media ${theme.largeUp} {
        border-left: ${!theme.primaryBackgroundDark && singlePromotional ? '1px solid #e1e1e1' : 'none'};
        border: ${!theme.primaryBackgroundDark && !singlePromotional && '1px solid #e1e1e1'};
      }
    `}
  
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-position: center;
    `}

  @media ${({ theme }) => theme.largeUp} {
    ${({ widthL }) =>
      widthL &&
      css`
        width: ${widthL};
      `}
  }
`;

export const PromotionalItemImageContainer = styled.div<{ widthL?: string }>`
  width: 100%;
  background: ${getCustomStyle('styleModule.primaryColor', '#ec9b23')};

  @media ${({ theme }) => theme.largeUp} {
    ${({ widthL }) =>
      widthL &&
      css`
        width: ${widthL};
      `}
  }
`;

export const PromotionalTextWrapper = styled.div``;

export const PromotionalSlider = styled(Slider)`
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    padding-right: 10px;
  }

  @media ${({ theme }) => theme.largeUp} {
    width: 65%;
  }
  .slick-arrow {
    display: none;
  }

  .slick-arrow:before {
    display: none;
  }

  .slick-next:before {
    transform: rotate(-180deg);
    margin-left: 10px;
    display: none;
  }
`;
