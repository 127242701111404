import { OptionType } from 'models/returns/selected-product/SelectedProduct';

export const resolveProductOptionDescription = (option: OptionType): string => {
  switch (option) {
    case null:
      return 'RETURN.overview.productsSelection.productsSelectionForm.product.options.description.option.null';
    case 'return':
      return 'RETURN.overview.productsSelection.productsSelectionForm.product.options.description.option.return';
    case 'exchange':
      return 'RETURN.overview.productsSelection.productsSelectionForm.product.options.description.option.exchange';
  }
};
