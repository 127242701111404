import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const ReturnsHeadingContainer = styled.div`
  margin: 0 0 60px 0;
`;

export const ReturnsHeadingTitle = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  margin: 0 0 40px 0;
`;

export const ReturnsHeadingSubtitle = styled.p`
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.bodyFontColor};
  margin: 0;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;
