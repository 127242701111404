import styled from '@emotion/styled';
import { css, FunctionInterpolation } from '@emotion/react';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';
import { get } from 'lodash';
import { MainTheme } from 'types/modules';

export const getCustomRoundStyle = (): FunctionInterpolation<{ theme: MainTheme }> => {
  return ({ theme }) => {
    return get(theme.customStyles, 'styleModule.buttonStyleRounded')
      ? '5px'
      : get(theme.customStyles, 'styleModule.buttonStylePill')
      ? '40px'
      : '0px';
  };
};

const StyledButton = styled.button<{
  width: string;
  widthMd?: string;
  variant?: 'text' | 'contained';
  height?: string;
  isLoading?: boolean;
  disabled?: boolean;
  isBoxShadow?: boolean;
  paddingDefault?: string;
  paddingMd?: string;
  paddingL?: string;
  paddingXl?: string;
  paddingXXl?: string;
  marginDefault?: string;
  marginMd?: string;
  marginL?: string;
  marginXl?: string;
  marginXXl?: string;
  borderRadius?: string;
  fontSize?: string;
  fontWeight?: string;
  isUppercase?: boolean;
}>`
  display: inline-block;
  height: ${({ height }) => (height ? height : 'auto')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  border: 0;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  cursor: pointer;
  text-align: center;
  box-shadow: ${({ isBoxShadow }) => (isBoxShadow ? '0 2px 0 0 rgba(0, 0, 0, 0.6)' : 'none')};
  text-decoration: none;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : getCustomRoundStyle())};
  outline: none;
  vertical-align: middle;
  text-transform: ${({ isUppercase }) => (isUppercase ? 'uppercase' : 'inherit')};

  width: ${({ width }) => width};
  margin: ${({ marginDefault }) => (marginDefault ? marginDefault : 0)};
  padding: ${({ paddingDefault }) => (paddingDefault ? paddingDefault : 0)};

  @media ${({ theme }) => theme.mediumUp} {
    ${({ widthMd }) =>
      widthMd &&
      css`
        width: ${widthMd};
      `}
    ${({ marginMd }) =>
      marginMd &&
      css`
        margin: ${marginMd};
      `}
    ${({ paddingMd }) =>
      paddingMd &&
      css`
        padding: ${paddingMd};
      `}
  }

  @media ${({ theme }) => theme.largeUp} {
    ${({ marginL }) =>
      marginL &&
      css`
        margin: ${marginL};
      `}
    ${({ paddingL }) =>
      paddingL &&
      css`
        padding: ${paddingL};
      `}
  }

  @media ${({ theme }) => theme.xlargeUp} {
    ${({ marginXl }) =>
      marginXl &&
      css`
        margin: ${marginXl};
      `}
    ${({ paddingXl }) =>
      paddingXl &&
      css`
        padding: ${paddingXl};
      `}
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    ${({ marginXXl }) =>
      marginXXl &&
      css`
        margin: ${marginXXl};
      `}
    ${({ paddingXXl }) =>
      paddingXXl &&
      css`
        padding: ${paddingXXl};
      `}
  }

  background-color: ${({ theme, variant }) =>
    variant && variant === 'text' ? 'transparent' : getCustomStyle('styleModule.secondaryColor', theme.buttonBackground)};
  color: ${({ theme, variant }) =>
    variant && variant === 'text'
      ? getCustomStyle('styleModule.secondaryColor', theme.buttonBackground)
      : getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark')
      ? theme.buttonColor
      : theme.buttonColorDark};

  &:hover {
    opacity: 0.9;
    color: ${({ theme, variant }) =>
      variant && variant === 'text'
        ? getCustomStyle('styleModule.secondaryColor', theme.buttonBackground)
        : getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark')
        ? theme.buttonColor
        : theme.buttonColor};
  }

  ${({ disabled, isLoading }) =>
    (disabled || isLoading) &&
    css`
      opacity: 0.6;
      pointer-events: none;
      cursor: default;
    `}
`;

const StyledButtonAsLink = styled.a<{
  height?: string;
  isLoading?: boolean;
  disabled?: boolean;
  isBoxShadow?: boolean;
  width?: string;
  maxWidth?: string;
  margin?: string;
  padding?: string;
  borderRadius?: string;
  fontSize?: string;
  isUppercase?: boolean;
}>`
  display: inline-block;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : 'auto')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'inherit')};
  padding: ${({ padding }) => (padding ? padding : 0)};
  margin: ${({ margin }) => (margin ? margin : 0)};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  border: 0;
  cursor: pointer;
  text-align: center;
  box-shadow: ${({ isBoxShadow }) => (isBoxShadow ? '0 2px 0 0 rgba(0, 0, 0, 0.6)' : 'none')};
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : getCustomRoundStyle())};
  outline: none;
  vertical-align: middle;
  text-transform: ${({ isUppercase }) => (isUppercase ? 'uppercase' : 'inherit')};
  background-color: ${({ theme }) => getCustomStyle('styleModule.secondaryColor', theme.buttonBackground)};
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? theme.buttonColor : theme.buttonColorDark)};

  &:hover {
    opacity: 0.9;
    color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? theme.buttonColor : theme.buttonColor)};
  }

  ${({ disabled, isLoading }) =>
    (disabled || isLoading) &&
    css`
      opacity: 0.6;
      pointer-events: none;
      cursor: default;
    `}
`;

const StyledButtonLink = styled.a<{
  fontSize?: string;
  margin?: string;
  isUppercase?: boolean;
  disabled?: boolean;
  width?: string;
  widthMd?: string;
  paddingDefault?: string;
  paddingMd?: string;
  paddingL?: string;
  paddingXl?: string;
  paddingXXl?: string;
  marginDefault?: string;
  marginMd?: string;
  marginL?: string;
  marginXl?: string;
  marginXXl?: string;
  display?: 'block' | 'inline-block' | 'inline';
  textAlign?: 'left' | 'center' | 'right';
}>`
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  display: ${({ display }) => (display ? display : 'inline')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  text-transform: ${({ isUppercase }) => (isUppercase ? 'uppercase' : 'inherit')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  color: ${getCustomStyle('styleModule.secondaryColor')};
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};

  width: ${({ width }) => (width ? width : 'auto')};
  margin: ${({ marginDefault }) => (marginDefault ? marginDefault : 0)};
  padding: ${({ paddingDefault }) => (paddingDefault ? paddingDefault : 0)};

  @media ${({ theme }) => theme.mediumUp} {
    ${({ widthMd }) =>
      widthMd &&
      css`
        width: ${widthMd};
      `}
    ${({ marginMd }) =>
      marginMd &&
      css`
        margin: ${marginMd};
      `}
    ${({ paddingMd }) =>
      paddingMd &&
      css`
        padding: ${paddingMd};
      `}
  }

  @media ${({ theme }) => theme.largeUp} {
    ${({ marginL }) =>
      marginL &&
      css`
        margin: ${marginL};
      `}
    ${({ paddingL }) =>
      paddingL &&
      css`
        padding: ${paddingL};
      `}
  }

  @media ${({ theme }) => theme.xlargeUp} {
    ${({ marginXl }) =>
      marginXl &&
      css`
        margin: ${marginXl};
      `}
    ${({ paddingXl }) =>
      paddingXl &&
      css`
        padding: ${paddingXl};
      `}
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    ${({ marginXXl }) =>
      marginXXl &&
      css`
        margin: ${marginXXl};
      `}
    ${({ paddingXXl }) =>
      paddingXXl &&
      css`
        padding: ${paddingXXl};
      `}
  }
`;

const ButtonText = styled.span``;

export { StyledButton, StyledButtonAsLink, StyledButtonLink, ButtonText };
