import { NonReturnableReasonCode } from 'types/return/overview/products-selection/non-returnabled-code-enum';

export const resolveProductStatusInfo = (nonReturnableReasonCode: NonReturnableReasonCode, areExchangesEnabled: boolean): string => {
  switch (nonReturnableReasonCode) {
    case NonReturnableReasonCode.RESTRICTED_ADDRESS:
      return 'UTILS.returns.overview.productsSelection.productsSelectionForm.resolveProductStatusInfo.nonReturnableReasonCode.RESTRICTED_ADDRESS';
    case NonReturnableReasonCode.ITEM_ALREADY_RETURNED:
      return 'UTILS.returns.overview.productsSelection.productsSelectionForm.resolveProductStatusInfo.nonReturnableReasonCode.ITEM_ALREADY_RETURNED';
    case NonReturnableReasonCode.RETURN_PERIOD_EXPIRES:
      if (areExchangesEnabled)
        return 'UTILS.returns.overview.productsSelection.productsSelectionForm.resolveProductStatusInfo.nonReturnableReasonCode.RETURN_PERIOD_EXPIRES.exchanges.enabled';
      return 'UTILS.returns.overview.productsSelection.productsSelectionForm.resolveProductStatusInfo.nonReturnableReasonCode.RETURN_PERIOD_EXPIRES.exchanges.disabled';
    case NonReturnableReasonCode.NON_RETURNABLE:
      if (areExchangesEnabled)
        return 'UTILS.returns.overview.productsSelection.productsSelectionForm.resolveProductStatusInfo.nonReturnableReasonCode.NON_RETURNABLE.exchanges.enabled';
      return 'UTILS.returns.overview.productsSelection.productsSelectionForm.resolveProductStatusInfo.nonReturnableReasonCode.NON_RETURNABLE.exchanges.disabled';
    case NonReturnableReasonCode.RESTRICTED_ADDRESS_NO_REASON:
      return 'UTILS.returns.overview.productsSelection.productsSelectionForm.resolveProductStatusInfo.nonReturnableReasonCode.NON_RETURNABLE.noinfo';
  }
};
