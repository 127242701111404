import styled from '@emotion/styled';

export const HoursListItemContainer = styled.li`
  margin-bottom: 2px;
  font-size: 14px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
