import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FormInput, InputContainer } from './EmailForm.styled';
import {
  Form,
  FormAdditionalButton,
  FormAdditionalButtonBreak,
  FormAdditionalButtonsContainer,
  FormButtonContainer,
  FormFieldset,
  FormLabel,
  FormLabelLink,
  FormResetButton,
  FormStatusMessage,
  FormSubmitButton,
  InputErrorMessage
} from '../common/Form.styled';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { CircularProgress } from 'components/Shared/styled-components/CircularProgress/CircularProgress.styled';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import { DeliveryType } from 'types/delivery-type';
import { Email } from 'types/tracking/common/notifications/notifications-type';
import { useIntl } from 'react-intl';
import { useMedia } from 'use-media';
import { TemplateEnum } from 'types/shared/template-type';
import { ClientConfigContext } from '../../../../context/shared/ClientConfigContext';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBIcomoonIconsEnum, PBMiniIconsEnum } from 'constants/PBIconsEnums';

export const EmailForm: React.FC<OwnProps> = (props) => {
  const { isDark, trackingNumber, urlKey, deliveryType, setShouldDisplayEmailForm, emails, setEmails } = props;

  const [disableNewEmailInput, setDisableNewEmailInput] = useState<boolean>(false);
  const [shouldDisplaySubmitButton, setShouldDisplaySubmitButton] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [sendingFormError, setSendingFormError] = useState<boolean>(false);
  const [isFormSent, setIsFormSent] = useState<boolean>(false);
  const [fieldTouched, setFieldTouched] = useState<boolean>(false);
  const [isDeleteEmailSubscriptionRequestSent, setIsDeleteEmailSubscriptionRequestSent] = useState<boolean>(false);
  const [deleteEmailSubscriptionError, setDeleteEmailSubscriptionError] = useState<boolean>(false);

  const handleSetFieldTouchedOnFalse = () => {
    setFieldTouched(false);
  };

  const intl = useIntl();
  const mediumUp = useMedia({ minWidth: '576px' });
  const { template, vanityName, brandId } = useContext(ClientConfigContext);
  const ref = useDetectClickOutside({ onTriggered: handleSetFieldTouchedOnFalse });

  useEffect(() => {
    if (emails[0]) {
      setEmail(emails[0].email);
      setShouldDisplaySubmitButton(false);
      setDisableNewEmailInput(true);
    }
  }, [emails]);

  useEffect(() => {
    if (fieldTouched && ref && ref.current) {
      // @ts-ignore
      ref.current.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldTouched]);

  const handleChangeEmail = () => {
    setShouldDisplaySubmitButton(true);
    setDisableNewEmailInput(false);
    setFieldTouched(true);
  };

  const handleClearAndCloseForm = () => {
    setShouldDisplayEmailForm(false);
  };

  const handleCancel = (): void => {
    handleClearAndCloseForm();
  };

  const handleSetEmailValue = (value: string) => {
    setFieldTouched(true);
    setEmail(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const FETCH_URL = `/notification/email/${trackingNumber}/${vanityName}?key=${urlKey ? urlKey : undefined}&deliveryType=${deliveryType}`;

    const payload: { email: string; brandId: string } = {
      email: email,
      brandId: brandId
    };

    const fetchParams = enhanceFetchParams({
      headers: enhanceHeadersParams(brandId),
      method: 'POST',
      body: JSON.stringify(payload)
    });

    try {
      setIsDeleteEmailSubscriptionRequestSent(false);
      setLoading(true);
      setIsFormSent(true);

      const response = await fetch(FETCH_URL, fetchParams);
      const jsonParsed = await response.json();

      if (jsonParsed.error) {
        setLoading(false);
        return setSendingFormError(true);
      }

      setEmails(jsonParsed.emails);
      setEmail(jsonParsed.emails[0].email);
      setDisableNewEmailInput(true);
      setShouldDisplaySubmitButton(false);
      setSendingFormError(false);
      setLoading(false);
      setFieldTouched(false);
    } catch (e) {
      setIsFormSent(true);
      setSendingFormError(true);
      setFieldTouched(true);
      setLoading(false);
    }
  };

  const handleRemoveEmailSubscription = async (subscription) => {
    const API_DELETE_EMAIL_SUBSCRIPTION = `/notification/email/${subscription}`;

    const fetchParams = enhanceFetchParams({
      method: 'DELETE',
      headers: enhanceHeadersParams(brandId)
    });

    try {
      setIsFormSent(false);
      setIsDeleteEmailSubscriptionRequestSent(true);

      const response = await fetch(API_DELETE_EMAIL_SUBSCRIPTION, fetchParams);
      if (!response.ok) return setDeleteEmailSubscriptionError(true);

      setDeleteEmailSubscriptionError(false);

      const newEmailsArray = emails.filter((element) => element.subscriptionId !== subscription);
      setEmails(newEmailsArray);
      setEmail('');
      setDisableNewEmailInput(false);
      setShouldDisplaySubmitButton(true);
      setFieldTouched(false);
    } catch (e) {}
  };

  return (
    <Form onSubmit={handleSubmit} data-test-id="notifications-email-form">
      <FormFieldset>
        <FormStatusMessage
          data-test-id="email-form-status-message"
          visible={isFormSent || isDeleteEmailSubscriptionRequestSent}
          error={(sendingFormError && isFormSent) || (deleteEmailSubscriptionError && isDeleteEmailSubscriptionRequestSent)}>
          {isFormSent && sendingFormError && intl.formatMessage({ id: 'TRACKING.notifications.email.formStatus.addNewEmail.error' })}
          {isFormSent && !sendingFormError && intl.formatMessage({ id: 'TRACKING.notifications.email.formStatus.addNewEmail.success' })}
          {isDeleteEmailSubscriptionRequestSent &&
            deleteEmailSubscriptionError &&
            intl.formatMessage({ id: 'TRACKING.notifications.email.formStatus.deleteEmail.error' })}
          {isDeleteEmailSubscriptionRequestSent &&
            !deleteEmailSubscriptionError &&
            intl.formatMessage({ id: 'TRACKING.notifications.email.formStatus.deleteEmail.success' })}
        </FormStatusMessage>
        <FormLabel htmlFor="email" isDark={isDark} data-test-id="email-input-label">
          {intl.formatMessage(
            { id: 'TRACKING.notifications.email.label' },
            {
              label1: (
                <FormLabelLink
                  data-gtm-id="email-privacy-policy"
                  href="https://www.pitneybowes.com/us/legal/privacy-statement.html"
                  target="_blank"
                  key={1}
                  rel="noopener noreferrer">
                  {intl.formatMessage({ id: 'TRACKING.notifications.email.label.link1' })}
                </FormLabelLink>
              ),
              label2: (
                <FormLabelLink
                  data-gtm-id="email-terms-of-use"
                  href="https://www.pitneybowes.com/us/consumer-connect-tou.html"
                  key={2}
                  target="_blank"
                  rel="noopener noreferrer">
                  {intl.formatMessage({ id: 'TRACKING.notifications.email.label.link2' })}
                </FormLabelLink>
              )
            }
          )}
        </FormLabel>
        <InputContainer>
          <FormInput
            data-gtm-id="email-text-field"
            data-test-id="email-input"
            disabled={disableNewEmailInput}
            ref={ref}
            required={true}
            type="email"
            name="email"
            id="email"
            onChange={(event) => handleSetEmailValue(event.target.value)}
            onFocus={() => setFieldTouched(true)}
            value={email}
            title="Enter valid email address"
            data-touched={fieldTouched}
            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
            placeholder="Enter your email address"
          />
          <PBIcon type={PBIconTypeEnum.Icomoon} name={PBIcomoonIconsEnum.Envelope} />
          <InputErrorMessage data-test-id="email-input-error">
            {intl.formatMessage({ id: 'TRACKING.notifications.email.inputValidation.error' })}
          </InputErrorMessage>
          {shouldDisplaySubmitButton ? (
            <FormSubmitButton data-gtm-id="email-submit" data-test-id="email-submit-button" formType="email" disabled={loading} type="submit">
              {loading ? <CircularProgress /> : intl.formatMessage({ id: 'TRACKING.notifications.email.formButton.submit' })}
            </FormSubmitButton>
          ) : (
            <FormAdditionalButtonsContainer data-test-id="email-additional-buttons-container">
              <FormAdditionalButton data-test-id="email-edit-button" onClick={handleChangeEmail} type="button" aria-label="change-button">
                {template === TemplateEnum.VIVID ? (
                  mediumUp ? (
                    intl.formatMessage({ id: 'TRACKING.notifications.email.formButton.change' })
                  ) : (
                    <PBIcon type={PBIconTypeEnum.Icomoon} name={PBIcomoonIconsEnum.Pencil} />
                  )
                ) : (
                  <PBIcon type={PBIconTypeEnum.Icomoon} name={PBIcomoonIconsEnum.Pencil} />
                )}
              </FormAdditionalButton>
              <FormAdditionalButtonBreak />
              <FormAdditionalButton
                onClick={() => handleRemoveEmailSubscription(emails[0].subscriptionId)}
                type="button"
                data-test-id="email-delete-button"
                aria-label="delete-button">
                {template === TemplateEnum.VIVID ? (
                  mediumUp ? (
                    intl.formatMessage({ id: 'TRACKING.notifications.email.formButton.delete' })
                  ) : (
                    <PBIcon type={PBIconTypeEnum.Mini} name={PBMiniIconsEnum.Close} />
                  )
                ) : (
                  <PBIcon type={PBIconTypeEnum.Mini} name={PBMiniIconsEnum.Close} />
                )}
              </FormAdditionalButton>
            </FormAdditionalButtonsContainer>
          )}
        </InputContainer>
        <FormButtonContainer>
          <FormResetButton data-gtm-id="email-cancel" data-test-id="email-cancel-button" type="reset" onClick={handleCancel}>
            {intl.formatMessage({ id: 'TRACKING.notifications.email.formButton.cancel' })}
          </FormResetButton>
        </FormButtonContainer>
      </FormFieldset>
    </Form>
  );
};

export interface OwnProps {
  isDark: boolean;
  trackingNumber: string;
  urlKey: string | null;
  emails: Email[];
  setEmails: Dispatch<SetStateAction<Email[]>>;
  deliveryType: DeliveryType.Return | DeliveryType.Forward;
  setShouldDisplayEmailForm: Dispatch<SetStateAction<boolean>>;
}
