import React, { PropsWithChildren } from 'react';
import { css, Global } from '@emotion/react';
import baseStyles from './baseStyles';
import clientStyles from './clientStyles';
import { MainTheme } from 'types/modules';
import { ClientConfigurationType } from 'types/shared/config/client-configuration-type';

export const GlobalStyles: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { theme, isPreviewBasedOnRouteUrl, clientConfig } = props;

  if (!clientConfig)
    return (
      <Global
        styles={css`
          ${baseStyles(theme)} ${clientStyles()}
        `}
      />
    );

  return (
    <Global
      styles={css`
            //Base styles
            ${baseStyles({ ...theme, customStyles: { styleModule: clientConfig.styleModule } })}

            //Clients overrides styles by client's conf object
            ${clientStyles({ ...clientConfig, isPreview: isPreviewBasedOnRouteUrl })}
          `}
    />
  );
};

interface OwnProps {
  theme: MainTheme;
  isPreviewBasedOnRouteUrl: boolean;
  clientConfig?: ClientConfigurationType;
}
