import styled from '@emotion/styled';
import { FormControl } from '@mui/material';
import { styled as muiStyled } from '@mui/system';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px 0;
`;

export const CustomFormControl = muiStyled(FormControl)({
  minWidth: '170px'
});
