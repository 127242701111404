import React, { PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ContactLink,
  ContactLinksBreak,
  ContactLinksWrapper,
  ContactParagraph,
  ContactSubtitle,
  ContactTitle,
  ContactWrapper,
  EmptyContactWrapper,
  FooterContainer,
  FooterLayout,
  PoweredByContainer,
  PoweredByLinkContainer,
  PoweredByImg,
  SocialMediaIconItem,
  SocialMediaIconLink,
  SocialMediaIconsList,
  TermsList,
  TermsListItem,
  TermsListLink,
  TermsWrapper
} from './Footer.styled';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { FooterModuleType, SocialLink } from 'types/shared/config/footerModule/footer-module-type';
import { FooterTerm } from 'types/shared/footer/footer-term-type';
import { footerTerms } from 'utils/shared/footer/footer-terms/footer-terms';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { socialLinkNameToIconNameMapper } from 'utils/shared/footer/social-link-name-to-icon-name-mapper/social-link-name-to-icon-name-mapper';
import { TiktokIcon } from './TiktokIcon/TiktokIcon';

export const Footer: React.FC<FooterProps> = (props: PropsWithChildren<FooterProps>) => {
  const { footerModule, smsEnabled } = props;
  const { contactInformation, socialLinks, brandName } = footerModule;
  const { enabled: contactInformationEnabled, phoneNumber, websiteUrl } = contactInformation;

  const [termsArray] = useState<FooterTerm[]>(footerTerms(smsEnabled));

  const intl = useIntl();
  const { staticFilesRepositoryCoco538 } = useFlags();

  return (
    <FooterLayout data-test-id="footer-layout">
      <FooterContainer data-test-id="footer-container" shouldCenterL={!contactInformationEnabled && !socialLinks}>
        {contactInformationEnabled ? (
          <ContactWrapper data-test-id="footer-contact-wrapper">
            <ContactTitle data-test-id="contact-title">{intl.formatMessage({ id: 'SHARED.footer.contact.title' })}</ContactTitle>
            <ContactSubtitle data-test-id="contact-subtitle">{intl.formatMessage({ id: 'SHARED.footer.contact.subtitle' })}</ContactSubtitle>
            <ContactLinksWrapper data-test-id="contact-links-wrapper">
              {brandName && websiteUrl && (
                <ContactLink data-gtm-id="footer-website-link" data-test-id="contact-website-link" target="_blank" href={websiteUrl}>
                  {brandName}
                </ContactLink>
              )}
              {brandName && !websiteUrl && (
                <ContactParagraph data-gtm-id="footer-website-paragraph" data-test-id="contact-website-paragraph">
                  {brandName}
                </ContactParagraph>
              )}
              {brandName && phoneNumber && <ContactLinksBreak data-test-id="footer-contact-links-break">|</ContactLinksBreak>}
              {phoneNumber && (
                <ContactLink data-test-id="contact-phone-link" href={`tel:${phoneNumber}`} data-gtm-id="footer-contact-phone">
                  {phoneNumber}
                </ContactLink>
              )}
            </ContactLinksWrapper>
          </ContactWrapper>
        ) : (
          <EmptyContactWrapper shouldHideItem={!contactInformationEnabled && !socialLinks} />
        )}
        {!!socialLinks && (
          <SocialMediaIconsList data-test-id="footer-social-media-icons-list" aria-label="list-of-social-media-icons">
            {socialLinks.map((socialLink: SocialLink) => (
              <SocialMediaIconItem key={socialLink.name} aria-label={`${socialLink.name}-list-item`}>
                <SocialMediaIconLink
                  href={socialLink.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-gtm-id={'footer-social-link-' + socialLink.name}>
                  {socialLink.name !== 'tiktok' ? (
                    <PBIcon
                      type={PBIconTypeEnum.Icomoon}
                      name={socialLinkNameToIconNameMapper(socialLink.name)}
                      role="img"
                      aria-label={`${socialLink.name} logo`}
                    />
                  ) : (
                    <TiktokIcon />
                  )}
                </SocialMediaIconLink>
              </SocialMediaIconItem>
            ))}
          </SocialMediaIconsList>
        )}
        <TermsWrapper data-test-id="terms-wrapper">
          <TermsList data-test-id="terms-list" aria-label="list-of-terms">
            {termsArray.map((term: FooterTerm) => {
              return (
                term.enabled && (
                  <TermsListItem key={term.id} data-gtm-id={term.id} aria-label={term.ariaLabel}>
                    <TermsListLink target="_blank" href={staticFilesRepositoryCoco538 ? term.secondUrl : term.url} rel="noopener noreferrer">
                      {intl.formatMessage({ id: term.intlText })}
                    </TermsListLink>
                  </TermsListItem>
                )
              );
            })}
          </TermsList>
          {staticFilesRepositoryCoco538 ? (
            <PoweredByContainer data-test-id="powered-by-container" shouldCenterL={!contactInformationEnabled && !socialLinks}>
              <PoweredByImg data-test-id="powered-by-image" src="/assets/img/powered-by-pitney-bowes.png" alt="Powered by" />
            </PoweredByContainer>
          ) : (
            <PoweredByLinkContainer
              shouldCenterL={!contactInformationEnabled && !socialLinks}
              href="https://www.pitneybowes.com/us/package-tracking-resources.html"
              data-test-id="powered-by-link"
              target="_blank"
              rel="noopener noreferrer"
              data-gtm-id="powered-by-pb-link">
              <PoweredByImg data-test-id="powered-by-image" src="/assets/img/powered-by-pitney-bowes.png" alt="Powered by" />
            </PoweredByLinkContainer>
          )}
        </TermsWrapper>
      </FooterContainer>
    </FooterLayout>
  );
};

export interface FooterProps {
  footerModule: FooterModuleType;
  smsEnabled: boolean;
}
