import React from 'react';
import { ReturnsHeadingContainer, ReturnsHeadingSubtitle, ReturnsHeadingTitle } from './ReturnsHeading.styled';

export const ReturnsHeading: React.FC<OwnProps> = (props) => {
  const { title, subtitle } = props;

  return (
    <ReturnsHeadingContainer>
      <ReturnsHeadingTitle data-test-id="returns-heading-title">{title}</ReturnsHeadingTitle>
      <ReturnsHeadingSubtitle data-test-id="returns-heading-subtitle">{subtitle}</ReturnsHeadingSubtitle>
    </ReturnsHeadingContainer>
  );
};

interface OwnProps {
  title: string;
  subtitle: string;
}
