import { DropOffLocationsSearchParams } from 'types/return/return-info/Common/drop-off-locations-response';
import { OriginAddress } from 'types/return/return-info/Common/origin-address-type';

export const transformOriginAddress = (data: DropOffLocationsSearchParams): OriginAddress => {
  const { zipCode, street, city, state } = data;
  return {
    zipCode: zipCode ? zipCode : null,
    street: street ? street : null,
    city: city ? city : null,
    state: state ? state : null
  };
};
