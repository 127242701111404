import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Stack, Table, Modal } from 'react-bootstrap';
import { getCookie } from 'utils/common/utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import { COCO_ENV } from 'constants/config';
import { getApiHostURL } from 'utils/common/get-api-host-url/get-api-host-url';
import { ApiHostTypeEnum } from 'types/shared/config/api-host-type-enum';
import { ClientConfigurationType } from 'types/shared/config/client-configuration-type';
import * as Constants from './constants';

// Temporary Data
import intransitData from '../GiftTrackerApp/assets/intransit.json';
import outfordeliveryData from '../GiftTrackerApp/assets/outfordelivery.json';
import deliveredData from '../GiftTrackerApp/assets/delivered.json';
import videoIntransitData from '../GiftTrackerApp/assets/gift-video-intransit.json';
import videooutfordeliveryData from '../GiftTrackerApp/assets/gift-video-outfordelivery.json';
import giftVideoData from '../GiftTrackerApp/assets/gift-video.json';

type AppParams = {
  vanityName: string | undefined;
  giftId: string | undefined;
};

export default function RecipientComponent() {
  const { vanityName, giftId } = useParams<AppParams>();
  const [packageDetails, setPackageDetails] = useState<string[] | null>([]);
  const [giftInfo, setGiftInfo] = useState({});
  const [headerSubText, setHeaderSubText] = useState('');
  const [showPackageInfo, setShowPackageInfo] = useState(false);
  const [showTapDiv, setShowTapDiv] = useState(true);
  const [backgroundClass, setBackgroundClass] = useState('r-background-birthday');
  const [deliveryText, setDeliveryText] = useState('');
  const [deliveredText, setDeliveredText] = useState('');
  const [cocoEnv, setCOCOEnv] = useState(COCO_ENV);
  const [senderWebsiteUrl, setSenderWebsiteUrl] = useState('');
  //Stay in loop variables
  const [showModal, setShowModal] = useState(false);
  const [subscriberPhone, setSubscriberPhone] = useState('');
  const [subscriberTnC, setSubscriberTnC] = useState(false);
  const [showPhoneNumberErr, setShowPhoneNumberErr] = useState(false);
  const [showPhoneNumberRegexErr, setShowPhoneNumberRegexErr] = useState(false);
  const [showCheckConditionErr, setShowCheckConditionErr] = useState(false);
  const [showNoThanksDialog, setShowNoThanksDialog] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const allowedImageTypes = ['.jpeg', '.jpg', '.gif', '.png', '.heic'];
  const allowedVideoTypes = ['.mp4', '.mov'];

  // Handling of user's phone number subscription modal
  const modalClose = () => setShowModal(false);
  const modalCloseNoThanks = () => {
    localStorage.setItem(`${giftId}-modal-close-no-thanks`, 'true');
    setShowModal(false);
  };
  const modalShow = () => setShowModal(true);

  //Mapping of the response from the get gift details API.
  const getGiftDetails = (data) => {
    const giftInfo = {};
    giftInfo['textMessage'] = data.giftTrackerModel.textMessage;
    giftInfo['name'] = data.giftTrackerModel.name;
    giftInfo['subscription'] = data.giftTrackerModel.subscription;
    giftInfo['giftCategory'] = data.giftTrackerModel.giftCategory;
    giftInfo['brandName'] = data.giftTrackerModel.brandName;
    giftInfo['brandId'] = data.giftTrackerModel.brandId;
    giftInfo['giftType'] = data.giftTrackerModel.content[0].type;
    giftInfo['giftUrl'] = data.giftTrackerModel.content[0].url;
    giftInfo['trackingStatus'] = data.packageDetailsResponse.trackingPackageStatus;
    giftInfo['trackingNumber'] = data.packageDetailsResponse.trackingNumber;
    giftInfo['deliveryText'] = data.packageDetailsResponse.trackingEventsModule.eddText;
    return giftInfo;
  };

  //Mapping of the tracking details response from the get gift details API.
  const getTrackingEvents = (data) => {
    const trackEvent = {};
    trackEvent['description'] = data.description;
    trackEvent['location'] = data.location;
    trackEvent['date'] = data.date;
    trackEvent['hour'] = data.hour;
    if (trackEvent['description'] == 'Delivered') {
      trackEvent['delivereddate'] = data.dayOfWeek + ', ' + data.shortDate;
      console.log(trackEvent['delivereddate']);
      setDeliveredText(trackEvent['delivereddate']);
    }
    return trackEvent;
  };

  const showToastMessage = () => {
    toast.success('Successfully subscribed! Your surprise message will be revealed when your gift arrives.', {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  //Handling of user phone number subscription
  const onStayInLoop = async () => {
    try {
      if (subscriberPhone && subscriberTnC) {
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!phoneRegex.test(subscriberPhone)) {
          setShowPhoneNumberRegexErr(true);
          return;
        }
        setShowPhoneNumberRegexErr(false);
        setShowPhoneNumberErr(false);
        setShowCheckConditionErr(false);
        setIsShowLoader(true);
        const subscriptionAPI = `/v1/subscribeNumber?trackingNumber=${giftInfo['trackingNumber']}&number=${subscriberPhone}`;
        const headers = {
          'content-type': 'application/json',
          Accept: 'application/json',
          'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty',
          'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
        };

        const init: RequestInit = {
          method: 'GET',
          // @ts-ignore
          headers
        };
        const response = await fetch(subscriptionAPI, init);
        const data = await response.json();
        setIsShowLoader(false);
        showToastMessage();
        modalClose();
      } else {
        // Show & Hide stay in loop validation
        if (subscriberPhone) {
          setShowPhoneNumberErr(false);
        } else {
          setShowPhoneNumberErr(true);
        }

        if (subscriberTnC) {
          setShowCheckConditionErr(false);
        } else {
          setShowCheckConditionErr(true);
        }
      }
    } catch {
      toast.error('Something went wrong. Please try again in sometime!', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  // Start the scratching animation once user click's or tap the gift reveal section
  const activateAnimation = (e) => {
    e.preventDefault();
    const isPreScratch = localStorage.getItem(`${giftId}-pre-scratch`);
    const scratchTrackingStatus = localStorage.getItem(`${giftId}-scratch-tracking-status`);
    const imageHead: HTMLElement | null = document.getElementById('image-head');
    if (imageHead && giftInfo) {
      switch (giftInfo['trackingStatus']) {
        case 'OutForDelivery':
          if (imageHead) {
            if (isPreScratch === 'true' && scratchTrackingStatus === 'InTransit') {
              imageHead.className = 'mask-sub-layer mask-sub-layer-animation-pre-scratch-otd add-receiver-video-container';
            } else {
              imageHead.className = 'mask-sub-layer mask-sub-layer-animation-otd add-receiver-video-container';
            }

            localStorage.setItem(`${giftId}-scratch-tracking-status`, 'OutForDelivery');
          }
          break;
        case 'Delivered':
          imageHead.className = 'mask-sub-layer mask-sub-layer-animation-delivered add-receiver-video-container';
          localStorage.setItem(`${giftId}-scratch-tracking-status`, 'Delivered');
          break;
        default:
          imageHead.className = 'mask-sub-layer mask-sub-layer-animation-inTransit add-receiver-video-container';
          localStorage.setItem(`${giftId}-scratch-tracking-status`, 'InTransit');
      }
      setShowTapDiv(false);
      localStorage.setItem(`${giftId}-pre-scratch`, 'true');
    }
  };

  const onSubscriberTermsChecked = () => {
    setSubscriberTnC(!subscriberTnC);
  };

  const showHidePackageDetails = (e) => {
    e.preventDefault();
    setShowPackageInfo(!showPackageInfo);
  };

  useEffect(() => {
    const getGiftInfo = async () => {
      const GET_GIFT_DETAILS_API = `/v1/getGiftDetails?id=${giftId}`;
      const headers = {
        'content-type': 'application/json',
        Accept: 'application/json',
        'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty',
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
      };

      const init: RequestInit = {
        method: 'GET',
        // @ts-ignore
        headers
      };

      try {
        const response = await fetch(GET_GIFT_DETAILS_API, init);
        // switch case should be removed & only the response from the API will be used.
        let data;
        switch (giftId) {
          case 'demo-intran-98sd3':
            data = intransitData;
            break;
          case 'demo-otd-6593cf':
            data = outfordeliveryData;
            break;
          case 'demo-del-9dfs34':
            data = deliveredData;
            break;
          case 'demo-vi-intran-98sd4':
            data = videoIntransitData;
            break;
          case 'demo-vi-otd-6593cf1':
            data = videooutfordeliveryData;
            break;
          case 'demo-vi-del-8323f':
            data = giftVideoData;
            break;
          default:
            data = await response.json();
            break;
        }
        const giftInfo = getGiftDetails(data);
        const packageDetails = data.packageDetailsResponse.trackingEventsModule.trackingEvents.map((info) => {
          return getTrackingEvents(info);
        });
        //console.log(packageDetails);
        setPackageDetails(packageDetails);
        setGiftInfo(giftInfo);
        const isPreScratch = localStorage.getItem(`${giftId}-pre-scratch`);
        const scratchTrackingStatus = localStorage.getItem(`${giftId}-scratch-tracking-status`);
        const imageHead: HTMLElement | null = document.getElementById('image-head');
        switch (giftInfo['trackingStatus']) {
          case 'OutForDelivery':
            setHeaderSubText('will be delivered today.');
            if (imageHead && isPreScratch === 'true' && scratchTrackingStatus === 'OutForDelivery') {
              imageHead.className = 'mask-sub-layer mask-sub-layer-animation-pre-otd add-receiver-video-container';
              setShowTapDiv(false);
            }
            if (imageHead && isPreScratch === 'true' && scratchTrackingStatus === 'InTransit') {
              imageHead.className = 'mask-sub-layer mask-sub-layer-animation-pre-inTransit add-receiver-video-container';
            }
            break;
          case 'Delivered':
            {
              const requestUrl = `${getApiHostURL(ApiHostTypeEnum.CLIENT_CONFIGURATION_API)}/api/configuration/published-configurations/${giftInfo[
                'brandName'
              ].toLowerCase()}`;
              const enhancedParams = {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty'
                }
              };
              const response: Response = await fetch(requestUrl, enhancedParams);
              const brandResponse: ClientConfigurationType = await response.json();
              if (brandResponse?.footerModule?.contactInformation?.websiteUrl) {
                setSenderWebsiteUrl(brandResponse.footerModule.contactInformation.websiteUrl);
              }
              setHeaderSubText('was delivered.');
            }

            if (imageHead) {
              if (isPreScratch === 'true') {
                if (scratchTrackingStatus === 'OutForDelivery') {
                  imageHead.className = 'mask-sub-layer mask-sub-layer-animation-pre-scratch-delivered-otd add-receiver-video-container';
                }
                if (scratchTrackingStatus === 'InTransit') {
                  imageHead.className = 'mask-sub-layer mask-sub-layer-animation-pre-scratch-delivered-inTransit add-receiver-video-container';
                }

                if (scratchTrackingStatus === 'Delivered') {
                  imageHead.className = 'mask-sub-layer mask-sub-layer-animation-delivered add-receiver-video-container';
                }
              } else {
                imageHead.className = 'mask-sub-layer mask-sub-layer-animation-delivered add-receiver-video-container';
              }
            }
            setShowTapDiv(false);
            break;
          default:
            if (imageHead && isPreScratch === 'true' && scratchTrackingStatus === 'InTransit') {
              imageHead.className = 'mask-sub-layer mask-sub-layer-animation-pre-inTransit add-receiver-video-container';
              setShowTapDiv(false);
            }
            setHeaderSubText('is on the way.');
        }

        // set the background as per the occasion
        setBackgroundClass(`r-background-${giftInfo['giftCategory'].toLowerCase()}`);

        // show the subscription dialog if user hasn't subscribed yet for the special message
        if (giftInfo['trackingStatus'] && giftInfo['trackingStatus'] !== 'Delivered') {
          setTimeout(() => {
            const isNoThanksMsg = localStorage.getItem(`${giftId}-modal-close-no-thanks`);
            if (isNoThanksMsg === 'true') {
              // if user closes the dialog using no thanks button
              setShowNoThanksDialog(true);
            } else {
              setShowNoThanksDialog(false);
            }
            modalShow();
          }, 1000);
        }

        if (giftInfo && giftInfo['deliveryText'] && giftInfo['deliveryText'] !== null) {
          setDeliveryText(giftInfo['deliveryText']);
        } else {
          const dt = new Date();
          const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          setDeliveryText(`${dayOfWeek[dt.getDay()]}, ${months[dt.getMonth()]} ${dt.getDate()}`);
        }
      } catch {
        toast.error('Something went wrong. Please try again in sometime!', {
          position: toast.POSITION.TOP_RIGHT
        });
      } finally {
        // final result
      }
    };
    getGiftInfo();
  }, [giftId]);

  return (
    <>
      <Row className="r-header">
        <Col>
          <h1>
            Your surprise <span className="r-header-color">{headerSubText}</span>
          </h1>
        </Col>
      </Row>
      <Row className="r-subheader">
        <Col>
          {giftInfo && (giftInfo['trackingStatus'] === 'InTransit' || giftInfo['trackingStatus'] === 'OutForDelivery') && (
            <h4>It&apos;s from {giftInfo && giftInfo['name']}.</h4>
          )}
          {giftInfo && giftInfo['trackingStatus'] === 'Delivered' && <h4>And so is your message from {giftInfo && giftInfo['name']}.</h4>}
        </Col>
      </Row>
      <Row>
        <div className={`r-background ${backgroundClass}`}>
          <Container className="mb-3">
            <Row className="r-container">
              <Col xs={12} sm={12} md={2}></Col>
              <Col xs={12} sm={12} md={4} className="r-tracking-info mt-3">
                {giftInfo['trackingStatus'] && giftInfo['trackingStatus'] === 'Delivered' && (
                  <div className="r-gift-banner mb-3">
                    <Stack direction="horizontal" gap={3}>
                      <div>
                        <img src="/assets/img/g-frame.png" alt="" />
                      </div>
                      <div>
                        <div className="r-gift-banner-head">Did you like your gift?</div>
                        <div className="r-gift-banner-body">Send someone else a memorable gifting experience.</div>
                      </div>
                      <div>
                        <a href={senderWebsiteUrl} target="_blank" rel="noreferrer">
                          <img src="/assets/img/g-arrow-right.png" alt="" />
                        </a>
                      </div>
                    </Stack>
                  </div>
                )}
                <div className="r-delivery mb-3">
                  <div className="r-delivery-title">
                    {giftInfo && giftInfo['trackingStatus'] !== 'Delivered' && <div className="m-0 r-delivery-title-content">ESTIMATED DELIVERY</div>}
                    {giftInfo && giftInfo['trackingStatus'] === 'Delivered' && (
                      <div className="m-0 r-delivery-title-content">PACKAGE DELIVERED ON</div>
                    )}
                    <div className="r-flex-container">
                      <div className="m-0 r-delivery-title-content-sub">
                        {giftInfo['trackingStatus'] !== 'Delivered' && <b>{deliveryText}</b>}
                        {giftInfo['trackingStatus'] === 'Delivered' && <b>{deliveredText}</b>}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Table className="mb-1 pb-7 rounded-pill" responsive>
                      <tbody>
                        {showPackageInfo &&
                          packageDetails &&
                          packageDetails.length > 0 &&
                          packageDetails.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="r-pack-info-1">{item['date']}</div>
                                <div className="r-pack-info-2">{item['hour']}</div>
                              </td>
                              <td className="r-text-right">
                                <div className="r-pack-info-1">{item['description']}</div>
                                <div className="r-pack-info-2">{item['location']}</div>
                              </td>
                            </tr>
                          ))}

                        {showPackageInfo && (
                          <>
                            <tr>
                              <td className="r-pack-info-1 r-w-35">Tracking Number</td>
                              <td className="r-text-right r-pack-info-1 r-w-65">{giftInfo && giftInfo['trackingNumber']}</td>
                            </tr>
                            <tr>
                              <td colSpan={2} className="r-hide-details-link border-0">
                                <a href="" onClick={showHidePackageDetails}>
                                  Hide Details
                                </a>
                              </td>
                            </tr>
                          </>
                        )}
                        {!showPackageInfo && (
                          <tr>
                            <td colSpan={2} className="r-hide-details-link border-0">
                              <a href="" onClick={showHidePackageDetails}>
                                View Details
                              </a>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {giftInfo['trackingStatus'] && giftInfo['trackingStatus'] !== 'Delivered' && (
                  <div className="r-work">
                    <div className="r-work-header">How it works.</div>
                    <div className="r-work-step">
                      <Stack direction="horizontal" gap={2}>
                        <div className="p-2 r-work-step-content w-60">
                          <span className="r-work-step-header">Step 1:</span> <br />
                          Enter your phone number to get updates on delivery tracking and the reveal of a special message – just for you.
                        </div>
                        <div className="p-2 r-work-step-phone w-40"></div>
                      </Stack>
                    </div>
                    <div className="r-work-step">
                      <input
                        type="text"
                        placeholder="Enter phone number"
                        className={`r-phone-text-field ${showPhoneNumberErr || showPhoneNumberRegexErr ? 'r-phone-text-field-err' : ''}`}
                        value={subscriberPhone}
                        onChange={(event) => {
                          const updatedValue = event.target.value.replace(/[^\d+\-() ]/g, '');
                          setSubscriberPhone(updatedValue);
                        }}
                      />
                      {showPhoneNumberErr && <p className="r-error-color text-center pb-0">Enter a valid phone number.</p>}
                      {showPhoneNumberRegexErr && <p className="r-error-color text-center pb-0">Enter a valid 10 digit phone number.</p>}
                      <p className="r-phone-text-field-content mb-3">We are not sharing your phone number with anyone.</p>
                      <label className="mb-3 r-t-n-c">
                        <input checked={subscriberTnC} type="checkbox" onChange={onSubscriberTermsChecked} /> I agree to{' '}
                        <a href={Constants.SMS_URL} target="_blank" rel="noreferrer">
                          SMS Notification Terms
                        </a>
                        ,{' '}
                        <a href={Constants.PRIVACY_URL} target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a href={Constants.TnC_URL} target="_blank" rel="noreferrer">
                          Term Of Use
                        </a>
                        .
                      </label>
                      {showCheckConditionErr && <p className="r-error-color text-center pb-0">Check the box to agree to terms and conditions.</p>}
                      <button className="r-stay-in-loop-btn" onClick={onStayInLoop}>
                        Stay in the loop
                        {isShowLoader && (
                          <span>
                            <div className="dot-flashing"></div>
                          </span>
                        )}
                      </button>
                    </div>

                    <div className="r-work-step">
                      <Stack direction="horizontal" gap={2}>
                        <div className="p-2 r-work-step-msg w-40"></div>
                        <div className="p-2 w-60">
                          <div className=" r-work-step-content mb-2">
                            <span className="r-work-step-header">Step 2:</span> <br />
                            Take a peek at {giftInfo['name']} message while tracking your gift.
                          </div>
                          <div className="r-work-step-content-note">
                            Note: As the gift gets closer, we’ll reveal a little at a time to get you excited, without ruining the surprise.
                          </div>
                        </div>
                      </Stack>
                    </div>

                    <div className="r-work-step">
                      <Stack direction="horizontal" gap={2}>
                        <div className="p-2 r-work-step-content w-60">
                          <span className="r-work-step-header">Step 3:</span> <br />
                          Once the gift arrives, your surprise message will be revealed.
                        </div>
                        <div className="p-2 r-work-step-gift w-40"></div>
                      </Stack>
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={12} sm={12} md={4} className="r-gift-info mt-3">
                <div className="r-animation-box">
                  <div onClick={activateAnimation} className="r-gift">
                    <div className="mask-layer r-scratch" id="image-head-parent">
                      <div className="mask-content">
                        <div className="scratch-div">
                          <div className="scratch-div-icon"></div>
                        </div>
                        <div className="gift-div">
                          <div className="gift-icon-div"></div>
                        </div>
                        {showTapDiv && (
                          <div className="tap-div">
                            <div className="tap-div-icon tap-layer-animation"></div>
                            <div className="tap-div-content">
                              <div className="mobile-div">
                                Tap the screen to get a sneak peek. The full message will be revealed when your gift is delivered.
                              </div>
                              <div className="desktop-div">
                                Click the screen to get a sneak peek. The full message will be revealed when your gift is delivered.
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mask-sub-layer" id="image-head">
                        {giftInfo && allowedImageTypes.includes(giftInfo['giftType']) && (
                          <>
                            <img height="80%" width="100%" src={giftInfo['giftUrl']} className="r-remove-save-img" />
                            <div className="r-photo-text">
                              <p className="px-3">{giftInfo && giftInfo['textMessage']}</p>
                            </div>
                          </>
                        )}
                        {giftInfo && allowedVideoTypes.includes(giftInfo['giftType']) && (
                          <div>
                            <video width="100%" className="VideoInput_video" controls src={giftInfo['giftUrl']}></video>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={2}></Col>
            </Row>
          </Container>
        </div>
      </Row>
      <Modal show={showModal} onHide={modalClose} className="sub-modal">
        <Modal.Header className="sun-modal-header">
          <Modal.Title className="w-100 text-center">
            <div className="r-dialog-header">Don&apos;t miss a thing</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="sub-modal-body">
          {giftInfo && !giftInfo['subscription'] && !showNoThanksDialog && (
            <>
              <p className="r-dialog-body mb-3">
                Get updates and more hints as the gift gets closer. Reveal the surprise message when it&apos;s delivered.
              </p>
              <div className="r-work-step mb-3">
                <div>
                  <input
                    type="text"
                    placeholder="Enter phone number"
                    className={`r-phone-text-field ${showPhoneNumberErr || showPhoneNumberRegexErr ? 'r-phone-text-field-err' : ''}`}
                    value={subscriberPhone}
                    onChange={(event) => {
                      const updatedValue = event.target.value.replace(/[^\d+\-() ]/g, '');
                      setSubscriberPhone(updatedValue);
                    }}
                  />
                  {showPhoneNumberErr && <p className="r-error-color text-center pb-0">Enter a valid phone number.</p>}
                  {showPhoneNumberRegexErr && <p className="r-error-color text-center pb-0">Enter a valid 10 digit phone number.</p>}
                  <p className="r-phone-text-field-content">We are not sharing your phone number with anyone.</p>
                </div>
                <div>
                  <label className="r-t-n-dialog-content">
                    <input checked={subscriberTnC} type="checkbox" onChange={onSubscriberTermsChecked} /> I agree to{' '}
                    <a href="https://www.pitneybowes.com/us/consumer-connect-sms-terms.html" target="_blank" rel="noreferrer">
                      SMS Notification Terms
                    </a>
                    ,{' '}
                    <a href="https://www.pitneybowes.com/us/legal/privacy-statement.html" target="_blank" rel="noreferrer">
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a href="https://www.pitneybowes.com/us/consumer-connect-tou.html" target="_blank" rel="noreferrer">
                      Term Of Use
                    </a>
                    .
                  </label>
                  {showCheckConditionErr && <p className="r-error-color text-center pb-0">Check the box to agree to terms and conditions.</p>}
                </div>
              </div>
            </>
          )}
          {giftInfo && giftInfo['subscription'] && (
            <p className="r-dialog-loop-content">Don’t forget. Your surprise message will be revealed when your gift arrives.</p>
          )}
          {giftInfo && !giftInfo['subscription'] && showNoThanksDialog && (
            <p className="r-dialog-loop-content">Stay tuned for gift tracking updates and your surprise message.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="w-100 mt-0 border-0 sub-modal-footer">
          {giftInfo && !giftInfo['subscription'] && !showNoThanksDialog && (
            <Stack direction="horizontal" className="w-100">
              <button className="sub-modal-loop-btn" onClick={onStayInLoop}>
                Stay in the loop
                {isShowLoader && (
                  <span>
                    <div className="dot-flashing"></div>
                  </span>
                )}
              </button>
              <button onClick={modalCloseNoThanks} className="sub-modal-close-btn">
                No Thanks
              </button>
            </Stack>
          )}
          {giftInfo && (giftInfo['subscription'] || showNoThanksDialog) && (
            <button className="sub-modal-loop-btn" onClick={modalClose}>
              Close
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}
