import styled from '@emotion/styled';

export const AttributesListItemContainer = styled.li`
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const ProductTextModifierCapitalize = styled.span`
  text-transform: capitalize;
`;
