export interface FaviconsModule {
  useDefault: boolean;
  mainImageUrl: string;
}

export interface TrackingEventsModule {
  eddText?: string;
  headerText: string;
  secondaryTextKey?: string;
  location: string;
  packageDelivered: boolean;
  packageShipped: boolean;
  trackingEvents?: TrackingEventsEntity[] | null;
  moreThanThreeEvents: boolean;
  packageNotFound: boolean;
  packageException: boolean;
  postalCode: string;
}

export interface TrackingEventsEntity {
  description: string;
  location: string;
  date: string;
  longDate: string;
  shortDate: string;
  dayOfWeek: string;
  hour: string;
  hasLongEventInfo: boolean;
}

export enum States {
  Preparing = 'Preparing',
  InTransit = 'InTransit',
  OutForDelivery = 'OutForDelivery',
  Delivered = 'Delivered',
  DeliveryException = 'DeliveryException',
  Unknown = 'Unknown',
  ReturnStarted = 'ReturnStarted',
  ReturnInTransit = 'ReturnInTransit',
  ReturnOutForDelivery = 'ReturnOutForDelivery',
  ReturnReceived = 'ReturnReceived',
  ReturnDeliveryException = 'ReturnDeliveryException',
  ReturnUnknown = 'ReturnUnknown',
  NotFound = 'NotFound',
  Timeout = 'Timeout',
  ServerError = 'ServerError',
  Fallback = 'Fallback'
}
