import React, { useContext, useState } from 'react';
import {
  TileContainer,
  TileContent,
  TileActions,
  PrimaryText,
  SecondaryText,
  SecondaryTextDate,
  Box1,
  Box2,
  Box3,
  Paragraph,
  Text,
  TextModifier,
  Wrapper,
  TileCustomButton,
  SecondaryTextDateTime,
  SecondaryTextDateTimeModifier,
  TileError,
  TileErrorModifier,
  ButtonsWrapper,
  TileActionsIconWrapper,
  ButtonsWrapper2
} from './Tile.styled';
import { Collapse, IconButton, useMediaQuery } from '@mui/material';
import { ParcelDetails, ParcelStatusEnum, TrackingEvent } from 'types/customer-service/find-parcels-api-response-type';
import { useIntl } from 'react-intl';
import { StatusBar } from './StatusBar/StatusBar';
import { transformEddDateToDisplayForm } from 'utils/customer-service/transform-edd-date-to-display-form/transform-edd-date-to-display-form';
import { ParcelsViewEnum } from 'types/customer-service/parcels-view-enum';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBIcomoonIconsEnum, PBOutlineIconsEnum } from 'constants/PBIconsEnums';
import dayjs from 'dayjs';
import { transformEddTimeToDisplayForm } from 'utils/customer-service/transform-edd-time-to-display-form/transform-edd-time-to-display-form';
import { resolveEDDTimePreposition } from 'utils/customer-service/transform-edd-time-to-display-form/resolve-edd-time-preposition/resolve-edd-time-preposition';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material/styles';
import useClipboard from 'react-use-clipboard';
import { buildTrackingPageLink } from 'utils/customer-service/build-tracking-page-link/build-tracking-page-link';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { TrackingEventsTable } from 'components/CustomerService/Parcels/ParcelsList/TrackingEventsTable/TrackingEventsTable';
import { shouldDisplayTrackingEvents } from 'utils/customer-service/should-display-tracking-events/should-display-tracking-events';
import { PtsSwitchContext } from 'context/customer-service/PtsSwitchContext';
import { AuthUserContext } from 'context/customer-service/AuthUserContext';

export const Tile: React.FC<OwnProps> = (props) => {
  const { parcelDetails, trackingEvents, initiallyShowTrackingEventsHistory } = props;

  const { primaryTextKey, secondaryTextKey, parcelStatus, deliveryDetails, carrier, referenceNumber, trackingNumber } = parcelDetails;
  const { vanityName } = useContext(ClientConfigContext);
  const { consumerViewOnly } = useContext(PtsSwitchContext);
  const { isUserAuth } = useContext(AuthUserContext);
  const [isCopied, setCopied] = useClipboard(buildTrackingPageLink(vanityName, trackingNumber), { successDuration: 2000 });
  const [trackingEventsExpanded, setTrackingEventsExpanded] = useState<boolean>(
    initiallyShowTrackingEventsHistory && shouldDisplayTrackingEvents(trackingEvents, consumerViewOnly, isUserAuth)
  );

  const { hideTrackingLinkOnCsPageCbconnect2554 } = useFlags();
  const intl = useIntl();
  const theme = useTheme();
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));

  const handleClickCopyPageLink = () => {
    setCopied();
  };

  const handleClickViewTrackingPage = () => {
    window.dataLayer.push({
      event: 'clickViewTrackingPageButton',
      viewTrackingPageUrl: buildTrackingPageLink(vanityName, trackingNumber)
    });
    window.open(buildTrackingPageLink(vanityName, trackingNumber), 'target');
  };

  const handleToggleTrackingEventsHistory = () => {
    setTrackingEventsExpanded((prevState: boolean) => !prevState);
  };

  return (
    <TileContainer aria-label="tile">
      <TileContent
        displayTileActions={shouldDisplayTrackingEvents(trackingEvents, consumerViewOnly, isUserAuth) || !hideTrackingLinkOnCsPageCbconnect2554}>
        {intl.formatMessage({ id: primaryTextKey }) != ' ' && (
          <PrimaryText data-test-id="tile__primary-text">{intl.formatMessage({ id: primaryTextKey })}</PrimaryText>
        )}
        <Wrapper>
          <Box1>
            <StatusBar parcelStatus={parcelStatus} />
            {intl.formatMessage({ id: secondaryTextKey }) != ' ' && (
              <SecondaryText data-test-id="tile__secondary-text">{intl.formatMessage({ id: secondaryTextKey })}</SecondaryText>
            )}
            {!!transformEddDateToDisplayForm(
              parcelStatus,
              !!deliveryDetails ? deliveryDetails.estimatedDeliveryDate : null,
              !!deliveryDetails ? deliveryDetails.parcelDeliveredAtDate : null,
              !!deliveryDetails ? deliveryDetails.parcelDeliveryAddress : null,
              ParcelsViewEnum.Tile
            ) && (
              <SecondaryTextDate data-test-id="tile__estimated-delivery-date">
                {transformEddDateToDisplayForm(
                  parcelStatus,
                  !!deliveryDetails ? deliveryDetails.estimatedDeliveryDate : null,
                  !!deliveryDetails ? deliveryDetails.parcelDeliveredAtDate : null,
                  !!deliveryDetails ? deliveryDetails.parcelDeliveryAddress : null,
                  ParcelsViewEnum.Tile
                )}
                {!!transformEddTimeToDisplayForm(
                  parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.estimatedDeliveryDate : null,
                  parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.parcelDeliveredAtDate : null,
                  parcelDetails.parcelStatus,
                  ParcelsViewEnum.Tile
                ) && (
                  <SecondaryTextDateTime>
                    {intl.formatMessage({ id: resolveEDDTimePreposition(parcelDetails.parcelStatus, ParcelsViewEnum.Tile) })}
                    <SecondaryTextDateTimeModifier>
                      {transformEddTimeToDisplayForm(
                        parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.estimatedDeliveryDate : null,
                        parcelDetails.deliveryDetails ? parcelDetails.deliveryDetails.parcelDeliveredAtDate : null,
                        parcelDetails.parcelStatus,
                        ParcelsViewEnum.Tile
                      )}
                    </SecondaryTextDateTimeModifier>
                  </SecondaryTextDateTime>
                )}
              </SecondaryTextDate>
            )}
          </Box1>
          <Box2>
            <Paragraph data-test-id="tile__tracking-number">
              <Text data-test-id="tracking-number__name">
                {intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.trackingNumber.name' })}
              </Text>
              <TextModifier data-test-id="tracking-number__value">{trackingNumber}</TextModifier>
            </Paragraph>
            <Paragraph data-test-id="tile__carrier">
              <Text data-test-id="carrier__name">
                {intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.carrier.name' })}
              </Text>
              {!!carrier && <TextModifier data-test-id="carrier__value">{carrier}</TextModifier>}
            </Paragraph>
            <Paragraph data-test-id="tile__reference-number">
              <Text data-test-id="reference-number__name">
                {intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.referenceNumber.name' })}
              </Text>
              {!!referenceNumber && <TextModifier data-test-id="reference-number__value">{referenceNumber}</TextModifier>}
            </Paragraph>
            <Paragraph data-test-id="tile__ship-date">
              <Text data-test-id="ship-date__name">
                {intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.shipDate.name' })}
              </Text>
              {!!deliveryDetails && <TextModifier data-test-id="ship-date__value">{dayjs(deliveryDetails.shipDate).format('ll')}</TextModifier>}
            </Paragraph>
          </Box2>
          <Box3></Box3>
        </Wrapper>
      </TileContent>
      {parcelStatus === ParcelStatusEnum.NOT_FOUND || parcelStatus === ParcelStatusEnum.TIMEOUT ? (
        <TileError data-test-id="tile__error">
          <TileErrorModifier>
            {parcelStatus === ParcelStatusEnum.NOT_FOUND
              ? intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.error.notFound.prefix' })
              : intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.error.timeout.prefix' })}
          </TileErrorModifier>
          {parcelStatus === ParcelStatusEnum.NOT_FOUND
            ? intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.error.notFound.postfix' })
            : intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.error.timeout.postfix' })}
        </TileError>
      ) : shouldDisplayTrackingEvents(trackingEvents, consumerViewOnly, isUserAuth) || !hideTrackingLinkOnCsPageCbconnect2554 ? (
        <TileActions data-test-id="tile__actions-container">
          {shouldDisplayTrackingEvents(trackingEvents, consumerViewOnly, isUserAuth) && (
            <TileCustomButton
              data-test-id="tile__show-tracking-history-button"
              data-gtm-id={trackingEventsExpanded ? 'events-table-toggle-hide' : 'events-table-toggle-show'}
              onClick={handleToggleTrackingEventsHistory}
              size={largeUp ? 'medium' : 'small'}
              variant="text"
              startIcon={
                <PBIcon
                  style={{ fontSize: '24px' }}
                  rotate={trackingEventsExpanded ? 90 : 0}
                  type={PBIconTypeEnum.Icomoon}
                  name={PBIcomoonIconsEnum.ChevronRight}
                />
              }>
              {trackingEventsExpanded
                ? intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.button.showTrackingHistory.unexpanded' })
                : intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.button.showTrackingHistory.expanded' })}
            </TileCustomButton>
          )}
          {!hideTrackingLinkOnCsPageCbconnect2554 && !largeUp && (
            <ButtonsWrapper>
              <ButtonsWrapper2>
                <IconButton
                  onClick={handleClickViewTrackingPage}
                  data-test-id="tile__view-tracking-page-mobile-button"
                  aria-label="view-tracking-page"
                  sx={{ margin: '0 3px 0 0' }}>
                  <TileActionsIconWrapper displayInfoMessage={false}>
                    <PBIcon
                      data-test-id="view-tracking-page-mobile-button__icon"
                      title={intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.button.viewTrackingPage' })}
                      name={PBOutlineIconsEnum.LocationPin}
                      type={PBIconTypeEnum.Outline}
                    />
                  </TileActionsIconWrapper>
                </IconButton>
                <IconButton
                  onClick={handleClickCopyPageLink}
                  disabled={isCopied}
                  data-test-id="tile__copy-page-link-mobile-button"
                  data-gtm-id="copy-page-link"
                  aria-label="copy-page-link">
                  <TileActionsIconWrapper
                    displayInfoMessage={isCopied}
                    message={intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.button.copyPageLink.copied' })}>
                    <PBIcon
                      data-test-id="copy-page-link-mobile-button__icon"
                      title={intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.button.copyPageLink.uncopied' })}
                      name={isCopied ? PBOutlineIconsEnum.CheckCircle : PBOutlineIconsEnum.Connection}
                      type={isCopied ? PBIconTypeEnum.Outline : PBIconTypeEnum.Outline}
                    />
                  </TileActionsIconWrapper>
                </IconButton>
              </ButtonsWrapper2>
            </ButtonsWrapper>
          )}
          {!hideTrackingLinkOnCsPageCbconnect2554 && largeUp && (
            <ButtonsWrapper>
              <ButtonsWrapper2>
                <TileCustomButton
                  marginDefault="0 10px 0 0"
                  data-test-id="tile__view-tracking-page-desktop-button"
                  size="medium"
                  variant="text"
                  onClick={handleClickViewTrackingPage}
                  startIcon={<PBIcon style={{ fontSize: '20px' }} name={PBOutlineIconsEnum.LocationPin} type={PBIconTypeEnum.Outline} />}>
                  {intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.button.viewTrackingPage' })}
                </TileCustomButton>
                <TileCustomButton
                  data-test-id="tile__copy-page-link-desktop-button"
                  data-gtm-id="copy-page-link"
                  size="medium"
                  variant="text"
                  onClick={handleClickCopyPageLink}
                  disabled={isCopied}
                  style={{ color: isCopied ? '#00b140' : '#0068a8' }}
                  startIcon={
                    <PBIcon
                      style={{ fontSize: '20px' }}
                      name={isCopied ? PBOutlineIconsEnum.CheckCircle : PBOutlineIconsEnum.Connection}
                      type={isCopied ? PBIconTypeEnum.Outline : PBIconTypeEnum.Outline}
                    />
                  }>
                  {isCopied
                    ? intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.button.copyPageLink.copied' })
                    : intl.formatMessage({ id: 'CS.parcels.parcelsList.common.tileView.tilesList.tile.button.copyPageLink.uncopied' })}
                </TileCustomButton>
              </ButtonsWrapper2>
            </ButtonsWrapper>
          )}
        </TileActions>
      ) : null}

      <Collapse in={trackingEventsExpanded} unmountOnExit timeout="auto">
        <TrackingEventsTable trackingEvents={trackingEvents} referenceNumber={null} />
      </Collapse>
    </TileContainer>
  );
};

export interface OwnProps {
  parcelDetails: ParcelDetails;
  trackingEvents: TrackingEvent[];
  initiallyShowTrackingEventsHistory: boolean;
}
