import styled from '@emotion/styled';

export const ButtonBox = styled.button<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 10px;
  background-color: white;
  opacity: 0.8;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0 0 0 / 50%);
  border: 0;
  cursor: pointer;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  @media ${({ theme }) => theme.mediumUp} {
    flex-direction: row;
  }
`;

export const IconBox = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`;

export const ButtonLabel = styled.span<{ fontFamily: string; fontColor: string }>`
  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ fontColor }) => fontColor};
  font-weight: 700;
  font-size: 14px;

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 16px;
    margin-left: 5px;
  }
`;
