import { Config, load } from 'webfontloader';
import compact from 'lodash/compact';
import { StyleModuleType } from 'types/shared/config/styleModule/style-module-type';

export const resolveLoadFonts2Payload = (styleModule: StyleModuleType | null, onFontsLoaded: () => void, defaultFonts: string[]): Config => {
  const defaultFontSet: string[] = defaultFonts;
  const defaultWebFontConfig: Config = {
    google: {
      families: defaultFontSet
    },
    loading: () => {
      onFontsLoaded();
    }
  };
  if (!styleModule) return defaultWebFontConfig;

  const { headerFontFamily, bodyFontFamily, subheaderFontFamily } = styleModule;

  const customFonts: string[] = compact([
    headerFontFamily ? `${headerFontFamily}:400,700` : null,
    subheaderFontFamily ? `${subheaderFontFamily}:400,700` : null,
    bodyFontFamily ? `${bodyFontFamily}:400,700` : null
  ]);

  const WebFontConfig: Config = {
    google: {
      families: customFonts
    },
    loading: () => {
      onFontsLoaded();
    }
  };

  return WebFontConfig;
};

export const loadFonts2 = (styleModule: StyleModuleType | null, onFontsLoaded: () => void, defaultFonts: string[]): void => {
  const WebFontConfig: Config = resolveLoadFonts2Payload(styleModule, onFontsLoaded, defaultFonts);

  load(WebFontConfig);
};
