import React from 'react';
import {
  BannerContainer,
  BannerIconContainer,
  BannerLink,
  BannerList,
  BannerListItem,
  BannerParagraph,
  BannerTextContainer,
  BannerTitle,
  BannerWrapper
} from './Banner.styled';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBOutlineIconsEnum } from 'constants/PBIconsEnums';

export const Banner: React.FC<OwnProps> = (props) => {
  const { titleText, paragraphText, linkText, linkUrl, textsArray } = props;

  return (
    <BannerContainer data-test-id="banner-container">
      <BannerWrapper data-test-id="banner-wrapper">
        <BannerIconContainer data-test-id="banner-icon-container">
          <PBIcon type={PBIconTypeEnum.Outline} name={PBOutlineIconsEnum.WarningTriangle} />
        </BannerIconContainer>
        <BannerTextContainer data-test-id="banner-text-container">
          {titleText && <BannerTitle data-test-id="banner-title">{titleText}</BannerTitle>}
          {paragraphText && (
            <BannerParagraph data-test-id="banner-paragraph">
              {paragraphText}
              {linkText && linkUrl && ' '}
              {linkText && linkUrl && (
                <BannerLink data-test-id="banner-link" target="_blank" href={linkUrl}>
                  {linkText}
                </BannerLink>
              )}
            </BannerParagraph>
          )}
          {textsArray && textsArray.length !== 0 && (
            <BannerList data-test-id="banner-list">
              {textsArray.map((textObject, index) => (
                <BannerListItem key={index}>{textObject.message}</BannerListItem>
              ))}
            </BannerList>
          )}
        </BannerTextContainer>
      </BannerWrapper>
    </BannerContainer>
  );
};

export interface OwnProps {
  titleText?: string | null;
  paragraphText?: string;
  linkText?: string;
  linkUrl?: string;
  textsArray?: TextObject[];
}

type TextObject = {
  message: string;
  url: string;
};
