import { MethodEnum } from 'types/return/overview/methods/method-types';

enum GTMReturnLabelTypeEnum {
  Label = 'Label',
  QRCode = 'QR Code'
}

export const resolveGTMReturnLabelTypeValue = (returnMethod: MethodEnum): GTMReturnLabelTypeEnum => {
  if (returnMethod === MethodEnum.Label) return GTMReturnLabelTypeEnum.Label;
  return GTMReturnLabelTypeEnum.QRCode;
};
