import styled from '@emotion/styled';
import { FormHelperText } from '@mui/material';

export const CustomLabel = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  color: #4e4e4e;
`;

export const SelectPlaceholder = styled.em`
  font-style: normal;
  color: #bbbbbb;
`;

export const HelperText = styled(FormHelperText)`
  color: #f44336;
  margin-left: 0;
`;
