import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import React, { useContext, useEffect } from 'react';
import { GoogleTagManagerModuleType } from 'types/shared/googleTagManager/google-tag-manager-type';
import { initGoogleTagManagerDataLayer } from 'utils/common/google-tag-manager/google-tag-manager';
import { QrCodeContent } from '../QRCodeContent/QRCodeContent';
import { QRCodeDetailsWrapper } from './QRCodeDetails.styled';

export const QRCodeDetails: React.FC<OwnProps> = (props) => {
  const { qrCode, urlKey, tagManagerDetails } = props;

  const { merchantId, vanityName, clientReturnType, template, brandId } = useContext(ClientConfigContext);

  useEffect(() => {
    return initGoogleTagManagerDataLayer(
      tagManagerDetails.deliveryType,
      tagManagerDetails.trackingStatus,
      merchantId,
      vanityName,
      null,
      'Return QR Code',
      'returnPageview',
      clientReturnType === 'integrated' ? 'RMA' : 'Non-integrated',
      template,
      !!urlKey,
      null,
      brandId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QRCodeDetailsWrapper data-test-id="qr-code-details-wrapper">
      <QrCodeContent qrCode={qrCode} />
    </QRCodeDetailsWrapper>
  );
};

export interface OwnProps {
  qrCode: string;
  urlKey: string | null;
  tagManagerDetails: GoogleTagManagerModuleType;
}
