import React from 'react';
import { CharacterCounterMaxValue, CharacterCounterText, CharacterCounterContainer, CharacterCounterValue } from './CharacterCounter.styled';

export const CharacterCounter: React.FC<OwnProps> = (props) => {
  const { maxValue, value } = props;

  return (
    <CharacterCounterContainer data-tes-id="character-counter">
      <CharacterCounterText data-test-id="character-counter__text">
        <CharacterCounterValue>{value}</CharacterCounterValue>
        {' of '}
        <CharacterCounterMaxValue>{maxValue}</CharacterCounterMaxValue>
      </CharacterCounterText>
    </CharacterCounterContainer>
  );
};

export interface OwnProps {
  maxValue: number;
  value: number;
}
