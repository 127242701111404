import React from 'react';
import { CustomHtmlContainer } from './CustomHtml.styled';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { CustomHtmlModuleType } from 'types/shared/config/customHtmlModule/custom-html-module-type';

export const CustomHtml: React.FC<CustomHtmlProps> = (props) => {
  const { customHtmlModule } = props;
  const { content } = customHtmlModule;

  if (!content) return null;
  return (
    <SectionLayout marginDefault="0 0 40px 0" backgroundAsColor={false}>
      <CustomHtmlContainer data-test-id="custom-html-container" dangerouslySetInnerHTML={{ __html: content }} data-gtm-id="custom-html-module" />
    </SectionLayout>
  );
};

export interface CustomHtmlProps {
  customHtmlModule: CustomHtmlModuleType;
}
