declare const process: {
  env: {
    [key: string]: string;
  };
};

export const BASE_URL: string = process.env.REACT_APP_BASE_URL;
export const GOOGLE_MAP_KEY: string = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const GIFT_APP_BASE_URL: string = process.env.REACT_APP_GIFT_APP_BASE_URL;
export const COCO_ENV: string = process.env.REACT_APP_COCO_ENV;
