import React, { SetStateAction } from 'react';
import { Container, CustomFormControl } from './TableToolbar.styled';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import type { FilterByStatusType } from 'types/customer-service/filter-by-status-type';
import { SelectOptionsForFilterParcelsType, SELECT_OPTIONS_FOR_FILTER_PARCELS } from 'constants/select-options-for-filter-parcels';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { ExportCSVButton } from './ExportCSVButton/ExportCSVButton';

export const TableToolbar = (props: OwnProps) => {
  const { filterByStatus, setFilterByStatus, sortedParcels } = props;

  const handleChangeFilterParcelsBy = (event: SelectChangeEvent<unknown>) => {
    setFilterByStatus(event.target.value as FilterByStatusType);

    const foundElement: SelectOptionsForFilterParcelsType | undefined = SELECT_OPTIONS_FOR_FILTER_PARCELS.find(
      (element: SelectOptionsForFilterParcelsType) => element.value === (event.target.value as FilterByStatusType)
    );

    if (!!foundElement) {
      window.dataLayer.push({
        event: 'tableViewFilterParcelsBy',
        tableViewFilterParcelsByOption: foundElement.name
      });
    }
  };

  return (
    <Container>
      <ExportCSVButton parcels={sortedParcels} />
      <CustomFormControl size="small" data-test-id="cs-table-toolbar-filter-parcels-form-control">
        <Select
          labelId="cs-table-toolbar-filter-parcels-select"
          value={filterByStatus}
          onChange={(event: SelectChangeEvent<unknown>) => handleChangeFilterParcelsBy(event)}
          sx={{ color: '#4a4a4a' }}>
          {SELECT_OPTIONS_FOR_FILTER_PARCELS.map((filterParcelValue) => (
            <MenuItem key={filterParcelValue.name} value={filterParcelValue.value} sx={{ color: '#4a4a4a' }}>
              {filterParcelValue.name}
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
    </Container>
  );
};

export interface OwnProps {
  filterByStatus: FilterByStatusType;
  setFilterByStatus: React.Dispatch<SetStateAction<FilterByStatusType>>;
  sortedParcels: Parcel[];
}
