import styled from '@emotion/styled';

export const SpartanContainer = styled.main`
  width: 100%;
  min-height: 100vh;
`;

export const MainContentContainer = styled.div`
  @media ${({ theme }) => theme.xlargeUp} {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    padding: 0 120px;
  }
`;

export const MainContentWrapper = styled.div<{ asideExist: boolean }>`
  @media ${({ theme }) => theme.xlargeUp} {
    width: ${({ asideExist }) => (asideExist ? '65%' : '100%')};
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    width: ${({ asideExist }) => (asideExist ? '70%' : '100%')};
  }
`;

export const AsideContent = styled.aside`
  margin-bottom: 80px;

  @media ${({ theme }) => theme.xlargeUp} {
    width: 30%;
    margin-bottom: 0;
  }
  @media ${({ theme }) => theme.xxlargeUp} {
    width: 25%;
  }
`;
