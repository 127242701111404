import React from 'react';
import { ItemContainer, ItemDescription, ItemDate, ItemHour, ItemElementsWrapper, ItemLocation } from './TrackingEventsListItem.styled';

export const TrackingEventsListItem: React.FC<TrackingEventsListItemProps> = (props) => {
  const { date, hour, description, location, trackingEventsLength } = props;

  return (
    <ItemContainer data-test-id="tracking-events-item-container" trackingEventsLength={trackingEventsLength}>
      <ItemElementsWrapper>
        <ItemDate data-test-id="tracking-events-item-date">{date}</ItemDate>
        <ItemHour data-test-id="tracking-events-item-hour">{hour}</ItemHour>
      </ItemElementsWrapper>
      <ItemElementsWrapper>
        <ItemDescription data-test-id="tracking-events-item-description">{description}</ItemDescription>
        {location && <ItemLocation data-test-id="tracking-events-item-location">{location}</ItemLocation>}
      </ItemElementsWrapper>
    </ItemContainer>
  );
};

export interface TrackingEventsListItemProps {
  date: string;
  hour: string;
  description: string;
  location: string | null;
  trackingEventsLength: number;
}
