import { ParcelStatusEnum } from 'types/customer-service/find-parcels-api-response-type';
import type { FilterByStatusType } from 'types/customer-service/filter-by-status-type';

export interface SelectOptionsForFilterParcelsType {
  name: string;
  value: FilterByStatusType;
}

export const SELECT_OPTIONS_FOR_FILTER_PARCELS: SelectOptionsForFilterParcelsType[] = [
  { name: 'All Statuses', value: 'all' },
  { name: 'Preparing', value: ParcelStatusEnum.PREPARING },
  { name: 'In Transit', value: ParcelStatusEnum.IN_TRANSIT },
  { name: 'Out for Delivery', value: ParcelStatusEnum.OUT_FOR_DELIVERY },
  { name: 'Delivered', value: ParcelStatusEnum.DELIVERED },
  { name: 'Delivery Exception', value: ParcelStatusEnum.DELIVERY_EXCEPTION },
  { name: 'Unknown', value: ParcelStatusEnum.UNKNOWN },
  { name: 'Return Started', value: ParcelStatusEnum.RETURN_STARTED },
  { name: 'Return In Transit', value: ParcelStatusEnum.RETURN_IN_TRANSIT },
  { name: 'Return Out for Delivery', value: ParcelStatusEnum.RETURN_OUT_FOR_DELIVERY },
  { name: 'Return Received', value: ParcelStatusEnum.RETURN_RECEIVED },
  { name: 'Return Delivery Exception', value: ParcelStatusEnum.RETURN_DELIVERY_EXCEPTION },
  { name: 'Return Unknown', value: ParcelStatusEnum.RETURN_UNKNOWN },
  { name: 'Not Found', value: ParcelStatusEnum.NOT_FOUND }
];
