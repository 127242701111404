import React from 'react';
import { TitleText } from './SubpageHeading.styled';

export const SubpageHeading: React.FC<OwnProps> = (props) => {
  const { title, dataTestId, titleId } = props;

  if (!!titleId)
    return (
      <TitleText id={titleId} data-test-id={dataTestId}>
        {title}
      </TitleText>
    );
  return <TitleText data-test-id={dataTestId}>{title}</TitleText>;
};

export interface OwnProps {
  title: string;
  dataTestId?: string;
  titleId?: string;
}
