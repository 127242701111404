import React, { SetStateAction } from 'react';
import { CustomTableHead } from './HeadParcelTable.styled';
import { SortDirection, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { SortByParcelType } from 'types/customer-service/sort-by-parcel-type';
import { ColumnTypeEnum } from 'types/customer-service/column-type-enum';
import { SingleParcelColumnHead } from './SingleParcelColumnHead/SingleParcelColumnHead';
import { shouldDisplayColumn } from 'utils/customer-service/should-display-column/should-display-column';

export const HeadParcelTable = (props: OwnProps) => {
  const { sortBy, setSortBy, sortType, setSortType } = props;

  const theme = useTheme();
  const xlargeUp = useMediaQuery(theme.breakpoints.up('xlarge'));

  return (
    <CustomTableHead>
      <TableRow>
        {Object.values(ColumnTypeEnum).map((columnType: ColumnTypeEnum) => {
          if (shouldDisplayColumn(columnType, xlargeUp))
            return (
              <SingleParcelColumnHead
                key={columnType}
                columnType={columnType}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortType={sortType}
                setSortType={setSortType}
              />
            );
        })}
      </TableRow>
    </CustomTableHead>
  );
};

export interface OwnProps {
  sortBy: SortByParcelType;
  setSortBy: React.Dispatch<SetStateAction<SortByParcelType>>;
  sortType: SortDirection;
  setSortType: React.Dispatch<SetStateAction<SortDirection>>;
}
