import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import { InfoTypeEnum } from './InfoItem';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const InfoItemContainer = styled.li`
  font-family: ${getCustomStyle('styleModule.subheaderFontFamily')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      padding: 6px 18px;
      border-bottom: 1px solid ${theme.gray900};
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      padding: 8px 5px;
      border-bottom: 1px solid ${theme.gray800};
    `}
`;

export const InfoItemText = styled.span<{ infoType?: string; bold?: boolean }>`
  color: ${({ infoType }) =>
    infoType === InfoTypeEnum.TRACKING_NUMBER || infoType === InfoTypeEnum.ORDER_NUMBER ? getCustomStyle('styleModule.secondaryColor') : '#6b6b6b'};
  font-weight: ${({ infoType }) => (infoType === InfoTypeEnum.TRACKING_NUMBER || infoType === InfoTypeEnum.ORDER_NUMBER ? '700' : '400')};
  text-transform: ${({ infoType }) => infoType === InfoTypeEnum.TRACKING_NUMBER && 'uppercase'};

  ${({ theme, bold }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      font-weight: ${bold && '700'};
      font-size: 12px;
      line-height: 21px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      font-size: 14px;
      line-height: 25px;
    `}
`;

export const InfoItemIconWrapper = styled.div`
  margin-right: 5px;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      width: 21px;
      height: 21px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      width: 25px;
      height: 25px;
    `}
`;

export const InfoItemIcon = styled.img`
  width: 100%;
`;

export const InfoItemLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    text-decoration: underline;
    transition: all 0.3s;
  }
`;

export const InfoItemWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`;
