import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { isNumberValid } from 'utils/shared/number-regex/number-regex';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DispositionRuleType, DispositionSortCodeType, SortCodeType } from 'types/return/shared/disposition-sort-code-type';
import { ShipToType } from 'types/return/overview/overview-api-response-type';
import { useIntl } from 'react-intl';
import { ReturnsCustomFields } from 'types/return/shared/returns-custom-fields-type';
import { isFirstLetterVowel } from 'utils/shared/articles/articles';
import { ReturnClientReturnType } from '../../types/return/shared/client-return-type';

export const ReviewContext = createContext<ReviewContextType>({
  orderLevelComment: '',
  setOrderLevelComment: () => {},
  orderLevelCommentError: '',
  setOrderLevelCommentError: () => {},

  customField: '',
  setCustomField: () => {},
  customFieldError: '',
  setCustomFieldError: () => {},

  fullName: '',
  setFullName: () => {},
  fullNameError: '',
  setFullNameError: () => {},

  addressOne: '',
  setAddressOne: () => {},
  addressOneError: '',
  setAddressOneError: () => {},

  addressTwo: '',
  setAddressTwo: () => {},
  addressTwoError: '',
  setAddressTwoError: () => {},

  city: '',
  setCity: () => {},
  cityError: '',
  setCityError: () => {},

  selectedState: '',
  setSelectedState: () => {},
  selectedStateError: '',
  setSelectedStateError: () => {},

  zipCode: '',
  setZipCode: () => {},
  zipCodeError: '',
  setZipCodeError: () => {},

  email: '',
  setEmail: () => {},
  emailError: '',
  setEmailError: () => {},

  number: '',
  setNumber: () => {},
  numberError: '',
  setNumberError: () => {},

  emailNotificationChecked: false,
  setEmailNotificationChecked: () => {},
  emailNotification: 'string',
  setEmailNotification: () => {},
  emailNotificationError: '',
  setEmailNotificationError: () => {},

  numberNotificationChecked: false,
  setNumberNotificationChecked: () => {},
  numberNotification: 'string',
  setNumberNotification: () => {},
  numberNotificationError: '',
  setNumberNotificationError: () => {},

  dispositionRuleType: '',
  sortCodeLabel: '',
  sortCodes: [],
  selectedSortCode: {},
  setSelectedSortCode: () => {},
  sortCodeSelection: '',
  setSortCodeSelection: () => {},
  initialShipFromAddressValid: false,
  setValuesFromInitialShipFromAddressForForm: () => {},
  showShippingFormAddressForm: true,
  setShowShippingFormAddressForm: () => {},
  areAllRequiredFieldsCorrect: true
});

export const ReviewContextProvider: React.FC<ReviewContextProviderProps> = (props) => {
  const { children, initialShipFromAddress, dispositionSortCode, returnsCustomField, isShippingPostalCodeExists, initialClientReturnType } = props;

  // const { clientReturnType } = useContext(ClientConfigContext);
  const intl = useIntl();
  const { returnsRemovePhoneNumberCoco159, returnsUps2DayCoco264 } = useFlags();

  const [orderLevelComment, setOrderLevelComment] = useState<string>('');
  const [orderLevelCommentError, setOrderLevelCommentError] = useState<string>('');

  const [customField, setCustomField] = useState<string>('');
  const [customFieldError, setCustomFieldError] = useState<string>('');

  const [fullName, setFullName] = useState<string>('');
  const [fullNameError, setFullNameError] = useState<string>('');

  const [addressOne, setAddressOne] = useState<string>('');
  const [addressOneError, setAddressOneError] = useState<string>('');

  const [addressTwo, setAddressTwo] = useState<string>('');
  const [addressTwoError, setAddressTwoError] = useState<string>('');

  const [city, setCity] = useState<string>('');
  const [cityError, setCityError] = useState<string>('');

  const [selectedState, setSelectedState] = useState<string>('');
  const [selectedStateError, setSelectedStateError] = useState<string>('');

  const [zipCode, setZipCode] = useState<string>('');
  const [zipCodeError, setZipCodeError] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const [number, setNumber] = useState<string>('');
  const [numberError, setNumberError] = useState<string>('');

  const [emailNotificationChecked, setEmailNotificationChecked] = useState<boolean>(false);
  const [emailNotification, setEmailNotification] = useState<string>('');
  const [emailNotificationError, setEmailNotificationError] = useState<string>('');

  const [numberNotificationChecked, setNumberNotificationChecked] = useState<boolean>(false);
  const [numberNotification, setNumberNotification] = useState<string>('');
  const [numberNotificationError, setNumberNotificationError] = useState<string>('');

  const [dispositionRuleType, setDispositionRuleType] = useState<DispositionRuleType>('');
  const [sortCodeLabel, setSortCodeLabel] = useState<string>('');
  const [sortCodes, setSortCodes] = useState<SortCodeType[]>([]);
  const [selectedSortCode, setSelectedSortCode] = useState<SortCodeType | Record<string, never>>({});
  const [sortCodeSelection, setSortCodeSelection] = useState<string>('');

  const [initialShipFromAddressValid, setInitialShipFromAddressValid] = useState<boolean>(false);
  const [showShippingFormAddressForm, setShowShippingFormAddressForm] = useState<boolean>(true);

  const [areAllRequiredFieldsCorrect, setAreAllRequiredFieldsCorrect] = useState<boolean>(false);

  useEffect(() => {
    if (
      !orderLevelCommentError &&
      !customFieldError &&
      !fullNameError &&
      !addressOneError &&
      !addressTwoError &&
      !cityError &&
      !selectedStateError &&
      !zipCodeError &&
      !emailError &&
      !numberError &&
      !emailNotificationError &&
      !numberNotificationError &&
      (!returnsCustomField.enabled || !returnsCustomField.required || (returnsCustomField.required && customField.length > 0)) &&
      fullName.length > 0 &&
      addressOne.length > 0 &&
      city.length > 0 &&
      selectedState.length > 0 &&
      zipCode.length > 0 &&
      ((!returnsUps2DayCoco264 && email.length > 0) || returnsUps2DayCoco264) &&
      (!emailNotificationChecked || (emailNotificationChecked && emailNotification.length > 0)) &&
      (!numberNotificationChecked || (numberNotificationChecked && numberNotification.length > 0))
    )
      return setAreAllRequiredFieldsCorrect(true);
    setAreAllRequiredFieldsCorrect(false);
  }, [
    orderLevelCommentError,
    customFieldError,
    fullNameError,
    addressOneError,
    addressTwoError,
    cityError,
    selectedStateError,
    zipCodeError,
    emailError,
    numberError,
    emailNotificationError,
    numberNotificationError,
    returnsCustomField,
    customField,
    fullName,
    addressOne,
    city,
    selectedState,
    zipCode,
    email,
    emailNotification,
    emailNotificationChecked,
    numberNotification,
    numberNotificationChecked,
    returnsUps2DayCoco264
  ]);

  useEffect(() => {
    if (initialClientReturnType === 'nonintegrated') {
      setOrderLevelCommentError('');
      setCustomFieldError('');
      setFullNameError('');
      setAddressOneError('');
      setAddressTwo('');
      setCityError('');
      setSelectedStateError('');
      setZipCodeError('');
      setNumberError('');
      setEmailError('');
    }
  }, [initialClientReturnType]);

  useEffect(() => {
    if (!initialShipFromAddress) {
      setFullName('');
      setAddressOne('');
      setAddressTwo('');
      setCity('');
      setSelectedState('');
      setZipCode('');
      setInitialShipFromAddressValid(false);
      setShowShippingFormAddressForm(true);
    }
    if (returnsUps2DayCoco264) setFullName('AT&T');
    if (initialShipFromAddress) {
      if (!returnsUps2DayCoco264 && initialShipFromAddress.firstName) setFullName(initialShipFromAddress.firstName);

      if (!returnsUps2DayCoco264 && initialShipFromAddress.lastName)
        setFullName((prevState) => `${prevState ? prevState + ' ' : ''}${initialShipFromAddress.lastName}`);
      if (initialShipFromAddress.mailingAddress && initialShipFromAddress.mailingAddress.address1)
        setAddressOne(initialShipFromAddress.mailingAddress.address1);
      if (initialShipFromAddress.mailingAddress && initialShipFromAddress.mailingAddress.address2)
        setAddressTwo(initialShipFromAddress.mailingAddress.address2);
      if (initialShipFromAddress.mailingAddress && initialShipFromAddress.mailingAddress.city) setCity(initialShipFromAddress.mailingAddress.city);
      if (initialShipFromAddress.mailingAddress && initialShipFromAddress.mailingAddress.stateOrProvince)
        setSelectedState(initialShipFromAddress.mailingAddress.stateOrProvince);
      if (initialShipFromAddress.mailingAddress && initialShipFromAddress.mailingAddress.postalCode)
        setZipCode(initialShipFromAddress.mailingAddress.postalCode);
      if (initialShipFromAddress.emailAddress) setEmail(initialShipFromAddress.emailAddress);
      if (initialShipFromAddress.homePhone) setNumber(initialShipFromAddress.homePhone);
      if (initialShipFromAddress.mailingAddress) {
        if (
          (initialShipFromAddress.firstName || initialShipFromAddress.lastName || returnsUps2DayCoco264) &&
          initialShipFromAddress.mailingAddress.address1 &&
          initialShipFromAddress.mailingAddress.city &&
          initialShipFromAddress.mailingAddress.stateOrProvince &&
          initialShipFromAddress.mailingAddress.postalCode &&
          (returnsRemovePhoneNumberCoco159 ||
            (!returnsRemovePhoneNumberCoco159 && (!initialShipFromAddress.homePhone || isNumberValid(initialShipFromAddress.homePhone))))
        )
          setInitialShipFromAddressValid(true);
      }
    }

    if (initialClientReturnType === 'integrated' && isShippingPostalCodeExists && returnsCustomField.enabled && returnsCustomField.required) {
      setCustomFieldError(
        intl.formatMessage(
          { id: 'RETURN.overview.review.form.customField.input.error.empty' },
          {
            article: isFirstLetterVowel(!!returnsCustomField.label ? returnsCustomField.label : ''),
            label: returnsCustomField.label?.toLowerCase()
          }
        )
      );
    }

    if (initialShipFromAddress) {
      if (!returnsUps2DayCoco264 && !initialShipFromAddress.firstName && !initialShipFromAddress.lastName)
        setFullNameError(intl.formatMessage({ id: 'RETURN.overview.review.form.shipFromAddress.fullName.input.error.empty' }));
      if (!initialShipFromAddress.mailingAddress || (initialShipFromAddress.mailingAddress && !initialShipFromAddress.mailingAddress.address1))
        setAddressOneError(intl.formatMessage({ id: 'RETURN.overview.review.form.shipFromAddress.addressOne.input.error.empty' }));
      if (!initialShipFromAddress.mailingAddress || (initialShipFromAddress.mailingAddress && !initialShipFromAddress.mailingAddress.city))
        setCityError(intl.formatMessage({ id: 'RETURN.overview.review.form.shipFromAddress.city.input.error.empty' }));
      if (!initialShipFromAddress.mailingAddress || (initialShipFromAddress.mailingAddress && !initialShipFromAddress.mailingAddress.stateOrProvince))
        setSelectedStateError(intl.formatMessage({ id: 'RETURN.overview.review.form.shipFromAddress.state.input.error.empty' }));
      if (!initialShipFromAddress.mailingAddress || (initialShipFromAddress.mailingAddress && !initialShipFromAddress.mailingAddress.postalCode))
        setZipCodeError(intl.formatMessage({ id: 'RETURN.overview.review.form.shipFromAddress.zipCode.input.error.empty' }));
      if (!returnsUps2DayCoco264 && !initialShipFromAddress.emailAddress)
        setEmailError(intl.formatMessage({ id: 'RETURN.overview.review.form.email.input.error.empty' }));
    }
  }, [
    initialShipFromAddress,
    returnsRemovePhoneNumberCoco159,
    returnsUps2DayCoco264,
    returnsCustomField,
    intl,
    initialClientReturnType,
    isShippingPostalCodeExists
  ]);

  useEffect(() => {
    if (dispositionSortCode) {
      if (dispositionSortCode.dispositionRuleType) setDispositionRuleType(dispositionSortCode.dispositionRuleType);
      if (dispositionSortCode.label) setSortCodeLabel(dispositionSortCode.label);
      if (dispositionSortCode.sortCodes) {
        setSortCodes(dispositionSortCode.sortCodes);
        if (dispositionSortCode.sortCodes[0]) setSortCodeSelection(dispositionSortCode.sortCodes[0].dispositionRuleLabel);
      }
    }
  }, [dispositionSortCode]);

  useEffect(() => {
    if (sortCodes) {
      const selectedSortCodeFilter = sortCodes.find((element: SortCodeType) => element.dispositionRuleLabel === sortCodeSelection);
      if (selectedSortCodeFilter) setSelectedSortCode(selectedSortCodeFilter);
    }
  }, [sortCodeSelection, sortCodes]);

  useEffect(() => {
    if (initialShipFromAddressValid) return setShowShippingFormAddressForm(false);
  }, [initialShipFromAddressValid]);

  const setValuesFromInitialShipFromAddressForForm = (): void => {
    if (returnsUps2DayCoco264) setFullName('AT&T');

    if (initialShipFromAddress) {
      if (!returnsUps2DayCoco264 && initialShipFromAddress.firstName) setFullName(initialShipFromAddress.firstName);
      if (!returnsUps2DayCoco264 && initialShipFromAddress.lastName)
        setFullName((prevState) => `${prevState ? prevState + ' ' : ''}${initialShipFromAddress.lastName}`);
      if (initialShipFromAddress.mailingAddress && initialShipFromAddress.mailingAddress.address1)
        setAddressOne(initialShipFromAddress.mailingAddress.address1);
      if (initialShipFromAddress.mailingAddress && initialShipFromAddress.mailingAddress.address2) {
        setAddressTwo(initialShipFromAddress.mailingAddress.address2);
      } else {
        setAddressTwo('');
      }

      if (initialShipFromAddress.mailingAddress && initialShipFromAddress.mailingAddress.city) setCity(initialShipFromAddress.mailingAddress.city);
      if (initialShipFromAddress.mailingAddress && initialShipFromAddress.mailingAddress.stateOrProvince)
        setSelectedState(initialShipFromAddress.mailingAddress.stateOrProvince);
      if (initialShipFromAddress.mailingAddress && initialShipFromAddress.mailingAddress.postalCode)
        setZipCode(initialShipFromAddress.mailingAddress.postalCode);
      if (initialShipFromAddress.homePhone) {
        setNumber(initialShipFromAddress.homePhone);
      } else {
        setNumber('');
      }
    }
    setFullNameError('');
    setAddressOneError('');
    setAddressTwoError('');
    setCityError('');
    setSelectedStateError('');
    setZipCodeError('');
    setNumberError('');
  };

  const value = useMemo<ReviewContextType>(
    () => ({
      orderLevelComment,
      setOrderLevelComment,
      orderLevelCommentError,
      setOrderLevelCommentError,

      customField,
      setCustomField,
      customFieldError,
      setCustomFieldError,

      fullName,
      setFullName,
      fullNameError,
      setFullNameError,

      addressOne,
      setAddressOne,
      addressOneError,
      setAddressOneError,

      addressTwo,
      setAddressTwo,
      addressTwoError,
      setAddressTwoError,

      city,
      setCity,
      cityError,
      setCityError,

      selectedState,
      setSelectedState,
      selectedStateError,
      setSelectedStateError,

      zipCode,
      setZipCode,
      zipCodeError,
      setZipCodeError,

      email,
      setEmail,
      emailError,
      setEmailError,

      number,
      setNumber,
      numberError,
      setNumberError,

      emailNotificationChecked,
      setEmailNotificationChecked,
      emailNotification,
      setEmailNotification,
      emailNotificationError,
      setEmailNotificationError,

      numberNotificationChecked,
      setNumberNotificationChecked,
      numberNotification,
      setNumberNotification,
      numberNotificationError,
      setNumberNotificationError,

      dispositionRuleType,
      sortCodeLabel,
      sortCodes,
      selectedSortCode,
      setSelectedSortCode,
      sortCodeSelection,
      setSortCodeSelection,

      initialShipFromAddressValid,
      setValuesFromInitialShipFromAddressForForm,
      showShippingFormAddressForm,
      setShowShippingFormAddressForm,

      areAllRequiredFieldsCorrect
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      orderLevelComment,
      orderLevelCommentError,
      customField,
      customFieldError,
      fullName,
      fullNameError,
      addressOne,
      addressOneError,
      addressTwo,
      addressTwoError,
      city,
      cityError,
      selectedState,
      selectedStateError,
      zipCode,
      zipCodeError,
      email,
      emailError,
      number,
      numberError,
      emailNotificationChecked,
      emailNotification,
      emailNotificationError,
      numberNotificationChecked,
      numberNotification,
      numberNotificationError,
      dispositionRuleType,
      sortCodeLabel,
      sortCodes,
      selectedSortCode,
      sortCodeSelection,
      initialShipFromAddressValid,
      showShippingFormAddressForm,
      areAllRequiredFieldsCorrect
    ]
  );

  return <ReviewContext.Provider value={value}>{children}</ReviewContext.Provider>;
};

interface ReviewContextProviderProps {
  children: ReactNode;
  initialShipFromAddress: ShipToType | null;
  dispositionSortCode: DispositionSortCodeType | null;
  returnsCustomField: ReturnsCustomFields;
  isShippingPostalCodeExists: boolean;
  initialClientReturnType: ReturnClientReturnType;
}

interface ReviewContextType {
  orderLevelComment: string;
  setOrderLevelComment: Dispatch<SetStateAction<string>>;
  orderLevelCommentError: string;
  setOrderLevelCommentError: Dispatch<SetStateAction<string>>;

  customField: string;
  setCustomField: Dispatch<SetStateAction<string>>;
  customFieldError: string;
  setCustomFieldError: Dispatch<SetStateAction<string>>;

  fullName: string;
  setFullName: Dispatch<SetStateAction<string>>;
  fullNameError: string;
  setFullNameError: Dispatch<SetStateAction<string>>;

  addressOne: string;
  setAddressOne: Dispatch<SetStateAction<string>>;
  addressOneError: string;
  setAddressOneError: Dispatch<SetStateAction<string>>;

  addressTwo: string;
  setAddressTwo: Dispatch<SetStateAction<string>>;
  addressTwoError: string;
  setAddressTwoError: Dispatch<SetStateAction<string>>;

  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  cityError: string;
  setCityError: Dispatch<SetStateAction<string>>;

  selectedState: string;
  setSelectedState: Dispatch<SetStateAction<string>>;
  selectedStateError: string;
  setSelectedStateError: Dispatch<SetStateAction<string>>;

  zipCode: string;
  setZipCode: Dispatch<SetStateAction<string>>;
  zipCodeError: string;
  setZipCodeError: Dispatch<SetStateAction<string>>;

  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  emailError: string;
  setEmailError: Dispatch<SetStateAction<string>>;

  number: string;
  setNumber: Dispatch<SetStateAction<string>>;
  numberError: string;
  setNumberError: Dispatch<SetStateAction<string>>;

  emailNotificationChecked: boolean;
  setEmailNotificationChecked: Dispatch<SetStateAction<boolean>>;
  emailNotification: string;
  setEmailNotification: Dispatch<SetStateAction<string>>;
  emailNotificationError: string;
  setEmailNotificationError: Dispatch<SetStateAction<string>>;

  numberNotificationChecked: boolean;
  setNumberNotificationChecked: Dispatch<SetStateAction<boolean>>;
  numberNotification: string;
  setNumberNotification: Dispatch<SetStateAction<string>>;
  numberNotificationError: string;
  setNumberNotificationError: Dispatch<SetStateAction<string>>;

  dispositionRuleType: DispositionRuleType;
  sortCodeLabel: string;
  sortCodes: SortCodeType[];
  selectedSortCode: SortCodeType | Record<string, never>;
  setSelectedSortCode: Dispatch<SetStateAction<SortCodeType | Record<string, never>>>;
  sortCodeSelection: string;
  setSortCodeSelection: Dispatch<SetStateAction<string>>;

  initialShipFromAddressValid: boolean;
  setValuesFromInitialShipFromAddressForForm: () => void;
  showShippingFormAddressForm: boolean;
  setShowShippingFormAddressForm: Dispatch<SetStateAction<boolean>>;

  areAllRequiredFieldsCorrect: boolean;
}
