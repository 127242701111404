import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const MethodsFormContainer = styled.form`
  width: 100%;
`;

export const MethodsFormGroup = styled.fieldset`
  padding: 0;
  border: 0;
  display: block;

  &:first-of-type {
    margin: 0 0 30px 0;
  }

  &:last-of-type {
    margin: 0 0 40px 0;
  }
`;

export const MethodsFormGroupTitle = styled.legend`
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 16px;
  display: none;
`;

export const MethodsFormGroupTitleModifier = styled.span`
  font-weight: 700;
`;

export const MethodsFormButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const MethodsError = styled.label`
  color: red;
  font-size: 12px;
`;
