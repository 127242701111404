import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Image = styled.img<{ height?: string; minHeight?: string; isBorder?: boolean }>`
  width: 100%;
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `}

  ${({ isBorder }) =>
    isBorder &&
    css`
      border: 1px solid #e1e1e1;
    `}
`;
