import { Breakpoint } from 'constants/Breakpoint';

export const sliderSettings = {
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: Breakpoint.medium,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        centerMode: false,
        arrows: true
      }
    },
    {
      breakpoint: Breakpoint.small,
      settings: {
        mobileFirst: true,
        slidesToShow: 1.1,
        slidesToScroll: 1,
        infinite: false,
        centerMode: false,
        arrows: false
      }
    }
  ]
};
