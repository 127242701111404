import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const QRCodeInfoContainer = styled.div`
  width: 100%;
  margin-bottom: 70px;

  @media ${({ theme }) => theme.largeUp} {
    margin-bottom: 0;
    width: 55%;
  }
`;

export const QRCodeInfoWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.returnsBorderColor};
  padding: 30px 15px;

  @media ${({ theme }) => theme.mediumUp} {
    padding: 40px 20px;
  }
`;

export const InstructionsList = styled.ul`
  margin-bottom: 40px;
`;

export const QRCodeInfoReminder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const QRCodeInfoIconContainer = styled.div`
  width: 29px;
  min-width: 25px;
  margin-right: 10px;
`;

export const QRCodeInfoIcon = styled.img`
  width: 100%;
`;

export const QRCodeInfoIconDescription = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
`;
