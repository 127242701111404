import React from 'react';
import { NotFoundContainer, NotFoundImage, NotFoundTip, NotFoundTitle, NotFoundWrapper } from './NotFound.styled';
import { createTheme, theme } from 'constants/theme';
import { GlobalStyles } from 'components/Shared/GlobalStyles/GlobalStyles';
import { Footer } from 'components/Shared/Footer/Footer';
import { ThemeProvider } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { ClientConfigurationType } from 'types/shared/config/client-configuration-type';
import { useIntl } from 'react-intl';
import { getFavIcons } from 'utils/common/favicons/favicons';
import { FaviconsModule } from 'types/tracking-types';
import PageTitle from 'components/Shared/PageTitle/PageTitle';
import { defaultFooterModule } from 'constants/default-footer-module';

export const NotFound: React.FC = () => {
  const intl = useIntl();

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <GlobalStyles isPreviewBasedOnRouteUrl={false} clientConfig={{} as ClientConfigurationType} theme={theme} />
      <PageTitle title={intl.formatMessage({ id: 'NOT_FOUND.pageTitle' })} brandName={null} />
      <Helmet>{getFavIcons({ useDefault: true } as FaviconsModule)}</Helmet>
      <NotFoundContainer data-test-id="not-found-page-container">
        <NotFoundWrapper>
          <NotFoundImage alt="404" src="/assets/img/404_animation.gif" />
          <NotFoundTitle>{intl.formatMessage({ id: 'NOT_FOUND.message' })}</NotFoundTitle>
          <NotFoundTip>{intl.formatMessage({ id: 'NOT_FOUND.tip' })}</NotFoundTip>
        </NotFoundWrapper>
      </NotFoundContainer>
      <Footer footerModule={defaultFooterModule} smsEnabled={false} />
    </ThemeProvider>
  );
};
