import React from 'react';
import { Helmet } from 'react-helmet';

const PageTitle: React.FC<OwnProps> = (props) => {
  const { title, brandName } = props;

  return (
    <Helmet>
      <title>
        {title}
        {brandName ? ' - ' + brandName : ''}
      </title>
    </Helmet>
  );
};

export default PageTitle;

export interface OwnProps {
  title: string;
  brandName: string | null;
}
