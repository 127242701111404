import React, { Dispatch, PropsWithChildren, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { OverviewFormStepsEnum } from 'types/return/overview/overview-api-response-type';

export enum AlertErrorTitle {
  PRODUCTS_SELECTION_VALIDATION = 'RETURN.overview.productsSelection.form.error.validation',
  REVIEW_VALIDATION = 'RETURN.overview.review.form.error.validation',
  OVERVIEW_SERVER_ERROR_LABEL = 'RETURN.overview.form.error.serverError.label',
  OVERVIEW_SERVER_ERROR_QR_CODE = 'RETURN.overview.form.error.serverError.qrcode',
  OVERVIEW_VALIDATION_LABEL = 'RETURN.overview.form.error.validation.label',
  OVERVIEW_VALIDATION_QR_CODE = 'RETURN.overview.form.error.validation.qrcode'
}

export const OverviewContext = React.createContext<OverviewContextType>({
  step: OverviewFormStepsEnum.Zero,
  setStep: () => {},
  isFromSearchOrder: true,
  formError: null,
  setFormError: () => {},
  formErrorsList: [],
  setFormErrorsList: () => {}
});

export const OverviewContextProvider: React.FC<OverviewProviderType> = (props: PropsWithChildren<OverviewProviderType>) => {
  const { children, initialStep, initialIsFromSearchOrder } = props;

  const [isFromSearchOrder] = useState<boolean>(initialIsFromSearchOrder);
  const [step, setStep] = useState<OverviewFormStepsEnum>(initialStep);
  // errorAlerts
  const [formErrorsList, setFormErrorsList] = useState<string[]>([]);
  const [formError, setFormError] = useState<AlertErrorTitle | null>(null);

  useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  const value = useMemo<OverviewContextType>(
    () => ({
      step,
      setStep,
      isFromSearchOrder,
      formError,
      setFormError,
      formErrorsList,
      setFormErrorsList
    }),
    [step, isFromSearchOrder, formError, formErrorsList]
  );

  return <OverviewContext.Provider value={value}>{children}</OverviewContext.Provider>;
};

export interface OverviewContextType {
  step: OverviewFormStepsEnum;
  setStep: Dispatch<SetStateAction<OverviewFormStepsEnum>>;
  isFromSearchOrder: boolean;
  formError: AlertErrorTitle | null;
  setFormError: Dispatch<SetStateAction<AlertErrorTitle | null>>;
  formErrorsList: string[];
  setFormErrorsList: Dispatch<SetStateAction<string[]>>;
}

interface OverviewProviderType {
  children: ReactNode;
  initialStep: OverviewFormStepsEnum;
  initialIsFromSearchOrder: boolean;
}
