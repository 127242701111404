import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { ColumnTypeEnum } from 'types/customer-service/column-type-enum';
import { SortByParcelType } from 'types/customer-service/sort-by-parcel-type';
import { getOrderByValue } from 'utils/customer-service/get-order-by-value/get-order-by-value';
import { IntlShape } from 'react-intl';
import { SortDirection } from '@mui/material';

export const sortParcelsByTableColumn = (parcels: Parcel[], sortBy: SortByParcelType, sortType: SortDirection, intl: IntlShape): Parcel[] => {
  const descendingComparator = (a: Parcel, b: Parcel, orderByValue: SortByParcelType) => {
    let orderBy = orderByValue;
    if (!orderBy) orderBy = ColumnTypeEnum.TrackingNumber;

    const orderByValueA = getOrderByValue(a, orderBy, intl.formatMessage({ id: a.parcelDetails.primaryTextKey }));
    const orderByValueB = getOrderByValue(b, orderBy, intl.formatMessage({ id: b.parcelDetails.primaryTextKey }));

    if (!orderByValueA) return 1;
    if (!orderByValueB) return -1;

    if (orderByValueB < orderByValueA) return -1;
    if (orderByValueB > orderByValueA) return 1;

    return 0;
  };

  const sortTable: Parcel[] = parcels
    .slice()
    .sort((a: Parcel, b: Parcel) => (sortType === 'desc' ? descendingComparator(a, b, sortBy) : -descendingComparator(a, b, sortBy)));

  return sortTable;
};
