import React, { Dispatch, PropsWithChildren, SetStateAction, useContext } from 'react';
import {
  IEPDFSupport,
  LabelPDFContainer,
  NewgisticsContainer,
  NewgisticsImage,
  NewgisticsImageWrapper,
  NewgisticsInstructionsContainer,
  NewgisticsInstructionsParagraph,
  StepList,
  StepListItem,
  StepListTitle,
  StyledDocument
} from './LabelPDF.styled';
import { isIE } from 'react-device-detect';
import { Loading } from 'components/Shared/Loading/Loading';
import { Page, pdfjs } from 'react-pdf';
import { useIntl } from 'react-intl';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';

export const LabelPDF: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { setPdfError, sapiLabel, newgisticsLabel } = props;
  const { brandId } = useContext(ClientConfigContext);

  const intl = useIntl();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <LabelPDFContainer data-test-id="label-pdf-container">
      {sapiLabel ? (
        !isIE ? (
          <StyledDocument
            data-test-id="pdf-content"
            file={{
              url: sapiLabel,
              httpHeaders: {
                'X-BRAND-ID': brandId
              }
            }}
            loading={<Loading height="595px" />}
            onLoadError={() => setPdfError(true)}
            error={<Loading height="400px" />}>
            <Page pageNumber={1} />
          </StyledDocument>
        ) : (
          <IEPDFSupport data-test-id="pdf-content-ie-support" src={sapiLabel ? sapiLabel : ''} />
        )
      ) : (
        <NewgisticsContainer data-test-id="newgistics-container">
          <NewgisticsImageWrapper>
            <NewgisticsImage
              onError={() => setPdfError(true)}
              data-test-id="returns-label-preview-label-img"
              src={newgisticsLabel ? newgisticsLabel : ''}
              alt="shipping label"
            />
          </NewgisticsImageWrapper>
          <NewgisticsInstructionsContainer data-test-id="returns-label-preview-label-instructions">
            <NewgisticsInstructionsParagraph data-test-id="returns-label-preview-label-paragraph-1">
              {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelPdf.instruction.paragraph1' })}
            </NewgisticsInstructionsParagraph>
            <StepListTitle data-test-id="returns-label-preview-label-step-list-title">
              {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelPdf.instruction.stepsListTitle' })}
            </StepListTitle>
            <StepList>
              <StepListItem data-test-id="returns-label-preview-label-step-1">
                {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelPdf.instruction.stepsList.step1' })}
              </StepListItem>
              <StepListItem data-test-id="returns-label-preview-label-step-2">
                {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelPdf.instruction.stepsList.step2' })}
              </StepListItem>
              <StepListItem data-test-id="returns-label-preview-label-step-3">
                {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelPdf.instruction.stepsList.step3' })}
              </StepListItem>
              <StepListItem data-test-id="returns-label-preview-label-step-4">
                {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelPdf.instruction.stepsList.step4' })}
              </StepListItem>
            </StepList>
            <NewgisticsInstructionsParagraph data-test-id="returns-label-preview-label-paragraph-2">
              {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelPdf.instruction.paragraph2' })}
            </NewgisticsInstructionsParagraph>
            <NewgisticsInstructionsParagraph data-test-id="returns-label-preview-label-paragraph-3">
              {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelPdf.instruction.paragraph3' })}
            </NewgisticsInstructionsParagraph>
          </NewgisticsInstructionsContainer>
        </NewgisticsContainer>
      )}
    </LabelPDFContainer>
  );
};

export interface OwnProps {
  setPdfError: Dispatch<SetStateAction<boolean>>;
  sapiLabel: string | null;
  newgisticsLabel: string | null;
}
