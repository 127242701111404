import { FooterTerm } from 'types/shared/footer/footer-term-type';

export const footerTerms = (smsEnabled: boolean): FooterTerm[] => {
  return [
    {
      intlText: 'SHARED.footer.terms.smsNotification',
      id: 'footer-sms-notification',
      enabled: smsEnabled,
      url: 'https://www.pitneybowes.com/us/consumer-connect-sms-terms.html',
      secondUrl: '/docs/consumer-connect-sms-terms',
      ariaLabel: 'footer-sms-notification-terms-item'
    },
    {
      intlText: 'SHARED.footer.terms.privacy',
      id: 'footer-privacy-link',
      enabled: true,
      url: 'https://www.pitneybowes.com/us/legal/privacy-statement.html',
      secondUrl: '/docs/privacy-statement',
      ariaLabel: 'footer-privacy-terms-item'
    },
    {
      intlText: 'SHARED.footer.terms.termOfUse',
      id: 'footer-term-of-use-link',
      enabled: true,
      url: 'https://www.pitneybowes.com/us/consumer-connect-tou.html',
      secondUrl: '/docs/consumer-connect-tou',
      ariaLabel: 'footer-term-of-use-terms-item'
    },
    {
      intlText: 'SHARED.footer.terms.cookiesPolicy',
      id: 'footer-cookies-policy',
      enabled: true,
      url: '/docs/cookies-policy',
      secondUrl: '/docs/cookies-policy',
      ariaLabel: 'footer-cookies-policy'
    }
  ];
};
