import { FindParcelsApiResponseSuccess, Parcel, ParcelStatusEnum } from 'types/customer-service/find-parcels-api-response-type';
import { whetherDisplayEDDTime } from '../whether-display-edd-time/whether-display-edd-time';

export interface EDDDisplayStatuses {
  EDD: number;
  nonEDD: number;
  EDDWithTime: number;
}

export const calculateEDDDisplayStatuses = (parcels: FindParcelsApiResponseSuccess): EDDDisplayStatuses => {
  const initialStatuses: EDDDisplayStatuses = {
    nonEDD: 0,
    EDD: 0,
    EDDWithTime: 0
  };

  return parcels.reduce((acc: EDDDisplayStatuses, currentParcel: Parcel) => {
    // edd =+ 1 because for out for delivery status we display edd as todays date
    if (
      !!currentParcel.parcelDetails.deliveryDetails &&
      !currentParcel.parcelDetails.deliveryDetails.estimatedDeliveryDate &&
      currentParcel.parcelDetails.parcelStatus === ParcelStatusEnum.OUT_FOR_DELIVERY
    ) {
      return {
        ...acc,
        EDD: acc.EDD += 1
      };
    }
    if (
      !currentParcel.parcelDetails.deliveryDetails ||
      (!!currentParcel.parcelDetails.deliveryDetails && !currentParcel.parcelDetails.deliveryDetails.estimatedDeliveryDate) ||
      currentParcel.parcelDetails.parcelStatus === ParcelStatusEnum.DELIVERED ||
      currentParcel.parcelDetails.parcelStatus === ParcelStatusEnum.RETURN_RECEIVED ||
      currentParcel.parcelDetails.parcelStatus === ParcelStatusEnum.DELIVERY_EXCEPTION ||
      currentParcel.parcelDetails.parcelStatus === ParcelStatusEnum.RETURN_DELIVERY_EXCEPTION ||
      currentParcel.parcelDetails.parcelStatus === ParcelStatusEnum.UNKNOWN ||
      currentParcel.parcelDetails.parcelStatus === ParcelStatusEnum.RETURN_UNKNOWN ||
      currentParcel.parcelDetails.parcelStatus === ParcelStatusEnum.TIMEOUT ||
      currentParcel.parcelDetails.parcelStatus === ParcelStatusEnum.NOT_FOUND
    )
      return {
        ...acc,
        nonEDD: acc.nonEDD += 1
      };
    if (
      !!currentParcel.parcelDetails.deliveryDetails &&
      !!currentParcel.parcelDetails.deliveryDetails.estimatedDeliveryDate &&
      whetherDisplayEDDTime(currentParcel.parcelDetails.deliveryDetails.estimatedDeliveryDate)
    )
      return {
        ...acc,
        EDDWithTime: acc.EDDWithTime += 1
      };
    return {
      ...acc,
      EDD: acc.EDD += 1
    };
  }, initialStatuses);
};
