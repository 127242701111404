import React from 'react';
import { EllipseText } from './EllipseColumnText.styled';
import { CustomTableCell } from 'components/Shared/styled-components/CustomTableCell/CustomTableCell.styled';

export const EllipseColumnText = (props: OwnProps) => {
  const { text, numberOfSuffixChar, ariaLabel, isUppercase } = props;

  if (numberOfSuffixChar === 0)
    return (
      <CustomTableCell
        paddingDefault="19px 10px 10px 5px"
        widthDefault="25%"
        widthXL="20%"
        fontSizeDefault="12px"
        fontSizeXL="14px"
        shouldOneLineDisplay
        aria-label={ariaLabel}>
        <EllipseText isTextCut={false} isUppercase={isUppercase}>
          {text}
        </EllipseText>
      </CustomTableCell>
    );

  return (
    <CustomTableCell
      paddingDefault="19px 10px 10px 5px"
      widthDefault="25%"
      widthXL="20%"
      fontSizeDefault="12px"
      fontSizeXL="14px"
      shouldOneLineDisplay
      aria-label={ariaLabel}>
      <EllipseText isTextCut={true} isUppercase={isUppercase}>
        {text.slice(0, text.length - numberOfSuffixChar)}
      </EllipseText>
      <EllipseText isTextCut={true} isUppercase={isUppercase}>
        {text.slice(-numberOfSuffixChar)}
      </EllipseText>
    </CustomTableCell>
  );
};
export interface OwnProps {
  text: string;
  numberOfSuffixChar: number;
  ariaLabel: string;
  isUppercase: boolean;
}
