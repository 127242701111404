import React, { PropsWithChildren } from 'react';
import { PostOfficeListItemContainer, PostOfficeItemTitle, HoursList, HoursWrapper, HoursListTitle } from './PostOfficeListItem.styled';
import { HoursListItem } from './HoursListItem/HoursListItem';
import { useIntl } from 'react-intl';
import { CarrierSuggestionAddress, CarrierSuggestionFacilityHours } from 'types/return/return-info/return-info-api-response-type';

export const PostOfficeListItem: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { address, carrierFacilityHours } = props;
  const { addressLines, cityTown, stateProvince, postalCode } = address;

  const intl = useIntl();

  return (
    <PostOfficeListItemContainer data-test-id="post-office-item-container">
      <PostOfficeItemTitle data-test-id="post-office-item-title">
        {intl.formatMessage(
          { id: 'RETURN.returnInfo.qrCode.qrCodeInfo.instructionsList.instruction.postOfficeTitle' },
          { address: addressLines.toString(), city: cityTown, state: stateProvince, zipCode: postalCode }
        )}
      </PostOfficeItemTitle>
      {carrierFacilityHours && (
        <HoursWrapper>
          <HoursListTitle data-test-id="post-office-hours-list-title">
            {intl.formatMessage({ id: 'RETURN.returnInfo.qrCode.qrCodeInfo.instructionsList.instruction.postOffice.hoursTitle' })}
          </HoursListTitle>
          <HoursList data-test-id="post-office-hours-list">
            {carrierFacilityHours.map((element: CarrierSuggestionFacilityHours, index: number) => (
              <HoursListItem key={index} days={element.days} hours={element.hours} />
            ))}
          </HoursList>
        </HoursWrapper>
      )}
    </PostOfficeListItemContainer>
  );
};

export interface OwnProps {
  address: CarrierSuggestionAddress;
  carrierFacilityHours: CarrierSuggestionFacilityHours[];
}
