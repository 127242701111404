import React, { PropsWithChildren } from 'react';
import {
  QRCodeAdditionalInfoContainer,
  QRCodeAdditionalInfoLink,
  QRCodeAdditionalInfoParagraph,
  QRCodeAdditionalModifier
} from './QRCodeAdditionalInfo.styled';
import { useIntl } from 'react-intl';

export const QrCodeAdditionalInfo: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { qrCodeId, trackingUrl } = props;

  const intl = useIntl();

  return (
    <QRCodeAdditionalInfoContainer data-test-id="qr-code-additional-info-container">
      <QRCodeAdditionalInfoParagraph data-test-id="qr-code-additional-info-paragraph-1">
        {intl.formatMessage(
          { id: 'RETURN.returnInfo.qrCode.qrCodeAdditionalInfo.trackPackage' },
          {
            link: (
              <QRCodeAdditionalInfoLink
                data-test-id="qr-code-additional-info-track-package-link"
                key={1}
                href={trackingUrl ? trackingUrl : ''}
                target="_blank"
                data-gtm-id="qr-track-package">
                {intl.formatMessage({ id: 'RETURN.returnInfo.qrCode.qrCodeAdditionalInfo.trackPackage.link' })}
              </QRCodeAdditionalInfoLink>
            )
          }
        )}
      </QRCodeAdditionalInfoParagraph>
      {qrCodeId && (
        <QRCodeAdditionalInfoParagraph data-test-id="qr-code-additional-info-paragraph-2">
          {intl.formatMessage(
            { id: 'RETURN.returnInfo.qrCode.qrCodeAdditionalInfo.checkLabel' },
            {
              link: (
                <QRCodeAdditionalInfoLink key={1} href="https://tools.usps.com/label-broker.htm" target="_blank" data-gtm-id="usps-label-broker">
                  {intl.formatMessage({ id: 'RETURN.returnInfo.qrCode.qrCodeAdditionalInfo.checkLabel.link' })}
                </QRCodeAdditionalInfoLink>
              ),
              code: <QRCodeAdditionalModifier key={2}>{qrCodeId}</QRCodeAdditionalModifier>
            }
          )}
        </QRCodeAdditionalInfoParagraph>
      )}
    </QRCodeAdditionalInfoContainer>
  );
};

export interface OwnProps {
  qrCodeId: string | null;
  trackingUrl: string | null;
}
