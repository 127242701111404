import { Environment, resolveEnvironment } from 'utils/common/environment-resolver/environment-resolver';

export const buildTrackingPageLink = (vanityName: string, trackingNumber: string): string => {
  const ENV: Environment = resolveEnvironment();

  const domain = window.location.href;
  if (domain.includes('shipment.co')) return `https://${vanityName}.shipment.co/track/${trackingNumber}`;

  switch (ENV) {
    case Environment.LOCAL:
      return `http://localhost:3000/tracking/${vanityName}/${trackingNumber}`;
    case Environment.AWS_DEV:
      return `https://${vanityName}.shipment-dev.co/track/${trackingNumber}`;
    case Environment.AWS_PPD:
      return `https://${vanityName}.shipment-demo.co/track/${trackingNumber}`;
    case Environment.AWS_PRD:
      return `https://${vanityName}.shipment-prd.co/track/${trackingNumber}`;
  }
};
