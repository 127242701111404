import React, { ReactNode, useEffect, useMemo, useState } from 'react';

export const UrlKeyContext = React.createContext<UrlKeyContextType>({
  urlKey: ''
});

export const UrlKeyProvider: React.FC<UrlKeyProviderPropsType> = (props) => {
  const { children, urlKeyProp } = props;

  const [urlKey, setUrlKey] = useState<string>('');

  useEffect(() => {
    setUrlKey(urlKeyProp);
  }, [urlKeyProp]);

  const value = useMemo<UrlKeyContextType>(
    () => ({
      urlKey
    }),
    [urlKey]
  );

  return <UrlKeyContext.Provider value={value}>{children}</UrlKeyContext.Provider>;
};

interface UrlKeyContextType {
  urlKey: string;
}

interface UrlKeyProviderPropsType {
  children: ReactNode;
  urlKeyProp: string;
}
