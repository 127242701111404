import React from 'react';
import { SectionDescriptionContainer, SectionDescriptionTitle, SectionDescriptionSubtitle } from './SectionDescription.styled';

export const SectionDescription: React.FC<OwnProps> = (props) => {
  const { title, subtitle } = props;
  return (
    <SectionDescriptionContainer data-test-id="section-description-container">
      <SectionDescriptionTitle data-test-id="section-description-title">{title}</SectionDescriptionTitle>
      {!!subtitle && <SectionDescriptionSubtitle data-test-id="section-description-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />}
    </SectionDescriptionContainer>
  );
};

export interface OwnProps {
  title: string;
  subtitle?: string;
}
