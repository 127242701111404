import React from 'react';
import { ReviewFormGroup } from 'components/Returns/Overview/Review/ReviewForm/ReviewForm.styled';
import { Container, AuthenticatedReturnsFormCustomFormControlLabel } from '../AuthenticatedReturnsForm/AuthenticatedReturnsForm.styled';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SkeletonCheckboxWrapper } from './AuthenticatedReturnsFormSkeletons.styled';

export const AuthenticatedReturnsFormSkeletons: React.FC = () => {
  const theme = useTheme();
  const xxLargeUp = useMediaQuery(theme.breakpoints.up('xxlarge'));

  return (
    <Container noValidate data-test-id="authenticated-returns-form-skeleton" displayZipCodeTextField={true}>
      <ReviewFormGroup justifyContent="space-between" margin="0 0 20px 0">
        <Skeleton variant="rectangular" width={144} height={26} sx={{ borderRadius: '4px' }} />

        <AuthenticatedReturnsFormCustomFormControlLabel
          control={
            <SkeletonCheckboxWrapper>
              <Skeleton variant="rectangular" width={20} height={20} sx={{ borderRadius: '4px' }} />
            </SkeletonCheckboxWrapper>
          }
          label={<Skeleton variant="rectangular" width={85} height={24} sx={{ margin: '0 0 0 20px', borderRadius: '4px' }} />}
        />
      </ReviewFormGroup>
      <ReviewFormGroup justifyContent="space-between" alignItems="flex-start">
        <Skeleton variant="rectangular" width={150} height={56} sx={{ borderRadius: '4px' }} />
        <Skeleton variant="rectangular" width={150} height={56} sx={{ margin: xxLargeUp ? ' 0 0 0 15px' : ' 0 0 0 20px', borderRadius: '4px' }} />
        <Skeleton variant="rectangular" width={150} height={56} sx={{ margin: xxLargeUp ? ' 0 0 0 15px' : ' 0 0 0 20px', borderRadius: '4px' }} />
      </ReviewFormGroup>
    </Container>
  );
};
