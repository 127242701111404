import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import {
  ExchangeOptionContainer,
  ExchangeOptionDetails,
  ExchangeOptionDetailsWrapper,
  ExchangeOptionImageContainer,
  ExchangeOptionName,
  ExchangeOptionSku,
  ExchangeOptionTextModifier,
  ExchangeOptionWrapper
} from './ExchangeOption.styled';
import { ImageWithFallback } from 'components/Shared/ImageWithFallback/ImageWithFallback';
import { Collapse, useMediaQuery, Skeleton } from '@mui/material';
import { AttributesList } from './AttributesList/AttributesList';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { Alert } from 'components/Shared/Alert/Alert';
import { useTheme } from '@mui/material/styles';
import { SelectedProducts, SelectedProductsErrors, ProductsSelectionContext } from 'context/returns/ProductsSelectionContext';
import { createProductApiUrl } from 'utils/returns/overview/products-selection/create-product-api-url/create-product-api-url';
import { convertAttributesSelectedOptionsObjectsToArray } from 'utils/returns/overview/products-selection/convert-attributes-selected-options-objects-to-array/convert-attributes-selected-options-objects-to-array';
import { AttributesSelectedOption } from 'types/return/overview/products-selection/products-selection-form/attributes-selected-option';
import { useIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { transformAttributesOptionsToStringArray } from 'utils/returns/overview/products-selection/transform-attributes-options-to-string-array/transform-attributes-options-to-string-array';

export const ExchangeOption: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { rootProductSKU, rootProductAttributesValues, rootProductId, exchangeOptionIndex, orderNumber } = props;
  const { selectedProducts, setSelectedProducts, selectedProductsErrors, setSelectedProductsErrors } = useContext(ProductsSelectionContext);

  const [attributesSelectedOptions, setAttributesSelectedOptions] = useState<AttributesSelectedOption[]>(
    convertAttributesSelectedOptionsObjectsToArray(selectedProducts, rootProductId, exchangeOptionIndex)
  );

  const [RETURN_PRODUCT_API, setRETURN_PRODUCT_API] = useState<string>(
    createProductApiUrl(orderNumber, rootProductSKU, rootProductAttributesValues, attributesSelectedOptions)
  );

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [fetchAgainToggle, setFetchAgainToggle] = useState<boolean>(false);
  const { addAttributeOrderingToExchangesCoco782 } = useFlags();

  const { brandId } = useContext(ClientConfigContext);
  const theme = useTheme();
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));
  const intl = useIntl();

  useEffect(() => {
    setAttributesSelectedOptions(convertAttributesSelectedOptionsObjectsToArray(selectedProducts, rootProductId, exchangeOptionIndex));
  }, [exchangeOptionIndex, selectedProducts, rootProductId]);

  useEffect(() => {
    setRETURN_PRODUCT_API(createProductApiUrl(orderNumber, rootProductSKU, rootProductAttributesValues, attributesSelectedOptions));
  }, [orderNumber, rootProductSKU, rootProductAttributesValues, attributesSelectedOptions]);

  useEffect(() => {
    // this condition is required because for instance when we change quantity from 2 to 1 the old instance before it is utilized
    // send a fetch an fill up the context exchangeProducts with old value. When we move on reviewStep it causes app crash because the data
    // is not compatible
    if (exchangeOptionIndex + 1 <= selectedProducts[rootProductId].quantity)
      (async () => {
        const fetchHeaders = {
          headers: {
            'Content-Type': 'application/json',
            'Content-Encoding': 'gzip'
          }
        };

        const params = enhanceFetchParams({
          method: 'GET',
          headers: enhanceHeadersParams(brandId, fetchHeaders)
        });

        try {
          setIsDataLoading(true);

          const response: Response = await fetch(RETURN_PRODUCT_API, params);

          const data = await response.json();

          if (data.errorTitle) {
            return setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
              return {
                ...prevState,
                [rootProductId]: {
                  ...prevState[rootProductId],
                  exchangeOptionsAttributesFetchDataError: {
                    ...prevState[rootProductId].exchangeOptionsAttributesFetchDataError,
                    [exchangeOptionIndex]: {
                      error: intl.formatMessage({ id: data.errorTitle })
                    }
                  }
                }
              };
            });
          }

          setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
            return {
              ...prevState,
              [rootProductId]: {
                ...prevState[rootProductId],
                exchangeOptionsAttributesFetchDataError: {
                  ...prevState[rootProductId].exchangeOptionsAttributesFetchDataError,
                  [exchangeOptionIndex]: {
                    error: null
                  }
                }
              }
            };
          });
          setSelectedProducts((prevState: SelectedProducts) => {
            return {
              ...prevState,
              [rootProductId]: {
                ...prevState[rootProductId],
                exchangeProducts: {
                  ...prevState[rootProductId].exchangeProducts,
                  [exchangeOptionIndex]: {
                    sku: data.sku,
                    quantity: 1,
                    exchangeProductImageUrl: data.imageUrl,
                    name: data.name,
                    attributes: transformAttributesOptionsToStringArray(data.attributes, addAttributeOrderingToExchangesCoco782)
                  }
                }
              }
            };
          });
        } catch (e) {
          setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
            return {
              ...prevState,
              [rootProductId]: {
                ...prevState[rootProductId],
                exchangeOptionsAttributesFetchDataError: {
                  ...prevState[rootProductId].exchangeOptionsAttributesFetchDataError,
                  [exchangeOptionIndex]: {
                    error: intl.formatMessage({
                      id:
                        'RETURN.overview.productsSelection.productsSelectionForm.product.exchangeOptionsList.exchangeOptionsListItem.alert.error.500'
                    })
                  }
                }
              }
            };
          });
        } finally {
          setIsDataLoading(false);
        }
      })();
    // we have to refetch component even if instance in context was created for it to fill up exchangeProducts --> attributes array
    // because every quantity change it is reset to an empty array in Exchange component --> handle quantity change fn
    // react do some kind of optimalization to do not rerender component hence, it does not fetch data to fill up attributes array
    // so selectedProducts[rootProductId].quantity dependency is necessary in this case.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RETURN_PRODUCT_API, exchangeOptionIndex, fetchAgainToggle, rootProductId, brandId, selectedProducts[rootProductId].quantity]);

  const handleToggleFetchAgain = () => {
    setFetchAgainToggle((prevState: boolean) => !prevState);
    window.dataLayer.push({
      event: 'exchangesExchangeOptionTryAgainButton',
      exchangesExchangeRootProductSKU: rootProductSKU
    });
  };

  return (
    <ExchangeOptionContainer data-test-id="exchange-option">
      <ExchangeOptionWrapper>
        <ExchangeOptionImageContainer>
          {isDataLoading ? (
            <Skeleton variant="rectangular" width="100%" height="140px" />
          ) : (
            <ImageWithFallback
              isBorder
              minHeight="140px"
              src={
                selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex] &&
                !!selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].exchangeProductImageUrl
                  ? (selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].exchangeProductImageUrl as string)
                  : ''
              }
              fallbackSrc="/assets/img/image-placeholder-framed.svg"
              alt="test"
              height="auto"
            />
          )}
        </ExchangeOptionImageContainer>
        <ExchangeOptionDetails>
          <ExchangeOptionDetailsWrapper>
            {isDataLoading ? (
              <Skeleton width="100%" height="17px" />
            ) : (
              <ExchangeOptionName>
                <ExchangeOptionTextModifier>
                  {selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex] &&
                  selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].name
                    ? selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].name
                    : ''}
                </ExchangeOptionTextModifier>
              </ExchangeOptionName>
            )}
            {isDataLoading ? (
              <Skeleton width="40%" height="17px" />
            ) : (
              <ExchangeOptionSku>
                {selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex] &&
                selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].sku
                  ? selectedProducts[rootProductId].exchangeProducts[exchangeOptionIndex].sku
                  : ''}
              </ExchangeOptionSku>
            )}
          </ExchangeOptionDetailsWrapper>
          {largeUp && (
            <AttributesList
              isDataLoading={isDataLoading}
              rootProductId={rootProductId}
              rootProductAttributesValues={rootProductAttributesValues}
              setAttributesSelectedOptions={setAttributesSelectedOptions}
              exchangeOptionIndex={exchangeOptionIndex}
            />
          )}
        </ExchangeOptionDetails>
      </ExchangeOptionWrapper>
      {!largeUp && (
        <AttributesList
          isDataLoading={isDataLoading}
          rootProductId={rootProductId}
          rootProductAttributesValues={rootProductAttributesValues}
          setAttributesSelectedOptions={setAttributesSelectedOptions}
          exchangeOptionIndex={exchangeOptionIndex}
        />
      )}
      {!isDataLoading &&
        !!selectedProductsErrors[rootProductId].exchangeOptionsAttributesFetchDataError[exchangeOptionIndex] &&
        !!selectedProductsErrors[rootProductId].exchangeOptionsAttributesFetchDataError[exchangeOptionIndex].error &&
        typeof selectedProductsErrors[rootProductId].exchangeOptionsAttributesFetchDataError[exchangeOptionIndex].error === 'string' && (
          <Collapse in={!!selectedProductsErrors[rootProductId].exchangeOptionsAttributesFetchDataError[exchangeOptionIndex].error}>
            <Alert
              message={selectedProductsErrors[rootProductId].exchangeOptionsAttributesFetchDataError[exchangeOptionIndex].error as string}
              marginSm="30px 0 0 0"
              btnMethod={handleToggleFetchAgain}
              btnMessage={intl.formatMessage({
                id:
                  'RETURN.overview.productsSelection.productsSelectionForm.product.exchangeOptionsList.exchangeOptionsListItem.alert.error.button.tryFetchAgain'
              })}
            />
          </Collapse>
        )}
    </ExchangeOptionContainer>
  );
};

export interface OwnProps {
  orderNumber: string;
  rootProductImageUrl: string | undefined;
  rootProductName: string;
  rootProductSKU: string;
  rootProductAttributesValues: string[];
  rootProductId: string;
  exchangeOptionIndex: number;
}
