import React from 'react';
import { TableBody } from '@mui/material';
import { TrackingEvent } from 'types/customer-service/find-parcels-api-response-type';
import { SingleEventRow } from './SingleEventRow/SingleEventRow';

export const TrackingEventsTableBody = (props: OwnProps) => {
  const { trackingEvents } = props;

  return (
    <TableBody>
      {trackingEvents.map((trackingEvent: TrackingEvent) => {
        return <SingleEventRow key={trackingEvent.eventId} trackingEvent={trackingEvent} />;
      })}
    </TableBody>
  );
};

export interface OwnProps {
  trackingEvents: TrackingEvent[];
}
