import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { enhanceFetchParams, enhanceHeadersParams1 } from 'utils/common/utils/utils';

export default function Terms() {
  return (
    <div>
      <h6>1) Acceptance of Terms</h6>
      <p>
        Welcome to Pitney Bowes Track and Feel program called Gift Tracker (&quot;Gift Tracker&quot;). Pitney Bowes provides its website and related
        services (&quot;Site&quot;) to you subject to the following User Agreement and Terms and Conditions (&quot;Agreement&quot;), which may be
        updated from time to time without notice to you. Your continued use of the Gift Tracker Site after any such changes constitutes your
        acceptance of the new terms. If you have any questions about the Agreement, you may contact us at gifttracker@pb.com
      </p>
      <p>BY CONTINUING TO USE THE SITE, YOU ARE INDICATING YOUR AGREEMENT TO BE BOUND BY THE TERMS OF THIS AGREEMENT AND ALL REVISIONS THEREOF.</p>
      <h6>2) User Guidelines and Conduct</h6>
      <p>In using this Site, you agree:</p>
      <p>
        a) To not upload, download, post, email or otherwise transmit any materials including but not limited to text, data, photos, graphics, or any
        of these elements in combination as a design for products available on this Site or otherwise (&quot;Content&quot; and “Content Restrictions”)
        that:
      </p>
      <p>
        Is content or images, whether appearing individually or in combination with other content or images, that may cause the Gift Tracking program
        to become a public forum, including but not limited to: content or images actively advocating or disparaging the religious, political, or
        legal agenda of any person or entity, including but not limited to content or images designed to influence a specific piece of legislation;
        partisan or political content or images, including but not limited to content or images supporting or opposing election of any candidate(s) to
        any federal/state/local governmental office or supporting or opposing any referendum conducted by federal/state/local government; or content
        or images related to human reproduction or sexuality, including but not limited to content or images related to services or counseling with
        regard to pregnancy, abortion, or other sexual matter;
      </p>
      <p>
        Incorporates a celebrity or other famous person&apos;s name or likeness, whether living or deceased, including television and movie actors and
        personalities, fashion or print models, musicians, and collegiate or professional athletes;
      </p>
      <p>
        Incorporates the name or likeness of any current or former world leader or politician, or any local, regional, national or international
        leader, religious figurehead, or politician, whether living or deceased;
      </p>
      <p>
        Parodies an underlying copyright or trademark including well recognized brands, logos, titles, or phrases or includes corporate names,
        trademarks, products, images, text or designs;
      </p>
      <p>
        Is any material for any unlawful purpose or that is obscene, offensive, cruel, blasphemous, pornographic, sexually suggestive, deceptive,
        profane, threatening, menacing, abusive, harmful, an invasion of privacy, supportive of unlawful action, patently offensive, defamatory,
        slanderous, libelous, vulgar, violent, discriminatory (for example, based on race, color, religion, gender, sexual orientation, age, national
        origin, ancestry, or physical/mental ability), negatively pertaining to any particular individual or otherwise objectionable, or that
        advocates any of the aforementioned impermissible messaging;
      </p>
      <p>Is content or images containing non-incidental depictions of: firearms, ammunition, or gambling;</p>
      <p>
        Is content or images depicting: illegal activities, illegal substances, paraphernalia designed for use in connection with illegal substances
        or activities, or convicted criminals or anything related to the sale of alcoholic beverages, tobacco or tobacco-related products; profanity,
        nudity, or sexually explicit functions or materials; anything related to the sale of products designed for use in connection with sexual
        activity, or products, services, or entertainment directed to sexual stimulation; Material which might express or imply ideological, economic
        or other views not generally accepted as in the public interest;
      </p>
      <p>
        Is any material that you do not have a right to transmit or communicate under any contractual or fiduciary relationship or which infringes or
        may infringe any copyright, trademark, publicity, privacy, patent or other intellectual property right or any moral right of any living or
        deceased person or entity including, but not limited to, the United States Postal Service (USPS);
      </p>
      <p>
        Is any material which is likely to cause harm to the any computer systems upon which such material will be run or processed, including but not
        limited to that which contains any virus, code, worm, data or other files or programs designed to damage or allow unauthorized access to the
        any computer system or which may cause any defect, error, malfunction or corruption to any computer system; or
      </p>
      <p>
        Is any material that emulates any form of valid indicia or payment for postage, or resembles or could be confused with postal markings, or
        text required or recommended by postal regulation;
      </p>
      <p>Is any material that violates United States Postal Service (“USPS”) guidelines and rules; and</p>
      <p>Is any other material that Pitney Bowes, in its sole discretion, determines to be inappropriate or offensive.</p>
      <p>
        b)That Pitney Bowes may preserve Content and may also disclose Content if required to do so by law or in the good faith belief that such
        preservation or disclosure is reasonably necessary to: (i) comply with legal process; (ii) enforce the Agreement; (iii) respond to claims that
        any Content violates the rights of third-parties; or (iv) protect the rights, property, or personal safety of Pitney Bowes, its users and the
        public.
      </p>
      <p>
        c)That you understand that the technical processing and operation of the Site, including your Content, may involve (i) transmissions over
        various networks; and (ii) changes to conform and adapt to technical requirements of connection networks or devices.
      </p>
      <p>
        d)That while Pitney Bowes shall have the right to review the Content you submit to determine its suitability for use with Gift Tracking and
        compliance with this Agreement, nothing contained herein shall require Pitney Bowes to perform such review.
      </p>
      <p>
        e)hat you are solely responsible for actions and communications undertaken or transmitted under your account. Pitney Bowes takes no
        responsibility and assumes no liability for any Content uploaded or otherwise transmitted by or to you or by or to any third-party, or for any
        mistakes, defamation, slander, libel, omissions, falsehoods, infringement, obscenity, pornography or profanity you or a third party may
        encounter. Pitney Bowes reserves the right but not the obligation to remove any materials it deems objectionable. You hereby agree to waive
        any claims against Pitney Bowes and its affiliates, contractors, agents and employees for losses, damages and injuries which are based on or
        relate to communications or materials made available to the Site or posted on the Site by persons other than Gift Tracker or its affiliates.
        You hereby agree to indemnify Pitney Bowes and its affiliates from all claims and expenses, including reasonable attorney&apos;s fees, which
        claims are based on or arise from your violation of any of the provisions of this User Agreement.
      </p>
      <p>
        f)That no royalty will be owed or paid on the sale of any items containing an image which is in violation of any of the provisions of this
        Agreement, including but not limited to no royalty on any images that contain objectionable content or are posted in violation of the
        intellectual property, privacy or celebrity rights of any person or entity.
      </p>
      <p>
        g)That you will use this Site and any products, including without limitation, videos, ordered on this Site at all times in accordance with all
        applicable United States federal, state and local laws, statutes, regulations and ordinances and will not take any action that harms or
        violates the rights of any person or entity.
      </p>
      <p>h)That we reserve the right to implement our Take Down policy as we deem appropriate.</p>
      <p>
        i)In addition, in the event you violate these Content Restrictions and you publicize such violation, you acknowledge that Pitney Bowes will
        suffer substantial damage to its reputation and goodwill and that you will be liable for any resulting damage, including consequential and
        indirect damages and that Pitney Bowes shall be entitled to injunctive relief without having to post any bond.
      </p>
      <p>
        j)Pitney Bowes may, in its discretion, accept or reject any content for any reason, including, without limitation, for non-compliance with the
        Content Submission and Restriction requirements set forth above. If it rejects an order, Pitney Bowes shall notify you.
      </p>
      <h6>3) Privacy Policy</h6>
      <p>
        Your privacy is very important to Pitney Bowes. Users of thisSite should refer to our privacy policy
        (https://www.pitneybowes.com/us/legal/privacy-statement.html) for information about how Pitney Bowes collects and uses personal information.
        By accepting this Agreement you expressly consent to Pitney Bowes&apos;s disclosure and use of your personal information (including using
        email to communicate with you) as described in the privacy policy, which is incorporated herein by reference.
      </p>
      <h6>4) Proprietary Rights</h6>
      <p>
        b) You retain all ownership rights to your Content. However, by submitting Content to Pitney Bowes, you grant Pitney Bowes a perpetual,
        worldwide, non-exclusive license to use, reproduce, distribute, and display your Content in accordance with our non-exclusive license
        agreement.
      </p>
      <p>
        c) You acknowledge and agree that the Site and any software usedin connection with the Site contains proprietary and confidential information
        that is protected by applicable intellectual property and other laws. You agree not to modify, rent, lease, loan, sell, distribute or create
        derivative works based on the Site, in whole or in part.
      </p>
      <p>
        d) The intellectual property rights in or relating to the content of any notes, messages, e-mails, postings, letters, ideas, suggestions,
        concepts or other written materials which you submit or communicate to Pitney Bowes (excluding the Content) will automatically be deemed to be
        assigned, granted and transferred by you to Pitney Bowes upon their submission or communication to Pitney Bowes, and you agree that the same
        will automatically become the property of Pitney Bowes and that Pitney Bowes shall be entitled to use, exploit, copy, publish, implement,
        transfer and in all other ways deal with such materials and all of the intellectual property rights therein in any way and for any purpose
        Pitney Bowes may elect, forever.
      </p>
      <p>
        e) You are responsible for all of the images you submit for use hereunder. You represent and warrant that you have the legal right to copy,
        display, distribute and/or make derivative works of each image that you submit to Pitney Bowes, share or print. You authorize Pitney Bowes to
        cooperate with copyright and trademark owners who claim that you have infringed or may infringe their intellectual property rights. This
        cooperation includes, but is not limited to taking the following action without notice: disclosing your material and any other information you
        have disclosed to us to the copyright or trademark owner or its agent and destroying all alleged infringing material. Pitney Bowes may also
        suspend or terminate your account if you appear to be infringing Intellectual Property, and may take further appropriate action.
      </p>
      <p>
        f) At Pitney Bowes request, you shall provide to Pitney Bowes all data, samples, proofs, evidence of its ownership of or right to use any
        images or other copyrighted works that are to be used by Pitney Bowes as part of the Gift Tracking services, and other documents associated
        with the order. You warrant that Pitney Bowes has the right to request confirmation (e.g. a copy of the Copyright Registration or a copy of a
        License Agreement) from you that you either own the copyright in or have the express right to use any images to be used by Pitney Bowes in
        connection with any specifications and/or customer images submitted to Pitney Bowes by you. If you are unable to supply sufficient proof of
        ownership and/or right to use for a specific image, Pitney Bowes has the right to refuse such image without penalty, even if such refusal
        delays the order.
      </p>
      <p>
        g) Except as set forth herein, all right, title and interest in and to the equipment, processes, materials developed and used by Pitney Bowes
        to provide the services hereunder (collectively, the “Business Methods”) in connection with this Agreement (including without limitation all
        data, computer programs, documentation, schematics, formulas or other technical or business information), and all right, title and interest in
        all patents, copyrights, trade secrets, trademarks and other intellectual property derived from such Business Methods shall be the exclusive
        property of Pitney Bowes.
      </p>
      <p>
        h) You acknowledge that Pitney Bowes owns all rights in and to the trademark PITNEY BOWES. You agree that all marketing material (including
        on-line marketing of Gift Tracking) shall include the phrase “Powered by Pitney Bowes” unless otherwise directed by Pitney Bowes. Where
        marketing material, including on-line marketing of Gift Tracking, includes the phrase &quot;Powered by Pitney Bowes&quot;, Pitney Bowes shall
        have the right to request removal of or changes to this phrase if you are misusing the phrase or otherwise making inappropriate use of the
        trademark PITNEY BOWES. Such removal of or changes to the phrase &quot;Powered by Pitney Bowes&quot; must be made within one (1) business day
        after receiving any such request in writing from Pitney Bowes.
      </p>
      <h6>5) Copyright and Trademark Policy</h6>
      <p>
        Pitney Bowes respects the intellectual property rights of others. We ask our users to do the same. Pitney Bowes may terminate the accounts of
        users who appear to infringe the copyright or other intellectual property rights of others.
      </p>
      <h6>6) WARRANTY OF OWNERSHIP AND NON-INFRINGEMENT</h6>
      <p>
        YOU REPRESENT AND WARRANT THAT YOU ARE THE OWNER OF THE CONTENT WHICH YOU SUBMIT AND THAT THE CONTENT DOES NOT INFRINGE UPON THE PROPERTY
        RIGHTS, INTELLECTUAL PROPERTY RIGHTS (COPYRIGHTS AND TRADEMARKS) OR OTHER RIGHTS OF OTHERS. YOU ALSO REPRESENT THAT THERE ARE NO OUTSTANDING
        DISPUTES IN CONNECTION WITH THE PROPERTY RIGHTS, INTELLECTUAL PROPERTY RIGHTS OR OTHER RIGHTS IN THE CONTENT OR ANY PARTS OF THE CONTENT.
      </p>
      <h6>7) Indemnity</h6>
      <p>
        You agree to defend, indemnify and hold Pitney Bowes and its officers, directors, employees, agents and licensors harmless from all judgments,
        awards, losses, liabilities, costs and expenses, including but not limited to reasonable attorney&apos;s fees, expert witness fees, and costs
        of litigation arising out of or based on (a) content you submit, post to or transmit through the Site, (b) your use of the Site, (c) your
        connection to the Site, (d) your violation of the Agreement or (e) your violation of any rights of a third party.
      </p>
      <h6>8) No Resale</h6>
      <p>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any portion of the Site, use of the Site or
        access to the Site without Pitney Bowe&apos;s express written consent.
      </p>
      <h6>9) Termination</h6>
      <p>
        You agree that Pitney Bowes, in its sole discretion, may terminate your use of the Site, and remove and discard any Content you may have
        contributed to the Site, at any time for any reason or no reason. Pitney Bowes may also in its sole discretion and at any time discontinue
        providing the Site, or any part thereof, with or without notice. You agree that any termination of your access to the Site under any provision
        of this Agreement may be effected without prior notice, and acknowledge and agree that Pitney Bowes may immediately deactivate or delete your
        account and all related information and files in your account and/or bar any further access to such files of the Site. Further, you agree that
        Pitney Bowes shall not be liable to you or any third-party for any termination of your access to the Site. Should you object to any terms and
        conditions of the Agreement or become dissatisfied with the Site in any way, your only recourse is to immediately discontinue your use of the
        Site and/or terminate your account.
      </p>
      <h6>10) Links</h6>
      <p>
        The Site may provide, or third parties may provide, links to other websites or resources. Because Pitney Bowes has no control of such sites
        and resources, you acknowledge and agree that Pitney Bowes is not responsible for the availability of such sites or resources, and does not
        endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such sites or
        resources.
      </p>
      <h6>11) Public Reputation</h6>
      <p>
        You acknowledge and agree that you will not use the Gift Tracker Site, including without limitation any Track and Feel, in a way that would be
        damaging to Pitney Bowe&apos;s public reputation or that of its employees, board members, shareholders, licensors, or solution partners.
        Additionally, you acknowledge that if you choose to display any product ordered from the Site, including without limitation, in a public
        setting, including on the Internet, in a way which disparages Pitney Bowes, Pitney Bowe&apos;s board members, employees, shareholders or
        partners, or the United States Postal Service, Pitney Bowes reserves the right to demand immediate return of the product, to furthermore
        pursue all recourses and remedies available under the law.
      </p>
      <h6>12) Age and Geographic Restrictions</h6>
      <p>You must be at least 13 years of age to use Gift Tracker. Please contact us if you have any concerns or questions about this restriction.</p>
      <h6>13) Take Down Policy As Communicated to End Users</h6>
      <p>
        Take Down Policy: We respond to notices of alleged intellectual property infringement or Content Restriction violations and terminate access
        to our Site at our discretion. If you believe that materials on our Site or used in conjunction with our Site, infringe copyright, trademark,
        patent, defamation or foreign copyright, or violate our Content Restrictions, please send the following information to the email below:
      </p>
      <p>i.Your address, telephone number, and email</p>
      address;
      <p>ii.A description of the work that you claim is being infringed or violates our Content Restrictions;</p>
      <p>
        iii.A description of the material that you claim is infringing or violating and are requesting be removed along with information about where
        it is located;
      </p>
      <p>
        iv.A statement that you have “a good faith belief that use of the material in the manner complained of is not authorized by the owner, its
        agent, the law, or the Content Restrictions.”
      </p>
      <p>v.An electronic or physical signature of the owner (or a person authorized to act for the intellectual property owner); and</p>
      <p>
        vi.A statement by you, made under penalty of perjury, that the information you are providing is accurate and if applicable, that you are the
        intellectual property owner or authorized to act on behalf of the owner.
      </p>
      <p>
        Notices should be emailed to the following designated agent (this designated agent is recorded at the US Copyright Office) at the address:
      </p>
      <p>If you don not follow these requirements, your notice may not be valid.</p>
      <h6>14) DISCLAIMER OF WARRANTIES</h6>
      <p>
        YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK. THIS SITE AND THE INFORMATION, SERVICES, PRODUCTS, AND MATERIALS
        AVAILABLE THROUGH IT ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS.
      </p>
      <p>
        TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, PITNEY BOWES AND ITS AFFILIATES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND
        WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF TITLE, OR IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT.
      </p>
      <p>
        PITNEY BOWES DOES NOT MAKE ANY WARRANTY THAT (A) THE SITE OR ITS CONTENT WILL MEET YOUR REQUIREMENTS, OR (B) THAT THE SITE OR SERVICE WILL BE
        UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE, OR (C) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR
        OBTAINED BY YOU THROUGH THE SITE WILL MEET YOUR EXPECTATIONS, OR (D) THAT DEFECTS, IF ANY, WILL BE CORRECTED. YOU AGREE THAT PITNEY BOWES
        SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGES SUFFERED BY YOU IN CONNECTION WITH THE SITE OR ANY CONTENT CONTAINED THEREIN INCLUDING BUT NOT
        LIMITED TO LOSS OF DATA FROM DELAYS, NONDELIVERIES OF CONTENT OR EMAIL, ERRORS, SYSTEM DOWN TIME, MISDELIVERIES OF CONTENT OR EMAIL, NETWORK
        OR SYSTEM OUTAGES, FILE CORRUPTION, OR SERVICE INTERRUPTIONS CAUSED BY THE NEGLIGENCE OF PITNEY BOWES, ITS AFFILIATES, ITS LICENSORS, OR A
        USER&apos;S OWN ERRORS AND/OR OMISSIONS.
      </p>
      <p>
        YOU EXPRESSLY AGREE THAT USE OF THIS SERVICE, INCLUDING ALL CONTENT, DATA OR SOFTWARE DISTRIBUTED BY, DOWNLOADED OR ACCESSED FROM OR THROUGH
        THIS SITE, IS AT YOUR SOLE RISK. YOU UNDERSTAND AND AGREE THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR BUSINESS, YOUR COMPUTER
        SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH CONTENT, DATA AND/OR SOFTWARE AND PITNEY BOWES WILL NOT BE LIABLE FOR ANY
        DAMAGES YOU MAY INCUR BY REASON OF YOUR ORDER OR USE OF THE PRODUCTS ORDERED HEREUNDER OR THE FAILURE OF THE PRODUCTS TO OPERATE OR PERFORM,
        INCLUDING LOST PROFITS, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES, EVEN IF PITNEY BOWES HAS BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES.
      </p>
      <p>
        YOU ACKNOWLEDGE THAT PITNEY BOWES DOES NOT CONTROL IN ANY RESPECT ANY INFORMATION, PRODUCTS, OR SERVICES OFFERED BY THIRD PARTIES ON OR
        THROUGH THIS SERVICE. EXCEPT AS OTHERWISE AGREED IN WRITING, PITNEY BOWES AND ITS AFFILIATES ASSUME NO RESPONSIBILITY FOR AND MAKE NO WARRANTY
        OR REPRESENTATION AS TO THE ACCURACY, CURRENCY, COMPLETENESS, RELIABILITY OR USEFULNESS OF CONTENT OR PRODUCTS DISTRIBUTED OR MADE AVAILABLE
        BY THIRD PARTIES THROUGH THIS SITE.
      </p>
      <h6>15)General</h6>
      <p>
        You represent and warrant that you agree to use the Gift Tracker hereunder for lawful purposes only and in a manner consistent with local,
        state and federal laws and regulations. You represent, warrant, and covenant to Pitney Bowes that (i) any information provided to Pitney Bowes
        in connection with your use of the products shall be accurate, (ii) you have and will continue to have the power and authority to enter into
        and perform your obligations under this Agreement, and (iii) you will not use Pitney Bowes’ name, image, copyright or any of its intellectual
        property without our prior express written consent, which may be withheld, denied or withdrawn, by us at any time for any reason, in our sole
        discretion. You acknowledge and agree that the Gift Tracking will contain information that would allow Pitney Bowes and the USPS to identify
        the user of the Gift Tracking and you hereby expressly authorize your consent for Pitney Bowes to convey your customer information to the USPS
        as may be required by the USPS. You hereby acknowledge, agree, and warrant that you understand that Gift Tracking images are not approved or
        endorsed in any way by the USPS, and that you will not make promotional references to Gift Tracking products nor make any representations
        which could cause a reasonable person to believe that Gift Tracking images are provided, approved, or endorsed by the USPS. If Pitney Bowes
        performance of the terms of this Agreement or any obligations hereunder is prevented, restricted or interfered with by reason of fire,
        earthquake, or other casualty or accident, strikes or labor disputes, war, terrorism or other violence, any law, order, proclamation,
        regulation, ordinance, demand or requirement of any government agency including, without limitation, the USPS, or any other act or condition
        beyond the reasonable control of Pitney Bowes, then Pitney Bowes shall be excused from such performance during such prevention, restriction or
        interference. Pitney Bowes reserves the right to limit or expand the scope of the offering hereunder. Additionally, Pitney Bowes may alter,
        suspend or discontinue this offering at any time in its sole discretion, without penalty. The offering of the products hereunder is limited to
        only those jurisdictions where it may lawfully be made. This Agreement and performance hereunder shall be governed by and construed in
        accordance with the laws of Connecticut, but without recourse to Connecticut’s conflict of law provisions that would otherwise require the
        application of the law of any other jurisdiction. The parties hereby agree and consent to the exclusive jurisdiction and venue of the courts
        situated in Fairfield County, Connecticut, in any action arising out of or relating to this Agreement and hereby submit to the personal
        jurisdiction of such courts. The United Nations Convention on Contracts for the International Sale of Goods is expressly disclaimed. The
        waiver or failure of Pitney Bowes to exercise in any respect any right provided for herein shall not be deemed a waiver of any further right
        hereunder. If any portions of this Agreement is invalid under any applicable statute or rule of law to that extent they shall be deemed
        omitted from Agreement. You represent and warrant that you have the authority to enter into this Agreement and to be bound accordingly. You
        further agree that this Agreement is the entire agreement between Pitney Bowes and you with respect to the products ordered hereunder and
        supersedes all prior agreements (whether written or oral) and other communications between Pitney Bowes and you with respect to the products
        ordered hereunder. The section headings in this Agreement are provided for convenience only and shall not affect the legal meaning of this
        Agreement or any section hereof.
      </p>
    </div>
  );
}
