import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const NoLabelImageContainer = styled.div`
  width: 100%;
  margin: 0 0 250px 0;
`;

export const NoLabelImageWrapper = styled.div`
  max-width: 560px;
  width: 100%;

  text-align: center;

  @media ${({ theme }) => theme.mediumUp} {
    text-align: left;
  }
`;

export const NoLabelImageTitle = styled.h3`
  font-size: 18px;
  margin: 0 0 25px 0;
  font-weight: 400;
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
`;

export const NoLabelImageSubtitle = styled.p`
  font-size: 16px;
  margin: 0 0 60px 0;
  font-weight: 400;
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
`;

export const LabelEmailInputWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto 50px auto;

  @media ${({ theme }) => theme.mediumUp} {
    margin: 0 0 50px 0;
  }
`;

export const NoLabelImageParagraph = styled.p`
  font-size: 15px;
  margin: 0 0 25px 0;
  font-weight: 400;
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};

  &:last-of-type {
    margin: 0;
  }
`;
