import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import { css } from '@emotion/react';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const InputContainer = styled.div<{ error: boolean; valid: boolean }>`
  position: relative;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  padding: 7px 75px 0 40px;
  margin: 0 0 20px 0;
  border: 2px solid ${({ error }) => (error ? '#dd1010' : '#ffffff')};
  border-right: none;
  background-color: white;
  border-radius: 5px;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  transition: all 0.2s ease;
  font-family: ${() => (getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : '')};

  @media ${({ theme }) => theme.mediumUp} {
    padding: 7px 100px 0 40px;
  }

  ${({ theme, error }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      border: 1px solid ${error ? '#dd1010' : '#e1e1e1'};
      padding: 7px 60px 0 40px;

      @media ${theme.mediumUp} {
        padding: 7px 100px 0 40px;
      }

      @media ${theme.xlargeUp} {
        padding: 7px 60px 0 40px;
      }

      @media (min-width: 1400px) {
        padding: 7px 80px 0 40px;
      }
    `}
  & > i {
    transition: all 0.2s ease;
    color: ${({ theme, error, valid }) => (error ? '#dd1010' : valid ? (theme.template === TemplateEnum.VIVID ? '#246917' : '#b8efae') : theme.gray)};
    font-size: 20px;
    position: absolute;
    transform: translate(0, -50%);
    top: 48%;
    left: 12px;
  }
`;

export const TagsContainer = styled.div`
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
`;

export const TagsInput = styled.input<{ shouldExist: boolean }>`
  outline: none;
  max-height: 25px;
  padding: 5px 0;
  box-sizing: border-box;
  border: 2px solid white;
  border-radius: 5px;
  display: ${({ shouldExist }) => (shouldExist ? 'block' : 'none')};
  flex: 1;
  margin-bottom: 7px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.2s ease;
  font-family: ${() => (getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : '')};

  &::placeholder {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    font-family: ${() => (getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : '')};
  }

  &:disabled {
    background-color: white;
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      min-width: 120px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      min-width: 100px;

      @media (min-width: 576px) {
        min-width: 120px;
      }

      @media (min-width: 992px) {
        min-width: 80px;
      }

      @media (min-width: 1400px) {
        min-width: 120px;
      }
    `}
`;

export const InputErrorMessage = styled.p`
  font-family: ${() => (getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : '')};
  padding: 0;
  margin: 0;
  color: #dd1010;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
`;
