import { css, SerializedStyles } from '@emotion/react';
import get from 'lodash/get';
import { ClientConfigurationType } from 'types/shared/config/client-configuration-type';

export const clientStyles = (config?: ClientConfigurationType): SerializedStyles => {
  const headerBackgroundColor = get(config, 'headerModule.showBackgroundAsColor') && get(config, 'headerModule.backgroundColor');

  const headerBackgroundStyles = headerBackgroundColor
    ? css`
        .sc-header-background {
          background-color: ${headerBackgroundColor} !important;
        }
      `
    : '';

  const previewStyles = get(config, 'isPreview')
    ? css`
        body {
          pointer-events: none;
        }
      `
    : '';

  const primaryColor = get(config, 'styleModule.primaryColor');
  const primaryColorStyles = primaryColor
    ? css`
        .sc-primary-color {
          color: ${primaryColor} !important;
        }
        .sc-primary-background-color {
          background: ${primaryColor} !important;
        }
      `
    : '';

  const darkerPrimaryColor = get(config, 'styleModule.darkerPrimaryColor');
  const darkerPrimaryColorStyles = darkerPrimaryColor
    ? css`
        .sc-darker-primary-background-color {
          color: ${darkerPrimaryColor} !important;
        }
      `
    : '';

  const secondaryColor = get(config, 'styleModule.secondaryColor');
  const secondaryColorStyles = secondaryColor
    ? css`
        .sc-secondary-color {
          color: ${secondaryColor} !important;
        }
        .sc-secondary-background-color {
          background: ${secondaryColor} !important;
        }
        .sc-secondary-background-color.secondaryBackgroundLight {
          color: rgba(0, 0, 0, 0.54);
        }
      `
    : '';

  const tertiaryColor = get(config, 'styleModule.tertiaryColor');
  const tertiaryColorStyles = tertiaryColor
    ? css`
        .sc-tertiary-color {
          color: ${tertiaryColor} !important;
        }
        .sc-tertiary-background-color {
          background: ${tertiaryColor} !important;
        }
      `
    : '';

  const buttonStyleRectangularStyles = get(config, 'styleModule.buttonStyleRectangular')
    ? css`
        .sc-button-default {
          border-radius: 0;
        }
      `
    : '';

  const buttonStylePillStyles = get(config, 'styleModule.buttonStylePill')
    ? css`
        .sc-button-default {
          border-radius: 40px;
        }
      `
    : '';

  const buttonStyleRoundedStyles = get(config, 'styleModule.buttonStyleRounded')
    ? css`
        .sc-button-default {
          border-radius: 5px;
        }
      `
    : '';

  const notificationsBackgroundColor = get(config, 'notificationsModule.showBackgroundAsColor') && get(config, 'notificationsModule.backgroundColor');
  const notificationsBackgroundColorStyles = notificationsBackgroundColor
    ? css`
        .sc-notifications--solid {
          background: ${notificationsBackgroundColor} !important;
        }
      `
    : '';

  const headerFontFamily = get(config, 'styleModule.headerFontFamily');
  const headerFontStyles = headerFontFamily
    ? css`
        .sc-header-font-loaded-n4.sc-header-font-loaded-n7 .sc-header-font {
          font-family: ${headerFontFamily} !important;
        }
      `
    : '';

  const subHeaderFontFamily = get(config, 'styleModule.subheaderFontFamily');
  const subHeaderFontStyles = headerFontFamily
    ? css`
        .sc-header-font-loaded-n4.sc-header-font-loaded-n7 .sc-subheader-font {
          font-family: ${subHeaderFontFamily} !important;
        }
      `
    : '';

  const bodyFontFamily = get(config, 'styleModule.bodyFontFamily');
  const bodyFontStyles = headerFontFamily
    ? css`
        .sc-header-font-loaded-n4.sc-header-font-loaded-n7 .sc-body-font {
          font-family: ${bodyFontFamily} !important;
        }
      `
    : '';

  return css`
        ${headerBackgroundStyles}
        ${previewStyles}
        ${primaryColorStyles}
        ${darkerPrimaryColorStyles}
        ${secondaryColorStyles}
        ${tertiaryColorStyles}
        ${buttonStylePillStyles}
        ${buttonStyleRectangularStyles}
        ${buttonStyleRoundedStyles}
        ${notificationsBackgroundColorStyles}
        ${headerFontStyles}
        ${subHeaderFontStyles}
        ${bodyFontStyles}
    `;
};

export default clientStyles;
