import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';

export const TileContainer = styled.li`
  color: #4a4a4a;
  background-color: #fff;
  padding: 20px 20px 20px 20px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  margin: 0 0 30px 0;

  &:last-of-type {
    margin: 0;
  }
`;

export const TileContent = styled.div<{ displayTileActions: boolean }>`
  ${({ displayTileActions }) =>
    displayTileActions &&
    css`
      padding: 0 0 20px 0;
      border-bottom: 2px solid #e1e1e1;
    `}
`;

export const Wrapper = styled.div`
  @media ${({ theme }) => theme.largeUp} {
    display: flex;
    justify-content: space-between;
  }
`;

export const Box1 = styled.div`
  margin: 0 0 30px 0;
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    margin: 0;
    width: 33%;
  }
`;

export const Box2 = styled.div`
  width: 100%;
  @media ${({ theme }) => theme.largeUp} {
    width: 33%;
  }
`;
export const Box3 = styled.div`
  width: 100%;
  @media ${({ theme }) => theme.largeUp} {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

export const TileActions = styled.div`
  padding: 15px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;
export const ButtonsWrapper2 = styled.div``;

export const TileActionsIconWrapper = styled.div<{ displayInfoMessage: boolean; message?: string }>`
  color: #0068a8;
  font-size: 20px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${({ displayInfoMessage, message }) =>
    displayInfoMessage &&
    message &&
    css`
      color: #00b140;

      &:after {
        position: absolute;
        top: 135%;
        content: ${`'${message}'`};
        color: #00b140;
        font-size: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
      }
    `}
`;

export const TileError = styled.p`
  width: 100%;
  margin: 0;
  padding: 20px 2px 0 2px;
  font-size: 14px;
`;

export const IconWrapper = styled.div`
  font-size: 16px;
  margin: 0 5px 0 0;
  color: #dd1010;
`;

export const TileErrorModifier = styled.span`
  font-weight: 700;
  color: #dd1010;
`;

export const PrimaryText = styled.h4`
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 14px 0;
`;

export const SecondaryText = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 5px 0;
`;

export const SecondaryTextDate = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`;

export const SecondaryTextDateTime = styled.span`
  font-size: 14px;
  font-weight: 300;
`;

export const SecondaryTextDateTimeModifier = styled.span`
  font-weight: 700;
`;

export const Paragraph = styled.p`
  font-size: 13px;
  font-weight: 300;
  margin: 0 0 3px 0;
  display: flex;
  justify-content: space-between;

  &:last-of-type {
    margin: 0;
  }

  @media ${({ theme }) => theme.largeUp} {
    max-width: 350px;
    font-size: 14px;
  }
`;

export const Text = styled.span`
  display: inline-block;
  width: 120px;

  @media ${({ theme }) => theme.largeUp} {
    width: 130px;
  }
`;

export const TextModifier = styled.span`
  font-weight: 700;
  display: inline-block;
  width: calc(100% - 20px - 120px);
  flex-grow: 2;
  margin: 0 0 0 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${({ theme }) => theme.largeUp} {
    width: calc(100% - 20px - 130px);
  }
`;

export const TileCustomButton = styled(CustomButton)`
  text-transform: none;
  border-radius: 4px;
  transition: 'all 2s ease-in-out' !important;
  color: #0068a8;
  white-space: nowrap;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    transition: 'all 2s ease-in-out' !important;
    border-radius: 4px;
    color: #0068a8;
  }
`;
