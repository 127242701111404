import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const ReturnsInstructionsList = styled.ul``;

export const ReturnsInstructionsListItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.bodyFontColor};
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.primaryFontFamily)};
  margin: 0 0 20px 0;

  &:last-of-type {
    margin: 0;
  }
`;

export const ReturnsInstructionsParagraph = styled.p`
  margin: 0;
  font-size: inherit;
  flex-grow: 1;
`;

export const ReturnsInstructionsNumber = styled.span`
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 0;
  border-radius: 50%;
  background-color: #e1e1e1;
  font-size: inherit;
`;
