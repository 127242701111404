import React, { PropsWithChildren, useEffect, useState } from 'react';
import { ExchangeOptionsListContainer, ExchangeOptionsListWrapper, ExchangeOptionsListTitle } from './ExchangeOptionsList.styled';
import { ExchangeOption } from 'components/Returns/Overview/ProductsSelection/ProductsSelectionForm/Product/ExchangeOptionsList/ExchangeOption/ExchangeOption';
import { fillInArrayWithQuantities } from 'utils/returns/overview/products-selection/fill-in-array-with-quantities/fill-in-array-with-quantities';
import { useIntl } from 'react-intl';

export const ExchangeOptionsList: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { selectedQuantity, rootProductImageUrl, rootProductName, rootProductSKU, rootProductAttributesValues, rootProductId, orderNumber } = props;

  const [selectedQuantities, setSelectedQuantities] = useState<number[]>(fillInArrayWithQuantities(Number(selectedQuantity)));

  const intl = useIntl();

  useEffect(() => {
    setSelectedQuantities(fillInArrayWithQuantities(Number(selectedQuantity)));
  }, [selectedQuantity]);

  return (
    <ExchangeOptionsListContainer>
      <ExchangeOptionsListTitle id="exchange-options-heading">
        {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.exchangeOptionsList.title' })}
      </ExchangeOptionsListTitle>
      <ExchangeOptionsListWrapper aria-labelledby="exchange-options-heading">
        {selectedQuantities.map((element: number, exchangeOptionIndex: number) => (
          <ExchangeOption
            orderNumber={orderNumber}
            rootProductId={rootProductId}
            rootProductAttributesValues={rootProductAttributesValues}
            rootProductImageUrl={rootProductImageUrl}
            rootProductName={rootProductName}
            rootProductSKU={rootProductSKU}
            key={element}
            exchangeOptionIndex={exchangeOptionIndex}
          />
        ))}
      </ExchangeOptionsListWrapper>
    </ExchangeOptionsListContainer>
  );
};

export interface OwnProps {
  orderNumber: string;
  selectedQuantity: number;
  rootProductImageUrl: string | undefined;
  rootProductName: string;
  rootProductSKU: string;
  rootProductAttributesValues: string[];
  rootProductId: string;
}
