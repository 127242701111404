import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import useFetch from 'hooks/useFetch/useFetch';
import { useIntl } from 'react-intl';
import { ReturnsButtonContainer, ReturnsConfirmButton, ReturnsContainer, ReturnsParagraph, ReturnsTitle } from './Returns.styled';
import { TemplateEnum } from 'types/shared/template-type';
import { TrackingButtonAsLink } from 'components/Tracking/Common/Buttons/TrackingButtons.styled';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { Loading } from 'components/Shared/Loading/Loading';
import { TrackingReturnsModuleResponse } from 'types/tracking/returns-types';
import { DefaultErrorResponse } from 'hooks/useFetch/useFetch';
import { ClientConfigContext } from '../../../context/shared/ClientConfigContext';
import { buildGetTrackingReturnsApi } from 'utils/tracking/build-get-tracking-returns-api/build-get-tracking-returns-api';

export const Returns: React.FC<OwnProps> = (props) => {
  const { designId, trackingNumber, urlKey, delivered, bgColor, setShowReturns } = props;

  const { template, vanityName } = useContext(ClientConfigContext);
  const intl = useIntl();

  const [showReturnButton, setShowReturnButton] = useState(delivered);

  const TRACKING_RETURNS_URL: string = buildGetTrackingReturnsApi(vanityName, trackingNumber, urlKey, designId);

  const [trackingReturnsConfig, , error] = useFetch<TrackingReturnsModuleResponse, DefaultErrorResponse>(TRACKING_RETURNS_URL);

  useEffect(() => {
    if (error) {
      setShowReturns(false);
    }
  }, [setShowReturns, error]);

  const handleReturnLinkClick = () => {
    setShowReturnButton(true);
  };

  if (error) return null;
  if (!trackingReturnsConfig)
    return (
      <SectionLayout
        marginDefault={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : '0 0 50px 0'}
        bgColor={template === TemplateEnum.SPARTAN ? bgColor : ''}
        marginXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
        marginXXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
        paddingXl={template === TemplateEnum.SPARTAN ? '0' : ''}
        paddingXXl={template === TemplateEnum.SPARTAN ? '0' : ''}
        backgroundAsColor={template === TemplateEnum.SPARTAN}>
        <ReturnsContainer>
          <Loading height="150px" />
        </ReturnsContainer>
      </SectionLayout>
    );

  if (!trackingReturnsConfig.enabled) return null;

  return (
    <SectionLayout
      marginDefault={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : '0 0 50px 0'}
      bgColor={template === TemplateEnum.SPARTAN ? bgColor : ''}
      marginXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
      marginXXl={template === TemplateEnum.SPARTAN ? '0 0 2px 0' : ''}
      paddingXl={template === TemplateEnum.SPARTAN ? '0' : ''}
      paddingXXl={template === TemplateEnum.SPARTAN ? '0' : ''}
      backgroundAsColor={template === TemplateEnum.SPARTAN}>
      <ReturnsContainer data-test-id="tracking-returns-container">
        <ReturnsTitle data-test-id="tracking-returns-title">
          {showReturnButton ? trackingReturnsConfig.headerCopy : intl.formatMessage({ id: 'TRACKING.returns.title' })}
        </ReturnsTitle>
        {showReturnButton && <ReturnsParagraph data-test-id="tracking-returns-paragraph">{trackingReturnsConfig.subheadCopy}</ReturnsParagraph>}
        {!showReturnButton && (
          <ReturnsConfirmButton data-test-id="tracking-returns-confirm-button" onClick={() => handleReturnLinkClick()} data-gtm-id="return-button">
            {intl.formatMessage({ id: 'TRACKING.returns.link.title' })}
          </ReturnsConfirmButton>
        )}
        {showReturnButton && (
          <ReturnsButtonContainer>
            <TrackingButtonAsLink
              isBoxShadow={true}
              target="_blank"
              padding="12px 30px"
              maxWidth="190px"
              data-test-id="tracking-returns-link"
              href={`/search/${vanityName}`}
              data-gtm-id="return-link">
              {intl.formatMessage({ id: 'TRACKING.returns.button.startReturn' })}
            </TrackingButtonAsLink>
          </ReturnsButtonContainer>
        )}
      </ReturnsContainer>
    </SectionLayout>
  );
};

export interface OwnProps {
  trackingNumber: string;
  delivered: boolean;
  urlKey: string | null;
  designId: string | null;
  bgColor?: string;
  setShowReturns: Dispatch<SetStateAction<boolean>>;
}
