import React from 'react';
import { MultiPromotional } from './common/MultiPromotional/MultiPromotional';
import { SinglePromotional } from './common/SinglePromotional/SinglePromotional';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { PromotionsAddonModuleType } from 'types/shared/config/promotionsAddonModule/promotions-addon-module-type';

export const Promotions: React.FC<OwnProps> = (props: OwnProps) => {
  const { promotionsModule } = props;
  const { productFeedEnabled } = promotionsModule;

  return (
    <SectionLayout marginDefault="0 0 80px 0" backgroundAsColor={false}>
      {productFeedEnabled ? (
        <MultiPromotional title={promotionsModule.title} subtitle={promotionsModule.subtitle} buttonLabel={promotionsModule.buttonCopy} />
      ) : (
        <SinglePromotional
          imageUrl={promotionsModule.imageUrl}
          title={promotionsModule.title}
          subtitle={promotionsModule.subtitle}
          linkHref={promotionsModule.url}
          buttonLabel={promotionsModule.buttonCopy}
        />
      )}
    </SectionLayout>
  );
};

export interface OwnProps {
  promotionsModule: PromotionsAddonModuleType;
}
