import React, { useContext, useEffect } from 'react';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { useIntl } from 'react-intl';
import { LabelDetailsWrapper } from './LabelDetails.styled';
import { setLabelPrintUrl } from 'utils/returns/returnInfo/label/set-label-print-url/set-label-print-url';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { setSendEmailWithLabelUrl } from 'utils/returns/returnInfo/label/set-send-email-with-label-url/set-send-email-with-label-url';
import { LabelEmailInput } from 'components/Returns/Label/LabelEmailInput/LabelEmailInput';
import { LabelPickupScheduler } from 'components/Returns/Label/LabelPickupScheduler/LabelPickupScheduler';
import { initGoogleTagManagerDataLayer } from 'utils/common/google-tag-manager/google-tag-manager';
import { ReturnInfoApiResponseSuccessType } from 'types/return/return-info/return-info-api-response-type';
import { CarriersEnum } from 'types/return/overview/methods/method-types';

export const LabelDetails: React.FC<OwnProps> = (props) => {
  const { details, urlKey, labelId, inputOrderId, shippingPostalCode, carrierName } = props;
  const { merchantId, vanityName, clientReturnType, template, brandId } = useContext(ClientConfigContext);

  const { makeEmailCopyOfLabelButtonTextDynamicCoco529, returnsUps2DayCoco264, attCustomerCareModificationsCoco519 } = useFlags();
  const intl = useIntl();

  const LABEL_PRINT_URL = setLabelPrintUrl(details.pdfContentLink, details.pdfLabelLink);
  const SEND_EMAIL_URL = setSendEmailWithLabelUrl(vanityName, labelId, inputOrderId, shippingPostalCode, urlKey);

  useEffect(() => {
    return initGoogleTagManagerDataLayer(
      details.tagManagerModule.deliveryType,
      details.tagManagerModule.trackingStatus,
      merchantId,
      vanityName,
      null,
      'Return Label',
      'returnPageview',
      clientReturnType === 'integrated' ? 'RMA' : 'Non-integrated',
      template,
      !!urlKey,
      null,
      brandId
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LabelDetailsWrapper data-test-id="label-details-wrapper">
      {!attCustomerCareModificationsCoco519 && (
        <CustomButton
          size="medium"
          width="100%"
          href={LABEL_PRINT_URL}
          isUppercase
          data-test-id="label-info-print-link"
          data-gtm-id="print-label"
          variant="contained"
          marginDefault="0 0 25px 0">
          {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.button.printLabel' })}
        </CustomButton>
      )}
      {makeEmailCopyOfLabelButtonTextDynamicCoco529 && makeEmailCopyOfLabelButtonTextDynamicCoco529.visible && (
        <LabelEmailInput sendEmailUrl={SEND_EMAIL_URL} />
      )}
      {!returnsUps2DayCoco264 && carrierName?.toLowerCase() === CarriersEnum.USPS && (
        <LabelPickupScheduler labelId={labelId} inputOrderId={inputOrderId} />
      )}
    </LabelDetailsWrapper>
  );
};

export interface OwnProps {
  details: ReturnInfoApiResponseSuccessType;
  urlKey: string | null;
  labelId: string;
  inputOrderId: string;
  shippingPostalCode: string | null;
  carrierName?: string | null;
}
