export const resolveAppErrorPageTitle = (status: number | 400 | 404 | 500 | 503): string => {
  switch (status) {
    case 400:
      return 'APP.appErrorLoader.appError.error.400.pageTitle';
    case 404:
      return 'APP.appErrorLoader.appError.error.404.pageTitle';
    case 503:
      return 'APP.appErrorLoader.appError.error.503.pageTitle';
    default:
      return 'APP.appErrorLoader.appError.error.500.pageTitle';
  }
};
