import { resolveVanityNameAPIHost } from 'utils/common/resolve-vanity-api-host/resolve-vanity-name-api-host';
import queryString from 'query-string';

export const buildAuthReturnsRedirectUrl = (vanityName: string, orderNumber: string, zipCode: string | null, urlKey: string): string => {
  const mainUrl: string = `${resolveVanityNameAPIHost(vanityName)}/return/overview/${vanityName}`;

  return queryString.stringifyUrl(
    {
      url: mainUrl,
      query: {
        inputOrderId: orderNumber,
        shippingPostalCode: zipCode,
        key: urlKey
      }
    },
    { skipNull: true, sort: false }
  );
};
