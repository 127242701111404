import { SearchBy } from 'types/customer-service/search-by-type';

export const buildFindParcelsPayload = (searchBy: SearchBy, numbers: string): string | string[] => {
  let result: string | string[] = '';

  if (searchBy === SearchBy.TRACKING_NUMBER) {
    const verifiedAndCleanedTrackingNumbers: string[] = [];
    const trackingNumbers: string[] = numbers.split('\n');

    for (let i = 0; i < trackingNumbers.length; i++) {
      const cleanTrackingNumber: string = trackingNumbers[i].replace(/\s/g, '');

      if (!verifiedAndCleanedTrackingNumbers.includes(cleanTrackingNumber) && cleanTrackingNumber.length > 0)
        verifiedAndCleanedTrackingNumbers.push(cleanTrackingNumber);
    }
    result = verifiedAndCleanedTrackingNumbers;
  } else {
    const cleanOrderNumber: string = numbers.replace(/\n/g, '').replace(/\s/g, '');
    result = cleanOrderNumber;
  }

  return result;
};
