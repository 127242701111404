import React, { useState } from 'react';
import {
  Container,
  ContainerActions,
  Link,
  ContainerContent,
  Text,
  HoursList,
  TextModifier,
  CustomAccordionSummary,
  CustomAccordion,
  CustomAccordionDetails,
  HoursItem,
  LocationInfo,
  LocationIconContainer,
  LocationIcon,
  UnpackIconContainer,
  UnpackIcon,
  CloseText
} from './DropOffLocationItem.styled';
import { DropOffLocationType, OpeningHours } from 'types/return/return-info/Common/drop-off-location-type';
import { useIntl } from 'react-intl';
import { buildGoogleMapLink } from 'utils/returns/returnInfo/common/build-google-map-link/build-google-map-link';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const DropOffLocationItem: React.FC<OwnProps> = (props) => {
  const { item, userLatitudePosition, userLongitudePosition, originStreet, originCity, originState, originZipCode, selectedDropOffLocation } = props;
  const { name, placeAddress, placeAvailability } = item;
  const { street: destinationStreet, city: destinationCity, state: destinationState, zipCode: destinationZipCode } = placeAddress;
  const { openingHours, currentStatus, currentStatusInfo } = placeAvailability;
  const [expanded, setExpanded] = useState(false);

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black
      }
    })
  );

  const intl = useIntl();

  const handleExpandClick = () => {
    setExpanded(!expanded);
    window.dataLayer.push({
      event: 'drop-off-locations-hours-accordion',
      dropOffLocationsHoursExpandedValue: !expanded ? 'Open' : 'Close'
    });
  };

  return (
    <Container data-test-id="drop-off-location" data-gtm-id="drop-off-locations-tile" aria-label="drop-off-location">
      <div style={{ display: 'flex', width: '100%' }}>
        <LocationIconContainer>
          {selectedDropOffLocation === item.id && <LocationIcon src="/assets/img/Icons_map_pin.svg" alt="reminder finger" />}
        </LocationIconContainer>
        <div style={{ width: '100%' }}>
          <ContainerContent>
            <LocationInfo>
              <Text isBold margin="0 0 3px" data-test-id="drop-off-location__name">
                {name}
              </Text>
              <Text margin="0 0 3px" data-test-id="drop-off-location__away">
                {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocationItem.distance' }, { distance: item.mapLocation.distance })}
              </Text>
            </LocationInfo>
            <Text margin="0 0 3px" data-test-id="drop-off-location__address">
              {destinationStreet}
            </Text>
            <Text data-test-id="drop-off-location__address-details">
              {intl.formatMessage(
                { id: 'RETURN.returnInfo.common.dropOffLocationItem.addressDetails' },
                { city: destinationCity, state: destinationState, zipCode: destinationZipCode }
              )}
            </Text>
          </ContainerContent>
          <ContainerActions>
            <CustomAccordion expanded={expanded} onChange={handleExpandClick}>
              <CustomAccordionSummary data-test-id="drop-off-location__opening-hours-button" data-gtm-id="drop-off-location-hours-arrow">
                <Text>{currentStatusInfo}</Text>
                {currentStatus.toUpperCase() === 'CLOSE_SOON' && ' '}
                {currentStatus.toUpperCase() === 'CLOSE_SOON' && (
                  <TextModifier>{intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocationItem.button.extraInfo' })}</TextModifier>
                )}
                {currentStatus.toUpperCase() === 'CLOSE' && ' '}
                {currentStatus.toUpperCase() === 'CLOSE' && (
                  <CloseText>{intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocationItem.button.closedInfo' })}</CloseText>
                )}
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                <HoursList>
                  {openingHours.map((element: OpeningHours) => (
                    <HoursItem aria-label="hours-range" key={element.id}>
                      {element.days} {element.hours}
                    </HoursItem>
                  ))}
                </HoursList>
              </CustomAccordionDetails>
            </CustomAccordion>
            <LocationInfo>
              <Link
                data-test-id="drop-off-location__link"
                data-gtm-id="drop-off-location-directions-link"
                href={buildGoogleMapLink(
                  userLatitudePosition,
                  userLongitudePosition,
                  originStreet,
                  originCity,
                  originState,
                  originZipCode,
                  destinationStreet,
                  destinationCity,
                  destinationState,
                  destinationZipCode
                )}
                target="_blank">
                {intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocationItem.link' })}
              </Link>
              <UnpackIconContainer>
                {item.packageLess && (
                  <BootstrapTooltip
                    title={intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocationItem.unpackedIconInfo' })}
                    placement="right">
                    <UnpackIcon src="/assets/img/Icon_Box_Block.svg" alt="reminder finger" />
                  </BootstrapTooltip>
                )}
              </UnpackIconContainer>
            </LocationInfo>
          </ContainerActions>
        </div>
      </div>
    </Container>
  );
};

export interface OwnProps {
  item: DropOffLocationType;
  userLatitudePosition: null | number;
  userLongitudePosition: null | number;
  originStreet: string | null;
  originCity: string | null;
  originState: string | null;
  originZipCode: string | null;
  selectedDropOffLocation: number | null;
}
