import styled from '@emotion/styled';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';
import { css } from '@emotion/react';
import { darken } from 'polished';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const OrderDetailsContainer = styled.div`
  width: 100%;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      max-width: 740px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      max-width: 1160px;
    `}
`;

export const OrderDetailsTitle = styled.h3`
  color: #6b6b6b;
  font-size: 12px;
  font-weight: normal;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 14px;
  }
`;

export const OrderDetailsList = styled.ul`
  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      padding-top: 10px;
      background-color: white;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      box-shadow: 0 1px 4px 0 rgba(181, 181, 181, 0.29);
    `}
`;

export const OrderDetailsButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      background: ${theme.white};
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    `}
`;

export const OrderDetailsButton = styled.button`
  border: none;
  background: transparent;
  max-width: 300px;
  transition: text 0.3s ease;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    color: ${({ theme }) => darken(0.1, getCustomStyleProperty(theme, 'styleModule.secondaryColor'))};
    text-decoration: underline;
  }

  color: ${getCustomStyle('styleModule.secondaryColor')};
`;
