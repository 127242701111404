import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FormControlLabel } from '@mui/material';

export const Container = styled.section``;

export const ContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  height: 33px;
`;

export const Title = styled.h2`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 4px 0;
  display: flex;
  color: #2e2e2e;
`;

export const List = styled.ul`
  margin: 0 0 16px 0;
`;

export const DropOffLocationsListButton = styled.button`
  padding: 13px 25px;
  background-color: #f8f8f8;
  color: #0072b8;
  border: 0;
  border-radius: 4px;
  width: 100%;
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  display: flex;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

export const DropOffInput = styled.input`
  appearance: none;
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
  background: transparent;
  border: none;
  display: block;
`;

export const DropOffLabel = styled.label<{ checked?: boolean }>`
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  display: flex;
  justify-content: space-between;

  &:hover,
  &:focus {
    border: 1px solid #4e4e4e;
  }
  ${({ checked }) =>
    checked &&
    css`
      border: 1.5px solid #4e4e4e;
      background-color: #f8f8f8;
      box-shadow: 2px 2px 4px rgb(60 60 59 / 15%);
      &:hover,
      &:focus {
        border: 1px solid #4e4e4e;
      }
    `}
`;

export const DropOffContainer = styled.div`
  margin-bottom: 8px;
  height: auto;
  &:last-of-type {
    margin: 0;
  }
`;

export const DropOffContentBox = styled.div`
  margin: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ${({ theme }) => theme.largeUp} {
  }

  @media ${({ theme }) => theme.mediumDown} {
    justify-content: space-between;
    .dropOffLocation {
      order: 2;
    }

    .googleMap {
      order: 1;
    }
  }
`;

export const DropOffWrapper = styled.div`
  overflow-y: auto;
  white-space: nowrap;

  @media ${({ theme }) => theme.largeUp} {
    width: 50%;
    max-height: 600px;
    padding-right: 1rem;
  }

  @media ${({ theme }) => theme.mediumDown} {
    width: 100%;
  }
`;

export const DropOffMapWrapper = styled.div`
  @media ${({ theme }) => theme.largeUp} {
    width: 50%;
  }

  @media ${({ theme }) => theme.mediumDown} {
    width: 100%;
  }
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  margin-bottom: 12px;

  .MuiFormControlLabel-label {
    margin-left: 12px;
    color: ${({ theme }) => theme.bodyFontColor};
    font-family: ${({ theme }) => theme.bodyFontFamily};
  }

  @media ${({ theme }) => theme.mediumUp} {
    margin-bottom: 0;
    /* stylelint-disable */
    min-height: 40px;
    /* stylelint-enable */
  }
`;

export const UnpackedContentBox = styled.div`
  margin: 20px 0 0;

  @media ${({ theme }) => theme.largeUp} {
    display: flex;
  }
`;

export const UnpackedBoxWrapper = styled.div`
  @media ${({ theme }) => theme.largeUp} {
    width: 50%;
    display: flex;
  }
`;

export const UnpackedBoxLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #2e2e2e;
  margin-left: 5px;
`;

export const UnpackedBoxText = styled.div`
  width: 85%;
`;
