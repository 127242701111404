import React from 'react';
import { TagButton, TagText, TagContainer } from './Tag.styled';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBMiniIconsEnum } from 'constants/PBIconsEnums';

export const Tag: React.FC<OwnProps> = (props) => {
  const { phoneNumber, subscriptionId, handleRemoveSmsSubscription } = props;

  return (
    <TagContainer data-test-id="sms-tag-container">
      <TagText data-test-id="sms-tag-value">{phoneNumber}</TagText>
      <TagButton
        data-test-id="sms-tag-delete-button"
        type="button"
        onClick={() => handleRemoveSmsSubscription(subscriptionId)}
        aria-label="delete-sms-subscription-button">
        <PBIcon type={PBIconTypeEnum.Mini} name={PBMiniIconsEnum.Close} />
      </TagButton>
    </TagContainer>
  );
};

interface OwnProps {
  phoneNumber: string;
  subscriptionId: string;
  handleRemoveSmsSubscription: (subscriptionId: string) => void;
}
