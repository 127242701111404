import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
export const USPSFormContainer = styled.form`
  width: 100%;
`;
export const CloseFormButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  i {
    font-weight: 300;
    font-size: 20px;
  }
`;

export const USPSFormText = styled.p`
  font-size: 14px;
  color: #4e4e4e;
  margin: 0 0 20px;
`;
export const ButtonsContainer = styled.div``;

export const USPSFormLegend = styled.legend<{ margin?: string }>`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: #4e4e4e;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  padding: 0;
  margin: ${({ margin }) => (margin ? margin : '0')};
`;
