import { Environment, resolveEnvironment } from 'utils/common/environment-resolver/environment-resolver';

export enum WillowBrandIds {
  PRD = 'NjOzhJSE',
  PPD = 'vmUmN83e',
  DEV = 'vmUmN83e',
  LOCAL = 'vmUmN83e'
}

export const launchDarklyResolveUserDefaultKey = (): string => {
  const environment: Environment = resolveEnvironment();
  switch (environment) {
    case Environment.AWS_PRD:
      return WillowBrandIds.PRD;
    case Environment.AWS_PPD:
      return WillowBrandIds.PPD;
    case Environment.AWS_DEV:
      return WillowBrandIds.DEV;
    default:
      return WillowBrandIds.LOCAL;
  }
};
