import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const OrderItemContainer = styled.li`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      margin-bottom: 0;
      background-color: white;
      padding: 10px 20px;
      border-bottom: 1px solid ${theme.gray900};
    `}
`;

export const CheckboxWrapper = styled.div``;

export const ItemImageWrapper = styled.div`
  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      max-width: 100px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      border: solid 1px #e1e1e1;
      max-width: 120px;

      @media ${theme.mediumUp} {
        max-width: 150px;
      }

      @media ${theme.xlargeUp} {
        max-width: 190px;
      }
    `}
`;

export const ItemTextWrapper = styled.div`
  padding: 15px 0 15px 15px;
  flex-grow: 2;
`;

export const TopElementsWrapper = styled.div`
  @media ${({ theme }) => theme.mediumUp} {
    display: flex;
    justify-content: space-between;
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      margin-bottom: 3px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      margin-bottom: 15px;
    `}
`;

export const MediumElementsWrapper = styled.div``;

export const BottomElementsWrapper = styled.div`
  margin-top: 20px;
`;

export const ItemName = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: #6b6b6b;
  padding: 0;
  font-weight: bold;
  font-size: 14px;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      margin: 0;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      margin: 0 0 10px 0;
      @media ${theme.mediumUp} {
        margin: 0;
      }
    `}
`;

export const ItemSku = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: #6b6b6b;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const ItemNormalText = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: #6b6b6b;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const ItemPrice = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: #6b6b6b;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const ItemQuantity = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: #6b6b6b;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  padding: 0;
`;
