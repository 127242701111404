import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const DropOffLocationsFormWrapper = styled.form`
  margin: 0 0 25px 0;
`;

export const DropOffLocationsInputsContainer = styled.div`
  margin: 15px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.xlargeUp} {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
`;

export const DropOffLocationsFormHeading = styled.h4`
  margin: 0;
  font-weight: normal;
  color: ${({ theme }) => theme.bodyFontColor};
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  font-size: 14px;
`;

export const InputsSeparator = styled.span`
  width: 100%;
  margin: 21px 0 0;
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.xlargeUp} {
    height: 61px;
    margin: 0;
    width: auto;
    align-items: flex-end;
  }
`;
