import styled from '@emotion/styled';

export const ReturnIconsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ReturnIconsList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;

  @media ${({ theme }) => theme.largeUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
