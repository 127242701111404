import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { shareOnMobile } from 'react-mobile-share';
import Terms from './Terms';
import { Button } from 'react-bootstrap';

export default function Share() {
  const [shareMobileData, setShareMobileData] = useState({});
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isShowLoaderCopy, setIsShowLoaderCopy] = useState(false);
  const [backgroundClass, setBackgroundClass] = useState('r-background-birthday');
  const item = localStorage.getItem('occasion');
  let imageurl = '/assets/img/Birthday-BG.png';

  if (item === 'Birthday') {
    imageurl = '/assets/img/Birthday-BG.png';
  } else if (item === 'Annivesary') {
    imageurl = '/assets/img/Anniversary-BG.png';
  } else if (item === 'Graduation') {
    imageurl = '/assets/img/Graduation-BG.png';
  } else if (item === 'Thanks') {
    imageurl = '/assets/img/Thanks-BG.png';
  } else if (item === 'Baby') {
    imageurl = '/assets/img/Baby-BG.png';
  } else if (item === 'Other') {
    imageurl = '/assets/img/Other-BG.png';
  }

  const [showInput, setShowInput] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [text, setText] = useState('');
  const [input, setInput] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [showTerms, setShowTerms] = useState(false);
  const handleTermClose = () => setShowTerms(false);
  const handleTermShow = () => setShowTerms(true);
  const termsClick = () => {
    setShowTerms(true);
  };

  useEffect(() => {
    // set the background as per the occasion
    if (item) setBackgroundClass(`r-background-${item.toLowerCase()}`);
  }, [item]);

  const inputHandler = (e) => {
    setShowInput(false);
    setInput(e.target.value);
    setText(e.target.value);
  };

  const handleChange = (event) => {
    setShowCheck(false);
    setIsSubscribed((current) => !current);
  };

  const handleLabelClick = (event) => {
    event.preventDefault(); // Prevent default behavior of label click
  };

  const handleback = () => {
    window.history.go(-1);
  };

  const showToastMessage = () => {
    toast.success('Copied to clipboard !', {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  const copy = async (e) => {
    if (text == '') {
      setShowInput(true);
    }
    if (isSubscribed === false) {
      setShowCheck(true);
    }
    if (text !== '' && isSubscribed === true) {
      const trackingnumber = localStorage.getItem('tracking') ?? '';
      const cleanedTrackingNumber = trackingnumber.replace(/^"(.*)"$/, '$1');
      // Prepare params
      const params = {
        id: localStorage.getItem('UniqueId'),
        nickName: text
      };
      try {
        setIsShowLoaderCopy(true);
        // Send POST request using axios
        const response = await axios.get('/v1/getOccasionAndSetName', { params });
        const url = response.data.URL;
        // Check if response is successful
        if (response.status === 200) {
          console.log('Data sent successfully!');
          const el = document.createElement('input');
          el.value = url;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          showToastMessage();
        } else {
          console.log('Failed to send data.');
          //setShowServerError(true);
        }
        setIsShowLoaderCopy(false);
      } catch (error) {
        setIsShowLoaderCopy(false);
        console.error('Error:', error);
      }
    }
  };

  const onSubmit = async () => {
    if (text == '') {
      setShowInput(true);
    }
    if (isSubscribed === false) {
      setShowCheck(true);
    }

    if (text !== '' && isSubscribed === true) {
      const trackingnumber = localStorage.getItem('tracking') ?? '';
      const cleanedTrackingNumber = trackingnumber.replace(/^"(.*)"$/, '$1');
      // Prepare params
      const params = {
        id: localStorage.getItem('UniqueId'),
        nickName: text
      };

      try {
        setIsShowLoader(true);
        // Send POST request using axios
        const response = await axios.get('/v1/getOccasionAndSetName', { params });
        const subject = response.data.Occasions.subject;
        const body = response.data.Occasions.body;
        const url = response.data.URL;
        // Check if response is successful
        if (response.status === 200) {
          window.location.href = 'mailto: ?subject=' + subject + '&body=' + body + '%0D%0A' + url + '%0D%0A %0D%0A' + text + '';
        } else {
          console.log('Failed to send data.');
          setShowServerError(true);
        }
        setIsShowLoader(false);
      } catch (error) {
        setIsShowLoader(false);
        console.error('Error:', error);
      }
    }
    //e.preventDefault();
  };

  const onSubmitMobile = async () => {
    if (text == '') {
      setShowInput(true);
    }
    if (isSubscribed === false) {
      setShowCheck(true);
    }

    if (text !== '' && isSubscribed === true) {
      const trackingnumber = localStorage.getItem('tracking') ?? '';
      const cleanedTrackingNumber = trackingnumber.replace(/^"(.*)"$/, '$1');
      // Prepare params
      const params = {
        id: localStorage.getItem('UniqueId'),
        nickName: text
      };

      try {
        setIsShowLoader(true);
        // Send POST request using axios
        const response = await axios.get('/v1/getOccasionAndSetName', { params });
        const subject = response.data.Occasions.subject;
        const body = response.data.Occasions.body;
        const url = response.data.URL;
        // Check if response is successful
        if (response.status === 200) {
          const data = {
            url: url,
            title: body
          };
          setShareMobileData(data);
          shareOnMobile(data);
        } else {
          console.log('Failed to send data.');
          setShowServerError(true);
        }
        setIsShowLoader(false);
      } catch (error) {
        setIsShowLoader(false);
        console.error('Error:', error);
      }
    }
    //e.preventDefault();
  };

  return (
    <div>
      <h1 className="media_selection_title">
        <span className="mobile-lefticon" onClick={handleback}>
          <img src="/assets/img/left-icon.png" />
        </span>
        Almost done - Send your personalized gift tracker
      </h1>
      <div className={`media_selection_background r-background ${backgroundClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="addnickname-section">
                <h1>Add your name or nickname</h1>
                <input className="form-control" type="text" name="Name" placeholder="Name or NickName" onChange={inputHandler} maxLength={30} />
                <p className="charLeft">{30 - input.length} characters remaining</p>
                {showInput && <p className="shareerror">Enter your name or nickname</p>}
                <span>
                  <input type="checkbox" value={isSubscribed ? 'true' : 'false'} onChange={handleChange} id="subscribe" name="subscribe" />
                  <label htmlFor="subscribe" className="form-check-label" onClick={handleLabelClick}>
                    {' '}
                    I agree to{' '}
                    <a className="termsclick" onClick={termsClick}>
                      terms and conditions
                    </a>{' '}
                    , and acknowledge that content not in compliance with the policy may be removed without prior notice.
                  </label>
                </span>
                {showCheck && <p className="shareerror">Check I agree to terms and conditions</p>}

                <button type="button" className="btn btn-dark sharegift-button-desktop" onClick={onSubmit}>
                  Share the Gift tracking Link
                  {isShowLoader && (
                    <span>
                      <div className="dot-flashing"></div>
                    </span>
                  )}
                </button>
                {showServerError && (
                  <p className="shareerror">System is not responding. Wait a few minutes and try again.We apologize for the inconvenience.</p>
                )}

                <button type="button" className="btn btn-dark sharegift-button-mobile" onClick={onSubmitMobile}>
                  Continue
                  {isShowLoader && (
                    <span>
                      <div className="dot-flashing"></div>
                    </span>
                  )}
                </button>

                <p className="bottomtext">Alternatively, use copy link option to share the gift tracker link with the recipient.</p>
                <button type="button" className="btn btn-outline-dark copylink-button" onClick={copy}>
                  Copy the link
                  {isShowLoaderCopy && (
                    <span>
                      <div className="dot-flashing"></div>
                    </span>
                  )}
                </button>
                <ToastContainer />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="video-section">
                <video className="w-100" controls>
                  <source src="https://cdn.shipment.co/gift-tracker/video_with_new_logo.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showTerms} onHide={handleTermClose}>
        <Modal.Header closeButton>
          <Modal.Title className="termsheader">
            <h4>Terms and condition</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="termsbody">
          <Terms />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleTermClose} className="btn btn-dark">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
