import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.primaryColor600};
  padding: 5px 0 5px 10px;
  margin-bottom: 7px;
  margin-right: 10px;
`;

export const TagText = styled.span`
  color: ${({ theme }) => theme.white};
  font-size: 13px;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      font-size: 12px;

      @media ${theme.mediumUp} {
        font-size: 13px;
      }

      @media ${theme.largeUp} {
        font-size: 12px;
      }

      @media (min-width: 1400px) {
        font-size: 13px;
      }
    `}
`;

export const TagButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  padding: 0 10px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    transition: 0.3s ease-out;
    font-size: 10px;
    color: ${({ theme }) => theme.white};
  }

  &:hover {
    i {
      transition: 0.3s ease-out;
      color: ${({ theme }) => theme.primaryColor200};
    }
  }
`;
