import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const ExchangeOptionsListContainer = styled.div`
  margin: 40px 0 0 0;
  max-width: 650px;
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    margin: 20px 0 0 0;
    padding-left: 158px;
  }
`;

export const ExchangeOptionsListTitle = styled.h3`
  margin: 0 0 10px;
  font-size: 14px;
  font-weight: 300;
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;

export const ExchangeOptionsListWrapper = styled.ul``;
