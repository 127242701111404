import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomRoundStyle, StyledButton } from 'components/Shared/Button/Button.styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import { lighten } from 'polished';
import { CustomTextField } from 'components/Shared/styled-components/CustomTextField/CustomTextField.styled';

export const LabelEmailInputContainer = styled.div``;

export const Container = styled.div`
  position: relative;
  height: 72px;
  overflow: hidden;
`;

export const ButtonWrapper = styled.div<{ isActive: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  transition: transform 0.3s;
  width: 100%;
  background-color: #fff;

  ${({ isActive }) =>
    isActive &&
    css`
      transform: translateX(-105%);
    `}
`;

export const Button = styled(StyledButton)<{ isActive: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  transition: transform 0.3s;

  &:hover {
    background-color: ${({ theme }) => lighten(0.03, `${theme.secondaryColor}`)};
    opacity: 1;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      transform: translateX(-105%);
    `}
`;

export const FormContainer = styled.form`
  position: absolute;
  display: flex;
  width: 100%;
  height: 40px;

  @media ${({ theme }) => theme.mediumUp} {
    height: 36.5px;
  }

  @media ${({ theme }) => theme.largeUp} {
    height: 40px;
  }

  z-index: 1;
`;

export const LabelEmailCustomTextField = styled(CustomTextField)`
  input {
    @media ${({ theme }) => theme.mediumUp} {
      padding: 6.5px 14px;
    }

    @media ${({ theme }) => theme.largeUp} {
      padding: 8.5px 14px;
    }
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black;
    border-right: none;
  }

  & .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336;
    border-right: none;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: black;
    border-right: none;
    border-right-color: transparent;
  }

  .MuiOutlinedInput-root {
    border-color: black;
    border-right: none;
    border-radius: ${getCustomRoundStyle()};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const SubmitButton = styled(StyledButton)`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 62px;
  box-sizing: border-box;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left-color: ${({ theme }) => getCustomStyle('styleModule.secondaryColor', theme.buttonBackground)};
`;
