import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BannersLayout } from 'layouts/Shared/BannersLayout/BannersLayout';
import { shouldDisplayWeatherBanner } from 'utils/tracking/should-display-weather-banner/should-display-weather-banner';
import { WeatherBanner } from 'components/Shared/Banner/WeatherBanner/WeatherBanner';
import PageTitle from 'components/Shared/PageTitle/PageTitle';
import { AsideContent, MainContentContainer, MainContentWrapper, SpartanContainer } from './Spartan.styled';
import { Lead } from 'components/Tracking/Lead/Lead';
import { TrackingEvents } from 'components/Tracking/TrackingEvents/TrackingEvents';
import { ShippingInfo } from 'components/Tracking/ShippingInfo/ShippingInfo';
import { OrderDetails } from 'components/Tracking/OrderDetails/OrderDetails';
import { Notifications } from 'components/Tracking/Notifications/Notifications';
import { calculateDeliveryType } from 'utils/common/utils/utils';
import { Returns } from 'components/Tracking/Returns/Returns';
import { Feedback } from 'components/Tracking/Feedback/Feedback';
import { FeaturedProducts } from 'components/Tracking/FeaturedProducts/FeaturedProducts';
import { Promotions } from 'components/Tracking/Promotions/Promotions';
import { ExploreTheBrand } from 'components/Tracking/ExploreTheBrand/ExploreTheBrand';
import { CustomHtml } from 'components/Shared/CustomHtml/CustomHtml';
import { ClientConfigurationType } from 'types/shared/config/client-configuration-type';
import { TrackingConfigurationType } from 'types/tracking/trackingConfig/tracking-configuration-type';
import { WordPressBanners } from 'components/Shared/Banner/WordPressBanners/WordPressBanners';

export const Spartan: React.FC<OwnProps> = (props) => {
  const {
    clientConfig,
    trackingNumber,
    urlKey,
    isPreview,
    trackingData,
    showCustomHtml,
    showPromotional,
    showExploreProducts,
    showFeaturedProducts,
    showNotifications,
    setShowNotifications,
    setShowFeedback,
    showFeedback,
    setShowReturns,
    showReturns,
    isReturn,
    showOrderDetails
  } = props;

  const intl = useIntl();
  const { boxDocsBanners } = useFlags();

  return (
    <SpartanContainer data-test-id="tracking-spartan-container">
      <BannersLayout marginDefault="0 0 40px 0">
        {boxDocsBanners && <WordPressBanners />}
        {trackingData && shouldDisplayWeatherBanner(trackingData.trackingPackageStatus) && trackingData.trackingEventsModule.postalCode && (
          <WeatherBanner postalCode={trackingData.trackingEventsModule.postalCode} />
        )}
      </BannersLayout>
      <PageTitle title={intl.formatMessage({ id: 'TRACKING.page.title' })} brandName={clientConfig.brandName} />
      <MainContentContainer>
        <MainContentWrapper asideExist={showNotifications || showReturns || showFeedback}>
          {trackingData.trackingEventsModule && (
            <Lead
              headerModule={clientConfig.headerModule}
              trackingEventsModule={trackingData.trackingEventsModule}
              primaryTextKey={trackingData.primaryTextKey}
              trackingPackageStatus={trackingData.trackingPackageStatus}
              trackingData={trackingData}
              brandName={clientConfig.brandName}
            />
          )}
          {trackingData.trackingEventsModule && <TrackingEvents trackingEventsModule={trackingData.trackingEventsModule} />}
          <ShippingInfo
            trackingNumber={trackingNumber}
            orderDetailsModule={clientConfig.orderDetailsModule}
            shippingInfoModule={trackingData.shippingInfoModule}
            urlKey={trackingData.urlKey}
          />
          {showOrderDetails && (
            <OrderDetails
              orderDetailsModule={clientConfig.orderDetailsModule}
              vanityName={clientConfig.vanityName}
              urlKey={urlKey}
              isPreview={isPreview}
              shippingInfoModule={trackingData.shippingInfoModule}
            />
          )}
        </MainContentWrapper>

        {(showNotifications || showReturns || showFeedback) && (
          <AsideContent>
            {showNotifications && clientConfig.notificationsModule && (
              <Notifications
                setShowNotifications={setShowNotifications}
                vanityName={clientConfig.vanityName}
                urlKey={urlKey}
                notificationsModule={clientConfig.notificationsModule}
                trackingNumber={trackingNumber}
                deliveryType={calculateDeliveryType(isReturn)}
                bgColor={clientConfig.styleModule.primaryColor}
              />
            )}

            {showReturns && (
              <Returns
                bgColor={clientConfig.styleModule.primaryColor}
                delivered={trackingData.trackingEventsModule && trackingData.trackingEventsModule.packageDelivered}
                trackingNumber={trackingData.shippingInfoModule && trackingData.shippingInfoModule.trackingNumber}
                urlKey={urlKey}
                designId={clientConfig.designId}
                setShowReturns={setShowReturns}
              />
            )}

            {showFeedback && (
              <Feedback
                bgColor={clientConfig.styleModule.primaryColor}
                title={clientConfig.feedbackQuestionsModule && clientConfig.feedbackQuestionsModule.headerText}
                trackingNumber={trackingNumber}
                isPreview={isPreview}
                isReturn={isReturn}
                setShowFeedback={setShowFeedback}
              />
            )}
          </AsideContent>
        )}
      </MainContentContainer>

      {showFeaturedProducts && <FeaturedProducts featuredProductsModule={clientConfig.featuredProductsModule} />}

      {showPromotional && <Promotions promotionsModule={clientConfig.promotionsAddonModule} />}

      {showExploreProducts && <ExploreTheBrand exploreTheBrandModule={clientConfig.exploreTheBrandModule} />}

      {showCustomHtml && <CustomHtml customHtmlModule={clientConfig.customHtmlModule} />}
    </SpartanContainer>
  );
};

export interface OwnProps {
  clientConfig: ClientConfigurationType;
  trackingNumber: string;
  urlKey: string | null;
  isPreview: boolean;
  trackingData: TrackingConfigurationType;
  showNotifications: boolean;
  setShowNotifications: Dispatch<SetStateAction<boolean>>;
  showReturns: boolean;
  setShowReturns: Dispatch<SetStateAction<boolean>>;
  showFeedback: boolean;
  setShowFeedback: Dispatch<SetStateAction<boolean>>;
  isReturn: boolean;
  showOrderDetails: boolean;
  showFeaturedProducts: boolean;
  showPromotional: boolean;
  showExploreProducts: boolean;
  showCustomHtml: boolean;
}
