export const sliderProps = {
  slidesToShow: 2,
  slidesToScroll: 1,
  infinite: false,
  arrows: false,
  centerMode: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1.96,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        centerMode: false
      }
    }
  ]
};
