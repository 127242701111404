import { RootProductAttribute } from 'types/return/overview/overview-api-response-type';
import { RootProductAttributeView } from 'models/returns/selected-product/SelectedProduct';

export const transformRootProductAttributesToRootProductAttributesViews = (
  rootProductAttributes: RootProductAttribute[],
  sortArray: boolean
): RootProductAttributeView[] => {
  if (sortArray)
    return rootProductAttributes
      .sort((a: RootProductAttribute, b: RootProductAttribute) => a.attributeNameOrder - b.attributeNameOrder)
      .map((element) => ({
        attributeName: element.attributeName,
        attributeValue: element.attributeValue
      }));
  return rootProductAttributes.map((element: RootProductAttribute) => ({
    attributeName: element.attributeName,
    attributeValue: element.attributeValue
  }));
};
