import React from 'react';
import { ThemeOptions } from '@mui/material';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { isString, startsWith } from 'lodash';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    small: true;
    medium: true;
    large: true;
    xlarge: true;
    xxlarge: true;
  }

  interface Palette {
    button: {
      secondaryBackgroundDark: boolean;
      buttonStyle: string;
      buttonBackground: React.CSSProperties['color'];
      buttonColor: React.CSSProperties['color'];
      buttonColorDark: React.CSSProperties['color'];
    };
  }

  interface PaletteOptions {
    button?: {
      secondaryBackgroundDark: boolean;
      buttonStyle: string;
      buttonBackground: React.CSSProperties['color'];
      buttonColor: React.CSSProperties['color'];
      buttonColorDark: React.CSSProperties['color'];
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to']; target?: string }>((props, ref) => {
  const { href, target, ...other } = props;

  const isExternal = isString(href) && (startsWith(href, 'http') || startsWith(href, 'mailto:') || startsWith(href, '/api'));

  if (isExternal) {
    return <a ref={ref} target={target ? target : '_blank'} rel="noreferrer" href={href} {...other} />;
  }
  // Map href (MUI) -> to (react-router)
  return <RouterLink data-test-id="custom-link" ref={ref} to={href} {...other} />;
});

LinkBehavior.displayName = 'LinkBehavior';

export const getDesignTokens = (
  fontFamily: string,
  secondaryColor: string,
  secondaryBackgroundDark: boolean,
  buttonBackground: string,
  buttonColor: string,
  buttonColorDark: string,
  buttonStyle: string
): ThemeOptions => ({
  typography: {
    fontFamily: fontFamily
  },
  palette: {
    button: {
      secondaryBackgroundDark: secondaryBackgroundDark,
      buttonStyle: buttonStyle,
      buttonBackground: buttonBackground,
      buttonColor: buttonColor,
      buttonColorDark: buttonColorDark
    },
    primary: {
      main: '#000'
    },
    secondary: {
      main: secondaryColor
    },
    error: {
      main: '#f44336'
    }
  },
  breakpoints: {
    values: {
      small: 0,
      medium: 576,
      large: 768,
      xlarge: 992,
      xxlarge: 1200
    }
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
          color: '#f44336',
          margin: '4px 0 0 4px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            color: 'black',
            borderColor: 'black'
          },
          '& .MuiOutlinedInput-root:hover.Mui-error  .MuiOutlinedInput-notchedOutline': {
            color: 'black',
            borderColor: '#f44336'
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black !important'
          },
          '& .Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important'
          },
          '& .Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336'
          }
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: false
      },
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            margin: '0 10px',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            maxHeight: 'calc(100% - 10px)'
          }
        }
      }
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#00000014',
            '&:hover': {
              backgroundColor: '#00000014'
            }
          }
        }
      }
    }
  }
});
