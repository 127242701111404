import React from 'react';
import { Breakpoint } from 'constants/Breakpoint';
import { StyledImage } from './ResponsiveImage.styled';
import { ResponsiveImageSizesType } from 'types/shared/responsive-image-sizes-type';

const ResponsiveImage: React.FC<OwnProps> = (props) => {
  const { sizes, url, alt, title, className, dataGtmId, testId } = props;

  const sizesKeys = Object.keys(sizes);
  const largestSizeKey = sizesKeys[sizesKeys.length - 1];
  const largestSize = sizes[largestSizeKey];
  const breakpoints = Breakpoint;

  const getMedia = (breakpoint: number): string => {
    return `(max-width: ${breakpoint}px)`;
  };

  return (
    <picture className={className}>
      {sizesKeys &&
        sizesKeys.map((size, index, sizesKeysArray) => {
          const isLast = sizesKeysArray.length === index + 1;
          return (
            <source
              key={index}
              media={!isLast ? getMedia(breakpoints[size]) : undefined}
              srcSet={`${url}?w=${sizes[size]} 1x, ${url}?w=${sizes[size] * 2} 2x`}
            />
          );
        })}

      <StyledImage
        src={`${url}?w=${largestSize}`}
        title={title}
        alt={alt}
        data-gtm-id={dataGtmId}
        data-test-id={testId ? testId : 'responsive-image__image'}
      />
    </picture>
  );
};

export interface OwnProps {
  sizes: ResponsiveImageSizesType;
  url: string;
  alt: string;
  title: string;
  className?: string;
  dataGtmId?: string;
  testId?: string;
}

export default ResponsiveImage;
