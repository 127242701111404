import { useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { ButtonBox, ButtonLabel, IconBox } from './BackToTopButton.styled';
import { PBOutlineIconsEnum } from 'constants/PBIconsEnums';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';

export const BackToTopButton: React.FC<OwnProps> = (props) => {
  const { fontFamily, fontColor, visibleAbove } = props;

  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > visibleAbove) return setIsVisible(true);
      return setIsVisible(false);
    };

    window.addEventListener('scroll', toggleVisible);

    return () => window.removeEventListener('scroll', toggleVisible);
  }, [visibleAbove]);

  const moveToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const theme = useTheme();
  const mediumUp = useMediaQuery(theme.breakpoints.up('medium'));

  return (
    <ButtonBox isVisible={isVisible} onClick={moveToTop}>
      <IconBox color={fontColor}>
        <PBIcon name={PBOutlineIconsEnum.MinimalUp} type={PBIconTypeEnum.Outline} />
      </IconBox>
      <ButtonLabel fontFamily={fontFamily} fontColor={fontColor}>
        {mediumUp ? 'Back To Top' : 'Top'}
      </ButtonLabel>
    </ButtonBox>
  );
};

interface OwnProps {
  fontFamily: string;
  fontColor: string;
  visibleAbove: number;
}
