import React, { ChangeEvent, FormEvent, KeyboardEvent, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  ButtonsContainer,
  ButtonsWrapper,
  CustomFormControlLabel,
  ReviewFormContainer,
  ReviewFormEmailLabel,
  ReviewFormFieldset,
  ReviewFormGroup,
  ReviewFormLabel,
  ReviewFormLegend,
  ReviewFormLegendDescription,
  ReviewFormLegendWrapper,
  ReviewSelect,
  ShipFromAddressTextFieldsWrapper
} from './ReviewForm.styled';
import { CustomFormControl } from 'components/Shared/styled-components/CustomFormControl/CustomFormControl.styled';
import { CustomTextField } from 'components/Shared/styled-components/CustomTextField/CustomTextField.styled';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import { isEmailValid } from 'utils/shared/email-regex/email-regex';
import { isNumberValid } from 'utils/shared/number-regex/number-regex';
import { enhanceZipCodeValue } from 'utils/shared/enhance-zip-code-value/enhance-zip-code-value';
import { isZipCodeValid } from 'utils/shared/zip-code-regex/zip-code-regex';
import { CharacterCounter } from 'components/Shared/CharacterCounter/CharacterCounter';
import { isInputValid } from 'utils/shared/basic-input-regex/basic-input-regex';
import { isFullNameValid } from 'utils/shared/full-name-regex/full-name-regex';
import { isAddressValid } from 'utils/shared/address-regex/address-regex';
import { isCityValid } from 'utils/shared/city-regex/city-regex';
import { states } from 'constants/states';
import { ReviewContext } from 'context/returns/ReviewContext';
import { ReturnDetails } from 'models/returns/return-details/return-details';
import { MethodEnum, ShippingTypeEnum, CarriersEnum } from 'types/return/overview/methods/method-types';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AlertErrorTitle, OverviewContext } from 'context/returns/OverviewContext';
import { useIntl } from 'react-intl';
import { enhanceMaxLengthInputValue } from 'utils/shared/enhance-max-length-input-value/enhance-max-length-input-value';
import { ShippingAddressSummary } from './ShippingAddressSummary/ShippingAddressSummary';
import { scrollToError } from 'utils/returns/overview/common/scroll-to-error/scroll-to-error';
import { UrlKeyContext } from 'context/returns/UrlKeyContext';
import { isFirstLetterVowel } from 'utils/shared/articles/articles';
import { SubpageHeading } from 'components/Returns/Common/SubpageHeading/SubpageHeading';
import { Alert } from 'components/Shared/Alert/Alert';
import { scrollToTop } from 'utils/returns/overview/common/scroll-to-top/scroll-to-top';
import { Checkbox } from 'components/Shared/Checkbox/Checkbox';
import { buildSearchUrl } from 'utils/returns/overview/common/build-search-url/build-search-url';
import { OverviewFormStepsEnum } from 'types/return/overview/overview-api-response-type';
import { ReturnNotificationsModuleType } from 'types/return/shared/return-notifications-module-type';
import { OverviewReturnsModuleType } from 'types/return/overview/returns-module/overview-returns-module-type';
import { SortCodeType } from 'types/return/shared/disposition-sort-code-type';
import { States } from 'types/return/overview/review/states-types';
import { ReturnClientReturnType } from 'types/return/shared/client-return-type';
import { MethodsContext } from 'context/returns/MethodsContext';
import { isMethodsStepAvailable, isShippingUPSAvailable } from 'utils/returns/overview/review/is-methods-step-available/is-methods-step-available';
import { isUserAuthenticated } from 'utils/shared/is-user-authenticated/is-user-authenticated';
import { isIE } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { CustomLoadingButton } from 'components/Shared/styled-components/CustomLoadingButton/CustomLoadingButton.styled';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { SelectedProductsReviewList } from '../SelectedProductsReviewList/SelectedProductsReviewList';
import { ProductsSelectionContext } from 'context/returns/ProductsSelectionContext';
import { transformSelectedProducts } from 'utils/returns/overview/common/transform-selected-products/transform-selected-products';
import { calculateTotalSkus } from 'utils/returns/overview/review/calculate-total-skus/calculate-total-skus';
import { calculateSelectedProducts } from 'utils/returns/overview/review/calculate-selected-products/calculate-selected-products';
import { calculateUiSelectionItems } from 'utils/returns/overview/review/calculate-ui-selection-items/calculate-ui-selection-items';
import { OrderItemsContext } from 'context/returns/OrderItemsContext';

export const ReviewForm: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { returnsModule, orderNumber, shippingPostalCode, clientReturnType, notificationsModule } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const { setStep } = useContext(OverviewContext);
  const { brandId, vanityName } = useContext(ClientConfigContext);
  const { orderItems } = useContext(OrderItemsContext);
  const { selectedProducts } = useContext(ProductsSelectionContext);
  const { allItemsHaveShippingTypeNull, serviceLevelShouldDisplayOnlyExpedited, method } = useContext(MethodsContext);
  const { urlKey } = useContext(UrlKeyContext);

  const {
    sapiQrCodeCbconnect2705,
    returnsRemovePhoneNumberCoco159,
    returnsUps2DayCoco264,
    sapiReturnLabelsCbconnect2631,
    attCustomerCareModificationsCoco519,
    multiCarrierUps,
    attMultiCarrierUps,
    atTZipCodeExapps8637
  } = useFlags();

  //const atTZipCodeExapps8637 = true;

  let carrier = CarriersEnum.USPS;
  let shippingMethod = ShippingTypeEnum.Standard;
  const intl = useIntl();

  const CREATE_RETURN_API = `/api/return/createLabel/${vanityName}?inputOrderId=${orderNumber}${
    shippingPostalCode ? `&shippingPostalCode=${shippingPostalCode}` : ''
  }${!!urlKey ? `&key=${urlKey}` : ''}`;

  // fix: can't perform a react state update on an unmounted component
  const mounted = useRef(false);

  const {
    orderLevelComment,
    setOrderLevelComment,
    orderLevelCommentError,
    setOrderLevelCommentError,

    customField,
    setCustomField,
    customFieldError,
    setCustomFieldError,

    fullName,
    setFullName,
    fullNameError,
    setFullNameError,

    addressOne,
    setAddressOne,
    addressOneError,
    setAddressOneError,

    addressTwo,
    setAddressTwo,
    addressTwoError,
    setAddressTwoError,

    city,
    setCity,
    cityError,
    setCityError,

    selectedState,
    setSelectedState,
    selectedStateError,
    setSelectedStateError,

    zipCode,
    setZipCode,
    zipCodeError,
    setZipCodeError,

    email,
    setEmail,
    emailError,
    setEmailError,

    number,
    setNumber,
    numberError,
    setNumberError,

    emailNotificationChecked,
    setEmailNotificationChecked,
    emailNotification,
    setEmailNotification,
    emailNotificationError,
    setEmailNotificationError,

    numberNotificationChecked,
    setNumberNotificationChecked,
    numberNotification,
    setNumberNotification,
    numberNotificationError,
    setNumberNotificationError,

    dispositionRuleType,
    sortCodeLabel,
    sortCodes,
    sortCodeSelection,
    setSortCodeSelection,
    selectedSortCode,
    initialShipFromAddressValid,
    setValuesFromInitialShipFromAddressForForm,
    showShippingFormAddressForm,
    setShowShippingFormAddressForm,
    areAllRequiredFieldsCorrect
  } = useContext(ReviewContext);

  const { isFromSearchOrder, formError, setFormError, formErrorsList, setFormErrorsList } = useContext(OverviewContext);
  const navigate = useNavigate();
  const [restrictedAddress, setRestrictedAddress] = useState<boolean>(false);
  const [isUspsFromZipCode, setIsUspsFromZipCode] = useState<boolean>(true);
  const [eventZipCode, setEventZipCode] = useState<string>('');
  //checkFormForRestrictedAddress();

  useEffect(() => {
    const fetchZipCodeData = async () => {
      try {
        if (/^\d{5}(-\d{4})?$/.test(eventZipCode)) {
          const response = await axios.get(`/api/zipCodes/findByZipCode?zipcode=${eventZipCode}`);
          setIsUspsFromZipCode(response.data.uspsfromZipCode);
        }
      } catch (error) {
        //console.error('Error fetching data:', error);
      }
    };

    if (atTZipCodeExapps8637) {
      fetchZipCodeData();
    }

    localStorage.setItem('eventZipCode', eventZipCode);

    const checkFormForRestrictedAddress = (): void => {
      // const checkFormForRestrictedAddress = () => {
      let hazmatItemCount1 = 0;
      Object.keys(selectedProducts).forEach((product) => {
        if (selectedProducts[product]?.hazmat === true && selectedProducts[product]?.isChecked === true) {
          hazmatItemCount1++;
        }
      });
      if (hazmatItemCount1 > 0 && (selectedState == 'AA' || selectedState == 'AE' || selectedState === 'AP')) {
        setRestrictedAddress(true);
        setFullNameError(' ');
        setAddressOneError(' ');
        setCityError(' ');
        setSelectedStateError(' ');
        setZipCodeError(' ');
        scrollToError();
      } else if (hazmatItemCount1 === 0) {
        setRestrictedAddress(false);
        setFullNameError('');
        setAddressOneError('');
        setCityError('');
        setSelectedStateError('');
        setZipCodeError('');
      }
    };
    checkFormForRestrictedAddress();
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [
    eventZipCode,
    selectedProducts,
    restrictedAddress,
    selectedState,
    setFullNameError,
    setAddressOneError,
    setCityError,
    setSelectedStateError,
    setZipCodeError,
    isUspsFromZipCode,
    atTZipCodeExapps8637
  ]);
  const handleChangeOrderLevelComment = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setOrderLevelComment(enhanceMaxLengthInputValue(250, value));

    if (!isInputValid(enhanceMaxLengthInputValue(250, value)))
      return setOrderLevelCommentError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.orderLevelComment.input.error.regex'
        })
      );
    return setOrderLevelCommentError('');
  };

  const handleChangeCustomField = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setCustomField(value);

    if (returnsModule.returnsCustomField.required && value.length === 0)
      return setCustomFieldError(
        intl.formatMessage(
          {
            id: 'RETURN.overview.review.form.customField.input.error.empty'
          },
          {
            article: isFirstLetterVowel(!!returnsModule.returnsCustomField.label ? returnsModule.returnsCustomField.label : ''),
            label: returnsModule.returnsCustomField.label?.toLowerCase()
          }
        )
      );
    if (value.length === 0) return setCustomFieldError('');
    if (!isInputValid(value))
      return setCustomFieldError(
        intl.formatMessage(
          {
            id: 'RETURN.overview.review.form.customField.input.error.regex'
          },
          {
            article: isFirstLetterVowel(!!returnsModule.returnsCustomField.label ? returnsModule.returnsCustomField.label : ''),
            label: returnsModule.returnsCustomField.label?.toLowerCase()
          }
        )
      );
    return setCustomFieldError('');
  };

  const handleChangeFullName = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setFullName(value);

    if (value.length === 0)
      return setFullNameError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.fullName.input.error.empty'
        })
      );
    if (!isFullNameValid(value))
      return setFullNameError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.fullName.input.error.regex'
        })
      );
    return setFullNameError('');
  };

  const handleChangeAddressOne = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setAddressOne(value);

    if (value.length === 0)
      return setAddressOneError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.addressOne.input.error.empty'
        })
      );
    if (!isAddressValid(value))
      return setAddressOneError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.addressOne.input.error.regex'
        })
      );
    return setAddressOneError('');
  };

  const handleChangeAddressTwo = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setAddressTwo(value);

    if (value.length === 0) return setAddressTwoError('');
    if (!isAddressValid(value))
      return setAddressTwoError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.addressTwo.input.error.regex'
        })
      );
    return setAddressTwoError('');
  };

  const handleChangeCity = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setCity(value);

    if (value.length === 0)
      return setCityError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.city.input.error.empty'
        })
      );
    if (!isCityValid(value))
      return setCityError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.city.input.error.regex'
        })
      );
    return setCityError('');
  };

  const handleChangeState = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { target } = event;
    const { value } = target;
    setSelectedState(value);
    let hazmatItemCount = 0;

    Object.keys(selectedProducts).forEach((product) => {
      if (selectedProducts[product]?.hazmat === true && selectedProducts[product]?.isChecked === true) {
        hazmatItemCount++;
      }
    });
    if (hazmatItemCount > 0 && (target.value == 'AA' || target.value == 'AE' || target.value === 'AP')) {
      setRestrictedAddress(true);
      setFullNameError(' ');
      setAddressOneError(' ');
      setCityError(' ');
      setSelectedStateError(' ');
      setZipCodeError(' ');
      return scrollToError();
    } else {
      setRestrictedAddress(false);
      setFullNameError('');
      setAddressOneError('');
      setCityError('');
      setSelectedStateError('');
      setZipCodeError('');
    }

    if (value.length === 0)
      return setSelectedStateError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.state.input.error.empty'
        })
      );
    setSelectedStateError('');
  };

  const handleChangeZipCode = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setZipCode(enhanceZipCodeValue(value));

    if (value.length === 0)
      return setZipCodeError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.zipCode.input.error.empty'
        })
      );
    if (!isZipCodeValid(enhanceZipCodeValue(value)))
      return setZipCodeError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.zipCode.input.error.regex'
        })
      );
    setZipCodeError('');
    setEventZipCode(enhanceZipCodeValue(value));
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setEmail(value);

    if (value.length === 0)
      return setEmailError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.email.input.error.empty'
        })
      );
    if (!isEmailValid(value))
      return setEmailError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.email.input.error.regex'
        })
      );
    setEmailError('');
  };

  const handleChangeNumber = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setNumber(value);

    if (value.length === 0) return setNumberError('');
    if (!isNumberValid(value))
      return setNumberError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.shipFromAddress.number.input.error.regex'
        })
      );
    setNumberError('');
  };

  const handleChangeEmailNotificationChecked = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { checked } = target;
    setEmailNotification('');
    setEmailNotificationError('');
    setEmailNotificationChecked(checked);
  };

  const handleChangeNumberNotificationChecked = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { checked } = target;
    setNumberNotification('');
    setNumberNotificationError('');
    setNumberNotificationChecked(checked);
  };

  const handleChangeEmailNotification = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setEmailNotification(value);

    if (value.length === 0)
      return setEmailNotificationError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.notifications.emailNotification.input.error.empty'
        })
      );
    if (!isEmailValid(value))
      return setEmailNotificationError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.notifications.emailNotification.input.error.regex'
        })
      );
    setEmailNotificationError('');
  };

  const handleChangeNumberNotification = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setNumberNotification(value);

    if (value.length === 0)
      return setNumberNotificationError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.notifications.numberNotification.input.error.empty'
        })
      );
    if (!isNumberValid(value))
      return setNumberNotificationError(
        intl.formatMessage({
          id: 'RETURN.overview.review.form.notifications.numberNotification.input.error.regex'
        })
      );
    setNumberNotificationError('');
  };

  const handleChangeSortCode = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { target } = event;
    const { value } = target;
    setSortCodeSelection(value);
  };

  const handlePreventSubmitOnEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      return;
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (!areAllRequiredFieldsCorrect) return;

    const errorsArray: string[] = [];

    const instantSubmitFormValidation = async () => {
      if (returnsModule.returnsCustomField.enabled && returnsModule.returnsCustomField.required && customField.length === 0) {
        errorsArray.push('0');
        setCustomFieldError(
          intl.formatMessage(
            {
              id: 'RETURN.overview.review.form.customField.input.error.empty'
            },
            {
              article: isFirstLetterVowel(!!returnsModule.returnsCustomField.label ? returnsModule.returnsCustomField.label : ''),
              label: returnsModule.returnsCustomField.label?.toLowerCase()
            }
          )
        );
      }
      if (fullName.length === 0) {
        errorsArray.push('1');
        setFullNameError(
          intl.formatMessage({
            id: 'RETURN.overview.review.form.shipFromAddress.fullName.input.error.empty'
          })
        );
      }
      if (addressOne.length === 0) {
        errorsArray.push('2');
        setAddressOneError(
          intl.formatMessage({
            id: 'RETURN.overview.review.form.shipFromAddress.addressOne.input.error.empty'
          })
        );
      }
      if (city.length === 0) {
        errorsArray.push('3');
        setCityError(
          intl.formatMessage({
            id: 'RETURN.overview.review.form.shipFromAddress.city.input.error.empty'
          })
        );
      }
      if (selectedState.length === 0) {
        errorsArray.push('4');
        setSelectedStateError(
          intl.formatMessage({
            id: 'RETURN.overview.review.form.shipFromAddress.state.input.error.empty'
          })
        );
      }
      if (zipCode.length === 0) {
        errorsArray.push('5');
        setZipCodeError(
          intl.formatMessage({
            id: 'RETURN.overview.review.form.shipFromAddress.zipCode.input.error.empty'
          })
        );
      }
      if (!returnsUps2DayCoco264 && email.length === 0) {
        errorsArray.push('6');
        setEmailError(
          intl.formatMessage({
            id: 'RETURN.overview.review.form.shipFromAddress.email.input.error.empty'
          })
        );
      }
      if (emailNotificationChecked && emailNotification.length === 0) {
        errorsArray.push('7');
        setEmailNotificationError(
          intl.formatMessage({
            id: 'RETURN.overview.review.form.notifications.emailNotification.input.error.empty'
          })
        );
      }
      if (numberNotificationChecked && numberNotification.length === 0) {
        errorsArray.push('8');
        setNumberNotificationError(
          intl.formatMessage({
            id: 'RETURN.overview.review.form.notifications.numberNotification.input.error.empty'
          })
        );
      }
    };

    await instantSubmitFormValidation();

    if (
      errorsArray.length > 0 ||
      !!orderLevelCommentError ||
      !!customFieldError ||
      !!fullNameError ||
      !!addressOneError ||
      !!addressTwoError ||
      !!cityError ||
      !!selectedStateError ||
      !!zipCodeError ||
      !!emailError ||
      !!numberError ||
      !!emailNotificationError ||
      !!numberNotificationError
    ) {
      window.dataLayer.push({
        event: isMethodsStepAvailable(
          sapiQrCodeCbconnect2705,
          returnsModule.shippingType.standard.enabled,
          returnsModule.shippingType.expedited.enabled,
          allItemsHaveShippingTypeNull,
          serviceLevelShouldDisplayOnlyExpedited
        )
          ? 'reviewSubpageContinueButtonFailure'
          : 'reviewSubpageCreateLabelButtonFailure'
      });
      return scrollToError();
    }

    setFormError(null);
    setFormErrorsList([]);

    if (atTZipCodeExapps8637) {
      if (!isUspsFromZipCode) {
        carrier = CarriersEnum.USPS;
        shippingMethod = ShippingTypeEnum.Standard;
      } else {
        window.dataLayer.push({ event: 'reviewSubpageContinueButtonSuccess' });
        return setStep(OverviewFormStepsEnum.Three);
      }
    } else {
      if (
        (multiCarrierUps &&
          isShippingUPSAvailable(returnsModule) &&
          Object.keys(selectedProducts).filter(
            (id) => selectedProducts[id]?.carrier?.toLowerCase() === CarriersEnum.UPS && selectedProducts[id]?.isChecked === true
          ).length >= 1) ||
        (attMultiCarrierUps && clientReturnType === 'nonintegrated' && isShippingUPSAvailable(returnsModule))
      ) {
        carrier = CarriersEnum.UPS;
        shippingMethod = ShippingTypeEnum.UPS;
      } else if (
        isMethodsStepAvailable(
          sapiQrCodeCbconnect2705,
          returnsModule.shippingType.standard.enabled,
          returnsModule.shippingType.expedited.enabled,
          allItemsHaveShippingTypeNull,
          serviceLevelShouldDisplayOnlyExpedited
        )
      ) {
        window.dataLayer.push({ event: 'reviewSubpageContinueButtonSuccess' });
        return setStep(OverviewFormStepsEnum.Three);
      }
    }

    const fetchHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    };

    const returnDetails = new ReturnDetails(
      orderLevelComment,
      transformSelectedProducts(selectedProducts),
      orderNumber,
      {
        fullName,
        addressOne,
        addressTwo,
        city,
        state: selectedState,
        zip: zipCode,
        email,
        phone: number
      },
      {
        notificationEmail: emailNotification,
        notificationSms: numberNotification
      },
      MethodEnum.Label,
      selectedSortCode,
      shippingMethod,
      customField
    );

    const params = enhanceFetchParams({
      method: 'POST',
      headers: enhanceHeadersParams(brandId, fetchHeaders),
      body: JSON.stringify(returnDetails)
    });

    if (clientReturnType === 'integrated') {
      window.dataLayer.push({
        event: 'exchangesUiCounting',
        exchangesUiSelectionCount: calculateUiSelectionItems(selectedProducts),
        exchangesSkuCount: calculateTotalSkus(selectedProducts),
        exchangesExchangeProductCount: calculateSelectedProducts(selectedProducts, 'exchange'),
        exchangesReturnProductCount: calculateSelectedProducts(selectedProducts, 'return')
      });
    }

    try {
      setLoading(true);
      const response = await fetch(CREATE_RETURN_API, params);

      const data = await response.json();

      if (data.errorTitle) {
        setFormError(data.errorTitle);
        setFormErrorsList(data.errors);
        if (data.errorTitle === AlertErrorTitle.PRODUCTS_SELECTION_VALIDATION) setStep(OverviewFormStepsEnum.One);
        if (carrier === CarriersEnum.UPS) {
          if (data.errorTitle === AlertErrorTitle.REVIEW_VALIDATION) setStep(OverviewFormStepsEnum.Two);
        }
        window.dataLayer.push({ event: 'reviewSubpageCreateLabelButtonFailure' });
        return scrollToError();
      }

      setFormError(null);
      scrollToTop();
      window.dataLayer.push({ event: 'reviewSubpageCreateLabelButtonSuccess' });
      return navigate(
        `/return/getLabel/${vanityName}?inputOrderId=${orderNumber}${shippingPostalCode ? `&shippingPostalCode=${shippingPostalCode}` : ''}${
          !!urlKey ? `&key=${urlKey}` : ''
        }&labelId=${data.labelId}`,
        { state: ['showLabel', carrier] }
      );
    } catch (e) {
      await setFormError(AlertErrorTitle.OVERVIEW_SERVER_ERROR_LABEL);
      return scrollToError();
    } finally {
      setLoading(false);
    }
  };
  return (
    <ReviewFormContainer
      data-test-id="review-form-container"
      noValidate
      onKeyDown={(event: KeyboardEvent<HTMLFormElement>) => handlePreventSubmitOnEnter(event)}
      onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event)}>
      {formError && (formError === AlertErrorTitle.REVIEW_VALIDATION || formError === AlertErrorTitle.OVERVIEW_VALIDATION_LABEL) && (
        <Alert
          message={intl.formatMessage({ id: formError })}
          error={formErrorsList}
          gtmId={
            method
              ? `return-overview-sapi-address-validation-${method}-error`
              : sapiReturnLabelsCbconnect2631
              ? 'return-overview-sapi-address-validation-label-error'
              : 'label-generation-error'
          }
          marginSm="0 0 30px 0"
        />
      )}
      <SubpageHeading
        dataTestId="review-form-title"
        title={intl.formatMessage({
          id:
            clientReturnType !== 'integrated'
              ? 'RETURN.overview.review.subtitle.nonIntegrated'
              : 'RETURN.overview.review.subtitle.integrated.exchange'
        })}
      />
      {clientReturnType === 'integrated' && <SelectedProductsReviewList />}
      {returnsModule.consumerComments && (
        <ReviewFormFieldset data-test-id="review-form-order-level-comments-fieldset" margin="0 0 20px 0" data-error-id={!!orderLevelCommentError}>
          <ReviewFormLabel margin="0 0 15px 0" htmlFor="order-level-comment">
            {intl.formatMessage({
              id: 'RETURN.overview.review.form.orderLevelComment.legend'
            })}
          </ReviewFormLabel>

          <CustomTextField
            fullWidth
            data-test-id="review-form-order-level-comments-text-field"
            size="small"
            id="order-level-comment"
            name="order-level-comment"
            placeholder={intl.formatMessage({
              id: 'RETURN.overview.review.form.orderLevelComment.input.placeholder'
            })}
            variant="outlined"
            error={!!orderLevelCommentError}
            helperText={!!orderLevelCommentError ? orderLevelCommentError : ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeOrderLevelComment(event)}
            value={orderLevelComment}
            inputProps={{
              'data-gtm-id': 'order-level-comments-field',
              'data-test-id': 'review-form-order-level-comments-input'
            }}
          />
          <CharacterCounter maxValue={250} value={orderLevelComment.length} />
        </ReviewFormFieldset>
      )}

      {returnsModule.returnsCustomField.enabled && (
        <ReviewFormFieldset data-test-id="review-form-returns-custom-field-fieldset" margin="0 0 40px 0" data-error-id={!!customFieldError}>
          <ReviewFormLabel margin="0 0 15px 0" htmlFor="returns-custom-field">
            {returnsModule.returnsCustomField.label}
          </ReviewFormLabel>

          <CustomTextField
            fullWidth
            data-test-id="review-form-returns-custom-text-field"
            size="small"
            id="returns-custom-field"
            name="returns-custom-field"
            placeholder={intl.formatMessage(
              {
                id: 'RETURN.overview.review.form.customField.input.placeholder'
              },
              {
                optional: returnsModule.returnsCustomField.required ? '' : 'optional ',
                label: returnsModule.returnsCustomField.label?.toLowerCase(),
                asterix: returnsModule.returnsCustomField.required ? ' *' : ''
              }
            )}
            required={returnsModule.returnsCustomField.required}
            variant="outlined"
            value={customField}
            error={!!customFieldError}
            helperText={!!customFieldError ? customFieldError : ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeCustomField(event)}
            inputProps={{
              'data-gtm-id': 'custom-field',
              'data-test-id': 'review-form-returns-custom-field-input'
            }}
          />
        </ReviewFormFieldset>
      )}
      <ReviewFormFieldset data-test-id="review-form-ship-from-address-fields-fieldset" maxWidth="550px" margin="0 0 40px 0">
        <ReviewFormLegendWrapper>
          <ReviewFormLegend data-test-id="review-form-ship-from-address-fields-legend">
            {intl.formatMessage({
              id: 'RETURN.overview.review.form.shipFromAddress.legend'
            })}
          </ReviewFormLegend>
          {initialShipFromAddressValid && (
            <CustomButton
              size="medium"
              marginDefault="0 0 0 20px"
              type="button"
              onClick={() => {
                if (showShippingFormAddressForm) setValuesFromInitialShipFromAddressForForm();
                setRestrictedAddress(false);
                setShowShippingFormAddressForm((prevState: boolean) => !prevState);
              }}
              isUppercase
              data-gtm-id={`address-form-button-${showShippingFormAddressForm ? 'cancel' : 'change'}`}
              data-test-id="ship-from-address-button"
              variant="text">
              {showShippingFormAddressForm ? 'Cancel' : 'Change'}
            </CustomButton>
          )}
        </ReviewFormLegendWrapper>
        {showShippingFormAddressForm ? (
          <ShipFromAddressTextFieldsWrapper data-test-id="ship-from-address-text-fields-wrapper">
            {!returnsUps2DayCoco264 && (
              <CustomTextField
                fullWidth
                data-test-id="review-form-full-name-text-field"
                marginDefault="0 0 13px 0"
                size="small"
                name="full-name"
                required
                id="full-name"
                placeholder={intl.formatMessage({
                  id: 'RETURN.overview.review.form.shipFromAddress.fullName.input.placeholder'
                })}
                error={!!fullNameError}
                data-error-id={!!fullNameError}
                helperText={!!fullNameError && fullNameError.toLowerCase() !== ' ' ? fullNameError : ''}
                value={fullName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeFullName(event)}
                variant="outlined"
                inputProps={{
                  'data-test-id': 'review-form-full-name-input'
                }}
              />
            )}
            <CustomTextField
              data-test-id="review-form-address-one-text-field"
              fullWidth
              marginDefault="0 0 13px 0"
              size="small"
              id="address-one"
              name="address-one"
              placeholder={intl.formatMessage({
                id: 'RETURN.overview.review.form.shipFromAddress.addressOne.input.placeholder'
              })}
              required
              error={!!addressOneError}
              data-error-id={!!addressOneError}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeAddressOne(event)}
              value={addressOne}
              helperText={!!addressOneError && addressOneError.toLowerCase() !== ' ' ? addressOneError : ''}
              variant="outlined"
              inputProps={{
                'data-test-id': 'review-form-address-one-input'
              }}
            />

            <CustomTextField
              data-test-id="review-form-address-two-text-field"
              fullWidth
              marginDefault="0 0 13px 0"
              size="small"
              id="address-two"
              name="address-two"
              placeholder={intl.formatMessage({
                id: 'RETURN.overview.review.form.shipFromAddress.addressTwo.input.placeholder'
              })}
              error={!!addressTwoError}
              data-error-id={!!addressTwoError}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeAddressTwo(event)}
              value={addressTwo}
              helperText={!!addressTwoError && addressTwoError.toLowerCase() !== ' ' ? addressTwoError : ''}
              variant="outlined"
              inputProps={{
                'data-test-id': 'review-form-address-two-input'
              }}
            />
            <ReviewFormGroup alignItems="flex-start">
              <CustomTextField
                data-test-id="review-form-city-text-field"
                size="small"
                marginDefault="0 0 13px 0"
                id="city"
                name="city"
                placeholder={intl.formatMessage({
                  id: 'RETURN.overview.review.form.shipFromAddress.city.input.placeholder'
                })}
                required
                error={!!cityError}
                data-error-id={!!cityError}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeCity(event)}
                value={city}
                helperText={!!cityError && cityError.toLowerCase() !== ' ' ? cityError : ''}
                variant="outlined"
                width="31.5%"
                inputProps={{
                  'data-test-id': 'review-form-city-input'
                }}
              />

              <CustomFormControl
                data-test-id="review-form-state-form-control"
                variant="outlined"
                width="31.5%"
                size="small"
                marginDefault="0 0 12px 0"
                data-error-id={!!selectedStateError}
                error={!!selectedStateError}
                required>
                <ReviewSelect
                  native
                  id="state"
                  name="state"
                  value={selectedState}
                  inputProps={{
                    id: 'state',
                    'data-gtm-id': 'select-us-state',
                    'data-test-id': 'review-form-state-select'
                  }}
                  // @ts-ignore
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeState(event)}>
                  <option aria-label="None" value="">
                    {intl.formatMessage({
                      id: 'RETURN.overview.review.form.shipFromAddress.state.input.placeholder'
                    })}
                  </option>
                  {states.map((state: States) => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </ReviewSelect>
                {!!selectedStateError && (
                  <FormHelperText data-gtm-id="form-input-error" style={{ margin: '4px 0 0 4px' }}>
                    {selectedStateError && selectedStateError.toLowerCase() !== ' ' && selectedStateError}
                  </FormHelperText>
                )}
              </CustomFormControl>

              <CustomTextField
                data-test-id="review-form-zip-code-text-field"
                size="small"
                marginDefault="0 0 13px 0"
                name="zip-code"
                required
                id="zip-code"
                placeholder={intl.formatMessage({
                  id: 'RETURN.overview.review.form.shipFromAddress.zipCode.input.placeholder'
                })}
                value={zipCode}
                error={!!zipCodeError}
                data-error-id={!!zipCodeError}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeZipCode(event)}
                helperText={!!zipCodeError && zipCodeError.toLowerCase() !== ' ' ? zipCodeError : ''}
                width="31.5%"
                variant="outlined"
                inputProps={{
                  'data-test-id': 'review-form-zip-code-input'
                }}
              />
            </ReviewFormGroup>

            {restrictedAddress && (
              <FormHelperText style={{ margin: '-10px 0 0 0px' }}>
                {intl.formatMessage({
                  id: 'RETURN.overview.review.form.shipFromAddress.restricted'
                })}
              </FormHelperText>
            )}

            {!returnsRemovePhoneNumberCoco159 && (
              <CustomTextField
                data-test-id="review-form-number-text-field"
                size="small"
                id="number"
                name="number"
                placeholder={intl.formatMessage({
                  id: 'RETURN.overview.review.form.shipFromAddress.number.input.placeholder'
                })}
                required={false}
                type="tel"
                value={number}
                error={!!numberError}
                data-error-id={!!numberError}
                helperText={!!numberError ? numberError : ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeNumber(event)}
                width="100%"
                variant="outlined"
                inputProps={{
                  'data-test-id': 'review-form-number-input'
                }}
              />
            )}
          </ShipFromAddressTextFieldsWrapper>
        ) : (
          <ShippingAddressSummary
            fullName={fullName}
            addressOne={addressOne}
            addressTwo={addressTwo}
            city={city}
            selectedState={selectedState}
            zipCode={zipCode}
            number={number}
          />
        )}
      </ReviewFormFieldset>
      {!returnsUps2DayCoco264 && (
        <ReviewFormFieldset data-test-id="review-form-email-field-fieldset" margin="0 0 40px 0" maxWidth="550px">
          <ReviewFormLegend data-test-id="review-form-email-field-legend" margin="0 0 15px 0">
            {intl.formatMessage({
              id: 'RETURN.overview.review.form.email.legend'
            })}
          </ReviewFormLegend>
          <ReviewFormEmailLabel htmlFor="email" margin="0 0 20px 0">
            {intl.formatMessage({
              id: 'RETURN.overview.review.form.email.legend.description'
            })}
          </ReviewFormEmailLabel>

          <CustomTextField
            data-test-id="review-form-email-text-field"
            fullWidth
            size="small"
            id="email"
            name="email"
            placeholder={intl.formatMessage({
              id: 'RETURN.overview.review.form.email.input.placeholder'
            })}
            type="email"
            value={email}
            error={!!emailError}
            data-error-id={!!emailError}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeEmail(event)}
            helperText={!!emailError ? emailError : ''}
            inputProps={{
              'data-test-id': 'review-form-email-input'
            }}
            variant="outlined"
          />
        </ReviewFormFieldset>
      )}

      {notificationsModule && (notificationsModule.emailEnabled || notificationsModule.smsEnabled) && (
        <ReviewFormFieldset data-test-id="review-form-notification-fields-fieldset" maxWidth="550px" margin=" 0 0 40px 0">
          <ReviewFormLegend data-test-id="review-form-notification-fields-legend" margin="0 0 15px 0">
            {intl.formatMessage({
              id: 'RETURN.overview.review.form.notifications.legend'
            })}
          </ReviewFormLegend>
          <ReviewFormLegendDescription margin="0 0 30px 0" data-test-id="review-form-notification-fields-legend-description">
            {intl.formatMessage({
              id: attCustomerCareModificationsCoco519
                ? 'RETURN.overview.review.form.notifications.legend.description.att'
                : 'RETURN.overview.review.form.notifications.legend.description.default'
            })}
          </ReviewFormLegendDescription>
          {notificationsModule.emailEnabled && (
            <ReviewFormGroup
              data-test-id="review-form-notification-form-group-email"
              margin="0 0 25px 0"
              display="block"
              displayMd="flex"
              alignItems="flex-start">
              <CustomFormControlLabel
                control={
                  <Checkbox
                    checked={emailNotificationChecked}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeEmailNotificationChecked(event)}
                    dataTestId="review-form-email-notification-checkbox"
                    gtmTagId="email-notification-checkbox"
                  />
                }
                label={intl.formatMessage({
                  id: 'RETURN.overview.review.form.notifications.emailNotification.label'
                })}
              />
              {emailNotificationChecked && (
                <CustomTextField
                  data-test-id="review-form-notification-email-text-field"
                  size="small"
                  type="email"
                  id="notification-email"
                  name="notification-email"
                  placeholder={intl.formatMessage({
                    id: 'RETURN.overview.review.form.notifications.emailNotification.input.placeholder'
                  })}
                  value={emailNotification}
                  required
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeEmailNotification(event)}
                  error={!!emailNotificationError}
                  data-error-id={!!emailNotificationError}
                  helperText={!!emailNotificationError ? emailNotificationError : ''}
                  marginDefault="0"
                  width="100%"
                  widthMd="70%"
                  variant="outlined"
                  inputProps={{
                    'data-test-id': 'review-form-notification-email-input'
                  }}
                />
              )}
            </ReviewFormGroup>
          )}
          {notificationsModule.smsEnabled && (
            <ReviewFormGroup data-test-id="review-form-notification-form-group-number" display="block" displayMd="flex" alignItems="flex-start">
              <CustomFormControlLabel
                control={
                  <Checkbox
                    checked={numberNotificationChecked}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeNumberNotificationChecked(event)}
                    dataTestId="review-form-notification-number-checkbox"
                    gtmTagId="sms-notification-checkbox"
                  />
                }
                label={intl.formatMessage({
                  id: 'RETURN.overview.review.form.notifications.numberNotification.label'
                })}
              />

              {numberNotificationChecked && (
                <CustomTextField
                  data-test-id="review-form-notification-number-text-field"
                  size="small"
                  type="tel"
                  id="notification-number"
                  name="notification-number"
                  placeholder={intl.formatMessage({
                    id: 'RETURN.overview.review.form.notifications.numberNotification.input.placeholder'
                  })}
                  value={numberNotification}
                  required
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeNumberNotification(event)}
                  error={!!numberNotificationError}
                  data-error-id={!!numberNotificationError}
                  helperText={!!numberNotificationError ? numberNotificationError : ''}
                  marginDefault="0"
                  width="100%"
                  widthMd="70%"
                  variant="outlined"
                  inputProps={{
                    'data-test-id': 'review-form-notification-number-input'
                  }}
                />
              )}
            </ReviewFormGroup>
          )}
        </ReviewFormFieldset>
      )}
      {dispositionRuleType === 'multiple' && isUserAuthenticated(urlKey) && (
        <ReviewFormFieldset data-test-id="review-form-sort-code-field-fieldset" margin="0 0 40px 0">
          <CustomFormControl
            data-test-id="review-form-sort-code-form-control"
            variant="outlined"
            width="280px"
            size="small"
            marginDefault="0 0 12px 0"
            required>
            {isIE ? (
              <ReviewFormLabel htmlFor="sort-code" data-test-id="review-form-sort-code-field-label" margin="0 0 15px 0">
                {sortCodeLabel}
              </ReviewFormLabel>
            ) : (
              <ReviewFormLabel id="sort-code" data-test-id="review-form-sort-code-field-label" margin="0 0 15px 0">
                {sortCodeLabel}
              </ReviewFormLabel>
            )}
            {isIE ? (
              <Select
                native
                id="sort-code"
                name="sort-code"
                value={sortCodeSelection}
                inputProps={{
                  id: 'sort-code',
                  'data-test-id': 'review-form-sort-code-select'
                }}
                // @ts-ignore
                onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeSortCode(event)}>
                {sortCodes &&
                  sortCodes.map((element: SortCodeType, index: number) => (
                    <option key={index} value={element.dispositionRuleLabel}>
                      {element.dispositionRuleLabel}
                    </option>
                  ))}
              </Select>
            ) : (
              <Select
                value={sortCodeSelection}
                id="sort-code"
                labelId="sort-code"
                name="sort-code"
                inputProps={{
                  id: 'sort-code',
                  'data-test-id': 'review-form-sort-code-select'
                }}
                // @ts-ignore
                onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeSortCode(event)}>
                {sortCodes &&
                  sortCodes.map((element: SortCodeType, index: number) => (
                    <MenuItem key={index} value={element.dispositionRuleLabel}>
                      {element.dispositionRuleLabel}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </CustomFormControl>
        </ReviewFormFieldset>
      )}

      {formError && formError === AlertErrorTitle.OVERVIEW_SERVER_ERROR_LABEL && (
        <Alert
          message={intl.formatMessage({ id: formError })}
          error={formErrorsList}
          gtmId={sapiReturnLabelsCbconnect2631 ? 'return-overview-sapi-other-label-error' : 'label-generation-error'}
          marginSm="0 0 30px 0"
        />
      )}

      <ButtonsContainer>
        <ButtonsWrapper>
          {clientReturnType === 'integrated' && orderItems.length > 0 && (
            <CustomButton
              size="large"
              width="100%"
              widthMd="auto"
              marginDefault="20px 0 0 0"
              marginMd="0"
              disabled={loading}
              onClick={() => {
                setStep(OverviewFormStepsEnum.One);
                scrollToTop('auto');
              }}
              isUppercase
              data-gtm-id="returns-overview-review-back"
              data-test-id="review-form-prev-step-button"
              variant="text">
              {intl.formatMessage({
                id: 'RETURN.overview.review.form.button.back'
              })}
            </CustomButton>
          )}

          {isFromSearchOrder && (clientReturnType === 'nonintegrated' || (clientReturnType === 'integrated' && orderItems.length === 0)) && (
            <CustomButton
              size="large"
              width="100%"
              widthMd="auto"
              marginDefault="20px 0 0 0"
              marginMd="0"
              href={buildSearchUrl(vanityName)}
              disabled={loading}
              isUppercase
              data-gtm-id="returns-overview-review-back"
              data-test-id="review-form-prev-step-button"
              variant="text">
              {intl.formatMessage({
                id: 'RETURN.overview.review.form.button.back'
              })}
            </CustomButton>
          )}

          {isMethodsStepAvailable(
            sapiQrCodeCbconnect2705,
            returnsModule.shippingType.standard.enabled,
            returnsModule.shippingType.expedited.enabled,
            allItemsHaveShippingTypeNull,
            serviceLevelShouldDisplayOnlyExpedited
          ) && isUspsFromZipCode ? (
            <CustomLoadingButton
              disabled={!areAllRequiredFieldsCorrect}
              data-gtm-id="continue-button"
              data-test-id="review-form-submit-button"
              isUppercase
              size="large"
              type="submit"
              width="100%"
              widthMd="auto"
              marginDefault="0"
              marginMd="0 0 0 30px"
              loading={loading}
              variant="contained">
              {intl.formatMessage({
                id: 'RETURN.overview.review.form.button.nextStep'
              })}
            </CustomLoadingButton>
          ) : (
            <CustomLoadingButton
              size="large"
              width="100%"
              widthMd="auto"
              marginDefault="0"
              marginMd="0 0 0 30px"
              disabled={loading || !areAllRequiredFieldsCorrect}
              isUppercase
              data-gtm-id={`create-label-button-${sapiReturnLabelsCbconnect2631 ? 'sapi' : 'legacy'}`}
              data-test-id="review-form-submit-button"
              type="submit"
              loading={loading}
              variant="contained">
              {intl.formatMessage({
                id: 'RETURN.overview.review.form.button.submit'
              })}
            </CustomLoadingButton>
          )}
        </ButtonsWrapper>
      </ButtonsContainer>
    </ReviewFormContainer>
  );
};

export interface OwnProps {
  returnsModule: OverviewReturnsModuleType;
  orderNumber: string;
  shippingPostalCode: string | null;
  clientReturnType: ReturnClientReturnType;
  notificationsModule?: ReturnNotificationsModuleType;
}
