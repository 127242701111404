import React, { createContext, ReactNode, useMemo, useState } from 'react';
import { OrderItemType } from 'types/return/overview/overview-api-response-type';

export const OrderItemsContext = createContext<OrderItemsContextType>({
  orderItems: []
});

export const OrderItemsContextProvider: React.FC<OrderItemsContextProviderProps> = (props) => {
  const { children, initialOrderItems } = props;

  const [orderItems] = useState<OrderItemType[]>(initialOrderItems);

  const value = useMemo<OrderItemsContextType>(
    () => ({
      orderItems
    }),
    [orderItems]
  );

  return <OrderItemsContext.Provider value={value}>{children}</OrderItemsContext.Provider>;
};

interface OrderItemsContextType {
  orderItems: OrderItemType[];
}

interface OrderItemsContextProviderProps {
  children: ReactNode;
  initialOrderItems: OrderItemType[];
}
