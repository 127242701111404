import React from 'react';
import { SpecialLabel } from 'components/Shared/SpecialLabel/SpecialLabel';
import { imageConfig } from './config/imageConfig';
import {
  Content,
  ContentTop,
  ContentWrapper,
  ProductItemWrapper,
  ProductDescription,
  ProductImage,
  ProductItemContainer,
  ProductName,
  ProductPrice,
  ProductPriceStrikethrough,
  SalePriceAmount
} from './ProductItem.styled';
import { TemplateType } from 'types/shared/template-type';
import { ResponsiveImageSizesType } from 'types/shared/responsive-image-sizes-type';
import { FeaturedProductType } from 'types/tracking/featured-product-type';
import { PromotionalProductType } from 'types/tracking/promotional-product-type';

export const ProductItem: React.FC<OwnProps> = (props) => {
  const { product, template, productType } = props;
  const { name, imageUrl, url, description, salePriceLabel, salePriceAmount, priceFormatted } = product;

  const imageSizes: ResponsiveImageSizesType = imageConfig[template];

  return (
    <ProductItemContainer data-test-id="product-item-container">
      <ProductItemWrapper href={url} target="_blank" data-test-id="product-item-link">
        <ProductImage
          sizes={imageSizes}
          url={imageUrl}
          alt={name}
          title={name}
          testId="product-item-image"
          dataGtmId={productType === 'featured' ? 'feature-product-image' : 'promotional-product-image'}
        />
        <Content
          data-test-id="product-item-content"
          data-gtm-id={productType === 'featured' ? 'product-description-tiles' : 'promotional-product-item-content'}>
          <ContentWrapper>
            {salePriceLabel && (
              <SpecialLabel
                testId="product-item-special-label"
                label={salePriceLabel}
                dataGtmId={productType === 'featured' ? 'product-badges' : 'promotional-product-badge'}
              />
            )}
            <ContentTop>
              <ProductName data-test-id="product-item-name">{name}</ProductName>
              <ProductPrice data-test-id="product-item-price">
                {priceFormatted}
                {salePriceAmount && <ProductPriceStrikethrough data-test-id="product-item-price-strikethrough" />}
              </ProductPrice>
            </ContentTop>
            {salePriceAmount && <SalePriceAmount data-test-id="product-item-sale-price-amount">{salePriceAmount}</SalePriceAmount>}
            {description && <ProductDescription data-test-id="product-item-description">{description}</ProductDescription>}
          </ContentWrapper>
        </Content>
      </ProductItemWrapper>
    </ProductItemContainer>
  );
};

export interface OwnProps {
  product: FeaturedProductType | PromotionalProductType;
  productType: ProductOption;
  template: TemplateType;
}

type ProductOption = 'promotional' | 'featured';
