import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionDetailsProps, AccordionProps } from '@mui/material';
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled as muiStyled } from '@mui/material/styles';

export const PrivacyStatementContainer = styled.div`
  padding: 30px 0;
`;

export const PrivacyStatementParagraph = styled.p<{ isFirstParagraph?: boolean; isBolded?: boolean; margin?: string }>`
  color: #4e4e4e;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Precision Sans W', 'Helvetica Neue', Arial, sans-serif;
  margin: ${({ margin }) => (margin ? margin : '10px 0')};
  font-weight: ${({ isBolded }) => (isBolded ? 700 : 400)};

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 16px;
  }
`;

export const PrivacyStatementTableOfContents = styled.ul`
  list-style: numeric;
  margin: 0 0 10px 40px;
`;

export const PrivacyStatementTableOfContentsItem = styled.li`
  margin: 10px 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const PrivacyStatementTableOfContentsLink = styled.a`
  display: block;
  font-size: 14px;
  line-height: 1.5;
  color: #0072b8;
  font-family: 'Precision Sans W', 'Helvetica Neue', Arial, sans-serif;
  text-decoration: underline;

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 16px;
  }
`;

export const PrivacyStatementChapters = styled.ul`
  list-style: numeric;
`;

export const PrivacyStatementChapter = styled.li`
  list-style-position: inside;
  font-size: 20px;
  font-family: 'Precision Sans W', sans-serif;
  font-weight: 700;

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 24px;
  }
`;

export const PrivacyStatementChapterTitle = styled.h3`
  display: inline;
  font-size: 20px;

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 24px;
  }
`;

export const PrivacyStatementList = styled.ul<{ listStyle: 'numeric' | 'disc' }>`
  list-style: ${({ listStyle }) => listStyle};
  margin-left: 40px;
  font-family: 'Precision Sans W', 'Helvetica Neue', Arial, sans-serif;
  color: #4e4e4e;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 16px;
  }
`;

export const PrivacyStatementListItem = styled.li``;

export const AccordionBox = styled.div`
  margin: 55px 0 55px 20px;
`;

export const CustomAccordion = muiStyled(Accordion)<AccordionProps>(() => ({
  marginBottom: '20px',
  boxShadow: 'none',
  border: '1px solid #eaeaea'
}));

export const CustomAccordionSummary = muiStyled(AccordionSummary)<AccordionSummaryProps>(() => ({
  '&:hover': {
    backgroundColor: '#F4F9FC'
  },
  '&.Mui-expanded': {
    backgroundColor: '#E1EEF7'
  }
}));

export const CustomAccordionDetails = muiStyled(AccordionDetails)<AccordionDetailsProps>(() => ({}));

export const AccordionTitle = styled.h4`
  margin: 10px 0%;
  font-size: 16px;
`;

export const JapanPolicyMarkImgBox = styled.div`
  width: 261px;
`;

export const JapanPolicyMarkImg = styled.img`
  width: 100%;
`;

export const EmailLink = styled.a`
  color: #0072b8;
  font-size: 14px;
  font-family: 'Precision Sans W', 'Helvetica Neue', Arial, sans-serif;
  text-decoration: underline;

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 16px;
  }
`;

export const NonLinkedUrl = styled.span`
  word-break: break-all;
`;
