import React, { useContext } from 'react';
import ResponsiveImage from 'components/Shared/ResponsiveImage/ResponsiveImage';
import { imageConfig } from './config/imageConfig';
import { PromotionalContainer, PromotionalItem, PromotionalItemImageContainer } from '../../Promotions.styled';
import { PromotionalInfoBox } from '../PromotionalInfoBox/PromotionalInfoBox';
import { ResponsiveImageSizesType } from 'types/shared/responsive-image-sizes-type';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';

export const SinglePromotional: React.FC<OwnProps> = (props) => {
  const { imageUrl, title, subtitle, linkHref, buttonLabel } = props;

  const { template } = useContext(ClientConfigContext);

  const imageSizes: ResponsiveImageSizesType = imageConfig[template];

  const handleClick = () => {
    window.open(linkHref, '_blank');
  };

  return (
    <PromotionalContainer singlePromotional data-test-id="promotional-single-wrapper" onClick={handleClick}>
      <PromotionalItemImageContainer widthL="50%" data-test-id="promotional-single-image">
        <ResponsiveImage sizes={imageSizes} url={imageUrl} alt="" title="" dataGtmId="promotional-image" />
      </PromotionalItemImageContainer>
      <PromotionalItem singlePromotional widthL="50%" data-test-id="promotional-text-item-wrapper" data-gtm-id="promotional-description-tile">
        <PromotionalInfoBox title={title} subtitle={subtitle} buttonLabel={buttonLabel} />
      </PromotionalItem>
    </PromotionalContainer>
  );
};

export interface OwnProps {
  imageUrl: string;
  title: string;
  subtitle: string;
  linkHref: string;
  buttonLabel: string;
}
