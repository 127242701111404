import styled from '@emotion/styled';
import React from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBMiniIconsEnum } from 'constants/PBIconsEnums';

export const Container = styled.li`
  display: flex;
  position: relative;
  padding: 10px 10px;
  background: #f8f8f8;
  list-style: none;
  margin: 0 0 16px 0;
  border-radius: 4px;
  width: 100%;
  &:last-of-type {
    margin: 0;
  }
`;

export const Text = styled.p<{ isBold?: boolean; margin?: string }>`
  font-size: 12px;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  font-family: ${({ theme }) => theme.bodyFontFamily};
  color: #2e2e2e;
  margin: ${({ margin }) => (!!margin ? margin : 0)};
`;

export const ContainerContent = styled.div`
  margin: 0 0 12px 0;
`;

export const ContainerActions = styled.div``;

export const CustomAccordion = muiStyled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
  border: `none`,
  fontSize: '12px',
  margin: ' 0 0 1px 0',
  background: 'transparent',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
});

export const CustomAccordionSummary = muiStyled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<PBIcon type={PBIconTypeEnum.Mini} name={PBMiniIconsEnum.MinimalRight} />} {...props} />
))({
  backgroundColor: 'transparent',
  width: 'max-content',
  borderRadius: '4px',
  padding: '6px 0px',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  minHeight: 'auto',
  flexDirection: 'row',
  justifyContent: 'flex-start',

  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#000000',
    margin: '0 0 0 6px',
    fontSize: '14px'
  },

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0'
  },

  '&:hover': {
    backgroundColor: ' rgba(0, 0, 0, 0.04)',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  }
});

export const CustomAccordionDetails = muiStyled(MuiAccordionDetails)({
  padding: '0 0 8px 5px',
  margin: '10px 0 0 0',
  borderTop: 'none'
});

export const HoursList = styled.ul`
  padding: 0;
  margin: 0;
`;
export const HoursItem = styled.li`
  font-size: 12px;
  margin: 0 0 3px 0;
  &:last-of-type {
    margin: 0;
  }
`;

export const ButtonTextWrapper = styled.div``;

export const ButtonText = styled.span`
  display: inline-block;
`;
export const TextModifier = styled.span`
  color: #c14500;
  display: inline-block;
  margin: 0 0 0 5px;
`;

export const ButtonIconWrapper = styled.div<{ expand: boolean }>`
  i {
    font-size: 15px;
  }
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  display: flex;
  margin: 0 0 0 6px;
  transform: ${({ expand }) => (!!expand ? 'rotate(-90deg)' : 'rotate(0deg)')};
`;

export const Link = styled.a`
  display: inline-block;
  padding: 4px 0px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  font-size: 12px;
  color: #0072b8;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

export const LocationInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LocationIconContainer = styled.div`
  width: 13px;
  height: 18px;
  margin-right: 10px;
`;

export const LocationIcon = styled.img`
  width: 100%;
`;

export const UnpackIconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

export const UnpackIcon = styled.img`
  width: 100%;
`;

export const CloseText = styled.span`
  color: #d30c0c;
  display: inline-block;
  margin: 0 0 0 5px;
`;
