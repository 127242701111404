import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const ItemContainer = styled.li<{ trackingEventsLength: number }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;

  ${({ theme, trackingEventsLength }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'vivid' &&
    css`
      border-bottom: 1px solid ${theme.gray900};

      &:last-child {
        border-bottom: ${trackingEventsLength <= 3 && 'none'};
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'spartan' &&
    css`
      border-bottom: 1px solid ${theme.white};
    `}
`;

export const ItemElementsWrapper = styled.div`
  &:first-of-type {
    width: 25%;
  }
  &:last-of-type {
    width: 70%;
  }
`;

export const ItemDate = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 0 0 2px 0;
`;

export const ItemHour = styled.div`
  font-weight: normal;
  color: ${({ theme }) => theme.gray};

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      font-size: 12px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      font-size: 12.5px;
    `}
`;

export const ItemDescription = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 0 0 2px 0;
  text-align: right;
`;

export const ItemLocation = styled.p`
  color: ${({ theme }) => theme.gray};
  margin: 0;
  padding: 0;
  text-align: right;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      font-size: 12px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      font-size: 12.5px;
    `}
`;
