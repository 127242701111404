import { HostType } from 'types/shared/config/host-type';
import { Environment } from 'utils/common/environment-resolver/environment-resolver';

export const GIFT_APP_URLS: HostType = {
  PROD: 'https://gift.shipment.co/send',
  [Environment.AWS_PRD]: 'https://gift.shipment-prd.co/send',
  [Environment.AWS_PPD]: 'https://gift.shipment-demo.co/send',
  [Environment.AWS_DEV]: 'https://gift.shipment-dev.co/send',
  [Environment.LOCAL]: 'http://localhost:3000/send/gift'
};
