export const scrollToError = (): void => {
  const errorElement = document.querySelector('[data-error-id="true"]');
  if (errorElement) {
    window.scrollTo({
      top: errorElement.getBoundingClientRect().top + window.scrollY - 150,
      left: 0,
      behavior: 'smooth'
    });
  }
};
