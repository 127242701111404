import React, { PropsWithChildren } from 'react';
import {
  InstructionsList,
  QRCodeInfoContainer,
  QRCodeInfoIcon,
  QRCodeInfoIconContainer,
  QRCodeInfoIconDescription,
  QRCodeInfoReminder,
  QRCodeInfoWrapper
} from './QRCodeInfo.styled';
import { useIntl } from 'react-intl';
import { InstructionsListItem } from './InstructionsListItem/InstructionsListItem';
import { CarrierSuggestion } from 'types/return/return-info/return-info-api-response-type';

export const QrCodeInfo: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { carrierSuggestions } = props;

  const intl = useIntl();

  const instructions: Instruction[] = [
    {
      title: intl.formatMessage({ id: 'RETURN.returnInfo.qrCode.qrCodeInfo.step1' }),
      dataId: 'returns-qr-instruction-1',
      postOffice: [],
      id: '1'
    },
    {
      title: intl.formatMessage({ id: 'RETURN.returnInfo.qrCode.qrCodeInfo.step2' }),
      dataId: 'returns-qr-instruction-2',
      postOffice: carrierSuggestions ? carrierSuggestions : [],
      id: '2'
    },
    {
      title: intl.formatMessage({ id: 'RETURN.returnInfo.qrCode.qrCodeInfo.step3' }),
      dataId: 'returns-qr-instruction-3',
      postOffice: [],
      id: '3'
    }
  ];

  return (
    <QRCodeInfoContainer data-test-id="qr-code-info-container">
      <QRCodeInfoWrapper>
        <InstructionsList>
          {instructions.map((element: Instruction, index: number) => (
            <InstructionsListItem
              key={element.id}
              title={element.title}
              dataTestId={element.dataId}
              stepCounter={index + 1}
              postOffice={element.postOffice}
            />
          ))}
        </InstructionsList>
        <QRCodeInfoReminder>
          <QRCodeInfoIconContainer>
            <QRCodeInfoIcon data-test-id="returns-qr-code-reminder-icon" src="/assets/img/reminder-finger.svg" alt="reminder finger" />
          </QRCodeInfoIconContainer>
          <QRCodeInfoIconDescription data-test-id="returns-qr-code-description">
            {intl.formatMessage({ id: 'RETURN.returnInfo.qrCode.qrCodeInfo.reminder' })}
          </QRCodeInfoIconDescription>
        </QRCodeInfoReminder>
      </QRCodeInfoWrapper>
    </QRCodeInfoContainer>
  );
};

interface Instruction {
  title: string;
  dataId: string;
  id: string;
  postOffice: CarrierSuggestion[];
}

export interface OwnProps {
  carrierSuggestions?: CarrierSuggestion[];
}
