import styled from '@emotion/styled';

export const ReturnsLayoutContainer = styled.main`
  width: 100%;
`;

export const ReturnsLayoutWrapper = styled.div`
  width: 100%;
  max-width: 840px;
  padding: 60px 0;
`;
