import React, { Dispatch, PropsWithChildren, SetStateAction, useMemo, useState } from 'react';
import { SelectedProduct } from 'models/returns/selected-product/SelectedProduct';
import { SelectedProductErrors } from 'models/returns/selected-product-errors/SelectedProductErrors';

export const ProductsSelectionContext = React.createContext<ProductsSelectionContextInterface>({
  selectedProducts: {},
  setSelectedProducts: () => {},
  selectedProductsErrors: {},
  setSelectedProductsErrors: () => {}
});

// notice it is a plural form with ,,s'' ant the end
export type SelectedProducts = Record<string, SelectedProduct>;
// notice it is a plural form with ,,s'' ant the end of Items and Errors
export type SelectedProductsErrors = Record<string, SelectedProductErrors>;

export const ProductsSelectionContextProvider: React.FC<ProductsSelectionContextProviderProps> = (
  props: PropsWithChildren<ProductsSelectionContextProviderProps>
) => {
  const { children } = props;

  const [selectedProducts, setSelectedProducts] = useState<SelectedProducts>({});
  const [selectedProductsErrors, setSelectedProductsErrors] = useState<SelectedProductsErrors>({});

  const value = useMemo<ProductsSelectionContextInterface>(
    () => ({
      selectedProducts,
      setSelectedProducts,
      selectedProductsErrors,
      setSelectedProductsErrors
    }),
    [selectedProducts, selectedProductsErrors]
  );

  return <ProductsSelectionContext.Provider value={value}>{children}</ProductsSelectionContext.Provider>;
};

export interface ProductsSelectionContextInterface {
  selectedProducts: SelectedProducts;
  setSelectedProducts: Dispatch<SetStateAction<SelectedProducts>>;
  selectedProductsErrors: SelectedProductsErrors;
  setSelectedProductsErrors: Dispatch<SetStateAction<SelectedProductsErrors>>;
}

interface ProductsSelectionContextProviderProps {
  children: React.ReactNode;
}
