import { CustomTheme } from 'types/modules';

// fonts
const primaryFont = `"Karla", sans-serif`;
const secondaryFont = `"Source Serif Pro", serif`;

// colors
const white = '#fff',
  black = '#383838',
  wildSand = '#f6f6f6',
  viking = '#6bb6d9',
  mercury = '#e3e3e3',
  oceanGreen = '#50b67b';

export const theme: Partial<CustomTheme> = {
  white: white,
  black: black,
  primaryFontFamily: primaryFont,
  secondaryFontFamily: secondaryFont,
  primaryColor: viking,
  secondaryColor: oceanGreen,
  tertiaryColor: mercury,
  layoutMaxWidth: 840,
  bodyBackgroundColor: wildSand,
  buttonColor: white
};
