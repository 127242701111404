import React, { PropsWithChildren, useContext } from 'react';
import { OverviewStepsContainer } from './OverviewSteps.styled';
import { Review } from 'components/Returns/Overview/Review/Review';
import { ProductsSelection } from 'components/Returns/Overview/ProductsSelection/ProductsSelection';
import { Methods } from 'components/Returns/Overview/Methods/Methods';
import { OverviewContext } from 'context/returns/OverviewContext';
import { Loading } from 'components/Shared/Loading/Loading';
import { OverviewApiResponseSuccessType, OverviewFormStepsEnum, ShipToType } from 'types/return/overview/overview-api-response-type';

export const OverviewSteps: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { overviewConfig, orderNumber, shippingPostalCode, address } = props;
  const { step } = useContext(OverviewContext);

  return (
    <OverviewStepsContainer data-test-id="overview-steps-container">
      {step === OverviewFormStepsEnum.Zero && <Loading />}
      {step === OverviewFormStepsEnum.One && (
        <ProductsSelection
          overviewConfig={overviewConfig}
          orderNumber={orderNumber}
          shippingPostalCode={shippingPostalCode}
          shippingAddress={address}
        />
      )}
      {step === OverviewFormStepsEnum.Two && (
        <Review overviewConfig={overviewConfig} orderNumber={orderNumber} shippingPostalCode={shippingPostalCode} />
      )}
      {step === OverviewFormStepsEnum.Three && (
        <Methods overviewConfig={overviewConfig} orderNumber={orderNumber} shippingPostalCode={shippingPostalCode} />
      )}
    </OverviewStepsContainer>
  );
};

export interface OwnProps {
  overviewConfig: OverviewApiResponseSuccessType;
  orderNumber: string;
  shippingPostalCode: string | null;
  address: ShipToType | null;
}
