import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const Container = styled.div`
  margin: 20px 0 0;
`;

export const PickUpInformation = styled.p`
  font-size: 14px;
  text-align: justify;
  color: ${({ theme }) => theme.bodyFontColor};
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
`;

export const PickUpBox = styled.div`
  margin: 20px 0 0;
`;

export const PickUpTitle = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
  margin: 0 0 12px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.bodyFontColor};
  text-align: center;
`;

export const CancelPickupLink = styled.a`
  font-size: 14px;
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
`;
