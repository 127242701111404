import React from 'react';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { SearchBy } from 'types/customer-service/search-by-type';
import { Tile } from './Tile/Tile';
import { Container } from './TilesList.styled';

export const TilesList: React.FC<OwnProps> = (props) => {
  const { parcels, searchBy } = props;
  return (
    <Container data-test-id="tiles-list" aria-label="tiles-list">
      {parcels.map((element: Parcel) => (
        <Tile
          initiallyShowTrackingEventsHistory={parcels.length === 1 && searchBy === SearchBy.TRACKING_NUMBER}
          key={element.id}
          parcelDetails={element.parcelDetails}
          trackingEvents={element.trackingEvents}
        />
      ))}
    </Container>
  );
};

export interface OwnProps {
  parcels: Parcel[];
  searchBy: SearchBy;
}
