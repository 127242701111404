import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import ResponsiveImage from 'components/Shared/ResponsiveImage/ResponsiveImage';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const ProductItemContainer = styled.div`
  padding-right: 10px;
  margin-bottom: -3px;
`;

export const ProductItemWrapper = styled.a`
  display: inline-block;
  width: 100%;
  position: relative;
  text-transform: none;
  box-sizing: content-box;
  background: white;
  box-shadow: #e3e3e3 2px 2px 2px;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      box-shadow: #e3e3e3 2px 2px 2px;
    `}
`;

const ProductDescription = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.gray500};
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease-out;
  font-family: ${getCustomStyle('styleModule.subheaderFontFamily')};
`;

const Content = styled.div`
  padding: 14px;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  left: 0;
  right: 0;
  transition: all 0.5s ease-out;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      border-bottom: solid 1px ${theme.white};
      &:hover {
        border-bottom: solid 1px ${theme.black};

        ${ProductDescription} {
          max-height: 100px;
        }
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      &:hover {
        ${ProductDescription} {
          max-height: 100px;
        }
      }
    `}
`;

const ContentWrapper = styled.div`
  position: relative;
`;

const ContentTop = styled.div`
  overflow: hidden;
`;

const ProductName = styled.h3`
  font-size: 12px;
  float: left;
  max-width: 150px;
  margin: 0;
  text-transform: none;
  color: ${({ theme }) => theme.black};
  font-family: ${getCustomStyle('styleModule.subheaderFontFamily')};
`;

const ProductPrice = styled.span`
  position: relative;
  float: right;
  font-size: 12px;
  vertical-align: top;
  max-width: 90px;
  margin: 0;
  color: ${({ theme }) => theme.black};
  font-family: ${getCustomStyle('styleModule.subheaderFontFamily')};
`;

const ProductPriceStrikethrough = styled.span`
  width: calc(100% + 10px);
  height: 1px;
  position: absolute;
  display: block;
  left: -5px;
  top: 50%;
  background-color: ${getCustomStyle('styleModule.secondaryColor')};
  font-family: ${getCustomStyle('styleModule.subheaderFontFamily')};
`;

const SalePriceAmount = styled.span`
  float: right;
  color: ${getCustomStyle('styleModule.secondaryColor')};
  font-family: ${getCustomStyle('styleModule.subheaderFontFamily')};
`;

const ProductImage = styled(ResponsiveImage)`
  img {
    padding-bottom: 60px;
  }
`;

export {
  ProductDescription,
  Content,
  ContentWrapper,
  ContentTop,
  ProductName,
  ProductPrice,
  ProductPriceStrikethrough,
  SalePriceAmount,
  ProductImage
};
