import React from 'react';
import { PolicyLayout } from 'layouts/ConsumerConnectPolicy/PolicyLayout/PolicyLayout';
import {
  ConsumerConnectTermsOfUseContainer,
  PolicyList,
  PolicyListItem,
  PolicyParagraph,
  PolicyTitle,
  TextWrapper
} from './ConsumerConnectTermsOfUse.styled';
import { useIntl } from 'react-intl';

export const ConsumerConnectTermsOfUse = () => {
  const intl = useIntl();

  return (
    <PolicyLayout
      pageTitle={intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.page_title' })}
      title={intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.title' })}
      subtitle={intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.subtitle' })}>
      <ConsumerConnectTermsOfUseContainer>
        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.background.title' },
              {
                counter: <span>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.background.paragraph1' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.acceptance_of_these_terms.title' },
              {
                counter: <span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.acceptance_of_these_terms.paragraph1' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.acceptance_of_these_terms.paragraph2' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.changes_to_these_terms.title' },
              {
                counter: <span>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.changes_to_these_terms.paragraph1' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.accessing_the_site.title' },
              {
                counter: <span>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.accessing_the_site.paragraph1' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.accessing_the_site.paragraph2' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.intellectual_property_rights.title' },
              {
                counter: <span>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.intellectual_property_rights.paragraph1' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.intellectual_property_rights.paragraph2' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.intellectual_property_rights.listDescription' })}</PolicyParagraph>
          <PolicyList>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.intellectual_property_rights.list.item1' })}</PolicyListItem>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.intellectual_property_rights.list.item2' })}</PolicyListItem>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.intellectual_property_rights.list.item3' })}</PolicyListItem>
          </PolicyList>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.intellectual_property_rights.paragraph3' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.trademarks.title' },
              {
                counter: <span>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.trademarks.paragraph1' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.prohibited_uses.title' },
              {
                counter: <span>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.prohibited_uses.listDescription' })}</PolicyParagraph>
          <PolicyParagraph />
          <PolicyList>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.prohibited_uses.list.item1' })}</PolicyListItem>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.prohibited_uses.list.item2' })}</PolicyListItem>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.prohibited_uses.list.item3' })}</PolicyListItem>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.prohibited_uses.list.item4' })}</PolicyListItem>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.prohibited_uses.list.item5' })}</PolicyListItem>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.prohibited_uses.list.item6' })}</PolicyListItem>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.prohibited_uses.list.item7' })}</PolicyListItem>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.prohibited_uses.list.item8' })}</PolicyListItem>
            <PolicyListItem>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.prohibited_uses.list.item9' })}</PolicyListItem>
          </PolicyList>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.reliance_on_information_posted.title' },
              {
                counter: <span>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.reliance_on_information_posted.paragraph1' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.reliance_on_information_posted.paragraph2' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.changes_to_the_site.title' },
              {
                counter: <span>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.changes_to_the_site.paragraph1' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.links_from_site.title' },
              {
                counter: <span>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.links_from_site.paragraph1' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.disclaimer_of_warranties.title' },
              {
                counter: <span>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.disclaimer_of_warranties.paragraph1' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.disclaimer_of_warranties.paragraph2' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.disclaimer_of_warranties.paragraph3' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.limitation_on_liability.title' },
              {
                counter: <span>12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.limitation_on_liability.paragraph1' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.limitation_on_liability.paragraph2' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.indemnification.title' },
              {
                counter: <span>13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.indemnification.paragraph1' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.governing_law_and_jurisdiction.title' },
              {
                counter: <span>14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.governing_law_and_jurisdiction.paragraph1' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.governing_law_and_jurisdiction.paragraph2' })}</PolicyParagraph>
        </TextWrapper>

        <TextWrapper>
          <PolicyTitle>
            {intl.formatMessage(
              { id: 'POLICY.TERMS_OF_USE.miscellaneous.title' },
              {
                counter: <span>15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              }
            )}
          </PolicyTitle>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.miscellaneous.paragraph1' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.miscellaneous.paragraph2' })}</PolicyParagraph>
          <PolicyParagraph>{intl.formatMessage({ id: 'POLICY.TERMS_OF_USE.miscellaneous.paragraph3' })}</PolicyParagraph>
        </TextWrapper>
      </ConsumerConnectTermsOfUseContainer>
    </PolicyLayout>
  );
};
