export const isMethodsStepAvailable = (
  sapiQrCodeCbconnect2705: boolean,
  standardEnabled: boolean,
  expeditedEnabled: boolean,
  allItemsHaveShippingTypeNull: boolean,
  serviceLevelShouldDisplayOnlyExpedited: boolean
): boolean => {
  if (
    (!allItemsHaveShippingTypeNull && !serviceLevelShouldDisplayOnlyExpedited && !sapiQrCodeCbconnect2705 && standardEnabled && expeditedEnabled) ||
    (standardEnabled && !sapiQrCodeCbconnect2705 && !expeditedEnabled)
  )
    return false;
  return true;
};

export const isShippingUPSAvailable = (returnsModule): boolean => {
  if (returnsModule.shippingType.ups?.enabled) {
    return true;
  } else {
    return false;
  }
};
