import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import { css } from '@emotion/react';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const InputContainer = styled.div`
  position: relative;
  margin: 0 0 20px 0;
  background: white;
  border-radius: 5px;

  & > i {
    transition: all 0.2s ease;
    color: ${({ theme }) => theme.gray};
    font-size: 18px;
    position: absolute;
    transform: translate(0, -50%);
    top: 48%;
    left: 12px;
  }
`;

export const FormInput = styled.input`
  outline: none;
  box-sizing: border-box;
  border: ${({ theme }) => (theme.template === TemplateEnum.VIVID ? '2px solid #ffffff' : '1px solid #e1e1e1')};
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.2s ease;
  font-family: ${() => (getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : '')};

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    font-family: ${() => (getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : '')};
  }

  &:disabled {
    background-color: white;
  }

  &:valid ~ i {
    color: #009966;
  }

  &[data-touched='true']:invalid ~ i {
    color: #dd1010;
    transition: all 0.2s ease;
  }

  &[data-touched='true']:invalid ~ p {
    visibility: visible;
  }

  &[data-touched='true']:invalid {
    transition: all 0.2s ease;
    border: ${({ theme }) => (theme.template === TemplateEnum.VIVID ? '2px' : '1px')} solid #dd1010;
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      padding: 12.5px 100px 12.5px 40px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      padding: 12.5px 80px 12.5px 40px;

      @media ${theme.mediumUp} {
        padding: 12.5px 100px 12.5px 40px;
      }
    `}
`;
