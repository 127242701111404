import React, { useState } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { resolveVanityName2 } from 'utils/app/resolve-vanity-name/resolve-vanity-name';
import { NotFound } from 'pages/NotFound/NotFound';
import './css/App.css';
import './css/custom.css';

type AppParams = {
  vanityName: string | undefined;
  brandName: string | undefined;
  trackingNumber: string | undefined;
};

export const GiftApp: React.FC = () => {
  const { vanityName, brandName, trackingNumber } = useParams<AppParams>();

  if (trackingNumber) {
    localStorage.setItem('tracking', trackingNumber);
  }

  if (brandName) {
    localStorage.setItem('brandName', brandName);
  }

  const location = useLocation();
  const [resolvedVanityName] = useState<string>(resolveVanityName2(vanityName, location.pathname));
  if (resolvedVanityName == 'gift' || resolvedVanityName == 'g') {
    const cssId = 'bootstrap-css'; // you could encode the css path itself to generate id..
    if (!document.getElementById(cssId)) {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.id = cssId;
      link.rel = 'stylesheet';
      link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css';
      head.appendChild(link);
    }
  } else {
    const cssId = 'bootstrap-css';
    const element: HTMLElement | null = document.getElementById(cssId);
    if (element) {
      element.remove();
    }
    return <NotFound />;
  }

  return <Outlet />;
};
