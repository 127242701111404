import React, { useContext, useState } from 'react';
import useFetch from 'hooks/useFetch/useFetch';
import { useIntl } from 'react-intl';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { UnsubscribeBox, UnsubscribeBoxMessage, UnsubscribeMessage, UnsubscribePageContainer } from './Unsubscribe.styled';
import { useParams, useSearchParams } from 'react-router-dom';
import { Error } from 'components/Shared/Error/Error';
import { Loading } from 'components/Shared/Loading/Loading';
import PageTitle from '../../components/Shared/PageTitle/PageTitle';
import { buildUnsubscribeApi } from 'utils/unsubscribe/build-unsubscribe-api/build-unsubscribe-api';
import { UnsubscribeApiResponseSuccess, UnsubscribeApiResponseError } from 'types/unsubscribe/unsubscribe-api-response-type';

type UnsubscribeParams = {
  trackingNumber: string | undefined;
};

export const Unsubscribe: React.FC = () => {
  const { trackingNumber } = useParams<UnsubscribeParams>();
  const [urlSearchParams] = useSearchParams();
  const { vanityName, brandName } = useContext(ClientConfigContext);
  const [key] = useState<string | null>(urlSearchParams.get('key'));

  const intl = useIntl();

  const UNSUBSCRIBE_API: string = buildUnsubscribeApi(vanityName, trackingNumber, key);
  const [data, , error] = useFetch<UnsubscribeApiResponseSuccess, UnsubscribeApiResponseError>(UNSUBSCRIBE_API);

  if (error) return <Error />;
  if (!data) return <Loading />;

  return (
    <UnsubscribePageContainer data-test-id="unsubscribe-page-container">
      <PageTitle title={intl.formatMessage({ id: 'UNSUBSCRIBE.pageTitle' })} brandName={brandName} />
      <UnsubscribeBox>
        <UnsubscribeBoxMessage>
          <UnsubscribeMessage data-test-id="unsubscribe-message">
            {data.unsubscribed ? intl.formatMessage({ id: 'UNSUBSCRIBE.message.success' }) : intl.formatMessage({ id: 'UNSUBSCRIBE.message.failed' })}
          </UnsubscribeMessage>
        </UnsubscribeBoxMessage>
      </UnsubscribeBox>
    </UnsubscribePageContainer>
  );
};
