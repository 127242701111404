import { MenuItem } from '@mui/material';
import { ReviewFormGroup } from 'components/Returns/Overview/Review/ReviewForm/ReviewForm.styled';
import { CustomFormControl } from 'components/Shared/styled-components/CustomFormControl/CustomFormControl.styled';
import { CustomTextField } from 'components/Shared/styled-components/CustomTextField/CustomTextField.styled';
import React, { ChangeEvent, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';
import { isIE } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { SearchBy } from 'types/customer-service/search-by-type';
import {
  Form,
  Title,
  ButtonsWrapper,
  ButtonWrapper,
  SearchParcelsFormCustomSelect,
  SearchParcelsFormCustomLoadingButton,
  Wrapper,
  SearchParcelsFormCustomButton,
  SearchParcelsCustomFormHelperText
} from './SearchParcelsForm.styled';
import { CustomLabel } from 'components/Shared/styled-components/CustomLabel/CustomLabel';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isTrackingNumberValid } from 'utils/customer-service/is-tracking-number-valid/is-tracking-number-valid';
import { isOrderNumberValid } from 'utils/shared/is-order-number-valid/is-order-number-valid';
import { useSearchParams } from 'react-router-dom';
import { FindParcelsPayloadBody } from 'types/customer-service/find-parcels-api-response-type';
import { buildFindParcelsPayload } from 'utils/customer-service/build-find-parcels-payload/build-find-parcels-payload';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBOutlineIconsEnum } from 'constants/PBIconsEnums';

export const SearchParcelsForm: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { searchBy, setSearchBy, searchByOrderNumberEnabled, isLoading, fetchParcels, cleanParcelsResults } = props;

  const [searchParams] = useSearchParams();
  const [numberFromSearchParams] = useState<string | null>(searchParams.get('number'));
  const [numbers, setNumbers] = useState<string>(!!numberFromSearchParams ? numberFromSearchParams : '');
  const [numbersError, setNumbersError] = useState<string | null>(null);

  const MAX_TRACKING_NUMBERS_COUNT: number = 50;

  const intl = useIntl();
  const theme = useTheme();
  const xLargeUp = useMediaQuery(theme.breakpoints.up('xlarge'));

  useEffect(() => {
    (async () => {
      if (!!numberFromSearchParams) {
        if (searchBy === SearchBy.TRACKING_NUMBER && !isTrackingNumberValid(numberFromSearchParams))
          return setNumbersError(
            intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.trackingNumber.error.regex' })
          );

        if (searchBy === SearchBy.ORDER_NUMBER && !isOrderNumberValid(numberFromSearchParams, true))
          return setNumbersError(
            intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.orderNumber.error.regex' })
          );

        const payload: FindParcelsPayloadBody = buildFindParcelsPayload(searchBy, numberFromSearchParams);
        await fetchParcels(searchBy, payload);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSearchBy = (event: ChangeEvent<HTMLSelectElement>) => {
    const { target } = event;
    const { value } = target;

    // @ts-ignore
    setSearchBy(value);
    setNumbers('');
    setNumbersError(null);
    cleanParcelsResults();
  };

  const handleChangeNumbers = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;
    const transformedValue: string = value.toUpperCase();

    setNumbers(transformedValue);
    if (transformedValue.length === 0 && searchBy === SearchBy.TRACKING_NUMBER)
      return setNumbersError(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.trackingNumber.error.empty' })
      );
    if (transformedValue.length === 0 && searchBy === SearchBy.ORDER_NUMBER)
      return setNumbersError(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.orderNumber.error.empty' })
      );

    if (searchBy === SearchBy.TRACKING_NUMBER) {
      const trackingNumbers: string[] = transformedValue.split('\n'); // here textarea holds numbers so it will contain new lines

      const isEveryTrackingNumberValid = trackingNumbers.every((trackingNumber: string) => isTrackingNumberValid(trackingNumber));
      if (!isEveryTrackingNumberValid)
        return setNumbersError(
          intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.trackingNumber.error.regex' })
        );
    }

    if (searchBy === SearchBy.ORDER_NUMBER && !isOrderNumberValid(transformedValue, true))
      return setNumbersError(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.orderNumber.error.regex' })
      );

    if (searchBy === SearchBy.TRACKING_NUMBER) {
      const trackingNumbers: string[] = transformedValue.split('\n');
      if (trackingNumbers.length > MAX_TRACKING_NUMBERS_COUNT)
        return setNumbersError(
          intl.formatMessage({
            id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.trackingNumber.error.tooManyTrackingNumbers'
          })
        );
    }
    setNumbersError(null);
  };

  const handleClickNewSearchButton = () => {
    cleanParcelsResults();
    setNumbersError(null);
    setNumbers('');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | null) => {
    if (event) event.preventDefault();
    const errorsArray: string[] = [];

    if (numbers.length === 0 && searchBy === SearchBy.TRACKING_NUMBER) {
      errorsArray.push(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.trackingNumber.error.empty' })
      );
      setNumbersError(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.trackingNumber.error.empty' })
      );
    }

    if (numbers.length === 0 && searchBy === SearchBy.ORDER_NUMBER) {
      errorsArray.push(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.orderNumber.error.empty' })
      );
      setNumbersError(
        intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.orderNumber.error.empty' })
      );
    }

    if (errorsArray.length > 0 || !!numbersError) return;

    const payload: FindParcelsPayloadBody = buildFindParcelsPayload(searchBy, numbers);

    await fetchParcels(searchBy, payload);
  };

  const handleKeyDownTextarea = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await handleSubmit(null);
    }
  };

  return (
    <Form
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(event)}
      noValidate
      aria-label="search-parcels-form"
      data-test-id="search-parcels-form">
      <Title>{intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.title' })}</Title>
      {searchByOrderNumberEnabled && (
        <CustomFormControl width="auto" size="small" marginDefault="0 0 12px 0">
          <ReviewFormGroup>
            {isIE ? (
              <CustomLabel marginDefault="0 10px 0 0" fontSize="16px" htmlFor="search-parcels-form__search-by-select">
                {intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchBySelect.label' })}
              </CustomLabel>
            ) : (
              <CustomLabel marginDefault="0 10px 0 0" fontSize="16px" id="search-parcels-form__search-by-select">
                {intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchBySelect.label' })}
              </CustomLabel>
            )}
            {isIE ? (
              <SearchParcelsFormCustomSelect
                id="search-parcels-form__search-by-select"
                name="search-by"
                disabled={isLoading}
                value={searchBy}
                // @ts-ignore
                onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeSearchBy(event)}
                native
                data-test-id="search-parcel-form__search-by-select">
                <option value={SearchBy.TRACKING_NUMBER}>
                  {intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchBySelect.option1' })}
                </option>
                <option value={SearchBy.ORDER_NUMBER}>
                  {intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchBySelect.option2' })}
                </option>
              </SearchParcelsFormCustomSelect>
            ) : (
              <SearchParcelsFormCustomSelect
                autoWidth
                labelId="search-parcels-form__search-by-select"
                id="search-parcels-form__search-by-select"
                name="search-by"
                disabled={isLoading}
                value={searchBy}
                // @ts-ignore
                onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeSearchBy(event)}
                data-test-id="search-parcel-form__search-by-select">
                <MenuItem value={SearchBy.TRACKING_NUMBER}>
                  {intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchBySelect.option1' })}
                </MenuItem>
                <MenuItem value={SearchBy.ORDER_NUMBER}>
                  {intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchBySelect.option2' })}
                </MenuItem>
              </SearchParcelsFormCustomSelect>
            )}
          </ReviewFormGroup>
        </CustomFormControl>
      )}
      <CustomFormControl>
        <Wrapper>
          <ReviewFormGroup justifyContent="flex-start" alignItems="flex-start" style={{ width: '100%' }}>
            {searchBy === SearchBy.TRACKING_NUMBER ? (
              <CustomTextField
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeNumbers(event)}
                value={numbers}
                error={!!numbersError}
                disabled={isLoading}
                borderRadius="4px 0 0 4px"
                name="numbers"
                size="medium"
                width="auto"
                flexGrow="1"
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => handleKeyDownTextarea(event)}
                multiline
                maxRows={7}
                aria-describedby="search-parcels-form__search-parcels-text-field-error"
                id="search-parcels-form__search-parcels-text-field-textarea"
                data-test-id="search-parcels-form__search-parcels-text-field-textarea"
              />
            ) : (
              <CustomTextField
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeNumbers(event)}
                value={numbers}
                error={!!numbersError}
                disabled={isLoading}
                borderRadius="4px 0 0 4px"
                name="numbers"
                size="medium"
                width="auto"
                flexGrow="1"
                aria-describedby="search-parcels-form__search-parcels-text-field-error"
                id="search-parcels-form__search-parcels-text-field-input"
                data-test-id="search-parcels-form__search-parcels-text-field-input"
              />
            )}
            <ButtonsWrapper>
              <SearchParcelsFormCustomLoadingButton sx={{ fontSize: '28px' }} aria-label="search-button" loading={isLoading} type="submit">
                <PBIcon type={PBIconTypeEnum.Outline} name={PBOutlineIconsEnum.Search} />
              </SearchParcelsFormCustomLoadingButton>
              {xLargeUp && (
                <SearchParcelsFormCustomButton
                  visibility={!!numbers ? 'visible' : 'hidden'}
                  disabled={!numbers || isLoading}
                  data-gtm-id="search-parcels-form__new-search-button"
                  data-test-id="search-parcels-form__new-search-button"
                  onClick={handleClickNewSearchButton}
                  size="medium"
                  marginDefault="0 0 0 10px"
                  variant="text"
                  minWidth="fit-content">
                  {intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.newSearchButton' })}
                </SearchParcelsFormCustomButton>
              )}
            </ButtonsWrapper>
          </ReviewFormGroup>
          {!xLargeUp && !!numbers && (
            <ButtonWrapper>
              <SearchParcelsFormCustomButton
                disabled={!numbers || isLoading}
                visibility="visible"
                data-test-id="search-parcels-form__new-search-button"
                data-gtm-id="search-parcels-form__new-search-button"
                onClick={handleClickNewSearchButton}
                size="medium"
                minWidth="fit-content"
                variant="text">
                {intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.newSearchButton' })}
              </SearchParcelsFormCustomButton>
            </ButtonWrapper>
          )}
        </Wrapper>
        {searchBy === SearchBy.TRACKING_NUMBER ? (
          <CustomLabel
            data-test-id="search-parcels-form__search-parcels-label"
            marginDefault="0"
            htmlFor="search-parcels-form__search-parcels-text-field-textarea">
            {intl.formatMessage(
              { id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.trackingNumber.label' },
              { br: <br /> }
            )}
          </CustomLabel>
        ) : (
          <CustomLabel
            data-test-id="search-parcels-form__search-parcels-label"
            marginDefault="0"
            htmlFor="search-parcels-form__search-parcels-text-field-input">
            {intl.formatMessage({ id: 'CS.parcels.searchParcels.searchParcelsForm.searchParcelsTextField.searchBy.orderNumber.label' })}
          </CustomLabel>
        )}
        {!!numbersError && (
          <SearchParcelsCustomFormHelperText
            id="search-parcels-form__search-parcels-text-field-error"
            data-test-id="search-parcels-form__text-field-error">
            {numbersError}
          </SearchParcelsCustomFormHelperText>
        )}
      </CustomFormControl>
    </Form>
  );
};

export interface OwnProps {
  fetchParcels: (searchBy: SearchBy, trackingNumbersOrOrderNumber: FindParcelsPayloadBody) => Promise<void>;
  cleanParcelsResults: () => void;
  searchByOrderNumberEnabled: boolean;
  searchBy: SearchBy;
  setSearchBy: Dispatch<SetStateAction<SearchBy>>;
  isLoading: boolean;
}
