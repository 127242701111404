import styled from '@emotion/styled';

export const ReturnInfoLoadingWrapper = styled.div`
  padding: 60px 0;
  width: 100%;
  max-width: 840px;
`;

export const ReturnInfoLoadingHeadingBox = styled.div`
  margin: 0 auto 50px auto;
  width: 100%;

  @media ${({ theme }) => theme.mediumUp} {
    width: 300px;
  }

  @media ${({ theme }) => theme.largeUp} {
    width: 400px;
  }
`;

export const ReturnInfoLoadingSubheadingBox = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    width: 600px;
  }
`;

export const ReturnInfoLoadingContentBox = styled.div`
  margin: 50px 0 0;
  @media ${({ theme }) => theme.largeUp} {
    display: flex;
    align-items: center;
  }
`;

export const ReturnInfoLoadingInsideLeftContentBox = styled.div`
  width: 100%;
  @media ${({ theme }) => theme.largeUp} {
    width: 50%;
    padding: 0 60px;
  }
`;

export const ReturnInfoLoadingInsideRightContentBox = styled.div`
  width: 100%;
  margin: 20px 0 0 0;

  @media ${({ theme }) => theme.largeUp} {
    width: 50%;
    padding: 0 0 0 20px;
    margin: 0;
  }
`;

export const ReturnInfoLoadingInsideRightContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const ReturnInfoLoadingDropOffLocationHeadingBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 50px 0 0;
  width: 100%;

  @media ${({ theme }) => theme.mediumUp} {
    width: 300px;
  }
`;

export const ReturnInfoLoadingDropOffLocationsBox = styled.div`
  margin: 20px 0 0;
  width: 100%;
`;

export const ReturnInfoLoadingPackageTrackingBox = styled.div`
  margin: 50px 0 0;
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    width: 80%;
  }
`;

export const ReturnInfoLoadingReturnPolicyBox = styled.div`
  margin: 50px 0 0 0;
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    width: 200px;
  }
`;
