import React from 'react';
import { ImageWithFallback } from 'components/Shared/ImageWithFallback/ImageWithFallback';
import {
  ContentAttributeName,
  ContentAttributeValue,
  ContentBox,
  ContentQuantityValue,
  ContentText,
  ProductDetailsContainer,
  ItemImageBox
} from './ProductDetails.styled';
import { useIntl } from 'react-intl';
import { RootProductAttributeView } from 'models/returns/selected-product/SelectedProduct';

export const ProductDetails: React.FC<OwnProps> = (props) => {
  const { as, id, name, sku, imageUrl, attributes, quantity, reason, comment, displayImage } = props;

  const intl = useIntl();

  return (
    <ProductDetailsContainer key={id} as={as} data-test-id="product-details">
      {displayImage && (
        <ItemImageBox>
          <ImageWithFallback
            isBorder
            src={!!imageUrl ? imageUrl : ''}
            fallbackSrc="/assets/img/image-placeholder-framed.svg"
            alt={name}
            height="140px"
          />
        </ItemImageBox>
      )}
      <ContentBox displayImage={displayImage}>
        <ContentText isBold data-test-id="product-details__name">
          {name}
        </ContentText>
        <ContentText margin="0 0 10px 0" data-test-id="product-details__sku">
          {sku}
        </ContentText>
        {attributes.map((attribute) => (
          <ContentText data-test-id="product-details__attribute" key={`${id}__${attribute.attributeName}-${attribute.attributeValue}`} isCapitalize>
            <ContentAttributeName data-test-id="attribute__name">{attribute.attributeName}</ContentAttributeName>{' '}
            <ContentAttributeValue data-test-id="attribute__value">{attribute.attributeValue}</ContentAttributeValue>
          </ContentText>
        ))}
        <ContentText data-test-id="product-details__quantity">
          {intl.formatMessage(
            { id: 'RETURN.overview.review.selectedProductsReviewList.productDetails.quantity' },
            {
              quantity: <ContentQuantityValue data-test-id="quantity__value">{quantity}</ContentQuantityValue>
            }
          )}
        </ContentText>
        {reason && (
          <ContentText margin="10px 0 0 0" data-test-id="product-details__reason">
            {intl.formatMessage({ id: 'RETURN.overview.review.selectedProductsReviewList.productDetails.reason' }, { reason })}
          </ContentText>
        )}
        {comment && (
          <ContentText data-test-id="product-details__reason-comment">
            {intl.formatMessage({ id: 'RETURN.overview.review.selectedProductsReviewList.productDetails.reasonComment' }, { comment })}
          </ContentText>
        )}
      </ContentBox>
    </ProductDetailsContainer>
  );
};

export interface OwnProps {
  as: 'li' | 'div';
  id: string;
  name: string;
  sku: string;
  imageUrl: string | null;
  attributes: RootProductAttributeView[];
  quantity: number;
  reason?: string;
  comment?: string;
  displayImage: boolean;
}
