import React, { FormEvent, KeyboardEvent, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { MethodsFormContainer, MethodsFormGroup, MethodsFormGroupTitle, MethodsFormGroupTitleModifier } from './MethodsForm.styled';
import { useIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MethodsContext } from 'context/returns/MethodsContext';
import { Method } from '../Method/Method';
import { MethodEnum, ShippingTypeEnum, CarriersEnum } from 'types/return/overview/methods/method-types';
import { ButtonsContainer, ButtonsWrapper } from 'components/Returns/Overview/Review/ReviewForm/ReviewForm.styled';
import { ReturnDetails } from 'models/returns/return-details/return-details';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import { ReviewContext } from 'context/returns/ReviewContext';
import { AlertErrorTitle, OverviewContext } from 'context/returns/OverviewContext';
import { UrlKeyContext } from 'context/returns/UrlKeyContext';
import { Alert } from 'components/Shared/Alert/Alert';
import { OverviewFormStepsEnum } from 'types/return/overview/overview-api-response-type';
import { ShippingTypeFeeType } from 'types/return/shared/expedited-return-types';
import { scrollToError } from 'utils/returns/overview/common/scroll-to-error/scroll-to-error';
import { useNavigate } from 'react-router-dom';
import { SubpageHeading } from 'components/Returns/Common/SubpageHeading/SubpageHeading';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { CustomLoadingButton } from 'components/Shared/styled-components/CustomLoadingButton/CustomLoadingButton.styled';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { ProductsSelectionContext } from 'context/returns/ProductsSelectionContext';
import { transformSelectedProducts } from 'utils/returns/overview/common/transform-selected-products/transform-selected-products';
import { calculateTotalSkus } from 'utils/returns/overview/review/calculate-total-skus/calculate-total-skus';
import { calculateSelectedProducts } from 'utils/returns/overview/review/calculate-selected-products/calculate-selected-products';
import { calculateUiSelectionItems } from 'utils/returns/overview/review/calculate-ui-selection-items/calculate-ui-selection-items';
import './customeStyle.css';

export const MethodsForm: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { shippingTypeConfig, orderNumber, shippingPostalCode } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();
  const { urlKey } = useContext(UrlKeyContext);
  const { brandId, vanityName, clientReturnType } = useContext(ClientConfigContext);
  const { method, shippingType, allItemsHaveShippingTypeNull, serviceLevelShouldDisplayOnlyExpedited } = useContext(MethodsContext);
  const { sapiQrCodeCbconnect2705, returnsExpeditedQrCodeCoco258 } = useFlags();
  const { selectedProducts } = useContext(ProductsSelectionContext);
  const { formError, formErrorsList, setFormErrorsList, setFormError, setStep } = useContext(OverviewContext);
  const navigate = useNavigate();

  const CREATE_RETURN_API = `/api/return/createLabel/${vanityName}?inputOrderId=${orderNumber}${
    shippingPostalCode ? `&shippingPostalCode=${shippingPostalCode}` : ''
  }${!!urlKey ? `&key=${urlKey}` : ''}`;

  // fix: can't perform a react state update on an unmounted component
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const {
    orderLevelComment,
    customField,
    fullName,
    addressOne,
    addressTwo,
    city,
    selectedState,
    zipCode,
    email,
    number,
    emailNotification,
    numberNotification,
    selectedSortCode
  } = useContext(ReviewContext);

  const handleClickBackButton = () => {
    setFormError(null);
    setStep(OverviewFormStepsEnum.Two);
  };

  const handlePreventSubmitOnEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return;
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (method === '') {
      const errelm = document.querySelector<HTMLElement>('.errormsg')!;
      //errelm.style.display = 'block';
      if (errelm) {
        errelm.classList.add('Showerrormsg');
      }

      const elm = document.querySelectorAll<HTMLElement>('.methodtype')!;
      elm.forEach((element) => {
        //element.style.border = '1px solid red';
        element.classList.add('validatemethod');
      });
    }

    if (method === '' || shippingType === '') return;

    const fetchHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    };

    const returnDetails = new ReturnDetails(
      orderLevelComment,
      transformSelectedProducts(selectedProducts),
      orderNumber,
      {
        fullName,
        addressOne,
        addressTwo,
        city,
        state: selectedState,
        zip: zipCode,
        email,
        phone: number
      },
      {
        notificationEmail: emailNotification,
        notificationSms: numberNotification
      },
      method,
      selectedSortCode,
      shippingType,
      customField
    );

    const params = enhanceFetchParams({
      method: 'POST',
      headers: enhanceHeadersParams(brandId, fetchHeaders),
      body: JSON.stringify(returnDetails)
    });

    if (clientReturnType === 'integrated') {
      window.dataLayer.push({
        event: 'exchangesUiCounting',
        exchangesUiSelectionCount: calculateUiSelectionItems(selectedProducts),
        exchangesSkuCount: calculateTotalSkus(selectedProducts),
        exchangesExchangeProductCount: calculateSelectedProducts(selectedProducts, 'exchange'),
        exchangesReturnProductCount: calculateSelectedProducts(selectedProducts, 'return')
      });
    }

    try {
      setLoading(true);
      setFormError(null);
      setFormErrorsList([]);
      const response = await fetch(CREATE_RETURN_API, params);

      const data = await response.json();

      if (data.errorTitle) {
        setFormError(data.errorTitle);
        setFormErrorsList(data.errors);
        if (data.errorTitle === AlertErrorTitle.PRODUCTS_SELECTION_VALIDATION) setStep(OverviewFormStepsEnum.One);
        if (data.errorTitle === AlertErrorTitle.REVIEW_VALIDATION) setStep(OverviewFormStepsEnum.Two);
        window.dataLayer.push({ event: 'methodsSubpageConfirmReturnButtonFailure' });
        return scrollToError();
      }

      setFormError(null);
      window.dataLayer.push({ event: 'methodsSubpageConfirmReturnButtonSuccess' });
      return navigate(
        `/return/getLabel/${vanityName}?inputOrderId=${orderNumber}${shippingPostalCode ? `&shippingPostalCode=${shippingPostalCode}` : ''}${
          !!urlKey ? `&key=${urlKey}` : ''
        }&labelId=${data.labelId}`,
        { state: ['showLabel', CarriersEnum.USPS] }
      );
    } catch (e) {
      setFormError(method === MethodEnum.QRCode ? AlertErrorTitle.OVERVIEW_SERVER_ERROR_QR_CODE : AlertErrorTitle.OVERVIEW_SERVER_ERROR_LABEL);
      window.dataLayer.push({ event: 'methodsSubpageConfirmReturnButtonFailure' });
      scrollToError();
    } finally {
      setLoading(false);
    }
  };

  if (
    shippingTypeConfig.standard.enabled &&
    shippingTypeConfig.expedited.enabled &&
    !allItemsHaveShippingTypeNull &&
    clientReturnType === 'integrated'
  )
    return (
      <MethodsFormContainer
        onKeyDown={(event: KeyboardEvent<HTMLFormElement>) => handlePreventSubmitOnEnter(event)}
        onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event)}
        data-test-id="methods-form">
        <SubpageHeading
          dataTestId="methods-form-title"
          title={intl.formatMessage({
            id: 'RETURN.overview.methods.subtitle'
          })}
        />
        {serviceLevelShouldDisplayOnlyExpedited ? (
          <MethodsFormGroup data-test-id="expedited-shipping-type-form-group">
            <MethodsFormGroupTitle data-test-id="methods-form-group-title">
              <MethodsFormGroupTitleModifier>
                {intl.formatMessage({
                  id: 'RETURN.overview.methods.form.expeditedShipping.title.firstPart'
                })}
              </MethodsFormGroupTitleModifier>
              {intl.formatMessage({
                id: 'RETURN.overview.methods.form.expeditedShipping.title.secondPart'
              })}
            </MethodsFormGroupTitle>

            <Method
              price={shippingTypeConfig.expedited.fee}
              iconUrl="/assets/img/label-big.svg"
              returnMethod={MethodEnum.Label}
              returnShippingType={ShippingTypeEnum.Expedited}
            />

            {returnsExpeditedQrCodeCoco258 && (
              <Method
                price={shippingTypeConfig.expedited.fee}
                iconUrl="/assets/img/qr-code-big.svg"
                returnMethod={MethodEnum.QRCode}
                returnShippingType={ShippingTypeEnum.Expedited}
              />
            )}
          </MethodsFormGroup>
        ) : (
          <MethodsFormGroup data-test-id="standard-shipping-type-form-group">
            <MethodsFormGroupTitle data-test-id="methods-form-group-title">
              <MethodsFormGroupTitleModifier>
                {intl.formatMessage({
                  id: 'RETURN.overview.methods.form.standardShipping.title.firstPart'
                })}
              </MethodsFormGroupTitleModifier>
              {intl.formatMessage({
                id: 'RETURN.overview.methods.form.standardShipping.title.secondPart'
              })}
            </MethodsFormGroupTitle>
            <Method
              price={shippingTypeConfig.standard.fee}
              iconUrl="/assets/img/label-big.svg"
              returnMethod={MethodEnum.Label}
              returnShippingType={ShippingTypeEnum.Standard}
            />
            {sapiQrCodeCbconnect2705 && (
              <Method
                price={shippingTypeConfig.standard.fee}
                iconUrl="/assets/img/qr-code-big.svg"
                returnMethod={MethodEnum.QRCode}
                returnShippingType={ShippingTypeEnum.Standard}
              />
            )}
          </MethodsFormGroup>
        )}

        {formError &&
          (formError === AlertErrorTitle.OVERVIEW_SERVER_ERROR_LABEL ||
            formError === AlertErrorTitle.OVERVIEW_SERVER_ERROR_QR_CODE ||
            formError === AlertErrorTitle.OVERVIEW_VALIDATION_LABEL ||
            formError === AlertErrorTitle.OVERVIEW_VALIDATION_QR_CODE) && (
            <Alert
              width="100%"
              gtmId={
                formError === AlertErrorTitle.OVERVIEW_VALIDATION_LABEL || formError === AlertErrorTitle.OVERVIEW_VALIDATION_QR_CODE
                  ? `return-methods-sapi-address-validation-${method}-error`
                  : `return-methods-sapi-other-${method}-error`
              }
              error={formErrorsList}
              message={intl.formatMessage({ id: formError })}
            />
          )}

        <ButtonsContainer>
          <ButtonsWrapper>
            <CustomButton
              size="large"
              width="100%"
              widthMd="auto"
              marginDefault="20px 0 0 0"
              marginMd="0"
              disabled={loading}
              onClick={handleClickBackButton}
              isUppercase
              data-gtm-id="returns-overview-methods-back"
              data-test-id="methods-form-prev-step-button"
              variant="text">
              {intl.formatMessage({
                id: 'RETURN.overview.methods.form.button.back'
              })}
            </CustomButton>

            <CustomLoadingButton
              size="large"
              width="100%"
              widthMd="auto"
              marginDefault="0"
              marginMd="0 0 0 30px"
              disabled={loading}
              isUppercase
              data-gtm-id="confirm-button"
              data-test-id="methods-form-submit-button"
              type="submit"
              loading={loading}
              variant="contained">
              {intl.formatMessage({
                id: 'RETURN.overview.methods.form.button.submit'
              })}
            </CustomLoadingButton>
          </ButtonsWrapper>
        </ButtonsContainer>
      </MethodsFormContainer>
    );

  return (
    <MethodsFormContainer
      onKeyDown={(event) => handlePreventSubmitOnEnter(event)}
      onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event)}
      data-test-id="methods-form">
      <SubpageHeading
        dataTestId="methods-form-title"
        title={intl.formatMessage({
          id: 'RETURN.overview.methods.subtitle'
        })}
      />
      {shippingTypeConfig.standard.enabled && (
        <MethodsFormGroup data-test-id="standard-shipping-type-form-group">
          <MethodsFormGroupTitle data-test-id="methods-form-group-title">
            <MethodsFormGroupTitleModifier>
              {intl.formatMessage({
                id: 'RETURN.overview.methods.form.standardShipping.title.firstPart'
              })}
            </MethodsFormGroupTitleModifier>
            {intl.formatMessage({
              id: 'RETURN.overview.methods.form.standardShipping.title.secondPart'
            })}
          </MethodsFormGroupTitle>

          <Method
            price={shippingTypeConfig.standard.fee}
            iconUrl="/assets/img/label-big.svg"
            returnMethod={MethodEnum.Label}
            returnShippingType={ShippingTypeEnum.Standard}
          />

          {sapiQrCodeCbconnect2705 && (
            <Method
              price={shippingTypeConfig.standard.fee}
              iconUrl="/assets/img/qr-code-big.svg"
              returnMethod={MethodEnum.QRCode}
              returnShippingType={ShippingTypeEnum.Standard}
            />
          )}
        </MethodsFormGroup>
      )}
      {shippingTypeConfig.expedited.enabled && (
        <MethodsFormGroup data-test-id="expedited-shipping-type-form-group">
          <MethodsFormGroupTitle data-test-id="methods-form-group-title">
            <MethodsFormGroupTitleModifier>
              {intl.formatMessage({
                id: 'RETURN.overview.methods.form.expeditedShipping.title.firstPart'
              })}
            </MethodsFormGroupTitleModifier>
            {intl.formatMessage({
              id: 'RETURN.overview.methods.form.expeditedShipping.title.secondPart'
            })}
          </MethodsFormGroupTitle>

          <Method
            price={shippingTypeConfig.expedited.fee}
            iconUrl="/assets/img/label-big.svg"
            returnMethod={MethodEnum.Label}
            returnShippingType={ShippingTypeEnum.Expedited}
          />
          {returnsExpeditedQrCodeCoco258 && (
            <Method
              price={shippingTypeConfig.expedited.fee}
              iconUrl="/assets/img/qr-code-big.svg"
              returnMethod={MethodEnum.QRCode}
              returnShippingType={ShippingTypeEnum.Expedited}
            />
          )}
        </MethodsFormGroup>
      )}

      {formError &&
        (formError === AlertErrorTitle.OVERVIEW_SERVER_ERROR_LABEL ||
          formError === AlertErrorTitle.OVERVIEW_SERVER_ERROR_QR_CODE ||
          formError === AlertErrorTitle.OVERVIEW_VALIDATION_LABEL ||
          formError === AlertErrorTitle.OVERVIEW_VALIDATION_QR_CODE) && (
          <Alert
            width="100%"
            gtmId={
              formError === AlertErrorTitle.OVERVIEW_VALIDATION_LABEL || formError === AlertErrorTitle.OVERVIEW_VALIDATION_QR_CODE
                ? `return-methods-sapi-address-validation-${method}-error`
                : `return-methods-sapi-other-${method}-error`
            }
            error={formErrorsList}
            message={intl.formatMessage({ id: formError })}
          />
        )}

      <p className="errormsg" style={{}}>
        Select the return method
      </p>

      <ButtonsContainer>
        <ButtonsWrapper>
          <CustomButton
            size="large"
            width="100%"
            widthMd="auto"
            marginDefault="20px 0 0 0"
            marginMd="0"
            disabled={loading}
            onClick={handleClickBackButton}
            isUppercase
            data-gtm-id="returns-overview-methods-back"
            data-test-id="methods-form-prev-step-button"
            variant="text">
            {intl.formatMessage({
              id: 'RETURN.overview.methods.form.button.back'
            })}
          </CustomButton>

          <CustomLoadingButton
            className="savebutton"
            size="large"
            width="100%"
            widthMd="auto"
            marginDefault="0"
            marginMd="0 0 0 30px"
            disabled={loading}
            isUppercase
            data-gtm-id="confirm-button"
            data-test-id="methods-form-submit-button"
            type="submit"
            loading={loading}
            variant="contained">
            {intl.formatMessage({
              id: 'RETURN.overview.methods.form.button.submit'
            })}
          </CustomLoadingButton>
        </ButtonsWrapper>
      </ButtonsContainer>
    </MethodsFormContainer>
  );
};

export interface OwnProps {
  shippingTypeConfig: ShippingTypeFeeType;
  orderNumber: string;
  shippingPostalCode: string | null;
}
