import styled from '@emotion/styled';

export const BannerContainer = styled.div`
  width: 100%;
  background-color: #fcf6f2;
`;

export const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;
export const BannerIconContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-right: 20px;

  i {
    font-size: 30px;
    color: #f66e0a;
  }
`;

export const BannerTextContainer = styled.div``;

export const BannerTitle = styled.h2`
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
`;

export const BannerParagraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
`;

export const BannerLink = styled.a`
  font-size: 14px;
`;

export const BannerList = styled.ul`
  margin-top: 5px;
`;

export const BannerListItem = styled.li`
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
`;
