import styled from '@emotion/styled';

export const Container = styled.div<{ searchByOrderNumberEnabled: boolean }>`
  display: none;

  padding: ${({ searchByOrderNumberEnabled }) => (searchByOrderNumberEnabled ? '51px 0 0 0' : '0')};
  @media ${({ theme }) => theme.xlargeUp} {
    display: flex;
    justify-content: flex-end;
    width: 47%;
  }
`;
