import styled from '@emotion/styled';

export const TrackingNumberLink = styled.a`
  display: inline-block;
  width: 100%;
  font-size: inherit;
  color: #1976d2;

  &:link {
    color: #1976d2;
  }

  &:visited {
    color: #1976d2;
  }

  &:hover {
    color: #1976d2;
    text-decoration: underline;
  }

  &:active {
    color: #1976d2;
  }
`;
