import React, { useContext } from 'react';
import {
  BottomElementsWrapper,
  ItemImageWrapper,
  ItemName,
  ItemPrice,
  ItemQuantity,
  ItemSku,
  ItemTextWrapper,
  MediumElementsWrapper,
  OrderItemContainer,
  TopElementsWrapper
} from './OrderItem.styled';
import { ImageWithFallback } from 'components/Shared/ImageWithFallback/ImageWithFallback';
import { TemplateEnum } from 'types/shared/template-type';
import { OrderDetailsType } from 'types/tracking/common/order-details/order-details-type';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';

export const OrderItem: React.FC<OwnProps> = (props) => {
  const { item } = props;
  const { template } = useContext(ClientConfigContext);

  const { currencyCode, imageUrl, name, priceAmount, quantity, sku } = item;

  return (
    <OrderItemContainer data-test-id="order-item-container" aria-label={name}>
      <ItemImageWrapper>
        <ImageWithFallback data-test-id="order-item-image" src={imageUrl} fallbackSrc={'/assets/img/image-placeholder-framed.svg'} alt={name} />
      </ItemImageWrapper>
      <ItemTextWrapper>
        <TopElementsWrapper>
          <ItemName data-test-id="order-item-name">{name}</ItemName>
          {template === TemplateEnum.SPARTAN && <ItemSku data-test-id="order-item-sku">{sku}</ItemSku>}
        </TopElementsWrapper>
        <MediumElementsWrapper>
          <ItemQuantity data-test-id="order-item-quantity">Quantity: {quantity}</ItemQuantity>
        </MediumElementsWrapper>
        {template === TemplateEnum.VIVID && (
          <BottomElementsWrapper>
            <ItemPrice data-test-id="order-item-price">
              {currencyCode} {priceAmount}
            </ItemPrice>
          </BottomElementsWrapper>
        )}
      </ItemTextWrapper>
    </OrderItemContainer>
  );
};

interface OwnProps {
  item: OrderDetailsType;
}
