import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { enhanceFetchParams, enhanceHeadersParams1 } from 'utils/common/utils/utils';
import Terms from './Terms';
import { ClientConfigurationType } from 'types/shared/config/client-configuration-type';
import { getApiHostURL } from 'utils/common/get-api-host-url/get-api-host-url';
import { ApiHostTypeEnum } from 'types/shared/config/api-host-type-enum';

export default function Video() {
  const [backgroundClass, setBackgroundClass] = useState('r-background-birthday');
  const item = localStorage.getItem('occasion');
  const occasion = String(item);

  const [file, setFile] = useState<any | null>(null);
  const [text, setText] = useState('');
  const [isTypeError, setIsTypeError] = useState(false);
  const [isSeverError, setIsServerError] = useState(false);
  const [isShowImage, setIsShowImage] = useState(false);
  const [isShowContinue, setIsShowContinue] = useState(false);
  const [isShowChange, setIsShowChange] = useState(false);
  const [isShowFooter, setIsShowFooter] = useState(true);
  const [isShowText, setIsShowText] = useState(false);
  const [isShowIcon, setIsShowIcon] = useState(true);
  const [isShowTextIcon, setIsShowTextIcon] = useState(true);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [servererrorMsg, setServerErrorMsg] = useState('');
  const [typeerrorMsg, setTypeErrorMsg] = useState('');
  const [isSizeError, setIsSizeError] = useState(false);
  const [sizeerrorMsg, setSizeErrorMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any | null>(null);

  useEffect(() => {
    if (isShowImage) {
      setIsShowFooter(false);
      setIsShowContinue(true);
      setIsShowChange(true);
    } else {
      setIsShowFooter(true);
      setIsShowContinue(false);
      setIsShowChange(false);
    }
  }, [isShowImage, isShowText]);

  const handleback = () => {
    navigate('/send/gift/media');
  };

  useEffect(() => {
    // set the background as per the occasion
    if (item) setBackgroundClass(`r-background-${item.toLowerCase()}`);
  }, [item]);

  const handleChange = (event) => {
    setIsServerError(false);
    setServerErrorMsg('');
    setIsShowLoader(false);
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
    const previewFile = setFile(URL.createObjectURL(event.target.files[0]));
    const errelm = document.querySelector<HTMLElement>('.add-video-container')!;
    if (errelm) {
      errelm.style.opacity = '1';
    }
    setIsSuccess(false);

    // Checking if the file type is allowed or not
    const allowedTypes = ['video/mp4', 'video/mov', 'video/quicktime'];

    if (!allowedTypes.includes(selectedFile?.type)) {
      setTypeErrorMsg('Accepted file formats are: mp4, mov. Check your file and try again.');
      setIsTypeError(true);
      setIsSuccess(false);
      return;
    } else {
      setTypeErrorMsg('');
      setIsTypeError(false);
      setIsShowImage(true);
      setIsShowIcon(false);
      setIsShowChange(true);
    }

    const MIN_FILE_SIZE = 1024; // 1MB
    //const MAX_FILE_SIZE = 5120; // 5MB

    const fileSizeKiloBytes = selectedFile.size / 1024;

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      setSizeErrorMsg('Minimum file size is 1MB.Check the file size and try again.');
      setIsSizeError(true);
      setIsSuccess(false);
      return;
    } else {
      /*if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setSizeErrorMsg('Maximum file size is 5MB.Check the file size and try again.');
      setIsSizeError(true);
      setIsSuccess(false);
      return;
    } */
      setSizeErrorMsg('');
      setIsTypeError(false);
      setIsShowImage(true);
      setIsShowIcon(false);
      setIsShowChange(true);
    }
  };

  const [show, setShow] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleTermClose = () => setShowTerms(false);
  const handleTermShow = () => setShowTerms(true);
  const [input, setInput] = useState('');

  const termsClick = () => {
    setShowTerms(true);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsShowText(true);
    setIsShowTextIcon(false);
    setShow(false);
  };

  const inputHandler = (e) => {
    setInput(e.target.value);
    setText(e.target.value);
  };

  const navigate = useNavigate();
  const onSubmit = async (e) => {
    setIsShowLoader(true);
    e.preventDefault();
    const trackingnumber = localStorage.getItem('tracking') ?? '';
    const cleanedTrackingNumber = trackingnumber.replace(/^"(.*)"$/, '$1');
    const brandName = localStorage.getItem('brandName') ?? '';
    const cleanedBrandName = brandName.replace(/^"(.*)"$/, '$1');
    const hostName = window.location.origin;

    const requestUrl = `${getApiHostURL(
      ApiHostTypeEnum.CLIENT_CONFIGURATION_API
    )}/api/configuration/published-configurations/${cleanedBrandName.toLowerCase()}`;
    const enhancedParams = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ORIGINAL-REFERER': document.referrer ? document.referrer : 'empty'
      }
    };
    const response: Response = await fetch(requestUrl, enhancedParams);
    const json: ClientConfigurationType = await response.json();

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('trackingNumber', cleanedTrackingNumber);
    formData.append('merchantId', json.merchantId);
    formData.append('brandName', cleanedBrandName);
    formData.append('brandId', json.brandId);
    formData.append('giftCategory', occasion);
    formData.append('hostName', hostName);

    const fetchHeaders = {
      headers: {
        //"Content-Type": "multipart/form-data",
        Accept: 'application/json'
      }
    };

    const params = enhanceFetchParams({
      method: 'POST',
      headers: enhanceHeadersParams1(fetchHeaders),
      body: formData
    });

    try {
      // Send POST request using axios
      const response = await fetch('/v1/addGift', params);

      // Check if response is successful

      const data = await response.json();
      const UniqueId = data.id;
      localStorage.setItem('UniqueId', UniqueId);

      if (response.status === 200) {
        const errelm = document.querySelector<HTMLElement>('.add-video-container')!;
        if (errelm) {
          errelm.style.opacity = '1';
        }
        console.log('Data sent successfully!');
        setIsShowLoader(false);
        navigate('/send/gift/share');
      } else {
        console.log('Failed to send data.');
        const errelm = document.querySelector<HTMLElement>('.add-video-container')!;
        if (errelm) {
          errelm.style.opacity = '0.8';
        }
        setIsShowImage(false);
        setIsShowIcon(true);
        setIsServerError(true);
        setServerErrorMsg('System is not responding. Wait a few minutes and try again. We apologize for the inconvenience.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {isShowFooter && (
        <h1 className="media_selection_title">
          <span className="mobile-lefticon" onClick={handleback}>
            <img src="/assets/img/left-icon.png" />
          </span>
          Add video message
        </h1>
      )}
      {isShowContinue && isShowChange && (
        <h1 className="media_selection_title">
          <span className="mobile-lefticon" onClick={handleback}>
            <img src="/assets/img/left-icon.png" />
          </span>
          Your personalized gift tracker is ready
        </h1>
      )}
      <div className={`media_selection_background r-background ${backgroundClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="main-container">
                <div className="add-video-container">
                  {isShowImage && (
                    <div>
                      <video controls className="VideoInput_video" width="100%" src={file} />
                    </div>
                  )}
                  <div className="inner-photo-section">
                    {isTypeError && (
                      <div className="error-msg">
                        <h1>Unable to Upload the Video</h1>
                        <p>{typeerrorMsg}</p>
                      </div>
                    )}

                    {isSizeError && (
                      <div className="error-msg">
                        <h1>Unable to Upload the Video</h1>
                        <p>{sizeerrorMsg}</p>
                      </div>
                    )}

                    {isSeverError && (
                      <div className="error-msg">
                        <h1>Unable to Upload the Video</h1>
                        <p>{servererrorMsg}</p>
                      </div>
                    )}

                    {isShowIcon && (
                      <div>
                        <div className="photo-icon-div" onChange={handleChange}>
                          <div className="input--file">
                            <span>
                              <img src="/assets/img/video-icon-2.svg" />
                            </span>
                            <input name="Select File" type="file" accept="video/*" />
                          </div>
                        </div>
                        <div className="title-div">
                          <p>Add Video</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="footer-text">
                  {isShowFooter && (
                    <p>
                      By uploading content,you are acknowledging your agreement to adhere to our content guidelines. To learn more, refer to <br />
                      <a className="termsclick" onClick={termsClick}>
                        Content Guidelines and policy
                      </a>
                    </p>
                  )}
                </div>
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <div className="change_div">
                    {isShowChange && (
                      <button type="button" className="btn btn-outline-dark video-change-button" onChange={handleChange}>
                        <div className="input--file">
                          <input name="Select File" type="file" accept="video/*" title={selectedFile.name} />
                        </div>
                        Change
                      </button>
                    )}
                  </div>
                  <div className="continue_div">
                    {isShowContinue && (
                      <button type="button" className="btn btn-dark video-continue-button" onClick={onSubmit}>
                        Continue{' '}
                        {isShowLoader && (
                          <span>
                            <div className="dot-flashing"></div>
                          </span>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showTerms} onHide={handleTermClose}>
        <Modal.Header closeButton>
          <Modal.Title className="termsheader">
            <h4>Terms and condition</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="termsbody">
          <Terms />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleTermClose} className="btn btn-dark">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
