export const imageConfig = {
  spartan: {
    small: 345,
    medium: 334,
    // spartan has no width limit, 566 is how long the images are on 4k res.
    large: 566
  },
  vivid: {
    small: 355,
    medium: 257
  },
  linear: {
    small: 355,
    medium: 257
  }
};
