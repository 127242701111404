import { ReturnNotificationsModuleType } from 'types/return/shared/return-notifications-module-type';
import { OverviewReturnsModuleType } from 'types/return/overview/returns-module/overview-returns-module-type';
import { NonReturnableReasonCode } from 'types/return/overview/products-selection/non-returnabled-code-enum';
import { TemplateType } from 'types/shared/template-type';
import { GoogleTagManagerModuleType } from 'types/shared/googleTagManager/google-tag-manager-type';
import { MethodEnum } from 'types/return/overview/methods/method-types';

export interface OverviewApiResponseSuccessType {
  allItemsEligibleForDisplay: boolean;
  merchantId: string;
  notificationsModule?: ReturnNotificationsModuleType;
  orderItems: OrderItemType[];
  returnsModule: OverviewReturnsModuleType;
  shipTo: ShipToType | null;
  tagManagerModule: GoogleTagManagerModuleType;
  template: TemplateType;
  trackingContextKey: string;
  urlKey: string;
  vanityName: string;
}

export interface OverviewApiResponseErrorType {
  errorTitle: string;
  errors: string[];
}

export enum OverviewFormStepsEnum {
  Zero,
  One,
  Two,
  Three
}

export interface ShipToType {
  emailAddress?: string;
  firstName?: string;
  homePhone?: string;
  lastName?: string;
  mailingAddress?: {
    address1?: string;
    address2?: string;
    city?: string;
    name?: string;
    postalCode?: string;
    stateOrProvince?: string;
  };
}

export interface OrderItemType {
  id: string;
  orderItemId: string;
  description: string;
  name: string;
  quantity: number;
  returnable: boolean;
  exchangeable: boolean;
  sku: string;
  returnMessage: string | null;
  shippingType: ShippingType;
  rootProductAttributes: RootProductAttribute[];
  imageUrl?: string;
  returnLabelId?: string;
  method?: MethodEnum;
  nonReturnableReasonCode?: NonReturnableReasonCode;
  hazmat?: boolean | undefined;
  hazmatType?: string | undefined;
  hazmatTypeSame?: boolean | undefined;
  carrier?: string | undefined;
}

export interface RootProductAttribute {
  attributeName: string;
  attributeNameOrder: number;
  attributeValue: string;
}

type ShippingType = 'standard' | 'expedited' | null;
