import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const Container = styled.div`
  width: 100%;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 30px;

  @media ${({ theme }) => theme.mediumUp} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const HeadingTitle = styled.h3`
  font-family: ${getCustomStyle('styleModule.bodyFontFamily')};
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  color: #4a4a4a;

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 18px;
  }
`;

export const Switchers = styled.div`
  display: flex;
`;
