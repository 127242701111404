import React, { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react';
import { TemplateType } from 'types/shared/template-type';
import { ClientReturnType } from 'types/shared/config/returnsModule/returns-module-type';
import { StyleModuleType } from 'types/shared/config/styleModule/style-module-type';
import { ClientConfigurationType } from 'types/shared/config/client-configuration-type';

export const defaultClientConfigContext: ClientConfigContextType = {
  brandId: '',
  brandName: 'Willow',
  clientReturnType: 'integrated',
  designId: 'DX23',
  isPreview: false,
  merchantId: '2476',
  template: 'vivid',
  vanityName: 'willow',
  styleModule: {
    bodyFontFamily: 'Roboto',
    buttonStyle: 'Rounded',
    buttonStylePill: false,
    buttonStyleRectangular: false,
    buttonStyleRounded: true,
    connectorLineColor: '#00253f',
    darkPrimaryColor: '#003051',
    darkerPrimaryColor: '#00253f',
    headerFontFamily: 'Roboto',
    mapStyle: 'default',
    primaryBackgroundDark: true,
    primaryColor: '#006bb5',
    secondaryBackgroundDark: true,
    secondaryColor: '#004f85',
    subheaderFontFamily: 'Roboto',
    tertiaryBackgroundDark: true,
    tertiaryColor: '#d00000'
  },
  clientConfig: {
    brandId: '',
    brandName: 'Willow',
    customHtmlModule: {
      content: null,
      enabled: false
    },
    designId: null,
    exploreTheBrandModule: {
      authorQuote: null,
      buttonCopy: 'Shop Accessories ',
      callToActionCopy: 'Check out the latest trendsetting pieces.',
      enabled: true,
      featuredImageUrl: 'https://images.ctfassets.net/nw7bgk8j5bv3/6HV7zohvvmVXJrVsqa8Tk0/4c1c62f85920d05abd7fcd182eebd127/3SHI58kfCGjHfLb75bwlRE',
      heroImage: 'https://images.ctfassets.net/nw7bgk8j5bv3/3mYDvkF4sDSz1LzYN3FcJP/e1252d5a9acfea72d8f4e1640b38700f/7qJmYX2hDAWRkYLrXfgioR',
      imageUrls: [
        'https://images.ctfassets.net/nw7bgk8j5bv3/1lmjdVFZTanT61DXRf7A8t/eb26ce73b532fa897bf16d487cd53937/L0V0hZPs5pb8MuVBRcPBh',
        'https://images.ctfassets.net/nw7bgk8j5bv3/4c9Y1cRsBcMwxI45xBjFZ0/2e0d304c057a27af3300e3d9393f1f57/4eKE8UE4eQ8KmADnrDqKeb'
      ],
      quote: null,
      subTitle: 'Rock the style with our new trendsetting accessories.',
      title: 'Step in Style',
      url: 'http://willowdemo.paas.tacitknowledge.com/'
    },
    featuredProductsModule: {
      enabled: true,
      subtitle: "We thought that you'd like these.",
      title: 'Online Exclusives'
    },
    feedbackQuestionsModule: {
      headerText: "We'd love your feedback!",
      isEnabled: true
    },
    footerModule: {
      contactInformation: {
        enabled: true,
        phoneNumber: '650-123-4567',
        websiteUrl: 'https://willow.shipment-demo.co/track'
      },
      socialLinks: [
        {
          link: 'http://willowdemo.paas.tacitknowledge.com/',
          name: 'facebook'
        },
        {
          link: 'http://willowdemo.paas.tacitknowledge.com/',
          name: 'pinterest'
        },
        {
          link: 'http://willowdemo.paas.tacitknowledge.com/',
          name: 'instagram'
        }
      ],
      brandName: 'Willow'
    },
    headerModule: {
      backgroundColor: '#000000',
      faviconsModule: {
        mainImageUrl: 'https://images.ctfassets.net/nw7bgk8j5bv3/2vFyBssNuj2PKVPTg695y2/ea6d00b284bf34fa6a6c112524009e9f/5syvMpEBZIo9NximiLnF0',
        useDefault: false
      },
      gotoUrl: 'go to willowdemo.paas.tacitknowledge.com',
      headerBackgroundDark: false,
      heroImage: 'https://images.ctfassets.net/nw7bgk8j5bv3/1T9QUR3w3V251ajZ07AEUp/38d74ea8e6a8f3be973d66d85e55cc48/s5GyHchb75FUutUtTbCBe',
      logo: 'https://images.ctfassets.net/nw7bgk8j5bv3/kBI1SZRf9MgSp7bvfWAfP/b68a61a29d141c419211757967297a41/5Qsou2JWyDb126VVu8fZlq',
      logoAligned: 'left',
      logoText: 'WILLOW',
      logoUrl: 'http://willowdemo.paas.tacitknowledge.com/',
      navigations: [
        {
          label: 'Women',
          url: 'https://willow.shipment-demo.co/track'
        },
        {
          label: 'Men',
          url: 'https://willow.shipment-demo.co/track'
        },
        {
          label: 'Accessories',
          url: 'https://willow.shipment-demo.co/track'
        },
        {
          label: 'Beauty',
          url: 'https://willow.shipment-demo.co/track'
        },
        {
          label: 'About Us',
          url: 'https://willow.shipment-demo.co/track'
        }
      ],
      showBackgroundAsColor: false,
      showLogoAsText: false
    },
    isPreview: false,
    merchantId: '2476',
    notificationsModule: {
      backgroundColor: '#fbc489',
      backgroundImage: null,
      emailEnabled: true,
      headerText: 'Get notifications.',
      notificationBackgroundDark: false,
      showBackgroundAsColor: true,
      smsEnabled: true,
      subheadText: "We'll send you shipping updates to keep you in the loop."
    },
    orderDetailsModule: {
      enabled: true,
      orderNumberUrl: 'http://willowdemo.paas.tacitknowledge.com',
      packageLevelDisplay: false
    },
    promotionsAddonModule: {
      buttonCopy: 'Shop Sunglasses',
      enabled: true,
      imageUrl: 'https://images.ctfassets.net/nw7bgk8j5bv3/1ak4DsFMiQ8unbvjdiLnV4/eb4123ad0f531398185d14226429ec8d/6rvTzO37dXPi74F9fvTd1V',
      productFeedEnabled: false,
      subtitle: 'Let the sun shine! Protect your eyes in our trendiest and smartest sunglasses.',
      title: 'Sunglass Sale',
      url: 'http://willowdemo.paas.tacitknowledge.com/'
    },
    returnsModule: {
      clientReturnType: 'integrated',
      consumerComments: true,
      customOrderNumberField: {
        labelValue: 'Order Number',
        maxLengthValidation: 20,
        allowDashes: true
      },
      enabled: true,
      headerCopy: 'Need to submit a return?',
      reasonRequired: false,
      reasonsEnabled: true,
      returnsPolicyView: {
        freeShipping: true,
        policyParagraph: {
          text:
            'Return postage is the responsibility of the customer, except for damaged or defective items, duplicate items, or items that you did not order. The final determination of the product condition is subject to our inspection. All other returns will be charged for return shipping and $5.95 will be deducted from your credit.\n',
          enabled: true
        },
        policyParagraphLink: {
          url: 'https://willow.shipment-demo.co/track',
          enabled: true
        }
      },
      returnsReasonViews: [
        {
          commentRequired: true,
          id: null,
          reasonText: 'Received wrong Item'
        },
        {
          commentRequired: false,
          id: null,
          reasonText: 'Damaged'
        },
        {
          commentRequired: false,
          id: null,
          reasonText: "Don't Like"
        },
        {
          commentRequired: false,
          id: null,
          reasonText: "Doesn't Fit"
        }
      ],
      subheadCopy: "If something didn't quite work out with your order, it's easy to set up a return."
    },
    searchByOrderNumberEnabled: true,
    styleModule: {
      bodyFontFamily: 'Roboto',
      buttonStyle: 'Pill',
      buttonStylePill: true,
      buttonStyleRectangular: false,
      buttonStyleRounded: false,
      connectorLineColor: '#00253f',
      darkerPrimaryColor: '#00253f',
      darkPrimaryColor: '#003051',
      headerFontFamily: 'Roboto',
      mapStyle: 'default',
      primaryBackgroundDark: true,
      primaryColor: '#006bb5',
      secondaryBackgroundDark: true,
      secondaryColor: '#004f85',
      subheaderFontFamily: 'Roboto',
      tertiaryBackgroundDark: true,
      tertiaryColor: '#d00000'
    },
    tagManagerModule: {
      tagManagerEnvironment: 'gtm_auth=gKRu_TOp7dRssML4vPX3Gg&gtm_preview=env-3',
      tagManagerId: 'GTM-KQ3XH2K'
    },
    template: 'vivid',
    vanityName: 'willow'
  }
};

export const ClientConfigContext = React.createContext<ClientConfigContextType>(defaultClientConfigContext);

export const ClientConfigContextProvider: React.FC<ClientConfigContextProviderType> = (props: PropsWithChildren<ClientConfigContextProviderType>) => {
  const {
    children,
    initialBrandId,
    initialBrandName,
    initialDesignId,
    initialClientReturnType,
    initialIsPreview,
    initialVanityName,
    initialMerchantId,
    initialTemplate,
    initialStyleModule,
    initialClientConfig
  } = props;

  const [brandName, setBrandName] = useState<string>(initialBrandName);
  const [clientReturnType, setClientReturnType] = useState<ClientReturnType>(initialClientReturnType);
  const [designId, setDesignedId] = useState<string | null>(initialDesignId);
  const [isPreview, setIsPreview] = useState<boolean>(initialIsPreview);
  const [merchantId, setMerchantId] = useState<string>(initialMerchantId);
  const [template, setTemplate] = useState<TemplateType>(initialTemplate);
  const [brandId, setBrandId] = useState<string>(initialBrandId);
  const [vanityName, setVanityName] = useState<string>(initialVanityName);
  const [styleModule, setStyleModule] = useState<StyleModuleType>(initialStyleModule);
  const [clientConfig, setClientConfig] = useState<ClientConfigurationType>(initialClientConfig);

  useEffect(() => {
    setBrandId(initialBrandId);
    setBrandName(initialBrandName);
    setClientReturnType(initialClientReturnType);
    setDesignedId(initialDesignId);
    setIsPreview(initialIsPreview);
    setMerchantId(initialMerchantId);
    setTemplate(initialTemplate);
    setVanityName(initialVanityName);
    setStyleModule(initialStyleModule);
    setClientConfig(initialClientConfig);
  }, [
    initialBrandId,
    initialBrandName,
    initialDesignId,
    initialClientReturnType,
    initialIsPreview,
    initialVanityName,
    initialMerchantId,
    initialTemplate,
    initialStyleModule,
    initialClientConfig
  ]);

  const value = useMemo<ClientConfigContextType>(
    () => ({
      brandId,
      brandName,
      clientReturnType,
      designId,
      isPreview,
      merchantId,
      template,
      vanityName,
      styleModule,
      clientConfig
    }),
    [brandName, clientReturnType, designId, isPreview, merchantId, template, brandId, vanityName, styleModule, clientConfig]
  );

  return <ClientConfigContext.Provider value={value}>{children}</ClientConfigContext.Provider>;
};

export interface ClientConfigContextType {
  brandId: string;
  brandName: string;
  clientConfig: ClientConfigurationType;
  clientReturnType: ClientReturnType;
  designId: string | null;
  isPreview: boolean;
  merchantId: string;
  styleModule: StyleModuleType;
  template: TemplateType;
  vanityName: string;
}

export interface ClientConfigContextProviderType {
  children: ReactNode;
  initialBrandId: string;
  initialBrandName: string;
  initialClientConfig: ClientConfigurationType;
  initialClientReturnType: ClientReturnType;
  initialDesignId: string | null;
  initialIsPreview: boolean;
  initialMerchantId: string;
  initialStyleModule: StyleModuleType;
  initialTemplate: TemplateType;
  initialVanityName: string;
}
