import React from 'react';
import { LabelContainer } from './SpecialLabel.styled';

export const SpecialLabel: React.FC<OwnProps> = (props) => {
  const { label, dataGtmId, testId } = props;
  return (
    <LabelContainer data-test-id={testId ? testId : 'special-label'} data-gtm-id={dataGtmId}>
      {label}
    </LabelContainer>
  );
};

export interface OwnProps {
  label: string;
  dataGtmId?: string;
  testId?: string;
}
