import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  max-width: 380px;
`;

export const Title = styled.h1`
  margin: 0 0 25px 0;
  padding: 0;
  color: #4a4a4a;
  font-size: 26px;
  font-weight: 400;
`;

export const Text = styled.p`
  width: 100%;
  margin: 0;
  padding: 0;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
`;

export const Link = styled.a`
  text-decoration: underline;
  text-decoration-color: rgba(25, 118, 210, 0.4);
  color: #1976d2;
  font-size: 16px;
  display: inline-block;
  text-align: left;
  transition: all 0.2s;

  &:hover {
    transition: text 0.2s;
    color: #1976d2;
    text-decoration-color: rgba(25, 118, 210, 1);
  }
`;
