import { Environment, resolveEnvironment } from 'utils/common/environment-resolver/environment-resolver';

export const resolveVanityNameAPIHost = (vanityName: string): string => {
  const env: Environment = resolveEnvironment();
  const domain = window.location.href;
  if (domain.includes('shipment.co')) return `https://${vanityName}.shipment.co`;

  switch (env) {
    case Environment.AWS_DEV:
      return `https://${vanityName}.shipment-dev.co`;
    case Environment.AWS_PPD:
      return `https://${vanityName}.shipment-demo.co`;
    case Environment.AWS_PRD:
      return `https://${vanityName}.shipment-prd.co`;
    default:
      return 'http://localhost:3000';
  }
};
