import styled from '@emotion/styled';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';
import { css } from '@emotion/react';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const Form = styled.form`
  width: 100%;

  &:nth-of-type(2) {
    margin: 30px 0 0 0;
  }
`;

export const FormFieldset = styled.fieldset`
  padding: 0;
  margin: 0;
  border: none;
  width: 100%;
`;

export const FormLabel = styled.label<{ isDark?: boolean }>`
  margin: 0 0 15px 0;
  display: block;
  font-weight: 200;

  font-family: ${({ theme }) =>
    getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : theme.secondaryFontFamily};

  ${({ theme, isDark }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      font-size: 14px;
      text-align: center;
      color: ${isDark ? theme.darkBackgroundColor : theme.white};

      @media ${theme.largeUp} {
        text-align: left;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      color: ${theme.primaryBackgroundDark ? theme.white : theme.buttonColorDark};
      font-size: 13px;
      font-weight: 100;
    `}
`;

export const FormLabelLink = styled.a`
  font-family: ${({ theme }) =>
    getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : theme.secondaryFontFamily};
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
`;

export const FormButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FormStatusMessage = styled.p<{ visible: boolean; error: boolean }>`
  min-height: 21px;
  font-weight: 700;
  font-family: ${() => (getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : '')};
  padding: 0;
  color: ${({ error, theme }) => (error ? '#dd1010' : theme.template === TemplateEnum.VIVID ? '#246917' : '#b8efae')};
  margin: 0 0 5px 0;
  font-size: 17px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      text-align: center;

      @media ${theme.largeUp} {
        text-align: left;
      }
    `}
`;

export const FormSubmitButton = styled.button<{ formType: 'sms' | 'email' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  font-family: ${() => (getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : '')};
  width: 100%;
  border: none;
  position: absolute;
  transform: translate(0, -50%);
  text-transform: uppercase;
  top: 50%;
  right: -1px;
  border-radius: 0 5px 5px 0;
  letter-spacing: 1.6px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primaryColor600};
  transition: 0.3s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor500};
    transition: 0.3s ease-out;
  }

  ${({ theme, formType }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      height: calc(100% + ${formType === 'email' ? '0' : '4'}px);

      font-size: 12px;
      max-width: 90px;
    `}

  ${({ theme, formType }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      height: calc(100% + ${formType === 'email' ? '0' : '3'}px);
      max-width: 60px;
      font-size: 9px;

      @media ${theme.mediumUp} {
        max-width: 80px;
        font-size: 11px;
      }

      @media ${theme.xlargeUp} {
        max-width: 70px;
        font-size: 10px;
      }

      @media (min-width: 1400px) {
        max-width: 80px;
        font-size: 11px;
      }
    `}
`;

export const FormAdditionalButtonsContainer = styled.div`
  background: ${({ theme }) => theme.white};
  position: absolute;
  transform: translate(0, -50%);
  text-transform: uppercase;
  top: 50%;
  right: 1px;
  display: flex;
  align-items: center;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      padding: 0 15px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      padding: 0 5px;

      @media ${theme.mediumUp} {
        padding: 0 15px;
      }

      @media ${theme.xlargeUp} {
        padding: 0 5px;
      }

      @media (min-width: 1400px) {
        padding: 0 15px;
      }
    `}
`;

export const FormAdditionalButton = styled.button`
  font-family: ${() => (getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : '')};
  color: ${({ theme }) => theme.primaryColor600};
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.6px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-out;

  &:hover {
    transition: 0.3s ease-out;
    color: ${({ theme }) => theme.primaryColor400};
  }

  &:first-of-type {
    padding: 3px 12px 3px 0;
  }

  &:last-of-type {
    padding: 3px 0 3px 12px;
  }
`;

export const FormAdditionalButtonBreak = styled.div`
  height: 7px;
  width: 1px;
  background-color: ${({ theme }) => theme.primaryColor600};
`;

export const FormResetButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: ${() => getCustomStyle('styleModule.bodyFontFamily')};
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? theme.white : theme.buttonColorDark)};
  font-size: 11px;
  letter-spacing: 1.6px;
`;

export const InputErrorMessage = styled.p`
  font-family: ${() => (getCustomStyle('styleModule.bodyFontFamily') ? getCustomStyle('styleModule.bodyFontFamily') : '')};
  padding: 0;
  margin: 0;
  color: #dd1010;
  font-size: 14px;
  font-weight: 400;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
`;
