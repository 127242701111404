import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TemplateEnum } from '../../../types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const BannersContainer = styled.section<{ marginDefault?: string }>`
  margin: ${({ marginDefault }) => (marginDefault ? marginDefault : 0)};
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fcf6f2;
  ${({ theme }) =>
    theme &&
    (theme.template === TemplateEnum.SPARTAN || theme.pageType === PageTypeEnum.CustomerServicePage) &&
    css`
      justify-content: flex-start;
    `}
`;

export const BannersContent = styled.div`
  padding: 0 20px;
  background-color: #fcf6f2;
  width: 100%;

  @media ${({ theme }) => theme.mediumUp} {
    padding: 0 30px;
  }

  @media ${({ theme }) => theme.largeUp} {
    padding: 0 50px;
  }

  @media ${({ theme }) => theme.xlargeUp} {
    padding: 0 115px;
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    padding: 0 115px;
  }

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.CustomerServicePage &&
    css`
      padding: 0 15px;

      @media ${theme.mediumUp} {
        padding: 0 30px;
      }

      @media ${theme.largeUp} {
        padding: 0 30px;
      }

      @media ${theme.xlargeUp} {
        padding: 0 95px;
      }

      @media ${theme.xxlargeUp} {
        padding: 0 115px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      max-width: 840px;

      @media ${theme.mediumUp} {
        padding: 0 30px;
      }

      @media ${theme.largeUp} {
        padding: 0 30px;
      }

      @media ${theme.xlargeUp} {
        padding: 0 30px;
      }

      @media ${theme.xxlargeUp} {
        padding: 0 30px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      @media ${theme.mediumUp} {
        padding: 0 30px;
      }

      @media ${theme.largeUp} {
        padding: 0 50px;
      }

      @media ${theme.xlargeUp} {
        padding: 0 50px;
      }

      @media ${theme.xxlargeUp} {
        padding: 0 120px;
      }
    `}
`;
