import React from 'react';
import { Container, Subheading, CustomPBIcon } from './SortedParcels.styled';
import { Parcel, ParcelTypeEnum } from 'types/customer-service/find-parcels-api-response-type';
import { PBMiniIconsEnum } from 'constants/PBIconsEnums';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { SearchBy } from 'types/customer-service/search-by-type';
import { TileView } from '../../Common/TileView/TileView';

export const SortedParcels: React.FC<OwnProps> = (props) => {
  const { sortedParcels, parcelsType, subheading, iconName } = props;

  const convertedParcelsType = parcelsType.toLowerCase();

  return (
    <Container data-test-id={`sorted-${convertedParcelsType}-type-parcels`}>
      <Subheading id={`${convertedParcelsType}-parcels-subheading`}>
        <CustomPBIcon
          type={PBIconTypeEnum.Mini}
          name={iconName}
          aria-label={`${convertedParcelsType}-parcels-subheading-icon`}
          parcelsType={parcelsType}
        />
        {subheading}
      </Subheading>
      <TileView parcels={sortedParcels} searchBy={SearchBy.ORDER_NUMBER} />
    </Container>
  );
};

export interface OwnProps {
  sortedParcels: Parcel[];
  parcelsType: ParcelTypeEnum;
  subheading: string;
  iconName: PBMiniIconsEnum.DeliveryFast | PBMiniIconsEnum.Undo | PBMiniIconsEnum.AlertCircle;
}
