import styled from '@emotion/styled';

export const QRCodeContainer = styled.div``;

export const QRCodeWrapper = styled.div`
  display: block;
  margin-bottom: 80px;

  @media ${({ theme }) => theme.largeUp} {
    display: flex;
    justify-content: space-between;
  }
`;

export const QRCodeTitle = styled.h3`
  font-size: 18px;
  margin: 0 0 20px 0;
  font-weight: normal;
`;
