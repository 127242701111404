import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const SectionDescriptionContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const SectionDescriptionTitle = styled.h3`
  font-size: 18px;
  margin: 0;
  text-align: center;
  font-family: ${getCustomStyle('styleModule.headerFontFamily')};
`;

export const SectionDescriptionSubtitle = styled.p`
  margin: 5px 0 0 0;
  font-size: 12px;
  color: ${({ theme }) => theme.gray500};
  text-align: center;
  font-family: ${getCustomStyle('styleModule.bodyFontFamily')};
`;
