import React, { PropsWithChildren, useContext } from 'react';
import {
  PickupAddressSummaryContainer,
  PickupAddressSummaryElement,
  PickupAddressSummaryTitle,
  PickupAddressSummaryWrapper
} from './PickupAddressSummary.styled';
import { PickupContext } from 'context/returns/PickupContext';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { useIntl } from 'react-intl';
import { isDemoLink } from 'utils/returns/returnInfo/label/is-demo-link/is-demo-link';

export const PickupAddressSummary: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { addressOne, addressTwo, city, state, zipCode, inputOrderId } = props;
  const { setSchedulePickupFormError, setShowPickupFromAddressForm } = useContext(PickupContext);

  const intl = useIntl();

  const handleClickChangePickupAddress = () => {
    setSchedulePickupFormError(null);
    setShowPickupFromAddressForm(true);
  };

  return (
    <PickupAddressSummaryContainer>
      <PickupAddressSummaryWrapper>
        <PickupAddressSummaryTitle data-test-id="pickup-address-title">
          {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickupFromAddressSummary.title' })}
        </PickupAddressSummaryTitle>
        <CustomButton
          data-gtm-id={!isDemoLink(inputOrderId) ? 'usps-pickup-change-address-button' : undefined}
          data-test-id="pickup-address-summary-change-button"
          style={{ marginLeft: '60px' }}
          type="button"
          variant="text"
          onClick={handleClickChangePickupAddress}
          isUppercase={false}>
          {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickupFromAddressSummary.button' })}
        </CustomButton>
      </PickupAddressSummaryWrapper>
      <PickupAddressSummaryElement data-test-id="pickup-address-element-address1">{addressOne}</PickupAddressSummaryElement>
      {addressTwo && <PickupAddressSummaryElement data-test-id="pickup-address-element-address2">{addressTwo}</PickupAddressSummaryElement>}
      <PickupAddressSummaryElement data-test-id="pickup-address-element-city">
        {city} {state} {zipCode}
      </PickupAddressSummaryElement>
    </PickupAddressSummaryContainer>
  );
};

export interface OwnProps {
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zipCode: string;
  inputOrderId: string;
}
