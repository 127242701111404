import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect } from 'react';
import { LabelContainer } from './Label.styled';
import { LabelInfo } from './LabelInfo/LabelInfo';
import { LabelPDF } from './LabelPDF/LabelPDF';
import { setLabelPrintUrl } from 'utils/returns/returnInfo/label/set-label-print-url/set-label-print-url';
import { NoLabelImage } from 'components/Returns/ReturnInfo/Label/NoLabelImage/NoLabelImage';
import { ReturnInfoApiResponseSuccessType } from 'types/return/return-info/return-info-api-response-type';
import { initGoogleTagManagerDataLayer } from '../../../../utils/common/google-tag-manager/google-tag-manager';
import { ClientConfigContext } from '../../../../context/shared/ClientConfigContext';

export const Label: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { urlKey, labelId, shippingPostalCode, inputOrderId, setPdfError, pdfError, returnInfoConfig, carrierName } = props;

  const { merchantId, vanityName, clientReturnType, template, brandId } = useContext(ClientConfigContext);
  const { pdfLabelLink, imgLabelLink, pdfContentLink } = returnInfoConfig;

  const SEND_EMAIL_URL = `/return/${vanityName}/${labelId}/email?inputOrderId=${inputOrderId}${
    shippingPostalCode ? `&shippingPostalCode=${shippingPostalCode}` : ''
  }${urlKey ? `&key=${urlKey}` : ''}`;
  const LABEL_PRINT_URL = setLabelPrintUrl(pdfContentLink, pdfLabelLink);

  useEffect(() => {
    if (returnInfoConfig) {
      return initGoogleTagManagerDataLayer(
        returnInfoConfig.tagManagerModule.deliveryType,
        returnInfoConfig.tagManagerModule.trackingStatus,
        merchantId,
        vanityName,
        null,
        'Return Label',
        'returnPageview',
        clientReturnType === 'integrated' ? 'RMA' : 'Non-integrated',
        template,
        !!urlKey,
        null,
        brandId
      );
    }
  }, [returnInfoConfig, merchantId, vanityName, clientReturnType, template, urlKey, brandId]);

  if (pdfError) return <NoLabelImage sendEmailUrl={SEND_EMAIL_URL} />;
  return (
    <LabelContainer data-test-id="label-container">
      <LabelInfo
        sendEmailUrl={SEND_EMAIL_URL}
        labelPrintUrl={LABEL_PRINT_URL}
        labelId={labelId}
        inputOrderId={inputOrderId}
        carrierName={carrierName}
      />
      <LabelPDF setPdfError={setPdfError} sapiLabel={pdfContentLink} newgisticsLabel={imgLabelLink} />
    </LabelContainer>
  );
};

export interface OwnProps {
  labelId: string;
  urlKey: string | null;
  inputOrderId: string;
  shippingPostalCode: string | null;
  pdfError: boolean;
  setPdfError: Dispatch<SetStateAction<boolean>>;
  returnInfoConfig: ReturnInfoApiResponseSuccessType;
  carrierName?: string | null;
}
