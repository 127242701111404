import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const MethodContainer = styled.div`
  margin-bottom: 20px;
  height: auto;
  &:last-of-type {
    margin: 0;
  }
`;

export const MethodInput = styled.input`
  appearance: none;
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
  background: transparent;
  border: none;
  display: block;
`;

export const MethodLabel = styled.label<{ checked?: boolean }>`
  cursor: pointer;
  width: 100%;
  padding: 25px;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  display: flex;
  justify-content: space-between;

  &:hover,
  &:focus {
    border: solid 1px #383838;
  }
  ${({ checked }) =>
    checked &&
    css`
      padding: 24px;
      border: solid 2px #383838 !important;
      background-color: #f8f8f8;
      box-shadow: 2px 2px 4px rgb(60 60 59 / 15%);
      &:hover,
      &:focus {
        border: solid 2px #383838 !important;
      }
    `}
`;

export const MethodError = styled.label`
  color: red;
  font-size: 12px;
`;

export const SecondContentContainer = styled.div`
  width: calc(100% - 30px);
  @media ${({ theme }) => theme.mediumUp} {
    width: calc(100% - (30px + 50px + 50px));
    padding-left: 20px;
    padding-right: 2px;
  }
  @media ${({ theme }) => theme.smallDown} {
    width: auto;
  }
`;

export const SecondContentFirstWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${({ theme }) => theme.mediumUp} {
    display: none;
  }
`;

export const Icon = styled.img`
  width: 50px;
  @media ${({ theme }) => theme.mediumUp} {
    display: none;
  }
`;

export const Icon2 = styled.img`
  display: none;
  @media ${({ theme }) => theme.mediumUp} {
    display: block;
    width: 50px;
    align-self: flex-start;
  }
`;

export const Icon3 = styled.img`
  position: relative;
  top: 10px;
  padding-right: 10px;
  width: 6.4%;
  @media ${({ theme }) => theme.smallDown} {
    width: 15%;
  }
`;

export const SecondContentWrapper = styled.div``;

export const MethodPrice = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  align-self: center;
  @media ${({ theme }) => theme.mediumUp} {
    font-size: 14px;
  }
`;

export const MethodPrice2 = styled.p`
  display: none;
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  @media ${({ theme }) => theme.mediumUp} {
    width: 50px;
    justify-content: flex-end;
    display: flex;
    font-size: 14px;
  }
`;

export const MethodTitle = styled.h3`
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 14px;
  margin: 0 0 1px 0;
`;

export const MethodSubtitle = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 300;
  font-size: 14px;
  margin: 0 0 0 0;
`;

export const MethodDescription = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 300;
  font-size: 14px;
  margin: 0px 0px 18px 0px;
`;

export const MethodShowqr = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 300;
  font-size: 14px;
  margin: 0 0 15px 0;
`;

export const MethodEstimate = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 300;
  font-size: 14px;
  margin: 0 0 1px 0;
`;

export const MethodPriceInformation = styled.p`
  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
  color: ${({ theme }) => theme.bodyFontColor};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 300;
  font-size: 12px;
  margin: 0 0 0 0;
`;
