import React, { useContext, useEffect, useState } from 'react';
import { DropOffLocationsContainer } from './DropOffLocations.styled';
import { DropOffLocationsForm } from './DropOffLocationsForm/DropOffLocationsForm';
import { DropOffLocationType } from 'types/return/return-info/Common/drop-off-location-type';
import { DropOffLocationsSearchParams, DropOffLocationsErrorResponse } from 'types/return/return-info/Common/drop-off-locations-response';
import {
  buildDropOffLocationsURL,
  buildUserLocationURL
} from 'utils/returns/return-info/drop-off-locations/build-drop-off-locations-url/build-drop-off-locations-url';
import { FormProvider, useForm } from 'react-hook-form';
import { DropOffLocationsFormValues } from 'types/return/return-info/Common/drop-off-locations-form-values';
import { transformOriginAddress } from 'utils/returns/return-info/drop-off-locations/transform-origin-address/transform-origin-address';
import { OriginAddress } from 'types/return/return-info/Common/origin-address-type';
import { DropOffLocationsList } from 'components/Returns/Common/DropOffLocationsList/DropOffLocationsList';
import { userTime } from 'utils/shared/user-time/user-time';
import { useSearchParams } from 'react-router-dom';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';

export const DropOffLocations: React.FC<OwnProps> = (props) => {
  const { returnZipCode } = props;
  const { brandId } = useContext(ClientConfigContext);

  const [urlSearchParams] = useSearchParams();
  const [orderNumber] = useState<string | null>(urlSearchParams.get('inputOrderId'));

  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<DropOffLocationsSearchParams>({
    zipCode: returnZipCode,
    street: '',
    city: '',
    state: ''
  });
  const [originAddress, setOriginAddress] = useState<OriginAddress>({
    zipCode: returnZipCode,
    street: null,
    city: null,
    state: null
  });

  const [dropOffLocations, setDropOffLocations] = useState<DropOffLocationType[]>([]);
  const [isDropOffLocationsLoading, setIsDropOffLocationsLoading] = useState<boolean>(true);
  const [errorsInfo, setErrorsInfo] = useState<DropOffLocationsErrorResponse | null>(null);
  const [userLatitudePosition, setUserLatitudePosition] = useState<number>(39.80986);
  const [userLongitudePosition, setUserLongitudePosition] = useState<number>(-98.555183);

  const todaysDate = new Date();
  const USER_TIME: string = userTime(todaysDate);

  const methods = useForm<DropOffLocationsFormValues>({
    defaultValues: {
      street: '',
      city: '',
      state: '',
      zipCode: ''
    },
    mode: 'onChange'
  });

  const getDropOffLocations = async (searchParams: DropOffLocationsSearchParams) => {
    setErrorsInfo(null);
    setDropOffLocations([]);
    setOriginAddress(transformOriginAddress(searchParams));

    try {
      setIsDropOffLocationsLoading(true);
      const DROP_OFF_LOCATIONS_URL = buildDropOffLocationsURL(searchParams);
      const GETDropOffLocationsFetch = await fetch(DROP_OFF_LOCATIONS_URL, {
        headers: {
          'X-CLIENT-TIME': USER_TIME,
          'X-ORDER-NUMBER': orderNumber ? orderNumber : '',
          'X-BRAND-ID': brandId
        }
      });

      const dropOffLocationsResponse = await GETDropOffLocationsFetch.json();

      if (dropOffLocationsResponse.errorTitle) return setErrorsInfo(dropOffLocationsResponse);

      if (!GETDropOffLocationsFetch.ok) {
        return setErrorsInfo({
          errorTitle: 'RETURN.returnInfo.dropOffLocations.error.serverError',
          errors: []
        });
      }

      setDropOffLocations(dropOffLocationsResponse);

      const USER_LOCATION_URL = buildUserLocationURL(searchParams);
      const GETUserLocationFetch = await fetch(USER_LOCATION_URL, {
        headers: {
          'X-CLIENT-TIME': USER_TIME,
          'X-ORDER-NUMBER': orderNumber ? orderNumber : '',
          'X-BRAND-ID': brandId
        }
      });

      if (GETUserLocationFetch.status === 200) {
        const userLocationResponse = await GETUserLocationFetch.json();
        if (userLocationResponse && userLocationResponse.latitude && userLocationResponse.longitude) {
          setUserLatitudePosition(Number(userLocationResponse.latitude));
          setUserLongitudePosition(Number(userLocationResponse.longitude));
        }
      }
    } catch (e) {
      return setErrorsInfo({
        errorTitle: 'RETURN.returnInfo.dropOffLocations.error.serverError',
        errors: []
      });
    } finally {
      setIsDropOffLocationsLoading(false);
      methods.reset();
    }
  };

  useEffect(() => {
    getDropOffLocations(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <FormProvider {...methods}>
      <DropOffLocationsContainer>
        {isFormVisible && <DropOffLocationsForm isDropOffLocationsLoading={isDropOffLocationsLoading} setSearchParams={setSearchParams} />}
        <DropOffLocationsList
          items={dropOffLocations}
          originAddress={originAddress}
          isLoading={isDropOffLocationsLoading}
          errorTitle={!!errorsInfo ? errorsInfo.errorTitle : null}
          errors={!!errorsInfo ? errorsInfo.errors : []}
          isFormVisible={isFormVisible}
          setIsFormVisible={setIsFormVisible}
          userLatitudePosition={userLatitudePosition}
          userLongitudePosition={userLongitudePosition}
        />
      </DropOffLocationsContainer>
    </FormProvider>
  );
};

export interface OwnProps {
  returnZipCode: string;
}
