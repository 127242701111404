import { SelectedProducts } from 'context/returns/ProductsSelectionContext';
import { ExchangeProductReviewType, SelectedProduct } from 'models/returns/selected-product/SelectedProduct';
import { ReturnOptionEnum } from 'types/return/overview/review/return-option-enum';
import { SelectedProductPayloadType } from 'types/return/overview/selected-product-payload-type';
import { isItemOptionSelected } from '../is-item-option-selected/is-item-option-selected';
import { isItemChecked } from '../is-item-checked/is-item-checked';

export const transformSelectedProducts = (selectedProducts: SelectedProducts): SelectedProductPayloadType[] => {
  const transformedItems: SelectedProductPayloadType[] = [];
  Object.values(selectedProducts).forEach((selectedProduct: SelectedProduct) => {
    const { sku, orderItemId, quantity, reason, comment, option, exchangeProducts, hazmat, hazmatType, carrier } = selectedProduct;

    if (!(isItemChecked(selectedProduct) && isItemOptionSelected(selectedProduct))) return;

    const tempExchangeItemSkus: string[] = [];

    if (option === 'exchange')
      Object.values(exchangeProducts).forEach((exchangeProduct: ExchangeProductReviewType) => {
        Array.from(Array(exchangeProduct.quantity).keys()).forEach(() => tempExchangeItemSkus.push(exchangeProduct.sku));
      });

    transformedItems.push({
      option: option ? ReturnOptionEnum[option] : ReturnOptionEnum['return'],
      orderItemId,
      sku,
      comment,
      reason,
      quantity,
      exchangeItemSkus: tempExchangeItemSkus,
      hazmat: hazmat,
      hazmatType: hazmatType,
      carrier: carrier
    });
  });

  return transformedItems;
};
