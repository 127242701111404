import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { MethodsContainer } from './Methods.styled';
import PageTitle from 'components/Shared/PageTitle/PageTitle';
import { MethodsForm } from './MethodsForm/MethodsForm';
import { scrollToTop } from 'utils/returns/overview/common/scroll-to-top/scroll-to-top';
import { OverviewApiResponseSuccessType } from 'types/return/overview/overview-api-response-type';
import { initGoogleTagManagerDataLayer } from '../../../../utils/common/google-tag-manager/google-tag-manager';
import { ClientConfigContext } from '../../../../context/shared/ClientConfigContext';
import { useIntl } from 'react-intl';

export const Methods: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { overviewConfig, orderNumber, shippingPostalCode } = props;

  const intl = useIntl();

  const { brandName, merchantId, vanityName, clientReturnType, template, brandId } = useContext(ClientConfigContext);

  useEffect(() => {
    scrollToTop('auto');
  }, []);

  useEffect(() => {
    if (overviewConfig) {
      return initGoogleTagManagerDataLayer(
        overviewConfig.tagManagerModule.deliveryType,
        overviewConfig.tagManagerModule.trackingStatus,
        merchantId,
        vanityName,
        null,
        'Return Method',
        'returnPageview',
        overviewConfig.returnsModule.clientReturnType === 'integrated' ? 'RMA' : 'Non-integrated',
        template,
        !!overviewConfig.urlKey,
        null,
        brandId
      );
    }
  }, [overviewConfig, merchantId, vanityName, clientReturnType, template, brandId]);

  return (
    <MethodsContainer data-test-id="methods-step-container">
      <PageTitle title={intl.formatMessage({ id: 'RETURN.overview.methods.page.title' })} brandName={brandName} />
      <MethodsForm shippingTypeConfig={overviewConfig.returnsModule.shippingType} orderNumber={orderNumber} shippingPostalCode={shippingPostalCode} />
    </MethodsContainer>
  );
};

export interface OwnProps {
  overviewConfig: OverviewApiResponseSuccessType;
  orderNumber: string;
  shippingPostalCode: string | null;
}
