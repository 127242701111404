import React, { useContext } from 'react';
import { SelectVisibleInput, IconBox } from './LanguageSwitcher.styled';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { FormControl, MenuItem, Select, SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material';
import { languages } from 'constants/languages';
import { LanguageOption, LanguageValueType } from 'types/customer-service/languages-types';
import { LanguageContext } from 'context/shared/LanguageContext';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { PBOutlineIconsEnum } from 'constants/PBIconsEnums';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';

export const LanguageSwitcher = () => {
  const { locale, handleLocale } = useContext(LanguageContext);

  const theme = useTheme();
  const mediumUp = useMediaQuery(theme.breakpoints.up('medium'));

  const handleChange = (event: SelectChangeEvent) => {
    handleLocale(event.target.value as LanguageValueType);
    const languageName = Object.values(languages).filter((languageValue) => languageValue.value === (event.target.value as LanguageValueType))[0]
      .label;
    window.dataLayer.push({
      event: 'languageCodeEvent',
      languageName
    });
  };

  const handleRenderDisplayedValue = (value: LanguageValueType) => {
    const language = Object.values(languages).filter((languageValue) => languageValue.value === value);
    return (
      <SelectVisibleInput>
        <IconBox>
          <PBIcon type={PBIconTypeEnum.Outline} name={PBOutlineIconsEnum.Globe2} data-test-id="language-switcher-icon" />
        </IconBox>
        {language[0].label}
      </SelectVisibleInput>
    );
  };

  return (
    <SectionLayout
      backgroundAsColor={false}
      paddingDefault="50px 15px"
      paddingMd="50px 30px"
      paddingL="50px 30px"
      paddingXl="50px 95px"
      paddingXXl="50px 115px">
      <FormControl
        fullWidth={!mediumUp ? true : false}
        sx={{ width: !mediumUp ? '100%' : '300px', margin: '0 auto 0 0' }}
        size="small"
        data-test-id="language-switcher-form-control">
        <Select
          value={locale}
          onChange={handleChange}
          renderValue={(value: LanguageValueType) => handleRenderDisplayedValue(value)}
          sx={{ color: '#4a4a4a' }}>
          {Object.values(languages).map((language: LanguageOption) => {
            return (
              <MenuItem key={language.label} value={language.value} sx={{ color: '#4a4a4a' }}>
                {language.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </SectionLayout>
  );
};
