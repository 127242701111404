export const createProductReturnLabelLink = (
  vanityName: string,
  orderNumber: string,
  shippingPostalCode: string,
  key: string,
  returnLabelId: string
): string => {
  return `/return/getLabel/${vanityName}?inputOrderId=${orderNumber}&shippingPostalCode=${shippingPostalCode}${
    !!key ? `&key=${key}` : ``
  }&labelId=${returnLabelId}`;
};
