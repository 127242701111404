import { CustomTheme } from 'types/modules';

// colors
const black = '#383838',
  wildSand = '#f6f6f6';

export const theme: Partial<CustomTheme> = {
  black: black,
  bodyBackgroundColor: wildSand
};
