import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBIcomoonIconsEnum, PBMiniIconsEnum, PBOutlineIconsEnum } from 'constants/PBIconsEnums';

const pbIconSpin = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const PBIcon = styled.i<OwnProps>`
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  speak: none;
  text-transform: none;

  ${({ type, name }) => {
    switch (type) {
      case PBIconTypeEnum.Outline: {
        return css`
          @font-face {
            font-family: 'pbi-icon-outline';
            font-style: normal;
            font-weight: 400;
            src: url('/assets/fonts/pbi-icon-outline.woff2') format('woff2'), url('/assets/fonts/pbi-icon-outline.ttf') format('truetype'),
              url('/assets/fonts/pbi-icon-outline.woff') format('woff'), url('/assets/fonts/pbi-icon-outline.svg?#pbi-icon-outline') format('svg');
          }

          font-family: 'pbi-icon-outline' !important;

          &:before {
            content: '${name}';
          }
        `;
      }
      case PBIconTypeEnum.Mini: {
        return css`
          @font-face {
            font-family: 'pbi-icon-mini';
            font-style: normal;
            font-weight: 400;
            src: url('/assets/fonts/pbi-icon-mini.woff2') format('woff2'), url('/assets/fonts/pbi-icon-mini.ttf') format('truetype'),
              url('/assets/fonts/pbi-icon-mini.woff') format('woff'), url('/assets/fonts/pbi-icon-mini.svg?#pbi-icon-mini') format('svg');
          }

          font-family: 'pbi-icon-mini' !important;

          &:before {
            content: '${name}';
          }
        `;
      }
      case PBIconTypeEnum.Icomoon: {
        return css`
            @font-face {
              font-family: 'icomoon';
              src: url('/assets/fonts/icomoon.ttf?m5zd6s') format('truetype'),
              url('/assets/fonts/icomoon.woff?m5zd6s') format('woff'),
              url('/assets/fonts/icomoon.svg?m5zd6s#icomoon') format('svg');
              font-weight: normal;
              font-style: normal;
            }

            font-family: 'icomoon' !important;

            &:before {
              content: '${name}'
            }
          `;
      }
    }
  }}

  ${({ spin }) =>
    spin &&
    css`
      animation: 2s linear infinite ${pbIconSpin};
    `}

  ${({ size }) => {
    if (size === 'lg') {
      return css`
        font-size: 1.33333333em;
        vertical-align: -16%;
      `;
    } else if (typeof size === 'number') {
      return css`
        font-size: ${size}rem;
      `;
    }
  }}

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate}deg);
    `}

  ${({ flip }) => {
    if (flip === 'x') {
      return css`
        transform: scale(1, -1);
      `;
    } else if (flip === 'y') {
      return css`
        transform: scale(-1, 1);
      `;
    }
  }}
`;

export interface OwnProps {
  type: PBIconTypeEnum;
  name: PBIcomoonIconsEnum | PBMiniIconsEnum | PBOutlineIconsEnum;
  spin?: boolean;
  size?: 'lg' | 2 | 3 | 4 | 5 | 6;
  rotate?: 0 | 90 | 180 | 270;
  flip?: 'x' | 'y';
}

export default PBIcon;
