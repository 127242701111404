import styled from '@emotion/styled';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';

const LogoWrapper = styled.h1`
  margin: auto;
  line-height: normal;

  @media ${({ theme }) => theme.largeUp} {
    margin: 0;
  }
`;

const LogoLabel = styled.span`
  display: block;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: ${({ theme }) => getCustomStyle('styleModule.headerFontFamily', theme.secondaryFontFamily)};
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? theme.white : theme.black)};

  @media ${({ theme }) => theme.mediumDown} {
    font-size: 20px;
  }
`;

const LogoLink = styled.a`
  font-family: ${({ theme }) => getCustomStyle('styleModule.headerFontFamily', theme.primaryFontFamily)};
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? theme.white : theme.black)};
  cursor: pointer;
  font-size: inherit;
  text-decoration: none;
  font-weight: inherit;
  text-transform: inherit;
  display: flex;
  align-items: center;
  white-space: pre;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

const LogoPicture = styled.picture<{ logoAligned: string; isNavExists: boolean }>`
  display: flex;
  align-items: center;
  width: 120px;

  @media ${({ theme }) => theme.largeUp} {
    width: ${({ logoAligned, isNavExists }) => (logoAligned === 'center' && isNavExists ? '120px' : '150px')};
  }

  > img {
    width: 100%;
    @media ${({ theme }) => theme.largeUp} {
      display: ${({ logoAligned }) => (logoAligned === 'center' ? 'block' : 'inline-block')};
    }
  }
`;

export { LogoWrapper, LogoLabel, LogoLink, LogoPicture };
