import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { FindParcelsPayloadBody } from 'types/customer-service/find-parcels-api-response-type';
import { SearchBy } from 'types/customer-service/search-by-type';
import { CustomOrderNumberField } from 'types/shared/config/returnsModule/returns-module-type';
import { AuthenticatedReturns } from './AuthenticatedReturns/AuthenticatedReturns';
import { SearchParcelsForm } from './SearchParcelsForm/SearchParcelsForm';

export const SearchParcels: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const {
    searchBy,
    setSearchBy,
    searchByOrderNumberEnabled,
    fetchParcels,
    clearParcelsResults,
    isLoading,
    customOrderNumberField,
    areReturnsEnabled,
    isIntegrated
  } = props;

  return (
    <SectionLayout
      backgroundAsColor={true}
      bgColor="#fff"
      paddingDefault="30px 15px"
      paddingMd="30px 30px"
      paddingL="40px 30px"
      paddingXl="40px 95px"
      paddingXXl="40px 115px"
      justifyContent="space-between"
      data-test-id="search-parcels">
      <SearchParcelsForm
        cleanParcelsResults={clearParcelsResults}
        fetchParcels={fetchParcels}
        searchByOrderNumberEnabled={searchByOrderNumberEnabled}
        searchBy={searchBy}
        setSearchBy={setSearchBy}
        isLoading={isLoading}
      />
      <AuthenticatedReturns
        isIntegrated={isIntegrated}
        customOrderNumberField={customOrderNumberField}
        searchByOrderNumberEnabled={searchByOrderNumberEnabled}
        areReturnsEnabled={areReturnsEnabled}
      />
    </SectionLayout>
  );
};

export interface OwnProps {
  searchBy: SearchBy;
  setSearchBy: Dispatch<SetStateAction<SearchBy>>;
  searchByOrderNumberEnabled: boolean;
  customOrderNumberField: CustomOrderNumberField | null;
  areReturnsEnabled: boolean;
  fetchParcels: (searchBy: SearchBy, trackingNumbersOrOrderNumber: FindParcelsPayloadBody) => Promise<void>;
  clearParcelsResults: () => void;
  isLoading: boolean;
  isIntegrated: boolean;
}
