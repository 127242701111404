import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import gift_image from './assets/img/occasion-img.png';
import { ReactComponent as BirthdayIcon } from './assets/img/icon-birthday.svg';
import { ReactComponent as GraduationIcon } from './assets/img/icon-graduation.svg';
import { ReactComponent as AnniversaryIcon } from './assets/img/icon-anniversary.svg';
import { ReactComponent as ThanksIcon } from './assets/img/icon-thanks.svg';
import { ReactComponent as BabyIcon } from './assets/img/icon-baby.svg';
import { ReactComponent as OtherIcon } from './assets/img/icon-other.svg';
import Button from 'react-bootstrap/Button';
import Stack from '@mui/material/Stack';
import * as Constants from './constants';

export default function Occasion() {
  const navigate = useNavigate();
  const [isOccasionSelected, setSelectedOccasion] = useState(true);
  const [occasion, setOccasion] = useState('');
  const occasionList = [
    { id: 1, text: 'Birthday', icon: <BirthdayIcon /> },
    { id: 2, text: 'Graduation', icon: <GraduationIcon /> },
    { id: 3, text: 'Anniversary', icon: <AnniversaryIcon /> },
    { id: 4, text: 'Thanks', icon: <ThanksIcon /> },
    { id: 5, text: 'Baby', icon: <BabyIcon /> },
    { id: 6, text: 'Other', icon: <OtherIcon /> }
  ];

  const onOccasionSelection = (e, param) => {
    e.preventDefault();
    setOccasion(param);
    setSelectedOccasion(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (occasion === '') {
      setSelectedOccasion(false);
    } else {
      setSelectedOccasion(true);
      localStorage.setItem('occasion', occasion);
      navigate('/send/gift/media');
    }
  };

  return (
    <Container className="occasion-font-family">
      <Row className="pb-4 height-100 body-padding body-margin">
        <Stack gap={1} className="my-auto">
          <div className="occasion-header occasion-header-font-size mb-0">What are we celebrating?</div>
        </Stack>
      </Row>
      <Row className="body-padding body-margin">
        <Col className="gift-image-div">
          <img src={gift_image} alt="Logo" className="w-100 occasion-image" height={455} />
        </Col>
        <Col className="occasion-list text-center">
          <Row>
            {occasionList.map((element) => (
              <Col className="occasion-icons pb-3" key={element.id} xs={4} md={4} sm={4} lg={4}>
                <Stack spacing={2}>
                  <div className="px-1">
                    <a
                      className="occasion-fb-icon"
                      href=""
                      onClick={(e) => {
                        onOccasionSelection(e, element.text);
                      }}>
                      {element.icon}
                    </a>
                  </div>
                  <div className="px-1 mt-2 occasion-title">{element.text}</div>
                </Stack>
              </Col>
            ))}
          </Row>
          {!isOccasionSelected && (
            <Row>
              <Col>
                <p style={{ color: '#F75555' }}>Select the occasion to start.</p>
              </Col>
            </Row>
          )}
          <Row className="pb-3">
            <Col>
              <Button variant="dark" className="w-100 button-p" onClick={onSubmit}>
                Personalize Your Message
              </Button>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col>
              <p className="occasion-para">
                Make your gift extra special with a new personalized gift tracking experience they have never seen before. Its free easy and fun.
                Here&apos;s how it works:
              </p>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col>
              <video className="w-100" controls>
                <source src={Constants.GIFT_VIDEO_URL} type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
