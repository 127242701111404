import React from 'react';
import { Container, Heading, Content, TileWrapper } from './ParcelsListSkeleton.styled';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { SearchBy } from 'types/customer-service/search-by-type';

export const ParcelsListSkeleton: React.FC<OwnProps> = (props) => {
  const { searchBy } = props;
  const theme = useTheme();
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));

  return (
    <SectionLayout
      backgroundAsColor={false}
      marginDefault="50px 0 0"
      paddingDefault="0 15px"
      paddingMd="0 30px"
      paddingL="0 30px"
      paddingXl="0 95px"
      paddingXXl="0 115px">
      <Container>
        <Heading>
          <Skeleton variant="rectangular" sx={{ borderRadius: '4px' }} width={215} height={22} />
          {largeUp && searchBy === SearchBy.TRACKING_NUMBER && (
            <Skeleton variant="rectangular" sx={{ borderRadius: '4px' }} width={100} height={35} />
          )}
        </Heading>
        <Content>
          <TileWrapper>
            <Skeleton variant="rectangular" sx={{ borderRadius: '4px' }} width="100%" height={241} />
          </TileWrapper>
          <TileWrapper>
            <Skeleton variant="rectangular" sx={{ borderRadius: '4px' }} width="100%" height={241} />
          </TileWrapper>
        </Content>
      </Container>
    </SectionLayout>
  );
};

interface OwnProps {
  searchBy: SearchBy;
}
