import React from 'react';
import { useIntl } from 'react-intl';
import { MethodEnum } from 'types/return/overview/methods/method-types';
import {
  PackageTrackingAdditionalParagraph,
  PackageTrackingAdditionalParagraphLink,
  PackageTrackingReminder,
  PackageTrackingReminderIcon,
  PackageTrackingReminderIconContainer,
  PackageTrackingReminderText,
  PackageTrackingWrapper
} from './ReturnInfoPackageTracking.styled';

export const ReturnInfoPackageTracking: React.FC<OwnProps> = (props) => {
  const { methodType, trackingPackageUrl, qrCodeId } = props;

  const intl = useIntl();

  return (
    <PackageTrackingWrapper>
      <PackageTrackingReminder>
        <PackageTrackingReminderIconContainer>
          <PackageTrackingReminderIcon src="/assets/img/reminder-finger.svg" alt="reminder finger" />
        </PackageTrackingReminderIconContainer>
        <PackageTrackingReminderText>{intl.formatMessage({ id: 'RETURN.returnInfo.common.reminder.text' })}</PackageTrackingReminderText>
      </PackageTrackingReminder>
      <PackageTrackingAdditionalParagraph data-test-id="return-info-package-tracking-additional-paragraph-1">
        {intl.formatMessage(
          { id: 'RETURN.returnInfo.common.additionalParagraph.1' },
          {
            link: (
              <PackageTrackingAdditionalParagraphLink
                key={trackingPackageUrl}
                href={trackingPackageUrl}
                target="_blank"
                data-gtm-id="qr-track-package">
                {intl.formatMessage({ id: 'RETURN.returnInfo.common.additionalParagraph.link.1' })}
              </PackageTrackingAdditionalParagraphLink>
            )
          }
        )}
      </PackageTrackingAdditionalParagraph>
      {methodType === MethodEnum.QRCode && (
        <PackageTrackingAdditionalParagraph data-test-id="return-info-package-tracking-additional-paragraph-2">
          {intl.formatMessage(
            { id: 'RETURN.returnInfo.common.additionalParagraph.2' },
            {
              link: (
                <PackageTrackingAdditionalParagraphLink
                  key="https://tools.usps.com/label-broker.htm"
                  href="https://tools.usps.com/label-broker.htm"
                  target="_blank"
                  data-gtm-id="usps-label-broker">
                  {intl.formatMessage({ id: 'RETURN.returnInfo.common.additionalParagraph.link.2' })}
                </PackageTrackingAdditionalParagraphLink>
              ),
              qrCodeId: <b>{qrCodeId ? qrCodeId : ''}</b>
            }
          )}
        </PackageTrackingAdditionalParagraph>
      )}
    </PackageTrackingWrapper>
  );
};

export interface OwnProps {
  methodType: MethodEnum;
  trackingPackageUrl: string;
  qrCodeId: string | null;
}
