import React, { useContext, useMemo, useState } from 'react';
import { ProductsSelectionContext } from 'context/returns/ProductsSelectionContext';
import { SelectedProduct } from 'models/returns/selected-product/SelectedProduct';
import { SelectedProductsReviewListContainer, ItemTitle, ArrowBox, ItemTitleBox } from './SelectedProductsReviewList.styled';
import { useIntl } from 'react-intl';
import { transformRootItems } from 'utils/returns/overview/review/transform-root-items/transform-root-items';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isAnyOptionTypeExchange } from 'utils/returns/overview/review/is-any-option-type-exchange/is-any-option-type-exchange';
import { imageFilterIfAtLeastOneRootProductHasImage } from 'utils/returns/overview/review/image-filter-if-at-least-one-root-product-has-image/image-filter-if-at-least-one-root-product-has-image';
import { imageFilterIfAtLeastOneExchangeProductHasImage } from 'utils/returns/overview/review/image-filter-if-at-least-one-exchange-product-has-image/image-filter-if-at-least-one-exchange-product-has-image';
import { SelectedProductReviewItem } from './SelectedProductReviewItem/SelectedProductReviewItem';

export const SelectedProductsReviewList = () => {
  const { selectedProducts } = useContext(ProductsSelectionContext);

  const [isAtLeastOneProductAtAllHasImage] = useState<boolean>(
    imageFilterIfAtLeastOneRootProductHasImage(selectedProducts) || imageFilterIfAtLeastOneExchangeProductHasImage(selectedProducts)
  );

  const intl = useIntl();
  const theme = useTheme();
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));
  const anyExchangeProductExists = useMemo(() => isAnyOptionTypeExchange(selectedProducts), [selectedProducts]);

  if (transformRootItems(selectedProducts).length === 0) return null;
  return (
    <SelectedProductsReviewListContainer data-test-id="selected-products-review-list">
      {!largeUp ? null : anyExchangeProductExists ? (
        <ItemTitleBox>
          <ItemTitle data-test-id="selected-products-review-list__return-title">
            {intl.formatMessage({ id: 'RETURN.overview.review.selectedProductsReviewList.return.title' })}
          </ItemTitle>
          <ArrowBox isTreatAsSpacer={true} />
          <ItemTitle data-test-id="selected-products-review-list__exchange-title">
            {intl.formatMessage({ id: 'RETURN.overview.review.selectedProductsReviewList.exchange.title' })}
          </ItemTitle>
        </ItemTitleBox>
      ) : (
        <ItemTitleBox>
          <ItemTitle data-test-id="selected-products-review-list__return-title">
            {intl.formatMessage({ id: 'RETURN.overview.review.selectedProductsReviewList.return.title' })}
          </ItemTitle>
        </ItemTitleBox>
      )}
      {transformRootItems(selectedProducts).map((selectedProduct: SelectedProduct, index: number) => (
        <SelectedProductReviewItem
          key={selectedProduct.id}
          selectedProduct={selectedProduct}
          first={index === 0}
          isAtLeastOneProductAtAllHasImage={isAtLeastOneProductAtAllHasImage}
          anyExchangeProductExists={anyExchangeProductExists}
        />
      ))}
    </SelectedProductsReviewListContainer>
  );
};
