import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PickupStatusEnum } from 'types/cancel-pickup/pickup-status-enum';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const IconBox = styled.div<{ iconSize?: string; type: PickupStatusEnum }>`
  align-self: stretch;
  align-items: center;
  display: flex;

  i {
    text-align: center;
    width: 40px;

    ${({ type }) =>
      type === PickupStatusEnum.Failed &&
      css`
        color: #dd1010;
      `}

    ${({ type }) =>
      type === PickupStatusEnum.Success &&
      css`
        color: #009966;
      `}
    
    font-size: ${({ iconSize }) => (iconSize ? iconSize : '22px')};

    @media ${({ theme }) => theme.largeUp} {
      font-size: ${({ iconSize }) => (iconSize ? iconSize : '30px')};
    }
  }
`;

export const MessageContent = styled.p`
  flex-grow: 1;
  margin: 0 0 0 15px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.bodyFontFamily};
`;
