import styled from '@emotion/styled';

export const ConsumerConnectSMSTermsContainer = styled.div`
  padding: 44px 0;
`;

export const TextWrapper = styled.div`
  font-family: 'Precision Sans W', 'Helvetica Neue', Arial, sans-serif;
  margin: 0 0 20px 0;
  font-size: 14px;

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 16px;
  }

  &:last-of-type {
    margin: 0;
  }
`;

export const PolicyTitle = styled.h2`
  font-size: inherit;
  font-weight: 700;
  margin: 0 0 10px 0;
  font-family: inherit;
`;

export const PolicyParagraph = styled.p`
  font-size: inherit;
  margin: 0 0 10px 0;
  font-family: inherit;

  &:last-of-type {
    margin: 0;
  }
`;
