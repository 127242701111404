import styled from '@emotion/styled';
import { StyledButton, StyledButtonAsLink } from 'components/Shared/Button/Button.styled';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';

export const TrackingButton = styled(StyledButton)`
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? '' : 'rgba(0, 0, 0, .54)')};
  font-family: ${({ theme }) => getCustomStyle('styleModule.headerFontFamily', theme.primaryFontFamily)};
`;

export const TrackingButtonAsLink = styled(StyledButtonAsLink)`
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? '' : 'rgba(0, 0, 0, .54)')};
  font-family: ${({ theme }) => getCustomStyle('styleModule.headerFontFamily', theme.primaryFontFamily)};
`;
