import { Parcel, ParcelStatusEnum } from 'types/customer-service/find-parcels-api-response-type';
import { SortedParcelsByErrorStatus } from 'types/customer-service/sorted-parcels-by-error-status';

export const sortParcelsByErrorStatus = (parcels: Parcel[]): SortedParcelsByErrorStatus => {
  const timeoutParcels = parcels.filter((parcel: Parcel) => parcel.parcelDetails.parcelStatus === ParcelStatusEnum.TIMEOUT);
  const notFoundParcels = parcels.filter((parcel: Parcel) => parcel.parcelDetails.parcelStatus === ParcelStatusEnum.NOT_FOUND);

  return {
    timeoutParcels,
    notFoundParcels
  };
};
