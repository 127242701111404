import { FooterModuleType } from 'types/shared/config/footerModule/footer-module-type';

export const defaultFooterModule: FooterModuleType = {
  contactInformation: {
    enabled: false,
    phoneNumber: null,
    websiteUrl: null
  },
  socialLinks: null,
  brandName: null
};
