import React, { PropsWithChildren, useEffect, useState } from 'react';
import { LabelInfoContainer, LabelInfoSubtitle, LabelInfoTitle, LabelInfoWrapper } from './LabelInfo.styled';
import { useIntl } from 'react-intl';
import { LabelEmailInput } from 'components/Returns/ReturnInfo/Label/LabelEmailInput/LabelEmailInput';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LabelPickupScheduler } from 'components/Returns/ReturnInfo/Label/LabelInfo/LabelPickupScheduler/LabelPickupScheduler';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CarriersEnum } from 'types/return/overview/methods/method-types';
import axios from 'axios';

export const LabelInfo: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sendEmailUrl, labelPrintUrl, labelId, inputOrderId, carrierName } = props;
  //const atTZipCodeExapps8637 = true;
  const {
    returnsUps2DayCoco264,
    attCustomerCareModificationsCoco519,
    makeEmailCopyOfLabelButtonTextDynamicCoco529,
    atTZipCodeExapps8637
  } = useFlags();
  const intl = useIntl();
  const theme = useTheme();
  const mediumUp = useMediaQuery(theme.breakpoints.up('medium'));
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));
  const [isUspsFromZipCode, setIsUspsFromZipCode] = useState<boolean>(true);
  const [eventZipCode, setEventZipCode] = useState<string>('');

  useEffect(() => {
    const EventZipCode = localStorage.getItem('eventZipCode');
    const fetchZipCodeData = async () => {
      try {
        const response = await axios.get(`/api/zipCodes/findByZipCode?zipcode=${EventZipCode}`);
        setIsUspsFromZipCode(response.data.uspsfromZipCode);
      } catch (error) {
        //console.error('Error fetching data:', error);
      }
    };

    if (atTZipCodeExapps8637) {
      fetchZipCodeData();
    }
  });

  return (
    <LabelInfoContainer data-test-id="label-info-container">
      {!attCustomerCareModificationsCoco519 && (
        <LabelInfoWrapper>
          <LabelInfoTitle data-test-id="label-info-info-title">
            {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.title' })}
          </LabelInfoTitle>
          <LabelInfoSubtitle data-test-id="label-info-info-subtitle">
            {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.subtitle' })}
          </LabelInfoSubtitle>
          <CustomButton
            size={largeUp ? 'large' : mediumUp ? 'medium' : 'large'}
            width="100%"
            onClick={() => window.open(labelPrintUrl, '_blank')}
            isUppercase
            data-test-id="label-info-print-link"
            data-gtm-id="print-label"
            variant="contained">
            {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.button.printLabel' })}
          </CustomButton>
        </LabelInfoWrapper>
      )}
      {makeEmailCopyOfLabelButtonTextDynamicCoco529 && makeEmailCopyOfLabelButtonTextDynamicCoco529.visible && (
        <LabelEmailInput sendEmailUrl={sendEmailUrl} />
      )}

      {atTZipCodeExapps8637
        ? !returnsUps2DayCoco264 && isUspsFromZipCode && <LabelPickupScheduler labelId={labelId} inputOrderId={inputOrderId} />
        : !returnsUps2DayCoco264 &&
          carrierName?.toLowerCase() === CarriersEnum.USPS && <LabelPickupScheduler labelId={labelId} inputOrderId={inputOrderId} />}
    </LabelInfoContainer>
  );
};

export interface OwnProps {
  sendEmailUrl: string;
  labelPrintUrl: string;
  labelId: string;
  inputOrderId: string;
  carrierName?: string | null;
}
