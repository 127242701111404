import React, { PropsWithChildren } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ShippingAddressSummaryContainer, ShippingAddressSummaryElement } from './ShippingAddressSummary.styled';

export const ShippingAddressSummary: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { fullName, addressOne, city, selectedState, zipCode, addressTwo, number } = props;

  const { returnsRemovePhoneNumberCoco159 } = useFlags();

  return (
    <ShippingAddressSummaryContainer data-test-id="shipping-address-summary">
      <ShippingAddressSummaryElement data-test-id="shipping-address-summary-full-name">{fullName}</ShippingAddressSummaryElement>
      <ShippingAddressSummaryElement data-test-id="shipping-address-summary-address-one">{addressOne}</ShippingAddressSummaryElement>
      {addressTwo && <ShippingAddressSummaryElement data-test-id="shipping-address-summary-address-two">{addressTwo}</ShippingAddressSummaryElement>}
      <ShippingAddressSummaryElement data-test-id="shipping-address-summary-info-1">
        {city}, {selectedState} {zipCode}
      </ShippingAddressSummaryElement>
      {!returnsRemovePhoneNumberCoco159 && !!number && (
        <ShippingAddressSummaryElement data-test-id="shipping-address-summary-info-2">{number}</ShippingAddressSummaryElement>
      )}
    </ShippingAddressSummaryContainer>
  );
};

export interface OwnProps {
  fullName: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  selectedState: string;
  zipCode: string;
  number: string;
}
