import { CustomTheme } from 'types/modules';

// colors
const black = '#000',
  white = '#fff',
  gray = '#7e7e7e',
  viking = '#6bb6d9',
  mercury = '#e3e3e3',
  oceanGreen = '#50b67b';

// fonts
const primaryFont = `"Playfair Display", sans-serif`;
const secondaryFont = `"Roboto", sans-serif`;

export const theme: Partial<CustomTheme> = {
  black: black,
  gray: gray,
  primaryColor: viking,
  secondaryColor: oceanGreen,
  tertiaryColor: mercury,
  primaryFontFamily: primaryFont,
  secondaryFontFamily: secondaryFont,
  bodyBackgroundColor: white,
  buttonBackground: viking,
  buttonColor: white
};
