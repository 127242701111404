import React from 'react';
import {
  ExploreTheBrandWrapper,
  SecondItemContainer,
  FirstItemContainer,
  ItemImage,
  ItemContentBox,
  ItemTitle,
  ItemButtonContainer,
  ItemSmallImage,
  ImagesContainer,
  ItemMediumImage,
  ItemQuoteContainer,
  ItemQuote,
  ItemQuoteAuthor,
  ExploreTheBrandContainer
} from './ExploreTheBrand.styled';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { SectionDescription } from 'components/Tracking/Common/SectionDescription/SectionDescription';
import { TrackingButton } from 'components/Tracking/Common/Buttons/TrackingButtons.styled';
import { ExploreTheBrandModuleType } from 'types/shared/config/exploreTheBrandModule/explore-the-brand-module-type';

export const ExploreTheBrand: React.FC<OwnProps> = (props) => {
  const { exploreTheBrandModule } = props;
  const { title, subTitle, featuredImageUrl, url, imageUrls, heroImage, quote, authorQuote, buttonCopy, callToActionCopy } = exploreTheBrandModule;

  const handleClick = () => {
    return window.open(url, '_blank');
  };

  return (
    <SectionLayout marginDefault="0 0 80px 0" backgroundAsColor={false}>
      <ExploreTheBrandContainer data-test-id="explore-products-container">
        <SectionDescription title={title} subtitle={subTitle ? subTitle : ''} />
        <ExploreTheBrandWrapper data-test-id="explore-products-items-wrapper">
          <FirstItemContainer data-test-id="first-item-container" onClick={handleClick}>
            <ItemImage data-test-id="explore-products-image-1" backgroundImage={featuredImageUrl} data-gtm-id="promotional-image-1" />
            <ItemContentBox data-test-id="explore-products-description" data-gtm-id="promotional-module-description">
              <ItemTitle data-test-id="explore-item-1-title">{callToActionCopy}</ItemTitle>
              <ItemButtonContainer>
                <TrackingButton paddingDefault="12px 40px" width="250px" data-gtm-id="promotional-module-button">
                  {buttonCopy}
                </TrackingButton>
              </ItemButtonContainer>
            </ItemContentBox>
          </FirstItemContainer>

          <SecondItemContainer data-test-id="second-item-container">
            <ImagesContainer>
              {imageUrls[0] && (
                <ItemSmallImage
                  data-test-id="explore-products-image-2"
                  backgroundImage={imageUrls[0]}
                  data-gtm-id="promotional-image-2"
                  onClick={handleClick}
                />
              )}
              {imageUrls[1] && (
                <ItemSmallImage
                  data-test-id="explore-products-image-3"
                  backgroundImage={imageUrls[1]}
                  data-gtm-id="promotional-image-3"
                  onClick={handleClick}
                />
              )}
            </ImagesContainer>
            {quote ? (
              <ItemQuoteContainer data-test-id="explore-products-quote-container" data-gtm-id="promotional-quote-block" onClick={handleClick}>
                <ItemQuote data-test-id="explore-products-quote">{quote}</ItemQuote>
                <ItemQuoteAuthor data-test-id="explore-products-quote-author">{authorQuote}</ItemQuoteAuthor>
              </ItemQuoteContainer>
            ) : (
              <ItemMediumImage
                data-test-id="explore-products-image-4"
                backgroundImage={heroImage ? heroImage : ''}
                data-gtm-id="promotional-image-4"
                onClick={handleClick}
              />
            )}
          </SecondItemContainer>
        </ExploreTheBrandWrapper>
      </ExploreTheBrandContainer>
    </SectionLayout>
  );
};

export interface OwnProps {
  exploreTheBrandModule: ExploreTheBrandModuleType;
}
