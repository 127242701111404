import React from 'react';
import { PromotionalTextWrapper, PromotionalItemTitle, PromotionalItemDescription, PromotionalButtonWrapper } from './PromotionalInfoBox.styled';
import { TrackingButton } from 'components/Tracking/Common/Buttons/TrackingButtons.styled';

export const PromotionalInfoBox: React.FC<OwnProps> = (props) => {
  const { title, subtitle, buttonLabel } = props;

  return (
    <PromotionalTextWrapper>
      <PromotionalItemTitle data-test-id="promotional-item-title">{title}</PromotionalItemTitle>
      <PromotionalItemDescription data-test-id="promotional-item-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
      <PromotionalButtonWrapper>
        <TrackingButton width="200px" paddingDefault="12px 30px" isBoxShadow data-gtm-id="promotional-button" data-test-id="promotional-link-button">
          {buttonLabel}
        </TrackingButton>
      </PromotionalButtonWrapper>
    </PromotionalTextWrapper>
  );
};

export interface OwnProps {
  title: string;
  subtitle: string;
  buttonLabel: string;
}
