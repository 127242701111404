import dayjs from 'dayjs';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { PreparedParcelsToCSVExport } from 'types/customer-service/prepared-parcels-to-csv-export';

export const prepareDataToCSVExport = (parcels: Parcel[]): PreparedParcelsToCSVExport[] =>
  parcels.map((parcel: Parcel) => ({
    ['Ship Date']: parcel.parcelDetails.deliveryDetails ? dayjs(parcel.parcelDetails.deliveryDetails.shipDate).format('MM/DD/YYYY') : '-',
    ['Tracking Number']: parcel.parcelDetails.trackingNumber,
    ['Reference Number']: parcel.parcelDetails.referenceNumber ? parcel.parcelDetails.referenceNumber : '-',
    ['Status']: parcel.parcelDetails.parcelStatus,
    ['Estimated Delivery']:
      parcel.parcelDetails.deliveryDetails && parcel.parcelDetails.deliveryDetails.estimatedDeliveryDate
        ? dayjs(parcel.parcelDetails.deliveryDetails.estimatedDeliveryDate).format('MM/DD/YYYY')
        : '-'
  }));
