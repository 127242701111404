import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { ParcelTypeEnum } from 'types/customer-service/find-parcels-api-response-type';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const Container = styled.div`
  margin: 0 0 40px 0;

  &:last-of-type {
    margin: 0;
  }
`;

export const Subheading = styled.h4`
  font-family: ${getCustomStyle('styleModule.bodyFontFamily')};
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 20px;

  @media ${({ theme }) => theme.largeUp} {
    font-size: 18px;
  }
`;

export const CustomPBIcon = styled(PBIcon)<{ parcelsType: ParcelTypeEnum }>`
  padding: 0 10px 0 0;
  transform: translateY(2px);

  ${({ parcelsType }) =>
    parcelsType === ParcelTypeEnum.RETURN &&
    css`
      transform: translateY(3px) scale(-1, 1);
      padding: 0 0 0 10px;
    `};
`;
