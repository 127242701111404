import React from 'react';
import { Container, ContainerActions, ContainerContent, Text } from './DropOffLocationItem.styled';
import { Skeleton } from '@mui/material';

export const DropOffLocationItemSkeleton: React.FC = () => {
  return (
    <Container data-test-id="drop-off-location-skeleton">
      <ContainerContent>
        <Text isBold margin="0 0 3px">
          <Skeleton variant="text" width={70} sx={{ fontSize: '12px' }} />
        </Text>
        <Text margin="0 0 3px">
          <Skeleton variant="text" width={100} sx={{ fontSize: '12px' }} />
        </Text>
        <Text>
          <Skeleton variant="text" width={90} sx={{ fontSize: '12px' }} />
        </Text>
      </ContainerContent>
      <ContainerActions>
        <Skeleton variant="text" width={140} sx={{ fontSize: '12px', padding: '4px 5px' }} />
        <Skeleton variant="text" width={60} sx={{ fontSize: '12px', padding: '4px 5px' }} />
      </ContainerActions>
    </Container>
  );
};
