import React from 'react';
import { CustomTableCell } from 'components/Shared/styled-components/CustomTableCell/CustomTableCell.styled';
import { TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { MobileCellText } from './TrackingEventsTableHead.styled';

export const TrackingEventsTableHead = () => {
  const intl = useIntl();

  const theme = useTheme();
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));

  return (
    <TableHead>
      {largeUp ? (
        <TableRow>
          <CustomTableCell
            aria-label="tracking-events-table-head-date"
            widthDefault="15%"
            fontSizeDefault="11px"
            fontSizeXL="12px"
            paddingDefault="6px 10px"
            isBorderBottomActive>
            {intl.formatMessage({ id: 'CS.parcels.searchByTrackingNumber.trackingEventsTable.head.date' })}
          </CustomTableCell>
          <CustomTableCell
            aria-label="tracking-events-table-head-time"
            widthDefault="15%"
            fontSizeDefault="11px"
            fontSizeXL="12px"
            paddingDefault="6px 10px"
            isBorderBottomActive>
            {intl.formatMessage({ id: 'CS.parcels.searchByTrackingNumber.trackingEventsTable.head.time' })}
          </CustomTableCell>
          <CustomTableCell
            aria-label="tracking-events-table-head-activity"
            widthDefault="45%"
            fontSizeDefault="11px"
            fontSizeXL="12px"
            paddingDefault="6px 10px"
            isBorderBottomActive>
            {intl.formatMessage({ id: 'CS.parcels.searchByTrackingNumber.trackingEventsTable.head.activity' })}
          </CustomTableCell>
          <CustomTableCell
            aria-label="tracking-events-table-head-processing-location"
            widthDefault="25%"
            fontSizeDefault="11px"
            fontSizeXL="12px"
            paddingDefault="6px 10px"
            align="right"
            isBorderBottomActive>
            {intl.formatMessage({ id: 'CS.parcels.searchByTrackingNumber.trackingEventsTable.head.processingLocation' })}
          </CustomTableCell>
        </TableRow>
      ) : (
        <TableRow>
          <CustomTableCell
            aria-label="tracking-events-table-head-date-and-time"
            widthDefault="40%"
            fontSizeDefault="12px"
            paddingDefault="6px 10px"
            align="left"
            isBorderBottomActive>
            <MobileCellText isBolded={true}>
              {intl.formatMessage({ id: 'CS.parcels.searchByTrackingNumber.trackingEventsTable.head.date' })}
            </MobileCellText>
            <MobileCellText isBolded={false}>
              {intl.formatMessage({ id: 'CS.parcels.searchByTrackingNumber.trackingEventsTable.head.time' })}
            </MobileCellText>
          </CustomTableCell>
          <CustomTableCell
            aria-label="tracking-events-table-head-location-and-processing-location"
            widthDefault="60%"
            fontSizeDefault="12px"
            paddingDefault="6px 10px"
            align="right"
            isBorderBottomActive>
            <MobileCellText isBolded={true}>
              {intl.formatMessage({ id: 'CS.parcels.searchByTrackingNumber.trackingEventsTable.head.activity' })}
            </MobileCellText>
            <MobileCellText isBolded={false}>
              {intl.formatMessage({ id: 'CS.parcels.searchByTrackingNumber.trackingEventsTable.head.processingLocation' })}
            </MobileCellText>
          </CustomTableCell>
        </TableRow>
      )}
    </TableHead>
  );
};
