import { PageTypeEnum } from 'types/app/page-type-enum';
import { isPreviewBasedOnRoute } from 'utils/app/is-preview-based-on-route/is-preview-based-on-route';

export const resolveVanityName = (urlParamsVanityName: string | undefined, isPreview: boolean, pageType?: PageTypeEnum): string => {
  const isLocalhost: boolean = !window.location.hostname.includes('.');

  if (isPreview || isLocalhost || pageType === PageTypeEnum.CancelPickupPage) return urlParamsVanityName ? urlParamsVanityName : '';
  return window.location.hostname.split('.')[0];
};

export const resolveVanityName2 = (urlParamsVanityName: string | undefined, pathname: string): string => {
  const isLocalhost: boolean = !window.location.hostname.includes('.');
  const isPreviewBasedOnRouteUrl = isPreviewBasedOnRoute(pathname);
  const cancelPickup = ['return/pickup/cancel'];
  const isCancelPickupPage = cancelPickup.some((element: string) => pathname.includes(element));

  if (isLocalhost || isPreviewBasedOnRouteUrl || isCancelPickupPage) return urlParamsVanityName ? urlParamsVanityName : '';
  return window.location.hostname.split('.')[0];
};
