import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { ShippingInfoContainer, ShippingInfoList } from './ShippingInfo.styled';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { SectionDescription } from 'components/Tracking/Common/SectionDescription/SectionDescription';
import { InfoItem, InfoTypeEnum } from './ShippingInfoItem/InfoItem';
import useFetch, { DefaultErrorResponse } from 'hooks/useFetch/useFetch';
import { OrderData } from 'types/order-data';
import { TemplateEnum } from 'types/shared/template-type';
import { OrderDetailsModuleType } from 'types/shared/config/orderDetailsModule/order-details-module-type';
import { ShippingInfoModuleType } from 'types/tracking/trackingConfig/shippingInfoModule/shipping-info-module-type';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { buildGetOrderNumberApi } from 'utils/tracking/shipping-info/build-get-order-number-api/build-get-order-number-api';

export const ShippingInfo: React.FC<OwnProps> = (props) => {
  const { shippingInfoModule, orderDetailsModule, urlKey, trackingNumber } = props;
  const { carrierLogo, carrierUrl, shippingType, carrierTrackingNumber, carrierCode } = shippingInfoModule;

  const { vanityName, designId, template } = useContext(ClientConfigContext);

  const intl = useIntl();

  const ORDER_NUMBER_API = buildGetOrderNumberApi(vanityName, trackingNumber, urlKey, designId);

  const [orderData] = useFetch<OrderData, DefaultErrorResponse>(ORDER_NUMBER_API);

  const isCBDS = carrierCode === 'PBI' && shippingType === 'Pitney Bowes Expedited Parcel Service';

  if (!(carrierTrackingNumber || shippingType || (orderData && orderData.orderNumber))) return null;
  return (
    <SectionLayout
      backgroundAsColor={false}
      paddingXl={template === TemplateEnum.SPARTAN ? '0' : ''}
      paddingXXl={template === TemplateEnum.SPARTAN ? '0' : ''}
      marginDefault="0 0 50px 0">
      <ShippingInfoContainer data-test-id="shipping-info-container">
        {template === TemplateEnum.VIVID && <SectionDescription title={intl.formatMessage({ id: 'TRACKING.shippingInfoModule.title' })} />}
        <ShippingInfoList data-test-id="shipping-info-list">
          {orderDetailsModule.enabled && orderData && orderData.orderNumber && (
            <InfoItem
              ariaLabel="order-number-info-item"
              infoName={intl.formatMessage({ id: 'TRACKING.shippingInfoModule.orderNumber.lbl' })}
              info={orderData.orderNumber}
              infoUrl={orderDetailsModule.orderNumberUrl}
              infoType={InfoTypeEnum.ORDER_NUMBER}
              infoIconAlt={carrierCode}
            />
          )}
          {carrierTrackingNumber && (
            <InfoItem
              ariaLabel="tracking-number-info-item"
              infoName={intl.formatMessage({ id: 'TRACKING.shippingInfoModule.trackingNumber' })}
              info={carrierTrackingNumber}
              infoIconUrl={!isCBDS ? carrierLogo : ''}
              infoUrl={carrierUrl}
              infoType={InfoTypeEnum.TRACKING_NUMBER}
              infoIconAlt={carrierCode}
            />
          )}
          {shippingType && template === TemplateEnum.VIVID && (
            <InfoItem
              ariaLabel="shipping-type-info-item"
              infoName={intl.formatMessage({ id: 'TRACKING.shippingInfoModule.shippingType' })}
              info={shippingType}
              infoType={InfoTypeEnum.SHIPPING_TYPE}
              infoIconAlt={carrierCode}
            />
          )}
        </ShippingInfoList>
      </ShippingInfoContainer>
    </SectionLayout>
  );
};

export interface OwnProps {
  shippingInfoModule: ShippingInfoModuleType;
  orderDetailsModule: OrderDetailsModuleType;
  urlKey: string;
  trackingNumber: string;
}
