import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { PBOutlineIconsEnum } from 'constants/PBIconsEnums';
import React, { SetStateAction } from 'react';
import { ParcelsViewEnum } from 'types/customer-service/parcels-view-enum';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { Container, Switcher } from './ViewSwitcher.styled';

export const ViewSwitcher: React.FC<OwnProps> = (props) => {
  const { activeView, setActiveView } = props;
  return (
    <Container data-test-id="view-switcher">
      <Switcher
        data-test-id="cs-search-by-tracking-number-view-switcher-table"
        aria-label="cs-search-by-tracking-number-view-switcher-table"
        data-gtm-id="single-switcher-table"
        active={activeView === ParcelsViewEnum.Table}
        onClick={() => setActiveView(ParcelsViewEnum.Table)}
        disabled={activeView === ParcelsViewEnum.Table}>
        <PBIcon
          name={PBOutlineIconsEnum.Table}
          type={PBIconTypeEnum.Outline}
          size="lg"
          aria-label="cs-search-by-tracking-number-view-switcher-table-icon"
        />
      </Switcher>
      <Switcher
        data-test-id="cs-search-by-tracking-number-view-switcher-tile"
        aria-label="cs-search-by-tracking-number-view-switcher-tile"
        data-gtm-id="single-switcher-tile"
        active={activeView === ParcelsViewEnum.Tile}
        onClick={() => setActiveView(ParcelsViewEnum.Tile)}
        disabled={activeView === ParcelsViewEnum.Tile}>
        <PBIcon
          name={PBOutlineIconsEnum.ListBullet}
          type={PBIconTypeEnum.Outline}
          size="lg"
          aria-label="cs-search-by-tracking-number-view-switcher-tile-icon"
        />
      </Switcher>
    </Container>
  );
};

export interface OwnProps {
  activeView: ParcelsViewEnum;
  setActiveView: React.Dispatch<SetStateAction<ParcelsViewEnum>>;
}
