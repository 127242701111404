import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const SelectedProductsReviewListContainer = styled.ul`
  margin-bottom: 40px;
  list-style: none;
`;

export const ItemTitle = styled.p`
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 0 10px 0;
  color: ${({ theme }) => theme.bodyFontColor};
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;

  @media ${({ theme }) => theme.largeUp} {
    width: calc(50% - 35px);
  }
`;

export const ArrowBox = styled.div<{ isTreatAsSpacer: boolean }>`
  visibility: ${({ isTreatAsSpacer }) => (isTreatAsSpacer ? 'hidden' : 'visible')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bbb;
  margin: 30px 0;

  @media ${({ theme }) => theme.largeUp} {
    justify-content: center;
    width: 70px;
    min-width: 70px;
    margin: 0;
    height: ${({ isTreatAsSpacer }) => (isTreatAsSpacer ? 'auto' : '140px')};
    transform: rotate(-90deg);
  }
`;

export const ItemTitleBox = styled.div`
  width: 100%;
  display: flex;
`;
