import { Environment, resolveEnvironment } from 'utils/common/environment-resolver/environment-resolver';

export const CLIENT_IDS = {
  [Environment.AWS_PRD]: '6327bbeffdf46211c2559d2a',
  [Environment.AWS_PPD]: '6327bbd49cbe98126c2c68ca',
  [Environment.AWS_DEV]: '6327baace1a8bc120c782b9c',
  [Environment.LOCAL]: '6327baace1a8bc120c782b9c'
};

export function resolveClientId(): string {
  const env: Environment = resolveEnvironment();

  const clientId = CLIENT_IDS[env];
  if (clientId) {
    return clientId;
  }
  // @ts-ignore
  return CLIENT_IDS[Environment.LOCAL];
}
