import React, { PropsWithChildren } from 'react';
import { CircleContainer } from './Loading.styled';
import CircularProgress from '@mui/material/CircularProgress';

export const Loading: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { width, height } = props;
  return (
    <CircleContainer width={width} height={height} data-test-id="loading-container">
      <CircularProgress size={40} color="inherit" sx={{ color: 'grey.600' }} thickness={2} />
    </CircleContainer>
  );
};

export interface OwnProps {
  width?: string;
  height?: string;
}
