import React from 'react';
import { BannersContainer } from './WordPressBanners.styled';
import { Banner } from 'components/Shared/Banner/Banner';
import useFetch from 'hooks/useFetch/useFetch';
import { WordPressBannerSuccessType, WordPressBannerErrorType } from 'types/shared/word-press-banner-types';

export const WordPressBanners: React.FC = () => {
  const [banners, , error] = useFetch<WordPressBannerSuccessType[], WordPressBannerErrorType>('/api/banners');

  if (error || !banners || banners.length === 0) return null;

  return (
    <BannersContainer data-test-id="wordpress-banners-container">
      {banners.map(({ id, title, message }: WordPressBannerSuccessType) => (
        <Banner key={id} titleText={title ? title : undefined} paragraphText={message} />
      ))}
    </BannersContainer>
  );
};
