import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

const LabelContainer = styled.span`
  text-transform: uppercase;
  border-radius: 20px;
  padding: 4px 10px;
  color: ${(props) => props.theme.white};
  font-family: ${getCustomStyle('styleModule.headerFontFamily')};
  font-size: 12px;
  position: relative;
  bottom: 40px;
  left: 0;
  background-color: ${getCustomStyle('styleModule.secondaryColor')};

  &.primaryBackgroundLight {
    color: ${(props) => props.theme.darkBackgroundColor};
  }
`;

export { LabelContainer };
