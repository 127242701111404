import { IconType } from 'types/return/search-order/icons-type';

export const iconsDataLabel: IconType[] = [
  {
    url: '/assets/img/printer.svg',
    name: 'Print',
    info: 'Select items to return & print shipping Label',
    alt: 'Printer icon'
  },
  {
    url: '/assets/img/box-2.svg',
    name: 'Pack',
    info: 'Pack your items and apply shipping Label',
    alt: 'Pack icon'
  },
  {
    url: '/assets/img/delivery-fast.svg',
    name: 'Ship',
    info: 'Drop off your package to any USPS location',
    alt: 'Truck icon'
  }
];

export const iconsDataQR: IconType[] = [
  {
    url: '',
    name: 'Select',
    info: 'Select return option',
    alt: 'List bullet icon'
  },
  {
    url: '/assets/img/box-2.svg',
    name: 'Pack',
    info: 'Pack your items',
    alt: 'Pack icon'
  },
  {
    url: '/assets/img/delivery-fast.svg',
    name: 'Ship',
    info: 'Ship the package',
    alt: 'Truck icon'
  }
];
