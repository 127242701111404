import React from 'react';
import { Container, ReferenceNumberTitle } from './TrackingEventsTable.styled';
import { Table } from '@mui/material';
import { TrackingEvent } from 'types/customer-service/find-parcels-api-response-type';
import { TrackingEventsTableHead } from './TrackingEventsTableHead/TrackingEventsTableHead';
import { TrackingEventsTableBody } from './TrackingEventsTableBody/TrackingEventsTableBody';
import { useIntl } from 'react-intl';

export const TrackingEventsTable = (props: OwnProps) => {
  const { trackingEvents, referenceNumber } = props;

  const intl = useIntl();

  return (
    <Container>
      {referenceNumber && (
        <ReferenceNumberTitle>
          {intl.formatMessage({ id: 'CS.parcels.searchByTrackingNumber.trackingEventsTable.referenceNumberTitle' }, { referenceNumber })}
        </ReferenceNumberTitle>
      )}
      <Table data-test-id="cs-tracking-events-table" size="small">
        <TrackingEventsTableHead />
        <TrackingEventsTableBody trackingEvents={trackingEvents} />
      </Table>
    </Container>
  );
};

export interface OwnProps {
  trackingEvents: TrackingEvent[];
  referenceNumber: string | null;
}
