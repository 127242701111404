import React, { useEffect, useState } from 'react';
import { Container } from './TableView.styled';
import { TableToolbar } from './TableToolbar/TableToolbar';
import { HeadParcelTable } from './HeadParcelTable/HeadParcelTable';
import { BodyParcelTable } from './BodyParcelTable/BodyParcelTable';
import { SortDirection, Table } from '@mui/material';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import type { FilterByStatusType } from 'types/customer-service/filter-by-status-type';
import { filterParcelsByStatus } from 'utils/customer-service/filter-parcels-by-status/filter-parcels-by-status';
import { sortParcelsByTableColumn } from 'utils/customer-service/sort-parcels-by-table-column/sort-parcels-by-table-column';
import { ColumnTypeEnum } from 'types/customer-service/column-type-enum';
import { useIntl } from 'react-intl';

export const TableView = (props: OwnProps) => {
  const { parcels } = props;

  const intl = useIntl();

  const [filterByStatus, setFilterByStatus] = useState<FilterByStatusType>('all');
  const [sortBy, setSortBy] = useState<ColumnTypeEnum | null>(null);
  const [sortType, setSortType] = useState<SortDirection>('asc');
  const [sortedParcels, setSortedParcels] = useState<Parcel[]>(parcels);

  useEffect(() => {
    const filteredParcels = filterParcelsByStatus(parcels, filterByStatus);
    const sortedParcels = sortParcelsByTableColumn(filteredParcels, sortBy, sortType, intl);

    setSortedParcels(sortedParcels);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByStatus, sortBy, sortType]);

  return (
    <Container data-test-id="table-view">
      <TableToolbar filterByStatus={filterByStatus} setFilterByStatus={setFilterByStatus} sortedParcels={sortedParcels} />
      <Table data-test-id="cs-parcels-list-search-by-tracking-number-table-view">
        <HeadParcelTable sortBy={sortBy} setSortBy={setSortBy} sortType={sortType} setSortType={setSortType} />
        <BodyParcelTable parcels={sortedParcels} />
      </Table>
    </Container>
  );
};

export interface OwnProps {
  parcels: Parcel[];
}
