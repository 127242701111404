import React from 'react';
import { ErrorContainer, ErrorContentWrapper, ErrorTitle, ErrorSubtitle } from './Error.styled';
import { useIntl } from 'react-intl';

export const Error: React.FC = () => {
  const intl = useIntl();

  return (
    <ErrorContainer data-test-id="error-page-container">
      <ErrorContentWrapper>
        <ErrorTitle data-test-id="error-page-container__title">{intl.formatMessage({ id: 'SHARED.error.title' })}</ErrorTitle>
        <ErrorSubtitle data-test-id="error-page-container__subtitle">{intl.formatMessage({ id: 'SHARED.error.subtitle' })}</ErrorSubtitle>
      </ErrorContentWrapper>
    </ErrorContainer>
  );
};
