import React from 'react';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import {
  AlertContainer,
  AlertMessagesList,
  AlertMessagesListContainer,
  AlertMessagesListItem,
  AlertMessagesListTitle,
  AlertIconContainer,
  AlertMessageTitleLink,
  AlertMessageTitleText,
  AlertMessageTitleButton,
  AlertCloseIconContainer
} from './Alert.styled';

import { useIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBMiniIconsEnum } from 'constants/PBIconsEnums';

export enum alertType {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info'
}

export const Alert: React.FC<OwnProps> = (props) => {
  const {
    message,
    width,
    maxWidth,
    theme,
    gtmId,
    testId,
    error,
    iconSize,
    policyUrl,
    btnMessage,
    returnsCustomFieldLabel,
    btnMethod,
    type,
    marginSm,
    marginMd,
    marginL,
    marginXl,
    marginXXl,
    errorsListAsKeys,
    onClose,
    buttonGTMId
  } = props;

  const intl = useIntl();

  return (
    <AlertContainer
      role="alert"
      closeButtonExist={typeof onClose === 'function'}
      data-error-id={true}
      data-test-id={testId ? testId : 'alert-container'}
      data-gtm-id={gtmId}
      width={width}
      maxWidth={maxWidth}
      type={type}
      localTheme={theme}
      marginSm={marginSm}
      marginMd={marginMd}
      marginL={marginL}
      marginXl={marginXl}
      marginXXl={marginXXl}>
      {typeof onClose === 'function' && (
        <AlertCloseIconContainer>
          <IconButton data-test-id="alert-close-button" aria-label="close" onClick={onClose}>
            <PBIcon type={PBIconTypeEnum.Mini} name={PBMiniIconsEnum.Close} />
          </IconButton>
        </AlertCloseIconContainer>
      )}

      <AlertIconContainer localTheme={theme} iconSize={iconSize} type={type}>
        <PBIcon
          name={
            type === alertType.SUCCESS
              ? PBMiniIconsEnum.CheckCircleOpen
              : type === alertType.INFO
              ? PBMiniIconsEnum.InfoCircleOpen
              : PBMiniIconsEnum.AlertCircleOpen
          }
          type={PBIconTypeEnum.Mini}
        />
      </AlertIconContainer>
      <AlertMessagesListContainer data-gtm-id="alert-messages">
        <AlertMessagesListTitle data-test-id="alert-title">
          <AlertMessageTitleText data-test-id="alert-title-text" type={type} dangerouslySetInnerHTML={{ __html: message }} />
          {(policyUrl || (btnMethod && btnMessage)) && ' '}
          {policyUrl && (
            <AlertMessageTitleLink
              href={policyUrl}
              data-test-id="alert-title-link"
              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'RETURN.common.returnPolicy.link' }) }}
            />
          )}
          {btnMessage && btnMethod && (
            <AlertMessageTitleButton
              type="button"
              data-gtm-id={buttonGTMId}
              data-test-id="alert-title-button"
              dangerouslySetInnerHTML={{ __html: btnMessage }}
              onClick={btnMethod}
            />
          )}
        </AlertMessagesListTitle>
        {error && error.length > 0 && (
          <AlertMessagesList data-test-id="alert-list">
            {error.map((err, index) => {
              return (
                <AlertMessagesListItem data-test-id={`alert-list-item-${err.code}`} key={index}>
                  {errorsListAsKeys ? intl.formatMessage({ id: err }, { label: returnsCustomFieldLabel }) : err}
                </AlertMessagesListItem>
              );
            })}
          </AlertMessagesList>
        )}
      </AlertMessagesListContainer>
    </AlertContainer>
  );
};

export interface OwnProps {
  message: string;
  width?: string;
  maxWidth?: string;
  theme?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any[];
  gtmId?: string;
  buttonGTMId?: string;
  testId?: string;
  iconSize?: string;
  policyUrl?: string;
  btnMessage?: string;
  btnMethod?: () => void;
  type?: alertType;
  marginSm?: string;
  marginMd?: string;
  marginL?: string;
  marginXl?: string;
  marginXXl?: string;
  errorsListAsKeys?: boolean;
  returnsCustomFieldLabel?: string | null;
  onClose?: () => void;
}
