import { styled, TextField } from '@mui/material';

interface MyThemeComponentProps {
  width?: string;
  marginDefault?: string;
  widthMd?: string;
  marginMd?: string;
  widthXXL?: string;
  marginXXL?: string;
  textAlignCenter?: boolean;
  fontSize?: string;
  padding?: string;
  isAdornmentExist?: boolean;
  borderRadius?: string;
  flexGrow?: string;
}

export const CustomTextField = styled(TextField, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== 'width' &&
    prop !== 'marginDefault' &&
    prop !== 'widthMd' &&
    prop !== 'marginMd' &&
    prop !== 'widthXXL' &&
    prop !== 'marginXXL' &&
    prop !== 'textAlignCenter' &&
    prop !== 'fontSize' &&
    prop !== 'padding' &&
    prop !== 'isAdornmentExist' &&
    prop !== 'borderRadius' &&
    prop !== 'flexGrow'
})<MyThemeComponentProps>(
  ({
    theme,
    width,
    marginDefault,
    widthMd,
    marginMd,
    widthXXL,
    marginXXL,
    textAlignCenter,
    fontSize,
    padding,
    isAdornmentExist,
    borderRadius,
    flexGrow
  }) => ({
    width: width ? width : '100%',
    margin: marginDefault ? marginDefault : '0',
    position: 'relative',
    flexGrow: flexGrow ? flexGrow : 0,

    input: {
      background: '#fff',
      padding: isAdornmentExist ? '8.5px 14px' : padding,
      textAlign: textAlignCenter ? 'center' : 'left',
      fontSize: fontSize,
      fontFamily: theme.typography.fontFamily,
      borderRight: isAdornmentExist ? '1px solid rgba(0, 0, 0, 0.23)' : 'none'
    },

    '& .Mui-focused': {
      input: {
        borderRightWidth: isAdornmentExist ? '2px' : 'none',
        borderRight: isAdornmentExist ? '2px solid black' : 'none'
      }
    },

    '& .Mui-error': {
      input: {
        borderRightColor: isAdornmentExist ? '#f44336' : 'none'
      }
    },

    '& .MuiOutlinedInput-root:hover': {
      input: {
        borderRightColor: isAdornmentExist ? 'black' : 'none'
      }
    },

    '& .MuiOutlinedInput-root:hover.Mui-error': {
      input: {
        borderRightColor: isAdornmentExist ? '#f44336' : 'none'
      }
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: borderRadius ? borderRadius : '4px'
    },

    'textarea::placeholder': {
      fontSize: '16px'
    },

    '& .MuiInputAdornment-root': {
      marginLeft: '14px'
    },

    [theme.breakpoints.up('medium')]: {
      width: widthMd && widthMd,
      margin: marginMd && marginMd
    },

    [theme.breakpoints.up('xxlarge')]: {
      width: widthXXL && widthXXL,
      margin: marginXXL && marginXXL
    }
  })
);
