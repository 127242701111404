import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const ShippingInfoContainer = styled.div`
  width: 100%;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      max-width: 740px;
    `}
`;

export const ShippingInfoList = styled.ul`
  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      border-top: 1px solid ${theme.gray900};
    `}
`;
