import { PageTypeEnum } from 'types/app/page-type-enum';
import { Environment, resolveEnvironment } from 'utils/common/environment-resolver/environment-resolver';

export const resolvePageType = (
  pathname: string,
  resolvedVanityName: string,
  trackingNumber: string | undefined,
  designId: string | undefined
): PageTypeEnum => {
  const environment: Environment = resolveEnvironment();

  const tracking =
    environment === Environment.LOCAL
      ? [
          `/tracking/${resolvedVanityName}/${trackingNumber}`,
          `/preview/published/tracking/${resolvedVanityName}/${trackingNumber}`,
          `/preview/draft/tracking/${resolvedVanityName}/${designId}/${trackingNumber}`
        ]
      : [
          `/track/${trackingNumber}`,
          `/tracking/${trackingNumber}`,
          `/tracking/${resolvedVanityName}/${trackingNumber}`,
          `/preview/published/tracking/${resolvedVanityName}/${trackingNumber}`,
          `/preview/draft/tracking/${resolvedVanityName}/${designId}/${trackingNumber}`
        ];
  const customerService =
    environment === Environment.LOCAL
      ? [`track/${resolvedVanityName}`, `/preview/${resolvedVanityName}/${designId}`]
      : [`/track`, `/preview/${resolvedVanityName}/${designId}`];
  const cancelPickup = ['return/pickup/cancel'];
  const returns = ['search', 'return'];
  const unsubscribe = ['unsubscribe'];

  const trackingMatch = tracking.some((element: string) => pathname.includes(element));
  if (trackingMatch) return PageTypeEnum.TrackingPage;

  const customerServiceMatch = customerService.some((element: string) => pathname.includes(element));
  if (customerServiceMatch) return PageTypeEnum.CustomerServicePage;

  const cancelPickupMatch = cancelPickup.some((element: string) => pathname.includes(element));
  if (cancelPickupMatch) return PageTypeEnum.CancelPickupPage;

  const returnsMatch = returns.some((element: string) => pathname.includes(element));
  if (returnsMatch) return PageTypeEnum.ReturnsPage;

  const unsubscribeMatch = unsubscribe.some((element: string) => pathname.includes(element));
  if (unsubscribeMatch) return PageTypeEnum.UnsubscribePage;

  return PageTypeEnum.NotFound;
};
