import styled from '@emotion/styled';

export const Container = styled.div`
  margin: 10px 5px;
`;

export const ReferenceNumberTitle = styled.h4`
  display: block;
  margin: 0 0 10px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: normal;

  @media ${({ theme }) => theme.xlargeUp} {
    display: none;
  }
`;
