import React from 'react';
import { ReturnIconsContainer, ReturnIconsList } from './ReturnIcons.styled';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReturnIcon } from './ReturnIcon/ReturnIcon';
import { IconType } from 'types/return/search-order/icons-type';
import { iconsDataQR, iconsDataLabel } from 'constants/return-icons-lists';

export const ReturnIcons: React.FC = () => {
  const { sapiQrCodeCbconnect2705 } = useFlags();
  const iconsList: IconType[] = sapiQrCodeCbconnect2705 ? iconsDataQR : iconsDataLabel;

  return (
    <ReturnIconsContainer data-test-id="search-order-return-icons-container">
      <ReturnIconsList data-test-id="search-order-return-icons-list">
        {iconsList.map((item: IconType) => (
          <ReturnIcon key={item.name} title={item.name} description={item.info} url={item.url} alt={item.alt} />
        ))}
      </ReturnIconsList>
    </ReturnIconsContainer>
  );
};
