import React, { PropsWithChildren, useContext } from 'react';
import {
  Icon,
  Icon2,
  Icon3,
  MethodContainer,
  MethodDescription,
  MethodShowqr,
  MethodEstimate,
  MethodInput,
  MethodLabel,
  MethodPrice,
  MethodPrice2,
  MethodPriceInformation,
  MethodSubtitle,
  MethodTitle,
  SecondContentContainer,
  SecondContentFirstWrapper
} from './Method.styled';
import { MethodsContext } from 'context/returns/MethodsContext';
import { useIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MethodEnum, ShippingTypeEnum } from 'types/return/overview/methods/method-types';
import { resolveGTMReturnLabelTypeValue } from 'utils/returns/overview/methods/resolve-gtm-return-label-type-value/resolve-gtm-return-label-type-value';
import '../MethodsForm/customeStyle.css';

export const Method: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { iconUrl, returnMethod, returnShippingType, price } = props;
  const { method, setMethod, setShippingType, shippingType } = useContext(MethodsContext);
  const intl = useIntl();
  const { returnsUps2DayCoco264 } = useFlags();

  const handleClickMethod = (): void => {
    const errelm = document.querySelector<HTMLElement>('.errormsg')!;
    if (errelm) {
      //errelm.style.display = 'none';
      errelm.classList.add('Hideerrormsg');
    }

    const elm = document.querySelectorAll<HTMLElement>('.methodtype')!;
    elm.forEach((element) => {
      //element.style.border = '1px solid #a6a6a6';
      element.classList.add('defaultmethod');
    });
    setMethod(returnMethod);
    setShippingType(returnShippingType);
    window.dataLayer.push({ returnLabelType: resolveGTMReturnLabelTypeValue(returnMethod) });
  };

  return (
    <MethodContainer
      data-test-id={`method-container-${returnShippingType}-${returnMethod}`}
      data-gtm-id={`return-${returnShippingType}-${returnMethod}-method`}>
      <MethodInput
        data-test-id="method-radio-input"
        type="radio"
        name="shipping-option"
        value={`${returnShippingType}-${returnMethod}`}
        id={`${returnShippingType}-${returnMethod}`}
      />
      <MethodLabel
        className="methodtype"
        htmlFor={`${returnShippingType}-${returnMethod}`}
        data-test-id="method-label"
        checked={method === returnMethod && shippingType === returnShippingType}
        onClick={handleClickMethod}>
        <Icon2 data-test-id="method-icon" src={iconUrl} alt={returnMethod === 'label' ? 'Label icon' : 'QR code icon'} />
        <SecondContentContainer>
          <SecondContentFirstWrapper>
            <Icon data-test-id="method-icon" src={iconUrl} alt={returnMethod === 'label' ? 'Label icon' : 'QR code icon'} />
            <MethodPrice data-test-id="method-price">{price}</MethodPrice>
          </SecondContentFirstWrapper>
          <MethodTitle data-test-id="method-title">
            {returnShippingType === 'standard' &&
              returnMethod === 'label' &&
              intl.formatMessage(
                { id: 'RETURN.overview.methods.form.method.label.shippingType.standard.title' },
                { label: returnsUps2DayCoco264 ? '' : 'USPS ' }
              )}
            {returnShippingType === 'standard' &&
              returnMethod === 'qrcode' &&
              intl.formatMessage(
                { id: 'RETURN.overview.methods.form.method.qrcode.shippingType.standard.title' },
                { label: returnsUps2DayCoco264 ? '' : 'USPS ' }
              )}
            {returnShippingType === 'expedited' &&
              returnMethod === 'label' &&
              intl.formatMessage(
                { id: 'RETURN.overview.methods.form.method.label.shippingType.expedited.title' },
                { label: returnsUps2DayCoco264 ? '' : 'USPS ' }
              )}
            {returnShippingType === 'expedited' &&
              returnMethod === 'qrcode' &&
              intl.formatMessage(
                { id: 'RETURN.overview.methods.form.method.qrcode.shippingType.expedited.title' },
                { label: returnsUps2DayCoco264 ? '' : 'USPS ' }
              )}
          </MethodTitle>
          <MethodSubtitle data-test-id="method-subtitle">
            {returnMethod === 'label'
              ? intl.formatMessage({ id: 'RETURN.overview.methods.form.method.label.subtitle' })
              : intl.formatMessage({ id: 'RETURN.overview.methods.form.method.qrcode.subtitle' })}
          </MethodSubtitle>

          <MethodDescription data-test-id="method-description">
            {returnMethod === 'label' ? (
              <Icon3 className="printericon" src="/assets/img/Print.svg" />
            ) : (
              <Icon3 className="printericon" src="/assets/img/Print_Disable.svg" />
            )}

            {returnMethod === 'label'
              ? intl.formatMessage({ id: 'RETURN.overview.methods.form.method.label.description' })
              : intl.formatMessage({ id: 'RETURN.overview.methods.form.method.qrcode.description' })}
          </MethodDescription>

          <MethodShowqr data-test-id="method-showQRcodetext">
            {returnMethod === 'qrcode' && intl.formatMessage({ id: 'RETURN.overview.methods.form.method.qrcode.showQRtext' })}
          </MethodShowqr>

          <MethodEstimate data-test-id="method-estimate">
            {returnShippingType === 'standard'
              ? intl.formatMessage({ id: 'RETURN.overview.methods.form.method.label.estimate' })
              : intl.formatMessage({ id: 'RETURN.overview.methods.form.method.qrcode.estimate' })}
          </MethodEstimate>

          {price !== 'FREE' && (
            <MethodPriceInformation data-test-id="method-price-information">
              {intl.formatMessage({ id: 'RETURN.overview.methods.form.method.shippingType.expedited.priceInformation' })}
            </MethodPriceInformation>
          )}
        </SecondContentContainer>
        <MethodPrice2 data-test-id="method-price-2">{price}</MethodPrice2>
      </MethodLabel>
    </MethodContainer>
  );
};

export interface OwnProps {
  iconUrl: string;
  returnMethod: MethodEnum;
  returnShippingType: ShippingTypeEnum;
  price: string;
}
