import { MethodEnum, ShippingTypeEnum } from 'types/return/overview/methods/method-types';
import { SortCodeType } from 'types/return/shared/disposition-sort-code-type';
import { SelectedProductPayloadType } from 'types/return/overview/selected-product-payload-type';

export class ReturnDetails {
  consumerComments: string;
  items: SelectedProductPayloadType[];
  orderNumber: string;
  shippingAddress: ShippingAddressType;
  notification: NotificationsType;
  method: MethodEnum;
  sortCode: SortCodeType | Record<string, never>;
  shippingType: ShippingTypeEnum;
  returnsCustomFieldValue: string;

  constructor(
    consumerComments: string,
    items: SelectedProductPayloadType[],
    orderNumber: string,
    shippingAddress: ShippingAddressType,
    notification: NotificationsType,
    method: MethodEnum,
    sortCode: SortCodeType | Record<string, never>,
    shippingType: ShippingTypeEnum,
    returnsCustomFieldValue: string
  ) {
    this.consumerComments = consumerComments;
    this.items = items;
    this.orderNumber = orderNumber;
    this.shippingAddress = shippingAddress;
    this.notification = notification;
    this.method = method;
    this.sortCode = sortCode;
    this.shippingType = shippingType;
    this.returnsCustomFieldValue = returnsCustomFieldValue;
  }
}

interface ShippingAddressType {
  fullName: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
}

interface NotificationsType {
  notificationEmail: string;
  notificationSms: string;
}
