import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CustomFormControlProps {
  width?: string;
  widthL?: string;
  widthXl?: string;
  marginDefault?: string;
  marginL?: string;
  marginXl?: string;
}

export const CustomFormControl = styled(FormControl, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== 'width' && prop !== 'widthL' && prop !== 'widthXl' && prop !== 'marginDefault' && prop !== 'marginL' && prop !== 'marginXl'
})<CustomFormControlProps>(({ theme, width, widthL, widthXl, marginDefault, marginL, marginXl }) => ({
  width: width ? width : '100%',
  margin: marginDefault ? marginDefault : '0',

  [theme.breakpoints.up('large')]: {
    width: widthL && widthL,
    margin: marginL && marginL
  },

  [theme.breakpoints.up('xlarge')]: {
    width: widthXl && widthXl,
    margin: marginXl && marginXl
  }
}));
