import React, { PropsWithChildren, useContext } from 'react';
import {
  ButtonsContainer,
  ConfirmedDate,
  ConfirmedLocationDetailsBox,
  ConfirmedLocationElement,
  ConfirmedPickupDetailsBox,
  ScheduledPickupConfirmationContainer,
  ScheduledPickupConfirmationDetails,
  ScheduledPickupConfirmationTitle
} from './ScheduledPickupConfirmation.styled';
import { PickupContext } from 'context/returns/PickupContext';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { useIntl } from 'react-intl';

export const ScheduledPickupConfirmation: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { handleModalClose } = props;
  const intl = useIntl();

  const { confirmedPickupLocation, confirmationNumber, confirmedAdditionalInstructions, confirmedPickupDate } = useContext(PickupContext);

  if (!confirmedPickupLocation || !confirmationNumber || !confirmedPickupDate) return null;
  return (
    <ScheduledPickupConfirmationContainer data-test-id="scheduled-pickup-confirmation-container">
      <ScheduledPickupConfirmationTitle
        data-test-id="scheduled-pickup-confirmation-title"
        areConfirmedAdditionalInstructionsExist={!!confirmedAdditionalInstructions}>
        {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.scheduledPickupConfirmation.title' })}
      </ScheduledPickupConfirmationTitle>
      <ConfirmedPickupDetailsBox>
        <ConfirmedDate data-test-id="scheduled-pickup-confirmed-date">{confirmedPickupDate}</ConfirmedDate>
        <ConfirmedLocationDetailsBox>
          <ConfirmedLocationElement data-test-id="scheduled-pickup-confirmed-element-title">
            {intl.formatMessage({
              id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.scheduledPickupConfirmation.confirmedLocationDetailsBox.title'
            })}
          </ConfirmedLocationElement>
          <ConfirmedLocationElement data-test-id="scheduled-pickup-confirmed-element-address1">
            {confirmedPickupLocation.address1}
          </ConfirmedLocationElement>
          {confirmedPickupLocation.address2 && (
            <ConfirmedLocationElement data-test-id="scheduled-pickup-confirmed-element-address2">
              {confirmedPickupLocation.address2}
            </ConfirmedLocationElement>
          )}
          <ConfirmedLocationElement data-test-id="scheduled-pickup-confirmed-element-city">
            {confirmedPickupLocation.city} {confirmedPickupLocation.stateOrProvince} {confirmedPickupLocation.postalCode}
          </ConfirmedLocationElement>
          {confirmedAdditionalInstructions && (
            <ConfirmedLocationElement data-test-id="scheduled-pickup-confirmed-element-additional-instruction">
              {confirmedAdditionalInstructions}
            </ConfirmedLocationElement>
          )}
        </ConfirmedLocationDetailsBox>
      </ConfirmedPickupDetailsBox>
      <ScheduledPickupConfirmationDetails data-test-id="scheduled-pickup-confirmation-number">
        {intl.formatMessage(
          { id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.scheduledPickupConfirmation.confirmationNumber' },
          { confirmationNumber: <b>{confirmationNumber}</b> }
        )}
      </ScheduledPickupConfirmationDetails>
      <ButtonsContainer>
        <CustomButton isUppercase data-test-id="scheduled-pickup-confirmation-close-button" onClick={handleModalClose} variant="contained">
          {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.scheduledPickupConfirmation.button.close' })}
        </CustomButton>
      </ButtonsContainer>
    </ScheduledPickupConfirmationContainer>
  );
};

export interface OwnProps {
  handleModalClose: () => void;
}
