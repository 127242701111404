import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import { ParcelStatusEnum } from 'types/customer-service/find-parcels-api-response-type';

export const StatusBarContainer = styled.div`
  margin: 0 0 25px 0;
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    width: 240px;
    margin: 0 0 30px 0;
  }
`;

export const StepsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const FirstStep = styled.div<{ parcelStatus: ParcelStatusEnum }>`
  height: 16px;
  flex-grow: 1;
  width: 100%;
  background: ${({ parcelStatus }) =>
    parcelStatus !== ParcelStatusEnum.PREPARING &&
    parcelStatus !== ParcelStatusEnum.FALLBACK &&
    parcelStatus !== ParcelStatusEnum.TIMEOUT &&
    parcelStatus !== ParcelStatusEnum.NOT_FOUND
      ? getCustomStyle('styleModule.secondaryColor')
      : '#f0f0f0'};
  position: relative;

  ${({ parcelStatus }) =>
    (parcelStatus === ParcelStatusEnum.DELIVERED || parcelStatus === ParcelStatusEnum.RETURN_RECEIVED) &&
    css`
      background: #00b140;
    `}
  &:before {
    transform: translate(0, -50%);
    top: 50%;
    left: 100%;
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-bottom: 14px solid transparent;
    z-index: 2;
  }

  &:after {
    transform: translate(0, -50%);
    top: 50%;
    left: 100%;
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 8px solid #f0f0f0;
    border-bottom: 8px solid transparent;
    z-index: 2;
    border-left-color: ${({ parcelStatus }) =>
      parcelStatus !== ParcelStatusEnum.PREPARING &&
      parcelStatus !== ParcelStatusEnum.FALLBACK &&
      parcelStatus !== ParcelStatusEnum.TIMEOUT &&
      parcelStatus !== ParcelStatusEnum.NOT_FOUND
        ? getCustomStyle('styleModule.secondaryColor')
        : '#F0F0F0'};

    ${({ parcelStatus }) =>
      (parcelStatus === ParcelStatusEnum.DELIVERED || parcelStatus === ParcelStatusEnum.RETURN_RECEIVED) &&
      css`
        border-left-color: #00b140;
      `}
  }
`;

export const SecondStep = styled.div<{ parcelStatus: ParcelStatusEnum }>`
  height: 16px;
  flex-grow: 1;
  width: 100%;
  position: relative;
  background: #f0f0f0;

  background: ${({ parcelStatus }) =>
    parcelStatus === ParcelStatusEnum.OUT_FOR_DELIVERY ||
    parcelStatus === ParcelStatusEnum.DELIVERY_EXCEPTION ||
    parcelStatus === ParcelStatusEnum.UNKNOWN ||
    parcelStatus === ParcelStatusEnum.RETURN_IN_TRANSIT ||
    parcelStatus === ParcelStatusEnum.RETURN_OUT_FOR_DELIVERY ||
    parcelStatus === ParcelStatusEnum.RETURN_DELIVERY_EXCEPTION ||
    parcelStatus === ParcelStatusEnum.RETURN_UNKNOWN
      ? getCustomStyle('styleModule.secondaryColor')
      : '#f0f0f0'};
  ${({ parcelStatus }) =>
    (parcelStatus === ParcelStatusEnum.DELIVERED || parcelStatus === ParcelStatusEnum.RETURN_RECEIVED) &&
    css`
      background: #00b140;
    `}
  &:before {
    transform: translate(0, -50%);
    top: 50%;
    left: 100%;
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-bottom: 14px solid transparent;
    z-index: 2;
  }

  &:after {
    transform: translate(0, -50%);
    top: 50%;
    left: 100%;
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 8px solid #f0f0f0;
    border-bottom: 8px solid transparent;
    z-index: 2;
    border-left-color: ${({ parcelStatus }) =>
      parcelStatus === ParcelStatusEnum.OUT_FOR_DELIVERY ||
      parcelStatus === ParcelStatusEnum.DELIVERY_EXCEPTION ||
      parcelStatus === ParcelStatusEnum.UNKNOWN ||
      parcelStatus === ParcelStatusEnum.RETURN_IN_TRANSIT ||
      parcelStatus === ParcelStatusEnum.RETURN_OUT_FOR_DELIVERY ||
      parcelStatus === ParcelStatusEnum.RETURN_DELIVERY_EXCEPTION ||
      parcelStatus === ParcelStatusEnum.RETURN_UNKNOWN
        ? getCustomStyle('styleModule.secondaryColor')
        : '#f0f0f0'};

    ${({ parcelStatus }) =>
      (parcelStatus === ParcelStatusEnum.DELIVERED || parcelStatus === ParcelStatusEnum.RETURN_RECEIVED) &&
      css`
        border-left-color: #00b140;
      `}
  }
`;

export const ThirdStep = styled.div<{ parcelStatus: ParcelStatusEnum; shouldDisplayIcon?: boolean }>`
  height: 16px;
  flex-grow: 1;
  width: 100%;
  position: relative;
  background: #f0f0f0;

  @font-face {
    font-family: 'pbi-icon-mini';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/pbi-icon-mini.woff2') format('woff2'), url('/assets/fonts/pbi-icon-mini.ttf') format('truetype'),
      url('/assets/fonts/pbi-icon-mini.woff') format('woff'), url('/assets/fonts/pbi-icon-mini.svg?#pbi-icon-mini') format('svg');
  }

  font-family: 'pbi-icon-mini' !important;

  ${({ parcelStatus }) =>
    (parcelStatus === ParcelStatusEnum.DELIVERED || parcelStatus === ParcelStatusEnum.RETURN_RECEIVED) &&
    css`
      background: #00b140;

      &:after {
        font-style: normal;
        position: absolute;
        top: 50%;
        transform: translate(2px, -50%);
        right: 0;
        height: 40px;
        width: 40px;
        border: 4px solid #00b140;
        border-radius: 50%;
        background: #f9f9f9;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        color: #00b140;
        font-size: 20px;
        content: '\\e935';
      }
    `}
`;
