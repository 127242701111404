import { LanguageEnum, LanguagesType } from 'types/customer-service/languages-types';

export const languages: LanguagesType = {
  [LanguageEnum.ChineseTraditional]: {
    value: 'zh-hant',
    label: '中文(繁體)'
  },
  [LanguageEnum.ChineseSimplified]: {
    value: 'zh-hans',
    label: '中文(简体)'
  },
  [LanguageEnum.Danish]: {
    value: 'da',
    label: 'dansk'
  },
  [LanguageEnum.German]: {
    value: 'de',
    label: 'Deutsch'
  },
  [LanguageEnum.Greek]: {
    value: 'el',
    label: 'Ελληνικά'
  },
  [LanguageEnum.EnglishUS]: {
    value: 'en',
    label: 'English (United States)'
  },
  [LanguageEnum.Spanish]: {
    value: 'es',
    label: 'Español'
  },
  [LanguageEnum.FrenchCA]: {
    value: 'fr-ca',
    label: 'français (Canada)'
  },
  [LanguageEnum.FrenchFR]: {
    value: 'fr-fr',
    label: 'français (France)'
  },
  [LanguageEnum.Hungarian]: {
    value: 'hu',
    label: 'magyar'
  },
  [LanguageEnum.Italian]: {
    value: 'it',
    label: 'italiano'
  },
  [LanguageEnum.Dutch]: {
    value: 'nl',
    label: 'Nederlands'
  },
  [LanguageEnum.Japanese]: {
    value: 'ja',
    label: '日本語'
  },
  [LanguageEnum.Norwegian]: {
    value: 'nb',
    label: 'norsk (bokmål)'
  },
  [LanguageEnum.Polish]: {
    value: 'pl',
    label: 'Polski'
  },
  [LanguageEnum.Portuguese]: {
    value: 'pt',
    label: 'português'
  },
  [LanguageEnum.Russian]: {
    value: 'ru',
    label: 'русский'
  },
  [LanguageEnum.Finnish]: {
    value: 'fi',
    label: 'suomi'
  },
  [LanguageEnum.Swedish]: {
    value: 'sv',
    label: 'svenska'
  }
};
