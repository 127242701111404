import styled from '@emotion/styled';

export const EllipseText = styled.span<{ isTextCut: boolean; isUppercase: boolean }>`
  display: inline-block;
  overflow: hidden;
  font-size: inherit;
  color: inherit;
  text-decoration: inherit;
  text-transform: ${({ isUppercase }) => (isUppercase ? 'uppercase' : 'inherit')};

  &:nth-of-type(1) {
    max-width: ${({ isTextCut }) => (isTextCut ? 'calc(100% - 2em)' : '100%')};
    text-overflow: ellipsis;
  }

  &:nth-of-type(2) {
    max-width: calc(100% - 1.5em);
  }
`;
