import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { QRCodeContainer, QRCodeTitle, QRCodeWrapper } from './QRCode.styled';
import { QrCodeInfo } from './QRCodeInfo/QRCodeInfo';
import { QrCodeContent } from './QRCodeContent/QRCodeContent';
import { QrCodeAdditionalInfo } from './QRCodeAdditionalInfo/QRCodeAdditionalInfo';
import { useIntl } from 'react-intl';
import { ReturnInfoApiResponseSuccessType } from 'types/return/return-info/return-info-api-response-type';
import { initGoogleTagManagerDataLayer } from '../../../../utils/common/google-tag-manager/google-tag-manager';
import { ClientConfigContext } from '../../../../context/shared/ClientConfigContext';

export const QRCode: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { urlKey, returnInfoConfig } = props;
  const { carrierSuggestions, qrCode, qrCodeId, trackingUrl } = returnInfoConfig;

  const intl = useIntl();

  const { merchantId, vanityName, clientReturnType, template, brandId } = useContext(ClientConfigContext);

  useEffect(() => {
    if (returnInfoConfig) {
      return initGoogleTagManagerDataLayer(
        returnInfoConfig.tagManagerModule.deliveryType,
        returnInfoConfig.tagManagerModule.trackingStatus,
        merchantId,
        vanityName,
        null,
        'Return QR Code',
        'returnPageview',
        clientReturnType === 'integrated' ? 'RMA' : 'Non-integrated',
        template,
        !!urlKey,
        null,
        brandId
      );
    }
  }, [returnInfoConfig, merchantId, vanityName, clientReturnType, template, urlKey, brandId]);

  return (
    <QRCodeContainer data-test-id="qr-code-container">
      <QRCodeTitle data-test-id="qr-code-title">{intl.formatMessage({ id: 'RETURN.returnInfo.qrCode.title' })}</QRCodeTitle>
      <QRCodeWrapper>
        <QrCodeInfo carrierSuggestions={carrierSuggestions ? carrierSuggestions : []} />
        {qrCode && <QrCodeContent qrCode={qrCode} />}
      </QRCodeWrapper>
      <QrCodeAdditionalInfo qrCodeId={qrCodeId} trackingUrl={trackingUrl} />
    </QRCodeContainer>
  );
};

export interface OwnProps {
  urlKey: string | null;
  returnInfoConfig: ReturnInfoApiResponseSuccessType;
}
