import React from 'react';
import { useIntl } from 'react-intl';
import { MethodEnum } from 'types/return/overview/methods/method-types';
import { InstructionType, setReturnsInstruction } from 'utils/returns/common/set-returns-instruction/set-returns-instruction';
import {
  ReturnsInstructionsList,
  ReturnsInstructionsListItem,
  ReturnsInstructionsNumber,
  ReturnsInstructionsParagraph
} from './ReturnsInstructions.styled';

export const ReturnsInstructions: React.FC<OwnProps> = (props) => {
  const { methodType, carrierName } = props;

  const intl = useIntl();

  const instructions: InstructionType[] = setReturnsInstruction(methodType, carrierName);

  return (
    <ReturnsInstructionsList>
      {instructions.map((instruction: InstructionType, index: number) => (
        <ReturnsInstructionsListItem key={instruction.id} aria-label={`returns-instruction-${index + 1}`}>
          <ReturnsInstructionsNumber>{instruction.number}</ReturnsInstructionsNumber>
          <ReturnsInstructionsParagraph>{intl.formatMessage({ id: instruction.text })}</ReturnsInstructionsParagraph>
        </ReturnsInstructionsListItem>
      ))}
    </ReturnsInstructionsList>
  );
};

export interface OwnProps {
  methodType: MethodEnum;
  carrierName?: string | undefined;
}
