import moment from 'moment';
import { States } from 'types/tracking-types';
import { TrackingConfigurationType } from 'types/tracking/trackingConfig/tracking-configuration-type';
import { ReturnsModuleType } from 'types/shared/config/returnsModule/returns-module-type';
import { CustomHtmlModuleType } from 'types/shared/config/customHtmlModule/custom-html-module-type';
import { ExploreTheBrandModuleType } from 'types/shared/config/exploreTheBrandModule/explore-the-brand-module-type';
import { FeaturedProductsModuleType } from 'types/shared/config/featuredProductsModule/featured-products-module-type';
import { FeedbackQuestionsModuleType } from 'types/shared/config/feedbackQuestionsModule/feedback-questions-module-type';
import { NotificationsModuleType } from 'types/shared/config/notificationsModule/notifications-module-type';
import { OrderDetailsModuleType } from 'types/shared/config/orderDetailsModule/order-details-module-type';
import { PromotionsAddonModuleType } from 'types/shared/config/promotionsAddonModule/promotions-addon-module-type';
import { ShippingInfoModuleType } from 'types/tracking/trackingConfig/shippingInfoModule/shipping-info-module-type';

export const DATE_FORMAT = 'MMMM D, YYYY';
export const SHORT_DATE_FORMAT = 'DD-MMM-YY';
export const HOURS_FORMAT = 'YYYY/MM/DD HH:mm';

export function shouldDisplayNotificationComponent(trackingPackageStatus: string, notificationsModule: NotificationsModuleType | null): boolean {
  const excludeTrackingPackageStatus = ['Delivered', 'NotFound', 'ReturnReceived', 'ServerError'];
  const validNotificationStatus = trackingPackageStatus && !excludeTrackingPackageStatus.includes(trackingPackageStatus);
  return Boolean(notificationsModule && validNotificationStatus);
}

export function shouldDisplayFeedbackComponent(
  trackingPackageData: TrackingConfigurationType,
  feedbackQuestionsModule: FeedbackQuestionsModuleType
): boolean {
  return (
    feedbackQuestionsModule &&
    Boolean(feedbackQuestionsModule.isEnabled) &&
    !trackingPackageData.isReturn &&
    trackingPackageData.trackingPackageStatus === 'Delivered'
  );
}

export const shouldDisplayOrderDetailsComponent = (
  orderDetailsModule: OrderDetailsModuleType,
  shippingInfoModule: ShippingInfoModuleType,
  isTrusted: boolean
): boolean => {
  const isDemo = shippingInfoModule.trackingNumber.toLowerCase().includes('demo');
  return (isDemo && orderDetailsModule.enabled) || (!isDemo && isTrusted && orderDetailsModule.enabled);
};

export const shouldDisplayCustomHtmlComponent = (customHtmlModule: CustomHtmlModuleType): boolean => {
  return customHtmlModule && customHtmlModule.enabled;
};

export const shouldDisplayReturnsComponent = (
  trackingPackageNotFound: boolean,
  isTrusted: boolean,
  isReturn: boolean,
  returnsModule: ReturnsModuleType
): boolean => {
  return returnsModule && returnsModule.enabled && isTrusted && !trackingPackageNotFound && !isReturn;
};

export function shouldDisplayFeaturedProductsComponent(featuredProductsModule: FeaturedProductsModuleType): boolean {
  return featuredProductsModule && featuredProductsModule.enabled;
}

export function shouldDisplayPromotionsComponent(promotionsAddonModule: PromotionsAddonModuleType): boolean {
  return promotionsAddonModule && promotionsAddonModule.enabled;
}

export function shouldDisplayExploreTheBrandComponent(exploreTheBrandModule: ExploreTheBrandModuleType): boolean {
  return exploreTheBrandModule && Boolean(exploreTheBrandModule.enabled);
}

export const buildDeliveredDate = (deliveryDate: string, location: string, trackingPackageStatus: string, deliveryDay: string): string => {
  let date = '';

  if (trackingPackageStatus === States.ReturnReceived) return `${deliveryDay}, ${deliveryDate}`;

  if (deliveryDate !== '-' && deliveryDate !== undefined) date += deliveryDate;
  if (location && deliveryDate !== '-' && deliveryDate !== undefined) date += ' to ';
  if (location) date += location;

  return date;
};

export const setEDDText = (state: States | string, EDD: string | null): string | undefined => {
  switch (state) {
    case States.OutForDelivery:
    case States.ReturnOutForDelivery: {
      if (!EDD) {
        const date = new Date();
        return moment(date).format('dddd, MMMM D');
      }
      return EDD;
    }
    case States.Unknown:
    case States.DeliveryException:
    case States.ReturnDeliveryException:
    case States.ReturnUnknown:
    case States.NotFound:
    case States.ServerError:
    case States.Timeout: {
      return undefined;
    }
    default: {
      if (EDD) return EDD;
      return undefined;
    }
  }
};
