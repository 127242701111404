import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import ReactTooltip from 'react-tooltip';

export const SearchOrderFormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 140px;
`;

export const SearchOrderFormLabel = styled.label<{ margin?: string }>`
  font-family: ${({ theme }) => getCustomStyle('styleModule.subheaderFontFamily', theme.primaryFontFamily)};
  color: ${({ theme }) => theme.formLabelColor};
  font-size: 18px;
  width: 100%;
  text-align: center;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  padding: 0;
  margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const SearchOrderFormLabelText = styled.span<{ margin?: string }>`
  display: inline-block;
  color: inherit;
  margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const ToolTipIconWrapper = styled.div`
  display: inline-block;

  & > i {
    font-size: 16px;
  }
`;

export const CustomReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 18px 25px 18px 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &.show {
    opacity: 1;
  }

  .multi-line {
    padding: 0;
  }

  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
`;
