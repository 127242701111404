import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding: 18px 32px;
  background-color: #fff;
  color: #4a4a4a;
  border-radius: 4px;
  border: solid 1px rgb(225, 225, 225);
`;
