import styled from '@emotion/styled';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';

export const PromotionalTextWrapper = styled.div``;

export const PromotionalItemTitle = styled.h3`
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 27px;
  font-weight: bold;
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? theme.white : theme.black)};
  font-family: ${getCustomStyle('styleModule.headerFontFamily')};
  text-align: center;

  @media ${({ theme }) => theme.largeUp} {
    font-size: 22px;
    text-align: left;
  }
`;

export const PromotionalItemDescription = styled.p`
  font-size: 15px;
  font-weight: lighter;
  margin: 0 0 40px 0;
  color: ${({ theme }) => (getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark') ? theme.white : theme.black)};
  font-family: ${getCustomStyle('styleModule.bodyFontFamily')};
  text-align: center;

  @media ${({ theme }) => theme.largeUp} {
    font-size: 14px;
    text-align: left;
  }
`;

export const PromotionalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.largeUp} {
    justify-content: flex-start;
  }
`;
