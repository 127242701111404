import styled from '@emotion/styled';

export const QRCodeAdditionalInfoContainer = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.largeUp} {
    width: 55%;
    text-align: justify;
  }
`;

export const QRCodeAdditionalInfoParagraph = styled.p`
  font-size: 14px;
  margin: 0 0 20px 0;

  &:last-of-type {
    margin: 0;
  }
`;

export const QRCodeAdditionalInfoLink = styled.a`
  font-size: inherit;
`;

export const QRCodeAdditionalModifier = styled.span`
  font-weight: 700;
`;
