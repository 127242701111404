import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ProductsSelectionFormContainer = styled.form``;

export const ProductsWrapper = styled.fieldset`
  padding: 0;
  border: 0;
  width: 100%;
  display: block;
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 60px;
  }
`;

export const ProductsList = styled.ul``;

export const ButtonsContainer = styled.div<{ isAuth: boolean }>`
  ${({ isAuth, theme }) =>
    isAuth &&
    css`
      display: flex;
      flex-direction: column-reverse;

      @media ${theme.mediumUp} {
        flex-direction: row;
        justify-content: space-between;
      }
    `}
`;

export const ButtonsWrapper = styled.div<{ isAuth: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media ${({ theme }) => theme.mediumUp} {
    ${({ isAuth }) =>
      isAuth &&
      css`
        order: 1;
        width: auto;
        margin-bottom: 0;
      `}
    flex-direction: row;
    justify-content: flex-end;
  }
`;
