import React from 'react';
import { FaviconsModule } from 'types/tracking-types';

type LinkProps = JSX.IntrinsicElements['link'];

export function getFavIcons(faviconsModule: FaviconsModule): LinkProps[] {
  const useDefaultFavicon = !faviconsModule || faviconsModule.useDefault;
  const sizes = [16, 32, 57, 72, 96, 120, 128, 144, 152, 195, 228];
  const appleSizes = [0, 57, 60, 72, 76, 114, 120, 144, 152, 180];
  const links: JSX.Element[] = [];

  function setLinks(value) {
    const faviconsImageUrl = useDefaultFavicon
      ? '/assets/img/favicons/favicon_' + value + '.png'
      : faviconsModule.mainImageUrl + '?w=' + value + '&h=' + value;
    const size = value + 'x' + value;
    links.push(<link key={value} rel="icon" type="image/png" href={faviconsImageUrl} sizes={size} />);
  }

  function setAppleLinks(value) {
    const size = value + 'x' + value;
    const faviconsImageUrl = useDefaultFavicon
      ? '/apple-touch-icon' + (value ? '-' + size : '') + '.png'
      : faviconsModule.mainImageUrl + (value ? '?w=' + value + '&h=' + value : '');

    const faviconsPrecomposedImageUrl = useDefaultFavicon
      ? '/apple-touch-icon' + (value ? '-' + size : '') + '-precomposed.png'
      : faviconsModule.mainImageUrl + (value ? '?w=' + value + '&h=' + value : '');

    if (!value) {
      links.push(<link key={value} rel="apple-touch-icon" type="image/png" href={faviconsImageUrl} />);
      links.push(<link key={value} rel="apple-touch-icon-precomposed" type="image/png" href={faviconsPrecomposedImageUrl} />);
    } else {
      links.push(<link key={value} rel="apple-touch-icon" type="image/png" href={faviconsImageUrl} sizes={size} />);
      links.push(<link key={value} rel="apple-touch-icon-precomposed" type="image/png" href={faviconsPrecomposedImageUrl} sizes={size} />);
    }
  }

  sizes.filter(setLinks);
  appleSizes.filter(setAppleLinks);

  return links as LinkProps[];
}
