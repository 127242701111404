import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const ReturnPolicyContainer = styled.div`
  width: 100%;
  margin-top: 80px;
`;

export const ReturnPolicyTitle = styled.h2`
  padding: 0 0 15px 0;
  margin: 0 0 15px 0;
  font-size: 18px;
  font-weight: normal;
  color: ${({ theme }) => theme.bodyFontColor};
  font-family: ${({ theme }) => getCustomStyle('styleModule.headerFontFamily', theme.primaryFontFamily)};
  border-bottom: 1px solid #9b9b9b;
`;

export const ReturnPolicyDescription = styled.p`
  font-size: 14px;
  text-align: justify;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.bodyFontColor};

  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
`;

export const ReturnPolicyLink = styled.a`
  font-size: inherit;
  text-transform: none;

  font-family: ${({ theme }) => getCustomStyle('styleModule.bodyFontFamily', theme.bodyFontFamily)};
`;
