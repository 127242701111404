import React, { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { GoogleMapContainer } from './GoogleMap.styled';
import { DropOffLocationType, LocationInfo } from 'types/return/return-info/Common/drop-off-location-type';
import { buildGoogleMapLink } from 'utils/returns/returnInfo/common/build-google-map-link/build-google-map-link';
import { useIntl } from 'react-intl';
import { Marker } from '@googlemaps/markerclusterer/dist/marker-utils';
import { GOOGLE_MAP_KEY } from 'constants/config';

export const GoogleMaps: React.FC<OwnProps> = (props) => {
  const {
    dropOffLocations,
    userLatitudePosition,
    userLongitudePosition,
    originStreet,
    originCity,
    originState,
    originZipCode,
    selectedDropOffLocation,
    setSelectedDropOffLocation
  } = props;

  const [initMap, setInitMap] = useState<boolean>(false);
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [markerCluster, setMakerCluster] = useState<MarkerClusterer>();
  const [mapInfo, setMapDetails] = useState<google.maps.Map>();
  const [markersInfo, setMarkersDetails] = useState<Marker[]>([]);
  const [currentLocationMarker, setCurrentLocationMarker] = useState<google.maps.Marker>();

  const intl = useIntl();
  const loader = new Loader({
    apiKey: GOOGLE_MAP_KEY ? GOOGLE_MAP_KEY : '',
    version: 'weekly'
  });

  // This function creates the markers to be displayed on google map.
  const getMarkerInfo = (info, infoWindow, lat, lng, AdvancedMarkerElement) => {
    if (info.length > 0) {
      return info.map((position) => {
        const markerImage = document.createElement('img');
        markerImage.src = position.isSelected ? '/assets/img/Icons_map_pin.svg' : '/assets/img/Icons_map_pin_2.svg';
        const marker = new AdvancedMarkerElement({
          position,
          content: markerImage,
          map: mapInfo
        });

        // Create the direction link to be displayed on the popup window of marker on click of it.
        const googleMapUrl = `${buildGoogleMapLink(
          userLatitudePosition,
          userLongitudePosition,
          originStreet,
          originCity,
          originState,
          originZipCode,
          position.address.street,
          position.address.city,
          position.address.state,
          position.address.zipCode
        )}`;

        const directionLinkText = `${intl.formatMessage({ id: 'RETURN.returnInfo.common.dropOffLocationItem.link' })}`;
        const addressInfo = `${position.address.street} ${position.address.city} ${position.address.state} ${position.address.zipCode}`;

        // show marker popup with location info either on click of marker or selected from drop off location list.
        if (position.isSelected) {
          infoWindow.setContent(
            `<b>${position.name}</b><br/><br/>${addressInfo}<br/><a href="${googleMapUrl}" target=_blank>${directionLinkText}</a>`
          );
          infoWindow.open(mapInfo, marker);
        }

        // set the map center & zoom level either on click of marker or selected from drop off location list.
        if (mapInfo) {
          const center = new google.maps.LatLng(lat, lng);
          mapInfo.panTo(center);
          mapInfo.setZoom(13);
        }

        // markers can only be keyboard focusable when they have click listeners
        // open info window when marker is clicked
        marker.addListener('click', () => {
          setSelectedDropOffLocation(position.id);
        });
        return marker;
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    // To load the google map
    loader.load().then(async () => {
      const { Map, InfoWindow } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;

      // One time initialization of google map
      let markerClusterer;
      if (!initMap) {
        const map = new Map(document.getElementById('map') as HTMLElement, {
          zoom: 13,
          center: { lat: userLatitudePosition, lng: userLongitudePosition },
          mapId: 'DEMO_MAP_ID',
          fullscreenControl: false
        });

        markerClusterer = new MarkerClusterer({ map });

        const currentLocation = new google.maps.Marker({
          position: new google.maps.LatLng(userLatitudePosition, userLongitudePosition),
          icon: '/assets/img/user_current_loc.svg',
          map: map
        });

        setCurrentLocationMarker(currentLocation);
        setMakerCluster(markerClusterer);
        setMapDetails(map);
        setInitMap(true);
      }

      const infoWindow = new InfoWindow({
        content: '',
        disableAutoPan: true
      });

      // Markers will be removed each time when one of the marker is selected in order to update the latest images of markers.
      if (markerCluster) {
        markerCluster.removeMarkers(markersInfo, true);
        markerCluster.setMap(null);
      }

      // Old user location will be removed.
      if (currentLocationMarker) {
        currentLocationMarker.setMap(null);
      }

      // Set the user current search location on the google map.
      if (initMap && userLatitudePosition && userLongitudePosition) {
        const currentLocation = new google.maps.Marker({
          position: new google.maps.LatLng(userLatitudePosition, userLongitudePosition),
          icon: '/assets/img/user_current_loc.svg',
          map: mapInfo
        });
        setCurrentLocationMarker(currentLocation);
      }

      if (dropOffLocations.length > 0) {
        let mappedLocations: LocationInfo[] = [];
        mappedLocations = dropOffLocations.map((location) => ({
          id: location.id,
          lat: Number(location.mapLocation.latitude),
          lng: Number(location.mapLocation.longitude),
          name: location.name,
          address: {
            street: location.placeAddress.street,
            city: location.placeAddress.city,
            state: location.placeAddress.state,
            zipCode: location.placeAddress.zipCode
          },
          isSelected: selectedDropOffLocation === location.id ? true : false
        }));

        const selectedPosition = mappedLocations.filter((pos) => pos.id === selectedDropOffLocation);

        // Add some markers to the map.
        let markers = [];
        markers = await getMarkerInfo(mappedLocations, infoWindow, selectedPosition[0].lat, selectedPosition[0].lng, AdvancedMarkerElement);
        setMarkersDetails([]);
        setMarkersDetails(markers);

        if (!markerCluster) {
          markerClusterer.addMarkers(markers, true);
        } else {
          markerCluster.addMarkers(markers, true);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDropOffLocation]);

  return (
    <GoogleMapContainer>
      <div id="map" style={{ height: '100%' }}></div>
    </GoogleMapContainer>
  );
};

export interface OwnProps {
  dropOffLocations: DropOffLocationType[];
  userLatitudePosition: number;
  userLongitudePosition: number;
  originStreet: string | null;
  originCity: string | null;
  originState: string | null;
  originZipCode: string | null;
  selectedDropOffLocation: number | null;
  setSelectedDropOffLocation;
}
