import React, { useContext, useEffect, useState } from 'react';
import useFetch from 'hooks/useFetch/useFetch';
import { SearchOrderForm } from 'components/Returns/SearchOrder/SearchOrderForm/SearchOrderForm';
import { ReturnIcons } from 'components/Returns/SearchOrder/ReturnIcons/ReturnIcons';
import { Error } from 'components/Shared/Error/Error';
import PageTitle from 'components/Shared/PageTitle/PageTitle';
import { Loading } from 'components/Shared/Loading/Loading';
import { SearchOrderApiResponseSuccessType, SearchOrderApiResponseErrorType } from 'types/return/search-order/search-order-api-response-type';
import { ReturnsLayout } from 'layouts/Returns/ReturnsLayout/ReturnsLayout';
import { initGoogleTagManagerDataLayer } from 'utils/common/google-tag-manager/google-tag-manager';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { useIntl } from 'react-intl';

export const SearchOrder: React.FC = () => {
  const intl = useIntl();

  const [formError, setFormError] = useState<string>('');

  const { vanityName, brandName, merchantId, clientReturnType, template, brandId } = useContext(ClientConfigContext);

  const SEARCH_ORDER_CONFIG_API = `/api/search/${vanityName}`;
  const [searchOrderConfig, , error] = useFetch<SearchOrderApiResponseSuccessType, SearchOrderApiResponseErrorType>(SEARCH_ORDER_CONFIG_API);

  useEffect(() => {
    if (searchOrderConfig) {
      return initGoogleTagManagerDataLayer(
        searchOrderConfig.tagManagerModule.deliveryType,
        searchOrderConfig.tagManagerModule.trackingStatus,
        merchantId,
        vanityName,
        null,
        'Return Search',
        'returnPageview',
        clientReturnType === 'integrated' ? 'RMA' : 'Non-integrated',
        template,
        false,
        null,
        brandId
      );
    }
  }, [searchOrderConfig, merchantId, vanityName, clientReturnType, template, brandId]);

  if (error) return <Error />;
  if (!searchOrderConfig) return <Loading />;
  return (
    <ReturnsLayout
      orderNumber={null}
      searchOrderTitle={searchOrderConfig.returnsModule.headerCopy}
      searchOrderSubtitle={searchOrderConfig.returnsModule.subheadCopy}
      policyParagraph={searchOrderConfig.returnsModule.returnsPolicyView.policyParagraph}
      policyParagraphLink={searchOrderConfig.returnsModule.returnsPolicyView.policyParagraphLink}>
      <PageTitle
        title={intl.formatMessage({
          id:
            searchOrderConfig.returnsModule.clientReturnType === 'integrated'
              ? 'RETURN.searchOrder.integrated.page.title'
              : 'RETURN.searchOrder.nonIntegrated.page.title'
        })}
        brandName={brandName}
      />
      <SearchOrderForm
        formError={formError}
        setFormError={setFormError}
        clientReturnType={searchOrderConfig.returnsModule.clientReturnType}
        returnsModule={searchOrderConfig.returnsModule}
      />
      <ReturnIcons />
    </ReturnsLayout>
  );
};
