import React from 'react';
import { CancelPageContainer } from './CancelPickup.styled';
import { CancelPickupSchedule } from 'components/CancelPickup/CancelPickupSchedule/CancelPickupSchedule';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';

export const CancelPickup: React.FC = () => {
  return (
    <CancelPageContainer>
      <SectionLayout backgroundAsColor={false}>
        <CancelPickupSchedule />
      </SectionLayout>
    </CancelPageContainer>
  );
};
