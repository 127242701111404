import { SelectedProducts } from 'context/returns/ProductsSelectionContext';
import { AttributesSelectedOption } from 'types/return/overview/products-selection/products-selection-form/attributes-selected-option';

export const convertAttributesSelectedOptionsObjectsToArray = (
  selectedProducts: SelectedProducts,
  rootProductId: string,
  exchangeOptionIndex: number
): AttributesSelectedOption[] => {
  if (selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex])
    return Object.keys(selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex]).map((keyName: string) => {
      return {
        attributeName: keyName,
        selectedOption: selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex][keyName]
          ? selectedProducts[rootProductId].exchangeOptionsAttributes[exchangeOptionIndex][keyName]
          : null
      };
    });
  return [];
};
