// eslint-disable-next-line @typescript-eslint/no-var-requires
import queryString from 'query-string';

export const buildGetOrderNumberApi = (vanityName: string, trackingNumber: string, urlKey: string, designId: string | null): string => {
  return queryString.stringifyUrl(
    {
      url: '/api/orderNumbers',
      query: {
        vanityName,
        trackingNumber,
        key: urlKey,
        designId
      }
    },
    { skipNull: true, skipEmptyString: true, sort: false }
  );
};
