export const enhanceZipCodeValue = (value: string): string => {
  let modifiedValue: string = '';

  if (value.length > 0) {
    modifiedValue = value.replace(/[-W]/g, '');
  }

  if (modifiedValue.length > 5) {
    const firstPart = modifiedValue.substring(0, 5);
    const secondPart = modifiedValue.substring(5);
    modifiedValue = `${firstPart}-${secondPart}`;
  }

  if (modifiedValue.length > 10) modifiedValue = modifiedValue.substring(0, 10);

  return modifiedValue;
};
