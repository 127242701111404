import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const ReturnsContainer = styled.div`
  max-width: 600px;
  width: 100%;
  font-family: ${getCustomStyle('styleModule.headerFontFamily')};

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      max-width: none;
      padding: ${theme.primaryBackgroundDark ? '20px 0' : '20px'};
      border: ${theme.primaryBackgroundDark ? 'none' : '1px solid #e1e1e1'};
      @media ${theme.xlargeUp} {
        padding: 20px;
      }
    `}
`;

export const ReturnsTitle = styled.h4`
  margin: 0 0 20px 0;
  font-size: 25px;
  font-family: ${getCustomStyle('styleModule.headerFontFamily')};
  font-weight: 400;
  color: black;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      text-align: center;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      color: ${theme.primaryBackgroundDark ? 'white' : 'black'};
    `}
`;

export const ReturnsParagraph = styled.p`
  margin: 0 0 40px 0;
  font-size: 14px;
  font-family: ${getCustomStyle('styleModule.headerFontFamily')};
  font-weight: 400;
  color: black;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      text-align: center;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      color: ${theme.primaryBackgroundDark ? 'white' : 'black'};
    `}
`;

export const ReturnsConfirmButton = styled.button`
  font-size: 14px;
  font-family: ${getCustomStyle('styleModule.headerFontFamily')};
  font-weight: 400;
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  text-decoration: underline;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      text-align: center;
      margin: 0;
      display: block;
      width: 100%;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      text-align: left;
      margin: 0 0 20px 0;
      color: ${theme.primaryBackgroundDark ? 'white' : 'black'};
    `}
`;

export const ReturnsButtonContainer = styled.div`
  display: flex;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      justify-content: center;
    `}
`;
