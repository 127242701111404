import React, { ChangeEvent, FormEvent, PropsWithChildren, useContext } from 'react';
import { ButtonsContainer, USPSFormContainer, USPSFormLegend, USPSFormText } from './USPSForm.styled';
import { packageLocations } from 'constants/package-locations';
import { CustomLabel, SelectPlaceholder } from 'components/Returns/Common/CustomSelectElements/CustomSelectElements.styled';
import { isIE } from 'react-device-detect';
import { Collapse, FormHelperText, FormHelperTextProps, InputAdornment, MenuItem, Select } from '@mui/material';
import { CustomFormControl } from 'components/Shared/styled-components/CustomFormControl/CustomFormControl.styled';
import { ReviewFormFieldset, ReviewFormGroup, ReviewSelect } from 'components/Returns/Overview/Review/ReviewForm/ReviewForm.styled';
import { CustomTextField } from 'components/Shared/styled-components/CustomTextField/CustomTextField.styled';
import { CustomLoadingButton } from 'components/Shared/styled-components/CustomLoadingButton/CustomLoadingButton.styled';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { PickupAddressSummary } from './PickupAddressSummary/PickupAddressSummary';
import { states } from 'constants/states';
import { States } from 'types/return/overview/review/states-types';
import { useIntl } from 'react-intl';
import { PickupContext, USPSFormAlertErrorTitle } from 'context/returns/PickupContext';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import { Alert } from 'components/Shared/Alert/Alert';
import { isAddressValid } from 'utils/shared/address-regex/address-regex';
import { isCityValid } from 'utils/shared/city-regex/city-regex';
import { enhanceZipCodeValue } from 'utils/shared/enhance-zip-code-value/enhance-zip-code-value';
import { isZipCodeValid } from 'utils/shared/zip-code-regex/zip-code-regex';
import { isPackageWeightValid } from 'utils/shared/package-weight-regex/package-weight-regex';
import { isAdditionalInstruction } from 'utils/shared/additional-instructions-regex/additional-instructions';
import { isNumberValid } from 'utils/shared/number-regex/number-regex';
import { setPickupValidateApi } from 'utils/returns/returnInfo/label/set-pickup-validate-api/set-pickup-validate-api';
import { PackageLocationType } from 'types/return/return-info/Label/package-location-type';
import { setPickupScheduleApi } from 'utils/returns/returnInfo/label/set-pickup-schedule-api/set-pickup-schedule-api';
import { PickupAddressPayload, SchedulePickupPayload } from 'models/returns/schedule-pickup-payload/schedule-pickup-payload';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { isDemoLink } from 'utils/returns/returnInfo/label/is-demo-link/is-demo-link';

export const USPSForm: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { handleModalClose, labelId, inputOrderId } = props;

  const {
    showPickupFromAddressForm,
    setShowPickupFromAddressForm,

    addressOne,
    setAddressOne,
    addressOneError,
    setAddressOneError,

    addressTwo,
    setAddressTwo,
    addressTwoError,
    setAddressTwoError,

    city,
    setCity,
    cityError,
    setCityError,

    selectedState,
    setSelectedState,
    selectedStateError,
    setSelectedStateError,

    zipCode,
    setZipCode,
    zipCodeError,
    setZipCodeError,

    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    setPhoneNumberError,

    estimatedWeight,
    setEstimatedWeight,
    estimatedWeightError,
    setEstimatedWeightError,

    packageLocation,
    setPackageLocation,
    packageLocationError,
    setPackageLocationError,

    additionalInstructions,
    setAdditionalInstructions,
    additionalInstructionsError,
    setAdditionalInstructionsError,

    isAdditionalInstructionsRequired,
    setIsAdditionalInstructionsRequired,

    schedulePickupFormError,
    setSchedulePickupFormError,
    schedulePickupLoading,
    setSchedulePickupLoading,
    checkAvailabilityFormError,
    setCheckAvailabilityFormError,
    checkAvailabilityLoading,
    setCheckAvailabilityLoading,
    estimatedPickupDate,
    setEstimatedPickupDate,
    setStep,
    setConfirmedPickupLocation,
    setConfirmationNumber,
    setConfirmedAdditionalInstructions,
    setConfirmedPickupDate,
    setPickupCancelLink
  } = useContext(PickupContext);

  const PICKUP_SCHEDULE_API: string = setPickupScheduleApi(labelId);
  const PICKUP_VALIDATE_API: string = setPickupValidateApi(inputOrderId, {
    address1: addressOne,
    address2: addressTwo,
    city: city,
    postalCode: zipCode,
    stateOrProvince: selectedState
  });

  const { brandId } = useContext(ClientConfigContext);

  const intl = useIntl();

  const handleChangeAddressOne = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setAddressOne(value);
    if (value.length === 0)
      return setAddressOneError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.addressOne.input.error.empty'
        })
      );
    if (!isAddressValid(value))
      return setAddressOneError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.addressOne.input.error.regex'
        })
      );
    return setAddressOneError(null);
  };

  const handleChangeAddressTwo = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setAddressTwo(value);
    if (value.length === 0) return setAddressTwoError(null);
    if (!isAddressValid(value))
      return setAddressTwoError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.addressTwo.input.error.regex'
        })
      );
    return setAddressTwoError(null);
  };

  const handleChangeCity = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setCity(value);
    if (value.length === 0)
      return setCityError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.city.input.error.empty'
        })
      );
    if (!isCityValid(value))
      return setCityError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.city.input.error.regex'
        })
      );
    return setCityError(null);
  };

  const handleChangeState = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { target } = event;
    const { value } = target;
    setSelectedState(value);
    if (value.length === 0)
      return setSelectedStateError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.state.input.error.empty'
        })
      );
    setSelectedStateError(null);
  };

  const handleChangeZipCode = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setZipCode(enhanceZipCodeValue(value));
    if (value.length === 0)
      return setZipCodeError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.zipCode.input.error.empty'
        })
      );
    if (!isZipCodeValid(enhanceZipCodeValue(value)))
      return setZipCodeError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.zipCode.input.error.regex'
        })
      );
    setZipCodeError(null);
  };

  const handleChangePhoneNumber = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setPhoneNumber(value);

    if (value.length === 0)
      return setPhoneNumberError(
        intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.phoneNumber.input.error.empty' })
      );
    if (!isNumberValid(value))
      return setPhoneNumberError(
        intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.phoneNumber.input.error.regex' })
      );
    setPhoneNumberError(null);
  };

  const handleChangeEstimatedWeight = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setEstimatedWeight(value);
    if (value.length === 0)
      return setEstimatedWeightError(
        intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.estimatedWeight.input.error.empty' })
      );
    if (!isPackageWeightValid(value))
      return setEstimatedWeightError(
        intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.estimatedWeight.input.error.regex' })
      );
    return setEstimatedWeightError(null);
  };

  const handleChangePackageLocation = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { target } = event;
    const { value } = target;
    setPackageLocation(value);
    if (value === 'Other') {
      setIsAdditionalInstructionsRequired(true);
    }
    if (value !== 'Other') {
      setIsAdditionalInstructionsRequired(false);
      if (additionalInstructionsError !== 'Enter valid instructions') {
        setAdditionalInstructionsError(null);
      }
    }
    if (value.length === 0)
      return setPackageLocationError(
        intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.packageLocation.input.error.empty' })
      );
    setPackageLocationError(null);
  };

  const handleChangeAdditionalInstructions = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { value } = target;
    setAdditionalInstructions(value);
    if (!isAdditionalInstruction(value))
      return setAdditionalInstructionsError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.additionalInstructions.input.error.regex'
        })
      );
    if (value.length === 0 && isAdditionalInstructionsRequired)
      return setAdditionalInstructionsError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.additionalInstructions.input.error.empty'
        })
      );
    return setAdditionalInstructionsError(null);
  };

  const handleSubmitSchedulePickup = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const errorsArray: string[] = [];

    if (phoneNumber.length === 0) {
      errorsArray.push('0');
      setPhoneNumberError(
        intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.phoneNumber.input.error.empty' })
      );
    }

    if (estimatedWeight.length === 0) {
      errorsArray.push('1');
      setEstimatedWeightError(
        intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.estimatedWeight.input.error.empty' })
      );
    }

    if (packageLocation.length === 0) {
      errorsArray.push('2');
      setPackageLocationError(
        intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.packageLocation.input.error.empty' })
      );
    }

    if (additionalInstructions.length === 0 && isAdditionalInstructionsRequired) {
      errorsArray.push('3');
      setAdditionalInstructionsError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.additionalInstructions.input.error.empty'
        })
      );
    }

    if (errorsArray.length > 0 || phoneNumberError || estimatedWeightError || packageLocationError || additionalInstructionsError) return;

    const pickupAddress: PickupAddressPayload = {
      address1: addressOne,
      address2: addressTwo ? addressTwo : null,
      city: city,
      postalCode: zipCode,
      stateOrProvince: selectedState
    };

    const payload: SchedulePickupPayload = new SchedulePickupPayload(
      !!additionalInstructions ? additionalInstructions : null,
      Number(estimatedWeight),
      packageLocation,
      phoneNumber,
      pickupAddress
    );

    const fetchHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    };

    const params = enhanceFetchParams({
      method: 'POST',
      headers: enhanceHeadersParams(brandId, fetchHeaders),
      body: JSON.stringify(payload)
    });

    try {
      setSchedulePickupLoading(true);

      const response = await fetch(PICKUP_SCHEDULE_API, params);

      const data = await response.json();

      if (data.errorTitle) {
        return setSchedulePickupFormError(data.errorTitle);
      }

      setSchedulePickupFormError(null);

      setConfirmedPickupDate(data.pickupDate);

      setConfirmedPickupLocation(data.pickupAddress);

      setConfirmationNumber(data.confirmationNumber);

      setConfirmedAdditionalInstructions(data.additionalInstructions);

      setPickupCancelLink(data.pickupCancelLink);

      setStep(1);
    } catch (e) {
      return setSchedulePickupFormError(USPSFormAlertErrorTitle.SCHEDULE_PICKUP);
    } finally {
      setSchedulePickupLoading(false);
    }
  };

  const handleSubmitCheckAvailability = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const errorsArray: string[] = [];

    if (addressOne.length === 0) {
      errorsArray.push('0');
      setAddressOneError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.addressOne.input.error.empty'
        })
      );
    }

    if (city.length === 0) {
      errorsArray.push('1');
      setCityError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.city.input.error.empty'
        })
      );
    }

    if (selectedState.length === 0) {
      errorsArray.push('2');
      setSelectedStateError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.state.input.error.empty'
        })
      );
    }

    if (zipCode.length === 0) {
      errorsArray.push('3');
      setZipCodeError(
        intl.formatMessage({
          id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.zipCode.input.error.empty'
        })
      );
    }

    if (errorsArray.length > 0 || addressOneError || addressTwoError || cityError || selectedStateError || zipCodeError) return;

    const fetchHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    };

    const params = enhanceFetchParams({
      method: 'GET',
      headers: enhanceHeadersParams(brandId, fetchHeaders)
    });

    try {
      setCheckAvailabilityLoading(true);
      const response = await fetch(PICKUP_VALIDATE_API, params);

      const data = await response.json();

      if (data.errorTitle) {
        return setCheckAvailabilityFormError(data.errorTitle);
      }

      setShowPickupFromAddressForm(false);
      setCheckAvailabilityFormError(null);
      setEstimatedPickupDate(data.estimatePickupDate);
    } catch (e) {
      return setCheckAvailabilityFormError(USPSFormAlertErrorTitle.CHECK_AVAILABILITY);
    } finally {
      setCheckAvailabilityLoading(false);
    }
  };

  return (
    <USPSFormContainer
      data-test-id="usps-form-container"
      noValidate
      onSubmit={(event: FormEvent<HTMLFormElement>) =>
        showPickupFromAddressForm ? handleSubmitCheckAvailability(event) : handleSubmitSchedulePickup(event)
      }>
      {estimatedPickupDate && (
        <USPSFormText data-test-id="usps-form-text-1">
          {intl.formatMessage(
            { id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.text1' },
            { estimatedPickupDate: estimatedPickupDate }
          )}
        </USPSFormText>
      )}
      {estimatedPickupDate && (
        <USPSFormText data-test-id="usps-form-text-2">
          {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.text2' })}
        </USPSFormText>
      )}
      {showPickupFromAddressForm ? (
        <ReviewFormFieldset margin="0 0 30px 0" lastOfTypeMarginBottom="30px">
          <USPSFormLegend margin="0 0 15px 0">
            {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.text1' })}
          </USPSFormLegend>
          <CustomFormControl marginDefault="0 0 16px 0">
            <CustomLabel data-test-id="usps-form-address-one-label" htmlFor="usps-form-address-one-input">
              {intl.formatMessage({
                id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.addressOne.label'
              })}
            </CustomLabel>
            <CustomTextField
              data-test-id="usps-form-address-one-text-field"
              fullWidth
              size="small"
              id="usps-form-address-one-input"
              required
              error={!!addressOneError}
              data-error-id={!!addressOneError}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeAddressOne(event)}
              value={addressOne}
              helperText={!!addressOneError ? addressOneError : ''}
              variant="outlined"
              inputProps={{ 'data-test-id': 'usps-form-address-one-input' }}
              FormHelperTextProps={{ 'data-test-id': 'usps-form-address-one-input-helper-text' } as FormHelperTextProps}
            />
          </CustomFormControl>
          <CustomFormControl marginDefault="0 0 16px 0">
            <CustomLabel data-test-id="usps-form-address-two-label" htmlFor="usps-form-address-two-input">
              {intl.formatMessage(
                {
                  id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.addressTwo.label'
                },
                { type: <i>(optional)</i> }
              )}
            </CustomLabel>
            <CustomTextField
              data-test-id="usps-form-address-two-text-field"
              fullWidth
              size="small"
              id="usps-form-address-two-input"
              error={!!addressTwoError}
              data-error-id={!!addressTwoError}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeAddressTwo(event)}
              value={addressTwo}
              helperText={!!addressTwoError ? addressTwoError : ''}
              variant="outlined"
              inputProps={{ 'data-test-id': 'usps-form-address-two-input' }}
              FormHelperTextProps={{ 'data-test-id': 'usps-form-address-two-input-helper-text' } as FormHelperTextProps}
            />
          </CustomFormControl>
          <ReviewFormGroup alignItems="flex-start">
            <CustomFormControl width="31.5%">
              <CustomLabel data-test-id="usps-form-city-label" htmlFor="usps-form-city-input">
                {intl.formatMessage({
                  id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.city.label'
                })}
              </CustomLabel>
              <CustomTextField
                data-test-id="usps-form-city-text-field"
                size="small"
                id="usps-form-city-input"
                required
                error={!!cityError}
                data-error-id={!!cityError}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeCity(event)}
                value={city}
                helperText={!!cityError ? cityError : ''}
                variant="outlined"
                inputProps={{ 'data-test-id': 'usps-form-city-input' }}
                FormHelperTextProps={{ 'data-test-id': 'usps-form-city-input-helper-text' } as FormHelperTextProps}
              />
            </CustomFormControl>
            <CustomFormControl
              data-test-id="usps-form-state-form-control"
              variant="outlined"
              width="31.5%"
              size="small"
              data-error-id={!!selectedStateError}
              error={!!selectedStateError}
              required>
              <CustomLabel data-test-id="usps-form-state-label" htmlFor="state">
                {intl.formatMessage({
                  id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.state.label'
                })}
              </CustomLabel>
              <ReviewSelect
                native
                value={selectedState}
                id="state"
                inputProps={{
                  id: 'state',
                  'data-test-id': 'usps-form-state-select'
                }}
                // @ts-ignore
                onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangeState(event)}>
                <option aria-label="None" value="">
                  {intl.formatMessage({
                    id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.state.input.placeholderOption'
                  })}
                </option>
                {states.map((state: States) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </ReviewSelect>
              {!!selectedStateError && (
                <FormHelperText
                  data-test-id="usps-form-state-select-helper-text"
                  id="state"
                  data-gtm-id={!isDemoLink(inputOrderId) ? 'form-input-error' : undefined}
                  style={{ margin: '4px 0 0 4px' }}>
                  {selectedStateError}
                </FormHelperText>
              )}
            </CustomFormControl>
            <CustomFormControl width="31.5%">
              <CustomLabel data-test-id="usps-form-zip-code-label" htmlFor="usps-form-zip-code-input">
                {intl.formatMessage({
                  id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.pickFromAddress.zipCode.label'
                })}
              </CustomLabel>
              <CustomTextField
                data-test-id="usps-form-zip-code-text-field"
                size="small"
                required
                value={zipCode}
                error={!!zipCodeError}
                data-error-id={!!zipCodeError}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeZipCode(event)}
                helperText={!!zipCodeError ? zipCodeError : ''}
                id="usps-form-zip-code-input"
                variant="outlined"
                inputProps={{ 'data-test-id': 'usps-form-zip-code-input' }}
                FormHelperTextProps={{ 'data-test-id': 'usps-form-zip-code-input-helper-text' } as FormHelperTextProps}
              />
            </CustomFormControl>
          </ReviewFormGroup>
        </ReviewFormFieldset>
      ) : (
        <>
          {addressOne && city && selectedState && zipCode && (
            <PickupAddressSummary
              addressOne={addressOne}
              addressTwo={addressTwo}
              city={city}
              state={selectedState}
              zipCode={zipCode}
              inputOrderId={inputOrderId}
            />
          )}
          <ReviewFormFieldset margin="0 0 30px 0" lastOfTypeMarginBottom="30px">
            <CustomFormControl marginDefault="0 0 30px 0">
              <CustomLabel data-test-id="usps-form-phone-number-label" htmlFor="usps-form-phone-number-input">
                {intl.formatMessage({
                  id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.phoneNumber.label'
                })}
              </CustomLabel>
              <CustomTextField
                data-test-id="usps-form-phone-number-text-field"
                size="small"
                id="usps-form-phone-number-input"
                variant="outlined"
                width="50%"
                widthMd="40%"
                error={!!phoneNumberError}
                helperText={!!phoneNumberError ? phoneNumberError : ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangePhoneNumber(event)}
                value={phoneNumber}
                inputProps={{
                  'data-test-id': 'usps-form-phone-number-input'
                }}
                FormHelperTextProps={{ 'data-test-id': 'usps-form-phone-number-input-helper-text' } as FormHelperTextProps}
              />
            </CustomFormControl>
            <CustomFormControl marginDefault="0 0 30px 0">
              <CustomLabel data-test-id="usps-form-estimated-weight-label" htmlFor="usps-form-estimated-weight-input">
                {intl.formatMessage({
                  id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.estimatedWeight.label'
                })}
              </CustomLabel>
              <CustomTextField
                data-test-id="usps-form-estimated-weight-text-field"
                size="small"
                id="usps-form-estimated-weight-input"
                variant="outlined"
                width="50%"
                widthMd="40%"
                isAdornmentExist={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment data-test-id="test-adornment" position="end">
                      {intl.formatMessage({
                        id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.estimatedWeight.input.adornment'
                      })}
                    </InputAdornment>
                  )
                }}
                error={!!estimatedWeightError}
                helperText={!!estimatedWeightError ? estimatedWeightError : ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeEstimatedWeight(event)}
                value={estimatedWeight}
                inputProps={{
                  'data-test-id': 'usps-form-estimated-weight-input'
                }}
                FormHelperTextProps={{ 'data-test-id': 'usps-form-estimated-weight-input-helper-text' } as FormHelperTextProps}
              />
            </CustomFormControl>
            <CustomFormControl
              marginDefault="0 0 30px 0"
              variant="outlined"
              width="100%"
              size="small"
              data-test-id="usps-form-select-package-location-form-control">
              <CustomLabel data-test-id="usps-form-package-location-label" htmlFor="usps-form-select-package-location">
                {intl.formatMessage({
                  id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.packageLocation.label'
                })}
              </CustomLabel>
              {isIE ? (
                <Select
                  id="usps-form-select-package-location"
                  native
                  error={!!packageLocationError}
                  value={packageLocation}
                  // @ts-ignore
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangePackageLocation(event)}
                  required
                  displayEmpty
                  inputProps={{ id: 'usps-form-select-package-location', 'data-test-id': 'usps-form-package-location-select' }}>
                  <option value="">
                    {intl.formatMessage({
                      id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.packageLocation.input.placeholderOption'
                    })}
                  </option>
                  {packageLocations.map((element: PackageLocationType) => (
                    <option key={element.id} value={element.value}>
                      {element.name}
                    </option>
                  ))}
                </Select>
              ) : (
                <Select
                  error={!!packageLocationError}
                  value={packageLocation}
                  // @ts-ignore
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChangePackageLocation(event)}
                  required
                  id="usps-form-select-package-location"
                  displayEmpty
                  inputProps={{ id: 'usps-form-select-package-location', 'data-test-id': 'usps-form-select-package-location' }}>
                  <MenuItem value="">
                    <SelectPlaceholder>
                      {intl.formatMessage({
                        id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.packageLocation.input.placeholderOption'
                      })}
                    </SelectPlaceholder>
                  </MenuItem>
                  {packageLocations.map((element: PackageLocationType) => (
                    <MenuItem key={element.id} value={element.value}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {packageLocationError && (
                <FormHelperText id="usps-form-select-package-location" data-test-id="usps-form-select-package-location-error-validation">
                  {packageLocationError}
                </FormHelperText>
              )}
            </CustomFormControl>
            <CustomFormControl>
              <CustomLabel data-test-id="usps-form-additional-instructions-label" htmlFor="usps-form-additional-instructions-input">
                {intl.formatMessage(
                  {
                    id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.additionalInstructions.label'
                  },
                  { asterisk: isAdditionalInstructionsRequired ? ' *' : '' }
                )}
              </CustomLabel>
              <CustomTextField
                minRows={3}
                multiline
                fullWidth
                required={isAdditionalInstructionsRequired}
                data-test-id="usps-form-additional-instructions-text-field"
                size="small"
                id="usps-form-additional-instructions-input"
                variant="outlined"
                error={!!additionalInstructionsError}
                helperText={!!additionalInstructionsError ? additionalInstructionsError : ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeAdditionalInstructions(event)}
                value={additionalInstructions}
                inputProps={{
                  'data-test-id': 'usps-form-additional-instructions-input'
                }}
                FormHelperTextProps={{ 'data-test-id': 'usps-form-additional-instructions-input-helper-text' } as FormHelperTextProps}
              />
            </CustomFormControl>
          </ReviewFormFieldset>
        </>
      )}
      {showPickupFromAddressForm && checkAvailabilityFormError && (
        <Collapse in={!!checkAvailabilityFormError}>
          <Alert
            gtmId={!isDemoLink(inputOrderId) ? 'usps-pickup-availability-error' : undefined}
            message={checkAvailabilityFormError}
            onClose={() => setCheckAvailabilityFormError(null)}
            marginSm="0 0 30px 0"
          />
        </Collapse>
      )}
      {!showPickupFromAddressForm && schedulePickupFormError && (
        <Collapse in={!!schedulePickupFormError}>
          <Alert
            gtmId={!isDemoLink(inputOrderId) ? 'usps-pickup-schedule-error' : undefined}
            message={schedulePickupFormError}
            onClose={() => setSchedulePickupFormError(null)}
            marginSm="0 0 30px 0"
          />
        </Collapse>
      )}
      <ButtonsContainer>
        {showPickupFromAddressForm ? (
          <CustomLoadingButton
            data-gtm-id={!isDemoLink(inputOrderId) ? 'usps-pickup-check-availability-button' : undefined}
            data-test-id="usps-form-check-availability-button"
            type="submit"
            isUppercase
            loading={checkAvailabilityLoading}
            variant="contained">
            {intl.formatMessage({
              id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.button.submit.checkAvailability'
            })}
          </CustomLoadingButton>
        ) : (
          <CustomLoadingButton
            data-gtm-id={!isDemoLink(inputOrderId) ? 'usps-pickup-schedule-pickup-button' : undefined}
            isUppercase
            data-test-id="usps-form-schedule-pickup-button"
            type="submit"
            loading={schedulePickupLoading}
            variant="contained">
            {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.button.submit.schedulePickup' })}
          </CustomLoadingButton>
        )}
        <CustomButton
          data-gtm-id={!isDemoLink(inputOrderId) ? 'usps-pickup-cancel-button' : undefined}
          data-test-id="usps-form-cancel-button"
          onClick={handleModalClose}
          style={{ marginLeft: '15px' }}
          variant="text"
          isUppercase>
          {intl.formatMessage({ id: 'RETURN.returnInfo.label.labelInfo.pickUp.modal.schedulePickupSteps.uspsForm.button.cancel' })}
        </CustomButton>
      </ButtonsContainer>
    </USPSFormContainer>
  );
};

export interface OwnProps {
  handleModalClose: () => void;
  labelId: string;
  inputOrderId: string;
}
