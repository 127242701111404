import React from 'react';
import { InfoItemContainer, InfoItemIcon, InfoItemIconWrapper, InfoItemLink, InfoItemText, InfoItemWrapper } from './InfoItem.styled';

export enum InfoTypeEnum {
  ORDER_NUMBER = 'ORDER_NUMBER',
  TRACKING_NUMBER = 'TRACKING_NUMBER',
  SHIPPING_TYPE = 'SHIPPING_TYPE'
}

export const InfoItem: React.FC<InfoItemProps> = (props) => {
  const { infoName, infoUrl, info, infoType, infoIconUrl, infoIconAlt, ariaLabel } = props;

  return (
    <InfoItemContainer data-test-id="info-item-container" aria-label={ariaLabel}>
      <InfoItemText data-test-id="info-item-info-name" bold={infoType === InfoTypeEnum.TRACKING_NUMBER}>
        {infoName}
      </InfoItemText>
      {infoType === InfoTypeEnum.ORDER_NUMBER && (
        <InfoItemLink href={infoUrl} target="_blank" data-test-id="info-item-info" data-gtm-id="order-number-link">
          <InfoItemText infoType={infoType}>{info}</InfoItemText>
        </InfoItemLink>
      )}

      {infoType === InfoTypeEnum.TRACKING_NUMBER && infoUrl && (
        <InfoItemLink target="_blank" data-gtm-id="carrier-tracking-link" data-test-id="info-item-info" href={infoUrl}>
          {infoIconUrl && (
            <InfoItemIconWrapper>
              <InfoItemIcon src={infoIconUrl} data-test-id="info-item-info-icon" alt={`${infoIconAlt} logo`} />
            </InfoItemIconWrapper>
          )}
          <InfoItemText infoType={infoType}>{info}</InfoItemText>
        </InfoItemLink>
      )}

      {infoType === InfoTypeEnum.TRACKING_NUMBER && !infoUrl && (
        <InfoItemWrapper data-test-id="info-item-info">
          {infoIconUrl && (
            <InfoItemIconWrapper>
              <InfoItemIcon src={infoIconUrl} data-test-id="info-item-info-icon" alt={`${infoIconAlt} logo`} />
            </InfoItemIconWrapper>
          )}
          <InfoItemText infoType={infoType}>{info}</InfoItemText>
        </InfoItemWrapper>
      )}
      {infoType === InfoTypeEnum.SHIPPING_TYPE && <InfoItemText data-test-id="info-item-info">{info}</InfoItemText>}
    </InfoItemContainer>
  );
};

export interface InfoItemProps {
  infoName: string;
  infoUrl?: string;
  info: string;
  infoIconUrl?: string;
  infoType: infoType;
  infoIconAlt: string;
  ariaLabel: string;
}

export type infoType = 'TRACKING_NUMBER' | 'DESTINATION' | 'SHIPPING_TYPE' | 'ORDER_NUMBER';
