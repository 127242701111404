import { RootProductAttribute } from 'types/return/overview/overview-api-response-type';
import { ConvertedAttribute } from 'types/return/overview/products-selection/products-selection-form/converted-attribute-type';

export type OptionType = null | 'return' | 'exchange';

export type RootProductAttributeView = Omit<RootProductAttribute, 'attributeNameOrder'>;

export interface ExchangeProductType {
  sku: string;
  quantity: number;
  exchangeProductImageUrl: string | null;
  name: string;
  attributes: ConvertedAttribute[];
}
export type ExchangeProducts = Record<number, ExchangeProductType>;

export type ExchangeProductReviewType = Omit<ExchangeProductType, 'attributes'>;
export type ExchangeProductsReviewType = Record<number, ExchangeProductReviewType>;

export interface TransformedExchangeItem extends ExchangeProductReviewType {
  id: string;
  attributes: RootProductAttributeView[];
}

export type KeyValuePairAttributes = Record<string, string>;
export type ExchangeOptionsAttributes = Record<number, KeyValuePairAttributes>;

export class SelectedProduct {
  isChecked: boolean;
  option: OptionType;
  rootProductImageUrl: string | null;
  rootProductName: string;
  rootProductAttributes: RootProductAttributeView[];
  sku: string;
  id: string;
  orderItemId: string;
  quantity: number;
  reason: string;
  comment: string;
  exchangeOptionsAttributes: ExchangeOptionsAttributes;
  exchangeProducts: ExchangeProducts;
  hazmat?: boolean | undefined;
  hazmatType?: string | undefined;
  hazmatTypeSame?: boolean;
  carrier?: string | undefined;
  constructor(
    option: OptionType,
    rootProductImageUrl: string | null,
    rootProductName: string,
    rootProductAttributes: RootProductAttributeView[],
    sku: string,
    id: string,
    orderItemId: string,
    quantity: number,
    reason: string = '',
    comment: string = '',
    exchangeOptionsAttributes: ExchangeOptionsAttributes,
    exchangeProducts: ExchangeProducts,
    hazmat?: boolean,
    hazmatType?: string,
    hazmatTypeSame?: boolean,
    carrier?: string
  ) {
    this.isChecked = true;
    this.option = option;
    this.rootProductImageUrl = rootProductImageUrl;
    this.rootProductName = rootProductName;
    this.rootProductAttributes = rootProductAttributes;
    this.sku = sku;
    this.id = id;
    this.orderItemId = orderItemId;
    this.quantity = quantity;
    this.reason = reason;
    this.comment = comment;
    this.exchangeOptionsAttributes = exchangeOptionsAttributes;
    this.exchangeProducts = exchangeProducts;
    this.hazmat = hazmat;
    this.hazmatType = hazmatType;
    this.hazmatTypeSame = hazmatTypeSame;
    this.carrier = carrier;
  }
}
