import React, { useContext } from 'react';
import { Container, SwitchLabel, SwitchElementOff, SwitchElementOn } from './PTSSwitcher.styled';
import { useIntl } from 'react-intl';
import Switch from 'react-switch';
import { PtsSwitchContext } from 'context/customer-service/PtsSwitchContext';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';

export const PTSSwitcher: React.FC = () => {
  const { styleModule } = useContext(ClientConfigContext);
  const { consumerViewOnly, setConsumerViewOnly } = useContext(PtsSwitchContext);
  const intl = useIntl();

  const handleChangeConsumerViewOnly = () => {
    setConsumerViewOnly((prevState) => !prevState);
    window.dataLayer.push({
      event: 'clickCustomerViewOnlySwitch',
      customerViewOnlyValue: !consumerViewOnly ? 'on' : 'off'
    });
  };

  return (
    <Container data-test-id="cs-parcels-list-search-by-tracking-number-pts-switcher">
      <SwitchLabel data-test-id="cs-parcels-list-search-by-tracking-number-pts-switcher-label" htmlFor="pts-switch">
        {intl.formatMessage({ id: 'CS.ptsSwitcher.label' })}
      </SwitchLabel>
      <Switch
        data-test-id="cs-parcels-list-search-by-tracking-number-pts-switcher-"
        width={55}
        height={22}
        id="pts-switch"
        onColor={styleModule.secondaryColor}
        offColor="#d8dae4"
        uncheckedIcon={<SwitchElementOff>OFF</SwitchElementOff>}
        checkedIcon={<SwitchElementOn>ON</SwitchElementOn>}
        checked={consumerViewOnly}
        onChange={handleChangeConsumerViewOnly}
      />
    </Container>
  );
};
