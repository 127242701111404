import React, { PropsWithChildren } from 'react';
import { QRCodeContentContainer, QRCodeImage, QRCodeContentWrapper } from './QRCodeContent.styled';

export const QrCodeContent: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { qrCode } = props;

  return (
    <QRCodeContentContainer>
      <QRCodeContentWrapper data-test-id="qr-code-content-wrapper">
        <QRCodeImage src={qrCode} data-gtm-id="qr-code-image" alt="qr-code-info" />
      </QRCodeContentWrapper>
    </QRCodeContentContainer>
  );
};

export interface OwnProps {
  qrCode: string;
}
