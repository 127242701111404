import { ParcelStatusEnum } from 'types/customer-service/find-parcels-api-response-type';
import { ParcelsViewEnum } from 'types/customer-service/parcels-view-enum';

export type FunctionReturnType = 'CS.parcels.estimatedDeliveryTime.prefix.normal' | 'CS.parcels.estimatedDeliveryTime.prefix.exception';

export const resolveEDDTimePreposition = (parcelStatus: ParcelStatusEnum, parcelView: ParcelsViewEnum): FunctionReturnType => {
  switch (parcelStatus) {
    case ParcelStatusEnum.PREPARING:
    case ParcelStatusEnum.IN_TRANSIT:
    case ParcelStatusEnum.OUT_FOR_DELIVERY:
    case ParcelStatusEnum.RETURN_STARTED:
    case ParcelStatusEnum.RETURN_IN_TRANSIT:
    case ParcelStatusEnum.RETURN_OUT_FOR_DELIVERY:
    case ParcelStatusEnum.FALLBACK:
      return 'CS.parcels.estimatedDeliveryTime.prefix.normal';
    case ParcelStatusEnum.DELIVERED:
    case ParcelStatusEnum.RETURN_RECEIVED:
      if (parcelView === ParcelsViewEnum.Tile) return 'CS.parcels.estimatedDeliveryTime.prefix.exception';
      return 'CS.parcels.estimatedDeliveryTime.prefix.normal';
    default:
      return 'CS.parcels.estimatedDeliveryTime.prefix.normal';
  }
};
