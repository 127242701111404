import React from 'react';
import { ParcelStatusEnum } from 'types/customer-service/find-parcels-api-response-type';
import { StatusBarContainer, StepsWrapper, FirstStep, SecondStep, ThirdStep } from './StatusBar.styled';

export const StatusBar: React.FC<OwnProps> = (props) => {
  const { parcelStatus } = props;

  return (
    <StatusBarContainer>
      <StepsWrapper data-test-id="status-bar">
        <FirstStep data-gtm-id="progress-bar" parcelStatus={parcelStatus} data-test-id="first-step" />
        <SecondStep data-gtm-id="progress-bar" parcelStatus={parcelStatus} data-test-id="second-step" />
        <ThirdStep data-gtm-id="progress-bar" parcelStatus={parcelStatus} data-test-id="third-step" />
      </StepsWrapper>
    </StatusBarContainer>
  );
};

export interface OwnProps {
  parcelStatus: ParcelStatusEnum;
}
