import React, { Dispatch, PropsWithChildren, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { MethodEnum, ShippingTypeEnum } from 'types/return/overview/methods/method-types';
import { OrderItemType } from 'types/return/overview/overview-api-response-type';
import { ProductsSelectionContext } from './ProductsSelectionContext';
import { OrderItemsContext } from './OrderItemsContext';

export const MethodsContext = React.createContext<MethodsContextType>({
  method: '',
  setMethod: () => {},
  shippingType: '',
  setShippingType: () => {},
  serviceLevelShouldDisplayOnlyExpedited: false,
  allItemsHaveShippingTypeNull: false
});

export const MethodsContextProvider: React.FC<MethodsProviderType> = (props: PropsWithChildren<MethodsProviderType>) => {
  const { children } = props;

  const [method, setMethod] = useState<MethodEnum | ''>('');
  const [shippingType, setShippingType] = useState<ShippingTypeEnum | ''>('');

  const { orderItems } = useContext(OrderItemsContext);
  const { selectedProducts } = useContext(ProductsSelectionContext);
  const [serviceLevelShouldDisplayOnlyExpedited, setServiceLevelShouldDisplayOnlyExpedited] = useState<boolean>(false);
  const [allItemsHaveShippingTypeNull, setAllItemsHaveShippingTypeNull] = useState<boolean>(false);

  useEffect(() => {
    const selectedProductsWithShippingType: OrderItemType[] = [];

    const selectedProductsIds = Object.keys(selectedProducts);

    selectedProductsIds.forEach((selectedItemId: string) => {
      const found = orderItems.find(
        (orderItem: OrderItemType) =>
          orderItem.id === selectedItemId && selectedProducts[selectedItemId].isChecked && !!selectedProducts[selectedItemId].option
      );

      if (found) selectedProductsWithShippingType.push(found);
    });

    setAllItemsHaveShippingTypeNull(selectedProductsWithShippingType.every((element: OrderItemType) => element.shippingType === null));
    return setServiceLevelShouldDisplayOnlyExpedited(
      selectedProductsWithShippingType.some((element: OrderItemType) => element.shippingType === 'expedited')
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts]);

  const value = useMemo<MethodsContextType>(
    () => ({
      method,
      setMethod,
      shippingType,
      setShippingType,
      serviceLevelShouldDisplayOnlyExpedited,
      allItemsHaveShippingTypeNull
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [method, shippingType, serviceLevelShouldDisplayOnlyExpedited, allItemsHaveShippingTypeNull]
  );

  return <MethodsContext.Provider value={value}>{children}</MethodsContext.Provider>;
};

export interface MethodsContextType {
  method: MethodEnum | '';
  setMethod: Dispatch<SetStateAction<MethodEnum | ''>>;
  shippingType: ShippingTypeEnum | '';
  setShippingType: Dispatch<SetStateAction<ShippingTypeEnum | ''>>;
  serviceLevelShouldDisplayOnlyExpedited: boolean;
  allItemsHaveShippingTypeNull: boolean;
}

interface MethodsProviderType {
  children: ReactNode;
}
