import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { ParcelsViewEnum } from 'types/customer-service/parcels-view-enum';
import { PTSSwitcher } from './PTSSwitcher/PTSSwitcher';
import { ViewSwitcher } from './ViewSwitcher/ViewSwitcher';
import { Container, Heading, HeadingTitle, Switchers } from './TrackingNumberParcelsList.styled';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { shouldDisplayTileView } from 'utils/customer-service/should-display-tile-view/should-display-tile-view';
import { TileView } from '../Common/TileView/TileView';
import { TableView } from './TableView/TableView';
import { AuthUserContext } from 'context/customer-service/AuthUserContext';
import { SearchBy } from 'types/customer-service/search-by-type';

export const TrackingNumberParcelsList: React.FC<OwnProps> = (props) => {
  const { parcels } = props;

  const { isUserAuth } = useContext(AuthUserContext);

  const intl = useIntl();
  const theme = useTheme();
  const largeUp = useMediaQuery(theme.breakpoints.up('large'));

  const [activeParcelsView, setActiveParcelsView] = useState<ParcelsViewEnum>(ParcelsViewEnum.Tile);

  useEffect(() => {
    if (parcels.length > 1) return setActiveParcelsView(ParcelsViewEnum.Table);
  }, [parcels]);

  return (
    <Container data-test-id="cs-parcels-list-search-by-tracking-number">
      <Heading>
        <HeadingTitle data-test-id="cs-search-by-tracking-number-parcels-list-heading">
          {intl.formatMessage({ id: 'CS.parcels.parcelsList.trackingNumber.heading' }, { numberOfTrackingNumbers: parcels.length })}
        </HeadingTitle>
        <Switchers data-test-id="cs-parcels-list-search-by-tracking-number-switchers">
          {isUserAuth && <PTSSwitcher />}
          {largeUp && <ViewSwitcher setActiveView={setActiveParcelsView} activeView={activeParcelsView} />}
        </Switchers>
      </Heading>
      {shouldDisplayTileView(largeUp, activeParcelsView) ? (
        <TileView parcels={parcels} searchBy={SearchBy.TRACKING_NUMBER} />
      ) : (
        <TableView parcels={parcels} />
      )}
    </Container>
  );
};

export interface OwnProps {
  parcels: Parcel[];
}
