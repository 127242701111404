import React, { PropsWithChildren } from 'react';
import { QRCodeImage, QRCodeContentWrapper } from './QRCodeContent.styled';

export const QrCodeContent: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { qrCode } = props;

  return (
    <QRCodeContentWrapper data-test-id="qr-code-content-wrapper">
      <QRCodeImage src={qrCode} data-gtm-id="qr-code-image" alt="qr-code-info" />
    </QRCodeContentWrapper>
  );
};

export interface OwnProps {
  qrCode: string;
}
