import TagManager, { DataLayerArgs, TagManagerArgs } from 'react-gtm-module';
import { TemplateType } from 'types/shared/template-type';

// we received in response unreadable data this fn is responsible for resolving data and return proper value.
export const resolveGoogleTagManagerArgs = (tagManagerId: string, tagManagerEnvironment: string): TagManagerArgs => {
  const [auth, preview] = tagManagerEnvironment.split('&');
  const [, authValue] = auth.split('=');
  const [, previewValue] = preview.split('=');

  return {
    gtmId: tagManagerId,
    auth: authValue,
    preview: previewValue
  };
};

export const initGoogleTagManager = (tagManagerId: string, tagManagerEnvironment: string): void => {
  const tagManagerArgs: TagManagerArgs = resolveGoogleTagManagerArgs(tagManagerId, tagManagerEnvironment);

  TagManager.initialize(tagManagerArgs);
};

// template value should be transformed
export const resolveGoogleTagManagerDataLayerArgs = (
  deliveryType: string | null,
  trackingStatus: string | null,
  merchantId: string,
  vanityName: string,
  carrierCode: string | null,
  eventCategory: string,
  event: string,
  returnType: 'RMA' | 'Non-integrated',
  template: TemplateType,
  authenticated: boolean,
  eddDisplay: string | null,
  brandId: string
): DataLayerArgs => {
  return {
    dataLayer: {
      event: event,
      eventCategory: eventCategory,
      merchantId: merchantId,
      merchantName: vanityName,
      deliveryType: deliveryType,
      trackingStatus: trackingStatus,
      carrierCode: carrierCode,
      returnType: returnType,
      authenticated: authenticated,
      template: template.charAt(0).toUpperCase() + template.slice(1),
      eddDisplay: eddDisplay,
      brandId: brandId
    }
  };
};

export const initGoogleTagManagerDataLayer = (
  deliveryType: string | null,
  trackingStatus: string | null,
  merchantId: string,
  vanityName: string,
  carrierCode: string | null,
  eventCategory: string,
  event: string,
  returnType: 'RMA' | 'Non-integrated',
  template: TemplateType,
  authenticated: boolean,
  eddDisplay: string | null,
  brandId: string
): void => {
  window.dataLayerVariables = {
    merchantId: merchantId,
    merchantName: vanityName,
    deliveryType: deliveryType,
    eventCategory: eventCategory
  };

  const tagManagerDataLayerArgs: DataLayerArgs = resolveGoogleTagManagerDataLayerArgs(
    deliveryType,
    trackingStatus,
    merchantId,
    vanityName,
    carrierCode,
    eventCategory,
    event,
    returnType,
    template,
    authenticated,
    eddDisplay,
    brandId
  );

  TagManager.dataLayer(tagManagerDataLayerArgs);
};
