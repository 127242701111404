import styled from '@emotion/styled';

export const CharacterCounterContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CharacterCounterText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: gray;
`;

export const CharacterCounterMaxValue = styled.span``;

export const CharacterCounterValue = styled.span``;
