import { Parcel, ParcelTypeEnum } from 'types/customer-service/find-parcels-api-response-type';
import { SortedParcelsByType } from 'types/customer-service/sorted-parcels-by-type';

export const sortParcelsByType = (parcels: Parcel[]): SortedParcelsByType => {
  const sortedDeliveryParcels = parcels.filter((parcel: Parcel) => parcel.parcelDetails.parcelType === ParcelTypeEnum.DELIVERY);
  const sortedReturnParcels = parcels.filter((parcel: Parcel) => parcel.parcelDetails.parcelType === ParcelTypeEnum.RETURN);
  const sortedUnknownParcels = parcels.filter((parcel: Parcel) => parcel.parcelDetails.parcelType === ParcelTypeEnum.UNKNOWN);

  return {
    deliveryParcels: sortedDeliveryParcels,
    returnParcels: sortedReturnParcels,
    unknownParcels: sortedUnknownParcels
  };
};
