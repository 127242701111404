import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const CheckboxContainer = styled.div<{
  marginDefault?: string;
  marginMd?: string;
  marginL?: string;
  marginXl?: string;
  marginXXl?: string;
}>`
  margin: ${({ marginDefault }) => (marginDefault ? '20px' : 0)};

  @media ${({ theme }) => theme.mediumUp} {
    ${({ marginMd }) =>
      marginMd &&
      css`
        margin: ${marginMd};
      `}
  }

  @media ${({ theme }) => theme.largeUp} {
    ${({ marginL }) =>
      marginL &&
      css`
        margin: ${marginL};
      `}
  }

  @media ${({ theme }) => theme.xlargeUp} {
    ${({ marginXl }) =>
      marginXl &&
      css`
        margin: ${marginXl};
      `}
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    ${({ marginXXl }) =>
      marginXXl &&
      css`
        margin: ${marginXXl};
      `}
  }
`;

export const StyledCheckbox = styled.input<{ color?: string }>`
  appearance: none;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: white;
  border: 1px solid ${({ color }) => (color ? color : 'black')};
  border-radius: 2px;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 8px;
    height: 14px;
    border-bottom: 2px solid ${({ color }) => (color ? color : 'black')};
    border-right: 2px solid ${({ color }) => (color ? color : 'black')};
    transform: translate(-50%, -50%) rotate(45deg);
    transform-origin: 50% 50%;
    opacity: 0;
  }

  &:checked {
    &::before {
      opacity: 1;
    }
  }
`;
