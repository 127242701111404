import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';
/* stylelint-disable declaration-block-no-duplicate-properties */
export const LeadContainer = styled.div<{ template: string }>`
  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'vivid' &&
    css`
      width: 100%;
      max-width: 740px;
      padding-top: 40px;

      @media ${theme.largeUp} {
        padding-top: 107px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'spartan' &&
    css`
      width: 100%;
    `}
`;

export const PrimaryTextContainer = styled.div<{ template: string }>`
  ${({ template, theme }) =>
    template === TemplateEnum.VIVID &&
    css`
      width: 100%;
      background: rgba(47, 47, 47, 0.5);
      border-radius: 20px;
      display: flex;
      justify-content: center;
      padding: 40px 20px;

      @media ${theme.largeUp} {
        padding: 50px 70px;
      }
    `}
`;

export const PrimaryText = styled.h1<{
  backgroundAsColor?: boolean;
  backgroundDark?: boolean;
  template: string;
}>`
  font-family: ${({ theme, template }) =>
    template === TemplateEnum.VIVID && getCustomStyleProperty(theme, 'styleModule.headerFontFamily')
      ? getCustomStyle('styleModule.headerFontFamily')
      : theme.bodyFontFamily};
  font-family: ${({ template }) => template === TemplateEnum.SPARTAN && getCustomStyle('styleModule.headerFontFamily')};

  ${({ template, theme }) =>
    template === TemplateEnum.VIVID &&
    css`
      font-size: 29px;
      text-align: center;
      color: ${theme.white};

      @media ${theme.largeUp} {
        font-size: 40px;
      }
    `}

  ${({ template, theme }) =>
    template === TemplateEnum.SPARTAN &&
    css`
      font-size: 29px;
      font-weight: normal;
      margin: 0 0 50px 0;

      @media ${theme.largeUp} {
        font-size: 32px;
      }
    `}
`;

export const GiftContainer = styled.div`
  width: 64%;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 100%;
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 100% !important;
  }
`;
export const GiftIcon = styled.div`
  width: 8%;
  float: left;
  margin-top: 28px;
  text-align: center;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 18%;
    text-align: center;
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
    width: 18%;
    text-align: center;
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
    width: 18%;
    text-align: center;
  }
`;

export const GiftbannerHeaderdiv = styled.div`
  width: 70%;
  float: left;
  margin: 10px 0px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 67%;
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
    width: 67%;
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
    width: 67%;
  }
`;
export const GiftbannerHeader = styled.h1`
  font-size: 19px;
  margin-bottom: 0px;
  color: #4e4e4e;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
    font-size: 14px;
  }
`;
export const GiftbannerText = styled.p`
  font-size: 14px;
  margin-top: 4px;
  color: #4e4e4e;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const StartedLinkdiv = styled.div`
  width: 15%;
  float: left;
  margin: 10px 0px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
  }
`;

export const StartedLink = styled.div`
  font-size: 14px;
  display: block;
  margin: 20px 0px;
  border: 1px solid grey;
  padding: 7px 0px;
  border-radius: 20px;
  background: grey;
  color: #fff;
  text-align: center;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 100% !important;
    display: none;
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
    width: 100% !important;
    display: none;
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
    width: 100% !important;
    display: none;
  }
`;
export const StartedIcon = styled.div`
  display: none;
  margin: 20px 0px;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    display: block;
    text-align: center;
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
    display: block;
    text-align: center;
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
    display: block;
    text-align: center;
  }
`;

export const LeadContainer2 = styled.div<{ template: string }>`
  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'vivid' &&
    css`
      width: 30%;
      max-width: 740px;
      padding-top: 40px;

      @media ${theme.largeUp} {
        padding: 20px 0px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'spartan' &&
    css`
      width: 100%;
    `}
`;

export const PrimaryTextContainer1 = styled.div<{ template: string }>`
  ${({ template, theme }) =>
    template === TemplateEnum.VIVID &&
    css`
      width: 60%;
      display: inline-block;
      justify-content: center;
      padding: 0px;
      float: left;

      @media ${theme.largeUp} {
        padding: 0px 70px;
        padding-left: 180px;
        padding-right: 0px;
      }
    `}
`;

export const PrimaryTextContainer2 = styled.div<{ template: string }>`
  ${({ template, theme }) =>
    template === TemplateEnum.VIVID &&
    css`
      width: 40%;
      display: inline-block;
      justify-content: center;
      padding: 0px;

      @media ${theme.largeUp} {
        padding: 0px 70px;
      }
    `}
`;

export const PrimaryText1 = styled.h1<{
  backgroundAsColor?: boolean;
  backgroundDark?: boolean;
  template: string;
}>`
  font-family: ${({ theme, template }) =>
    template === TemplateEnum.VIVID && getCustomStyleProperty(theme, 'styleModule.headerFontFamily')
      ? getCustomStyle('styleModule.headerFontFamily')
      : theme.bodyFontFamily};
  font-family: ${({ template }) => template === TemplateEnum.SPARTAN && getCustomStyle('styleModule.headerFontFamily')};

  ${({ template, theme }) =>
    template === TemplateEnum.VIVID &&
    css`
      font-size: 29px;
      color: ${theme.white};

      @media ${theme.largeUp} {
        font-size: 40px;
      }
    `}

  ${({ template, theme }) =>
    template === TemplateEnum.SPARTAN &&
    css`
      font-size: 29px;
      font-weight: normal;
      margin: 0 0 50px 0;

      @media ${theme.largeUp} {
        font-size: 32px;
      }
    `}
`;

export const PrimaryText2 = styled.p<{
  backgroundAsColor?: boolean;
  backgroundDark?: boolean;
  template: string;
}>`
  font-family: ${({ theme, template }) =>
    template === TemplateEnum.VIVID && getCustomStyleProperty(theme, 'styleModule.headerFontFamily')
      ? getCustomStyle('styleModule.headerFontFamily')
      : theme.bodyFontFamily};
  font-family: ${({ template }) => template === TemplateEnum.SPARTAN && getCustomStyle('styleModule.headerFontFamily')};

  ${({ template, theme }) =>
    template === TemplateEnum.VIVID &&
    css`
      font-size: 16px;
      color: ${theme.white};
      line-height: 23px;
      padding-bottom: 10px;

      @media ${theme.largeUp} {
        font-size: 16px;
      }
    `}

  ${({ template, theme }) =>
    template === TemplateEnum.SPARTAN &&
    css`
      font-size: 29px;
      font-weight: normal;
      margin: 0 0 50px 0;

      @media ${theme.largeUp} {
        font-size: 32px;
      }
    `}
`;

export const PrimaryText3 = styled.p<{
  backgroundAsColor?: boolean;
  backgroundDark?: boolean;
  template: string;
}>`
  font-family: ${({ theme, template }) =>
    template === TemplateEnum.VIVID && getCustomStyleProperty(theme, 'styleModule.headerFontFamily')
      ? getCustomStyle('styleModule.headerFontFamily')
      : theme.bodyFontFamily};
  font-family: ${({ template }) => template === TemplateEnum.SPARTAN && getCustomStyle('styleModule.headerFontFamily')};

  ${({ template, theme }) =>
    template === TemplateEnum.VIVID &&
    css`
      font-size: 16px;
      color: ${theme.white};
      line-height: 0px;
      padding-bottom: 0px;
      margin: 0px;

      @media ${theme.largeUp} {
        font-size: 16px;
      }
    `}

  ${({ template, theme }) =>
    template === TemplateEnum.SPARTAN &&
    css`
      font-size: 29px;
      font-weight: normal;
      margin: 0 0 50px 0;

      @media ${theme.largeUp} {
        font-size: 32px;
      }
    `}
`;

export const LeadContentWrapper = styled.div<{ template: string }>`
  background: ${({ template }) => template === TemplateEnum.VIVID && getCustomStyle('styleModule.primaryColor', '#ec9b23')};
  background: ${({ template }) => template === TemplateEnum.SPARTAN && '#f8f8f8'};

  ${({ template, theme }) =>
    template === TemplateEnum.VIVID &&
    css`
      margin-top: 20px;
      width: 100%;
      padding: 20px 10px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      @media ${theme.largeUp} {
        padding: 20px 33px;
      }
    `}

  ${({ template, theme }) =>
    template === TemplateEnum.SPARTAN &&
    css`
      margin: 0 0 5px 0;
      padding: 20px;
      @media ${theme.largeUp} {
        padding: 18px;
      }
    `}
`;

export const SecondaryText = styled.h2`
  font-family: ${({ theme }) =>
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    getCustomStyle('styleModule.headerFontFamily', theme.primaryFontFamily)};
  color: ${({ theme }) =>
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark')
      ? theme.lightBackgroundColor
      : theme.darkBackgroundColor};

  font-family: ${({ theme }) =>
    theme.pageType === PageTypeEnum.TrackingPage && theme.template === TemplateEnum.SPARTAN && getCustomStyle('styleModule.headerFontFamily')};
  color: ${({ theme }) => theme.pageType === PageTypeEnum.TrackingPage && theme.template === TemplateEnum.SPARTAN && '#383838'};

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'vivid' &&
    css`
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1.1px;
      font-size: 18px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'spartan' &&
    css`
      text-align: left;
      margin: 0;
      padding: 0;
      font-weight: normal;
      font-size: 22px;

      @media ${theme.largeUp} {
        font-size: 24px;
      }
    `}
`;
export const DeliveryDate = styled.p`
  color: ${({ theme }) =>
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    getCustomStyleProperty(theme, 'styleModule.primaryBackgroundDark')
      ? theme.white
      : theme.darkBackgroundColor};

  color: ${({ theme }) =>
    theme.pageType === PageTypeEnum.TrackingPage && theme.template === TemplateEnum.SPARTAN && getCustomStyle('styleModule.tertiaryColor')};

  font-family: ${({ theme }) =>
    theme.pageType === PageTypeEnum.TrackingPage && theme.template === TemplateEnum.SPARTAN && getCustomStyle('styleModule.headerFontFamily')};

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      margin: 10px 0 0 0;
      padding: 0;
      text-align: center;
      font-size: 24px;
      font-weight: bold;

      @media ${theme.largeUp} {
        font-size: 26px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      margin: 10px 0 0 0;
      font-weight: bold;
      line-height: 37px;
      font-size: 26px;

      @media ${theme.largeUp} {
        font-size: 36px;
      }
    `}
`;

export const EDDTime = styled.span`
  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      display: block;
      margin: 10px 0 0;
      font-size: 12px;

      @media ${theme.mediumUp} {
        display: inline-block;
        margin: 0 0 0 10px;
      }

      @media ${theme.largeUp} {
        font-size: 14px;
      }
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      display: block;
      font-size: 12px;

      @media ${theme.mediumUp} {
        display: inline-block;
        margin: 0 0 0 10px;
      }

      @media ${theme.largeUp} {
        font-size: 18px;
      }
    `}
`;
