import React, { PropsWithChildren } from 'react';
import {
  InstructionsListItemContainer,
  InstructionStepCounter,
  InstructionTitle,
  InstructionWrapper,
  PostOfficeList,
  PostOfficeListCustomItem,
  PostOfficeListCustomItemLink
} from './InstructionsListItem.styled';
import { PostOfficeListItem } from './PostOfficeListItem/PostOfficeListItem';
import { useIntl } from 'react-intl';
import { CarrierSuggestion } from 'types/return/return-info/return-info-api-response-type';

export const InstructionsListItem: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { title, dataTestId, postOffice, stepCounter } = props;

  const intl = useIntl();

  return (
    <InstructionsListItemContainer data-test-id={dataTestId}>
      <InstructionWrapper>
        <InstructionStepCounter data-test-id={`${dataTestId}-step-counter`}>{stepCounter}</InstructionStepCounter>
        <InstructionTitle data-test-id={`${dataTestId}-title`}>{title}</InstructionTitle>
      </InstructionWrapper>
      {postOffice.length > 0 && (
        <PostOfficeList>
          {postOffice.map((element: CarrierSuggestion, index: number) => (
            <PostOfficeListItem key={index} address={element.address} carrierFacilityHours={element.carrierFacilityHours} />
          ))}
          <PostOfficeListCustomItem key={postOffice.length}>
            <PostOfficeListCustomItemLink
              href="https://tools.usps.com/find-location.htm"
              data-test-id="returns-qr-instruction-link"
              data-gtm-id="find-more-locations">
              {intl.formatMessage({ id: 'RETURN.returnInfo.qrCode.qrCodeInfo.postOffice.findLocation.link' })}
            </PostOfficeListCustomItemLink>
          </PostOfficeListCustomItem>
        </PostOfficeList>
      )}
      {postOffice.length === 0 && dataTestId === 'returns-qr-instruction-2' && (
        <PostOfficeList>
          <PostOfficeListCustomItem key={1}>
            <PostOfficeListCustomItemLink
              href="https://tools.usps.com/find-location.htm"
              data-test-id="returns-qr-instruction-link"
              data-gtm-id="find-more-locations">
              {intl.formatMessage({ id: 'RETURN.returnInfo.qrCode.qrCodeInfo.postOffice.findLocation.link' })}
            </PostOfficeListCustomItemLink>
          </PostOfficeListCustomItem>
        </PostOfficeList>
      )}
    </InstructionsListItemContainer>
  );
};

export interface OwnProps {
  title: string;
  dataTestId: string;
  stepCounter: number;
  postOffice: CarrierSuggestion[];
}
