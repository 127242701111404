import React, { FormEvent, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { ButtonsContainer, ButtonsWrapper, ProductsSelectionFormContainer, ProductsList, ProductsWrapper } from './ProductsSelectionForm.styled';
import { Product } from './Product/Product';
import { OrderItemType, OverviewFormStepsEnum } from 'types/return/overview/overview-api-response-type';
import { SubpageHeading } from 'components/Returns/Common/SubpageHeading/SubpageHeading';
import { useIntl } from 'react-intl';
import { OverviewReturnsModuleType } from 'types/return/overview/returns-module/overview-returns-module-type';
import { isUserAuthenticated } from 'utils/shared/is-user-authenticated/is-user-authenticated';
import { CustomButton } from 'components/Shared/styled-components/CustomButton/CustomButton.styled';
import { createSkipReturnLabelLink } from 'utils/returns/overview/products-selection/create-skip-return-label-link/create-skip-return-label-link';
import { buildSearchUrl } from 'utils/returns/overview/common/build-search-url/build-search-url';
import { SelectedProductsErrors, ProductsSelectionContext } from 'context/returns/ProductsSelectionContext';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { OverviewContext } from 'context/returns/OverviewContext';
import { isInputValid } from 'utils/shared/basic-input-regex/basic-input-regex';
import { scrollToError } from 'utils/returns/overview/common/scroll-to-error/scroll-to-error';
import { resolveReasonsWithRequiredComment } from 'utils/returns/overview/products-selection/resolve-reasons-with-required-comment/resolve-reasons-with-required-comment';
import { whetherAnySelectedProductHasBothOptionSelectedAndIsChecked } from 'utils/returns/overview/products-selection/whether-any-selected-product-has-both-option-selected-and-is-checked/whether-any-selected-product-has-both-option-selected-and-is-checked';
import { isItemChecked } from 'utils/returns/overview/common/is-item-checked/is-item-checked';
import { isItemOptionSelected } from 'utils/returns/overview/common/is-item-option-selected/is-item-option-selected';
import { isItemReturnQuantityValid } from 'utils/returns/overview/products-selection/is-item-return-quantity-valid/is-item-return-quantity-valid';
import { isItemReasonEmpty } from 'utils/returns/overview/products-selection/is-item-reason-empty/is-item-reason-empty';
import { isItemCommentEmpty } from 'utils/returns/overview/products-selection/is-item-comment-empty/is-item-comment-empty';

export const ProductsSelectionForm: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const {
    eligibleOrderItems,
    nonEligibleOrderItems,
    returnsModule,
    urlKey,
    orderNumber,
    shippingPostalCode,
    shouldDisplayImageEligible,
    shouldDisplayImageNonEligible
  } = props;
  const { returnsReasonViews, reasonRequired, reasonsEnabled, exchanges } = returnsModule;
  const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState<boolean>(false);
  // add loading field for exchanges options loading into context
  // continue button should also base on it and be disabled if at least one element is loading

  const intl = useIntl();
  const { selectedProducts, selectedProductsErrors, setSelectedProductsErrors } = useContext(ProductsSelectionContext);
  const { vanityName } = useContext(ClientConfigContext);
  const { isFromSearchOrder, setStep } = useContext(OverviewContext);

  useEffect(() => {
    if (
      eligibleOrderItems.length > 1 &&
      Object.keys(selectedProducts).length >= 1 &&
      Object.keys(selectedProducts).filter((id) => selectedProducts[id].isChecked === true).length >= 1
    ) {
      Object.keys(selectedProducts).forEach((id: string) => {
        const index = eligibleOrderItems.findIndex((element) => element.id == id && selectedProducts[id].isChecked === true);
        if (index !== -1) {
          if (eligibleOrderItems[index]?.hazmat === true) {
            const hazmatTypeToCheck = eligibleOrderItems[index]?.hazmatType;

            eligibleOrderItems.map(function (item) {
              if ((item?.hazmat === true && item?.hazmatType?.toLowerCase() === hazmatTypeToCheck?.toLowerCase()) || item?.hazmat === false) {
                item.hazmatTypeSame = true;
                selectedProducts[id].hazmatTypeSame = true;
              } else {
                item.hazmatTypeSame = false;
                selectedProducts[id].hazmatTypeSame = true;
              }
            });
          }
        }
      });
    }
    // if all items got deselected go to the innitial stage
    else if (eligibleOrderItems.length > 1 && Object.keys(selectedProducts).filter((id) => selectedProducts[id].isChecked === true).length == 0) {
      eligibleOrderItems.map(function (item) {
        item.hazmatTypeSame = undefined;
      });
      /*Object.keys(selectedProducts).forEach((id: string) => {
        //selectedProducts[id].hazmatTypeSame = undefined;
      });*/
    }
    setIsContinueButtonEnabled(whetherAnySelectedProductHasBothOptionSelectedAndIsChecked(selectedProducts));
  }, [selectedProducts, eligibleOrderItems]);

  const [reasonsWithRequiredComment] = useState<string[]>(resolveReasonsWithRequiredComment(returnsReasonViews));

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const errorsArray: string[] = [];

    const selectedProductsErrorsKeysArray = Object.keys(selectedProductsErrors);

    const instantSubmitFormValidation = async () => {
      selectedProductsErrorsKeysArray.forEach((id: string) => {
        if (isItemChecked(selectedProducts[id]) && isItemOptionSelected(selectedProducts[id]) && !isItemReturnQuantityValid(selectedProducts[id]))
          errorsArray.push(intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.quantity.select.error.empty' }));
        if (
          isItemChecked(selectedProducts[id]) &&
          isItemOptionSelected(selectedProducts[id]) &&
          reasonsEnabled &&
          isItemReasonEmpty(selectedProducts[id]) &&
          reasonRequired
        )
          errorsArray.push(intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reason.select.error.empty' }));
        if (
          isItemChecked(selectedProducts[id]) &&
          isItemOptionSelected(selectedProducts[id]) &&
          reasonsEnabled &&
          reasonsWithRequiredComment.includes(selectedProducts[id].reason) &&
          isItemCommentEmpty(selectedProducts[id])
        )
          errorsArray.push(
            intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reasonComment.input.error.empty' })
          );
        if (
          isItemChecked(selectedProducts[id]) &&
          isItemOptionSelected(selectedProducts[id]) &&
          !isItemCommentEmpty(selectedProducts[id]) &&
          !isInputValid(selectedProducts[id].comment)
        )
          errorsArray.push(
            intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reasonComment.input.error.regex' })
          );

        setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
          return {
            ...prevState,
            [id]: {
              ...prevState[id],
              selectedQuantityError:
                isItemChecked(selectedProducts[id]) && isItemOptionSelected(selectedProducts[id]) && !isItemReturnQuantityValid(selectedProducts[id])
                  ? intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.quantity.select.error.empty' })
                  : null,
              reasonError:
                isItemChecked(selectedProducts[id]) &&
                isItemOptionSelected(selectedProducts[id]) &&
                reasonsEnabled &&
                isItemReasonEmpty(selectedProducts[id]) &&
                reasonRequired
                  ? intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reason.select.error.empty' })
                  : null,
              reasonCommentError:
                isItemChecked(selectedProducts[id]) &&
                isItemOptionSelected(selectedProducts[id]) &&
                reasonsEnabled &&
                reasonsWithRequiredComment.includes(selectedProducts[id].reason) &&
                isItemCommentEmpty(selectedProducts[id])
                  ? intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reasonComment.input.error.empty' })
                  : !isItemCommentEmpty(selectedProducts[id])
                  ? !isInputValid(selectedProducts[id].comment)
                    ? intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.product.reasonComment.input.error.regex' })
                    : null
                  : null
            }
          };
        });

        const exchangeOptionsIdsArray = Object.keys(selectedProducts[id].exchangeOptionsAttributes);

        exchangeOptionsIdsArray.forEach((option_id: string) => {
          const exchangeOptionsAttributesKeysArray = Object.keys(selectedProducts[id].exchangeOptionsAttributes[option_id]);

          if (
            isItemChecked(selectedProducts[id]) &&
            selectedProducts[id].option === 'exchange' &&
            selectedProductsErrors[id].exchangeOptionsAttributesFetchDataError[option_id] &&
            selectedProductsErrors[id].exchangeOptionsAttributesFetchDataError[option_id].error
          )
            errorsArray.push(`Fetch Data Error`);

          exchangeOptionsAttributesKeysArray.forEach((attribute: string) => {
            if (
              isItemChecked(selectedProducts[id]) &&
              selectedProducts[id].option === 'exchange' &&
              selectedProducts[id].exchangeOptionsAttributes[option_id][attribute] === ''
            )
              errorsArray.push(
                intl.formatMessage(
                  {
                    id:
                      'RETURN.overview.productsSelection.productsSelectionForm.product.exchangeOptionsList.exchangeOptionsListItem.attributesList.attributesListItem.attribute.select.error.empty'
                  },
                  { attribute: attribute }
                )
              );

            setSelectedProductsErrors((prevState: SelectedProductsErrors) => {
              return {
                ...prevState,
                [id]: {
                  ...prevState[id],
                  exchangeOptionsAttributesErrors: {
                    ...prevState[id].exchangeOptionsAttributesErrors,
                    [option_id]: {
                      ...prevState[id].exchangeOptionsAttributesErrors[option_id],
                      [attribute]:
                        isItemChecked(selectedProducts[id]) &&
                        selectedProducts[id].option === 'exchange' &&
                        selectedProducts[id].exchangeOptionsAttributes[option_id][attribute] === ''
                          ? intl.formatMessage(
                              {
                                id:
                                  'RETURN.overview.productsSelection.productsSelectionForm.product.exchangeOptionsList.exchangeOptionsListItem.attributesList.attributesListItem.attribute.select.error.empty'
                              },
                              { attribute: attribute }
                            )
                          : null
                    }
                  }
                }
              };
            });
          });
        });
      });
    };

    await instantSubmitFormValidation();

    if (errorsArray.length > 0) {
      return scrollToError();
    }
    setStep(OverviewFormStepsEnum.Two);
  };

  return (
    <ProductsSelectionFormContainer
      data-test-id="products-selection-form"
      noValidate
      onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event)}>
      {eligibleOrderItems.length > 0 && (
        <ProductsWrapper>
          <SubpageHeading
            dataTestId="products-selection-form__eligible-items-title"
            titleId="eligible-items-heading"
            title={
              exchanges.enabled
                ? intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.subtitle.eligible.exchangesEnabled' })
                : intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.subtitle.eligible.exchangesDisabled' })
            }
          />
          <ProductsList aria-labelledby="eligible-items-heading" data-test-id="products-selection-form__eligible_items-list">
            {eligibleOrderItems.map((product: OrderItemType) => (
              <Product
                shouldDisplayRootProductImage={shouldDisplayImageEligible}
                areExchangesEnabled={exchanges.enabled}
                reasonsWithRequiredComment={reasonsWithRequiredComment}
                returnsReasonViews={returnsReasonViews}
                reasonRequired={reasonRequired}
                reasonsEnabled={reasonsEnabled}
                urlKey={urlKey}
                orderNumber={orderNumber}
                shippingPostalCode={shippingPostalCode}
                key={product.id}
                product={product}
              />
            ))}
          </ProductsList>
        </ProductsWrapper>
      )}

      {nonEligibleOrderItems.length > 0 && (
        <ProductsWrapper>
          <SubpageHeading
            dataTestId="products-selection-form__not-eligible-items-title"
            titleId="not-eligible-items-heading"
            title={
              exchanges.enabled
                ? intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.subtitle.notEligible.exchangesEnabled' })
                : intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.subtitle.notEligible.exchangesDisabled' })
            }
          />
          <ProductsList aria-labelledby="not-eligible-items-heading" data-test-id="products-selection-form__not-eligible_items-list">
            {nonEligibleOrderItems.map((product: OrderItemType) => (
              <Product
                shouldDisplayRootProductImage={shouldDisplayImageNonEligible}
                areExchangesEnabled={exchanges.enabled}
                reasonsWithRequiredComment={reasonsWithRequiredComment}
                returnsReasonViews={returnsReasonViews}
                reasonRequired={reasonRequired}
                reasonsEnabled={reasonsEnabled}
                urlKey={urlKey}
                orderNumber={orderNumber}
                shippingPostalCode={shippingPostalCode}
                key={product.id}
                product={product}
              />
            ))}
          </ProductsList>
        </ProductsWrapper>
      )}
      <ButtonsContainer isAuth={isUserAuthenticated(urlKey)}>
        {isUserAuthenticated(urlKey) && (
          <CustomButton
            width="100%"
            widthMd="auto"
            size="large"
            marginDefault="20px 0 0 0"
            marginMd="0"
            href={createSkipReturnLabelLink(vanityName, orderNumber, urlKey)}
            isUppercase
            data-gtm-id="returns-item-selection-skip"
            data-test-id="products-selection-form__skip-item-selection-link"
            variant="text">
            {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.button.skip' })}
          </CustomButton>
        )}

        <ButtonsWrapper isAuth={isUserAuthenticated(urlKey)}>
          {isFromSearchOrder && (
            <CustomButton
              width="100%"
              widthMd="auto"
              size="large"
              marginDefault="20px 0 0 0"
              marginMd="0"
              href={buildSearchUrl(vanityName)}
              isUppercase
              data-gtm-id="returns-overview-item-selection-back"
              data-test-id="products-selection-form__back-link"
              variant="text">
              {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.button.back' })}
            </CustomButton>
          )}

          <CustomButton
            className="continuebutton"
            size="large"
            width="100%"
            widthMd="auto"
            marginDefault="0"
            marginMd="0 0 0 30px"
            disabled={!isContinueButtonEnabled}
            isUppercase
            data-gtm-id="returns-overview-item-selection-continue"
            data-test-id="products-selection-form__continue-button"
            type="submit"
            variant="contained">
            {intl.formatMessage({ id: 'RETURN.overview.productsSelection.productsSelectionForm.button.continue' })}
          </CustomButton>
        </ButtonsWrapper>
      </ButtonsContainer>
    </ProductsSelectionFormContainer>
  );
};

export interface OwnProps {
  eligibleOrderItems: OrderItemType[];
  shouldDisplayImageEligible: boolean;
  shouldDisplayImageNonEligible: boolean;
  nonEligibleOrderItems: OrderItemType[];
  returnsModule: OverviewReturnsModuleType;
  urlKey: string;
  orderNumber: string;
  shippingPostalCode: string;
}
