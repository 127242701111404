import styled from '@emotion/styled';
import { Document } from 'react-pdf';

export const LabelPDFContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.returnsBorderColor};
  width: 100%;

  @media ${({ theme }) => theme.mediumUp} {
    width: 44%;
  }

  @media ${({ theme }) => theme.largeUp} {
    width: 44%;
  }

  @media ${({ theme }) => theme.xlargeUp} {
    width: 51%;
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    width: 54%;
  }
`;

export const StyledDocument = styled(Document)`
  .react-pdf {
    &__Page {
      &__canvas {
        width: 100% !important;
        height: auto !important;
      }

      &__textContent {
        display: none;
      }
    }
  }
`;

export const IEPDFSupport = styled.embed`
  width: 100%;
  height: 600px;
`;

export const NewgisticsContainer = styled.div`
  width: 100%;
`;

export const NewgisticsImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const NewgisticsImage = styled.img`
  width: 100%;
`;

export const NewgisticsInstructionsContainer = styled.div`
  padding: 0 20px 20px 20px;
`;

export const NewgisticsInstructionsParagraph = styled.p`
  margin: 0 0 20px 0;
  font-size: 15px;

  &:last-of-type {
    margin: 0;
  }
`;

export const StepListTitle = styled.h5`
  margin: 0 0 20px 0;
  font-size: 15px;
`;

export const StepList = styled.ul`
  list-style: decimal;
  padding-left: 20px;
  margin-bottom: 30px;
`;

export const StepListItem = styled.li`
  margin-bottom: 5px;
  font-size: 14px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
