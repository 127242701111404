export const resolveAppErrorMessage = (status: number | 400 | 404 | 500 | 503): string => {
  switch (status) {
    case 400:
      return 'APP.appErrorLoader.appError.error.400.message';
    case 404:
      return 'APP.appErrorLoader.appError.error.404.message';
    case 503:
      return 'APP.appErrorLoader.appError.error.503.message';
    default:
      return 'APP.appErrorLoader.appError.error.500.message';
  }
};
