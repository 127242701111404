import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { darken } from 'polished';
import { getCustomStyle, getCustomStyleProperty } from 'utils/common/custom-styles/custom-styles';
import { getCustomRoundStyle } from 'components/Shared/Button/Button.styled';
import { TemplateEnum } from 'types/shared/template-type';
import { PageTypeEnum } from 'types/app/page-type-enum';
/* stylelint-disable declaration-block-no-duplicate-properties */
export const TrackingContainer = styled.div`
  width: 100%;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'vivid' &&
    css`
      max-width: 740px;
      box-shadow: 0 1px 4px 0 rgba(181, 181, 181, 0.29);
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    `}
`;

export const TrackingEventsList = styled.ul`
  width: 100%;
  transition: height 1s ease;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'vivid' &&
    css`
      background: ${theme.white};
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === 'spartan' &&
    css`
      background: #f8f8f8;
    `}
`;

export const TrackingEventsButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      padding: 15px;
      background: ${theme.white};
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      padding: 15px 30px;
      background: #f8f8f8;
    `}
`;
export const TrackingEventsButton = styled.button`
  border: none;
  background: transparent;
  transition: text 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  border-radius: ${getCustomRoundStyle()};
  color: ${getCustomStyle('styleModule.secondaryColor')};

  &:hover {
    color: ${({ theme }) => theme.template === TemplateEnum.VIVID && darken(0.1, getCustomStyleProperty(theme, 'styleModule.secondaryColor'))};
    text-decoration: underline;
  }

  background-color: ${({ theme }) => theme.template === TemplateEnum.SPARTAN && getCustomStyle('styleModule.secondaryColor', theme.buttonBackground)};
  color: ${({ theme }) =>
    theme.template === TemplateEnum.SPARTAN &&
    (getCustomStyleProperty(theme, 'styleModule.secondaryBackgroundDark') ? theme.white : theme.darkBackgroundColor)};

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.VIVID &&
    css`
      max-width: 300px;
      padding: 0 30px;
    `}

  ${({ theme }) =>
    theme.pageType &&
    theme.pageType === PageTypeEnum.TrackingPage &&
    theme.template === TemplateEnum.SPARTAN &&
    css`
      font-weight: bold;
      max-width: 600px;
      width: 100%;
      padding: 12px 0;

      @media ${theme.xlargeUp} {
        color: #383838;
        border-radius: 0;
        background-color: transparent;
        max-width: 300px;
      }

      i {
        display: none;
        @media ${theme.xlargeUp} {
          display: inline-block;
          padding-left: 10px;
        }
      }
    `}
`;
