import styled from '@emotion/styled';

export const PolicyMain = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

export const PolicyTitle = styled.h1`
  width: 100%;
  color: #b70077;
  font-size: 32px;
  font-weight: 300;
  text-align: left;
  font-family: 'Precision Sans W', sans-serif;
`;

export const PolicySubtitle = styled.h2`
  width: 100%;
  color: #4e4e4e;
  font-size: 16px;
  text-align: left;
  margin: 5px 0 20px;
  font-weight: 100;
  font-family: 'Precision Sans W', sans-serif;
`;

export const HeadingBox = styled.div`
  width: 100%;
`;
