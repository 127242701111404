import React from 'react';
import { TrackingNumberLink } from './TrackingNumberCellLink.styled';
import { EllipseColumnText } from '../EllipseColumnText/EllipseColumnText';
import { EllipseText } from '../EllipseColumnText/EllipseColumnText.styled';
import { CustomTableCell } from 'components/Shared/styled-components/CustomTableCell/CustomTableCell.styled';

export const TrackingNumberCellLink = React.forwardRef((props: OwnProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
  const { text, href } = props;

  if (href)
    return (
      <CustomTableCell
        paddingDefault="19px 10px 10px 5px"
        widthDefault="25%"
        widthXL="20%"
        fontSizeDefault="12px"
        fontSizeXL="14px"
        aria-label="tracking-number-cell"
        shouldOneLineDisplay>
        <TrackingNumberLink
          data-test-id="tracking-number-link"
          data-gtm-id="table-tracking-number-link"
          href={href}
          target="_blank"
          rel="noreferrer"
          ref={ref}>
          <EllipseText isTextCut={true} isUppercase={false}>
            {text.slice(0, text.length - 4)}
          </EllipseText>
          <EllipseText isTextCut={true} isUppercase={false}>
            {text.slice(-4)}
          </EllipseText>
        </TrackingNumberLink>
      </CustomTableCell>
    );

  return <EllipseColumnText text={text} numberOfSuffixChar={4} ariaLabel="tracking-number-cell" isUppercase={false} />;
});

TrackingNumberCellLink.displayName = 'TrackingNumberCellLink';

export interface OwnProps {
  text: string;
  href: string | null;
}
