import { createTheme, themes } from 'constants/theme';
import { PageTypeEnum } from 'types/app/page-type-enum';
import { ThemeProps } from 'types/modules';
import { StyleModuleType } from 'types/shared/config/styleModule/style-module-type';
import { TemplateType } from 'types/shared/template-type';

export const resolveTheme = (customStyles: undefined | StyleModuleType, template: undefined | TemplateType, pageType: PageTypeEnum): ThemeProps => {
  if (customStyles && template) return createTheme({ ...themes.default, ...themes[template], pageType: pageType, template: template }, customStyles);
  if (customStyles) return createTheme({ ...themes.default, ...{ pageType: pageType } }, customStyles);
  return createTheme({ ...themes.default, ...{ pageType: pageType } });
};
