import React from 'react';
import { Container, IconBox, MessageContent } from './PickupStatus.styled';
import { PickupStatusEnum } from 'types/cancel-pickup/pickup-status-enum';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { useIntl } from 'react-intl';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBMiniIconsEnum } from 'constants/PBIconsEnums';

export const PickupStatus: React.FC<OwnProps> = (props) => {
  const { pickupMessage } = props;

  const intl = useIntl();

  return (
    <Container>
      <IconBox
        data-test-id="pickup-status__icon-box"
        type={pickupMessage === 'CANCEL.pickup.success' ? PickupStatusEnum.Success : PickupStatusEnum.Failed}>
        <PBIcon
          data-test-id="pickup-status__icon"
          type={PBIconTypeEnum.Mini}
          name={pickupMessage === 'CANCEL.pickup.success' ? PBMiniIconsEnum.CheckCircleOpen : PBMiniIconsEnum.AlertCircleOpen}
        />
      </IconBox>
      <MessageContent
        data-test-id="cancel-pickup-message"
        data-gtm-id={pickupMessage === 'CANCEL.pickup.success' ? 'cancel-pickup-success-message' : 'cancel-pickup-failure-message'}>
        {intl.formatMessage({ id: pickupMessage })}
      </MessageContent>
    </Container>
  );
};

export interface OwnProps {
  pickupMessage: string;
}
