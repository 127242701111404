import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { alertType } from 'components/Shared/Alert/Alert';

export const AlertContainer = styled.div<{
  width?: string;
  type?: alertType;
  maxWidth?: string;
  localTheme?: string;
  marginSm?: string;
  marginMd?: string;
  marginL?: string;
  marginXl?: string;
  marginXXl?: string;
  closeButtonExist: boolean;
}>`
  margin: ${({ marginSm }) => (marginSm ? marginSm : '30px 0')};
  padding: ${({ closeButtonExist }) => (closeButtonExist ? '17px 44px 17px 17px' : '17px')};
  display: flex;
  border-radius: 5px;
  font-weight: normal;
  align-items: center;
  border: 1px solid #d30c0c;
  background-color: #fdf2f2;
  width: ${({ width }) => (width ? width : '100%')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'none')};
  position: relative;

  @media ${({ theme }) => theme.mediumUp} {
    ${({ marginMd }) =>
      marginMd &&
      css`
        margin: ${marginMd};
      `}
  }

  @media ${({ theme }) => theme.largeUp} {
    ${({ marginL }) =>
      marginL &&
      css`
        margin: ${marginL};
      `}
  }

  @media ${({ theme }) => theme.xlargeUp} {
    ${({ marginXl }) =>
      marginXl &&
      css`
        margin: ${marginXl};
      `}
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    ${({ marginXXl }) =>
      marginXXl &&
      css`
        margin: ${marginXXl};
      `}
  }

  ${({ type }) =>
    type === alertType.ERROR &&
    css`
      border: 1px solid #d30c0c;
      background-color: #fdf2f2;
    `}

  ${({ type }) =>
    type === alertType.SUCCESS &&
    css`
      border: 1px solid #007d00;
      background-color: #f2f9f2;
      padding: 15px;
    `}

  ${({ type }) =>
    type === alertType.INFO &&
    css`
      border: 1px solid #0072b8;
      background-color: #f4f9fd;
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

export const AlertCloseIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  i {
    font-weight: 300;
    font-size: 16px;
  }
`;

export const AlertIconContainer = styled.div<{ width?: string; localTheme?: string; iconSize?: string; type?: alertType }>`
  align-self: stretch;
  align-items: center;
  display: flex;

  i {
    text-align: center;
    width: 40px;
    color: #dd1010;

    ${({ type }) =>
      type === alertType.ERROR &&
      css`
        color: #dd1010;
      `}

    ${({ type }) =>
      type === alertType.SUCCESS &&
      css`
        color: #009966;
      `}

    ${({ type }) =>
      type === alertType.INFO &&
      css`
        color: #0072b8;
      `}
    
    font-size: ${({ iconSize }) => (iconSize ? iconSize : '22px')};

    @media ${({ theme }) => theme.largeUp} {
      font-size: ${({ iconSize }) => (iconSize ? iconSize : '30px')};
    }
  }
`;

export const AlertMessagesListContainer = styled.div`
  padding-left: 15px;
`;

export const AlertMessagesListTitle = styled.h4`
  font-size: 14px;
  margin: 0;
  font-weight: normal;
  color: #383838;
`;

export const AlertMessageTitleText = styled.span<{ type?: alertType }>`
  font-size: 15px;
  color: ${({ type }) => (type === alertType.SUCCESS ? '#717171' : '#000000')};
`;

export const AlertMessageTitleLink = styled.a`
  color: #0470b1;
  font-size: 14px;

  &:link,
  &:hover,
  &:active,
  &:visited {
    color: #0470b1;
  }
`;

export const AlertMessageTitleButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #0068a8;
  font-size: 14px;
  display: inline;
  padding: 0;
  margin: 0;

  &:link,
  &:active,
  &:visited {
    color: #0068a8;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const AlertMessagesList = styled.ul`
  font-size: 14px;
  font-weight: normal;
  list-style: disc;
  padding-left: 17px;
  margin: 14px 0 0 0;
`;

export const AlertMessagesListItem = styled.li`
  font-weight: lighter;
  margin-bottom: 7px;

  &:last-child {
    margin-bottom: 0;
  }
`;
