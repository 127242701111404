import { get, isEmpty } from 'lodash';
import { FunctionInterpolation } from '@emotion/react';
import { MainTheme } from 'types/modules';

export function getCustomStyle(stylePath: string, defaultValue?: string): FunctionInterpolation<{ theme: MainTheme }> {
  return ({ theme }) => {
    const styleProperty = get(theme.customStyles, stylePath);

    if (!isEmpty(styleProperty)) {
      return styleProperty;
    }

    if (!isEmpty(defaultValue)) {
      return defaultValue;
    }

    return '';
  };
}

export const getCustomStyleProperty = (theme: MainTheme, stylePath: string): string => get(theme.customStyles, stylePath);
