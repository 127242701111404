import { darken, transparentize } from 'polished';
import { CustomTheme } from 'types/modules';

// fonts
const primaryFont = `"Playfair Display", sans-serif`;
const secondaryFont = `"Roboto", sans-serif`;

//Default colors
const white = '#fff';
const black = '#000';
const gray = '#7e7e7e';
const viking = '#6bb6d9';
const mineShaft = '#383838';
const brownGrey = '#a6a6a6';
const brownishGrey = '#6b6b6b';
const oceanGreen = '#50b67b';
const oceanBlue = '#0068A8';

//Message colors
const errorColor = '#DD1010';
const errorTextColor = mineShaft;
const errorBackground = '#FDF2F2';
const errorBorder = '#DD1010';

const infoColor = '#0470b1';
const infoTextColor = mineShaft;
const infoBackground = '#F2F8FC';
const infoBorder = '#0470b1';

const successColor = '#009966';
const successTextColor = mineShaft;
const successBackground = '#F2F9F2';
const successBorder = '#009966';

const warningColor = '#E9AD7C';
const warningTextColor = mineShaft;
const warningBackground = '#FCF6F2';
const warningBorder = '#E9AD7C';

const returnsBorderColor = '#D7D7D7';

export const theme: CustomTheme = {
  //Template name (vivid|spartan|linear|boolean)
  template: false,

  //Color
  white: white,
  black: black,
  gray: gray,
  primaryColor: viking,
  secondaryColor: oceanGreen,
  tertiaryColor: oceanBlue,

  //Body
  bodyFontFamily: secondaryFont,
  bodyFontColor: mineShaft,
  bodyBackgroundColor: white,

  //Fonts
  primaryFontFamily: primaryFont,
  secondaryFontFamily: secondaryFont,

  //Anchor
  anchorColor: oceanBlue,
  anchorColorFocus: darken(0.1, oceanBlue),

  //Forms
  formLabelColor: brownishGrey,
  formPlaceholderColor: brownGrey,
  formInputFontFamily: secondaryFont,
  formInputColor: brownGrey,
  formInputColorFocus: brownishGrey,
  formBorderColor: brownGrey,
  formBorderColorFocus: brownishGrey,

  //Button
  buttonBackground: viking,
  buttonColor: white,
  buttonColorDark: transparentize(0.5, black), //not-valid

  //Layout
  layoutMaxWidth: 992,

  //Messages
  errorColor: errorColor,
  errorTextColor: errorTextColor,
  errorBackground: errorBackground,
  errorBorder: errorBorder,

  warningColor: warningColor,
  warningTextColor: warningTextColor,
  warningBackground: warningBackground,
  warningBorder: warningBorder,

  successColor: successColor,
  successTextColor: successTextColor,
  successBackground: successBackground,
  successBorder: successBorder,

  infoColor: infoColor,
  infoTextColor: infoTextColor,
  infoBackground: infoBackground,
  infoBorder: infoBorder,

  //Shadows
  shadowHeader: `0px -5px 15px 10px ${transparentize(0.25, black)}`,
  shadowFooter: `inset 0px 3px 3px ${transparentize(0.1, black)}`,
  shadowNav: `0 5px 10px -5px ${transparentize(0.25, black)}`,

  //Misc
  darkBackgroundColor: 'rgba(0, 0, 0, .54)', //not-valid
  lightBackgroundColor: 'rgba(255, 255, 255, .7)', //not-valid

  // returns page
  returnsBorderColor: returnsBorderColor,

  // customer-service page
  searchBarBackgroundColor: '#ffffff'
};
