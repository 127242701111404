import React, { useContext } from 'react';
import useFetch, { DefaultErrorResponse } from 'hooks/useFetch/useFetch';
import { ProductItem } from 'components/Tracking/ProductItem/ProductItem';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { SectionDescription } from 'components/Tracking/Common/SectionDescription/SectionDescription';
import { FeaturedProductsContainer, FeaturedProductsSlider } from './FeaturedProducts.styled';
import { sliderSettings } from './config/slider-config';
import { Loading } from 'components/Shared/Loading/Loading';
import { FeaturedProductsModuleType } from 'types/shared/config/featuredProductsModule/featured-products-module-type';
import { FeaturedProductType } from 'types/tracking/featured-product-type';
import { buildProductsApi } from 'utils/tracking/common/build-products-api/build-products-api';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';

export const FeaturedProducts: React.FC<OwnProps> = (props) => {
  const { featuredProductsModule } = props;
  const { title, subtitle } = featuredProductsModule;

  const { vanityName, designId, template } = useContext(ClientConfigContext);

  const FEATURED_PRODUCTS_API = buildProductsApi(vanityName, 'featured', designId);
  const [products, , error] = useFetch<FeaturedProductType[] | null, DefaultErrorResponse>(FEATURED_PRODUCTS_API);

  if (error) return null;
  if (!products)
    return (
      <SectionLayout marginDefault="0 0 80px 0" backgroundAsColor={false}>
        <FeaturedProductsContainer data-test-id="featured-products-container" data-gtm-id="feature-products">
          <Loading height="200px" />
        </FeaturedProductsContainer>
      </SectionLayout>
    );

  if (products && products.length === 0) return null;
  return (
    <SectionLayout marginDefault="0 0 80px 0" backgroundAsColor={false}>
      <FeaturedProductsContainer data-test-id="featured-products-container" data-gtm-id="feature-products">
        <SectionDescription title={title} subtitle={subtitle} />
        <FeaturedProductsSlider {...sliderSettings} data-test-id="featured-products-slider">
          {products &&
            products.map((product, index: number) => <ProductItem key={index} product={product} template={template} productType="featured" />)}
        </FeaturedProductsSlider>
      </FeaturedProductsContainer>
    </SectionLayout>
  );
};

export interface OwnProps {
  featuredProductsModule: FeaturedProductsModuleType;
  defaultCarouselProps?: Record<string, unknown>;
}
