import React, { useContext } from 'react';
import useFetch, { DefaultErrorResponse } from 'hooks/useFetch/useFetch';
import { ProductItem } from 'components/Tracking/ProductItem/ProductItem';
import { PromotionalSlider, PromotionalContainer, PromotionalItem } from '../../Promotions.styled';
import { PromotionalInfoBox } from '../PromotionalInfoBox/PromotionalInfoBox';
import { Loading } from 'components/Shared/Loading/Loading';
import { sliderProps } from './config/slides-config';
import { PromotionalProductType } from 'types/tracking/promotional-product-type';
import { buildProductsApi } from 'utils/tracking/common/build-products-api/build-products-api';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';

export const MultiPromotional: React.FC<OwnProps> = (props) => {
  const { title, subtitle, buttonLabel } = props;

  const { vanityName, designId, template } = useContext(ClientConfigContext);

  const PROMOTIONAL_PRODUCTS_API = buildProductsApi(vanityName, 'promotional', designId);
  const [products, , error] = useFetch<PromotionalProductType[] | null, DefaultErrorResponse>(PROMOTIONAL_PRODUCTS_API);

  if (error) return null;
  if (!products)
    return (
      <PromotionalContainer>
        <Loading height="175px" />
      </PromotionalContainer>
    );
  if (products && products.length === 0) return null;
  return (
    <PromotionalContainer data-test-id="promotional-multi-wrapper">
      <PromotionalSlider {...sliderProps}>
        {products &&
          products.map((product: PromotionalProductType, index: number) => (
            <ProductItem product={product} key={index} template={template} productType="promotional" />
          ))}
      </PromotionalSlider>
      <PromotionalItem widthL="35%" data-test-id="promotional-text-item-wrapper">
        <PromotionalInfoBox title={title} subtitle={subtitle} buttonLabel={buttonLabel} />
      </PromotionalItem>
    </PromotionalContainer>
  );
};

export interface OwnProps {
  title: string;
  subtitle: string;
  buttonLabel: string;
}
