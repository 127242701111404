import styled from '@emotion/styled';

export const ShippingAddressSummaryContainer = styled.div``;

export const ShippingAddressSummaryElement = styled.div`
  margin-bottom: 3px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
