import { States } from 'types/tracking-types';
import { setEDDText } from 'utils/common/tracking.utils/tracking.utils';

type EDDDisplayTrackingPageType = 'Date' | 'DateTime' | 'None';

export const setEddDisplayGtmDimension = (state: States | string, EDD: string | null, EDDTime: string | null): EDDDisplayTrackingPageType => {
  if (!setEDDText(state, EDD) || state === States.Delivered || state === States.ReturnReceived) return 'None';
  if (EDDTime) return 'DateTime';
  return 'Date';
};
