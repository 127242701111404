import dayjs from 'dayjs';

/*
Now
Calling dayjs() without parameters returns a fresh Day.js object with the current date and time.

var now = dayjs()
This is essentially the same as calling dayjs(new Date()).

Day.js treats dayjs(undefined) as dayjs() due to that function parameters default to undefined when not passed in.

Day.js treats dayjs(null) as an invalid input.
*/

// HH - stands for 0-23 hours system
// hh - stands for 0-11 hours system

export const userTime = (date: Date | string): string => {
  return dayjs(date).format('YYYY-MM-DDTHH:mm');
};
