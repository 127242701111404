enum AddressOptions {
  STREET,
  CITY,
  STATE,
  ZIPCODE
}

/*
Function format zipCodes which are built from two parts and return only first part.
Sample: 
'66214-2546' --> '66214'
'66214' --> '66214'
null --> null
*/

const formatZipCode = (zipCode: string | null): string | null => {
  if (!zipCode) return null;
  if (zipCode.indexOf('-') !== -1) {
    const splittedZipCode: string[] = zipCode.split('-');
    return splittedZipCode[0];
  }
  return zipCode;
};

const replaceWhitespaceCharacters = (x: string | null, addCommaAtTheBegining: boolean, type: AddressOptions): string => {
  if (!x) return '';
  if (x.indexOf(' ') === -1) return addCommaAtTheBegining ? `%2C${x}` : x;
  if (type === AddressOptions.STATE || type === AddressOptions.ZIPCODE) return addCommaAtTheBegining ? `%2C${x.trim()}` : x.trim();
  const splittedX: string[] = x.split(' ');
  const joinedX = splittedX.join('+');
  return addCommaAtTheBegining ? `%2C${joinedX}` : joinedX;
};

export const buildGoogleMapLink = (
  userLatitudePosition: number | null,
  userLongitudePosition: number | null,
  originStreet: string | null,
  originCity: string | null,
  originState: string | null,
  originZipCode: string | null,
  destinationStreet: string,
  destinationCity: string,
  destinationState: string,
  destinationZipCode: string
): string => {
  const isIOS = navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPad') != -1 || navigator.platform.indexOf('iPod') != -1;
  // IOS devices should open theirs native map so it requires a different url_base than android, windows and macOS
  const URL_BASE = isIOS ? 'https://maps.apple.com/?' : 'https://www.google.com/maps/dir/?api=1';

  if (userLatitudePosition && userLongitudePosition)
    return `${URL_BASE}${isIOS ? '' : '&'}${isIOS ? 'saddr' : 'origin'}=${userLatitudePosition.toString()}%2C${userLongitudePosition.toString()}&${
      isIOS ? 'daddr' : 'destination'
    }=${replaceWhitespaceCharacters(destinationStreet, false, AddressOptions.STREET)}${replaceWhitespaceCharacters(
      destinationCity,
      !!destinationStreet,
      AddressOptions.CITY
    )}${replaceWhitespaceCharacters(destinationState, !!destinationStreet || !!destinationCity, AddressOptions.STATE)}${replaceWhitespaceCharacters(
      destinationZipCode,
      !!destinationStreet || !!destinationCity || !!destinationState,
      AddressOptions.ZIPCODE
    )}`;

  return `${URL_BASE}${isIOS ? '' : '&'}${isIOS ? 'saddr' : 'origin'}=${replaceWhitespaceCharacters(
    originStreet,
    false,
    AddressOptions.STREET
  )}${replaceWhitespaceCharacters(originCity, !!originStreet, AddressOptions.CITY)}${replaceWhitespaceCharacters(
    originState,
    !!originStreet || !!originCity,
    AddressOptions.STATE
  )}${replaceWhitespaceCharacters(formatZipCode(originZipCode), !!originStreet || !!originCity || !!originState, AddressOptions.ZIPCODE)}&${
    isIOS ? 'daddr' : 'destination'
  }=${replaceWhitespaceCharacters(destinationStreet, false, AddressOptions.STREET)}${replaceWhitespaceCharacters(
    destinationCity,
    !!destinationStreet,
    AddressOptions.CITY
  )}${replaceWhitespaceCharacters(destinationState, !!destinationStreet || !!destinationCity, AddressOptions.STATE)}${replaceWhitespaceCharacters(
    destinationZipCode,
    !!destinationStreet || !!destinationCity || !!destinationState,
    AddressOptions.ZIPCODE
  )}`;
};
