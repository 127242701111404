import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  Form,
  FormAdditionalButton,
  FormAdditionalButtonBreak,
  FormAdditionalButtonsContainer,
  FormButtonContainer,
  FormFieldset,
  FormLabel,
  FormLabelLink,
  FormResetButton,
  FormStatusMessage,
  FormSubmitButton
} from '../common/Form.styled';
import { InputContainer, InputErrorMessage, TagsContainer, TagsInput } from './SmsForm.styled';
import { NotificationNumber } from 'types/tracking/common/notifications/notifications-type';
import PBIcon from 'components/Shared/styled-components/PBIcon/PBIcon.styled';
import { CircularProgress } from 'components/Shared/styled-components/CircularProgress/CircularProgress.styled';
import { enhanceFetchParams, enhanceHeadersParams } from 'utils/common/utils/utils';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useIntl } from 'react-intl';
import { useMedia } from 'use-media';
import { Tag } from './Tag/Tag';
import { TemplateEnum } from 'types/shared/template-type';
import { ClientConfigContext } from '../../../../context/shared/ClientConfigContext';
import { PBIconTypeEnum } from 'types/shared/pb-icon-type-enum';
import { PBIcomoonIconsEnum } from 'constants/PBIconsEnums';

export const SmsForm: React.FC<OwnProps> = (props) => {
  const { trackingNumber, urlKey, deliveryType, setShouldDisplaySmsForm, numbers, setNumbers, isDark } = props;

  const [sendingFormError, setSendingFormError] = useState<boolean>(false);
  const [isFormSent, setIsFormSent] = useState<boolean>(false);
  const [disableNewNumberInput, setDisableNewNumberInput] = useState<boolean>(false);
  const [shouldDisplaySubmitButton, setShouldDisplaySubmitButton] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [newNumberValue, setNewNumberValue] = useState<string>('');
  const [newNumberValueError, setNewNumberValueError] = useState<string>('');
  const [fieldTouched, setFieldTouched] = useState<boolean>(false);
  const [isDeleteSmsSubscriptionRequestSent, setIsDeleteSmsSubscriptionRequestSent] = useState<boolean>(false);
  const [deleteSmsSubscriptionError, setDeleteSmsSubscriptionError] = useState<boolean>(false);

  const handleSetFieldTouchedOnFalse = () => {
    setFieldTouched(false);
  };

  const { vanityName, brandId, template } = useContext(ClientConfigContext);
  const intl = useIntl();
  const mediumUp = useMedia({ minWidth: '576px' });
  const ref = useDetectClickOutside({ onTriggered: handleSetFieldTouchedOnFalse });

  useEffect(() => {
    if (numbers.length > 0) {
      setShouldDisplaySubmitButton(false);
      setDisableNewNumberInput(true);
    }
  }, [numbers]);

  useEffect(() => {
    if (fieldTouched && ref && ref.current) {
      // @ts-ignore
      ref.current.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldTouched]);

  const handleEdit = () => {
    setShouldDisplaySubmitButton(true);
    setDisableNewNumberInput(false);
    setFieldTouched(true);
  };

  const handleClearAndCloseForm = () => {
    setShouldDisplaySmsForm(false);
  };

  const handleCancel = (): void => {
    handleClearAndCloseForm();
  };

  const isPhoneNumberValid = (phoneNumber: string): boolean => {
    const regex = /^(?:\+1[-. ]?)?((\([0-9]{3}\))|[0-9]{3})[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/;
    return regex.test(phoneNumber);
  };

  const handleSetNewNumberValue = (value: string) => {
    setFieldTouched(true);
    setNewNumberValue(value);
    if (!isPhoneNumberValid(value)) return setNewNumberValueError(intl.formatMessage({ id: 'TRACKING.notifications.sms.inputValidation.error1' }));
    return setNewNumberValueError('');
  };

  const handleCheckNewNumberValueLengthOnClick = () => {
    setFieldTouched(true);
    if (newNumberValue.length === 0) setNewNumberValueError(intl.formatMessage({ id: 'TRACKING.notifications.sms.inputValidation.error1' }));
  };

  const handleOnSubmitNewNumber = async (e) => {
    e.preventDefault();
    setFieldTouched(true);

    if (!isPhoneNumberValid(newNumberValue) && numbers.length <= 1)
      return setNewNumberValueError(intl.formatMessage({ id: 'TRACKING.notifications.sms.inputValidation.error1' }));
    if (numbers.length === 2) return setNewNumberValueError(intl.formatMessage({ id: 'TRACKING.notifications.sms.inputValidation.error2' }));

    const numberWithOutDirection = newNumberValue.replace('+1', '');
    const numberExist = numbers.some((element) => element.phoneNumber === numberWithOutDirection);
    if (numberExist) return setNewNumberValueError('Number already exists');

    const phoneNumbers: string[] = numbers.map((element: NotificationNumber) => element.phoneNumber);

    const payload: { brandId: string; phoneNumber: string } = {
      brandId: brandId,
      phoneNumber: [newNumberValue, ...phoneNumbers].join(',')
    };

    const API_POST_SMS_SUBSCRIPTION = `/notification/sms/${trackingNumber}/${vanityName}?key=${
      urlKey ? urlKey : undefined
    }&deliveryType=${deliveryType}`;

    const fetchParams = enhanceFetchParams({
      headers: enhanceHeadersParams(brandId),
      method: 'POST',
      body: JSON.stringify(payload)
    });

    try {
      setIsDeleteSmsSubscriptionRequestSent(false);
      setIsFormSent(true);
      setLoading(true);

      const response = await fetch(API_POST_SMS_SUBSCRIPTION, fetchParams);
      const jsonParsed = await response.json();

      if (jsonParsed.error) {
        setLoading(false);
        return setSendingFormError(true);
      }

      setShouldDisplaySubmitButton(false);
      setDisableNewNumberInput(true);
      setNumbers(jsonParsed.numbers);
      setSendingFormError(false);
      setLoading(false);
      setFieldTouched(false);
      setNewNumberValue('');
    } catch (e) {
      setIsFormSent(true);
      setSendingFormError(true);
      setFieldTouched(true);
      setLoading(false);
    }
  };

  const handleRemoveSmsSubscription = async (subscription: string) => {
    const API_DELETE_SMS_SUBSCRIPTION = `/notification/sms/${subscription}`;

    const fetchParams = enhanceFetchParams({
      method: 'DELETE',
      headers: enhanceHeadersParams(brandId)
    });

    try {
      setIsFormSent(false);
      setIsDeleteSmsSubscriptionRequestSent(true);

      const response = await fetch(API_DELETE_SMS_SUBSCRIPTION, fetchParams);
      if (!response.ok) return setDeleteSmsSubscriptionError(true);

      setDeleteSmsSubscriptionError(false);

      const newNumbersArray = numbers.filter((element) => element.subscriptionId !== subscription);
      if (newNumbersArray.length > 0) {
        setShouldDisplaySubmitButton(false);
        setDisableNewNumberInput(true);
      }
      if (newNumbersArray.length === 0) {
        setShouldDisplaySubmitButton(true);
        setDisableNewNumberInput(false);
      }
      setNumbers(newNumbersArray);
      setNewNumberValue('');
      setFieldTouched(false);
    } catch (e) {}
  };

  return (
    <Form data-test-id="notifications-sms-form" onSubmit={handleOnSubmitNewNumber}>
      <FormFieldset>
        <FormStatusMessage
          visible={isFormSent || isDeleteSmsSubscriptionRequestSent}
          error={(sendingFormError && isFormSent) || (deleteSmsSubscriptionError && isDeleteSmsSubscriptionRequestSent)}
          data-test-id="sms-form-status-message">
          {isFormSent && sendingFormError && intl.formatMessage({ id: 'TRACKING.notifications.sms.formStatus.addNewNumber.error' })}
          {isFormSent && !sendingFormError && intl.formatMessage({ id: 'TRACKING.notifications.sms.formStatus.addNewNumber.success' })}
          {isDeleteSmsSubscriptionRequestSent &&
            deleteSmsSubscriptionError &&
            intl.formatMessage({ id: 'TRACKING.notifications.sms.formStatus.deleteNumber.error' })}
          {isDeleteSmsSubscriptionRequestSent &&
            !deleteSmsSubscriptionError &&
            intl.formatMessage({ id: 'TRACKING.notifications.sms.formStatus.deleteNumber.success' })}
        </FormStatusMessage>
        <FormLabel htmlFor="phone" isDark={isDark} data-test-id="sms-input-label">
          {intl.formatMessage(
            { id: 'TRACKING.notifications.sms.label' },
            {
              label1: (
                <FormLabelLink
                  data-gtm-id="sms-notification-terms"
                  href="https://www.pitneybowes.com/us/consumer-connect-sms-terms.html"
                  target="_blank"
                  key={1}
                  rel="noopener noreferrer">
                  {intl.formatMessage({ id: 'TRACKING.notifications.sms.label.link1' })}
                </FormLabelLink>
              ),
              label2: (
                <FormLabelLink
                  data-gtm-id="sms-privacy-policy"
                  href="https://www.pitneybowes.com/us/legal/privacy-statement.html"
                  target="_blank"
                  key={2}
                  rel="noopener noreferrer">
                  {intl.formatMessage({ id: 'TRACKING.notifications.sms.label.link2' })}
                </FormLabelLink>
              ),
              label3: (
                <FormLabelLink
                  data-gtm-id="sms-terms-of-use"
                  href="https://www.pitneybowes.com/us/consumer-connect-tou.html"
                  target="_blank"
                  key={3}
                  rel="noopener noreferrer">
                  {intl.formatMessage({ id: 'TRACKING.notifications.sms.label.link3' })}
                </FormLabelLink>
              )
            }
          )}
        </FormLabel>
        <InputContainer valid={!newNumberValueError && fieldTouched && newNumberValue.length > 0} error={!!newNumberValueError && fieldTouched}>
          <PBIcon type={PBIconTypeEnum.Icomoon} name={PBIcomoonIconsEnum.TextSms} />
          <TagsContainer data-test-id="tags-container">
            {numbers.map((element: NotificationNumber, index: number) => {
              return (
                <Tag
                  phoneNumber={element.phoneNumber}
                  key={index}
                  subscriptionId={element.subscriptionId}
                  handleRemoveSmsSubscription={handleRemoveSmsSubscription}
                />
              );
            })}
            <TagsInput
              data-gtm-id="sms-text-field"
              data-test-id="sms-input"
              shouldExist={numbers.length < 2}
              ref={ref}
              type="tel"
              id="phone"
              disabled={numbers.length === 2 || disableNewNumberInput}
              name="phone"
              required={true}
              onClick={handleCheckNewNumberValueLengthOnClick}
              title="Enter valid phone number"
              pattern="^(?:\+?1[-. ]?)?((\([0-9]{3}\))|[0-9]{3})[-. ]?[0-9]{3}[-. ]?[0-9]{4}$"
              onFocus={() => setFieldTouched(true)}
              value={newNumberValue}
              onChange={(e) => handleSetNewNumberValue(e.target.value)}
              placeholder={numbers.length <= 1 ? 'Add up two numbers' : ''}
            />
          </TagsContainer>

          {shouldDisplaySubmitButton ? (
            <FormSubmitButton
              data-gtm-id="sms-submit"
              data-test-id="sms-submit-button"
              formType="sms"
              type="submit"
              disabled={loading}
              onClick={handleCheckNewNumberValueLengthOnClick}>
              {loading ? <CircularProgress /> : intl.formatMessage({ id: 'TRACKING.notifications.sms.formButton.submit' })}
            </FormSubmitButton>
          ) : (
            <FormAdditionalButtonsContainer data-test-id="sms-additional-buttons-container">
              <FormAdditionalButton data-test-id="sms-edit-button" onClick={handleEdit} type="button" aria-label="change-button">
                {template === TemplateEnum.VIVID ? (
                  mediumUp ? (
                    intl.formatMessage({ id: 'TRACKING.notifications.sms.formButton.change' })
                  ) : (
                    <PBIcon type={PBIconTypeEnum.Icomoon} name={PBIcomoonIconsEnum.Pencil} />
                  )
                ) : (
                  <PBIcon type={PBIconTypeEnum.Icomoon} name={PBIcomoonIconsEnum.Pencil} />
                )}
              </FormAdditionalButton>
              {numbers.length < 2 && <FormAdditionalButtonBreak />}
              {numbers.length < 2 && (
                <FormAdditionalButton data-test-id="sms-add-another-button" onClick={handleEdit} type="button" aria-label="add-number-button">
                  {template === TemplateEnum.VIVID ? (
                    mediumUp ? (
                      intl.formatMessage({ id: 'TRACKING.notifications.sms.formButton.addNumber' })
                    ) : (
                      <PBIcon type={PBIconTypeEnum.Icomoon} name={PBIcomoonIconsEnum.Plus} />
                    )
                  ) : (
                    <PBIcon type={PBIconTypeEnum.Icomoon} name={PBIcomoonIconsEnum.Plus} />
                  )}
                </FormAdditionalButton>
              )}
            </FormAdditionalButtonsContainer>
          )}
          {newNumberValueError && fieldTouched && <InputErrorMessage data-test-id="sms-input-error">{newNumberValueError}</InputErrorMessage>}
        </InputContainer>
        <FormButtonContainer>
          <FormResetButton data-test-id="sms-cancel-button" data-gtm-id="sms-cancel" onClick={handleCancel} type="reset" aria-label="cancel-button">
            {intl.formatMessage({ id: 'TRACKING.notifications.sms.formButton.cancel' })}
          </FormResetButton>
        </FormButtonContainer>
      </FormFieldset>
    </Form>
  );
};

export interface OwnProps {
  isDark: boolean;
  trackingNumber: string;
  urlKey: string | null;
  deliveryType: string;
  numbers: NotificationNumber[];
  setNumbers: Dispatch<SetStateAction<NotificationNumber[]>>;
  setShouldDisplaySmsForm: Dispatch<SetStateAction<boolean>>;
}
