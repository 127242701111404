import queryString from 'query-string';

export const setReturnInfoApi = (
  vanityName: string,
  inputOrderId: string | null,
  shippingPostalCode: string | null,
  urlKey: string | null,
  labelId: string | null
): string => {
  return queryString.stringifyUrl(
    {
      url: `/api/return/getLabel/${vanityName}`,
      query: {
        inputOrderId,
        shippingPostalCode,
        key: urlKey,
        labelId
      }
    },
    { skipNull: true, skipEmptyString: true, sort: false }
  );
};
