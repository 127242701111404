import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';
import { CustomLoadingButton } from 'components/Shared/styled-components/CustomLoadingButton/CustomLoadingButton.styled';

export const Container = styled.form<{ displayZipCodeTextField: boolean }>`
  width: 100%;
  max-width: ${({ displayZipCodeTextField }) => (displayZipCodeTextField ? '480px' : '315px')};

  @media ${({ theme }) => theme.xxlargeUp} {
    max-width: ${({ displayZipCodeTextField }) => (displayZipCodeTextField ? '490px' : '320px')};
  }
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: 400;
  margin: 0;
  color: #4a4a4a;
`;

export const AuthenticatedReturnsFormCustomFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  .MuiFormControlLabel-label {
    color: #4a4a4a;
    font-family: ${({ theme }) => theme.bodyFontFamily};
  }
`;

export const AuthenticatedReturnsFormCustomLoadingButton = styled(CustomLoadingButton)`
  min-height: 56px;
  border-radius: 4px;
`;
