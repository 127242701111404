import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const SectionLayout = styled.section<{
  height?: string;
  bgColor?: string;
  backgroundImage?: string;
  backgroundAsColor: boolean;
  paddingDefault?: string;
  paddingMd?: string;
  paddingL?: string;
  paddingXl?: string;
  paddingXXl?: string;
  marginDefault?: string;
  marginMd?: string;
  marginL?: string;
  marginXl?: string;
  marginXXl?: string;
  justifyContent?: string;
  flexGrow?: number;
}>`
  width: 100%;
  height: ${({ height }) => (height ? height : 'inherit')};
  background-color: ${({ bgColor, backgroundAsColor }) => (backgroundAsColor && bgColor ? bgColor : 'transparent')};
  display: flex;
  flex-grow: ${({ flexGrow }) => (flexGrow ? flexGrow : 0)};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  margin: ${({ marginDefault }) => (marginDefault ? marginDefault : '0')};
  padding: ${({ paddingDefault }) => (paddingDefault ? paddingDefault : '0 20px')};

  @media ${({ theme }) => theme.mediumUp} {
    padding: ${({ paddingMd }) => (paddingMd ? paddingMd : '0 30px')};
    ${({ marginMd }) =>
      marginMd &&
      css`
        margin: ${marginMd};
      `}
  }

  @media ${({ theme }) => theme.largeUp} {
    padding: ${({ paddingL }) => (paddingL ? paddingL : '0 50px')};
    ${({ marginL }) =>
      marginL &&
      css`
        margin: ${marginL};
      `}
  }

  @media ${({ theme }) => theme.xlargeUp} {
    padding: ${({ paddingXl }) => (paddingXl ? paddingXl : '0 100px')};
    ${({ marginXl }) =>
      marginXl &&
      css`
        margin: ${marginXl};
      `}
  }

  @media ${({ theme }) => theme.xxlargeUp} {
    padding: ${({ paddingXXl }) => (paddingXXl ? paddingXXl : '0 120px')};
    ${({ marginXXl }) =>
      marginXXl &&
      css`
        margin: ${marginXXl};
      `}
  }

  ${({ backgroundImage, backgroundAsColor }) =>
    backgroundImage &&
    !backgroundAsColor &&
    css`
            background-size: cover;
            background-position: center;
            background-image: url('${backgroundImage}?w=600');

            @media only screen and (min-width: 768px) {
              background-image: url('${backgroundImage}?w=800');
              background-image: image-set(url(${backgroundImage}?w=800) 1x,
              url(${backgroundImage}?w=1600) 2x);
            }

            @media only screen and (min-width: 1200px) {
              background-image: url('${backgroundImage}?w=1000');
              background-image: image-set(url(${backgroundImage}?w=1000) 1x,
              url(${backgroundImage}?w=2000) 2x);
            }
          `}
`;

export const GiftTrackerBanner = styled.a`
  width: 100%;
  height: auto;
  background-color: #e6e6e6;
  display: block;
  padding: 10px;
  display: flex;
  flex-grow: 0;
  justify-content: center;
  margin: 0;
  padding: 0 20px;
  cursor: pointer;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 6px;
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
    padding: 6px;
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
    padding: 6px;
  }
`;
