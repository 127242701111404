import React, { PropsWithChildren, useMemo } from 'react';
import { HeadingBox, PolicyMain, PolicySubtitle, PolicyTitle } from './PolicyLayout.styled';
import { PolicyHeader } from 'components/Shared/ConsumerConnectPolicy/PolicyHeader/PolicyHeader';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import { createTheme as createMUITheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { getDesignTokens } from 'styles/theme';
import { FaviconsModule } from 'types/tracking-types';
import { Helmet } from 'react-helmet';
import PageTitle from 'components/Shared/PageTitle/PageTitle';
import { GlobalStyles } from 'components/Shared/GlobalStyles/GlobalStyles';
import { getFavIcons } from 'utils/common/favicons/favicons';
import { ClientConfigurationType } from 'types/shared/config/client-configuration-type';
import { resolveTheme } from 'utils/common/theme-resolver/theme-resolver';
import { PageTypeEnum } from 'types/app/page-type-enum';

export const PolicyLayout: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { children, pageTitle, title, subtitle } = props;

  const mui_theme = useMemo(() => {
    return createMUITheme(getDesignTokens('Precision Sans W', '#000', false, '#000', '#fff', '#000', 'rounded'));
  }, []);

  const theme = resolveTheme(undefined, undefined, PageTypeEnum.ConsumerConnectPolicy);

  return (
    <MUIThemeProvider theme={mui_theme}>
      <ThemeProvider theme={theme}>
        <GlobalStyles isPreviewBasedOnRouteUrl={false} clientConfig={{} as ClientConfigurationType} theme={theme} />
        <PageTitle title={pageTitle} brandName={null} />
        <Helmet>{getFavIcons({ useDefault: true } as FaviconsModule)}</Helmet>
        <PolicyHeader />
        <PolicyMain>
          <SectionLayout backgroundAsColor bgColor="#fff">
            <HeadingBox>
              <PolicyTitle>{title}</PolicyTitle>
              {subtitle && <PolicySubtitle>{subtitle}</PolicySubtitle>}
            </HeadingBox>
          </SectionLayout>
          <SectionLayout flexGrow={2} backgroundAsColor bgColor="#F5F6FA">
            {children}
          </SectionLayout>
        </PolicyMain>
      </ThemeProvider>
    </MUIThemeProvider>
  );
};

interface OwnProps {
  children: React.ReactNode;
  pageTitle: string;
  title: string;
  subtitle?: string;
}
