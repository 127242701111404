import React from 'react';
import { Animate } from 'react-show';
import { LogoAlignedType, NavigationsType } from 'types/shared/config/headerModule/header-module-type';
import { DesktopNav, MobileNav, Navigation, NavLink, NavLinkItem } from './Navigations.styled';

export const Navigations: React.FC<OwnProps> = (props: OwnProps) => {
  const { navigations, laptopView, activeMobileNav, logoAligned } = props;

  return laptopView ? (
    <DesktopNav data-test-id="navigation-desktop" logoCenter={logoAligned === 'center'}>
      <Navigation>
        {navigations.map((item: NavigationsType, index: number) => (
          <NavLinkItem key={`nav-link-item-${index}`} data-gtm-id="nav-link-item">
            <NavLink href={item.url}>{item.label}</NavLink>
          </NavLinkItem>
        ))}
      </Navigation>
    </DesktopNav>
  ) : (
    <>
      <MobileNav data-test-id="navigation-mobile">
        <Animate
          show={activeMobileNav}
          duration={300}
          style={{ height: 'auto', overflow: 'hidden' }}
          start={{ height: 0 }}
          transitionOnMount
          stayMounted>
          <Navigation>
            {navigations.map((item: NavigationsType, index: number) => (
              <NavLinkItem key={`mobile-nav-link-item-${index}`} data-gtm-id="nav-link-item">
                <NavLink href={item.url}>{item.label}</NavLink>
              </NavLinkItem>
            ))}
          </Navigation>
        </Animate>
      </MobileNav>
    </>
  );
};

export interface OwnProps {
  laptopView: boolean;
  activeMobileNav: boolean;
  navigations: NavigationsType[];
  logoAligned: LogoAlignedType;
}
