import styled from '@emotion/styled';

export const GoogleMapContainer = styled.div`
  @media ${({ theme }) => theme.largeUp} {
    margin-left: 16px;
    height: 600px;
  }
  @media ${({ theme }) => theme.mediumDown} {
    margin-bottom: 16px;
    height: 300px;
  }
`;
