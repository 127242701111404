import React from 'react';
import { ReturnsTitleContainer, ReturnsTitleText, ReturnsTitleOrderNumber } from './ReturnsTitle.styled';

export const ReturnsTitle: React.FC<OwnProps> = (props) => {
  const { title, orderNumber } = props;

  return (
    <ReturnsTitleContainer data-test-id="return-order-number-title">
      <ReturnsTitleText id="titlereturn" className="returntitle">
        {title}
      </ReturnsTitleText>
      <ReturnsTitleOrderNumber>{orderNumber}</ReturnsTitleOrderNumber>
    </ReturnsTitleContainer>
  );
};

export interface OwnProps {
  title: string;
  orderNumber: string;
}
