import React, { Dispatch, PropsWithChildren, SetStateAction, useEffect, useMemo, useState } from 'react';
import { PickupAddressResponse } from 'types/return/return-info/pickup-config-response-type';

export enum USPSFormAlertErrorTitle {
  SCHEDULE_PICKUP = 'The USPS pickup scheduler is currently experiencing technical issues. Please try again later.',
  CHECK_AVAILABILITY = 'The USPS pickup scheduler is currently experiencing technical issues. Please try again later.'
}

export const defaultContext: PickupContextType = {
  confirmationNumber: null,
  setConfirmationNumber: () => {},

  confirmedAdditionalInstructions: null,
  setConfirmedAdditionalInstructions: () => {},

  confirmedPickupDate: null,
  setConfirmedPickupDate: () => {},

  isPickupExist: false,
  setIsPickupExist: () => {},

  confirmedPickupLocation: null,
  setConfirmedPickupLocation: () => {},

  step: 0,
  setStep: () => {},

  showPickupFromAddressForm: false,
  setShowPickupFromAddressForm: () => {},

  estimatedPickupDate: null,
  setEstimatedPickupDate: () => {},

  addressOne: '',
  setAddressOne: () => {},
  addressOneError: null,
  setAddressOneError: () => {},

  addressTwo: '',
  setAddressTwo: () => {},
  addressTwoError: '',
  setAddressTwoError: () => {},

  city: '',
  setCity: () => {},
  cityError: '',
  setCityError: () => {},

  selectedState: '',
  setSelectedState: () => {},
  selectedStateError: '',
  setSelectedStateError: () => {},

  zipCode: '',
  setZipCode: () => {},
  zipCodeError: '',
  setZipCodeError: () => {},

  phoneNumber: '',
  setPhoneNumber: () => {},
  phoneNumberError: '',
  setPhoneNumberError: () => {},

  estimatedWeight: '',
  setEstimatedWeight: () => {},
  estimatedWeightError: null,
  setEstimatedWeightError: () => {},

  packageLocation: '',
  setPackageLocation: () => {},
  packageLocationError: null,
  setPackageLocationError: () => {},

  additionalInstructions: '',
  setAdditionalInstructions: () => {},
  additionalInstructionsError: null,
  setAdditionalInstructionsError: () => {},

  isAdditionalInstructionsRequired: false,
  setIsAdditionalInstructionsRequired: () => {},

  schedulePickupFormError: null,
  setSchedulePickupFormError: () => {},
  schedulePickupLoading: false,
  setSchedulePickupLoading: () => {},

  checkAvailabilityFormError: null,
  setCheckAvailabilityFormError: () => {},
  checkAvailabilityLoading: false,
  setCheckAvailabilityLoading: () => {},

  setValuesFromInitialPickupFromAddressForForm: () => {},
  setValueFromInitialPhoneNumber: () => {},

  pickupCancelLink: null,
  setPickupCancelLink: () => {}
};

export const PickupContext = React.createContext<PickupContextType>(defaultContext);

export interface ConfirmedPickupLocation {
  address1: string;
  address2: string | null;
  city: string;
  postalCode: string;
  stateOrProvince: string;
}

export const PickupContextProvider: React.FC<PickupContextProviderPropsType> = (props: PropsWithChildren<PickupContextProviderPropsType>) => {
  const {
    children,
    initialPickupAddress,
    initialIsPickupExists,
    initialConfirmedPickupDate,
    initialPhoneNumber,
    initialPickupCancelLink,
    initialConfirmationNumber
  } = props;

  const [isPickupExist, setIsPickupExist] = useState<boolean>(false);
  const [confirmedPickupDate, setConfirmedPickupDate] = useState<string | null>(null);

  const [step, setStep] = useState<number>(0);
  const [confirmedPickupLocation, setConfirmedPickupLocation] = useState<ConfirmedPickupLocation | null>(null);
  const [confirmationNumber, setConfirmationNumber] = useState<string | null>(null);
  const [confirmedAdditionalInstructions, setConfirmedAdditionalInstructions] = useState<string | null>(null);

  const [showPickupFromAddressForm, setShowPickupFromAddressForm] = useState<boolean>(false);
  const [estimatedPickupDate, setEstimatedPickupDate] = useState<string | null>(null);
  const [addressOne, setAddressOne] = useState<string>('');
  const [addressOneError, setAddressOneError] = useState<string | null>(null);
  const [addressTwo, setAddressTwo] = useState<string>('');
  const [addressTwoError, setAddressTwoError] = useState<string | null>(null);
  const [city, setCity] = useState<string>('');
  const [cityError, setCityError] = useState<string | null>(null);
  const [selectedState, setSelectedState] = useState<string>('');
  const [selectedStateError, setSelectedStateError] = useState<string | null>(null);
  const [zipCode, setZipCode] = useState<string>('');
  const [zipCodeError, setZipCodeError] = useState<string | null>(null);

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [estimatedWeight, setEstimatedWeight] = useState<string>('');
  const [estimatedWeightError, setEstimatedWeightError] = useState<string | null>(null);
  const [packageLocation, setPackageLocation] = useState<string>('');
  const [packageLocationError, setPackageLocationError] = useState<string | null>(null);
  const [additionalInstructions, setAdditionalInstructions] = useState<string>('');
  const [additionalInstructionsError, setAdditionalInstructionsError] = useState<string | null>(null);
  const [isAdditionalInstructionsRequired, setIsAdditionalInstructionsRequired] = useState<boolean>(false);

  const [schedulePickupFormError, setSchedulePickupFormError] = useState<string | null>(null);
  const [schedulePickupLoading, setSchedulePickupLoading] = useState<boolean>(false);

  const [checkAvailabilityFormError, setCheckAvailabilityFormError] = useState<string | null>(null);
  const [checkAvailabilityLoading, setCheckAvailabilityLoading] = useState<boolean>(false);

  const [pickupCancelLink, setPickupCancelLink] = useState<string | null>(null);

  useEffect(() => {
    setIsPickupExist(initialIsPickupExists);
    setConfirmedPickupDate(initialConfirmedPickupDate);
    if (!!initialPhoneNumber) {
      setPhoneNumber(initialPhoneNumber);
    }
    setPickupCancelLink(initialPickupCancelLink);
    setConfirmationNumber(initialConfirmationNumber);
  }, [initialIsPickupExists, initialConfirmedPickupDate, initialPhoneNumber, initialPickupCancelLink, initialConfirmationNumber]);

  useEffect(() => {
    if (initialPickupAddress.address1) setAddressOne(initialPickupAddress.address1);
    if (initialPickupAddress.address2) setAddressTwo(initialPickupAddress.address2);
    if (initialPickupAddress.city) setCity(initialPickupAddress.city);
    if (initialPickupAddress.stateOrProvince) setSelectedState(initialPickupAddress.stateOrProvince);
    if (initialPickupAddress.postalCode) setZipCode(initialPickupAddress.postalCode);
  }, [initialPickupAddress]);

  const setValuesFromInitialPickupFromAddressForForm = (): void => {
    setAddressOne(!!initialPickupAddress.address1 ? initialPickupAddress.address1 : '');
    setAddressTwo(!!initialPickupAddress.address2 ? initialPickupAddress.address2 : '');
    setCity(!!initialPickupAddress.city ? initialPickupAddress.city : '');
    setSelectedState(!!initialPickupAddress.stateOrProvince ? initialPickupAddress.stateOrProvince : '');
    setZipCode(!!initialPickupAddress.postalCode ? initialPickupAddress.postalCode : '');

    setAddressOneError(null);
    setAddressTwoError(null);
    setCityError(null);
    setSelectedStateError(null);
    setZipCodeError(null);
  };

  const setValueFromInitialPhoneNumber = (): void => {
    setPhoneNumber(!!initialPhoneNumber ? initialPhoneNumber : '');

    setPhoneNumberError(null);
  };

  const value = useMemo<PickupContextType>(
    () => ({
      confirmationNumber,
      setConfirmationNumber,

      confirmedAdditionalInstructions,
      setConfirmedAdditionalInstructions,

      isPickupExist,
      setIsPickupExist,

      confirmedPickupDate,
      setConfirmedPickupDate,

      confirmedPickupLocation,
      setConfirmedPickupLocation,

      step,
      setStep,

      showPickupFromAddressForm,
      setShowPickupFromAddressForm,

      estimatedPickupDate,
      setEstimatedPickupDate,

      addressOne,
      setAddressOne,
      addressOneError,
      setAddressOneError,

      addressTwo,
      setAddressTwo,
      addressTwoError,
      setAddressTwoError,

      city,
      setCity,
      cityError,
      setCityError,

      selectedState,
      setSelectedState,
      selectedStateError,
      setSelectedStateError,

      zipCode,
      setZipCode,
      zipCodeError,
      setZipCodeError,

      phoneNumber,
      setPhoneNumber,
      phoneNumberError,
      setPhoneNumberError,

      estimatedWeight,
      setEstimatedWeight,
      estimatedWeightError,
      setEstimatedWeightError,

      packageLocation,
      setPackageLocation,
      packageLocationError,
      setPackageLocationError,

      additionalInstructions,
      setAdditionalInstructions,
      additionalInstructionsError,
      setAdditionalInstructionsError,

      isAdditionalInstructionsRequired,
      setIsAdditionalInstructionsRequired,

      schedulePickupFormError,
      setSchedulePickupFormError,
      schedulePickupLoading,
      setSchedulePickupLoading,

      checkAvailabilityFormError,
      setCheckAvailabilityFormError,
      checkAvailabilityLoading,
      setCheckAvailabilityLoading,

      setValuesFromInitialPickupFromAddressForForm,
      setValueFromInitialPhoneNumber,

      pickupCancelLink,
      setPickupCancelLink
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      confirmationNumber,
      confirmedAdditionalInstructions,
      isPickupExist,
      confirmedPickupDate,
      confirmedPickupLocation,
      step,
      showPickupFromAddressForm,
      estimatedPickupDate,
      addressOne,
      addressOneError,
      addressTwo,
      addressTwoError,
      city,
      cityError,
      selectedState,
      selectedStateError,
      zipCode,
      zipCodeError,
      phoneNumber,
      phoneNumberError,
      estimatedWeight,
      estimatedWeightError,
      packageLocation,
      packageLocationError,
      additionalInstructions,
      additionalInstructionsError,
      isAdditionalInstructionsRequired,
      schedulePickupFormError,
      schedulePickupLoading,
      checkAvailabilityFormError,
      checkAvailabilityLoading,
      pickupCancelLink
    ]
  );

  return <PickupContext.Provider value={value}>{children}</PickupContext.Provider>;
};

export interface PickupContextType {
  confirmationNumber: string | null;
  setConfirmationNumber: Dispatch<SetStateAction<string | null>>;

  confirmedAdditionalInstructions: string | null;
  setConfirmedAdditionalInstructions: Dispatch<SetStateAction<string | null>>;

  confirmedPickupDate: string | null;
  setConfirmedPickupDate: Dispatch<SetStateAction<string | null>>;

  isPickupExist: boolean;
  setIsPickupExist: Dispatch<SetStateAction<boolean>>;

  confirmedPickupLocation: ConfirmedPickupLocation | null;
  setConfirmedPickupLocation: Dispatch<SetStateAction<ConfirmedPickupLocation | null>>;

  step: number;
  setStep: Dispatch<SetStateAction<number>>;

  showPickupFromAddressForm: boolean;
  setShowPickupFromAddressForm: Dispatch<SetStateAction<boolean>>;

  estimatedPickupDate: string | null;
  setEstimatedPickupDate: Dispatch<SetStateAction<string | null>>;

  addressOne: string;
  setAddressOne: Dispatch<SetStateAction<string>>;
  addressOneError: string | null;
  setAddressOneError: Dispatch<SetStateAction<string | null>>;

  addressTwo: string;
  setAddressTwo: Dispatch<SetStateAction<string>>;
  addressTwoError: string | null;
  setAddressTwoError: Dispatch<SetStateAction<string | null>>;

  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  cityError: string | null;
  setCityError: Dispatch<SetStateAction<string | null>>;

  selectedState: string;
  setSelectedState: Dispatch<SetStateAction<string>>;
  selectedStateError: string | null;
  setSelectedStateError: Dispatch<SetStateAction<string | null>>;

  zipCode: string;
  setZipCode: Dispatch<SetStateAction<string>>;
  zipCodeError: string | null;
  setZipCodeError: Dispatch<SetStateAction<string | null>>;

  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  phoneNumberError: string | null;
  setPhoneNumberError: Dispatch<SetStateAction<string | null>>;

  estimatedWeight: string;
  setEstimatedWeight: Dispatch<SetStateAction<string>>;
  estimatedWeightError: string | null;
  setEstimatedWeightError: Dispatch<SetStateAction<string | null>>;

  packageLocation: string;
  setPackageLocation: Dispatch<SetStateAction<string>>;
  packageLocationError: string | null;
  setPackageLocationError: Dispatch<SetStateAction<string | null>>;

  additionalInstructions: string;
  setAdditionalInstructions: Dispatch<SetStateAction<string>>;
  additionalInstructionsError: string | null;
  setAdditionalInstructionsError: Dispatch<SetStateAction<string | null>>;

  isAdditionalInstructionsRequired: boolean;
  setIsAdditionalInstructionsRequired: Dispatch<SetStateAction<boolean>>;

  schedulePickupFormError: string | null;
  setSchedulePickupFormError: Dispatch<SetStateAction<string | null>>;
  schedulePickupLoading: boolean;
  setSchedulePickupLoading: Dispatch<SetStateAction<boolean>>;

  checkAvailabilityFormError: string | null;
  setCheckAvailabilityFormError: Dispatch<SetStateAction<string | null>>;
  checkAvailabilityLoading: boolean;
  setCheckAvailabilityLoading: Dispatch<SetStateAction<boolean>>;

  setValuesFromInitialPickupFromAddressForForm: () => void;
  setValueFromInitialPhoneNumber: () => void;

  pickupCancelLink: string | null;
  setPickupCancelLink: Dispatch<SetStateAction<string | null>>;
}

interface PickupContextProviderPropsType {
  children: React.ReactNode;
  initialPhoneNumber: string | null;
  initialPickupAddress: PickupAddressResponse;
  initialIsPickupExists: boolean;
  initialConfirmedPickupDate: string | null;
  initialPickupCancelLink: string | null;
  initialConfirmationNumber: string | null;
}
