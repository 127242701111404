import { HostType } from 'types/shared/config/host-type';
import { Environment } from 'utils/common/environment-resolver/environment-resolver';

export const CLIENT_CONFIGURATION_API_HOSTS: HostType = {
  PROD: 'https://api.shipment.co',
  [Environment.AWS_PRD]: 'https://api.shipment-prd.co',
  [Environment.AWS_PPD]: 'https://api.shipment-demo.co',
  [Environment.AWS_DEV]: 'https://api.shipment-dev.co',
  [Environment.LOCAL]: 'http://localhost:8082'
};
