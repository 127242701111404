import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { setTrackingDataUrl } from 'utils/tracking/common/api-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFetch, { DefaultErrorResponse } from 'hooks/useFetch/useFetch';
import { TrackingConfigurationType } from 'types/tracking/trackingConfig/tracking-configuration-type';
import {
  shouldDisplayCustomHtmlComponent,
  shouldDisplayExploreTheBrandComponent,
  shouldDisplayFeaturedProductsComponent,
  shouldDisplayFeedbackComponent,
  shouldDisplayNotificationComponent,
  shouldDisplayOrderDetailsComponent,
  shouldDisplayPromotionsComponent,
  shouldDisplayReturnsComponent
} from 'utils/common/tracking.utils/tracking.utils';
import { Error } from 'components/Shared/Error/Error';
import { Loading } from 'components/Shared/Loading/Loading';
import { Vivid } from 'components/Tracking/Templates/Vivid/Vivid';
import { Spartan } from 'components/Tracking/Templates/Spartan/Spartan';
import { initGoogleTagManagerDataLayer } from 'utils/common/google-tag-manager/google-tag-manager';
import { calculateDeliveryType } from 'utils/common/utils/utils';
import { ClientConfigContext } from 'context/shared/ClientConfigContext';
import { setEddDisplayGtmDimension } from 'utils/tracking/set-edd-display-gtm-dimension/set-edd-display-gtm-dimension';

type TrackingParams = {
  trackingNumber: string | undefined;
  designId: string | undefined;
};

export const Tracking: React.FC = () => {
  const { trackingFlagCoco891 } = useFlags();
  const { trackingNumber } = useParams<TrackingParams>();
  const [urlSearchParams] = useSearchParams();
  const [key] = useState(urlSearchParams.get('key'));

  const { template, vanityName, merchantId, clientReturnType, isPreview, designId, clientConfig, brandId } = useContext(ClientConfigContext);

  const TRACKING_CONFIGURATION_URL = setTrackingDataUrl(trackingNumber, key, isPreview, designId, location.pathname);
  const [trackingData, , trackingDataError] = useFetch<TrackingConfigurationType, DefaultErrorResponse>(TRACKING_CONFIGURATION_URL);
  // useState
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [showFeaturedProducts, setShowFeaturedProducts] = useState<boolean>(false);
  const [showPromotional, setShowPromotional] = useState<boolean>(false);
  const [showExploreProducts, setShowExploreProducts] = useState<boolean>(false);
  const [showReturns, setShowReturns] = useState<boolean>(false);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [showOrderDetails, setShowOrderDetails] = useState<boolean>(false);
  const [showCustomHtml, setShowCustomHtml] = useState<boolean>(false);
  const [isReturn, setIsReturn] = useState<boolean>(false);

  //useEffects
  useEffect(() => {
    if (trackingData && clientConfig && shouldDisplayNotificationComponent(trackingData.trackingPackageStatus, clientConfig.notificationsModule))
      setShowNotifications(true);
    if (clientConfig && shouldDisplayFeaturedProductsComponent(clientConfig.featuredProductsModule)) setShowFeaturedProducts(true);
    if (clientConfig && shouldDisplayPromotionsComponent(clientConfig.promotionsAddonModule)) setShowPromotional(true);
    if (clientConfig && shouldDisplayExploreTheBrandComponent(clientConfig.exploreTheBrandModule)) setShowExploreProducts(true);
    if (
      clientConfig &&
      trackingData &&
      shouldDisplayReturnsComponent(trackingData.trackingPackageNotFound, trackingData.isTrusted, trackingData.isReturn, clientConfig.returnsModule)
    )
      setShowReturns(true);
    if (trackingData && clientConfig && shouldDisplayFeedbackComponent(trackingData, clientConfig.feedbackQuestionsModule)) setShowFeedback(true);
    if (
      trackingData &&
      clientConfig &&
      shouldDisplayOrderDetailsComponent(clientConfig.orderDetailsModule, trackingData.shippingInfoModule, trackingData.isTrusted)
    )
      setShowOrderDetails(true);
    if (trackingData && clientConfig && shouldDisplayCustomHtmlComponent(clientConfig.customHtmlModule)) setShowCustomHtml(true);
    if (trackingData) setIsReturn(Boolean(trackingData.isReturn));
  }, [clientConfig, trackingData]);

  useEffect(() => {
    const tracking = JSON.stringify(trackingNumber);
    localStorage.setItem('tracking', tracking);
    //console.log(localStorage.getItem('tracking'));

    const brandID = JSON.stringify(brandId);
    window.localStorage.setItem('brandid', brandID);
    //console.log(localStorage.getItem('brandid'));

    const merchantID = JSON.stringify(merchantId);
    window.localStorage.setItem('merchantid', merchantID);
    //console.log(localStorage.getItem('merchantid'));

    if (trackingData) {
      initGoogleTagManagerDataLayer(
        calculateDeliveryType(trackingData.isReturn),
        trackingData.trackingPackageStatus,
        merchantId,
        vanityName,
        trackingData.shippingInfoModule.carrierCode,
        'Tracking Page',
        'trackPageview',
        'Non-integrated',
        template,
        false,
        setEddDisplayGtmDimension(
          trackingData.trackingPackageStatus,
          trackingData.trackingEventsModule.eddText,
          trackingData.trackingEventsModule.eddTime
        ),
        brandId
      );
      window.dataLayer.push({ event: 'runConfirmItScript' });
      return;
    }
    if (trackingDataError) {
      return initGoogleTagManagerDataLayer(
        null,
        'Timeout',
        merchantId,
        vanityName,
        null,
        'Tracking Page',
        'trackPageview',
        'Non-integrated',
        template,
        false,
        'None',
        brandId
      );
    }
  }, [trackingData, trackingDataError, merchantId, vanityName, clientReturnType, template, brandId, trackingNumber]);

  if (trackingDataError || !trackingNumber) return <Error />;
  if (!trackingData || !clientConfig) return <Loading />;

  if (trackingFlagCoco891) {
    trackingData.shippingInfoModule.carrierUrl = trackingData.trackingEventsModule?.trackingEvents[0]?.trackingUrl
      ? trackingData.trackingEventsModule.trackingEvents[0].trackingUrl
      : '';
  }

  if (template === 'vivid')
    return (
      <Vivid
        showNotifications={showNotifications}
        setShowNotifications={setShowNotifications}
        showReturns={showReturns}
        setShowReturns={setShowReturns}
        showFeedback={showFeedback}
        setShowFeedback={setShowFeedback}
        isReturn={isReturn}
        showOrderDetails={showOrderDetails}
        showFeaturedProducts={showFeaturedProducts}
        showPromotional={showPromotional}
        showExploreProducts={showExploreProducts}
        showCustomHtml={showCustomHtml}
        trackingData={trackingData}
        trackingNumber={trackingNumber}
        urlKey={key}
        clientConfig={clientConfig}
        isPreview={trackingData.isPreview}
        brandId={clientConfig.brandId}
      />
    );

  return (
    <Spartan
      showNotifications={showNotifications}
      setShowNotifications={setShowNotifications}
      showReturns={showReturns}
      setShowReturns={setShowReturns}
      showFeedback={showFeedback}
      setShowFeedback={setShowFeedback}
      isReturn={isReturn}
      showOrderDetails={showOrderDetails}
      showFeaturedProducts={showFeaturedProducts}
      showPromotional={showPromotional}
      showExploreProducts={showExploreProducts}
      showCustomHtml={showCustomHtml}
      trackingData={trackingData}
      trackingNumber={trackingNumber}
      urlKey={key}
      clientConfig={clientConfig}
      isPreview={trackingData.isPreview}
    />
  );
};
