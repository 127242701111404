import dayjs from 'dayjs';
import { Parcel } from 'types/customer-service/find-parcels-api-response-type';
import { ColumnTypeEnum } from 'types/customer-service/column-type-enum';
import { ParcelsViewEnum } from 'types/customer-service/parcels-view-enum';
import { SortByParcelType } from 'types/customer-service/sort-by-parcel-type';
import { transformStatusToDisplayForm } from 'utils/customer-service/transform-status-to-display-form/transform-status-to-display-form';
import { transformEddDateToDisplayForm } from '../transform-edd-date-to-display-form/transform-edd-date-to-display-form';

export const getOrderByValue = (parcel: Parcel, orderBy: SortByParcelType, displayedStatus: string): string | null => {
  switch (orderBy) {
    case ColumnTypeEnum.ShipDate:
      return parcel.parcelDetails.deliveryDetails ? parcel.parcelDetails.deliveryDetails.shipDate : null;
    case ColumnTypeEnum.TrackingNumber:
      return parcel.parcelDetails.trackingNumber;
    case ColumnTypeEnum.ReferenceNumber:
      return parcel.parcelDetails.referenceNumber;
    // here we transform status cause we need to sort it by displayed statuses not by original parcel statuses
    // f.e. Return Delivery Exception status is displayed as Return Exception
    case ColumnTypeEnum.CurrentStatus:
      return transformStatusToDisplayForm(displayedStatus, parcel.parcelDetails.parcelStatus);
    // here we need to format date to value which allow us to sort it correctly but also by display value not from response
    // because sometimes we do not have date in estimatedDeliveryDate but we display it
    // f.e when status is 'OutForDelivery' and we do not have estimatedDeliveryDate, we're displaying today date

    case ColumnTypeEnum.EstimatedDelivery: {
      const date = parcel.parcelDetails.deliveryDetails
        ? transformEddDateToDisplayForm(
            parcel.parcelDetails.parcelStatus,
            !!parcel.parcelDetails.deliveryDetails ? parcel.parcelDetails.deliveryDetails.estimatedDeliveryDate : null,
            !!parcel.parcelDetails.deliveryDetails ? parcel.parcelDetails.deliveryDetails.parcelDeliveredAtDate : null,
            !!parcel.parcelDetails.deliveryDetails ? parcel.parcelDetails.deliveryDetails.parcelDeliveryAddress : null,
            ParcelsViewEnum.Table
          )
        : null;
      if (!date || date === '-') return date;

      return dayjs(date).format('YYYY/MM/DD');
    }
    default:
      return null;
  }
};
