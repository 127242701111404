import { TrackingEvent } from 'types/customer-service/find-parcels-api-response-type';

export const shouldDisplayTrackingEvents = (trackingEvents: TrackingEvent[], consumerViewOnly: boolean, isUserAuth: boolean): boolean => {
  const L1OrL2DescriptionExist = trackingEvents.some(
    (trackingEvent: TrackingEvent) => trackingEvent.activity.l1Description || trackingEvent.activity.l2Description
  );
  const hasOnlyL2Description = trackingEvents.every(
    (trackingEvent: TrackingEvent) => !trackingEvent.activity.l1Description && !!trackingEvent.activity.l2Description
  );
  const atLeastOneDescriptionExists = trackingEvents.some((trackingEvent: TrackingEvent) => !!trackingEvent.activity.description);

  if (
    trackingEvents.length === 0 ||
    (!isUserAuth && !atLeastOneDescriptionExists) ||
    (isUserAuth && (!L1OrL2DescriptionExist || (hasOnlyL2Description && consumerViewOnly)))
  )
    return false;

  return true;
};
