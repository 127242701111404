import { TableCell } from '@mui/material';
import { styled as muiStyled } from '@mui/system';

export const CustomTableCell = muiStyled(TableCell, {
  shouldForwardProp: (prop) =>
    prop !== 'widthDefault' &&
    prop !== 'fontSizeDefault' &&
    prop !== 'paddingDefault' &&
    prop !== 'widthXL' &&
    prop !== 'fontSizeXL' &&
    prop !== 'paddingXL' &&
    prop !== 'toUppercase' &&
    prop !== 'fontWeight' &&
    prop !== 'isBorderBottomActive' &&
    prop !== 'shouldOneLineDisplay'
})<CustomTableCellProps>(
  ({
    theme,
    widthDefault,
    fontSizeDefault,
    paddingDefault,
    widthXL,
    fontSizeXL,
    paddingXL,
    toUppercase,
    fontWeight,
    isBorderBottomActive,
    shouldOneLineDisplay
  }) => ({
    width: widthDefault,
    fontSize: fontSizeDefault,
    padding: paddingDefault,
    textTransform: toUppercase ? 'uppercase' : 'none',
    fontWeight: fontWeight ? fontWeight : 'normal',
    maxWidth: shouldOneLineDisplay ? '1px' : 'auto',
    whiteSpace: shouldOneLineDisplay ? 'nowrap' : 'normal',
    borderBottom: isBorderBottomActive ? '1px solid rgba(224, 224, 224, 1)' : 'none',
    color: '#4a4a4a',

    [theme.breakpoints.up('xlarge')]: {
      width: widthXL ? widthXL : widthDefault,
      fontSize: fontSizeXL ? fontSizeXL : fontSizeDefault,
      padding: paddingXL ? paddingXL : paddingDefault
    }
  })
);

interface CustomTableCellProps {
  widthDefault: string;
  fontSizeDefault: string;
  paddingDefault: string;
  widthXL?: string;
  fontSizeXL?: string;
  paddingXL?: string;
  toUppercase?: boolean;
  fontWeight?: 'normal' | 'bold';
  isBorderBottomActive?: boolean;
  shouldOneLineDisplay?: boolean;
}
