export const isFirstLetterVowel = (word: string): 'a' | 'an' => {
  //exceptions
  if (word.toLowerCase() === 'hour') return 'an';

  const vowelsArray: string[] = ['a', 'e', 'i', 'o', 'u'];

  const wordCopy = word.toLowerCase().trim().split('');

  const isFirstLetterVowel = vowelsArray.find((element: string) => element === wordCopy[0]);
  return isFirstLetterVowel ? 'an' : 'a';
};
