import React, { PropsWithChildren } from 'react';
import { createTheme, theme } from 'constants/theme';
import { ThemeProvider } from '@emotion/react';
import { AppError } from 'app/AppErrorLoader/AppError/AppError';
import { GlobalStyles } from 'components/Shared/GlobalStyles/GlobalStyles';
import { Footer } from 'components/Shared/Footer/Footer';
import { ClientConfigurationErrorType, ClientConfigurationType } from 'types/shared/config/client-configuration-type';
import { DefaultErrorResponse } from 'hooks/useFetch/useFetch';
import { defaultFooterModule } from 'constants/default-footer-module';

export const AppErrorLoader: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { error, isPreviewBasedOnRouteUrl } = props;

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <GlobalStyles isPreviewBasedOnRouteUrl={isPreviewBasedOnRouteUrl} clientConfig={{} as ClientConfigurationType} theme={theme} />
      <AppError status={error.error.status} />
      <Footer footerModule={defaultFooterModule} smsEnabled={false} />
    </ThemeProvider>
  );
};

export interface OwnProps {
  error: ClientConfigurationErrorType | DefaultErrorResponse;
  isPreviewBasedOnRouteUrl: boolean;
}
