import React, { Dispatch, ReactNode, SetStateAction, useState, useMemo } from 'react';

export const AuthUserContext = React.createContext<AuthUserContextType>({
  isUserAuth: false,
  setIsUserAuth: () => {}
});

export const AuthUserProvider: React.FC<AuthUserProviderType> = (props) => {
  const { children } = props;

  const [isUserAuth, setIsUserAuth] = useState<boolean>(false);

  const value = useMemo<AuthUserContextType>(
    () => ({
      isUserAuth,
      setIsUserAuth
    }),
    [isUserAuth]
  );

  return <AuthUserContext.Provider value={value}>{children}</AuthUserContext.Provider>;
};

export interface AuthUserContextType {
  isUserAuth: boolean;
  setIsUserAuth: Dispatch<SetStateAction<boolean>>;
}

export interface AuthUserProviderType {
  children: ReactNode;
}
