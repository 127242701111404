import styled from '@emotion/styled';
import { getCustomStyle } from 'utils/common/custom-styles/custom-styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 30px 0 0;

  @media ${({ theme }) => theme.mediumUp} {
    margin: 0;
  }
`;

export const SwitchLabel = styled.label`
  font-size: 16px;
  display: block;
  color: #4a4a4a;
  margin: 0 5px 0 0;
  cursor: pointer;

  font-family: ${getCustomStyle('styleModule.bodyFontFamily')};

  @media ${({ theme }) => theme.mediumUp} {
    font-size: 18px;
    margin: 0 10px 0 0;
  }
`;

export const SwitchElementOn = styled.p`
  font-family: ${getCustomStyle('styleModule.bodyFontFamily')};
  margin: 0;
  padding: 2px 0 0 0;
  color: white;
  font-size: 11px;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SwitchElementOff = styled.p`
  font-family: ${getCustomStyle('styleModule.bodyFontFamily')};
  margin: 0;
  padding: 2px 5px 0 0;
  color: #333333;
  font-weight: bold;
  font-size: 11px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
