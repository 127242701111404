import React from 'react';
import { Skeleton } from '@mui/material';
import { SectionLayout } from 'layouts/Shared/SectionLayout/SectionLayout.styled';
import {
  ReturnInfoLoadingContentBox,
  ReturnInfoLoadingDropOffLocationHeadingBox,
  ReturnInfoLoadingDropOffLocationsBox,
  ReturnInfoLoadingHeadingBox,
  ReturnInfoLoadingInsideLeftContentBox,
  ReturnInfoLoadingInsideRightContentBox,
  ReturnInfoLoadingInsideRightContentWrapper,
  ReturnInfoLoadingPackageTrackingBox,
  ReturnInfoLoadingReturnPolicyBox,
  ReturnInfoLoadingSubheadingBox,
  ReturnInfoLoadingWrapper
} from './ReturnInfoLoading.styled';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Loading } from 'components/Shared/Loading/Loading';
import { DropOffLocationItemSkeleton } from 'components/Returns/Common/DropOffLocationsList/DropOffLocationItem/DropOffLocationItemSkeleton';

export const ReturnInfoLoading = () => {
  const { returnsUiChangesForUspsLocationsCoco754 } = useFlags();

  if (!returnsUiChangesForUspsLocationsCoco754) return <Loading />;

  return (
    <SectionLayout marginDefault="0" backgroundAsColor={false}>
      <ReturnInfoLoadingWrapper>
        <ReturnInfoLoadingHeadingBox>
          <Skeleton variant="text" width="100%" sx={{ fontSize: '30px' }} />
        </ReturnInfoLoadingHeadingBox>
        <ReturnInfoLoadingSubheadingBox>
          <Skeleton width="100%" sx={{ fontSize: '18px' }} />
        </ReturnInfoLoadingSubheadingBox>
        <Skeleton height="5px" sx={{ marginTop: '15px' }} />
        <ReturnInfoLoadingContentBox>
          <ReturnInfoLoadingInsideLeftContentBox>
            <Skeleton variant="rectangular" height="230px" />
          </ReturnInfoLoadingInsideLeftContentBox>
          <ReturnInfoLoadingInsideRightContentBox>
            <ReturnInfoLoadingInsideRightContentWrapper>
              <Skeleton variant="circular" height="30px" width="30px" sx={{ marginRight: '15px' }} />
              <Skeleton sx={{ fontSize: '14px' }} width="88%" />
            </ReturnInfoLoadingInsideRightContentWrapper>
            <ReturnInfoLoadingInsideRightContentWrapper>
              <Skeleton variant="circular" height="30px" width="30px" sx={{ marginRight: '15px' }} />
              <Skeleton sx={{ fontSize: '14px' }} width="88%" />
            </ReturnInfoLoadingInsideRightContentWrapper>
            <ReturnInfoLoadingInsideRightContentWrapper>
              <Skeleton variant="circular" height="30px" width="30px" sx={{ marginRight: '15px' }} />
              <Skeleton sx={{ fontSize: '14px' }} width="88%" />
            </ReturnInfoLoadingInsideRightContentWrapper>
          </ReturnInfoLoadingInsideRightContentBox>
        </ReturnInfoLoadingContentBox>
        <ReturnInfoLoadingDropOffLocationHeadingBox>
          <Skeleton variant="text" sx={{ fontSize: '14px' }} width="80%" />
          <Skeleton variant="text" sx={{ fontSize: '14px' }} width="15%" />
        </ReturnInfoLoadingDropOffLocationHeadingBox>
        <ReturnInfoLoadingDropOffLocationsBox>
          <DropOffLocationItemSkeleton />
          <DropOffLocationItemSkeleton />
          <DropOffLocationItemSkeleton />
        </ReturnInfoLoadingDropOffLocationsBox>
        <ReturnInfoLoadingPackageTrackingBox>
          <ReturnInfoLoadingInsideRightContentWrapper>
            <Skeleton variant="circular" height="30px" width="30px" sx={{ marginRight: '15px' }} />
            <Skeleton height="30px" width="100%" />
          </ReturnInfoLoadingInsideRightContentWrapper>
          <Skeleton variant="rectangular" height="20px" sx={{ marginTop: '15px' }} />
          <Skeleton variant="rectangular" height="20px" sx={{ marginTop: '15px' }} />
        </ReturnInfoLoadingPackageTrackingBox>
        <ReturnInfoLoadingReturnPolicyBox>
          <Skeleton height="30px" />
        </ReturnInfoLoadingReturnPolicyBox>
        <Skeleton height="5px" sx={{ marginTop: '15px' }} />
        <Skeleton variant="rectangular" height="80px" sx={{ marginTop: '15px' }} />
      </ReturnInfoLoadingWrapper>
    </SectionLayout>
  );
};
