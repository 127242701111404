export enum LanguageEnum {
  ChineseTraditional,
  ChineseSimplified,
  Danish,
  German,
  Greek,
  EnglishUS,
  Spanish,
  FrenchCA,
  FrenchFR,
  Hungarian,
  Italian,
  Dutch,
  Japanese,
  Norwegian,
  Polish,
  Portuguese,
  Russian,
  Finnish,
  Swedish
}

type LanguageLabelType =
  | '中文(繁體)'
  | '中文(简体)'
  | 'dansk'
  | 'Deutsch'
  | 'Ελληνικά'
  | 'English (United States)'
  | 'Español'
  | 'français (Canada)'
  | 'français (France)'
  | 'magyar'
  | 'italiano'
  | 'Nederlands'
  | '日本語'
  | 'norsk (bokmål)'
  | 'Polski'
  | 'português'
  | 'русский'
  | 'suomi'
  | 'svenska';

export type LanguageValueType =
  | 'zh-hant'
  | 'zh-hans'
  | 'da'
  | 'de'
  | 'el'
  | 'en'
  | 'es'
  | 'fr-ca'
  | 'fr-fr'
  | 'hu'
  | 'it'
  | 'nl'
  | 'ja'
  | 'nb'
  | 'pl'
  | 'pt'
  | 'ru'
  | 'fi'
  | 'sv';

export interface LanguageOption {
  label: LanguageLabelType;
  value: LanguageValueType;
}

export type LanguagesType = Record<LanguageEnum, LanguageOption>;

export const Languages: LanguagesType = {
  [LanguageEnum.ChineseTraditional]: {
    value: 'zh-hant',
    label: '中文(繁體)'
  },
  [LanguageEnum.ChineseSimplified]: {
    value: 'zh-hans',
    label: '中文(简体)'
  },
  [LanguageEnum.Danish]: {
    value: 'da',
    label: 'dansk'
  },
  [LanguageEnum.German]: {
    value: 'de',
    label: 'Deutsch'
  },
  [LanguageEnum.Greek]: {
    value: 'el',
    label: 'Ελληνικά'
  },
  [LanguageEnum.EnglishUS]: {
    value: 'en',
    label: 'English (United States)'
  },
  [LanguageEnum.Spanish]: {
    value: 'es',
    label: 'Español'
  },
  [LanguageEnum.FrenchCA]: {
    value: 'fr-ca',
    label: 'français (Canada)'
  },
  [LanguageEnum.FrenchFR]: {
    value: 'fr-fr',
    label: 'français (France)'
  },
  [LanguageEnum.Hungarian]: {
    value: 'hu',
    label: 'magyar'
  },
  [LanguageEnum.Italian]: {
    value: 'it',
    label: 'italiano'
  },
  [LanguageEnum.Dutch]: {
    value: 'nl',
    label: 'Nederlands'
  },
  [LanguageEnum.Japanese]: {
    value: 'ja',
    label: '日本語'
  },
  [LanguageEnum.Norwegian]: {
    value: 'nb',
    label: 'norsk (bokmål)'
  },
  [LanguageEnum.Polish]: {
    value: 'pl',
    label: 'Polski'
  },
  [LanguageEnum.Portuguese]: {
    value: 'pt',
    label: 'português'
  },
  [LanguageEnum.Russian]: {
    value: 'ru',
    label: 'русский'
  },
  [LanguageEnum.Finnish]: {
    value: 'fi',
    label: 'suomi'
  },
  [LanguageEnum.Swedish]: {
    value: 'sv',
    label: 'svenska'
  }
};
